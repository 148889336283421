<template>
    <div class="TagList">
        <el-scrollbar>
            <div class="scrollbar-flex-content">
                <!-- 右键 -->
                <!-- <el-popover v-for="(tag,index) in TagList" :key="index"
                    :ref="'popover'+index"
                    placement="right-start"
                    :width="85"
                    :offset="-20"
                    :show-arrow="false"
                    :hide-after="0"
                    popper-class="rightMen"
                    trigger="contextmenu">
                        <template #reference>
                            <el-tag                                
                                :closable="tag.isClose"                    
                                :effect="tag.effect"
                                @click="btnTagClick(tag)"
                                @close="btnTagClose(tag)">
                                <el-link class="tagName" :underline="false">{{tag.name}}</el-link>
                            </el-tag>
                        </template>
                        <ul>
                            <li><a @click="btnRefresh" class="iconfont icon-refresh"><span>刷新页面</span></a></li>
                            <li><a @click="btnClearTag" class="iconfont icon-guanbi"><span>关闭全部</span></a></li>
                        </ul>
                </el-popover> -->

                <el-tag
                    v-for="tag in TagList"
                    :key="tag.name"
                    :closable="tag.isClose"                    
                    :effect="tag.effect"
                    @click="btnTagClick(tag)"
                    @close="btnTagClose(tag)">
                    
                    <el-link class="tagName" :underline="false">{{tag.name}}</el-link>
                </el-tag>

                <!-- 清除功能 -->
                <el-dropdown>
                    <span class="el-dropdown-link">
                        <el-icon class="iconfont icon-arrow-down">
                        </el-icon>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="btnRefresh" class="iconfont icon-refresh">刷新页面</el-dropdown-item>
                            <el-dropdown-item @click="btnClearTag" class="iconfont icon-guanbi">关闭全部</el-dropdown-item>                            
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                
            </div>                    
        </el-scrollbar>
    </div>
    
</template>

<script>
import {mapState,mapActions} from 'vuex'
export default {
    name:'CommonTag',
    computed:{
        ...mapState('Master',['TagList']),
    },
    methods:{
        ...mapActions('Master',['work_AddTag','work_DelTag','work_LoadTag','work_ClearTag']),
        //点击Tag标签
        btnTagClick(tag){
            //先添加
            this.work_AddTag(tag)
            //再跳转
            this.$router.push({
                name:tag.routeName
            })
        },
        //关闭Tag标签
        btnTagClose(tag){
            //先跳转
            if(tag.effect == 'dark'){
                const index = this.TagList.indexOf(tag)-1;
                const newItem = this.TagList[index]
                this.$router.push({
                    name:newItem.routeName
                })
            }
            //再删除
            this.work_DelTag(tag)
        },
        //关闭所有Tag标签
        btnClearTag(){
            //清空数组和本地缓存
            this.work_ClearTag()
            localStorage.removeItem("TagList")
            //再跳转
            this.$router.push({
                name:'center'
            })
        },
        
        
    }
}
</script>

<style scoped>
.TagList{background-color: #fff;z-index: 999;box-shadow: 3px 3px 3px #d8dce5;position: relative;padding: 2px;}
    .el-tag{margin-right: 5px;}

    /* 滚动条 */
    .scrollbar-flex-content {
        display: flex;
    }
    .scrollbar-demo-item {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 50px;
        margin: 10px;
        text-align: center;
        border-radius: 4px;
        background: var(--el-color-danger-light-9);
        color: var(--el-color-danger);
    }
    /** 未选中Tag */
    .tagName{color: #000;}
    /** 选中Tag */
    .el-tag--dark .tagName{color: #fff;}
    /* 下拉列表 */
    .el-dropdown{line-height: 23px;}
    .el-dropdown-link {border: 1px solid #ddd;padding: 0px 2px;border-radius: 5px;}
    /* 右键菜单样式 */
    .rightMen{width: 80px;padding: 0px;}
    .rightMen ul {padding: 0px;margin: 0px;}
    .rightMen ul li{ list-style-type:none;height: 30px;line-height: 30px;}
    .rightMen ul li a{color: #000;}
    .rightMen ul li a span{padding-left: 5px;}
    .rightMen ul li:hover{background-color: #eee;}
</style>