<template>
  <div>

    <!-- 菜单列表 -->
    <el-scrollbar :height="GetDataHeight" always :min-size="30">
        <el-tree
        :data="allMenuList"
        :default-checked-keys="systemInfo.selectMenuList"
        :default-expanded-keys="[0]"
        show-checkbox
        ref = "treeMenu"
        node-key="id">          
        </el-tree>
    </el-scrollbar>


  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return{
            allMenuList:[],   //全部菜单列表
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-200
        },
        ...mapState('System',['systemInfo'])
    },
    methods:{
        //加载全部菜单列表(包含按钮)
        loadDataAllMenu(){
            this.$api.system.SelectAllMenuButtonList().then(res=>{
                if(res.data.status === 200){
                    this.allMenuList = res.data.data
                }else{
                    this.allMenuList = []
                }
            }).catch(e=>{
                console.log(e)
            })
        },

    },
    mounted(){
        this.loadDataAllMenu();   //加载全部菜单列表
    }
}
</script>

<style>

</style>