<template>
  <div>

    <el-form :inline="true"
    v-model="businessOrigin.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="businessOrigin.info">

            <el-col :span="8">
                <el-form-item label="原始单号">
                    <el-input v-model="businessOrigin.info.originNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="原始单据类型">
                    <el-tag v-if="businessOrigin.info.originType ==1" type="danger">采购单</el-tag>
                    <el-tag v-if="businessOrigin.info.originType ==2" type="success">销售单</el-tag>
                    <el-tag v-if="businessOrigin.info.originType ==3" type="info">零售单</el-tag>
                    <el-tag v-if="businessOrigin.info.originType ==4" type="danger">差额单</el-tag>
                    <el-tag v-if="businessOrigin.info.originType ==14" type="danger">退供单</el-tag>
                    <el-tag v-if="businessOrigin.info.originType ==15" type="danger">退货单</el-tag>
                    <el-tag v-if="businessOrigin.info.originType ==17" type="info">网络订单</el-tag>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="所属部门">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>所属部门</span>
                    </template>
                    <el-tree-select v-model="businessOrigin.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="所属大区">
                    <el-input v-model="businessOrigin.info.areaName" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="交易者">
                    <el-select v-model="businessOrigin.info.tradeNumber" filterable class="search-200">
                        <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="总价">
                    <el-input v-model="businessOrigin.info.totalPrice" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="财务单标识">
                    <el-tag v-if="businessOrigin.info.financeFlag == 0" type="danger">未生成</el-tag>
                    <el-tag v-if="businessOrigin.info.financeFlag == 1" type="info">已生成</el-tag>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="财务单号">
                    <el-input v-model="businessOrigin.info.financeNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="备注">
                    <el-input v-model="businessOrigin.info.remark" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="支付状态">
                    <el-tag v-if="businessOrigin.info.payState == 0" type="danger">未支付</el-tag>
                    <el-tag v-if="businessOrigin.info.payState == 1" type="info">已支付</el-tag>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="制单人">
                    <el-input v-model="businessOrigin.info.createName" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import PartTradeList from '@/utils/mixins/PartTradeList.js'  //交易者列表
import { mapState } from 'vuex'
export default {
    data(){
        return{
            tradeList:[],   //交易者列表
        }
    },
    mixins:[UserDptList,PartTradeList],
    computed:{
        ...mapState('Erp',['businessOrigin'])
    },
}
</script>

<style>

</style>