<template>
  <div>

    <el-row :gutter="20">
        <el-col :span="12">
            <el-table  border stripe :data="typeList" show-summary @row-click="TableRowClick">
                <el-table-column prop="name" label="分类名称"></el-table-column>
                <el-table-column prop="value" label="总金额(元)"></el-table-column>
            </el-table>
        </el-col>
        <el-col :span="12">
            <el-table  border stripe :data="subList" show-summary>
                <el-table-column prop="name" label="科目名称"></el-table-column>
                <el-table-column prop="value" label="总金额(元)"></el-table-column>
            </el-table>
        </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
    data(){
        return{
            currentYear:'',  //年份
            typeList:[],  //财务分类
            subList:[],   //会计科目
        }
    },
    methods:{

        //加载财务分类
        async LoadDataView(strYear){
            this.currentYear = strYear;
          let param = {
            year:strYear
          }
          await this.$api.stat.GetStatYearFinanceBillType(param).then(res=>{
            if(res.data.status === 200){
              this.typeList = res.data.data;
              this.subList = [];
            }
          })
        },

        //加载会计科目
        TableRowClick(row){
            console.log(row)
            let param = {
                year:this.currentYear,
                billType:row.billType,
            }
            this.$api.stat.GetStatYearFinanceSub(param).then(res=>{
                if(res.data.status === 200){
                    this.subList = res.data.data;
                }
            })
        },
    },
    mounted(){
        this.LoadDataView();
    }
        
}
</script>

<style>

</style>