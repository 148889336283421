<template>
  <div>

    <el-form :inline="true"
    v-model="cycleBusinessInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="cycleBusinessInfo.info">
            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="对账单号">
                    <el-input v-model="cycleBusinessInfo.info.cycleNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属部门">
                    <el-tree-select v-model="cycleBusinessInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="交易者">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>交易者</span>
                    </template>
                    <el-select v-model="cycleBusinessInfo.info.tradeNumber" filterable clearable class="search-200">
                        <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="总金额">
                    <el-input v-model="cycleBusinessInfo.info.totalPrice" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="备注">
                    <el-input v-model="cycleBusinessInfo.info.remark" class="search-200" ></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="创建时间">
                    <el-input v-model="cycleBusinessInfo.info.createTime" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>
  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'  //用户关联部门列表
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import { mapState } from 'vuex'
export default {
    mixins:[UserDptList,AllTradeList],
    computed:{
        ...mapState('Erp',['cycleBusinessInfo'])
    },
}
</script>

<style>

</style>