<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row>

            <el-col :span="24">
                <el-form-item label="所属部门">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>所属部门</span>
                    </template>
                    <el-tree-select v-model="info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="班次标识">
                    <el-select v-model="info.shiftFlag" class="search-200">
                        <el-option label="关闭" :value="0"></el-option>
                        <el-option label="开启" :value="1"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>  

            <el-col :span="24">
                <el-form-item label="产品来源">
                    <el-select v-model="info.goodsOrigin" class="search-200">
                        <el-option label="全部产品" :value="0"></el-option>
                        <el-option label="部门关联产品" :value="1"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>  

        </el-row>
    </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup" >
        <el-button type="primary"  @click="btnSaveData(1)">保存</el-button>        
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import { ElLoading, ElMessage } from 'element-plus'
export default {
  props:['editId','dataList'],
  mixins:[UserDptList],
  data(){
    return{
      info:{
        systemNumber:'',
        setDptNumber:'',
        departmentId:0,
        shiftFlag:0,
        goodsOrigin:0,
      },
      strDptNumber:this.editId,
    }
  },
  methods:{

    //加载数据
    GetDataInfo(){
      if(this.strDptNumber!=0){
        let param = {
          setDptNumber:this.strDptNumber
        }
        this.$api.common.GetSetDptInfo(param).then(res=>{
          if(res.data.status === 200){
            this.info = res.data.data;
          }else{
            this.btnClearData();
          }
        })
      }else{
        this.btnClearData();
      }      
    },

    //保存
    btnSaveData(){
      //打开遮罩层
      const loading = ElLoading.service({
          lock:true,
          text:'正在提交,请稍候!',
          background:'rgba(0, 0, 0, 0.7)',
      })

      this.$api.common.SaveSetDptInfo(this.info).then(res=>{
        loading.close();
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          //关闭
          this.btnClose();
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

    //清空数据
    btnClearData(){
      this.info={
          id:0,
          systemNumber:'',
          setDptNumber:'',
          departmentId:0,
          departmentName:'',
          shiftFlag:0,  //班次标识 0:关闭 1:开启
          goodsOrigin:0, //产品来源 0:全部产品 1:部门关联产品
        }
    },

    //上一条
    btnPrevious(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.setDptNumber === this.strDptNumber
        })
        let prevIndex = currentIndex -1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有上一条了");
            return false;
        }
        else{
            this.strDptNumber = prevInfo.setDptNumber
            this.GetDataInfo()
        }
        
    },
    //下一条
    btnNext(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.setDptNumber === this.strDptNumber
        })
        let prevIndex = currentIndex + 1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有下一条了");
            return false;
        }
        else{
            this.strDptNumber = prevInfo.setDptNumber
            this.GetDataInfo()
        }
    },

  },
  mounted(){
    this.GetDataInfo();
  },
}
</script>

<style>

</style>