<template>
  <div>
    <!-- 业务单明细列表 -->
    <el-table :data="businessOrigin.detailList" border stripe show-summary style="width: 100%">
        <el-table-column prop="billNumber" label="业务编号" min-width="80"></el-table-column>
        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="90">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" show-overflow-tooltip min-width="90"/>
        <el-table-column label="单位" min-width="100">
            <template v-slot="scope">
                <span>{{scope.row.unitName}}</span>
            </template>
        </el-table-column>
        <el-table-column label="单价" min-width="80">
            <template v-slot="scope">
                <span>{{scope.row.unitPrice}}</span>
            </template>
        </el-table-column>
        <el-table-column label="数量" min-width="80">
            <template v-slot="scope">
                <span>{{scope.row.itemNum}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="总价(元)" min-width="95" />     


    </el-table>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Erp',['businessOrigin'])
    },

}
</script>

<style>

</style>