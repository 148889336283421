<template>
  <div>
    <!-- 单日排班计划 -->
    <el-divider></el-divider>

    <span class="CurrentDate">{{day}}</span>
    <el-table :data="info.dataList" border>
        <el-table-column prop="userName" label="员工姓名"></el-table-column>
        <el-table-column prop="departmentName" label="部门"></el-table-column>
        <el-table-column prop="className" label="班次"></el-table-column>
    </el-table>

  </div>
</template>

<script>
export default {
    props:['day'],
    data(){
        return{
            info:{
                dataList:[],   //数据列表
            },
            
        }
    },
    methods:{
        //获取数据列表
        GetDataList(){
            let param = {
                day:this.day
            }
            this.$api.oa.GetSingleDayScheduleList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                }
            })
        },


    },
    mounted(){
        this.GetDataList();
    }
}
</script>

<style scoped>
.CurrentDate{font-weight: bolder;}
</style>