<template>
  <!-- 选择所有供应商列表 -->
  <div>
      <el-divider></el-divider>

      <el-scrollbar height="400px">
        
        <!-- 搜索功能 -->
        <div>
            <el-form :inline="true">

                <el-form-item label="部门">
                  <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple />
                </el-form-item>

                <el-form-item label="供应商名称">
                    <el-input v-model="info.search.supplierName"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>
            </el-form>
        </div>

        <!-- 内容列表 -->
        <div>
            <!-- 列表 -->
            <el-table :data="info.dataList" border style="width: 100%" @select="btnCheckbox" @select-all="btnAllCheckbox" ref="table"> 
                <el-table-column prop="id" type="selection" width="50" />  
                <el-table-column prop="departmentName" label="部门名称" />
                <el-table-column prop="supplierName" label="供应商名称" />
                <el-table-column prop="supplierAlias" label="别名" />
                <el-table-column prop="score" label="评分" />
            </el-table>

            <!-- 分页 -->
            <el-container class="page_one">
                <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[10, 50, 100, 200]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                >
                </el-pagination>
            </el-container>

        </div>
      </el-scrollbar>
  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
export default {
    name:'SelectAllSupplierList',
    mixins:[UserDptList],
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,   //页数
                size:10,  //单页记录数
                total:0,  //总记录数
                search:{  //搜索条件
                    supplierName:'',
                    selectDepartmentList:[],  //所属部门
                },
            },
            //选择后内容
            selectedItem:[],

        }
    },
    methods:{
        //加载所有供应商数据
        GetDataList(){
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                return item
                })
            }


            let param = {
                departmentList:newDepartmentList,
                supplierName:this.info.search.supplierName,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.supplier.ListSupplier(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;  //获取数据
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            }).catch(e=>{
                console.log(e.message)
            })
        },
        //选中多选框
        btnCheckbox(row){
            this.selectedItem = row
        },
        //全选
        btnAllCheckbox(){
            this.selectedItem = this.info.dataList
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //重置按钮
        btnResetSearch(){
            this.info.search.supplierName='',
            this.GetDataList()
        },

    },
    mounted(){
        this.GetDataList()   //加载数据Table数据
    }
}
</script>

<style>

</style>