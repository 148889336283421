<template>
  <div>
    <el-divider></el-divider>

    <div id="printData">
        <div>            
            <el-row>
                <el-col :span="7" class="titleLeft">打印时间：{{printTime}}</el-col>
                <el-col :span="10" class="title"><h3>退供单</h3></el-col>
                <el-col :span="7" class="titleRight">公司名称：{{CurrentsystemInfo.companyName}}</el-col>
            </el-row>
            <el-divider></el-divider>
        </div>
        <!-- 退供单 -->
        <table class="DataInfo">
            <tr>
                <td>退供单号：{{returnPurchaseInfo.info.billNumber}}</td>
                <td>所属部门：{{returnPurchaseInfo.info.departmentName}}</td>
                <td>交易类型：
                    <span v-if="returnPurchaseInfo.info.tradeType==1">供应商</span>
                    <span v-if="returnPurchaseInfo.info.tradeType==2">客户</span>
                    <span v-if="returnPurchaseInfo.info.tradeType==3">员工</span>
                </td>
                <td>交易者：{{returnPurchaseInfo.info.tradeName}}</td>
            </tr>
            <tr>
                <td>记账时间：{{returnPurchaseInfo.info.billTime}}</td>
                <td>总价：{{returnPurchaseInfo.info.totalPrice}}元</td>
                <td>状态：
                    <span v-if="returnPurchaseInfo.info.state ==1">新建单</span>
                    <span v-if="returnPurchaseInfo.info.state ==2">待审核</span>
                    <span v-if="returnPurchaseInfo.info.state ==3">已驳回</span>
                    <span v-if="returnPurchaseInfo.info.state ==4">已审核</span>
                    <span v-if="returnPurchaseInfo.info.state ==5">已出库</span>
                    <span v-if="returnPurchaseInfo.info.state ==10">已完成</span>
                </td>
            </tr>
        </table>
        <table class="DataDetail">
            <tr>
                <th>仓库</th>
                <th>产品类目</th>
                <th>品牌</th>
                <th>产品</th>
                <th>规格</th>
                <th>单位</th>
                <th>单价(元)</th>
                <th>计划数量</th>
                <th>出库数量</th>
                <th>总价(元)</th>
                <th>备注</th>
            </tr>
            <tr v-for="detail in returnPurchaseInfo.detailList" :key="detail.billNumber">
                <td>{{detail.warehouseName}}</td>
                <td>{{detail.cidName}}</td>
                <td>{{detail.brandName}}</td>
                <td>{{detail.spuName}}</td>
                <td>{{detail.skuName}}</td>
                <td>{{detail.unitName}}</td>
                <td>{{detail.unitPrice}}</td>
                <td>{{detail.planNum}}</td>
                <td>{{detail.realNum}}</td>
                <td>{{detail.totalPrice}}</td>
                <td>{{detail.remark}}</td>
            </tr>
        </table>

        <!-- 出库单记录 -->
        <div class="title">
            <h3>出库记录</h3>
        </div>
        <div v-if="returnPurchaseInfo.outAllInfoList !=null && returnPurchaseInfo.outAllInfoList.length>0">
            <div v-for="(allInfo,index) in returnPurchaseInfo.outAllInfoList" :key="index" class="cellBill">
                <!-- 出库表头 -->
                <table class="DataInfo">
                    <tr>
                        <td>退供单号：{{allInfo.info.originNumber}}</td>
                        <td>出库单号：{{allInfo.info.outNumber}}</td>                        
                        <td>出库时间：{{DateTimeFormat(allInfo.info.outTime)}}</td>
                        <td>
                            状态：
                            <span v-if="allInfo.info.state == 1">新建单</span>
                            <span v-if="allInfo.info.state == 2">出库中</span>
                            <span v-if="allInfo.info.state == 3">已出库</span>
                            <span v-if="allInfo.info.state == 10">已取消</span>
                        </td>                        
                    </tr>
                    <tr>
                        <td>交易者类型：
                            <span v-if="allInfo.info.tradeType==1">供应商</span>
                            <span v-if="allInfo.info.tradeType==2">客户</span>
                            <span v-if="allInfo.info.tradeType==3">员工</span>
                        </td>
                        <td>交易者名称：{{allInfo.info.tradeName}}</td>
                        <td>经手人：{{allInfo.info.receiveName}}</td>
                    </tr>
                </table>
                <table class="DataDetail">
                    <tr>
                        <th>仓库</th>
                        <th>产品类目</th>
                        <th>品牌</th>
                        <th>产品</th>
                        <th>规格</th>
                        <th>单位</th>
                        <th>出库数量</th>
                    </tr>
                    <tr v-for="detail in allInfo.detailList" :key="detail.id">
                        <td>{{detail.warehouseName}}</td>
                        <td>{{detail.cidName}}</td>
                        <td>{{detail.brandName}}</td>
                        <td>{{detail.spuName}}</td>
                        <td>{{detail.skuName}}</td>
                        <td>{{detail.unitName}}</td>
                        <td>{{detail.realNum}}</td>
                    </tr>
                </table>

            </div>            
        </div>
        
    </div>

    <!-- 按钮 -->
    <div class="btnGroup">
        <el-button type="primary" class="iconfont icon-dayin" v-print="printObj" id="btnPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
export default {
    data(){
        return{
            printTime:moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            printObj: {
              id:'printData',
              preview: false,
              popTitle: '打印预览',
            }
        }
    },
    computed:{
        ...mapState('Erp',['returnPurchaseInfo']),
        ...mapState('Master',['CurrentsystemInfo']),
    },
    methods:{
        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
}
</script>

<style scoped>
#printData {font-size: 12px;overflow: hidden;} /**防止多打印一张 */
#printData .titleLeft{display: flex;align-items: flex-end;}
#printData .title {text-align: center;font-size: 14px;}
#printData .titleRight{display: flex;align-items: flex-end;justify-content: flex-end;}
#printData .DataInfo{width: 100%;}
#printData .DataInfo td {padding: 5px 0px;}

#printData .cellBill{padding-bottom: 20px;} /* 间隔 单据*/
#printData .DataDetail {width: 100%;border-collapse: collapse;}
#printData .DataDetail th {border: 1px solid #ccc;}
#printData .DataDetail td {border: 1px solid #ccc;}
</style>