<template>
  <div>
    <!-- 货品量统计 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="产品类目">
              <el-tree-select v-model="info.search.cidList" :data="selectCategoryList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200"/>
            </el-form-item>

            <el-form-item label="单据类型">
                <el-select v-model="info.search.billType" class="search-200">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="采购单"></el-option>
                    <el-option :value="2" label="销售单"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>                    
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>
            </el-col>
        </el-row>

        <!-- 数据列表 -->
        <el-table :data="dataList" border show-summary stripe :height="GetDataHeight" style="width: 100%">
            <el-table-column prop="skuNumber" type="selection" width="70"></el-table-column>
            <el-table-column prop="cidName" label="产品类目"></el-table-column>
            <el-table-column prop="brandName" label="品牌"></el-table-column>
            <el-table-column prop="spuName" label="产品名称"></el-table-column>
            <el-table-column prop="skuName" label="规格"></el-table-column>
            <el-table-column prop="minUnitName" label="最小单位"></el-table-column>
            <el-table-column prop="minPurchaseNum" label="采购数量(最小单位)">
              <template v-slot="scope">
                  <span>{{scope.row.minPurchaseNum}} {{scope.row.minUnitName}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="purchaseTotalPrice" label="采购总金额(元)"></el-table-column>
            <el-table-column prop="minSaleNum" label="销售数量(最小单位)">
              <template v-slot="scope">
                  <span>{{scope.row.minSaleNum}} {{scope.row.minUnitName}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="saleTotalPrice" label="销售总金额(元)"></el-table-column>
        </el-table>

        <!-- 备注 -->
        <div>
          <span class="reMark">注：只统计已经生成业务单的采购单和销售单数据。</span>
        </div>

    </div>


  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import CategoryList from '@/utils/mixins/CategoryList.js'   //产品类目列表
import { ElLoading } from 'element-plus'
export default {
  mixins:[UserDptList,CategoryList],
  data(){
    return{
      dataList:[],   //数据
      info:{
        page:1,
        size:20,
        total:0,  //总记录数
        id:0,  //新增、编辑 的参数
        ids:[],   //删除 的参数
        search:{
          selectDepartmentList:[],
          datePoint:[this.beginDate,this.endDate],
          billType:0,
          cidList:[],
        },
        beginDate:this.DateFormat(new Date()),   //开始日期
        endDate:this.DateFormat(new Date()),   //结束日期
      }
    }
  },
  computed:{
    //自适应窗体高度
    GetDataHeight(){
        return window.innerHeight-220
    },
    
  },
  methods:{
    //加载数据列表
    GetDataList(){
      //打开遮罩层
      const loading = ElLoading.service({
          lock:true,
          text:'正在加载,请稍候!',
          background:'rgba(0, 0, 0, 0.7)',
      })

      if(this.info.search.datePoint == null){
          this.info.search.datePoint = ''
      }

      //获取、转换部门
      let newDepartmentList = []
      if(this.info.search.selectDepartmentList!=null){
          newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
              return item
          })
      }

      let param = {
        departmentList:newDepartmentList,
        billType:this.info.search.billType,
        cidList:this.info.search.cidList,
        startDate:this.DateFormat(this.info.search.datePoint[0]),
        endDate:this.DateFormat(this.info.search.datePoint[1]),
      }
      this.$api.stat.ListStatGoodsNums(param).then(res=>{
        loading.close();
        if(res.data.status === 200){
          this.dataList = res.data.data;   //绑定数据
        }
      }).catch(e=>{
        console.log(e);
      })
    },

    //重置按钮
    btnResetSearch(){
      this.info.search.selectDepartmentList = [];
      this.info.search.datePoint = [this.beginDate,this.endDate];
      this.info.search.billType = 0;
      this.info.search.cidList = [];
      this.GetDataList();
    },

    //计算日期
    GetbeginDate(){
      var date = new Date();
      date.setDate(date.getDate()-7);
      var year = date.getFullYear();
      var month = (date.getMonth() + 1).toString().padStart(2,'0');
      var day = date.getDate().toString().padStart(2,'0');
      var startDate = year + '-' + month + '-'+day;
      this.beginDate = this.DateFormat(startDate);
      this.info.search.datePoint = [this.beginDate,this.endDate];
    },


  },
  mounted(){
    this.GetbeginDate();  //设置开始日期
    this.GetDataList();
  }

}
</script>

<style scoped>
.reMark{color: #8f8b8b;}
</style>