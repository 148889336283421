<template>
  <div>
      <!-- 横线 -->
      <el-divider></el-divider>

      <el-form :inline = "true"
        v-model="info"
        label-width="80px"
        label-position="right">

        <el-row v-if="info">

            <el-col :span="12">
                <el-form-item label="所属部门">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>所属部门</span>
                    </template>
                    <el-tree-select v-model="info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="所属大区">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>所属大区</span>
                    </template>
                    <el-select v-model="info.areaNumber" class="search-200" placeholder="选择所属大区">
                        <el-option v-for="area in bigAreaList" :key="area.areaNumber" :value="area.areaNumber" :label="area.areaName"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <!-- <el-col :span="12">
                <el-form-item label="仓库编码">
                    <el-input v-model="info.warehouseCode" class="search-200"></el-input>
                </el-form-item>
            </el-col> -->

            <el-col :span="12">
                <el-form-item label="仓库名称">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>仓库名称</span>
                    </template>
                    <el-input v-model="info.warehouseName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="仓库类型">
                    <el-select v-model="info.warehouseType" class="search-200">
                        <el-option label="普通库" :value="1"></el-option>
                        <el-option label="冷藏库" :value="2"></el-option>
                        <el-option label="耗材库" :value="3"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="状态">
                    <el-select v-model="info.state" class="search-200">
                        <el-option label="关闭" :value="0"></el-option>
                        <el-option label="正常" :value="1"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="管理员">
                    <el-input v-model="info.manager" maxlength="100" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="省市区">
                    <el-cascader :options="areaList" clearable v-model="info.selectArea" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="详细地址">
                    <el-input v-model="info.address" maxlength="50" class="search-200"></el-input>
                </el-form-item>
            </el-col>            

            <el-col :span="12">
                <el-form-item label="备注">
                    <el-input v-model="info.remark" maxlength="100" class="search-200"></el-input>
                </el-form-item>
            </el-col>

        </el-row>
      </el-form>
      
      <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import areaList from '@/utils/mixins/AreaList.js'  //这是省市区列表，不是大区列表
import { ElMessage } from 'element-plus'
export default {
    name:'EditWarehouse',
    props:['editId','dataList'],
    mixins:[UserDptList,areaList],
    data(){
        return{
            info:{
                id:0,
                systemNumber:'',
                departmentId:0,
                areaNumber:'',  //所属大区
                warehouseNumber:'',
                warehouseCode:'',
                warehouseName:'',
                warehouseType:1,
                areaNum:0,
                state:1,
                selectArea:[],
                address:'',
                manager:'',
                remark:'',
            },
            warehouseNumber:this.editId,   //仓库编号
            bigAreaList:[],   //大区列表
        }
    },
    methods:{
        //加载大区列表
        LoadAreaList(){
            this.$api.system.SelectSystemAreaList().then(res=>{
                if(res.data.status === 200){
                    this.bigAreaList = res.data.data;
                }else{
                    this.bigAreaList = []
                }
            })
        },

        //加载数据
        GetDataInfo(){
            if(this.warehouseNumber!=0){
                let param = {
                    warehouseNumber:this.warehouseNumber
                }
                this.$api.wms.SingleWarehouse(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data
                    }else{
                        this.btnClearData();
                    }
                })
            }
            else
            {
                this.btnClearData();
            }
        },

        //保存-仓库信息 -type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            this.$api.wms.SaveWarehouseInfo(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //类型
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                    }
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //清空数据
        btnClearData(){
            let newInfo = {
                id:0,
                systemNumber:'',
                departmentId:0,
                areaNumber:'',
                warehouseNumber:'',
                warehouseCode:'',
                warehouseName:'',
                warehouseType:1,
                areaNum:0,
                state:1,
                selectArea:[],
                address:'',
                manager:'',
                remark:'',
            }
            this.info = newInfo;
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.warehouseNumber === this.warehouseNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.warehouseNumber = prevInfo.warehouseNumber
                this.GetDataInfo()
            }
            
        },

        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.warehouseNumber === this.warehouseNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.warehouseNumber = prevInfo.warehouseNumber
                this.GetDataInfo()
            }
        },

    },
    mounted(){
        this.LoadAreaList();  //加载大区列表
        this.GetDataInfo()  //获取信息
    }
}
</script>

<style>

</style>