<template>
  <div>
    <el-tag v-for="tag in memberInfo.tagList" :key="tag" class="MemberTag" type="warning" :closable="true" @close="TagClose(tag.tagId)">{{tag.tagName}}</el-tag>
       

    <!-- 间隔距离 -->
    <div style="height:20px;"></div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
export default {
  data(){
    return{
      TagList:['优质会员','高频消费','线上会员','实体店会员','低频会员']
    }
  },
  computed:{
      ...mapState('Shop',['memberInfo'])
  },
  methods:{
    //删除标签
    TagClose(tagId){
      this.memberInfo.tagList = this.memberInfo.tagList.filter(item=>{
        if(item.tagId != tagId){
          return item;
        }
      })
      //删除
      let param = {
        memberNumber:this.memberInfo.info.memberNumber,
        tagId:tagId
      }
      this.$api.member.DeleteRelationMemberTag(param).then(res=>{
        if(res.data.status != 200){
          ElMessage.error(res.data.msg);
        }
      })
    },

    
  }
}
</script>

<style scoped>
.MemberTag{margin-right: 10px;}
</style>