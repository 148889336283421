<template>
  <div>
    <el-divider></el-divider>

    <!-- 车辆信息 -->
    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row>

            <el-col :span="8">
                <el-form-item label="所属部门">
                    <el-tree-select v-model="info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="车牌号码">
                    <el-input v-model="info.carCode" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="车辆名称">
                    <el-input v-model="info.carName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],
    mixins:[UserDptList],
    data(){
        return{
            info:{
                systemNumber:'',
                carNumber:'',
                departmentId:0,
                carCode:'',
                carName:'',
                userNumber:'',
            },
            carNumber:this.editId, //当前编号
        }
    },
    methods:{
        //获取数据信息
        GetDataInfo(){
            if(this.carNumber!=0){
                let param = {
                    carNumber:this.carNumber
                }
                this.$api.erp.SingleCarInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }
                })
            }
        },

        //保存数据 type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            this.$api.erp.SaveCarInfo(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                    }
                    
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //清空数据
        btnClearData(){
            this.info={
                systemNumber:'',
                carNumber:'',
                departmentId:0,
                carCode:'',
                carName:'',
                userNumber:'',
            }
        },


        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.carNumber === this.carNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.carNumber = prevInfo.carNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.carNumber === this.carNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.carNumber = prevInfo.carNumber
                this.GetDataInfo()
            }
        },

    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style>

</style>