<template>
  <div>
    
    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" class="search-200"/>
                </el-form-item>

                <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
          </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
      <!-- 功能栏 -->
      <el-row :gutter="10" class="operation">
        <el-col :span="15">
          <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
          <el-popconfirm 
              title="确认要删除吗?"
              @confirm="btnBatchDeleteInfo">
                <template #reference>
                  <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
              </el-popconfirm>
        </el-col>

        <el-col :span="9" class="operation_hidden">
          <el-button-group>
                                            
              <el-tooltip
              class="box-item"
              effect="dark"
              content="流程图"
              placement="top">
              <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
              </el-tooltip>

              <el-tooltip
              class="box-item"
              effect="dark"
              content="打印"
              placement="top">
              <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
              </el-tooltip>

              <el-tooltip
              class="box-item"
              effect="dark"
              content="导出数据"
              placement="top">
              <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
              </el-tooltip>
          </el-button-group>  
          
        </el-col>
      </el-row>

      <!-- 列表 -->
      <el-table :data="dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="50" />  
        <el-table-column prop="departmentName" label="所属部门" />
        <el-table-column label="品牌">
          <template #default="scope">
            <span v-if="scope.row.brandId == 1">得力</span>
          </template>
        </el-table-column>
        <el-table-column prop="printerNumber" label="考勤机编号" />
        <el-table-column prop="printerName" label="考勤机名称" />
        <el-table-column prop="printerCode" label="识别码" />
        <el-table-column prop="state" label="状态" />
        <el-table-column label="操作" width="200" fixed="right">
          <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.brandId)">详情</el-button>
            <el-popconfirm 
              title="确认要删除吗?"
              @confirm="btnDeleteInfo(scope.row.brandId)">
                <template #reference>
                  <el-button size="small" type="danger">删除</el-button>
                </template>
              </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-container class="page_one">
          <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[50, 200, 500]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
          >
          </el-pagination>
      </el-container>

    </div>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="40%"
      :draggable="true"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditClockIn :editId="info.id" @closeBox="btnCloseBox"></EditClockIn>
    </el-dialog>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import EditClockIn from '@/views/Device/ClockIn/EditClockIn.vue'
export default {
    mixins:[UserDptList],
    components:{
      EditClockIn
    },
    data(){
        return{
            dataList:[],  //Table数据
            info:{
                page:1,   //页数
                size:50,  //单页记录数
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    selectDepartmentList:[],  //选中的部门列表
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'云考勤机'
            }
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){

        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.brandId)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增编辑部门信息
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "新增考勤机"
            }else{
                this.BoxEditInfo.title = "编辑考勤机"
            }  
            this.BoxEditInfo.isVisible = true;
        },

        //重置按钮
        btnResetSearch(){
            this.info.search = {
                selectDepartmentList:[],  //部门
            }
            this.GetDataList()
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

    }

}
</script>

<style>

</style>