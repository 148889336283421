<template>
  <!-- 新增备注记录功能 -->
  <div>
    <el-divider></el-divider>

      <el-form :model="info">
          <el-form-item label="类型">
              <el-radio-group v-model="info.type">
                <el-radio-button :value="1">文字</el-radio-button>
                <el-radio-button :value="2">图片</el-radio-button>
                <el-radio-button :value="3">文件</el-radio-button>
              </el-radio-group>
          </el-form-item>

          <!-- 文字 -->
          <el-form-item v-if="info.type == 1" label="内容">
              <el-input v-model="info.content" type="textarea"></el-input>
          </el-form-item>
          
          <!-- 图片、文件 都用标题 -->
          <el-form-item v-if="info.type == 2 || info.type == 3" label="标题" class="imgTitle">
              <el-input v-model="info.title"></el-input>
          </el-form-item>

          <el-form-item v-if="info.type == 2" label="图片">
              <!-- 上传图片 -->
              <el-upload class="avatar-uploader"
                :action="GetFileUrl('Record')"
                :headers="GetHeader"
                :show-file-list="false"
                :before-upload="BeforeUpload"
                :on-success="UploadSuccess"
                :on-error="UploadError">
                <img v-if="info.content" :src="info.content" class="avatar">
                <i v-else class="iconfont icon-add"></i>
              </el-upload>
          </el-form-item>

          <!-- 文件 -->
          <el-form-item v-if="info.type == 3" label="操作">
              <el-upload
              :action="GetFileUrl('Record')"
              :headers="GetHeader"
              :show-file-list="false"
              :before-upload="BeforeFileUpload"
              :on-progress="Uploading"
              :on-success="UploadSuccess"
              :on-error="UploadError">
                <el-button type="primary" class="iconfont icon-daochu">上传文件</el-button>
              </el-upload>
          </el-form-item>

          <!-- 文件 -->
          <el-form-item v-if="info.type == 3" label="链接">
              <el-link :href="info.content" target="_blank">{{info.title}}</el-link>
          </el-form-item>

      </el-form>
  </div>
</template>

<script>
import Authorize from '@/utils/authorize.js'
import { ElMessage } from 'element-plus';
import { mapState } from 'vuex';
export default {
    name:'NewRecord',
    data(){
        return{
            info:{
                id:0,
                systemNumber:'',
                billNumber:'',
                title:'',
                content:'',
                type:1,
                createTime: new Date(),
                creator:'',
                createName:'',
                isDel:0
            },
            fileList:[],
        }
    },
    computed:{
      // 获取当前用户名
      ...mapState('Master',['currentUserName']),
      //添加Token - 重要
      GetHeader(){
        return {
            "Authorization":"Bearer "+ Authorize.getToken()
        }
      },
    },
    methods:{
      //上传图片之前操作
      BeforeUpload(file){
        const fileType = file.type;
        const fileSize = file.size;
        
        if(fileType !== 'image/jpeg' && fileType !== 'image/png'){
          ElMessage.error('上传图片格式: jpeg | png')
          return false;
        }
        //文件大小
        if(fileSize/1024/1024 > 5){
          ElMessage.error('上传文件大小不能超过5Mb')
          return false;
        }

      },
      //上传过程中（图片、文件）通用
      Uploading(){
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        loading.close()
      },
      //上传完成（图片、文件）通用
      UploadSuccess(res,file){
        if(res.status === 200){
          this.info.content = res.data;
          this.info.title = file.name;
          ElMessage.success('上传成功');
        }
      },
      //上传失败（图片、文件）通用
      UploadError(e){
        ElMessage.error(e)
      },


      //上传文件之前操作
      BeforeFileUpload(file){
        const fileType = file.type;
        const fileSize = file.size;

        if(fileType !== 'application/pdf' && fileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
          ElMessage.error('上传文件格式: pdf | docx | xlsx')
          return false;
        }
        //文件大小
        if(fileSize/1024/1024 > 5){
          ElMessage.error('上传文件大小不能超过5Mb')
          return false;
        }
      },


    },
    mounted(){
      // 赋值默认创建者
      this.info.createName = this.currentUserName
    }
}
</script>

<style scoped>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-align: center;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 80px;
    height: 80px;
    display: block;
    border: solid 1px #ccc;
  }
  .icon-add {
    font-size: 40px;
    border: solid 1px #ccc;
    display: block;
    width: 80px;
    height: 60px;
    padding-top: 20px;
  }

  .imgTitle {width: 255px;}
</style>