<template>
  <div>

    <div class="skuBtnList">
        <el-button class="iconfont icon-add" type="primary" plain @click="AddRow">新增</el-button>
    </div>

    <!-- 列表 -->
    <el-table :data="customerInfo.invoiceList" border style="width: 100%">
        <el-table-column prop="skuName" label="企业名称">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.companyName"></el-input>
                <span v-else >{{scope.row.companyName}}</span>
            </template>
        </el-table-column>

        <el-table-column label="纳税人识别号">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.invoiceNumber"></el-input>
                <span v-else >{{scope.row.invoiceNumber}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="skuName" label="地址、电话">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.addressPhone"></el-input>
                <span v-else >{{scope.row.addressPhone}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="skuName" label="开户行、账号">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.accountNumber"></el-input>
                <span v-else >{{scope.row.accountNumber}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="130" fixed="right">
        <template v-slot="scope">
            <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
            <el-popconfirm 
            title="确认要删除吗?"
            @confirm="DeleteRow(scope.row.customerNumber,scope.row.id,scope.$index)">
                <template #reference>
                <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
        </template>
        </el-table-column>
    </el-table>


  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
export default {
    name:'TabCustomerInvoice',
    computed:{
        ...mapState('Erp',['customerInfo'])
    },
    methods:{
        //添加行
        AddRow(){
            let row = {
                id:0,
                systemNumber:'',
                customerNumber:this.customerInfo.info.customerNumber,
                companyName:'',
                invoiceNumber:'',
                addressPhone:'',
                accountNumber:'',
                receiveAddress:'',
                receivePhone:'',
                receiveMan:'',
                isDel:0,
                isEdit:1
            }
            this.customerInfo.invoiceList.push(row)
        },
        //编辑行
        EditRow(id){
            this.customerInfo.invoiceList = this.customerInfo.invoiceList.map(item=>{
                if(item.id === id){
                    item.isEdit = 1
                }                
                return item
            })
        },
        //删除行
        DeleteRow(customerNumber,id,index){
            this.customerInfo.invoiceList.splice(index,1)
            if(id !=0){
                let param = {
                    id:id
                }
                this.$api.erp.DeleteCustomerInvoice(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }
        }

    }
}
</script>

<style>

</style>