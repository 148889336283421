<template>
  <!-- 主动询价单列表 -->
  <div>    
      <!-- 搜索功能 -->
      <el-row :style="showSearch.search_hidden">
          <el-col :span="24">
              <el-form :inline="true">

                <el-form-item label="询价单号">
                  <el-input v-model="info.search.billNumber" class="search-200"></el-input>
                </el-form-item>

                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" class="search-200"/>
                </el-form-item>

                <el-form-item label="询价方式">
                    <el-select v-model="info.search.inquiryType" class="search-200">
                        <el-option :value="0" label="全部"></el-option>
                        <el-option :value="1" label="公开询价"></el-option>
                        <el-option :value="2" label="指定供应商"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="info.search.state" class="search-200">
                        <el-option :value="0" label="全部"></el-option>
                        <el-option :value="1" label="新询价单"></el-option>
                        <el-option :value="2" label="询价中"></el-option>
                        <el-option :value="3" label="已报价"></el-option>
                        <el-option :value="4" label="已关单"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="截止日期">
                    <el-date-picker
                        v-model="info.search.datePoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>

                <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

              </el-form>
          </el-col>
      </el-row>

      <!-- 主内容区 -->
      <div>
          <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
              
          </el-col>
          </el-row>

          <!-- 列表 -->
          <el-table :data="info.dataList" border stripe height="650" style="width: 100%" @select="btnCheckbox" ref="table"> 
              <el-table-column prop="id" type="selection" width="50" />  
              <el-table-column prop="billNumber" label="询价单号" width="170" />
              <el-table-column prop="departmentName" label="部门名称" />
              <el-table-column label="询价方式" >
                <template v-slot="scope">
                  <span v-if="scope.row.inquiryType == 1">公开询价</span>
                  <span v-if="scope.row.inquiryType == 2">指定供应商</span>
                </template>
              </el-table-column>
              <el-table-column prop="state" label="状态">
                  <template v-slot="scope">
                    <el-tag v-if="scope.row.state ==1">新询价单</el-tag>
                    <el-tag v-if="scope.row.state ==2">询价中</el-tag>
                    <el-tag v-if="scope.row.state ==3">已报价</el-tag>
                    <el-tag v-if="scope.row.state ==4" type="danger">已关单</el-tag>
                  </template>
              </el-table-column>
              <el-table-column prop="remark" label="备注" />   

              <el-table-column label="创建时间">
                <template v-slot="scope">
                  <span>{{DateFormat(scope.row.createTime)}}</span> 
                </template>
              </el-table-column>
              <el-table-column prop="areaNum" label="截止日期">
                <template v-slot="scope">
                    <span>{{DateFormat(scope.row.endTime)}}</span> 
                  </template>
              </el-table-column>
              
              <el-table-column label="操作" width="200" fixed="right">
              <template v-slot="scope">              
                  <el-button size="small" type="primary" @click="btnEditInfo(scope.row.billNumber)">详情</el-button>
                  <el-popconfirm 
                  title="确认要删除吗?"
                  @confirm="btnDeleteInfo(scope.row.billNumber)">
                      <template #reference>
                      <el-button size="small" type="danger">删除</el-button>
                      </template>
                  </el-popconfirm>
              </template>
              </el-table-column>
          </el-table>

          <!-- 分页 -->
          <el-container class="page_one">
              <el-pagination
                  v-model:currentPage="info.page"
                  v-model:page-size="info.size"
                  :page-sizes="[10, 50, 100, 200]"
                  :background="true"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="info.total"
                  @current-change="btnPagechange"
                  @size-change="btnSizechange">
              </el-pagination>
          </el-container>

      </div>

      <!-- 新增、编辑 弹出框 -->
      <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="60%"
      :draggable="true"
    :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditInquiryActive :billNumber="info.id" @closeBox="btnCloseBox"></EditInquiryActive>
      </el-dialog>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import EditInquiryActive from '@/views/Pms/Inquiry/EditInquiryActive.vue'
import UserDptList from '@/utils/mixins/UserDptList.js'
export default {
    name:'TabInquiryActive',
    components:{
      EditInquiryActive
    },
    mixins:[UserDptList],
    data(){
      return{
        info:{
          dataList:[],  //Table数据
          page:1,
          size:10,
          total:0,  //总记录数
          id:0,  //新增、编辑 的参数
          ids:[],   //删除 的参数
          search:{
            billNumber:'',   //询价单号
            selectDepartmentList:[],  //所属部门
            inquiryType:0,  //询价方式
            state:0,   //状态
            datePoint:'',   //截止日期
          }
        },
        //编辑-弹出框
        BoxEditInfo:{
          isVisible:false,
          title:'新增询价单'
        },

      }
    },    
    methods:{
      //加载数据列表
      GetDataList(){
        if(this.info.search.datePoint == null){
            this.info.search.datePoint = ''
        }

        //获取、转换部门
        let newDepartmentList = []
        if(this.info.search.selectDepartmentList!=null){
          newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
            return item
          })
        }

        let param = {
          billNumber:this.info.search.billNumber,
          departmentList:newDepartmentList,
          inquiryType:this.info.search.inquiryType,
          state:this.info.search.state,
          startDate:this.DateFormat(this.info.search.datePoint[0]),
          endDate:this.DateFormat(this.info.search.datePoint[1]),
          page:this.info.page,
          size:this.info.size
        }
        this.$api.inquiry.ListInquiry(param).then(res=>{
          if(res.data.status === 200){
            this.info.dataList = res.data.data;  //获取数据
            //分页
            this.info.total = res.data.total
            this.info.page = res.data.pageNumber
          }
        })
      },
      //选中多选框
      btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.billNumber)
      },
      //分页-页数变化时
      btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
      },
      //分页-单页记录数变化时
      btnSizechange(size){
        this.info.size = size
        this.info.page = 1
        this.GetDataList() //加载Table数据
      },
      //新增、编辑信息
      btnEditInfo(billNumber){
        this.info.id = billNumber
        if(billNumber == 0){
            this.BoxEditInfo.title = "新增询价单"
        }
        else{
            this.BoxEditInfo.title = "编辑询价单"
        }
        this.BoxEditInfo.isVisible = true;
      },
      //关闭弹出框
      btnCloseBox(){
          this.BoxEditInfo.isVisible = false;
      },

      
      //删除按钮
      btnDeleteInfo(billNumber){
        let param = [billNumber]
        this.$api.inquiry.DeleteInquiryAllInfo(param).then(res=>{
          if(res.data.status === 200){
              ElMessage({type:'success',message:res.data.msg})
              this.GetDataList()
          }
          else{
              ElMessage({type:'error',message:res.data.msg})
          }
        })
      },
      //批量删除
      btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
          ElMessage({type:'error',message:'请选择要删除的选项'})
          return false
        }

        this.$api.inquiry.DeleteInquiryAllInfo(this.info.ids).then(res=>{
          if(res.data.status === 200){
              ElMessage({type:'success',message:res.data.msg})
              this.GetDataList()
          }
          else{
              ElMessage({type:'error',message:res.data.msg})
          }
        })
      },

      //重置按钮
      btnResetSearch(){
        this.info.search = {
            billNumber:'',   //询价单号
            departmentList:[],  //所属部门
            inquiryType:0,  //询价方式
            state:0,   //状态
            datePoint:'',   //截止日期
        }
        this.GetDataList();
      }

    },
    mounted(){
      this.GetDataList()  //加载数据
    }
}
</script>

<style scoped>
  .operation {margin-bottom: 5px;}
  .operation .operation_hidden {text-align: right;}
  .page_one {float: right;margin-top: 5px;}
</style>