// 新增、编辑 部门信息页面
<template>
  <div>
    <el-divider></el-divider>
    
    <el-form :inline="true"
    v-model="info"
    label-width="80px"
    label-position="right">
        <el-row>
            <el-col :span="24">
                <el-form-item label="部门名称">
                    <el-input v-model="info.departmentName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="归属部门">
                    <el-tree-select v-model="info.pid" :data="treeDepartmentList" check-strictly :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="类型">
                    <el-select v-model.number="info.departmentType" class="search-200">
                        <el-option :value="1" label="公司"></el-option>
                        <el-option :value="2" label="部门"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="显示标识">
                    <el-select v-model="info.isShow" class="search-200">
                        <el-option label="隐藏" :value="0"></el-option>
                        <el-option label="显示" :value="1"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="排列顺序">
                    <el-input-number v-model.number="info.sort" class="search-200"></el-input-number>
                </el-form-item>
            </el-col>

        </el-row>

    </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
export default {
    name:'EditDepartment',
    props:['editId'],
    data(){
        return{
            info:{
                departmentId:0,
                departmentName:'',
                departmentType:2,
                pid:0,
                isShow:1,
                sort:0
            },
            treeDepartmentList:[]
        }
    },    
    methods:{
        //加载目录树
        GetTreeList(){
            let param = {
                isShow:-1
            }
            this.$api.system.GetTreeDptList(param).then(res=>{
                if(res.data.status === 200){
                    this.treeDepartmentList = res.data.data;
                    this.GetDataInfo()
                }
            }).catch(e=>{
                console.log(e.message)
            })
        },
        //加载数据
        GetDataInfo(){
            if(this.editId!=0){
                let param = {
                    departmentId:this.editId
                }
                this.$api.system.GetDepartmentInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }
                })
            }
            else
            {
                this.btnClearData();
            }
        },

        //保存信息  type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            this.$api.system.SaveDepartmentInfo(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //this.GetTreeList()
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData()
                    }
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            }).catch(e=>{
                console.log(e.message)
            })      
        },

        //清空数据
        btnClearData(){
            let newInfo ={
                departmentId:0,
                departmentName:'',
                departmentType:2,
                pid:this.info.pid,
                isShow:1,
                sort:0
            }
            this.info = newInfo;
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.GetTreeList();  //加载目录树
    }
}
</script>

<style>
    
</style>