<template>
  <div>
    <div class="skuBtnList" v-if="billInInfo.info && billInInfo.info.state <=2">
      <el-button class="iconfont icon-add" type="primary" plain @click="btnNewExtra">新增</el-button>
    </div>

    <!-- 附加成本项列表 -->
    <el-table :data="billInInfo.extraList" border stripe show-summary style="width: 100%">
        <el-table-column label="项目名称">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.itemName"></el-input>
                <span v-else>{{scope.row.itemName}}</span>
            </template>
        </el-table-column>        
        <el-table-column label="单价(元)">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.unitPrice" :precision="2" size="small" @change="OnChangeNum"></el-input-number>
                <span v-else >{{scope.row.unitPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column label="数量">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.amount" :min="0" :precision="2" size="small" @change="OnChangeNum"></el-input-number>
                <span v-else >{{scope.row.amount}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="totalPrice" label="总价(元)">
            <template v-slot="scope">
                <span>{{scope.row.totalPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="130" fixed="right">
        <template v-slot="scope" v-if="billInInfo.info && billInInfo.info.state <=2">
            <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
            <el-popconfirm 
            title="确认要删除吗?"
            @confirm="DeleteRow(scope.row.billNumber,scope.row.id,scope.$index)">
                <template #reference>
                <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
        </template>
        </el-table-column>
    </el-table>

    <div class="warningMsg">
        提示：有附加成本项的入库单不支持分批入库，因为不能分批计算附加成本。
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Erp',['billInInfo']),
    },
    methods:{
        //新增
        btnNewExtra(){
            let newRow = {
                id:0,
                systemNumber:'',
                billNumber:'',
                itemName:'',
                unitPrice:0,
                amount:1,
                totalPrice:this.myRound(0*1,2),
                isEdit:1,
            }
            this.billInInfo.extraList.push(newRow);
        },

        //编辑行
        EditRow(id){
            this.billInInfo.extraList = this.billInInfo.extraList.map(item=>{
                if(item.id === id){
                    item.isEdit = 1
                }
                return item
            })
        },

        //删除附加明细
        DeleteRow(billNumber, id, index){
            this.billInInfo.extraList.splice(index,1)
            if(id !=0){
                let param = {
                    billNumber:billNumber,
                    id:id
                }
                this.$api.wms.DeleteBillInExtra(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }
        },

        //计算单品总成本价
        OnChangeNum(){
            this.billInInfo.extraList = this.billInInfo.extraList.map(item => {
                if(item.isEdit==1){
                    item.totalPrice = this.myRound(item.unitPrice * item.amount,2)
                }
                return item;
            });
        },

    },
}
</script>

<style scoped>
.warningMsg {margin-left: 10px;font-size: 14px;color: #ee4b4b;}
</style>