<template>
  <div>
    <el-divider></el-divider>

    <!-- 车辆信息 -->
    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row>

            <el-col :span="24">
                <el-form-item label="店铺名称">
                    <el-input v-model="info.shopName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="省市区">
                    <el-cascader :options="areaList" clearable v-model="info.selectArea" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="详细地址">
                    <el-input v-model="info.address" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="状态">
                    <el-select v-model="info.state" class="search-200">
                        <el-option label="正常" :value="1"></el-option>
                        <el-option label="关闭" :value="0"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  props:['editId','dataList'],
  data(){
    return{
      info:{
        id:0,
        systemNumber:'',
        shopNumber:'',
        shopName:'',
        provinceCode:'',
        cityCode:'',
        areaCode:'',
        address:'',
        state:1,
        isDel:0,
        selectArea:[]
      },
      shopNumber:this.editId,  //当前编号
      areaList:[],   //地址列表
    }
  },
  methods:{

    //获取省市区列表
    GetAreaList(){
        this.$api.common.SelectTreeAreaList2().then(res=>{
            if(res.data.status === 200){
                this.areaList = res.data.data;
            }
        })
    },

    //获取数据信息
    GetDataInfo(){
      if(this.shopNumber!=0){
        let param = {
          shopNumber:this.shopNumber
        }
        this.$api.shop.SingleShopInfo(param).then(res=>{
          if(res.data.status == 200){
            this.info = res.data.data;
          }
        })
      }
    },

    //保存数据 type:1:保存并关闭 2:保存并新增
    btnSaveData(type){
      this.$api.shop.SaveShopInfo(this.info).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          if(type==1){
            this.btnClose();
          }else{
            this.btnClearData();
          }
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //清空数据
    btnClearData(){
      this.info = {
        id:0,
        systemNumber:'',
        shopNumber:'',
        shopName:'',
        provinceCode:'',
        cityCode:'',
        areaCode:'',
        address:'',
        state:1,
        isDel:0,
        selectArea:[]
      }
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

    //上一条
    btnPrevious(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.shopNumber === this.shopNumber
        })
        let prevIndex = currentIndex -1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有上一条了");
            return false;
        }
        else{
            this.shopNumber = prevInfo.shopNumber
            this.GetDataInfo()
        }
        
    },
    //下一条
    btnNext(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.shopNumber === this.shopNumber
        })
        let prevIndex = currentIndex + 1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有下一条了");
            return false;
        }
        else{
            this.shopNumber = prevInfo.shopNumber
            this.GetDataInfo()
        }
    },

  },
  mounted(){
    this.GetAreaList();
    this.GetDataInfo()
  }
}
</script>

<style>

</style>