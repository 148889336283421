<template>
  <div>

    <el-tabs v-model="info.MovePage">
        <el-tab-pane label="移出记录" name="tabMoveOut">
            <ListMoveOut></ListMoveOut>
        </el-tab-pane>

        <el-tab-pane label="移入记录" name="tabMoveIn">
            <ListMoveIn></ListMoveIn>
        </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import ListMoveOut from '@/views/Wms/Move/ListMoveOut.vue'  //移出记录
import ListMoveIn from '@/views/Wms/Move/ListMoveIn.vue'    //移入记录
export default {
    components:{
        ListMoveOut,
        ListMoveIn,
    },
    data(){
        return{
            info:{
                MovePage:'tabMoveOut',   //默认移出单表
            }
        }
    }
}
</script>

<style>

</style>