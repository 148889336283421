
//IP库
import ListIp from '@/views/Library/Ip/ListIp.vue'

export default[{
    //IP库
    name:'ListIp',
    path:'listip',
    component:ListIp
}]
