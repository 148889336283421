<template>
  <div>    
      <!-- 搜索功能 -->
      <el-row>
          <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="角色名称">
                  <el-input v-model="info.search.roleName" class="search-200"></el-input>
                </el-form-item>

                <el-form-item>
                  <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                  <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
            </el-col>
      </el-row>

      <div>
        <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
              <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
              <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                  <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                  </template>
                </el-popconfirm>
              
            </el-col>

            <el-col :span="9" class="operation_hidden">
              <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
                    </el-tooltip>
                </el-button-group>          
              
            </el-col>
          </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
          <el-table-column prop="roleId" type="selection" width="50" />  
          <el-table-column prop="roleName" label="角色名称" width="250" />
          <el-table-column prop="mark" label="备注" />
          <el-table-column prop="sort" label="排序"/>
          <el-table-column label="操作" width="130">
            <template v-slot="scope">
              <el-button size="small" type="primary" @click="btnEditInfo(scope.row.roleId)">详情</el-button>
              <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.roleId)">
                  <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                  </template>
                </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>

      </div>

      <!-- 页码 -->
      <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
      </el-affix>

      <!-- 新增、编辑 弹出框 -->
      <el-drawer
        :title="BoxEditInfo.title" 
        v-model="BoxEditInfo.isVisible"
        direction="ltr"
        size="85%"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @closed="GetDataList">
          <EditRole :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditRole>
      </el-drawer>

  </div>
</template>


<script>
import { ElMessage } from 'element-plus'
import EditRole from '@/views/system/Role/EditRole.vue'
export default {
  name:'ListRole',
  components:{
    EditRole,
  },
  data() {
      return {
        info:{
            dataList:[],  //Table数据
            page:1,   //页数
            size:50,  //单页记录数
            total:0,  //总记录数
            id:0,  //新增、编辑 的参数
            ids:[],   //删除 的参数
            search:{  //搜索条件
              roleName:''
            }
        },
        BoxEditInfo:{
            isVisible:false,  //新增、编辑 弹框
            title:"系统角色"
        },
      }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
          let param = {
            roleName:this.info.search.roleName,
            page:this.info.page,
            size:this.info.size
          }
          this.$api.system.PageSystemRoleList(param).then(res=>{
              if(res.data.status === 200){
                  this.info.dataList = res.data.data;  //获取数据
                  //分页
                  this.info.total = res.data.total
                  this.info.page = res.data.pageNumber
              }
          })
        },
        //选中多选框
        btnCheckbox(selection){
          this.info.ids = selection.map(item=>item.roleId)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        
        //新增编辑部门信息
        btnEditInfo(id){
          this.info.id = id
          if(id==0){
              this.BoxEditInfo.title = "新增角色"
          }else{
              this.BoxEditInfo.title = "编辑角色"
          }  
          this.BoxEditInfo.isVisible = true;
        },
        //删除按钮
        btnDeleteInfo(id){
          let param = [id]
          this.$api.system.DeleteRoleInfo(param).then(res=>{
            if(res.data.status === 200){
              ElMessage({type:'success',message:res.data.msg})
              this.GetDataList()
            }
            else{
              ElMessage({type:'error',message:res.data.msg})
            }
          })
        },
        //批量删除
        btnBatchDeleteInfo(){
          if(this.info.ids.length<1){
            ElMessage({type:'error',message:'请选择要删除的选项'})
            return false
          }

          this.$api.system.DeleteRoleInfo(this.info.ids).then(res=>{
            if(res.data.status === 200){
              ElMessage({type:'success',message:res.data.msg})
              this.GetDataList()
            }
            else{
              ElMessage({type:'error',message:res.data.msg})
            }
          })
        },
               
        //重置按钮
        btnResetSearch(){
          this.info.search.roleName='',
          this.GetDataList()
        },
        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //导出系统列表
        btnExportInfo(){
          ElMessage({type:'error',message:'此功能不支持导出'})
        },
        //授权权限
        btnAuthorize(roleId){
          this.info.id = roleId
          this.menuDialog.isVisible = true
        },
        //打开菜单弹框
        openMenuDialog(){
          this.$refs.treeInfo.GetDataInfo()
        },
        //保存菜单目录树
        btnSaveTreeData(){
          let menuList =[]
          //#region 参数组合
          const selectedKeys = this.$refs.treeInfo.GetSelectedKeys()
          selectedKeys.forEach(element => {
            let menu = {
              id:0,
              roleId:this.info.id,
              menuId:element,
              state:2
            }
            menuList.push(menu)
          });

          const halfSelectedKeys = this.$refs.treeInfo.GetHalfSelectedKeys()
          halfSelectedKeys.forEach(value=>{
            let menu = {
              id:0,
              roleId:this.info.id,
              menuId:value,
              state:1
            }
            menuList.push(menu)
          })
          //#endregion

          let param = {
            roleId:this.info.id,
            roleMenuList:menuList,
          }

          this.$api.system.SaveRoleMenu(param).then(res=>{
            if(res.data.status === 200){
              ElMessage.success(res.data.msg)
              this.menuDialog.isVisible = false
            }else{
              ElMessage.error(res.data.msg)
            }
          }).catch(e=>{
            console.log(e)
          })
        }

    },
    mounted(){
      this.GetDataList() //加载Table数据
    }

}
</script>

<style scoped>

</style>