<template>
  <div>
      <!-- 列表 -->
      <el-table :data="transportInfo.stateList" border style="width: 100%">
          <el-table-column prop="skuName" label="状态">
              <template v-slot="scope">
                  <span v-if="scope.row.state ==1">新建单</span>
                  <span v-if="scope.row.state ==2">已锁单</span>
                  <span v-if="scope.row.state ==3">已接单</span>
                  <span v-if="scope.row.state ==4">已完成</span>
              </template>
          </el-table-column>

          <el-table-column label="创建时间">
              <template v-slot="scope">
                  <span>{{DateTimeFormat(scope.row.createTime)}}</span>
              </template>
          </el-table-column>

          <el-table-column label="创建者">
              <template v-slot="scope">
                  <span>{{scope.row.createName}}</span>
              </template>
          </el-table-column>

      </el-table>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data(){
        return{
            dataList:[],  //数据列表
        }
    },
    computed:{
        ...mapState('Erp',['transportInfo'])
    },
}
</script>

<style>

</style>