<template>
  <div class="editInfo">
    <!-- 编辑采购单页 -->
    <el-divider></el-divider>
    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="采购单" name="tabPurchase">
            <TabPurchase ref="tabPurchase2"></TabPurchase>

            <el-divider>采购单明细</el-divider>
            <TabPurchaseDetail></TabPurchaseDetail>
            
        </el-tab-pane>

        <el-tab-pane label="入库记录" name="tabOutRecord">
            <TabInStock></TabInStock>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabPurchaseRecord></TabPurchaseRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabPurchaseState></TabPurchaseState>
        </el-tab-pane>

    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="purchaseInfo.info">
        <el-button v-if="CheckUserButtonAuth('BtnPurchaseCancel') && purchaseInfo.info.state == 4" type="danger" @click="btnCancelPurchase">撤单</el-button>
        <el-button type="primary" v-if="purchaseInfo.info.state ==1 || purchaseInfo.info.state ==3" @click="btnSaveData">保存</el-button>
        <el-button v-if="purchaseInfo.info.billNumber !='' && (purchaseInfo.info.state==1 || purchaseInfo.info.state == 3)" type="primary" @click="btnUpdateState(2)">提交审核</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnPurchaseAudit') && purchaseInfo.info.state == 2" type="warning" @click="BoxCheckState.isVisible = true">审核</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnPurchaseConfirm') && purchaseInfo.info.state == 7 " type="primary" @click="btnUpdateState(10)">确认完成</el-button>
        <el-button class="iconfont icon-dayin" v-if="purchaseInfo.info.state==10" @click="btnShowBoxPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 审核-弹框 -->
    <el-dialog title="采购单审核"
    v-model="BoxCheckState.isVisible"
    width="300px">
        <el-divider></el-divider>
        <div>采购单是否审核通过?</div>
        <template #footer>            
            <el-button type="primary" @click="btnUpdateState(4)">通过</el-button>
            <el-button type="danger" @click="btnUpdateState(3)">驳回</el-button>
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

    <!-- 打印框 -->
    <el-dialog title="打印预览"
    v-model="BoxPrint.isVisible"
    :draggable="true"
    :close-on-click-modal="false"
    width="80%">
        <PrintView v-if="BoxPrint.isVisible" @closeBox="btnClosePrint"></PrintView>
    </el-dialog>

  </div>
</template>

<script>
import TabPurchase from '@/views/Pms/Purchase/TabPurchase.vue'
import TabPurchaseDetail from '@/views/Pms/Purchase/TabPurchaseDetail.vue'
import TabPurchaseRecord from '@/views/Pms/Purchase/TabPurchaseRecord.vue'
import TabPurchaseState from '@/views/Pms/Purchase/TabPurchaseState.vue'
import TabInStock from '@/views/Pms/Purchase/TabInStock.vue'
import PrintView from '@/views/Pms/Purchase/PrintView.vue'

import { mapMutations, mapState } from 'vuex'
import moment from "moment";
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'

export default {
    props:['editId','dataList'],
    components:{
        TabPurchase,
        TabPurchaseDetail,
        TabInStock,
        TabPurchaseRecord,
        TabPurchaseState,
        PrintView,
    },
    data(){
        return{
            info:{
                activeName:'tabPurchase',
                billNumber:this.editId
            },
            //审核-弹出框
            BoxCheckState:{
                isVisible:false,
            },
            //打印框
            BoxPrint:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Purchase',['purchaseInfo'])
    },
    methods:{
        ...mapMutations('Purchase',['SetPurchaseInfo']),

        //加载数据信息
        GetDataInfo(){
            if(this.info.billNumber!=0){
                let param = {
                    billNumber:this.info.billNumber
                }
                this.$api.erp.SinglePurchaseAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetPurchaseInfo(res.data.data)
                    }
                    else
                    {
                        this.btnClearData();
                    } 
                    //加载
                    this.$refs.tabPurchase2.SelectShiftRecordList(); //班次列表
                    this.$refs.tabPurchase2.onLoadSetDpt();  //加载部门设置
                    this.$refs.tabPurchase2.SelectUserAreaList();    //加载用户关联的大区
                })
            }
            else
            {
                this.btnClearData();
                //加载
                this.$refs.tabPurchase2.SelectShiftRecordList(); //班次列表
                this.$refs.tabPurchase2.onLoadSetDpt();  //加载部门设置
                this.$refs.tabPurchase2.SelectUserAreaList();    //加载用户关联的大区
            }            
        },

        //保存-采购单信息
        btnSaveData(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            this.$api.erp.SavePurchaseAllInfo(this.purchaseInfo).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    //刷新页面
                    this.purchaseInfo.info.billNumber = res.data.data;
                    this.info.billNumber = res.data.data;
                    this.GetDataInfo();
                }else{
                    ElMessage.error(res.data.msg)
                }
            }).catch(e=>{
                console.log(e)
            })
        },

       
        //提交审核状态 
        btnUpdateState(state){   
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            let param = {
                billNumber:this.info.billNumber,
                state:state,
            }
            this.$api.erp.UpdatePurchaseState(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    // 更新
                    this.purchaseInfo.info.state = state
                    this.BoxCheckState.isVisible = false
                    //this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
            
        },

        //撤单
        btnCancelPurchase(){
            ElMessageBox.confirm('撤单将采购单状态变更为【新建单】,确认是否撤单','撤单警告',{
                confirmButtonText:'确认',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                let param = {
                    billNumber:this.purchaseInfo.info.billNumber
                }
                this.$api.erp.CancelPurchase(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        this.btnClose();
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }).catch(()=>{

            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.info.billNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.info.billNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
        },

        //清空数据
        btnClearData(){
            var newInfo = {
                    info:{
                        id:0,
                        systemNumber:'',
                        billNumber:'',
                        departmentId:this.userDepartmentId,
                        shiftNumber:'',
                        areaNumber:'',
                        areaName:'',
                        warehouseNumber:'',
                        supplierNumber:'',
                        tradeType:0,
                        tradeNumber:'',
                        tradeName:'',
                        billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        arriveTime:moment(new Date()).format("YYYY-MM-DD"),
                        totalPrice:0,
                        isTransport:0,
                        invoiceType:1,
                        state:1,
                        receiver:''
                    },
                    detailList:[],
                    recordList:[],
                    stateList:[]
                }
            this.SetPurchaseInfo(newInfo)
        },

        //打开-打印框
        btnShowBoxPrint(){
            this.BoxPrint.isVisible =true;
        },
        //关闭-打印框
        btnClosePrint(){
            this.BoxPrint.isVisible =false;        
        },

    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style>
</style>