<template>
  <div>
    <!-- 对账单明细 -->

    <!-- 销售单 -->
    <div v-for="allInfo in cycleBusinessInfo.saleAllInfoList" :key="allInfo.info.saleNumber" class="cycleDetail">
        <!-- 主信息 -->
        <el-descriptions
          title="销售单"
          :column="3"
          border
        >
          <template #extra>
            <el-button type="danger" @click="btnDeleteCycleBusinessOrigin(cycleBusinessInfo.info.cycleNumber,allInfo.info.saleNumber)">删除</el-button>
          </template>
          <el-descriptions-item label="销售单号">{{allInfo.info.saleNumber}}</el-descriptions-item>
          <el-descriptions-item label="部门名称">{{allInfo.info.departmentName}}</el-descriptions-item>
          <el-descriptions-item label="交易者名称">{{allInfo.info.tradeName}}</el-descriptions-item>
          <el-descriptions-item label="班次名称">{{allInfo.info.shiftName}}</el-descriptions-item>
          <el-descriptions-item label="记账日期">{{allInfo.info.billTime}}</el-descriptions-item>
          <el-descriptions-item label="总金额">{{allInfo.info.totalPrice}}</el-descriptions-item>
          <el-descriptions-item label="状态">
            <span v-if="allInfo.info.state ==1">新建单</span>
            <span v-if="allInfo.info.state ==2">待审核</span>
            <span v-if="allInfo.info.state ==3">已驳回</span>
            <span v-if="allInfo.info.state ==4">已审核</span>
            <span v-if="allInfo.info.state ==5">已出库</span>
            <span v-if="allInfo.info.state ==9">已取消</span>
            <span v-if="allInfo.info.state ==10">新建单</span>
            </el-descriptions-item>
        </el-descriptions>
        <!-- 明细列表 -->
        <el-table :data="allInfo.detailList" border stripe>
          <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
          <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
          <el-table-column prop="spuName" label="产品名称" show-overflow-tooltip min-width="90">
          </el-table-column>
          <el-table-column prop="skuName" label="规格名称" min-width="90"/>
          <el-table-column label="单位" min-width="100">
              <template v-slot="scope">
                  <span>{{scope.row.unitName}}</span>
              </template>
          </el-table-column>
          
          <el-table-column prop="unitPrice" label="销售单价(元)" min-width="120">
              <template v-slot="scope">
                  <span>{{scope.row.unitPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="planNum" label="计划数量" min-width="120">
              <template v-slot="scope">
                  <span>{{scope.row.planNum}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="realNum" label="出库数量" min-width="90"></el-table-column>

          <el-table-column prop="totalPrice" label="总价(元)" width="80">
              <template v-slot="scope">
                  <span>{{scope.row.totalPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column label="备注" min-width="90">
              <template v-slot="scope">
                  <span>{{scope.row.remark}}</span>
              </template>
          </el-table-column>
        </el-table>
    </div>

    <!-- 退货单 -->
    <div v-for="allInfo in cycleBusinessInfo.returnSaleAllInfoList" :key="allInfo.info.billNumber" class="cycleDetail">
        <!-- 主信息 -->
        <el-descriptions
          title="退货单"
          :column="3"
          border
        >
          <template #extra>
            <el-button type="danger" @click="btnDeleteCycleBusinessOrigin(cycleBusinessInfo.info.cycleNumber,allInfo.info.billNumber)">删除</el-button>
          </template>
          <el-descriptions-item label="退货单号">{{allInfo.info.billNumber}}</el-descriptions-item>
          <el-descriptions-item label="部门名称">{{allInfo.info.departmentName}}</el-descriptions-item>
          <el-descriptions-item label="交易者名称">{{allInfo.info.tradeName}}</el-descriptions-item>
          <el-descriptions-item label="班次名称">{{allInfo.info.shiftName}}</el-descriptions-item>
          <el-descriptions-item label="记账日期">{{allInfo.info.billTime}}</el-descriptions-item>
          <el-descriptions-item label="总金额">{{allInfo.info.totalPrice}}</el-descriptions-item>
          <el-descriptions-item label="状态">
            <span v-if="allInfo.info.state ==1">新建单</span>
            <span v-if="allInfo.info.state ==2">待审核</span>
            <span v-if="allInfo.info.state ==3">已驳回</span>
            <span v-if="allInfo.info.state ==4">已审核</span>
            <span v-if="allInfo.info.state ==5">已出库</span>
            <span v-if="allInfo.info.state ==9">已取消</span>
            <span v-if="allInfo.info.state ==10">新建单</span>
            </el-descriptions-item>
        </el-descriptions>
        <!-- 明细列表 -->
        <el-table :data="allInfo.detailList" border stripe>
          <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
          <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
          <el-table-column prop="spuName" label="产品名称" show-overflow-tooltip min-width="90">
          </el-table-column>
          <el-table-column prop="skuName" label="规格名称" min-width="90"/>
          <el-table-column label="单位" min-width="100">
              <template v-slot="scope">
                  <span>{{scope.row.unitName}}</span>
              </template>
          </el-table-column>
          
          <el-table-column prop="unitPrice" label="销售单价(元)" min-width="120">
              <template v-slot="scope">
                  <span>{{scope.row.unitPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="planNum" label="计划数量" min-width="120">
              <template v-slot="scope">
                  <span>{{scope.row.planNum}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="realNum" label="出库数量" min-width="90"></el-table-column>

          <el-table-column prop="totalPrice" label="总价(元)" width="80">
              <template v-slot="scope">
                  <span>{{scope.row.totalPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column label="备注" min-width="90">
              <template v-slot="scope">
                  <span>{{scope.row.remark}}</span>
              </template>
          </el-table-column>
        </el-table>
    </div>

    <!-- 采购单 -->
    <div v-for="allInfo in cycleBusinessInfo.purchaseList" :key="allInfo.info.billNumber" class="cycleDetail">
        <!-- 主信息 -->
        <el-descriptions
          title="采购单"
          :column="3"
          border
        >
          <template #extra>
            <el-button type="danger" @click="btnDeleteCycleBusinessOrigin(cycleBusinessInfo.info.cycleNumber,allInfo.info.billNumber)">删除</el-button>
          </template>
          <el-descriptions-item label="采购单号">{{allInfo.info.billNumber}}</el-descriptions-item>
          <el-descriptions-item label="部门名称">{{allInfo.info.departmentName}}</el-descriptions-item>
          <el-descriptions-item label="交易者名称">{{allInfo.info.tradeName}}</el-descriptions-item>
          <el-descriptions-item label="班次名称">{{allInfo.info.shiftName}}</el-descriptions-item>
          <el-descriptions-item label="记账日期">{{allInfo.info.billTime}}</el-descriptions-item>
          <el-descriptions-item label="总金额">{{allInfo.info.totalPrice}}</el-descriptions-item>
          <el-descriptions-item label="状态">
            <span v-if="allInfo.info.state ==1">新建单</span>
            <span v-if="allInfo.info.state ==2">待审核</span>
            <span v-if="allInfo.info.state ==3">已驳回</span>
            <span v-if="allInfo.info.state ==4">已审核</span>
            <span v-if="allInfo.info.state ==5">已出库</span>
            <span v-if="allInfo.info.state ==9">已取消</span>
            <span v-if="allInfo.info.state ==10">新建单</span>
            </el-descriptions-item>
        </el-descriptions>
        <!-- 明细列表 -->
        <el-table :data="allInfo.detailList" border stripe>
          <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
          <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
          <el-table-column prop="spuName" label="产品名称" show-overflow-tooltip min-width="90">
          </el-table-column>
          <el-table-column prop="skuName" label="规格名称" min-width="90"/>
          <el-table-column label="单位" min-width="100">
              <template v-slot="scope">
                  <span>{{scope.row.unitName}}</span>
              </template>
          </el-table-column>
          
          <el-table-column prop="unitPrice" label="销售单价(元)" min-width="120">
              <template v-slot="scope">
                  <span>{{scope.row.unitPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="planNum" label="计划数量" min-width="120">
              <template v-slot="scope">
                  <span>{{scope.row.planNum}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="realNum" label="出库数量" min-width="90"></el-table-column>

          <el-table-column prop="totalPrice" label="总价(元)" width="80">
              <template v-slot="scope">
                  <span>{{scope.row.totalPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column label="备注" min-width="90">
              <template v-slot="scope">
                  <span>{{scope.row.remark}}</span>
              </template>
          </el-table-column>
        </el-table>
    </div>

    <!-- 退供单 -->
    <div v-for="allInfo in cycleBusinessInfo.returnPurchaseList" :key="allInfo.info.billNumber" class="cycleDetail">
        <!-- 主信息 -->
        <el-descriptions
          title="退供单"
          :column="3"
          border
        >
          <template #extra>
            <el-button type="danger" @click="btnDeleteCycleBusinessOrigin(cycleBusinessInfo.info.cycleNumber,allInfo.info.billNumber)">删除</el-button>
          </template>
          <el-descriptions-item label="退供单号">{{allInfo.info.billNumber}}</el-descriptions-item>
          <el-descriptions-item label="部门名称">{{allInfo.info.departmentName}}</el-descriptions-item>
          <el-descriptions-item label="交易者名称">{{allInfo.info.tradeName}}</el-descriptions-item>
          <el-descriptions-item label="班次名称">{{allInfo.info.shiftName}}</el-descriptions-item>
          <el-descriptions-item label="记账日期">{{allInfo.info.billTime}}</el-descriptions-item>
          <el-descriptions-item label="总金额">{{allInfo.info.totalPrice}}</el-descriptions-item>
          <el-descriptions-item label="状态">
            <span v-if="allInfo.info.state ==1">新建单</span>
            <span v-if="allInfo.info.state ==2">待审核</span>
            <span v-if="allInfo.info.state ==3">已驳回</span>
            <span v-if="allInfo.info.state ==4">已审核</span>
            <span v-if="allInfo.info.state ==5">已出库</span>
            <span v-if="allInfo.info.state ==9">已取消</span>
            <span v-if="allInfo.info.state ==10">新建单</span>
            </el-descriptions-item>
        </el-descriptions>
        <!-- 明细列表 -->
        <el-table :data="allInfo.detailList" border stripe>
          <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
          <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
          <el-table-column prop="spuName" label="产品名称" show-overflow-tooltip min-width="90">
          </el-table-column>
          <el-table-column prop="skuName" label="规格名称" min-width="90"/>
          <el-table-column label="单位" min-width="100">
              <template v-slot="scope">
                  <span>{{scope.row.unitName}}</span>
              </template>
          </el-table-column>
          
          <el-table-column prop="unitPrice" label="销售单价(元)" min-width="120">
              <template v-slot="scope">
                  <span>{{scope.row.unitPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="planNum" label="计划数量" min-width="120">
              <template v-slot="scope">
                  <span>{{scope.row.planNum}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="realNum" label="出库数量" min-width="90"></el-table-column>

          <el-table-column prop="totalPrice" label="总价(元)" width="80">
              <template v-slot="scope">
                  <span>{{scope.row.totalPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column label="备注" min-width="90">
              <template v-slot="scope">
                  <span>{{scope.row.remark}}</span>
              </template>
          </el-table-column>
        </el-table>
    </div>


  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { mapState } from 'vuex'
export default {
    data(){
        return{
        }
    },
    computed:{
        ...mapState('Erp',['cycleBusinessInfo'])
    },
    methods:{
      //删除明细
      btnDeleteCycleBusinessOrigin(cycleNumber,originNumber){
        ElMessageBox.confirm('确定要删除该明细吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
              let param = {
                cycleNumber:cycleNumber,
                originNumber:originNumber,
              }
              this.$api.erp.DeleteCycleBusinessOrigin(param).then(res=>{
                if(res.data.status === 200){
                  ElMessage.success(res.data.msg);
                  //调用父级组件-刷新页面
                  this.$parent.GetDataInfo();
                }else{
                  ElMessage.error(res.data.msg);
                }
              })
            }).catch(()=>{

            })
        
      },
    },
}
</script>

<style scoped>
.cycleDetail{padding: 0px 10px 10px 10px;}
</style>