<template>
  <div>
    <!-- 选择产品 -->
    <div class="skuBtnList" v-if="returnSaleInfo.info && returnSaleInfo.info.state==1 && returnSaleInfo.info.originNumber=='' ">
      <el-button class="iconfont icon-add" type="primary" plain @click="BtnSelectSku">选择产品</el-button>
    </div>

    <!-- 销售列表 -->
    <el-table :data="returnSaleInfo.detailList" border stripe show-summary style="width: 100%">

        <el-table-column label="入库仓库" min-width="100">
              <template v-slot="scope">
                <el-select v-if="scope.row.isEdit==1" v-model="scope.row.warehouseNumber" @change="OnChangPlanNum">
                    <el-option v-for="warehouse in areaWhList" :key="warehouse.warehouseNumber" :label="warehouse.warehouseName" :value="warehouse.warehouseNumber" />
                </el-select>
                <span v-else>{{scope.row.warehouseName}}</span>
              </template>
          </el-table-column>
        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="100"/>
        <el-table-column prop="skuName" label="规格名称" min-width="100"/>
        <el-table-column label="单位" min-width="70">
            <template v-slot="scope">
                <el-select v-if="scope.row.isEdit==1 && returnSaleInfo.info.originNumber==''" v-model="scope.row.unitId"  size="small" @change="OnChangPlanNum">
                    <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
                </el-select>
                <span v-else>{{scope.row.unitName}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="costUnitPrice" label="成本单价(元)" min-width="120">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1 && returnSaleInfo.info.originNumber ==''" v-model="scope.row.costUnitPrice" :precision="2" size="small" ></el-input-number>
                <span v-else >{{scope.row.costUnitPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="unitPrice" label="退货单价(元)" min-width="120">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.unitPrice" :precision="2" size="small" @change="OnChangPlanNum"></el-input-number>
                <span v-else >{{scope.row.unitPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="planNum" label="计划数量" min-width="120">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.planNum" :min="0" :precision="2" size="small" @change="OnChangPlanNum"></el-input-number>
                <span v-else >{{scope.row.planNum}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="realNum" label="实际数量" min-width="100">
            <template v-slot="scope">
                <span>{{scope.row.realNum}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="totalPrice" label="总价(元)" width="80">
            <template v-slot="scope">
                <span>{{scope.row.totalPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column label="备注">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.remark"></el-input>
                <span v-else >{{scope.row.remark}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="130" fixed="right" v-if="returnSaleInfo.info && (returnSaleInfo.info.state ==1 || returnSaleInfo.info.state == 3)" >
            <template v-slot="scope">
                <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
                <el-popconfirm 
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.billNumber,scope.row.id,scope.$index)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>

    <!-- 选择产品 -->
    <el-dialog :title="BoxSkuInfo.title" 
        v-model="BoxSkuInfo.isVisible"
        width="70%"
        :destroy-on-close="true">
        <SelectAllSkuList ref="SelectSkuInfo"></SelectAllSkuList>
        <template #footer>
            <el-button type="primary" @click="btnSelectData">选择</el-button>
            <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import SelectAllSkuList from '@/components/common/SelectAllSkuList.vue'
import UserWhList from '@/utils/mixins/UserWhList.js'  //用户关联仓库

import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
export default {
  components:{
      SelectAllSkuList,
  },
  mixins:[UserWhList],
  data(){
    return{
      //选择产品-弹出框
      BoxSkuInfo:{
        isVisible:false,
        title:'选择产品'
      },
      areaWhList:[],    //大区关联的仓库列表 
    }
  },
  computed:{
    ...mapState('Erp',['returnSaleInfo'])
  },
  methods:{

    //选择产品
    BtnSelectSku(){
        this.onGetWhListByAreaNumber(1);
        this.BoxSkuInfo.isVisible = true
    },

    //保存选中产品
    btnSelectData(){
        let itemList = this.$refs.SelectSkuInfo.selectedItem
        if(itemList !=null && itemList.length>0){
            itemList.forEach(item => {
                let newRow = {
                    id:0,
                    systemNumber:this.returnSaleInfo.info.systemNumber,
                    billNumber:this.returnSaleInfo.info.billNumber,
                    warehouseNumber:'',
                    warehouseName:'',
                    cid:item.cid,
                    cidName:item.cidName,
                    brandId:item.brandId,
                    brandName:item.brandName,
                    spuNumber:item.spuNumber,
                    spuName:item.spuName,
                    skuNumber:item.skuNumber,
                    skuName:item.skuName,
                    unitId:item.defUnitId,   //默认单位
                    unitName:item.defUnitName,
                    costUnitPrice:0,   //成本单价
                    unitPrice:0,    //原销售单价
                    planNum:1,
                    realNum:0,
                    costTotalPrice:0,   //成本总价
                    totalPrice:0,       //原销售总价
                    remark:'',
                    isDel:0,
                    isEdit:1,
                    skuUnitList:item.skuUnitList,   //单位列表
                }

                setTimeout(()=>{
                    this.returnSaleInfo.detailList.push(newRow)
                },500);
                
            });
        }
        this.BoxSkuInfo.isVisible = false  //关闭选择产品弹出框
    },

    //编辑行
    EditRow(id){
        this.returnSaleInfo.detailList = this.returnSaleInfo.detailList.map(item=>{
            if(item.id === id){
                item.isEdit = 1;
                //#region 加载单位列表
                let param = {
                    skuNumber:item.skuNumber
                }
                this.$api.goods.GetSkuUnitList(param).then(res=>{
                    if(res.data.status === 200){
                        item.skuUnitList = res.data.data;
                    }
                }) 
                //#endregion
            }
            return item
        })
        
    },

    //删除销售明细
    DeleteRow(billNumber, id, index){
        this.returnSaleInfo.detailList.splice(index,1)
        if(id !=0){
            let param = {
                billNumber:billNumber,
                id:id
            }
            this.$api.erp.DeleteReturnSaleDetail(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        }
        //重新计算价格
        setTimeout(()=>{
            this.OnChangeRealNum();
        },500)
        
    },

    //计划数量改变时
    OnChangPlanNum(){
        let newtotalPrice = 0;   //主表-总价
        this.returnSaleInfo.detailList = this.returnSaleInfo.detailList.map(item=>{

            //获取成本单价：只有自建的退货单才能重新获取成本单价。
            if(this.returnSaleInfo.info.originNumber=='' && item.warehouseNumber!=''){
                this.ResultCostUnitPrice(item);
            }
            //成本总价
            item.costTotalPrice = this.myRound(item.costUnitPrice * item.planNum,5);
            //销售总价
            item.totalPrice = this.myRound(item.unitPrice * item.planNum,5);
            newtotalPrice += item.totalPrice;            
            return item
        })
        this.returnSaleInfo.info.totalPrice = this.myRound(newtotalPrice,5)  //更新主表-总价
    },

    //计算成本价
    async ResultCostUnitPrice(item){
        //#region 单位发生变化时-重新计算价格、库存
        let param2 = {
            warehouseNumber:item.warehouseNumber,
            skuNumber:item.skuNumber,
            unitId:item.unitId
        }
        await this.$api.wms.GetSkuUnitStockInfo(param2).then(res=>{
            if(res.data.status === 200){
                item.costUnitPrice = res.data.data.unitPrice;  //成本价
            }
        })
        //#endregion
        return item;
    },

    //加载指定大区下的仓库列表 type: 1:清空仓库选项 2:保留仓库选项
    onGetWhListByAreaNumber(type){ 
        if(this.returnSaleInfo.info.areaNumber!=''){
            let param = {
                areaNumber:this.returnSaleInfo.info.areaNumber,
            }
            this.$api.wms.GetWhListByAreaNumber(param).then(res=>{
                if(res.data.status === 200){
                    this.areaWhList = res.data.data;
                }else{
                    this.areaWhList = [];
                }
            })
        }else{
            this.areaWhList = [];  
        }  

        //清空仓库选项
        if(type==1){
            this.returnSaleInfo.detailList = this.returnSaleInfo.detailList.map(item=>{
                item.warehouseNumber = '';
                return item
            })
        }
    },


  },

}
</script>

<style>

</style>