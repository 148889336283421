<template>
  <div>
    <!-- 财务单列表 -->
    <el-table :data="cycleInfo.financeList" border stripe >

      <el-table-column label="财务单号" width="158">
          <template v-slot="scope">
              <span>{{scope.row.billNumber}}</span>
          </template>
      </el-table-column>

      <el-table-column prop="departmentName" label="部门名称" width="150">
          <template v-slot="scope">
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="scope.row.departmentName"
                  placement="top"
              >
              <div class="cellOverFlow">{{scope.row.departmentName}}</div>
            </el-tooltip>
          </template>
      </el-table-column>

      <el-table-column prop="billTypeName" label="财务类型" min-width="100"></el-table-column>
      <el-table-column label="交易者类型" min-width="100">
          <template #default="scope">
              <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
              <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
              <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
              <el-tag v-if="scope.row.tradeType ==4" type="warning">散客</el-tag>
          </template>
      </el-table-column>
      <el-table-column prop="tradeName" label="交易者名称" width="150"></el-table-column>  

      <el-table-column label="会计科目" min-width="140">
          <template #default="scope">
              <el-tooltip
              class="box-item"
              effect="dark"
              :content="scope.row.subFullName"
              placement="top"
            >
            <div class="cellOverFlow">{{scope.row.subName}}</div>
            </el-tooltip>
          </template>
      </el-table-column>

      <el-table-column prop="totalPrice" label="交易金额(元)" width="110"></el-table-column>
      <el-table-column label="状态" width="100">
          <template #default="scope">
              <el-tag v-if="scope.row.state ==1">新财务单</el-tag>
              <el-tag v-if="scope.row.state ==2" type="danger">已驳回</el-tag>
              <el-tag v-if="scope.row.state ==3" type="warning">审核通过并上报</el-tag>
              <el-tag v-if="scope.row.state ==4" type="success">审核已完成</el-tag>
              <el-tag v-if="scope.row.state ==5" type="info">已结款</el-tag>
              <el-tag v-if="scope.row.state ==6" type="info">已结算</el-tag>
              <el-tag v-if="scope.row.state ==10">已关单</el-tag>
          </template>
      </el-table-column>

      <el-table-column label="备注" width="100">
          <template v-slot="scope">
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="scope.row.remark"
                  placement="top">
                  <div class="cellOverFlow">{{scope.row.remark}}</div>
              </el-tooltip>
          </template>
      </el-table-column>

      <el-table-column label="创建时间" width="110">
          <template #default="scope">
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="scope.row.createTime"
                  placement="top"
              >
              <div class="cellOverFlow">{{DateFormat(scope.row.createTime)}}</div>
            </el-tooltip>
          </template>
      </el-table-column>

      <el-table-column label="操作" width="80" fixed="right">
        <template v-slot="scope">
            <el-button size="small" type="primary" @click="btnOpenFinanceBox(scope.row.billNumber)">详情</el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- 财务单详情-弹框 -->
    <el-dialog title="财务单详情"
    v-model="BoxFinance.isVisible"
    width="85%"
    :draggable="true">
        <EditFinance :editId="info.id" :dataList="cycleInfo.financeList" @closeBox="btnCloseFinanceBox"></EditFinance>
    </el-dialog>

  </div>
</template>

<script>
import EditFinance from '@/views/Fms/Finance/EditFinance.vue'
import { mapState } from 'vuex'
export default {
  components:{
    EditFinance,
  },
  data(){
    return{
      info:{
        id:0,
      },
      //财务单详情-弹框
      BoxFinance:{
        isVisible:false
      },
    }
  },
  computed:{
      ...mapState('Finance',['cycleInfo'])
  },
  methods:{
    //打开弹框
    btnOpenFinanceBox(billNumber){
      this.info.id = billNumber;  //财务单号
      this.BoxFinance.isVisible=true;
    },
    //关闭弹框
    btnCloseFinanceBox(){
      this.BoxFinance.isVisible = false;
    },
  }

}
</script>

<style>

</style>