<template>
  <div>
    <!-- 主信息 -->
    <el-form :inline="true"
    v-model="returnPurchaseInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="returnPurchaseInfo.info">

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="退供单号">
                    <el-input v-model="returnPurchaseInfo.info.billNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属部门">
                    <el-tree-select v-model="returnPurchaseInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200" v-if="returnPurchaseInfo.info.originNumber=='' "/>
                    <el-input v-else v-model="returnPurchaseInfo.info.departmentName" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属大区">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>所属大区</span>
                    </template>
                    <el-select v-model="returnPurchaseInfo.info.areaNumber" class="search-200" v-if="returnPurchaseInfo.info.state==1 && returnPurchaseInfo.info.originNumber==''">
                        <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                    </el-select>
                    <el-input v-else v-model="returnPurchaseInfo.info.areaName" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属班次">
                    <el-select v-model="returnPurchaseInfo.info.shiftNumber" class="search-200" clearable>
                        <el-option v-for="shiftInfo in shiftRecordList" :key="shiftInfo.shiftNumber" :label="shiftInfo.shiftName" :value="shiftInfo.shiftNumber"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="交易者">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>交易者</span>
                    </template>
                    <el-select v-model="returnPurchaseInfo.info.tradeNumber" filterable class="search-200" v-if="returnPurchaseInfo.info.originNumber=='' ">
                        <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                    </el-select>
                    <el-input v-else v-model="returnPurchaseInfo.info.tradeName" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="记账时间">
                    <el-date-picker v-model="returnPurchaseInfo.info.billTime" type="datetime" placeholder="记账时间" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="原始单号">
                    <el-input v-model="returnPurchaseInfo.info.originNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="物流标识">
                    <el-select v-model.number="returnPurchaseInfo.info.isTransport" disabled class="search-200">
                        <el-option :value="0" label="未使用"></el-option>
                        <el-option :value="1" label="已使用"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="总价">
                  <el-input v-model="returnPurchaseInfo.info.totalPrice" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="状态">
                  <el-tag v-if="returnPurchaseInfo.info.state ==1">新建单</el-tag>
                  <el-tag v-if="returnPurchaseInfo.info.state ==2">待审核</el-tag>
                  <el-tag v-if="returnPurchaseInfo.info.state ==3" type="danger">已驳回</el-tag>
                  <el-tag v-if="returnPurchaseInfo.info.state ==4" type="warning">已审核</el-tag>
                  <el-tag v-if="returnPurchaseInfo.info.state ==5" type="success">已出库</el-tag>
                  <el-tag v-if="returnPurchaseInfo.info.state ==10" type="info">已完成</el-tag>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="制单人">
                    <el-input v-model="returnPurchaseInfo.info.createName" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

            
        </el-row>
    </el-form>

    
  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import PartTradeList from '@/utils/mixins/PartTradeList.js'  //交易者列表
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList,PartTradeList],
    
    data(){
        return{
            shiftRecordList:[],  //班次列表
            userAreaList:[],   //用户关联大区列表
        }
    },
    computed:{
        ...mapState('Erp',['returnPurchaseInfo'])
    },
    methods:{

        //获取班次列表
        SelectShiftRecordList(){
            this.$api.erp.GetShiftRecordList().then(res=>{
                if(res.data.status === 200){
                    this.shiftRecordList = res.data.data;
                    //新增时，默认加载第一个 
                    if(this.shiftRecordList.length>0 && this.returnPurchaseInfo.info.billNumber==''){
                        this.returnPurchaseInfo.info.shiftNumber = this.shiftRecordList[0].shiftNumber;
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //加载用户关联的大区列表
        SelectUserAreaList(){
            this.$api.system.SelectUserAreaList().then(res=>{
                if(res.data.status === 200){
                    this.userAreaList = res.data.data;
                    //新增时，默认加载第一个大区
                    if(this.userAreaList.length>0 && this.returnPurchaseInfo.info.billNumber==''){
                        this.returnPurchaseInfo.info.areaNumber = this.userAreaList[0].areaNumber;
                        
                    }
                }else{
                    this.userAreaList = [];
                }
            })
        },


    },
    mounted(){
    },

}
</script>

<style>

</style>