<template>
  <div>
    <!-- 单日数据汇总 -->
    <!-- 标题 -->
    <el-row>
        <el-col :span="24" class="title">
            <h3>单日数据统计图</h3>
        </el-col>        
    </el-row>

    <el-row>
        <el-col :span="24" class="title">
            日期：<el-date-picker
                    v-model="currentDate"
                    :editable="false"
                    :clearable="false"
                    type="date"
                    size="default"
                    @change="LoadDataView"
                />
        </el-col>
    </el-row>


    <el-row :gutter="20">

         <!-- 采购 -->
        <el-col :span="8">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>采购统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>采购额：<span class="mainPrice">564613.46</span>元</div>
                    <div>采购单数：<span class="mainPrice">564</span>单</div>
                </div>      
            </el-card>
        </el-col>

        <!-- 批发 -->
        <el-col :span="8">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>批发统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>销售额：<span class="mainPrice">564613.46</span>元</div>
                    <div>销售单数：<span class="mainPrice">564</span>单</div>
                    <div>毛利润：<span class="mainPrice">64613.46</span>元</div>
                    <div>毛利率：<span class="mainPrice">24.36</span>%</div>
                </div>     
            </el-card>
        </el-col>

        <!-- 加工 -->
        <el-col :span="8">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>加工统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <el-progress type="circle" :width="200" :stroke-width="20" :percentage="90" />
                </div>        
            </el-card>
        </el-col>

        <!-- 加工 -->
        <el-col :span="8">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>财务统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>收款：<span class="mainPrice">23313.12</span>元</div>
                    <div>付款：<span class="mainPrice">13223.11</span>元</div>
                    <div>财务单：<span class="mainPrice">564</span>单</div>
                    <div>未结款：<span class="mainPrice">156</span>单</div>
                    <div>已结款：<span class="mainPrice">86</span>单</div>
                </div>        
            </el-card>
        </el-col>

        <!-- 零售 -->
        <el-col :span="8">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>零售统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>销售额：<span class="mainPrice">564613.46</span>元</div>
                    <div>销售单数：<span class="mainPrice">564</span>单</div>
                    <div>毛利润：<span class="mainPrice">64613.46</span>元</div>
                    <div>毛利率：<span class="mainPrice">24.36</span>%</div>
                </div>        
            </el-card>
        </el-col>

        <!-- 电商 -->
        <el-col :span="8">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>电商统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>销售额：<span class="mainPrice">564613.46</span>元</div>
                    <div>销售单数：<span class="mainPrice">564</span>单</div>
                    <div>毛利润：<span class="mainPrice">64613.46</span>元</div>
                    <div>毛利率：<span class="mainPrice">24.36</span>%</div>
                </div>        
            </el-card>
        </el-col>


    </el-row>

    

  </div>
</template>

<script>
import moment from 'moment'
export default {
    data(){
        return{
            currentDate:moment(new Date()).format("YYYY-MM-DD"),
        }
    }
}
</script>

<style scoped>
.box-card{margin-top: 20px;}
.cardMain{height: 200px;}
.title{text-align: center;}
.mainPrice{color: #cc0033;font-size: 25px;}
.dataSource{margin-left: 140px;font-size: 14px;color: #ccc;}

</style>