<template>
  <div>
    
    <div class="skuBtnList btnList">
        <el-button class="iconfont icon-add" type="primary" plain @click="AddRecordFont">文字</el-button>
        <el-upload style="margin-left:10px"
            :action="GetFileUrl('Record')"
            :headers="GetHeader"
            :show-file-list="false"
            multiple
            :before-upload="BeforeUpload"
            :on-success="UploadSuccess"
            :on-error="UploadError">
                <el-button class="iconfont icon-add" type="primary" plain>上传文件</el-button>
            </el-upload>
    </div>

    <!-- 列表 -->
    <el-table :data="businessInfo.recordList" border style="width: 100%">

        <el-table-column prop="skuName" label="备注类型">
            <template v-slot="scope">
                <span v-if="scope.row.type == 1">文字</span>
                <span v-if="scope.row.type == 2">图片</span>
                <span v-if="scope.row.type == 3">文件</span>
                <span v-if="scope.row.type == 4">视频</span>
            </template>
        </el-table-column>

        <el-table-column prop="skuName" label="备注内容">
            <template v-slot="scope">
                <!-- 文字 -->
                <el-input v-if="scope.row.id ==0 && scope.row.type ==1" v-model="scope.row.content"></el-input>
                <span v-if="scope.row.id !=0 && scope.row.type==1" >{{scope.row.content}}</span>
                <!-- 图片 -->
                <el-image v-if="scope.row.type==2"
                    style="width: 70px; height: 70px;border:1px solid #ccc;"
                    :src="scope.row.content"
                    :preview-src-list="businessInfo.imgList"
                    :initial-index="scope.$index"
                    :hide-on-click-modal="true"
                    :infinite="false"
                    fit="cover"
                    />
                <!-- 文件 -->
                <el-link v-if="scope.row.type==3" :href="scope.row.content" target="_blank">{{scope.row.title}}</el-link>
            </template>
        </el-table-column>

        <el-table-column prop="createName" label="创建者">
            <template v-slot="scope">
                <span>{{scope.row.createName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="createName" label="创建时间">
            <template v-slot="scope">
              <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="130" fixed="right">
        <template v-slot="scope">
            <el-popconfirm 
            title="确认要删除吗?"
            @confirm="DeleteRow(scope.row.billNumber,scope.row.id,scope.$index)">
                <template #reference>
                <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
        </template>
        </el-table-column>
    </el-table>
    

  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from "moment";
import { ElMessage } from 'element-plus';
import Authorize from '@/utils/authorize.js'
export default {
    data(){
        return{
            //备注-弹出框
            BoxRecord:{
                isVisible:false,
                title:'备注信息'
            }
        }
    },
    computed:{
        ...mapState('Erp',['businessInfo']),
        // 获取当前用户名
        ...mapState('Master',['currentUserName']),
        //添加Token - 重要
        GetHeader(){
            return {
                "Authorization":"Bearer "+ Authorize.getToken()
            }
        },
    },
    methods:{
        //新增备注文字
        AddRecordFont(){
            let recordInfo = {
                id:0,
                systemNumber:'',
                billNumber:this.businessInfo.info.originNumber,
                billType:this.businessInfo.info.originType,  //原始单据类型
                title:'',
                content:'', //内容
                type:1,  //文字类型
                createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                creator:'',
                createName:this.currentUserName,
                isdel:0
            }
            this.businessInfo.recordList.push(recordInfo);            
        },
        
        //删除行
        DeleteRow(billNumber,id,index){
            this.businessInfo.recordList.splice(index,1);
            if(id!=0){
                let param = {
                    billNumber:billNumber,
                    id:id
                }
                this.$api.erp.DeleteRecord(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }
        },


        //上传图片之前操作
        BeforeUpload(file){
            const fileType = file.type;
            const fileSize = file.size;
            if(fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'application/pdf' && fileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                ElMessage.error('上传格式: jpeg | png | pdf | docx | xlsx')
                return false;
            }
            //文件大小
            if(fileSize/1024/1024 > 5){
                ElMessage.error('上传文件大小不能超过5Mb')
                return false;
            }

        },
        //上传完成（图片、文件）通用
        UploadSuccess(res,file){
            if(res.status === 200){
                const fileExt = res.data.slice((res.data.lastIndexOf(".") - 1 >>> 0) + 2); //文件类型
                let fileType = 2; //默认图片类型
                if(fileExt =="docx" || fileExt=="pdf" || fileExt == "xlsx"){
                    fileType = 3;  //文件类型
                }
                //创建新记录
                let newRow = {
                    id:0,
                    systemNumber:'',
                    billNumber:this.businessInfo.info.originNumber,  //原始单据编号
                    billType:this.businessInfo.info.originType,  //原始单据类型
                    title:file.name,
                    content:res.data,
                    type:fileType,  //类型
                    createName:this.currentUserName,  //创建者 
                    createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),  //创建时间
                }
                this.businessInfo.recordList.push(newRow);  
                ElMessage.success('上传成功');               
            }
        },
        //上传失败（图片、文件）通用
        UploadError(e){
            ElMessage.error(e)
        },

    },

}
</script>

<style scoped>
.btnList{display: flex;justify-content: flex-start;}
</style>