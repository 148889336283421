
/**
 * 询价单模块接口
 */

 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const inquiry = {

    //#region 询价单

    //询价单列表
    ListInquiry(param){
        return axios.post(`${base.base}/Inquiry/ListInquiry`,param)
    },

    //获取单个询价单（所有信息）
    SingleInquiryAllInfo(param){
        return axios.get(`${base.base}/Inquiry/SingleInquiryAllInfo`,{
            params:param
        })
    },

    //保存询价单信息（所有信息）
    SaveInquiryAllInfo(param){
        return axios.post(`${base.base}/Inquiry/SaveInquiryAllInfo`,param)
    },

    //删除询价单信息(所有信息)
    DeleteInquiryAllInfo(param){
        return axios.post(`${base.base}/Inquiry/DeleteInquiryAllInfo`,param)
    },

    //#endregion

    //#region 询价单明细

    //删除询价单明细
    DeleteInquiryDetail(param){
        return axios({
            url:`${base.base}/Inquiry/DeleteInquiryDetail`,
            method:'post',
            params:param
        })
    },

    //#endregion

    //#region 询价单供应商列表

    //删除询价单供应商
    DeleteInquirySupplier(param){
        return axios({
            url:`${base.base}/Inquiry/DeleteInquirySupplier`,
            method:'post',
            params:param
        })
    },

    //#endregion

    //#region 询价单-询价结果列表

    //保存询价单结果
    SaveInquiryResult(param){
        return axios.post(`${base.base}/Inquiry/SaveInquiryResult`,param)
    },

    //删除询价单-结果


    //#endregion

 }

 export default inquiry