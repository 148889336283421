<template>
  <div>

    <el-form :inline="true"
    v-model="workInfo.info"
    label-width="110px"
    label-position="right">
        <el-row v-if="workInfo.info">

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属部门">
                    <el-tree-select v-model="workInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200" v-if="workInfo.info.state==1 || workInfo.info.state == 3" />
                    <el-input v-model="workInfo.info.departmentName" v-else disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="加工名称">
                    <el-input v-model="workInfo.info.workName" class="search-200" v-if="workInfo.info.state==1 || workInfo.info.state == 3"></el-input>
                    <el-input v-model="workInfo.info.workName" class="search-200" v-else disabled></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="加工组">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>加工组</span>
                    </template>
                    <el-select v-model="workInfo.info.workGroupNumber" class="search-200" v-if="workInfo.info.state==1 || workInfo.info.state == 3">
                        <el-option v-for="groupInfo in selectWorkGroupList" :key="groupInfo.workGroupNumber" :label="groupInfo.workGroupName" :value="groupInfo.workGroupNumber"></el-option>
                    </el-select>
                    <el-input v-model="workInfo.info.workGroupName" class="search-200" disabled v-else></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属大区">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>所属大区</span>
                    </template>
                    <el-select v-model="workInfo.info.areaNumber" class="search-200" @change="UpdateAreaWhList" v-if="workInfo.info.state==1 || workInfo.info.state == 3">
                        <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                    </el-select>
                    <el-input v-model="workInfo.info.areaName" class="search-200" v-else disabled></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="加工开始时间">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>加工开始时间</span>
                  </template>
                    <el-date-picker v-model="workInfo.info.startTime" type="datetime" value-format="YYYY-MM-DD HH:mm:ss" disabled style="width:200px"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="加工结束时间">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>加工结束时间</span>
                  </template>
                    <el-date-picker v-model="workInfo.info.endTime" type="datetime" value-format="YYYY-MM-DD HH:mm:ss" disabled style="width:200px"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="加工时长">
                    <el-input v-model="workInfo.info.timeDiff" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="出成率">
                    <el-input-number v-model="workInfo.info.successRate" :min="0" :max="1" :precision="2" :step="0.01" disabled class="search-200"></el-input-number>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="状态">
                  <el-tag v-if="workInfo.info.state ==1">新建单</el-tag>
                  <el-tag v-if="workInfo.info.state ==2" type="warning">待审核</el-tag>
                  <el-tag v-if="workInfo.info.state ==3" type="danger">已驳回</el-tag>
                  <el-tag v-if="workInfo.info.state ==4">已审核</el-tag>
                  <el-tag v-if="workInfo.info.state ==5">加工中</el-tag>
                  <el-tag v-if="workInfo.info.state ==6">待入库</el-tag>
                  <el-tag v-if="workInfo.info.state ==7" type="success">已完成</el-tag>
                  <el-tag v-if="workInfo.info.state ==10">已关单</el-tag>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import { mapState } from 'vuex'
export default {
  mixins:[UserDptList],
  data(){
    return{
      selectWorkGroupList:[],  //加工组列表
      userAreaList:[],    //用户关联的大区
    }
  },
  computed:{
    ...mapState('Erp',['workInfo'])
  },
  methods:{

    //加载用户关联的大区列表
    SelectUserAreaList(){
        this.$api.system.SelectUserAreaList().then(res=>{
            if(res.data.status === 200){
                this.userAreaList = res.data.data;
                //新增时，默认加载第一个大区
                if(this.userAreaList.length>0 && this.workInfo.info.workNumber==''){
                    this.workInfo.info.areaNumber = this.userAreaList[0].areaNumber;
                }
            }else{
                this.userAreaList = [];
            }
        })
    },

    //获取加工组列表
    GetWorkGroupList(){
        let param = {
            departmentList:null
        }
        this.$api.erp.GetWorkGroupList(param).then(res=>{
            if(res.data.status === 200){
                this.selectWorkGroupList = res.data.data;
            }
        })
    },

    //当大区发生改变时-更新大区关联的仓库
    UpdateAreaWhList(){
        this.$emit('OnChangeArea');
    },

  },
  mounted(){
    this.SelectUserAreaList();   //加载用户关联的大区
    this.GetWorkGroupList();
  }

}
</script>

<style>

</style>