<template>
  <div>

    <!-- 选择产品 -->
    <div class="skuBtnList">
      <el-button class="iconfont icon-add" type="primary" plain @click="btnSelectSku">选择产品</el-button>
    </div>

    <!-- 频道明细列表 -->
    <el-table :data="shopCategoryInfo.detailList" border style="width: 100%">
        <el-table-column prop="cidName" label="产品类目"></el-table-column>
        <el-table-column prop="brandName" label="品牌"></el-table-column>
        <el-table-column prop="spuName" label="产品"></el-table-column>
        <el-table-column prop="skuName" label="规格"></el-table-column>
        <el-table-column label="图片">
            <template v-slot="scope">
                <el-image
                    style="width: 70px; height: 70px"
                    :src="scope.row.skuImg"
                    :preview-src-list="[scope.row.skuImg]"
                    :hide-on-click-modal="true"
                    fit="cover">
                </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="retailPrice" label="零售价(元)"></el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
            <template v-slot="scope">
                <el-popconfirm 
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.categoryNumber,scope.row.id,scope.$index)">
                    <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>

    <!-- 选择产品 -->
    <el-dialog :title="BoxSkuInfo.title" 
    v-model="BoxSkuInfo.isVisible"
    width="65%"
    :destroy-on-close="true">
        <SelectAllSkuList ref="SelectSkuInfo"></SelectAllSkuList>
        <template #footer>
            <el-button type="primary" @click="btnSelectData">选择</el-button>
            <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
        </template>
    </el-dialog>


  </div>
</template>

<script>
import { mapState } from 'vuex'
import SelectAllSkuList from '@/components/common/SelectAllSkuList.vue'
import { ElMessage } from 'element-plus'
export default {
    components:{
        SelectAllSkuList
    },
    data(){
        return{
            //选择产品-弹出框
            BoxSkuInfo:{
              isVisible:false,
              title:'选择产品'
            },
        }
    },
    computed:{
        ...mapState('Shop',['shopCategoryInfo'])
    },
    methods:{
        //选择产品
        btnSelectSku(){
            this.BoxSkuInfo.title = "选择产品"
            this.BoxSkuInfo.isVisible = true            
        },

        //保存选中产品
        btnSelectData(){
            let itemList = this.$refs.SelectSkuInfo.selectedItem
            if(itemList !=null && itemList.length>0){
                itemList.forEach(item => {
                    let newRow = {
                        id:0,
                        systemNumber:'',
                        categoryNumber:'',
                        brandId:item.brandId,
                        brandName:item.brandName,
                        spuNumber:item.spuNumber,
                        spuName:item.spuName,
                        skuNumber:item.skuNumber,
                        skuName:item.skuName,
                        skuImg:item.skuImg,
                        retailPrice:item.retailPrice,
                    }
                    this.shopCategoryInfo.detailList.push(newRow);
                });
            }
            this.BoxSkuInfo.isVisible = false;  //关闭选择产品
        },


        //删除频道明细
        DeleteRow(categoryNumber,id,index){
            this.shopCategoryInfo.detailList.splice(index,1)
            if(id !=0){
                let param = {
                    categoryNumber:categoryNumber,
                    id:id
                }
                this.$api.shop.DeleteShopCategoryDetail(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }
        },

    }

}
</script>

<style>

</style>