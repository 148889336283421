<template>
  <div>
    <!-- 新增业务对账单 -->
    <el-divider></el-divider>

    <el-form :inline="true"
    v-model="allInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="allInfo.info">
            <el-col :span="12">
                <el-form-item label="对账单号">
                    <el-input v-model="allInfo.info.cycleNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="所属部门">
                    <el-tree-select v-model="allInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="交易者">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>交易者</span>
                    </template>
                    <el-select v-model="allInfo.info.tradeNumber" filterable clearable class="search-200">
                        <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="总金额">
                    <el-input v-model="allInfo.info.totalPrice" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="备注">
                    <el-input v-model="allInfo.info.remark" class="search-200" ></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="创建时间">
                    <el-input v-model="allInfo.info.createTime" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

        </el-row>
        


    </el-form>
    
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveInfo">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'  //用户关联部门列表
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import moment from 'moment'
import { ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList,AllTradeList],
    data(){
        return{
            allInfo:{
                info:{
                    systemNumber:'',
                    cycleNumber:'', 
                    departmentId:0,
                    departmentName:'',
                    tradeType:0,
                    tradeNumber:'',
                    tradeName:'',
                    totalPrice:0,
                    createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    remark:'',
                }
            },
            ff_uuid:'',  //           
        }
    },
    methods:{
        //保存业务对账单
        btnSaveInfo(){
            let header = {
                checkNum:this.ff_uuid,
            }
            this.$api.erp.SaveCycleBusinessAllInfo(this.allInfo,header).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
    mounted(){
        this.ff_uuid = this.$uuid.v4();  //防重复提交随机编码
    }
}
</script>

<style>

</style>