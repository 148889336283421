<template>
  <div>
    <!-- 资产负债表 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">
            
            <el-form-item label="日期">
                <el-date-picker v-model="info.search.datePoint" 
                type="daterange" 
                range-separator="To"
                start-placeholder="开始日期"
                end-placeholder="结束日期" />
            </el-form-item>

            <el-form-item>
              <el-button class="iconfont icon-search" type="primary" plain >搜索</el-button>
              <el-button class="iconfont icon-exchangerate" plain >重置</el-button>
              <el-button class="iconfont icon-dayin" v-print="printObj" id="btnPrint" plain >打印</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 资产负债表结构 -->
    <div class="ReportSheet" id="printData">
      <div class="ReportTitle">
        <div class="title">资产负债表</div> 
        <div class="summary">
          <div>账户式</div>
          <div>单位:元</div>
        </div>
      </div>
      <table>
        <tr>
          <th class="td_width">资产</th>
          <th class="td_width">期末余额</th>
          <th class="td_width">年初余额</th>
          <th class="td_width">负债和所有者权益</th>
          <th class="td_width">期末余额</th>
          <th class="td_width">年初余额</th>
        </tr>

        <tr>
          <td class="lft">流动资产:</td>
          <td></td>
          <td></td>
          <td>流动负债:</td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td class="lft">&emsp;货币资金</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
          <td>&emsp;短期借款</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
        </tr>

        <tr>
          <td>&emsp;短期投资</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
          <td>&emsp;应付票据</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
        </tr>

        <tr>
          <td>&emsp;应收票据</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
          <td>&emsp;应付账款</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
        </tr>

        <tr>
          <td>&emsp;应收账款</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
          <td>&emsp;预付账款</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
        </tr>

        <tr>
          <td>&emsp;预付账款</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
          <td>&emsp;应付职工薪酬</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
        </tr>

        <tr>
          <td>&emsp;应收股利</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
          <td>&emsp;应交税费</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
        </tr>

        <tr>
          <td>&emsp;应收利息</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
          <td>&emsp;应付利息</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
        </tr>

        <tr>
          <td>&emsp;其他应收款</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
          <td>&emsp;应付利润</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
        </tr>

        <tr>
          <td>&emsp;存货</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
          <td>&emsp;其他应付款</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
        </tr>

        <tr>
          <td class="lft">非流动资产:</td>
          <td></td>
          <td></td>
          <td>非流动负债:</td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>&emsp;固定资产清理</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
          <td>&emsp;长期借款</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
        </tr>

        <tr>
          <td>&emsp;长期待摊销费用</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
          <td>&emsp;长期应付款</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
        </tr>




        <tr>
          <td>资产总计</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
          <td>负债和所有者权益(或者股东权益)合计</td>
          <td class="rgt">0.00</td>
          <td class="rgt">0.00</td>
        </tr>

      </table>
    </div>
    

  </div>
</template>

<script>
export default {
  data(){
    return{
      info:{
        search:{
          datePoint:''
        }
      },
      printObj: {
        id:'printData',
        preview: false,
        popTitle: '打印预览',
      }
    }
  }
}
</script>

<style scoped>
.ReportSheet{width: 1080px;}
.ReportSheet table{width: 100%;border-collapse: collapse;}
.ReportSheet table th {border: 1px solid #ccc;padding: 5px;}
.ReportSheet table td {border: 1px solid #ccc;padding: 5px;}

.ReportSheet .lft{text-align: left;}
.ReportSheet .rgt{text-align: right;}
.ReportSheet .td_width {width: 180px;}

.ReportSheet .ReportTitle{text-align: center;}
.ReportSheet .ReportTitle .title{font-size: 20px;font-weight: bolder;}
.ReportSheet .ReportTitle .summary{display: flex;justify-content: space-between;}
</style>
