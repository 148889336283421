<template>
  <div>
    <el-form :inline="true"
        v-model="transportInfo.info"
        label-width="100px"
        label-position="right">
        <el-row v-if="transportInfo.info">

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="单号">
                  <el-input v-model="transportInfo.info.transNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属部门">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>所属部门</span>
                  </template>
                    <el-tree-select v-if="transportInfo.info.state==1" v-model="transportInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200" />
                    <el-tree-select v-else v-model="transportInfo.info.departmentId" :data="departmentList" :default-expand-all="true" disabled class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="物流单标题">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>物流单标题</span>
                  </template>
                  <el-input v-if="transportInfo.info.state==1" v-model="transportInfo.info.title" class="search-200"></el-input>
                  <el-input v-else v-model="transportInfo.info.title" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="接单人">
                  <!-- <el-input v-model="transportInfo.info.receiverName" ></el-input> -->
                  <el-select v-model="transportInfo.info.receiver" clearable @change="ReceiverChange" class="search-200">
                    <el-option v-for="info in selectUserList" :key="info.userNumber" :label="info.userName" :value="info.userNumber" class="search-200"></el-option>
                  </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="指定接货时间">
                    <el-date-picker v-model="transportInfo.info.receiveTime" type="datetime" placeholder="接货时间" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px" />
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="创建时间">
                  <el-input v-model="transportInfo.info.createTime" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="状态">
                  <el-tag v-if="transportInfo.info.state == 1">新建单</el-tag>
                  <el-tag v-if="transportInfo.info.state == 2">已锁单</el-tag>
                  <el-tag v-if="transportInfo.info.state == 3" type="primary">已接单</el-tag>
                  <el-tag v-if="transportInfo.info.state == 4" type="success">已完成</el-tag>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UserDptList from '@/utils/mixins/UserDptList.js'
import AllUserList from '@/utils/mixins/AllUserList.js'
export default {
  name:'TabSingleTransport',
  mixins:[UserDptList,AllUserList],
  computed:{
      ...mapState('Erp',['transportInfo'])
  },
  methods:{
    //选择接单人发生改变时触发
    ReceiverChange(option){
      if(option !=""){
        let userInfo = this.selectUserList.find(item=>{
          return item.userNumber === option
        })
        this.transportInfo.info.receiverName = userInfo.userName;
      }else{
        this.transportInfo.info.receiverName = ""
      }
    },
  }
}
</script>

<style>

</style>