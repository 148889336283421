<template>
  <div style="margin:15px;">

    <div :span="6" class="ItemInfo" @click="btnShowBox('/video/100.mp4','产品资料介绍')">
        <!-- 视频 -->
        <div class="ItemVideo">
            <img src="@/assets/img/flow.png" alt="">
        </div>
        <!-- 标题 -->
        <div class="ItemTitle">
            <span>产品资料介绍</span>
        </div>
    </div>

    <div :span="6" class="ItemInfo" @click="btnShowBox('/video/purchase.mp4','采购流单程介绍')">
        <!-- 视频 -->
        <div class="ItemVideo">
            <img src="@/assets/img/flow.png" alt="">
        </div>
        <!-- 标题 -->
        <div class="ItemTitle">
            <span>采购流单程介绍</span>
        </div>
    </div>

    <div :span="6" class="ItemInfo" @click="btnShowBox('/video/sale.mp4','销售单流程介绍')">
        <!-- 视频 -->
        <div class="ItemVideo">
            <img src="@/assets/img/flow.png" alt="">
        </div>
        <!-- 标题 -->
        <div class="ItemTitle">
            <span>销售单流程介绍</span>
        </div>
    </div>


    <div :span="6" class="ItemInfo" @click="btnShowBox('/video/100.mp4','加工单流程介绍')">
        <!-- 视频 -->
        <div class="ItemVideo">
            <img src="@/assets/img/flow.png" alt="">
        </div>
        <!-- 标题 -->
        <div class="ItemTitle">
            <span>加工单流程介绍</span>
        </div>
    </div>

    

    <div :span="6" class="ItemInfo" @click="btnShowBox('/video/100.mp4','物流单流程介绍')">
        <!-- 视频 -->
        <div class="ItemVideo">
            <img src="@/assets/img/flow.png" alt="">
        </div>
        <!-- 标题 -->
        <div class="ItemTitle">
            <span>物流单流程介绍</span>
        </div>
    </div>

    <div :span="6" class="ItemInfo" @click="btnShowBox('/video/100.mp4','对账单流程介绍')">
        <!-- 视频 -->
        <div class="ItemVideo">
            <img src="@/assets/img/flow.png" alt="">
        </div>
        <!-- 标题 -->
        <div class="ItemTitle">
            <span>对账单流程介绍</span>
        </div>
    </div>

    <div :span="6" class="ItemInfo" @click="btnShowBox('/video/100.mp4','财务单流程介绍')">
        <!-- 视频 -->
        <div class="ItemVideo">
            <img src="@/assets/img/flow.png" alt="">
        </div>
        <!-- 标题 -->
        <div class="ItemTitle">
            <span>财务单流程介绍</span>
        </div>
    </div>

    <div :span="6" class="ItemInfo" @click="btnShowBox('/video/100.mp4','电商管理介绍')">
        <!-- 视频 -->
        <div class="ItemVideo">
            <img src="@/assets/img/flow.png" alt="">
        </div>
        <!-- 标题 -->
        <div class="ItemTitle">
            <span>电商管理介绍</span>
        </div>
    </div>


    <!-- 流程介绍 -->
    <el-dialog :title="BoxFlow.title"
    v-model="BoxFlow.isVisible"
    :width="600"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
    <el-divider></el-divider>
        <BoxVideo :videoUrl="videoUrl"></BoxVideo>
    </el-dialog>

  </div>
</template>

<script>
import base from '@/api/base.js'   //导入接口域名列表
import BoxVideo from '@/components/common/BoxVideo.vue'  //功能介绍
export default {
    components:{
        BoxVideo
    },
    data(){
        return{
            videoUrl:'',  //视频地址
            //流程介绍
            BoxFlow:{
                title:'功能介绍',
                isVisible:false
            },
        }
    },
    methods:{
        btnShowBox(url,title){        
            this.videoUrl = `${base.host}` + url;
            console.log(this.videoUrl)
            this.BoxFlow.title = title;
            this.BoxFlow.isVisible = true;
        }
    }
}
</script>

<style scoped>
.ItemInfo {margin-bottom: 20px;display: inline-grid;text-align: center;justify-content: center;width: 250px;margin-right: 20px;}
.ItemInfo .ItemVideo {border-radius: 10px;width: 100%;height: 160px;border: 1px solid #ccc;overflow: hidden;}
.ItemInfo .ItemVideo img{width: 100%;height: 160px;}
.ItemInfo .ItemTitle{text-align: center;}
</style>