<template>
  <div>

    <el-form :inline="true"
    v-model="staffInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="staffInfo.info">

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="员工姓名">
                    <template #label>
                      <span style="color:red;">*</span>
                      <span>员工姓名</span>
                  </template>
                  <el-select v-model="staffInfo.info.userNumber" clearable class="search-200">
                      <el-option v-for="info in selectUserList" :key="info.userNumber" :label="info.userName" :value="info.userNumber"></el-option>
                  </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="身份证号">
                    <template #label>
                      <span style="color:red;">*</span>
                      <span>身份证号</span>
                  </template>
                  <el-input v-model="staffInfo.info.cardId" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="班制类型">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>班制类型</span>
                  </template>
                    <el-select v-model="staffInfo.info.clockInType" @change="changeClockInType" class="search-200">
                        <el-option label="常班制" :value="1"></el-option>
                        <el-option label="排班制" :value="2"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="入职日期">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>入职日期</span>
                  </template>
                    <el-date-picker v-model="staffInfo.info.entryDate" type="date" placeholder="入职日期" value-format="YYYY-MM-DD" style="width:200px"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24" v-if="staffInfo.info.clockInType==1">
                <el-form-item label="班次">
                    <el-select v-model="staffInfo.info.classesId" class="search-200">
                        <el-option :value="0" label="选择班次"></el-option>
                        <el-option v-for="classes in classesList" :key="classes.id" :label="classes.className" :value="classes.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="年假天数">
                  <el-input v-model="staffInfo.info.annualLeave" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>


        </el-row>
    </el-form>

    <div>
        提示：已存在档案的员工不会重复保存数据
    </div>

  </div>
</template>

<script>
import AllUserList from '@/utils/mixins/AllUserList.js'
import { mapState } from 'vuex'
export default {
    mixins:[AllUserList],
    computed:{
        ...mapState('Oa',['staffInfo'])
    },
    data(){
        return{
            classesList:[],  //班次列表
        }
    },
    methods:{
        //加载班次
        loadDataClasses(){
            this.$api.oa.SelectClassesList().then(res=>{
                if(res.data.status === 200){
                    this.classesList = res.data.data;
                    console.log(this.classesList)
                    if(this.classesList.length>0){
                        this.staffInfo.info.classesId = this.classesList[0].id;
                    }                    
                }
            })
        },

        //班制类型发生改变后
        changeClockInType(){
            if(this.staffInfo.info.clockInType==2){
                this.staffInfo.info.classesId = 0
            }
        },
    },
    mounted(){
        this.loadDataClasses();
    }

}
</script>

<style>

</style>