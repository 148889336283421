<template>
  <div>
    <el-divider></el-divider>

    <div id="printData">
        <div>            
            <el-row>
                <el-col :span="7" class="titleLeft">打印时间：{{printTime}}</el-col>
                <el-col :span="10" class="title"><h3>费用单</h3></el-col>
                <el-col :span="7" class="titleRight">公司名称：{{CurrentsystemInfo.companyName}}</el-col>
            </el-row>
            <el-divider></el-divider>
        </div>
        <!-- 费用单 -->
        <table class="DataInfo">
            <tr>
                <td>费用单号：{{costInfo.info.costNumber}}</td>
                <td>所属部门：{{costInfo.info.departmentName}}</td>
                <td>单据说明：{{costInfo.info.costName}}</td>
                <td>单据类型：{{costInfo.info.billTypeName}}</td>
                                
            </tr>
            <tr>
                <td>记账时间：{{costInfo.info.billTime}}</td>
                <td>交易类型：
                    <span v-if="costInfo.info.tradeType==1">供应商</span>
                    <span v-if="costInfo.info.tradeType==2">客户</span>
                    <span v-if="costInfo.info.tradeType==3">员工</span>
                </td>
                <td>交易者：{{costInfo.info.tradeName}}</td>
                <td>总价：{{costInfo.info.totalPrice}}元</td>
            </tr>
            <tr>
                <td>状态：
                    <span v-if="costInfo.info.state ==1">新建单</span>
                    <span v-if="costInfo.info.state ==2">待审核</span>
                    <span v-if="costInfo.info.state ==3">已驳回</span>
                    <span v-if="costInfo.info.state ==4">审核通过并上报</span>
                    <span v-if="costInfo.info.state ==5">审核完成</span>
                    <span v-if="costInfo.info.state ==10">已完成</span>
                </td>
                <td>财务单号：{{costInfo.info.financeNumber}}</td>
            </tr>
        </table>
        <table class="DataDetail">
            <tr>
                <th>项目名称</th>
                <th>单价(元)</th>
                <th>数量</th>
                <th>总价(元)</th>
                <th>备注</th>
            </tr>
            <tr v-for="detail in costInfo.detailList" :key="detail.costNumber">
                <td>{{detail.itemName}}</td>
                <td>{{detail.unitPrice}}</td>
                <td>{{detail.amount}}</td>
                <td>{{detail.totalPrice}}</td>
                <td>{{detail.remark}}</td>
            </tr>
        </table>
        
    </div>

    <!-- 按钮 -->
    <div class="btnGroup">
        <el-button type="primary" class="iconfont icon-dayin" v-print="printObj" id="btnPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
export default {
    data(){
        return{
            printTime:moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            printObj: {
              id:'printData',
              preview: false,
              popTitle: '打印预览',
            }
        }
    },
    computed:{
        ...mapState('Erp',['costInfo']),
        ...mapState('Master',['CurrentsystemInfo']),
    },
    methods:{
        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
}
</script>

<style scoped>
#printData {font-size: 12px;overflow: hidden;} /**防止多打印一张 */
#printData .titleLeft{display: flex;align-items: flex-end;}
#printData .title {text-align: center;font-size: 14px;}
#printData .titleRight{display: flex;align-items: flex-end;justify-content: flex-end;}
#printData .DataInfo{width: 100%;}
#printData .DataInfo td {padding: 5px 0px;}

#printData .cellBill{padding-bottom: 20px;} /* 间隔 单据*/
#printData .DataDetail {width: 100%;border-collapse: collapse;}
#printData .DataDetail th {border: 1px solid #ccc;}
#printData .DataDetail td {border: 1px solid #ccc;}
</style>