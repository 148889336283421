<template>
  <div>
    <!-- 选择物流单专用 -->
    <el-divider></el-divider>
    
    <!-- 搜索 -->
    <div>
        <el-form :inline="true">

            <el-form-item label="部门">
                <el-tree-select v-model="info.departmentId" :data="departmentList" :default-expand-all="true" @change="ChangeDepartment" class="search-200"/>
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

        </el-form>
    </div>

    <!-- 物流单列表 -->
    <div>
        <el-table :data="dataList" border style="width: 100%" highlight-current-row @current-change="handleCurrentChange"  ref="table">
            <el-table-column prop="transNumber" label="物流单号"></el-table-column>
            <el-table-column prop="departmentName" label="部门" min-width="90">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.departmentName"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="物流标题" min-width="90">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.title"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{scope.row.title}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
        </el-table>
    </div>

    <div class="btnGroup">
        <el-button type="primary" @click="openBoxTransport">新增物流单</el-button>
        <el-button type="primary" @click="btnSaveData">选择</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="70%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditTransport editId="0" :dataList="info.dataList" @closeBox="closeBoxTransport"></EditTransport>
    </el-dialog>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import EditTransport from '@/views/Tms/Transport/EditTransport.vue'
import { ElMessage } from 'element-plus'
export default {
    props:['numbers','billType'],
    mixins:[UserDptList],
    components:{
        EditTransport
    },
    data(){
        return{
            dataList:[],  //数据列表
            info:{
                dataList:[], //
                departmentId:0,
                transNumber:'',  //选中的物流单单号
            },
            //物流-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'新增物流单'
            },
        }
    },
    methods:{
        //选中行
        handleCurrentChange(option){
            this.info.transNumber = option.transNumber
        },

        //部门选择发生改变后
        ChangeDepartment(option){
            this.info.departmentId = option
        },

        //获取数据列表
        GetDataList(){
            let param = {
                departmentId:this.info.departmentId
            }
            this.$api.erp.SelectTransportList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                }
            })
        },

        //保存数据
        btnSaveData(){
            if(this.info.transNumber ==''){
                ElMessage.error('请选择物流单')
                return false;
            }
            let param = {
                transNumber:this.info.transNumber,
                billNumbers:this.numbers,
                billType:this.billType  //业务单据类型 2:销售单
            }
            this.$api.erp.SaveTransportOrigin(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //关闭
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //重置
        btnResetSearch(){
            this.info.departmentId = 0
            this.GetDataList();
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //新增物流单
        openBoxTransport(){
            this.BoxEditInfo.isVisible = true;
        },
        closeBoxTransport(){
            this.BoxEditInfo.isVisible = false;
        },

    },
    mounted(){
        this.GetDataList();
    }
}
</script>

<style>

</style>