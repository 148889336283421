<template>
  <div>

    <el-form :inline="true"
    v-model="costInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="costInfo.info">

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="费用单号">
                    <el-input v-model="costInfo.info.costNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属部门">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>所属部门</span>
                    </template>
                    <el-tree-select v-model="costInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="单据说明">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>单据说明</span>
                    </template>
                    <el-input v-model="costInfo.info.costName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="单据类型">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>单据类型</span>
                    </template>
                    <el-select v-model.number="costInfo.info.billType" class="search-200">
                        <el-option :value="0" label="选择类型"></el-option>
                        <el-option v-for="typeInfo in financeTypeList" :key="typeInfo.typeValue" :label="typeInfo.typeName" :value="typeInfo.typeValue"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="交易者">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>交易者</span>
                    </template>
                    <el-select v-model="costInfo.info.tradeNumber" filterable class="search-200">
                        <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="记账时间">
                    <el-date-picker v-model="costInfo.info.billTime" type="datetime" placeholder="记账时间" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="审核人">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>审核人</span>
                    </template>
                    <el-select v-model="costInfo.info.receiver" class="search-200">
                        <el-option v-for="checker in selectUserList" :key="checker.userNumber" :label="checker.userName" :value="checker.userNumber"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="总价">
                  <el-input v-model="costInfo.info.totalPrice" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="财务单号">
                    <el-input v-model="costInfo.info.financeNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="审核状态">
                  <el-tag v-if="costInfo.info.state ==1">新建单</el-tag>
                  <el-tag v-if="costInfo.info.state ==2" type="success">待审核</el-tag>
                  <el-tag v-if="costInfo.info.state ==3" type="danger">已驳回</el-tag>
                  <el-tag v-if="costInfo.info.state ==4" type="warning">已审核并上报</el-tag>
                  <el-tag v-if="costInfo.info.state ==5" type="info">已完成</el-tag>
                  <el-tag v-if="costInfo.info.state ==10">已关单</el-tag>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="财务单标识">
                    <el-tag v-if="costInfo.info.financeFlag == 0" type="danger">未生成</el-tag>
                    <el-tag v-if="costInfo.info.financeFlag == 1" type="info">已生成</el-tag>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="支付状态">
                    <el-tag v-if="costInfo.info.payState==0" type="danger">未支付</el-tag>
                    <el-tag v-if="costInfo.info.payState==1" type="info">已支付</el-tag>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import AllUserList from '@/utils/mixins/AllUserList.js'   //用户列表
import PartTradeList from '@/utils/mixins/PartTradeList.js'  //交易者列表
import FinaceTypeList from '@/utils/mixins/FinanceTypeList.js'  //财务单类型列表

import { mapState } from 'vuex'
export default {
    mixins:[UserDptList,AllUserList,PartTradeList,FinaceTypeList],
    computed:{
        ...mapState('Erp',['costInfo'])
    },
}
</script>

<style>

</style>