/**
 * 获取所有大区列表 - 下拉列表
 * 
 */

const AllAreaList = {
    data(){
        return{
            allAreaList:[],
        }
    },
    methods:{
        //加载全部大区列表
        LoadAllAeraList(){
            this.$api.system.SelectSystemAreaList().then(res=>{
                if(res.data.status === 200){
                    this.allAreaList = res.data.data;
                }else{
                    this.allAreaList = [];
                }
            })
        },
    },
    mounted(){
        this.LoadAllAeraList();  //加载全部大区列表
    },

}

export default AllAreaList;