<template>
  <div>
    <!-- 库存账-月记账 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="出入库类型">
                <el-select v-model="info.search.outInType" class="search-200">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="出库"></el-option>
                    <el-option :value="2" label="入库"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="原始单据类型">
                <el-select v-model="info.search.originType" class="search-150">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="采购单"></el-option>
                    <el-option :value="2" label="销售单"></el-option>
                    <el-option :value="3" label="零售单"></el-option>
                    <el-option :value="6" label="加工单"></el-option>
                    <el-option :value="8" label="报损单"></el-option>
                    <el-option :value="13" label="移仓单"></el-option>
                    <el-option :value="14" label="退供单"></el-option>
                    <el-option :value="15" label="退货单"></el-option>
                    <el-option :value="20" label="入库单"></el-option>
                    <el-option :value="21" label="出库单"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="allDptList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="大区">
                <el-select v-model="info.search.areaNumberList" multiple collapse-tags collapse-tags-tooltip class="search-150">
                    <el-option v-for="areaInfo in allAreaList" :key="areaInfo.areaNumber" :label="areaInfo.areaName" :value="areaInfo.areaNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable  class="search-150">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="仓库">
                <el-select v-model="info.search.selectWarehouseList" multiple collapse-tags collapse-tags-tooltip class="search-200">
                    <el-option v-for="item in allWhList" :key="item.id" :label="item.warehouseName" :value="item.warehouseNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                        
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
                    </el-tooltip>
                </el-button-group>
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe show-summary :height="GetDataHeight" style="width: 100%">
        <el-table-column label="月份" min-width="110" fixed="left">
            <template v-slot="scope">
                <span>{{scope.row.strInOutTime}}</span>
            </template>
        </el-table-column>
       
        <el-table-column prop="billNum" label="记录数量"></el-table-column>
        
        <el-table-column prop="inTotalPrice" label="借方(入库)" width="200">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.inTotalPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="outTotalPrice" label="贷方(出库)" width="200">
           <template v-slot="scope">
                <span>{{this.myRound(scope.row.outTotalPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="库房余额" width="200">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.warehouseTotalPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="系统余额" width="200">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.systemTotalPrice,2)}}</span>
            </template>
        </el-table-column>

        </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

  </div>
</template>

<script>
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import AllDptList from '@/utils/mixins/AllDptList.js'  //全部部门列表
import AllAreaList from '@/utils/mixins/AllAreaList.js'  //全部大区列表
import AllWhList from '@/utils/mixins/AllWhList.js'   //全部仓库列表

import { ElLoading, ElMessage } from 'element-plus'
export default {
    mixins:[AllTradeList,AllDptList,AllAreaList,AllWhList],
    data(){
        return{
            info:{
                dataList:[],  //数据列表
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    outInType:0,  //出入库类型
                    originType:0,   //原始单据类型
                    selectDepartmentList:[],  //选中的部门列表
                    areaNumberList:[],   //大区列表
                    selectWarehouseList:[],  //选中的仓库列表               
                    tradeType:0,    //交易者类型
                    tradeNumber:'', //交易者
                    datePoint:'',   //时间范围
                },
            },
        }
    },
    computed:{
        //Table自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-285
        }
    },
    methods:{
        // ...mapActions('Master',['work_AddTag']),


        //获取数据列表
        GetDataList(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }
            let param = {
                outInType:this.info.search.outInType,
                originType:this.info.search.originType,
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                warehouseList:this.info.search.selectWarehouseList,                
                tradeType:this.info.search.tradeType,
                tradeNumber:this.info.search.tradeNumber,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.PageStockDetailMonthList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //导出库存账到Excel
        btnExportInfo(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }
            let param = {
                outInType:this.info.search.outInType,
                originType:this.info.search.originType,
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                warehouseList:this.info.search.selectWarehouseList,               
                tradeType:this.info.search.tradeType,
                tradeNumber:this.info.search.tradeNumber,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在导出数据,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })
            this.$api.finance.ExportStockDetailMonthList(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    const alink = document.createElement('a');
                    alink.href = res.data.data;
                    document.body.appendChild(alink);
                    alink.click();
                    document.body.removeChild(alink)
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.outInType=0;
            this.info.search.originType = 0;
            this.info.search.selectDepartmentList=[];
            this.info.search.areaNumberList=[];   //大区
            this.info.search.selectWarehouseList=[];
            this.info.search.warehouseNumber='';
            this.info.search.tradeType = 0;
            this.info.search.tradeNumber='';
            this.info.search.datePoint='';
            this.info.page = 1;
            this.GetDataList();
        },
    },
    mounted(){
        this.GetDataList();  //加载数据
    },
}
</script>

<style>
</style>