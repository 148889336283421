<template>
  <!-- 询价单列表 -->
  <div>
      <el-tabs v-model="info.activeName">
          <el-tab-pane label="主动询价" name="TabInquiryActive">
              <ListInquiryActive></ListInquiryActive>
          </el-tab-pane>

          <el-tab-pane label="被动询价" name="TabInquiryPassive">
              <ListInquiryPassive></ListInquiryPassive>
          </el-tab-pane>

      </el-tabs>

  </div>
</template>

<script>
import ListInquiryActive from '@/views/Pms/Inquiry/ListInquiryActive.vue'
import ListInquiryPassive from '@/views/Pms/Inquiry/ListInquiryPassive.vue'

export default {
    name:'ListInquiry',
    components:{
        ListInquiryActive,
        ListInquiryPassive
    },
    data(){
        return{
            info:{
                activeName:'TabInquiryActive'
            }
        }
    }
}
</script>

<style>

</style>