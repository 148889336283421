/**
 * 获取账户列表 
 * 下拉列表专用
 */

const AccountList = {
    data(){
        return{
            selectAccountList:[]
        }
    },
    methods:{
        //加载账户下拉列表
        SelectAccountList(){
            let param = {
                statFlag:-1
            }
            this.$api.finance.SelectAccountList(param).then(res=>{
                if(res.data.status === 200){
                    this.selectAccountList = res.data.data;  //目录树
                }else{
                    console.log(res.data.msg);
                }
            }).catch(e=>{
                console.log(e);
            })
        },
    },
    mounted(){
        this.SelectAccountList();   //
    }
}

export default AccountList