<template>
  <div>
      <el-divider></el-divider>

      <el-form 
        v-model="info"
        label-width="80px"
        label-position="right">
            <el-row>

                <el-col :span="12">
                    <el-form-item label="所属指标">
                        <el-tree-select v-model="info.pid" :data="SelectTagList" check-strictly></el-tree-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="指标名称">
                        <el-input v-model="info.tagName"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
     </el-form>

  </div>
</template>

<script>
export default {
    name:'AddTag',
    props:['number'],
    data(){
        return{
            info:{
                tagId:0,
                themeNumber:this.number,
                pid:0,
                tagName:'',
            },
            SelectTagList:[],  //指标下啦列表
        }
    },
    methods:{
        //加载指标下拉列表
        SelectEvaluateTagList(){
            let param = {
                themeNumber:this.number
            }
            this.$api.service.SelectEvaluateTagList(param).then(res=>{
            if(res.data.status === 200){
                this.SelectTagList = res.data.data
            }
            })
        },

    },
    mounted(){
        this.SelectEvaluateTagList()  //加载指标-下拉列表
    }
}
</script>

<style>

</style>