<template>
  <div>
    
    <el-row>
        <!-- 车辆列表 -->
        <el-col :span="4" class="tree_info">
            <el-card>
                <template #header>
                    <div class="card-header">
                        <span>车辆列表</span>
                    </div>
                </template>
                <el-scrollbar height="600px">
                    <!-- 加载车辆列表 -->

                </el-scrollbar>
            </el-card>
        </el-col>

        <!-- 地图 -->
        <el-col :span="20">
            <div id="container"></div> 
        </el-col>
    </el-row>

  </div>
</template>



<script>
export default {

}
</script>

<style scoped>
.tree_info {padding-right: 10px;}
</style>