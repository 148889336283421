<template>
  <div>
    <el-divider></el-divider>
    <!-- 统计班次账户余额 -->

    <!-- 各单据统计 -->
    <el-descriptions
    title="各单据统计"
    :column="3"
    :size="size"
    border>
    <el-descriptions-item label="销售总额">{{allInfo.statInfo.saleTotal}}</el-descriptions-item>
    <el-descriptions-item label="销售总成本">{{allInfo.statInfo.saleCostTotal}}</el-descriptions-item>
    <el-descriptions-item label="销售毛利">{{allInfo.statInfo.saleProfit}}</el-descriptions-item>

    <el-descriptions-item label="退货总额">{{allInfo.statInfo.returnSaleTotal}}</el-descriptions-item>
    <el-descriptions-item label="退货总成本">{{allInfo.statInfo.returnSaleCostTotal}}</el-descriptions-item>
    <el-descriptions-item label="退货毛利">{{allInfo.statInfo.returnSaleProfit}}</el-descriptions-item>

    <el-descriptions-item label="零售总额">{{allInfo.statInfo.retailTotal}}</el-descriptions-item>
    <el-descriptions-item label="零售总成本">{{allInfo.statInfo.retailCostTotal}}</el-descriptions-item>
    <el-descriptions-item label="零售毛利">{{allInfo.statInfo.retailProfit}}</el-descriptions-item>

    <el-descriptions-item label="退零总额">{{allInfo.statInfo.returnRetailTotal}}</el-descriptions-item>
    <el-descriptions-item label="退零总成本">{{allInfo.statInfo.returnRetailCostTotal}}</el-descriptions-item>
    <el-descriptions-item label="退零毛利">{{allInfo.statInfo.returnRetailProfit}}</el-descriptions-item>
  </el-descriptions>

    <el-divider></el-divider>

    <!-- 账户列表 -->
    <el-table :data="allInfo.recordList" border stripe show-summary style="width: 100%">
        <el-table-column prop="accountName" label="账户名称" min-width="90"/>       

        <el-table-column prop="realPrice" label="实际交易额" min-width="110">
            <template v-slot="scope">
                <el-input-number v-model="scope.row.realPrice" :precision="2" :controls="false" style="width:100px" @change="OnChangeRealPrice"></el-input-number>
            </template>
        </el-table-column>

        <el-table-column label="系统发生额" min-width="100">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.totalPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="diffPrice" label="系统差额" min-width="110">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.diffPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="备注" min-width="90">
            <template v-slot="scope">
                <el-input v-model="scope.row.remark" :maxlength="50"></el-input>
            </template>
        </el-table-column>

    </el-table>

    <div class="remark">
        统计方式：系统发生额 = 零售单各账户总额 - 退零单各账户总额 + 调账记录各账户总额
    </div>

    <div class="btnGroup">
      <el-button type="primary" @click="SaveStatShiftInfo">保存</el-button>
      <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  props:['editId'],
  data(){
    return{
      dataList:[],  //数据列表
      allInfo:{
        statInfo:{  
          systemNumber:'',
          shiftNumber:this.editId,
          retailTotal:0,
          retailCostTotal:0,
          retailProfit:0,
          returnRetailTotal:0,
          returnRetailCostTotal:0,
          returnRetailProfit:0,
          saleTotal:0,
          saleCostTotal:0,
          saleProfit:0,
          returnSaleTotal:0,
          returnSaleCostTotal:0,
          returnSaleProfit:0,
        },   //各单据统计 
        recordList:[],  //数据列表
      }
    }
  },
  methods:{
    //加载统计初期结果(作废)
    StatRetailShiftRecordDetailList(){
      let param = {
        shiftNumber:this.editId
      }
      this.$api.erp.StatRetailShiftRecordDetailList(param).then(res=>{
        if(res.data.status === 200){
          this.dataList = res.data.data;
        }else{
          ElMessage.error(res.data.msg);
        }
      })

    },

    //保存统计更新后结果(作废)
    SaveRetailShiftRecordDetail(){
      this.$api.erp.SaveRetailShiftRecordDetail(this.dataList).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          this.btnClose();
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //加载数据
    StatShiftInfo(){
      let param = {
        shiftNumber:this.editId
      }
      this.$api.erp.StatShiftInfo(param).then(res=>{
        if(res.data.status === 200){
          this.allInfo = res.data.data;
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //保存数据
    SaveStatShiftInfo(){
      this.$api.erp.SaveStatShiftInfo(this.allInfo).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          this.btnClose();
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //实际交易额发生变化
    OnChangeRealPrice(){
      this.allInfo.recordList = this.allInfo.recordList.map(item=>{
        item.diffPrice = this.myRound(item.realPrice - item.totalPrice,2);
        return item;
      })
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

  },
  mounted(){
    //this.StatRetailShiftRecordDetailList();  //加载统计初期结果
    this.StatShiftInfo();
  },
  

}
</script>

<style scoped>
.remark{margin-left: 10px;font-size: 14px;color: #ccc;}
</style>