<template>
  <div>
    <el-form :inline="true"
        v-model="spuInfo.info"
        label-width="80px"
        label-position="right">
        <el-row v-if="spuInfo.info">
          
          <el-col :span="12">
              <el-form-item label="产品编号">
                  <el-input v-model="spuInfo.info.spuNumber" class="search-200" disabled></el-input>
              </el-form-item>
          </el-col>

          <el-col :span="12">
              <el-form-item label="类目">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>类目</span>
                  </template>
                  <el-tree-select v-model="spuInfo.info.cid" :data="selectCategoryList" :default-expand-all="true" filterable class="search-200"/>
              </el-form-item>
          </el-col>

          <el-col :span="12">
              <el-form-item label="产品名称">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>产品名称</span>
                  </template>
                  <el-input v-model="spuInfo.info.spuName" class="search-200"></el-input>
              </el-form-item>
          </el-col>

          <el-col :span="12">
              <el-form-item label="显示标识">
                  <el-select v-model="spuInfo.info.isShow" class="search-200">
                      <el-option label="隐藏" :value="0"></el-option>
                      <el-option label="显示" :value="1"></el-option>
                  </el-select>
                  <!-- 提示 -->
                  <el-tooltip
                      class="box-item"
                      effect="dark"
                      content="在产品列表是否显示该产品"
                      placement="top"
                  >
                      <i class="iconfont icon-wenhao"></i>
                  </el-tooltip>
              </el-form-item>
          </el-col>

          <el-col :span="12">
              <el-form-item label="排序">
                  <el-input-number v-model="spuInfo.info.sort" class="search-200"></el-input-number>
              </el-form-item>
          </el-col>

        </el-row>
    </el-form>
  </div>
</template>

<script>
import CategoryList from '@/utils/mixins/CategoryList.js'  //加载类目下拉框
import { mapState } from 'vuex'
export default {
  mixins:[CategoryList],
  computed:{
    ...mapState('Erp',['spuInfo'])
  },
  methods:{
    
  },

}
</script>

<style>

</style>