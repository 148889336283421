<template>
  <div>
    <!-- 全年数据统计图页面 -->
    
    <!-- 标题 -->
    <el-row>
        <el-col :span="24" class="title">
            <h3>全年数据统计图</h3>
        </el-col>        
    </el-row>

    <el-row>
        <el-col :span="24" class="title">
            年份：<el-date-picker
                    v-model="currentYear"
                    :editable="false"
                    :clearable="false"
                    type="year"
                    size="default"
                    @change="LoadDataView"
                />
        </el-col>
    </el-row>

    <!-- 主项 -->
    <el-row :gutter="10">

        <el-col :span="2"></el-col>
        <el-col :span="5" class="singleItem">采购额：<span class="mainPrice">{{info.purchaseTotalPrice}}</span>万元</el-col>
        <el-col :span="5" class="singleItem">销售额：<span class="mainPrice">{{info.saleTotalPrice}}</span>万元</el-col>
        <el-col :span="5" class="singleItem">毛利润：<span class="mainPrice">{{info.profitPrice}}</span>万元</el-col>
        <el-col :span="5" class="singleItem">毛利率：<span class="mainPrice">{{info.profitRate}}</span>%</el-col>
        <el-col :span="2" class="singleItem"></el-col>

        <!-- <el-col :span="2"></el-col>
        <el-col :span="4" class="singleItem">销售单数：<span class="mainPrice">{{info.saleTotalNum}}</span>单</el-col>
        <el-col :span="4" class="singleItem">采购单数：<span class="mainPrice">{{info.purchaseTotalNum}}</span>单</el-col>
        <el-col :span="4" class="singleItem">其他成本：<span class="mainPrice">{{info.otherCost}}</span>元</el-col>
        <el-col :span="4" class="singleItem">净利润率：<span class="mainPrice">{{info.netProfitRate}}</span>%</el-col>
        <el-col :span="2"></el-col> -->
   
    </el-row>

    <!-- 统计图 -->
    <div id="main" class="echarts" style="width:100%;height:500px;" ></div>

    <div class="dataSource">
        数据来源：采购单、销售单
    </div>

  </div>
</template>

<script>
import * as echarts from 'echarts'  //Echarts 图标组件
import moment from 'moment';
export default {
    data(){
        return{
            currentYear:moment(new Date()).format("YYYY"),
            //这里面放所有数据
            info:{
              purchaseTotalPrice:0,  //采购额
              purchaseTotalNum:0,   //采购单数
              saleTotalPrice:0,  //销售额
              saleTotalNum:0,   //销售单数
              profitPrice:0, //毛利润
              profitRate:0,   //毛利率
              purchasePriceList:[0,0,0,0,0,0,0,0,0,0,0,0], //采购额
              salePriceList:[0,0,0,0,0,0,0,0,0,0,0,0], //销售额
              profitList:[0,0,0,0,0,0,0,0,0,0,0,0],  //毛利润
              rateList:[0,0,0,0,0,0,0,0,0,0,0,0],  //毛利率
              purchaseNumList:[0,0,0,0,0,0,0,0,0,0,0,0],  //采购单数
              saleNumList:[0,0,0,0,0,0,0,0,0,0,0,0], //销售单数 
            },            
        }
    },
    methods:{
        //示例
        myEcharts(){
            var myChart = echarts.init(document.getElementById('main'));
            
            //配置图表
            var option = {
              tooltip: {
                trigger: 'axis',   //显示标签
              },
              legend:{
                top:'bottom',
                data:['采购额','销售额','毛利润','毛利率'] //示例图
              },
              xAxis: {
                type: 'category',
                data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
              },
              yAxis: [
                    {
                        type: 'value',
                        name:'交易额',
                        position: 'left',
                        axisLabel: {
                            formatter: '{value} 万元'
                        }
                    },
                    {
                        type:'value',
                        name:'毛利率',
                        position: 'right',
                        axisLabel: {
                            formatter: '{value} %'
                        }
                    },
              ],
              series: [
                
                {
                  name:'采购额',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.purchasePriceList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#33ccff'
                  },  //颜色
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 万元'
                    }
                  },
                },
                {
                  name:'销售额',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.salePriceList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#DC143C'
                  },
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 万元'
                    }
                  },
                },
                {
                  name:'毛利润',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.profitList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#FF4500'
                  },
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 万元'
                    }
                  },
                },
                {
                  name:'毛利率',
                  yAxisIndex: 1,  //用的是右侧Y坐标
                  data: this.info.rateList,
                  type: 'line',
                  smooth: true,   //平滑曲线
                  itemStyle:{
                    color:'#9F5F9F'
                  },
                  tooltip:{
                    valueFormatter:function(value){
                      if(value!=undefined && value!=null){
                        return value + '%'
                      }else{
                        return '-'
                      }                     
                    }
                  },
                  connectNulls:true,  //连上断开的点
                },
                // {
                //   name:'采购单数',
                //   yAxisIndex: 1,  //用的是右侧Y坐标
                //   data: this.info.purchaseNumList,
                //   type: 'line',
                //   smooth: true,   //平滑曲线
                //   itemStyle:{
                //     color:'#33ccff'
                //   }
                // },
                // {
                //   name:'销售单数',
                //   yAxisIndex: 1,  //用的是右侧Y坐标
                //   data: this.info.saleNumList,
                //   type: 'line',
                //   smooth: true,   //平滑曲线    
                //   itemStyle:{
                //     color:'#DC143C'
                //   }              
                // },
              ]
            };
            myChart.setOption(option);
        },

        //加载数据
        async LoadDataView(){
          let strYear = moment(this.currentYear).format("YYYY")
          let param = {
            year:strYear
          }

          await this.$api.stat.GetStatYearTurnover(param).then(res=>{
            if(res.data.status === 200){
              this.info = res.data.data;
              this.myEcharts();
            }
          })
        }

    },
    mounted(){
      this.LoadDataView();      
    },
}
</script>

<style scoped>

.title{text-align: center;}
.singleItem{padding-top: 15px;font-size: 14px;}
.mainPrice{color: #cc0033;font-size: 25px;}
.dataSource{margin-left: 140px;font-size: 14px;color: #ccc;}
</style>