/**
 * 人事管理模块接口列表
 */


 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const hr = {
    //#region 人事档案

    //列表
    ListStaff(param){
        return axios.post(`${base.base}/Staff/ListStaff`,param)
    },

    //单个
    SingleStaffAllInfo(param){
        return axios.get(`${base.base}/Staff/SingleStaffAllInfo`,{
            params:param
        })
    },

    //保存单个信息
    SaveStaffAllInfo(param){
        return axios.post(`${base.base}/Staff/SaveStaffAllInfo`,param)
    },    

    //删除
    DeleteStaff(param){
        return axios.post(`${base.base}/Staff/DeleteStaff`,param)
    },

    //清空年假
    ClearAnnualLeave(param){
        return axios.post(`${base.base}/Staff/ClearAnnualLeave`,param)
    },

    //#endregion

    //#region 年假调整记录

    //年假调整记录列表
    ListAnnualLeave(param){
        return axios.post(`${base.base}/AnnualLeave/ListAnnualLeave`,param)
    },

    //批量保存年假调整记录
    BatchSaveAnnualLeave(param){
        return axios.post(`${base.base}/AnnualLeave/BatchSaveAnnualLeave`,param)
    },

    //删除年假调整记录
    DeleteAnnualLeave(param){
        return axios.post(`${base.base}/AnnualLeave/DeleteAnnualLeave`,param)
    },

    //#endregion

 }

 export default hr