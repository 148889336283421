<template>
  <div>
    <!-- 单月数据统计图 -->
    <!-- 标题 -->
    <el-row>
        <el-col :span="24" class="title">
            <h3>单月数据统计图</h3>
        </el-col>        
    </el-row>

    <el-row>
        <el-col :span="24" class="title">
            月份：<el-date-picker
                    v-model="currentMonth"
                    :editable="false"
                    :clearable="false"
                    type="month"
                    size="default"
                    @change="LoadDataView"
                />
        </el-col>
    </el-row>

    <!-- 主项 -->
    <el-row :gutter="10">

        <el-col :span="2"></el-col>
        <el-col :span="5" class="singleItem">采购额：<span class="mainPrice">{{info.purchasePrice}}</span>元</el-col>
        <el-col :span="5" class="singleItem">销售额：<span class="mainPrice">{{info.salePrice}}</span>元</el-col>        
        <el-col :span="5" class="singleItem">毛利润：<span class="mainPrice">{{info.profitPrice}}</span>元</el-col>
        <el-col :span="5" class="singleItem">毛利率：<span class="mainPrice">{{info.profitRate}}</span>%</el-col>
        <!-- <el-col :span="4" class="singleItem">用工成本：<span class="mainPrice">{{info.staffCost}}</span>元</el-col>         -->
        <el-col :span="2" class="singleItem"></el-col>

    </el-row>


    <!-- 统计图 -->
    <div id="main" class="echarts" style="width:100%;height:500px;" ></div>

    <div class="dataSource">
        数据来源：采购单、销售单
    </div>

  </div>
</template>

<script>
import * as echarts from 'echarts'  //Echarts 图标组件
import moment from 'moment'
export default {
    data(){
        return{
            currentMonth:moment(new Date()).format("YYYY-MM"),
            //单月数据实体
            info:{
                purchasePrice:0,  //采购额
                salePrice:0,  //销售额
                profitPrice:0, //毛利润
                profitRate:0,   //毛利率
                purchasePriceList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], //采购额
                salePriceList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], //销售额
                profitList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],  //毛利润
                rateList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],  //毛利率
            }
        }
    },
    methods:{
        //示例
        myEcharts(){
            var myChart = echarts.init(document.getElementById('main'));
            
            //配置图表
            var option = {
              tooltip: {
                trigger: 'axis',   //显示标签
              },
              legend:{
                top:'bottom',
                data:['采购额','销售额','毛利润','毛利率'] //示例图
              },
              xAxis: {
                type: 'category',
                data: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
              },
              yAxis: [
                    {
                        type: 'value',
                        name:'金额',
                        position: 'left',
                        axisLabel: {
                            formatter: '{value} 元'
                        }
                    },
                    {
                        type:'value',
                        name:'毛利率',
                        position: 'right',
                        axisLabel: {
                            formatter: '{value} %'
                        }
                    },
              ],
              series: [
                
                {
                  name:'采购额',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.purchasePriceList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#33ccff'
                  },  //颜色
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 元'
                    }
                  },
                },
                {
                  name:'销售额',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.salePriceList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#DC143C'
                  },
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 元'
                    }
                  },
                },
                {
                  name:'毛利润',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.profitList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#FF4500'
                  },
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 元'
                    }
                  },
                },
                {
                  name:'毛利率',
                  yAxisIndex: 1,  //用的是右侧Y坐标
                  data: this.info.rateList,
                  type: 'line',
                  smooth: true,   //平滑曲线    
                  itemStyle:{
                    color:'#9F5F9F'
                  },
                  tooltip:{
                    valueFormatter:function(value){
                      if(value!=undefined && value!=null){
                        return value + '%'
                      }else{
                        return '-';
                      }                      
                    }
                  },
                  connectNulls:true,  //连上断开的点     
                },
              ]
            };
            myChart.setOption(option);
        },

        //加载数据
        async LoadDataView(){
          let strMonth = moment(this.currentMonth).format("YYYY-MM")
          var dateArr = strMonth.split('-');
          let param = {
            year:dateArr[0],
            month:dateArr[1],
          }
          await this.$api.stat.GetStatMonthTurnover(param).then(res=>{
            if(res.data.status === 200){
              this.info = res.data.data;
              this.myEcharts();
            }
          })
        },


    },
    mounted(){
      this.LoadDataView();      
    },
}
</script>

<style scoped>

.title{text-align: center;}
.singleItem{padding-top: 15px;font-size: 14px;}
.mainPrice{color: #cc0033;font-size: 25px;}
.dataSource{margin-left: 140px;font-size: 14px;color: #ccc;}
</style>