<template>
  <div>
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">          

                <el-form-item label="项目名称">
                    <el-input v-model="info.search.itemName"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                                
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>       
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="itemId" type="selection" width="50" />  
            <el-table-column prop="itemName" label="项目名称" />
            <el-table-column label="计算方式">
                <template #default="scope">
                    <el-tag v-if="scope.row.way ==0" type="danger">减</el-tag>
                    <el-tag v-if="scope.row.way ==1">加</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="sort" label="排序" />
            <el-table-column label="操作" width="150" fixed="right">
                <template v-slot="scope">              
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.itemId)">详情</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.itemId)">
                    <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                    </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="12">
                
            </el-col>
            <el-col :span="12">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[20, 50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="450"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditWageItem :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditWageItem>
    </el-dialog>
    
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import EditWageItem from '@/views/Oa/Wage/EditWageItem.vue'
export default {
    name:'ListWageItem',
    components:{
        EditWageItem
    },
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:20,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    itemName:'',                    
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'工资结构项详情'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            let param = {
                itemName:this.info.search.itemName,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.oa.PageWageItemList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.itemId)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //编辑
        btnEditInfo(itemId){
            this.info.id = itemId
            this.BoxEditInfo.isVisible = true;
        },
        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(itemId){
            let param = [itemId]
            this.$api.oa.DeleteWageItem(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg);
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg);
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项');
                return false
            }

            this.$api.oa.DeleteWageItem(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.GetDataList();
                    this.info.ids = [];
                }
                else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.itemName='';
            this.info.page = 1;
            this.GetDataList()
        },    

    },
    mounted(){
        this.GetDataList();  //加载数据
    }
}
</script>

<style>

</style>