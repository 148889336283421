
//人事档案
import ListStaff from '@/views/Oa/Staff/ListStaff.vue'

export default[{
    //人事档案
    name:'ListStaff',
    path:'liststaff',
    component:ListStaff
}]
