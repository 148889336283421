
// 防抖 ：一段时间内不再触发事件后执行
// export default function debounce(fn, time) {
//     time = time || 200
//     // 定时器
//     let timer = null
//     return function(...args) {
//       var _this = this
//       if (timer) {
//         clearTimeout(timer)
//       }
//       timer = setTimeout(function() {
//         timer = null
//         fn.apply(_this, args)
//       }, time)
//     }
//   }


//节流：先执行一次，一段时间内不在执行
export default function throttle(fn, time) {
    let timer = null
    time = time || 1000
    return function(...args) {
      if (timer) {
        return
      }
      const _this = this
      timer = setTimeout(() => {
        timer = null
      }, time)
      fn.apply(_this, args)
    }
  }