<template>
  <div>
    <!-- 移动端菜单 -->

    <el-scrollbar :height="GetDataHeight">
      <el-tree
      :data="allMobileMenuList"
      :default-checked-keys="roleInfo.selectMobileMenuList"
      default-expand-all
      show-checkbox
      ref = "treeMenu"
      node-key="id">
      </el-tree>
    </el-scrollbar>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return{
            allMobileMenuList:[],  //移动端菜单目录树
        }
    },
    computed:{
      //自适应窗体高度
      GetDataHeight(){
          return window.innerHeight-200
      },
      ...mapState('System',['roleInfo'])
    },
    methods:{
        //加载数据
        GetDataInfo(){
            //加载系统的全部菜单
            let param = {
                isButton:1
            }
            this.$api.system.SelectSystemAllMenuList(param).then(res=>{
                if(res.data.status === 200){
                    this.allMobileMenuList = res.data.data
                    
                }else{
                    this.allMobileMenuList = []
                }
            })
        },
    },
    mounted(){
        this.GetDataInfo();
    },
}
</script>

<style>

</style>