<template>
  <div>
    <!-- 加工组信息 -->
    <el-form :inline="false"
      v-model="workGroupInfo.info"
      label-width="100px"
      label-position="right">
          <el-row v-if="workGroupInfo.info">
              <el-col :span="12">
                  <el-form-item label="所属部门">
                      <template #label>
                          <span style="color:red;">*</span>
                          <span>所属部门</span>
                      </template>
                      <el-tree-select v-model="workGroupInfo.info.departmentId" :data="departmentList" :default-expand-all="true" />
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="加工组名称">
                    <template #label>
                          <span style="color:red;">*</span>
                          <span>加工组名称</span>
                      </template>
                      <el-input v-model="workGroupInfo.info.workGroupName"></el-input>
                  </el-form-item>
              </el-col>

          </el-row>
    </el-form>


  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import { mapState } from 'vuex'
export default {
  mixins:[UserDptList],
  computed:{
      ...mapState('Erp',['workGroupInfo'])
  },

}
</script>

<style>

</style>