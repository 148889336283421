<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <el-tabs v-model="activeName" class="editTab">
        <el-tab-pane label="企业信息" name="tabSystem">
            <TabSystemInfo></TabSystemInfo>            
        </el-tab-pane>
        <el-tab-pane label="用户信息" name="tabUser">
            <TabSystemUser></TabSystemUser>
        </el-tab-pane>
        <el-tab-pane label="授权模块" name="tabModule">
            <TabSystemModule ref="tabModule"></TabSystemModule>
        </el-tab-pane>
        <el-tab-pane label="授权Web端菜单" name="tabMenu">
            <TabSystemMenu ref="tabMenu"></TabSystemMenu>
        </el-tab-pane>
        <el-tab-pane label="授权Mobile菜单" name="tabMobile">
            <TabSystemMobileMenu ref="tabMoblieMenu"></TabSystemMobileMenu>
        </el-tab-pane>
    </el-tabs>
    
    <!-- 功能按钮组 -->
    <div class="editFooter">
        <el-button type="primary" @click="SaveDataInfo">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import TabSystemInfo from '@/views/system/Info/TabSystemInfo.vue'
import TabSystemUser from '@/views/system/Info/TabSystemUser.vue'
import TabSystemModule from '@/views/system/Info/TabSystemModule.vue'
import TabSystemMenu from '@/views/system/Info/TabSystemMenu.vue'
import TabSystemMobileMenu from '@/views/system/Info/TabSystemMobileMenu.vue'  //移动端菜单

import { ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
export default {
    props:['editId'],
    components:{
        TabSystemInfo,
        TabSystemUser,
        TabSystemModule,
        TabSystemMenu,
        TabSystemMobileMenu,
    },
    data(){
        return{
            activeName:'tabSystem',   //默认标签页
            systemNumber:this.editId,   //系统编号
        }
    },
    computed:{
        ...mapState('System',['systemInfo'])
    },
    methods:{
        ...mapMutations('System',['SetSystemInfo']),

        //获取数据信息
        GetDataInfo(){
            if(this.systemNumber!=0){
                this.btnClearData();
                
                let param = {
                    systemNumber:this.systemNumber
                }
                this.$api.system.GetSystemAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        let allInfo = res.data.data;
                        if(allInfo.configInfo == null){
                            allInfo.configInfo = {
                                id:0,
                                systemNumber:this.editId,
                                wxAppId:'',
                                wxAppSecret:'',
                                wxMchid:'',
                                isOpenWxPay:0,
                                isOpenAliPay:0
                            }
                        }
                        this.SetSystemInfo(allInfo);
                    }
                })
            }else{
                this.btnClearData();
            }            
        },

        //保存数据
        SaveDataInfo(){
            //#region 选中的模块
            let newSelectModuleList = [];
            const selectedKeys = this.$refs.tabModule.$refs.treeModule.getCheckedKeys();
            selectedKeys.forEach(element => {
                let newInfo = {
                    id:0,
                    systemNumber:this.systemNumber,
                    moduleId:element,
                }
                newSelectModuleList.push(newInfo)
            });
            this.systemInfo.moduleList = newSelectModuleList;  
            //#endregion
            //#region 选中的菜单
            let newSelectMenuList =[]            
            const selectedMenuKeys = this.$refs.tabMenu.$refs.treeMenu.getCheckedKeys()
            selectedMenuKeys.forEach(element => {
                let menu = {
                    id:0,
                    systemNumber:this.systemNumber,
                    menuId:element,
                    state:2
                }
                newSelectMenuList.push(menu)
            });

            const halfSelectedKeys = this.$refs.tabMenu.$refs.treeMenu.getHalfCheckedKeys()
            halfSelectedKeys.forEach(value=>{
                let menu = {
                    id:0,
                    systemNumber:this.systemNumber,
                    menuId:value,
                    state:1
                }
                newSelectMenuList.push(menu);
            })
            this.systemInfo.menuList = newSelectMenuList;
            //#endregion
            //#region 选中的移动端菜单
            let newSelectMobileMenuList = [];
            //全选
            const selMobileMenuKeys = this.$refs.tabMoblieMenu.$refs.treeMenu.getCheckedKeys();
            selMobileMenuKeys.forEach(element=>{
                let menu = {
                    id:0,
                    systemNumber:this.systemNumber,
                    menuId:element,
                    state:2
                }
                newSelectMobileMenuList.push(menu);
            })
            //半选
            const halfSelMobileMenuKeys = this.$refs.tabMoblieMenu.$refs.treeMenu.getHalfCheckedKeys();
            halfSelMobileMenuKeys.forEach(element=>{
                let menu = {
                    id:0,
                    systemNumber:this.systemNumber,
                    menuId:element,
                    state:1
                }
                newSelectMobileMenuList.push(menu);
            })
            //填装
            this.systemInfo.mobileMenuList = newSelectMobileMenuList;
            //#endregion
            
            this.$api.system.SaveSystemAllInfo(this.systemInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //清空数据
        btnClearData(){
            let newInfo = {
                info:{
                    systemNumber:'',
                    systemName:'',
                    domainName:'',
                    companyName:'',
                    legalPerson:'',
                    license:'',
                    linkMan:'',
                    linkPhone:'',
                    certNumber:'',
                    state:1,
                    systemType:1,
                    termTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    registerDate:moment(new Date()).format("YYYY-MM-DD"),
                    registerCapital:100,

                },   //系统信息
                userInfo:{
                    id:0,
                    systemNumber:'',
                    userNumber:'',
                    userName:'',
                    logo:'',
                    phone:'',
                    sex:0,
                    departmentId:0,
                    zhiwei:'',
                    openId:'',
                    userType:'',
                    staffType:1,
                    workState:1,
                    isOwner:1,
                    isDel:0
                },
                moduleList:[],   //选中的模块
                menuList:[],    //选中的菜单
                mobileMenuList:[],  //选中的移动端菜单
                userLoginPassWord:'',   //登录密码
            }
            this.SetSystemInfo(newInfo);
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style>

</style>