<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <!-- 计划单 -->
    <el-tabs v-model="activeName" class="editTab">
        <el-tab-pane label="计划单" name="tabPlan">
            <TabPlan></TabPlan>

            <el-divider>计划单明细</el-divider>
            <TabPlanDetail></TabPlanDetail>
        </el-tab-pane>

    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="planInfo.info">
        <el-button type="primary" v-if="planInfo.info.state<=2" @click="btnSaveData">保存</el-button>
        <el-button v-if="planInfo.info.state <= 2" type="warning" @click="BoxCheckState.isVisible = true">审核</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 审核-弹框 -->
    <el-dialog title="计划单审核"
    v-model="BoxCheckState.isVisible"
    width="20%">
        <el-divider></el-divider>
        <div>计划单是否审核通过?</div>
        <template #footer>            
            <el-button type="primary" @click="btnUpdateState(3)">通过</el-button>
            <el-button type="danger" @click="btnUpdateState(2)">驳回</el-button>
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import TabPlan from '@/views/Pms/Plan/TabPlan.vue'
import TabPlanDetail from '@/views/Pms/Plan/TabPlanDetail.vue'

import moment from 'moment'
import { ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
export default {
    props:['editId','dataList'],
    components:{
        TabPlan,
        TabPlanDetail,
    },
    data(){
        return{      
            activeName:'tabPlan',
            planNumber:this.editId,  //加载数据编号
            //审核-弹出框
            BoxCheckState:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Purchase',['planInfo'])
    },
    methods:{
        ...mapMutations('Purchase',['SetPlanInfo']),

        //加载数据
        GetDataInfo(){
            if(this.planNumber!=0){
                let param = {
                    planNumber:this.planNumber
                }
                this.$api.erp.SinglePlanAll(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetPlanInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                }).catch(e=>{
                    console.log(e)
                })
            }else{
                this.btnClearData();
            }
        },

        //保存数据
        btnSaveData(){
            //#region 更新单位名称
            if(this.planInfo !=null && this.planInfo.detailList!=null && this.planInfo.detailList.length>0){
                this.planInfo.detailList = this.planInfo.detailList.map(detail=>{
                    if(detail.unitId !=null && detail.unitId !=0){
                        detail.skuUnitList.map(item=>{
                            if(detail.unitId == item.unitId){
                                detail.unitName = item.unitName;
                            }
                        })
                    }
                    return detail;
                });
            }
            //#endregion
            this.$api.erp.SavePlanAllInfo(this.planInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //关闭
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //提交审核状态
        btnUpdateState(state){
            let param = {
                planNumber:this.planNumber,
                state:state
            }
            this.$api.erp.UpdatePlanByState(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //更新
                    this.planInfo.info.state = state;
                    this.BoxCheckState.isVisible = false
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.planNumber === this.planNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.planNumber = prevInfo.planNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.planNumber === this.planNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.planNumber = prevInfo.planNumber
                this.GetDataInfo()
            }
        },

        //清空数据
        btnClearData(){
            let newInfo = {
                info:{
                    systemNumber:'',
                    planNumber:'',
                    departmentId:0,
                    state:1,
                    termTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                },
                detailList:[],   //明细
            }
            this.SetPlanInfo(newInfo);
        },

    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style scoped>
.SingleInfo {min-height: 200px;}
</style>