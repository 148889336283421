<template>
  <div>
      <div :style="{backgroundImage:'url('+themeInfo.cert+')',
      backgroudRepeat:'no-repeat',
      backgroundSize:'100%',
      height:'1028px'}">

        <table>
            <tr>
                <td>证书编号：{{recordInfo.certNumber}}</td>
            </tr>
            <tr>
                <td>评价主题：{{recordInfo.themeName}}</td>
            </tr>
            <tr>
                <td>企业：{{recordInfo.companyName}}</td>
            </tr>
            <tr>
                <td>得分：{{recordInfo.totalScore}}</td>
            </tr>
            <tr>
                <td>等级： {{recordInfo.levelCode}}</td>
            </tr>
            <tr>
                <td>标准: {{themeInfo.standard}}</td>
            </tr>
            <tr>
                <td>评审单位：{{themeInfo.assessor}}</td>
            </tr>
        </table>

      </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus/lib/components'
export default {
    name:'LookCert',
    props:['billNumber'],
    data(){
        return{
            themeInfo:[], // 主题信息
            recordInfo:[], //评价主信息
        }
    },
    methods:{
        GetDataInfo(){
            let param = {
                billNumber:this.billNumber
            }
            this.$api.service.SingleRecordInfo(param).then(res=>{
                if(res.data.status === 200){
                    this.recordInfo = res.data.data
                    //
                    if(this.recordInfo !=null){
                        let param2 = {
                            themeNumber:this.recordInfo.themeNumber
                        }
                        this.$api.service.SingleEvaluateTheme(param2).then(res2=>{
                            if(res2.data.status === 200){
                                this.themeInfo = res2.data.data
                                //
                                //console.log(this.themeInfo)
                            }
                        })
                    }
                    // console.log(this.recordInfo)
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        }
        
        
    },
    mounted(){
        this.GetDataInfo();
    }
}
</script>

<style>

</style>