<template>
  <div>
    <el-divider></el-divider>

    <div id="printData">
        <div class="title">
            <h3>物流单</h3>
        </div>
        <table class="DataInfo">
            <tr>
                <td>物流单号：{{transportInfo.info.transNumber}}</td>
                <td>部门：{{transportInfo.info.departmentName}}</td>
                <td>单据标题：{{transportInfo.info.title}}</td>
            </tr>
            <tr>
                <td>接单人：{{transportInfo.info.receiverName}}</td>
                <td>创建时间：{{transportInfo.info.createTime}}</td>
                <td></td>
            </tr>
        </table>
        <el-divider></el-divider>
        
        <div v-for="detailInfo in transportInfo.detailList" :key="detailInfo.billNumber" class="DataAll">
            <table class="DataInfo">
                <tr>
                    <td>
                        单据类型：
                        <span v-if="detailInfo.billType == 1">采购单</span>
                        <span v-if="detailInfo.billType == 2">销售单</span>
                        <span v-if="detailInfo.billType == 14">退供单</span>
                        <span v-if="detailInfo.billType == 15">退货单</span>
                    </td>
                    <td>单号：{{detailInfo.billNumber}}</td>
                    <td>创建时间：{{detailInfo.createTime}}</td>
                </tr>
                <tr>
                    <td>交易者：{{detailInfo.tradeName}}</td>
                    <td>地址：{{detailInfo.address}}</td>
                    <td></td>
                </tr>
            </table>
            <table class="DataDetail">
                <tr>
                    <th>产品类目</th>
                    <th>品牌</th>
                    <th>产品</th>
                    <th>规格</th>
                    <th>数量</th>
                </tr>
                <tr v-for="itemInfo in detailInfo.itemList" :key="itemInfo.id">
                    <td>{{itemInfo.cidName}}</td>
                    <td>{{itemInfo.brandName}}</td>
                    <td>{{itemInfo.spuName}}</td>
                    <td>{{itemInfo.skuName}}</td>
                    <td>{{itemInfo.realNum}}</td>
                </tr>
            </table>
        </div>
    </div>

    <div class="btnGroup">
        <el-button type="primary" class="iconfont icon-dayin" v-print="printObj" id="btnPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return{
            printObj: {
              id:'printData',
              preview: false,
              popTitle: '打印预览',
            }
        }
    },
    computed:{
        ...mapState('Erp',['transportInfo'])
    },
    methods:{
        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
    mounted(){
        document.getElementById("btnPrint").click();
    }
}
</script>

<style scoped>
#printData {font-size: 12px;overflow: hidden;} /**防止多打印一张 */
#printData .title {text-align: center;}
#printData .DataAll{padding-bottom: 15px;}
#printData .DataInfo{width: 100%;}
#printData .DataInfo td {padding: 5px 0px;}

#printData .DataDetail {width: 100%;border-collapse: collapse;}
#printData .DataDetail th {border: 1px solid #ccc;}
#printData .DataDetail td {border: 1px solid #ccc;}
</style>