<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <div class="editTab">
        <TabInfo></TabInfo>
    
        <el-divider>班次账户明细</el-divider>
        <TablDetail></TablDetail>
    </div>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="shiftRecordInfo.info">
        <el-button type="primary" v-if="shiftRecordInfo.info.state<=1" @click="btnSaveData">保存</el-button>
        <el-button type="danger" v-if="CheckUserButtonAuth('BtnShiftStat') && (shiftRecordInfo.info.state==2 || shiftRecordInfo.info.state==3 || shiftRecordInfo.info.state==5)" @click="BoxRecordDetail.isVisible=true">重新统计</el-button>
        <el-button type="warning" v-if="CheckUserButtonAuth('BtnShiftStartWork') && shiftRecordInfo.info.shiftNumber !='' && shiftRecordInfo.info.state==1" @click="btnShiftRecordState(2)">接班</el-button>      
        <el-button type="warning" v-if="CheckUserButtonAuth('BtnShiftEndWork') && shiftRecordInfo.info.state==2" @click="btnShiftRecordState(3)">交班</el-button>
        
        <el-button type="primary" v-if="CheckUserButtonAuth('BtnShiftSubmit') && (shiftRecordInfo.info.state==3 || shiftRecordInfo.info.state==5)" @click="btnShiftRecordState(4)">提交</el-button>
        <el-button type="warning" v-if="CheckUserButtonAuth('BtnShiftCheck') && shiftRecordInfo.info.state==4" @click="BoxCheck.isVisible=true">审核</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 账户统计-弹出框 -->
    <el-dialog title="班次统计"
    v-model="BoxRecordDetail.isVisible"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataInfo"
    width="60%">
        <BoxRecordDetail :editId="info.shiftNumber" @closeBox="btnCloseStatAccount"></BoxRecordDetail>
    </el-dialog>

    <!-- 审核-弹出框 -->
    <el-dialog title="班次账户审核"
    v-model="BoxCheck.isVisible"
    :close-on-click-modal="false"
    width="500px">
        <span>请确认本班次各账户是否审核完成?</span>

        <template #footer>
            <el-button type="primary" @click="btnCheckState(6)">通过</el-button>
            <el-button type="danger" @click="btnCheckState(5)">驳回</el-button>
            <el-button type="default" @click="BoxCheck.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import BoxRecordDetail from '@/views/Rms/ShiftRecord/BoxRecordDetail.vue'
import TabInfo from '@/views/Rms/ShiftRecord/TabInfo.vue'
import TablDetail from '@/views/Rms/ShiftRecord/TabDetail.vue'
import { mapMutations, mapState } from 'vuex';
import moment from 'moment';
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
export default {
    props:['editId','dataList'],
    components:{
        TabInfo,
        TablDetail,
        BoxRecordDetail,
    },
    data(){
        return{
            info:{
                shiftNumber:this.editId,  //当前班次编号
            },
            //账户统计-弹出框
            BoxRecordDetail:{
                isVisible:false,
            },
            //审核-弹出框
            BoxCheck:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Erp',['shiftRecordInfo'])
    },
    methods:{
        ...mapMutations('Erp',['SetShiftRecordInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.shiftNumber !=0){
                let param = {
                    shiftNumber:this.info.shiftNumber
                }
                this.$api.erp.GetRetailShiftRecordAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetShiftRecordInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }            
        },

        //保存
        btnSaveData(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            this.$api.erp.SaveRetailShiftRecordAllInfo(this.shiftRecordInfo).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //刷新页面
                    this.shiftRecordInfo.info.shiftNumber = res.data.data;
                    this.info.shiftNumber = res.data.data;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //更新状态
        btnShiftRecordState(state){
            let message = "更新班次状态";
            if(state === 2){
                message = "确认接班？";
            }
            if(state === 3){
                message = "提交后员工将不能再添加任何单据，是否交班？";
            }
            if(state === 4){
                message = "提交后将不能再添加任何单据，是否提交？";
            }
            ElMessageBox.confirm(message,'更新提示',{
                confirmButtonText:'确认',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                //打开遮罩层
                const loading = ElLoading.service({
                    lock:true,
                    text:'正在提交,请稍候!',
                    background:'rgba(0, 0, 0, 0.7)',
                })

                let param = {
                    shiftNumber:this.info.shiftNumber,
                    state:state,
                }
                this.$api.erp.UpdateShiftRecordState(param).then(res=>{
                    loading.close();
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        //更新状态
                        this.shiftRecordInfo.info.state = state;
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }).catch(()=>{
                //取消 
            })
        },

        //提交审核
        btnCheckState(state){
            //打开遮罩层
                const loading = ElLoading.service({
                    lock:true,
                    text:'正在提交,请稍候!',
                    background:'rgba(0, 0, 0, 0.7)',
                })

                let param = {
                    shiftNumber:this.info.shiftNumber,
                    state:state,
                }
                this.$api.erp.UpdateShiftRecordState(param).then(res=>{
                    loading.close();
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        //更新状态
                        this.shiftRecordInfo.info.state = state;
                        this.BoxCheck.isVisible = false;
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
        },

        //清空实体
        btnClearData(){
            var newInfo = {
                info:{
                    shiftNumber:'',  //班次编号
                    systemNumber:'',
                    departmentId:this.userDepartmentId,
                    retailGroupNumber:'',
                    state:1,
                    classDate:moment(new Date()).format("YYYY-MM-DD"),  //排班日期
                    classType:1,  //排班类型
                    isDel:0
                },
                detailList:[],
            }
            this.SetShiftRecordInfo(newInfo);
        },
                
        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //关闭统计账户页面
        btnCloseStatAccount(){
            this.BoxRecordDetail.isVisible=false
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.shiftNumber === this.info.shiftNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.shiftNumber = prevInfo.shiftNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.shiftNumber === this.info.shiftNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.shiftNumber = prevInfo.shiftNumber
                this.GetDataInfo()
            }
        },

    },
    mounted(){
        this.GetDataInfo();
    },
}
</script>

<style>

</style>