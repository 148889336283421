/**
 * 服务模块
 * 
 */

//评价功能
import ListTheme from '@/views/Service/Evaluate/Theme/ListTheme'
import ListTagScore from '@/views/Service/Evaluate/TagScore/ListTagScore'
import ListEvaluateRecord from '@/views/Service/Evaluate/Record/ListRecord.vue'
//我的评价记录
import ListMyRecord from '@/views/Service/Evaluate/MyRecord/ListMyRecord.vue'


//溯源码功能
//申请记录
import ListApply from '@/views/Service/Trace/Apply/ListApply.vue'
//流转记录
import ListFlow from '@/views/Service/Trace/Flow/ListFlow.vue'



//短信通知
import ListNoticeSms from '@/views/Service/Notice/Sms/ListNoticeSms.vue'

//功能介绍
import ListVideo from '@/views/Service/Video/ListVideo.vue'

export default [
    {
        //企业信用评价主题
        name:'ListTheme',
        path:'listtheme',
        component:ListTheme
    },
    {
        //企业信用评价指标评分
        name:'ListTagScore',
        path:'listtagscore',
        component:ListTagScore
    },

    {
        //评价记录列表
        name:'ListEvaluateRecord',
        path:'listevaluaterecord',
        component:ListEvaluateRecord
    },
    {
        //获取我的评价记录列表
        name:'ListMyRecord',
        path:'listmyrecord',
        component:ListMyRecord
    },

    {
        //溯源码-申请列表
        name:'ListApply',
        path:'listapply',
        component:ListApply
    },
    {
        //溯源码-流转记录
        name:'ListFlow',
        path:'listflow',
        component:ListFlow
    },

    {
        //短信服务
        name:'ListNoticeSms',
        path:'listnoticesms',
        component:ListNoticeSms
    },
    {
        //功能介绍
        name:'ListVideo',
        path:'listvideo',
        component:ListVideo
    },
]