<template>
  <div>
    <!-- 锁定库存框 -->
    <el-divider></el-divider>

    <el-descriptions
        class="margin-top"
        :column="1"
        border
    >
        <el-descriptions-item label="请确认要调整的锁定库存数">
            <el-input-number v-model="info.totalLockStockNum" :min="0" :precision="2" :controls="false" class="search-200"></el-input-number>
            <span>{{param.minUnitName}}</span>  <span>(最小单位)</span>
        </el-descriptions-item>
    </el-descriptions>

    <el-divider style="margin-top:20px;">锁定库存单据列表</el-divider>

    <el-table :data="info.infoList" border stripe show-summary :max-height="500">
        <el-table-column prop="departmentName" label="部门名称" show-overflow-tooltip></el-table-column>
        <el-table-column label="单据编号" show-overflow-tooltip>
            <template #default="scope">
                <span>{{scope.row.billNumber}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="spuName" label="产品名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="skuName" label="规格名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="lockNum" label="锁定数量">
            <template #header>
                <div>锁定数量</div>
                <div class="defUnitItem">(默认单位)</div>
            </template>
            <template #default="scope">
                <span class="defUnitItem">{{scope.row.lockNum}}</span>
                <span class="defUnitItem">{{scope.row.defUnitName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="minLockNum" label="锁定数量">
            <template #header>
                <div>锁定数量</div>
                <div>(最小单位)</div>
            </template>
            <template #default="scope">
                <span class="defUnitItem">{{scope.row.minLockNum}}</span>
                <span class="defUnitItem">{{param.minUnitName}}</span>
            </template>
        </el-table-column>
    </el-table>


    <div class="btnGroup">
        <el-button type="primary" @click="btnAdjustLockStock">确认</el-button>
        <el-button @click="btnClose">取消</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    props:['lockStockInfo'],  //锁定库存信息
    data(){
        return{
            //传过来的参数
            param:{
                stockId:this.lockStockInfo.stockId,  //库存编号
                minUnitName:this.lockStockInfo.minUnitName,  //最小单位名称
            },
            //获取到的数据
            info:{
                totalLockStockNum:0,  //总锁定库存数
                infoList:[],  //锁定库存单据列表
            }
        }
    },
    methods:{
        //获取锁定库存单据列表 
        GetDataList(){
            let param = {
                warehouseNumber:this.lockStockInfo.warehouseNumber,
                skuNumber:this.lockStockInfo.skuNumber,
            }
            this.$api.wms.GetLockStockBillList(param).then(res=>{
                if(res.data.status === 200){
                    this.info = res.data.data;
                }
            })
        },

        //保存-调整锁定库存
        btnAdjustLockStock(){
            let param = {
                id:this.param.stockId,
                lockStockNum:this.info.totalLockStockNum,
            }
            this.$api.wms.AdjustLockStock(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style scoped>
.defUnitItem{color: rgb(57, 116, 245);}
</style>