<template>
  <div>

    <el-divider></el-divider>

    <!-- 路由信息 -->
    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row>

            <el-col :span="8">
                <el-form-item label="IP地址">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>IP地址</span>
                    </template>
                    <el-input v-model="info.ip" :maxlength="40"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="国家">
                    <el-input v-model="info.country" :maxlength="40"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="省份">
                    <el-input v-model="info.province" :maxlength="40"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="城市">
                    <el-input v-model="info.city" :maxlength="90"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="区县">
                    <el-input v-model="info.county" :maxlength="90"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="经度">
                    <el-input-number v-model="info.longitude" :min="1" :precision="9" style="width:197px"></el-input-number>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="纬度">
                    <el-input-number v-model="info.latitude" :min="1" :precision="9" style="width:197px"></el-input-number>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary"  @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],
    data(){
        return{
            intId:this.editId,  //编号
            info:{
                id:0,
                ip:'',
                country:'',
                province:'',
                city:'',
                county:'',
                longitude:1,
                latitude:1,
            },
        }
    },
    methods:{
        //加载数据
        GetDataInfo(){
            if(this.intId !=0){
                let param = {
                    id:this.intId
                }
                this.$api.library.GetLibraryIp(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存数据
        btnSaveData(type){
            this.$api.library.SaveLibraryIp(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.id === this.intId
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.intId = prevInfo.id
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.id === this.intId
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.intId = prevInfo.id
                this.GetDataInfo()
            }
        },

        //清空数据
        btnClearData(){
            this.info = {
                id:0,
                ip:'',
                country:'',
                province:'',
                city:'',
                county:'',
                longitude:1,
                latitude:1,
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.GetDataInfo();
    },
}
</script>

<style>

</style>