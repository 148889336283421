<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
        v-model="info"
        label-width="100px"
        label-position="right">
            <el-row>

                <el-col :span="24">
                    <el-form-item label="所属部门">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>所属部门</span>
                        </template>
                        <el-tree-select v-model="info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="品牌">
                        <el-select v-model="info.brandId" class="search-200">
                            <el-option label="飞鹅" :value="1"></el-option>
                            <el-option label="芯烨云" :value="2"></el-option>
                        </el-select>
                    </el-form-item>                    
                </el-col>
                
                <el-col :span="24">
                    <el-form-item label="打印机编号">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>打印机编号</span>
                        </template>
                        <el-input v-model="info.printerNumber" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="打印机名称">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>打印机名称</span>
                        </template>
                        <el-input v-model="info.printerName" class="search-200"></el-input>
                    </el-form-item>
                </el-col>
                
                <el-col :span="24">
                    <el-form-item label="识别码">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>识别码</span>
                        </template>
                        <el-input v-model="info.printerCode" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="状态">
                        <el-select v-model="info.state" class="search-200">
                            <el-option :value="0" label="关闭"></el-option>
                            <el-option :value="1" label="开启"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="排序">
                        <el-input-number v-model="info.sort" class="search-200" :min="0" :max="100"></el-input-number>
                    </el-form-item>
                </el-col>

            </el-row>
      </el-form>

    <!-- 功能按钮 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button type="warning" @click="btnLookStatus">查看状态</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],
    mixins:[UserDptList],
    data(){
        return{
            info:{
                id:0,
                departmentId:0,
                brandId:1,
                printerNumber:'',
                printerName:'',
                printerCode:'',
                state:1,
                sort:0,
            },
            intId:this.editId,  //编号
        }
    },
    methods:{

        //加载数据
        GetDataInfo(){
            if(this.intId!=0){
                let param = {
                    id:this.intId
                }
                this.$api.system.SinglePrinter(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }else{
                        this.btnClearData();
                    }
                })
            }
        },

        //保存数据 type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            this.$api.system.SavePrinter(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //类型
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //清空数据
        btnClearData(){
            this.info = {
                id:0,
                departmentId:0,
                brandId:1,
                printerNumber:'',
                printerName:'',
                printerCode:'',
                state:1,
                sort:0,
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.id === this.intId
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.intId = prevInfo.id
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.id === this.intId
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.intId = prevInfo.id
                this.GetDataInfo()
            }
        },

        //获取打印机状态
        btnLookStatus(){
            let param = {
                printerNumber:this.info.printerNumber
            }
            this.$api.system.GetPrinterStatus(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        }

    },
    mounted(){
        this.GetDataInfo();  //加载数据
    }
}
</script>

<style>

</style>