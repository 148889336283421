<template>
  <div>
    <!-- 生成业务对账单 -->
    <el-divider></el-divider>

    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row>

            <el-col :span="12">
                <el-form-item label="部门">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>部门</span>
                    </template>
                    <el-tree-select v-model="info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200">
                    </el-tree-select>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="交易者">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>交易者</span>
                    </template>
                    <el-select v-model="info.tradeNumber" filterable clearable class="search-200">
                        <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="备注">
                    <el-input v-model="info.remark" :maxlength="50" class="search-200"></el-input>
                </el-form-item>
            </el-col>


        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import PartTradeList from '@/utils/mixins/PartTradeList.js'  //交易者列表
export default {
    mixins:[UserDptList,PartTradeList],
    props:['financeInfo'],
    data(){
        return{
            info:{
                cycleNumber:'',  //对账单号
                departmentId:0,
                tradeNumber:'',
                tradeType:0,
                tradeName:'',
                remark:'',
                numberList:[],
            }
        }
    },
    mounted(){
        this.info.numberList = this.financeInfo.numberList;
        //交易者编号
        if(this.financeInfo.tradeNumber !=''){
            this.info.tradeNumber = this.financeInfo.tradeNumber;
        }
        //部门
        if(this.financeInfo.departmentId !=0){
            this.info.departmentId = this.financeInfo.departmentId;
        }
    },
}
</script>

<style>

</style>