<template>
  <div>
    <el-divider></el-divider>
    <!-- 退料 选择加工单页面 -->

    <el-table :data="workInfo.materialList" border stripe style="width: 100%" @select="btnCheckbox" @select-all="btnAllCheckbox" @row-click="TableRowClick" ref="billDetailList">
        <el-table-column prop="id" type="selection"></el-table-column>
        <el-table-column prop="warehouseName" label="仓库" />
        <el-table-column prop="cidName" label="产品类目"></el-table-column>
        <el-table-column prop="brandName" label="品牌"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="90">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" />
        <el-table-column label="单位">
            <template v-slot="scope">
                <span>{{scope.row.unitName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="planNum" label="计划数量"></el-table-column>
        <el-table-column prop="realNum" label="已出库数量"></el-table-column>
    </el-table>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return{
            selectedList:[],  //选中的物料列表
        }
    },   
    computed:{
        ...mapState('Erp',['workInfo'])
    }, 
    methods:{

        //单选
        btnCheckbox(row){
            this.selectedList = row;
        },
        //全选
        btnAllCheckbox(){
            this.selectedList = this.workInfo.materialList;
        },

        //选中行时
        TableRowClick(row){
            let isExist = false; //判断是否存在
            this.selectedList = this.selectedList.filter(item=>{
                if(row.skuNumber != item.skuNumber){
                    return item;
                }else{
                    //如果存在
                    isExist = true;
                }
            })

            if(isExist){
                this.$refs.billDetailList.toggleRowSelection(row,false);
            }else{
                this.selectedList.push(row);
                this.$refs.billDetailList.toggleRowSelection(row,true);
            }
        },

    }
}
</script>

<style>

</style>