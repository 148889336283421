<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
        v-model="info"
        label-width="80px"
        label-position="right">
        <el-row>

            <el-col :span="24">
                <el-form-item label="模块名称">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>模块名称</span>
                    </template>
                    <el-input v-model="info.moduleName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="图标">
                    <el-input v-model="info.icon" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="是否显示">
                    <el-select v-model="info.isShow" class="search-200">
                        <el-option label="是" :value="1"></el-option>
                        <el-option label="否" :value="0"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="排序" >
                    <el-input-number v-model="info.sort" class="search-200"></el-input-number>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

    <!-- 功能按钮 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],
    data(){
        return{
            info:{
                moduleId:0,
                moduleName:'',
                isShow:1,
                sort:0
            },
            moduleId:this.editId,   //当前编号
        }
    },
    methods:{
        //加载数据
        GetDataInfo(){
            if(this.moduleId!=0){
                let param = {
                    moduleId:this.moduleId
                }
                this.$api.system.SingleSystemModule(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }
            else
            {
                this.btnClearData()
            }
        },

        //保存信息 -type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
          this.$api.system.SaveSystemModule(this.info).then(res=>{
            if(res.data.status === 200){
              ElMessage.success(res.data.msg);
              //类型
                if(type==1){
                    this.btnClose();
                }else{
                    this.btnClearData();
                }
            }
            else{
              ElMessage.error(res.data.msg)
            }
          }).catch(e=>{
            console.log(e)
          })          
        }, 

        //清空实体
        btnClearData(){
            this.info = {
                moduleId:0,
                moduleName:'',
                isShow:1,
                sort:0
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.moduleId === this.moduleId
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.moduleId = prevInfo.moduleId
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.moduleId === this.moduleId
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.moduleId = prevInfo.moduleId
                this.GetDataInfo()
            }
        },

    },
    mounted(){
        this.GetDataInfo();
    }
}
</script>

<style>

</style>