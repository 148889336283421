/**
 * 获取单个系统下的所有产品分类列表
 * ===下拉列表专用===
 */

const CategoryList = {
    data(){
        return{
            selectCategoryList:[]
        }
    },
    methods:{
        //加载产品分类下拉列表
        SelectCategoryList(){
            let param = {
                isShow:1
            }
            this.$api.goods.GetTreeCategoryList(param).then(res=>{
                if(res.data.status === 200){
                    this.selectCategoryList = res.data.data
                }else{
                    console.log(res.data.msg);
                }
            }).catch(e=>{
                console.log(e.message)
            })
        }
    },
    mounted(){
        this.SelectCategoryList()   //加载产品分类列表
    }
}

export default CategoryList