/**
 * 登录模块接口列表
 */
//  import QS from 'qs'
import base from '../base'   //导入接口域名列表
import axios from '@/utils/http'   //导入http中创建的axios实例


const login = {
    //登录提交
    UserLogin(params){
        return axios.post(`${base.base}/System/LoginSystemUser`,params)
    },

    //获取验证码
    GetCaptcha(){
        return axios.get(`${base.base}/System/GetCaptcha`)
    },
    
    
}

export default login

