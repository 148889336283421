<template>
  <div>

    <!-- 按钮 -->
    <div class="skuBtnList">
        <el-button class="iconfont icon-add" type="primary" plain @click="AddRecord">新增</el-button>
    </div>

    <!-- 列表 -->
    <el-table :data="staffInfo.recordList" border style="width: 100%">

        <el-table-column prop="skuName" label="备注类型">
            <template v-slot="scope">
                <span v-if="scope.row.type == 1">文字</span>
                <span v-if="scope.row.type == 2">图片</span>
                <span v-if="scope.row.type == 3">文件</span>
                <span v-if="scope.row.type == 4">视频</span>
            </template>
        </el-table-column>

        <el-table-column prop="skuName" label="备注内容">
            <template v-slot="scope">
                <!-- 文字 -->
                <span v-if="scope.row.type==1" >{{scope.row.content}}</span>
                <!-- 图片 -->
                <el-image v-if="scope.row.type==2"
                    style="width: 100px; height: 100px"
                    :src="scope.row.content"
                    :preview-src-list="[scope.row.content]"
                    fit="cover"
                    />
                <!-- 文件 -->
                <el-link v-if="scope.row.type==3" :href="scope.row.content" target="_blank">{{scope.row.title}}</el-link>
            </template>
        </el-table-column>

        <el-table-column prop="createName" label="创建者">
            <template v-slot="scope">
                <span>{{scope.row.createName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="createName" label="创建时间">
            <template v-slot="scope">
              <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="160" fixed="right">
            <template v-slot="scope">
                <el-popconfirm 
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.billNumber,scope.row.id,scope.$index)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>
    

    <!-- 备注信息 - 弹出框 -->
    <el-dialog :title="BoxRecord.title" 
        v-model="BoxRecord.isVisible"
        width="40%"
        :destroy-on-close="true">
        <NewRecord ref="RecordInfo"></NewRecord>
        <template #footer>
            <el-button type="primary" @click="SaveRecordInfo">保存</el-button>
            <el-button @click="BoxRecord.isVisible = false">取消</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from "moment";
import NewRecord from '@/components/common/NewRecord.vue'
import { ElMessage } from 'element-plus';
export default {
    components:{
        NewRecord
    },
    data(){
        return{
            //备注-弹出框
            BoxRecord:{
                isVisible:false,
                title:'备注信息'
            }
        }
    },
    computed:{
        ...mapState('Oa',['staffInfo'])
    },
    methods:{
        //新增备注
        AddRecord(){
            this.BoxRecord.isVisible = true
        },
        //保存备注信息
        SaveRecordInfo(){
            let newRecordInfo = this.$refs.RecordInfo.info;
            let recordInfo = {
                id:0,
                systemNumber:'',
                billNumber:this.staffInfo.info.userNumber,
                billType:35,  //人事档案
                title:newRecordInfo.title,
                content:newRecordInfo.content,
                type:newRecordInfo.type,
                createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                creator:'',
                createName:newRecordInfo.createName,
                isdel:0
            }
            this.staffInfo.recordList.push(recordInfo)  //新增备注信息
            this.BoxRecord.isVisible = false  //关闭备注弹出框
            //直接保存到数据库
            if(this.staffInfo.info.id !=0){
                this.$api.erp.SaveRecord(recordInfo).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }
        },
        
        //删除行
        DeleteRow(billNumber,id,index){
            this.staffInfo.recordList.splice(index,1);
            if(id!=0){
                let param = {
                    billNumber:billNumber,
                    id:id
                }
                this.$api.erp.DeleteRecord(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }
            
        }
    },

}
</script>

<style>

</style>