<template>
  <div>
    <!-- 编辑物流单页 -->
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName">
        <el-tab-pane label="物流单" name="tabPurchase">
            <TabTransport></TabTransport>

            <TabTransportDetail></TabTransportDetail>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabTransportRecord></TabTransportRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabTransportState></TabTransportState>
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="btnGroup" v-if="transportInfo.info">
        <el-button type="primary" v-if="transportInfo.info.state ==1 " @click="btnSaveData">保存</el-button>
        <el-button type="warning" v-if="transportInfo.info.state ==1 " @click="UpdateTransportState(2)">锁单</el-button>
        <el-button type="primary" v-if="transportInfo.info.state == 2" @click="UpdateTransportState(3)" >接单</el-button>
        <el-button type="success" v-if="transportInfo.info.state == 3 "  @click="UpdateTransportState(4)" >确认完成</el-button>
        <el-button class="iconfont icon-dayin" @click="btnShowBoxPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 打印框 -->
    <el-dialog title="打印预览"
    v-model="BoxPrint.isVisible"
    width="70%">
        <PrintView @closeBox="btnClosePrint"></PrintView>
    </el-dialog>

  </div>
</template>

<script>
import TabTransport from '@/views/Tms/Transport/TabTransport.vue'
import TabTransportDetail from '@/views/Tms/Transport/TabTransportDetail.vue'
import TabTransportState from '@/views/Tms/Transport/TabTransportState.vue'
import TabTransportRecord from '@/views/Tms/Transport/TabTransportRecord.vue'
import PrintView from '@/views/Tms/Transport/PrintView.vue'

import { mapMutations, mapState } from 'vuex'
import { ElMessage } from 'element-plus'

export default {
    props:['editId','dataList'],
    components:{
        TabTransport,
        TabTransportDetail,
        TabTransportState,
        TabTransportRecord,
        PrintView
    },
    data(){
        return{
            info:{
                activeName:'tabPurchase',
                transNumber:this.editId,
            },
            //打印框
            BoxPrint:{
                isVisible:false,
            }
            
        }
    },
    computed:{
        ...mapState('Erp',['transportInfo'])
    },
    methods:{
        ...mapMutations('Erp',['SetTransportInfo']),

        //加载数据信息
        GetDataInfo(){
            if(this.info.transNumber != 0){
                let param = {
                    transNumber:this.info.transNumber
                }
                this.$api.erp.SingleTransportAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetTransportInfo(res.data.data)
                    }
                    else{
                        var newInfo = {
                            info:{
                                id:0,
                                systemNumber:'',
                                transNumber:'',
                                departmentId:0,
                                title:'',
                                state:1,
                                receiver:'',
                                receiverName:'',
                                receiveTime:'',
                                createTime:'',
                                creator:''
                            },
                            recordList:[],
                            stateList:[]
                        }
                        this.SetTransportInfo(newInfo)
                    }
                })
            }
            else
            {
                var newInfo = {
                            info:{
                                id:0,
                                systemNumber:'',
                                transNumber:'',
                                departmentId:0,
                                title:'',
                                state:1,
                                receiver:'',
                                receiverName:'',
                                receiveTime:'',
                                createTime:'',
                                creator:''
                            },
                            recordList:[],
                            stateList:[]
                        }
                this.SetTransportInfo(newInfo)
            }
        },

        //保存-物流单信息
        btnSaveData(){
            this.$api.erp.SaveTransportAllInfo(this.transportInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose()
                }
                else
                {
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e.message)
            })
        },

        //更新物流单状态
        UpdateTransportState(intState){
            let param = {
                transNumber:this.transportInfo.info.transNumber,
                state:intState
            }
            this.$api.erp.UpdateTransportState(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    //更新状态
                    this.transportInfo.info.state = intState
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.transNumber === this.info.transNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.transNumber = prevInfo.transNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.transNumber === this.info.transNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.transNumber = prevInfo.transNumber
                this.GetDataInfo()
            }
        },

        //打开打印框
        btnShowBoxPrint(){
            this.BoxPrint.isVisible =true;
        },
        //关闭打印框
        btnClosePrint(){
            this.BoxPrint.isVisible =false;
        },

    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style>

</style>