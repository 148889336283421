<template>
  <div>
    <el-divider></el-divider>

    <el-tabs v-model="info.activeName">
        <el-tab-pane label="档案信息" name="tabInfo">
            <TabStaff></TabStaff>
        </el-tab-pane>

        <el-tab-pane label="档案文件" name="tabFile">
            <TabStaffRecord></TabStaffRecord>
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary"  @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import TabStaff from '@/views/Oa/Staff/TabStaff.vue'
import TabStaffRecord from '@/views/Oa/Staff/TabStaffRecord.vue'
import { mapMutations, mapState } from 'vuex'
import { ElMessage } from 'element-plus'
import moment from 'moment'
export default {
    props:['editId','dataList'],
    components:{
        TabStaff,
        TabStaffRecord,
    },
    data(){
        return{
            info:{
                userNumber:this.editId,   //用户编号
                activeName:'tabInfo',
            }            
        }
    },
    computed:{
        ...mapState('Oa',['staffInfo'])
    },
    methods:{
        ...mapMutations('Oa',['SetStaffInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.userNumber !=0){
                let param = {
                    userNumber:this.info.userNumber
                }
                this.$api.hr.SingleStaffAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetStaffInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存信息 -type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            this.$api.hr.SaveStaffAllInfo(this.staffInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //类型
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //清空数据
        btnClearData(){
            let newInfo = {
                info:{
                    id:0,
                    systemNumber:'',
                    userNumber:'',
                    cardId:'',
                    entryDate:moment(new Date()).format("YYYY-MM-DD"),
                    annualLeave:0,
                    clockInType:1,
                    classesId:0
                },
                recordList:[],
            }
            this.SetStaffInfo(newInfo);
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.userNumber === this.info.userNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.userNumber = prevInfo.userNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.userNumber === this.info.userNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.userNumber = prevInfo.userNumber
                this.GetDataInfo()
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },


    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style>

</style>