<template>
  <div>
    
    <el-table :data="orderInfo.stateList" border style="width: 100%">
      <el-table-column label="状态">
          <template #default="scope">
              <el-tag v-if="scope.row.state == 1">待支付</el-tag>
              <el-tag v-if="scope.row.state == 2">待发货</el-tag>
              <el-tag v-if="scope.row.state == 3">待收货</el-tag>
              <el-tag v-if="scope.row.state == 4">已完成</el-tag>
              <el-tag v-if="scope.row.state == 5">售后中</el-tag>
              <el-tag v-if="scope.row.state == 6">已取消</el-tag>
          </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template #default="scope">
            <span>{{DateFormat(scope.row.createTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createName" label="创建人"></el-table-column>
      
    </el-table>

    <div class="DetailHeight"></div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name:'TabOrderState',
  computed:{
    ...mapState('Shop',['orderInfo'])
  },
}
</script>

<style scoped>
.DetailHeight{height: 20px;}
</style>