<template>
  <div>
    <el-divider></el-divider>

    <!-- 评价主信息 -->
    <el-descriptions :column="3" border>
      <el-descriptions-item label="评价主题">{{recordInfo.info.themeName}}</el-descriptions-item>
      <el-descriptions-item label="申请企业">{{recordInfo.info.companyName}}</el-descriptions-item>
      <el-descriptions-item label="得分">{{recordInfo.info.totalScore}}</el-descriptions-item>
      <el-descriptions-item label="等级">{{recordInfo.info.levelCode}}</el-descriptions-item>
      <el-descriptions-item label="状态">
        <el-tag size="small" v-if="recordInfo.info.state ==1" type="danger">未评价</el-tag>
        <el-tag size="small" v-if="recordInfo.info.state ==2">已评价</el-tag>
      </el-descriptions-item>
    </el-descriptions>

    <!-- 评价记录明细 -->    
    <el-scrollbar height="400px">
      <el-table :data="recordInfo.detailList" border>
        <el-table-column prop="oneTagName" label="一级指标" width="100" />
        <el-table-column prop="twoTagName" label="二级指标" width="100" />
        <el-table-column prop="threeTagName" label="三级指标" width="100" />
        <el-table-column prop="tagExplain" label="指标说明" />
        <el-table-column prop="tagScore" label="指标分值" width="90" />
        <el-table-column label="评分" width="150">
          <template v-slot="scope">
            <el-input-number v-model="scope.row.score" :min="0" :max="scope.row.tagScore" size="small"></el-input-number>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>

    <!-- 按钮 -->
    <div class="btnGroup">
      <el-button type="primary" v-if="recordInfo.info.state ===1" @click="btnSaveRecordDetail()">保存</el-button>
      <el-button @click="btnClose">关闭</el-button>
    </div>
    
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name:'EditRecordDetail',
  props:['billNumber'],
  data(){
    return{
      recordInfo:{
        info:{
          id:0,
          systemNumber:'',
          billNumber:'',
          themeNumber:'',
          applyNumber:'',
          companyName:'',
          totalScore:0,
          state:0,
        },  //评价记录
        detailList:[],   //明细列表
      }
      
    }
  },
  methods:{
    //加载数据信息
    GetDataInfo(){
      let param = {
        billNumber:this.billNumber
      }
      this.$api.service.SingleRecordAllInfo(param).then(res=>{
        if(res.data.status === 200){
          this.recordInfo = res.data.data;
          if(this.recordInfo.info.state == 1){
            //未评价
            let param2 = {
              themeNumber:this.recordInfo.info.themeNumber,
              page:1,
              size:100
            }
            this.$api.service.ListTagScore(param2).then(res2=>{
              if(res2.data.status === 200){
                this.recordInfo.detailList = res2.data.data;
                if(this.recordInfo.detailList != null && this.recordInfo.detailList.length > 0){
                  this.recordInfo.detailList = this.recordInfo.detailList.map(item=>{
                    item.score = 0;
                    item.billNumber = this.recordInfo.info.billNumber;
                    return item
                  })
                }
              }
            })
          }
        }
      })
    },

    //保存-评价记录明细
    btnSaveRecordDetail(){
        this.$api.service.SaveRecordDetail(this.recordInfo.detailList).then(res=>{
            if(res.data.status === 200){
                this.btnClose();       
                ElMessage.success(res.data.msg);
            }else{
                ElMessage.error(res.data.msg)
            }
        })
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },


  },
  mounted(){
    this.GetDataInfo()
  }
}
</script>

<style scoped>
.el-table {width: 100%;margin-top: 20px;}
</style>