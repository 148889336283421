<template>
  <div>
      <div class="skuBtnList">
        <el-button class="iconfont icon-add" type="primary" plain @click="AddRow">新增等级</el-button>
    </div>

    <!-- 列表 -->
    <el-table :data="evaluateInfo.levelList" border style="width: 100%">

      <el-table-column label="等级编码">
        <template v-slot="scope">
            <el-input v-if="scope.row.isEdit==1" v-model="scope.row.levelCode"></el-input>
            <span v-else >{{scope.row.levelCode}}</span>
        </template>
      </el-table-column>

      <el-table-column label="等级说明">
        <template v-slot="scope">
            <el-input v-if="scope.row.isEdit==1" v-model="scope.row.levelName"></el-input>
            <span v-else >{{scope.row.levelName}}</span>
        </template>
      </el-table-column>

      <el-table-column label="最低评分">
        <template v-slot="scope">
            <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minScore" :min="0" :max="100" :precision="2" size="small"></el-input-number>
            <span v-else >{{scope.row.minScore}}</span>
        </template>
      </el-table-column>

      <el-table-column label="最高评分">
        <template v-slot="scope">
            <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.maxScore" :min="0" :max="100" :precision="2" size="small"></el-input-number>
            <span v-else >{{scope.row.maxScore}}</span>
        </template>
      </el-table-column>

      <el-table-column label="排序">
        <template v-slot="scope">
            <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.sort" :min="0" size="small"></el-input-number>
            <span v-else >{{scope.row.sort}}</span>
        </template>
      </el-table-column>


      <el-table-column label="操作" width="160" fixed="right">
          <template v-slot="scope">
              <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.levelId)">编辑</el-button>
              <el-popconfirm 
              title="确认要删除吗?"
              @confirm="DeleteRow(scope.row.levelId,scope.$index)">
                  <template #reference>
                  <el-button size="small" type="danger">删除</el-button>
                  </template>
              </el-popconfirm>
          </template>
      </el-table-column>

    </el-table>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus/lib/components'
import { mapState } from 'vuex'
export default {
    name:'TabLevel',
    computed:{
        ...mapState('Purchase',['evaluateInfo']),
    },
    methods:{

      //添加行
      AddRow(){
        let row = {
          levelId:0,
          systemNumber:'',
          themeNumber:'',
          levelCode:'',
          levelName:'',
          minScore:0,
          maxScore:0,
          sort:0,
          isDel:0,
          isEdit:1
        }
        this.evaluateInfo.levelList.push(row)
      },

      //编辑行
      EditRow(levelId){
        this.evaluateInfo.levelList = this.evaluateInfo.levelList.map(item=>{
          if(item.levelId === levelId){
            item.isEdit = 1
          }
          return item
        })
      },

      //删除行
      DeleteRow(levelId,index){
        this.evaluateInfo.levelList.splice(index,1)
        if(levelId !== 0){
          //删除数据库操作
          let param = [levelId]
          this.$api.evaluate.DeleteEvaluateLevel(param).then(res=>{
            if(res.data.status === 200){
              ElMessage.success(res.data.msg)
            }
            else
            {
              ElMessage.error(res.data.msg)
            }
          })
        }
      }

    },

}
</script>

<style>
</style>