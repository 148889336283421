<template>
  <div>

    <el-form :inline="true"
    v-model="roleInfo.info"
    label-width="80px"
    label-position="right">
        <el-row v-if="roleInfo.info">

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="角色名称">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>角色名称</span>
                    </template>
                    <el-input v-model="roleInfo.info.roleName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="备注">
                    <el-input v-model="roleInfo.info.mark" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="排序">
                    <el-input-number v-model="roleInfo.info.sort" class="search-200"></el-input-number>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('System',['roleInfo'])
    },

}
</script>

<style>

</style>