<template>
  <div>
    
    <el-form :inline="true"
    v-model="moveInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="moveInfo.info">

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="移仓单号">
                    <el-input v-model="moveInfo.info.moveNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="移出部门">
                    <el-tree-select v-if="moveInfo.info.state==1 || moveInfo.info.state==3" v-model="moveInfo.info.outDepartmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                    <el-input v-else v-model="moveInfo.info.outDepartmentName" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="移出大区">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>移出大区</span>
                    </template>
                    <el-select v-if="moveInfo.info.state==1 || moveInfo.info.state==3" v-model="moveInfo.info.outAreaNumber" class="search-200">
                        <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                    </el-select>
                    <el-input v-else v-model="moveInfo.info.outAreaName" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="移入部门">
                    <el-tree-select v-if="moveInfo.info.state==1 || moveInfo.info.state==3" v-model="moveInfo.info.inDepartmentId" :data="allDptList" :default-expand-all="true" class="search-200"/>
                    <el-input v-else v-model="moveInfo.info.inDepartmentName" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="移入大区">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>移入大区</span>
                    </template>
                    <el-select v-if="moveInfo.info.state==1 || moveInfo.info.state==3" v-model="moveInfo.info.inAreaNumber" class="search-200" @change="UpdateAreaWhList">
                        <el-option v-for="userArea in allAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                    </el-select>
                    <el-input v-else v-model="moveInfo.info.inAreaName" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>


            <el-col :md="8" :sm="12" :xs="24">
              <el-form-item label="备注说明">
                <el-input v-if="moveInfo.info.state==1 || moveInfo.info.state==3" v-model="moveInfo.info.remark" :max="50" class="search-200"></el-input>
                <el-input v-else v-model="moveInfo.info.remark" disabled class="search-200"></el-input>
              </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="状态">
                  <el-tag v-if="moveInfo.info.state ==1">新建单</el-tag>
                  <el-tag v-if="moveInfo.info.state ==2" type="warning">待审核</el-tag>
                  <el-tag v-if="moveInfo.info.state ==3" type="danger">已驳回</el-tag>
                  <el-tag v-if="moveInfo.info.state ==4" >已审核</el-tag>
                  <el-tag v-if="moveInfo.info.state ==5" type="warning">已出库</el-tag>
                  <el-tag v-if="moveInfo.info.state ==8" >已入库</el-tag>
                  <el-tag v-if="moveInfo.info.state ==10" type="success">已完成</el-tag>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'  //用户授权的部门列表
import AllDptList from '@/utils/mixins/AllDptList.js'  //全部部门列表
import { mapState } from 'vuex'
export default {
    mixins:[UserDptList,AllDptList],
    data(){
      return{
        allAreaList:[],   //全部大区列表
        userAreaList:[],    //用户关联的大区列表
      }
    },
    computed:{
        ...mapState('Erp',['moveInfo'])
    },
    methods:{

 
      //加载全部大区列表
      LoadAreaList(){
          this.$api.system.SelectSystemAreaList().then(res=>{
              if(res.data.status === 200){
                  this.allAreaList = res.data.data;
                  //新增时，默认加载第一个大区
                  if(this.allAreaList.length>0 && this.moveInfo.info.moveNumber==''){
                    this.moveInfo.info.inAreaNumber = this.allAreaList[0].areaNumber;
                  }
              }else{
                  this.allAreaList = []
              }
          })
      },

      //加载用户关联的大区列表
      SelectUserAreaList(){
          this.$api.system.SelectUserAreaList().then(res=>{
              if(res.data.status === 200){
                  this.userAreaList = res.data.data;
                  //新增时，默认加载第一个大区
                  if(this.userAreaList.length>0 && this.moveInfo.info.moveNumber==''){
                      this.moveInfo.info.outAreaNumber = this.userAreaList[0].areaNumber;
                  }
              }else{
                  this.userAreaList = [];
              }
          })
      },

      //当移入大区发生改变时-更新移入大区关联的仓库
      UpdateAreaWhList(){
          this.$emit('OnChangeArea');
      },


    },
    mounted(){
      this.LoadAreaList();  //加载全部大区列表
    },
}
</script>

<style>

</style>