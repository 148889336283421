<template>
  <div>
    <el-divider></el-divider>

    <!-- 加工组 -->
    <TabGroupInfo></TabGroupInfo>

    <el-divider>加工组成员</el-divider>
    <TabGroupMember></TabGroupMember>

    <!-- 功能按钮 -->
    <div class="btnGroup">      
      <el-button type="primary" @click="btnSaveInfo()">保存</el-button>
      <el-button @click="btnClose">关闭</el-button>

      <el-button-group>
          <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
          <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
      </el-button-group>
    </div>

  </div>
</template>

<script>
import TabGroupInfo from '@/views/Work/WorkGroup/TabGroupInfo.vue'
import TabGroupMember from '@/views/Work/WorkGroup/TabGroupMember.vue'
import { mapMutations, mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],  
    components:{
        TabGroupInfo,
        TabGroupMember,
    },
    data(){
        return{
            info:{
                workGroupNumber:this.editId,   //加工组编号
            }
        }
    },
    computed:{
        ...mapState('Erp',['workGroupInfo'])
    },
    methods:{
        ...mapMutations('Erp',['SetWorkGroupInfo']),

        //获取信息
        GetDataInfo(){
            if(this.info.workGroupNumber !=0){
                let param = {
                    workGroupNumber:this.info.workGroupNumber,
                }
                this.$api.erp.GetWorkGroupAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetWorkGroupInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存
        btnSaveInfo(){
            this.$api.erp.SaveWorkGroupAllInfo(this.workGroupInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.workGroupNumber === this.info.workGroupNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.workGroupNumber = prevInfo.workGroupNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.workGroupNumber === this.info.workGroupNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.workGroupNumber = prevInfo.workGroupNumber
                this.GetDataInfo()
            }
        },

        //清空数据实体
        btnClearData(){
            let newInfo = {
                info:{
                    systemNumber:'',
                    workGroupName:'',
                    departmentId:0,
                },
                memberList:[],
            }
            this.SetWorkGroupInfo(newInfo);
        },


    },
    mounted(){
        this.GetDataInfo();
    }
}
</script>

<style>

</style>