<template>
  <div>

    <!-- 物流单详情 -->
    <div v-for="(detailInfo,index) in transportInfo.detailList" :key="detailInfo.billNumber">
        <el-divider>物流单明细-{{index+1}}</el-divider>
        <!-- 主信息 -->
        <el-descriptions border>
            <template #extra>
                <el-popconfirm
                title="确认要删除吗?"
                @confirm="DeleteTransportOrigin(detailInfo.billNumber,index)">
                    <template #reference>
                        <el-button v-if="transportInfo.info.state == 1" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>                
            </template>
            <el-descriptions-item>
                <template #label>
                    单据类型
                </template>
                    <span v-if="detailInfo.billType == 1">采购单</span>
                    <span v-if="detailInfo.billType == 2">销售单</span>
                    <span v-if="detailInfo.billType == 14">退供单</span>
                    <span v-if="detailInfo.billType == 15">退货单</span>
            </el-descriptions-item>
            <el-descriptions-item>
                <template #label>
                    单号
                </template>
                    {{detailInfo.billNumber}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template #label>
                    创建时间
                </template>
                    {{detailInfo.createTime}} 
            </el-descriptions-item>
            <el-descriptions-item>
                <template #label>
                    交易者
                </template>
                    {{detailInfo.tradeName}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template #label>
                    地址
                </template>
                    {{detailInfo.address}}
            </el-descriptions-item>
        </el-descriptions>
        <br/>
        <!-- 商品列表 -->
        <el-table border :data="detailInfo.itemList">
            <el-table-column prop="cidName" label="产品类目"></el-table-column>
            <el-table-column prop="brandName" label="品牌"></el-table-column>
            <el-table-column prop="spuName" label="产品"></el-table-column>
            <el-table-column prop="skuName" label="规格"></el-table-column>
            <el-table-column prop="unitName" label="单位"></el-table-column>
            <el-table-column prop="realNum" label="数量"></el-table-column>
            
        </el-table>
        <br/>
                
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Erp',['transportInfo'])
    },
    methods:{
        //删除物流单关联的原始单据
        DeleteTransportOrigin(originNumber,index){
            let param = {
                transNumber:this.transportInfo.info.transNumber,
                originNumber:originNumber
            };
            this.$api.erp.DeleteTransportOrigin(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //删除页面
                    this.transportInfo.detailList.splice(index,1)
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        }
    }
}
</script>

<style>

</style>