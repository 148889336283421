<template>
  <div>
    <!-- 全年财务大数据页面 -->
    
    <!-- 标题 -->
    <el-row>
        <el-col :span="24" class="title">
            <h3>全年财务统计</h3>
        </el-col>        
    </el-row>

    <el-row>
        <el-col :span="24" class="title">
            年份：<el-date-picker
                    v-model="currentYear"
                    :editable="false"
                    :clearable="false"
                    type="year"
                    size="default"
                    @change="LoadDataView"
                />
        </el-col>
    </el-row>

    <!-- 主项 -->
    <el-row :gutter="10">

        <el-col :span="3"></el-col>
        <el-col :span="6" class="singleItem">总收入：<span class="mainPrice">{{info.totalShouRu}}</span>万元</el-col>
        <el-col :span="6" class="singleItem">总支出：<span class="mainPrice">{{info.totalZhiChu}}</span>万元</el-col>
        <el-col :span="6" class="singleItem">总利润：<span class="mainPrice">{{info.totalProfit}}</span>万元</el-col>
        <el-col :span="3" class="singleItem"></el-col>

    </el-row>

    <!-- 统计图 -->
    <div id="main" class="echarts" style="width:100%;height:500px;" ></div>
    <TabYearFinanceType ref="tabInfo"></TabYearFinanceType>
    <TabYearFinanceTable ref="tabSub"></TabYearFinanceTable>
    <div class="dataSource">
        数据来源：财务单
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'  //Echarts 图标组件
import moment from 'moment';
import TabYearFinanceType from '@/views/Ams/BigData/TabYearFinanceType.vue'
import TabYearFinanceTable from '@/views/Ams/BigData/TabYearFinanceTable.vue'
export default {
    components:{
      TabYearFinanceType,
      TabYearFinanceTable
    },
    data(){
        return{
            currentYear:moment(this.currentYear).format("YYYY"),
            info:{
                totalShouRu:0,  //总收入
                totalZhiChu:0,  //总支出
                totalProfit:0,  //利润
                shouRuList:[0,0,0,0,0,0,0,0,0,0,0,0],  //收入
                zhiChuList:[0,0,0,0,0,0,0,0,0,0,0,0],  //支出
                profitList:[0,0,0,0,0,0,0,0,0,0,0,0],  //利润
            }
        }
    },
    methods:{
        //示例
        myEcharts(){
            var myChart = echarts.init(document.getElementById('main'));
            
            //配置图表
            var option = {
              tooltip: {
                trigger: 'axis',   //显示标签
              },
              legend:{
                top:'bottom',
                data:['支出','收入','利润'] //示例图
              },
              xAxis: {
                type: 'category',
                data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
              },
              yAxis: [
                    {
                        type: 'value',
                        name:'金额',
                        position: 'left',
                        axisLabel: {
                            formatter: '{value} 万元'
                        }
                    },
              ],
              series: [
                {
                  name:'支出',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.zhiChuList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#33ccff'
                  },
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 万元'
                    }
                  },
                },
                {
                  name:'收入',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.shouRuList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#DC143C'
                  },  //颜色
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 万元'
                    }
                  },
                },
                {
                  name:'利润',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.profitList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#FF4500'
                  },  //颜色
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 万元'
                    }
                  },
                },
                
              ]
            };
            myChart.setOption(option);
        },

        //加载数据
        async LoadDataView(){
          let strYear = moment(this.currentYear).format("YYYY")
          let param = {
            year:strYear
          }

          await this.$api.stat.GetStatYearFinance(param).then(res=>{
            if(res.data.status === 200){
              this.info = res.data.data;
              this.myEcharts();
              this.$refs.tabInfo.LoadDataView(strYear);  //分类
              this.$refs.tabSub.LoadDataView(strYear);  //科目
            }
          })
          //
          
          
        }

    },
    mounted(){
        this.LoadDataView();
    }
}
</script>

<style scoped>

.title{text-align: center;}
.singleItem{padding-top: 15px;font-size: 14px;}
.mainPrice{color: #cc0033;font-size: 25px;}
.dataSource{margin-left: 140px;font-size: 14px;color: #ccc;}
</style>