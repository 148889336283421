<template>
  <div>
    <!-- 查看退零单 -->
    <el-divider></el-divider>

    <!-- 主表信息 -->
    <el-descriptions border v-if="allInfo.info">
        <el-descriptions-item label="退零单号">{{allInfo.info.billNumber}}</el-descriptions-item>
        <el-descriptions-item label="所属班次">{{allInfo.info.shiftName}}</el-descriptions-item>
        <el-descriptions-item label="所属部门">{{allInfo.info.departmentName}}</el-descriptions-item>
        <el-descriptions-item label="交易者类型">
            <span v-if="allInfo.info.tradeType==1">供应商</span>
            <span v-if="allInfo.info.tradeType==2">客户</span>
            <span v-if="allInfo.info.tradeType==3">员工</span>
            <span v-if="allInfo.info.tradeType==4">散客</span>
        </el-descriptions-item>
        <el-descriptions-item label="交易者">{{allInfo.info.tradeName}}</el-descriptions-item>
        <el-descriptions-item label="付款账户">{{allInfo.info.accountName}}</el-descriptions-item>
        <el-descriptions-item label="应付金额(元)">{{myRound(allInfo.info.totalPrice,2)}}</el-descriptions-item>
        <el-descriptions-item label="实付金额(元)">{{myRound(allInfo.info.realPrice,2)}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{allInfo.info.createTime}}</el-descriptions-item>
        <el-descriptions-item label="付款时间">{{allInfo.info.payTime}}</el-descriptions-item>
        <el-descriptions-item label="状态">
            <el-tag v-if="allInfo.info.state ==1">未支付</el-tag>
            <el-tag v-if="allInfo.info.state ==5">已支付</el-tag>
            <el-tag v-if="allInfo.info.state ==9">已取消</el-tag>
        </el-descriptions-item>
    </el-descriptions>

    <br/>

    <!-- 明细表列表 -->
    <el-table :data="allInfo.detailList" border show-summary style="width: 100%">
        <el-table-column prop="warehouseName" label="仓库" min-width="90"/>
        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="90">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" min-width="90"/>
        <el-table-column label="单位" width="100">
            <template v-slot="scope">
                <span>{{scope.row.unitName}}</span>
            </template>
        </el-table-column>

        <el-table-column label="成本单价(元)" min-width="100">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.costUnitPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="unitPrice" label="零售价(元)" min-width="110">
            <template v-slot="scope">
                <span>{{scope.row.unitPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="realNum" label="数量" min-width="110">
            <template v-slot="scope">
                <span>{{scope.row.realNum}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="totalPrice" label="总价(元)" min-width="110">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.totalPrice,2)}}</span>
            </template>
        </el-table-column>

    </el-table>

    <!-- 功能按钮 -->
    <div class="btnGroup">
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
export default {
    props:['editId'],
    data(){
        return{
            allInfo:{
                info:{},  //主信息
                dataList:[],  //明细列表
            }
        }
    },
    methods:{
        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //获取单个信息
        GetDataInfo(){
            let param = {
                retailNumber:this.editId
            }
            this.$api.erp.GetReturnRetailAllInfoByRetailNumber(param).then(res=>{
                if(res.data.status === 200){
                    this.allInfo = res.data.data;
                }
            })
        },
    },
    mounted(){
        this.GetDataInfo();
    },
}
</script>

<style>

</style>