<template>
  <div>
    <!-- 修改密码 -->
      <el-divider></el-divider>

      <el-form
      v-model="info"
      label-width="100px">

        <el-row>

          <el-col :span="24">
            <el-form-item label="原密码">
                <el-input v-model="info.oldPwd" type="password" minlength="6" maxlength="25" class="search-200"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="新密码">
                <el-input v-model="info.newPwd" type="password" minlength="6" maxlength="25" class="search-200"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="确认新密码">
                <el-input v-model="info.confirmPwd" type="password" minlength="6" maxlength="25" class="search-200"></el-input>
            </el-form-item>
          </el-col>

        </el-row>

      </el-form>

    <!-- 按钮 -->
    <div class="btnGroup">
        <el-button type="primary" @click="UpdateUserPwd">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
export default {
  name:'UserPwd',
  data(){
    return{
      info:{
        oldPwd:'',
        newPwd:'',
        confirmPwd:'',
      }
    }
  },
  methods:{

    //保存修改用户密码
    UpdateUserPwd(){
        //#region 验证
        if(this.info.newPwd.length<6){
            ElMessage.error('新密码长度必须6~25位');
            return false;
        }

        if(this.info.newPwd != this.info.confirmPwd){
            ElMessage.error('新密码不一致,请重新输入');
            return false;
        }
        //#endregion
        this.$api.system.UpdateUserPwd(this.info).then(res=>{
            if(res.data.status=== 200){
                //关闭弹框
                this.btnClose(); 
                ElMessage.success(res.data.msg);                                       
            }
            else{
                ElMessage.error(res.data.msg);
            }
        })
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

  }
}
</script>

<style>

</style>