<template>
  <div>
    <!-- 我方发票选择框 -->
    <el-divider></el-divider>

    <el-table :data="info.dataList" border stripe style="width:100%" @select="btnCheckbox" @select-all="btnAllCheckbox">
        <el-table-column prop="id" type="selection" width="50" />  
        <el-table-column prop="accountName" label="账户名称" min-width="170">
            <template v-slot="scope">
                <el-tooltip
                class="box-item"
                effect="dark"
                :content="scope.row.accountName"
                placement="top"
                >
                <div class="cellOverFlow">{{scope.row.accountName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="invoiceNumber" label="纳税人识别号" width="200" />
        <el-table-column prop="address" label="地址" min-width="170">
            <template v-slot="scope">
                <el-tooltip
                class="box-item"
                effect="dark"
                :content="scope.row.address"
                placement="top"
                >
                <div class="cellOverFlow">{{scope.row.address}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="phone" label="电话" width="170" />
        <el-table-column prop="bankName" label="开户行" width="170" />
        <el-table-column prop="bankNumber" label="账号" width="170" />
    </el-table>

  </div>
</template>

<script>
export default {
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,   //页数
                size:10,  //单页记录数
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
            },
            selectedItem:[],   //选择后的列表
        }
    },
    methods:{
        //获取发票列表
        GetDataList(){
            this.$api.finance.GetInvoiceList().then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                }
            })
        },
        //选中多选框
        btnCheckbox(row){
            this.selectedItem = row
        },
        //全选
        btnAllCheckbox(){
            this.selectedItem = this.info.dataList;
        },

    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style>

</style>