<template>
  <div>

    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="登录账号">
                    <el-input v-model="info.search.loginName"></el-input>
                </el-form-item>

                <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
          </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
      <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
          <el-col :span="15">
            <el-button class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
            <el-popconfirm
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>
          </el-col>

          <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>      
            
          </el-col>
        </el-row>

      <!-- 列表 -->
      <el-table :data="info.dataList" border stripe style="width: 100%" :height="GetDataHeight" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="50" />  
        <el-table-column prop="loginName" label="登录账号" width="180" />
        <el-table-column prop="loginType" label="登录类型"  />
        <el-table-column prop="ip" label="IP地址" />
        <el-table-column prop="loginTime" label="登录时间" width="180">
          <template #default="scope">
              <span>{{DateTimeFormat(scope.row.loginTime)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
            <template v-slot="scope">              
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.id)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
            </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-container class="page_one">
          <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[50, 100, 200]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
          >
          </el-pagination>
      </el-container>

    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    name:'ListLogin',
    data(){
      return{
        info:{
          dataList:[],  //Table数据
          page:1,   //页数
          size:50,  //单页记录数
          total:0,  //总记录数
          search:{
            loginName:''  //登录账号
          }
        }
      }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
      //加载数据列表
      GetDataList(){
        let param = {
          page:this.info.page,
          size:this.info.size,
          loginName:this.info.search.loginName
        }
        this.$api.system.ListLoginError(param).then(res=>{
          if(res.data.status === 200){
            this.info.dataList = res.data.data;  //获取数据
            //分页
            this.info.total = res.data.total
            this.info.page = res.data.pageNumber
          }else{
            ElMessage.error(res.data.msg)
          }
        }).catch(e=>{
          console.log(e)
        })
      },
      //选中多选框
      btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.id)
      },
      //分页-页数变化时
      btnPagechange(page){
          this.info.page = page
          this.GetDataList() //加载Table数据
      },
      //分页-单页记录数变化时
      btnSizechange(size){
          this.info.size = size
          this.info.page = 1
          this.GetDataList() //加载Table数据
      },

      //删除按钮
        btnDeleteInfo(id){
            let param = [id]
            this.$api.system.DeleteLoginError(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.system.DeleteLoginError(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList();
                    this.info.ids = [];
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置
        btnResetSearch(){
            this.info.search.loginName = '',
            this.GetDataList() //加载Table数据
        },
    },
    mounted(){
      this.GetDataList() //加载Table数据
    }

}
</script>

<style>

</style>