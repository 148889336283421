<template>
  <div>
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="会员昵称">
                <el-input v-model="info.search.memberName" class="search-150"></el-input>
            </el-form-item>

            <el-form-item label="手机号码">
                <el-input v-model="info.search.phone" class="search-150"></el-input>
            </el-form-item>

            <el-form-item label="VIP标识">
                <el-select v-model="info.search.isVip" class="search-150">
                    <el-option :value="-1" label="全部"></el-option>
                    <el-option :value="0" label="否"></el-option>
                    <el-option :value="1" label="是"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="实名标识">
                <el-select v-model="info.search.isReal" class="search-150">
                    <el-option :value="-1" label="全部"></el-option>
                    <el-option :value="0" label="否"></el-option>
                    <el-option :value="1" label="是"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>       
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>                
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight"  style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="memberNumber" type="selection" width="50" />  
        <el-table-column prop="memberNumber" label="编号" width="170" />
        <el-table-column prop="memberName" label="会员昵称" />
        <el-table-column prop="realName" label="真实姓名" />
        <el-table-column label="头像">
            <template v-slot="scope">
                <el-image
                    style="width: 70px; height: 70px"
                    :src="scope.row.headImg"
                    :preview-src-list="[scope.row.headImg]"
                    :hide-on-click-modal="true"
                    fit="cover">
                </el-image>
          </template>
        </el-table-column>
        <el-table-column label="性别" width="70">
            <template v-slot="scope">
                <el-tag v-if="scope.row.sex == 0" type="info">未知</el-tag>
                <el-tag v-if="scope.row.sex == 1">男</el-tag>
                <el-tag v-if="scope.row.sex == 2" type="danger">女</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号码" width="120" />
        <el-table-column label="实名标识" width="85">
            <template v-slot="scope">
                <el-tag v-if="scope.row.isReal == 0 ">否</el-tag>
                <el-tag v-if="scope.row.isReal == 1 " type="danger">已实名</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="VIP标识" width="85">
            <template v-slot="scope">
                <el-tag v-if="scope.row.isVip == 0 ">否</el-tag>
                <el-tag v-if="scope.row.isVip == 1 " type="danger">VIP</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="注册日期" width="110">
            <template #default="scope">
            <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="VIP结束日期" width="110">
            <template #default="scope">
            <span>{{DateFormat(scope.row.vipEndTime)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="140" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.memberNumber)">详情</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.lossNumber)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="12">
                
            </el-col>
            <el-col :span="12">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditMember :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditMember>
    </el-drawer>

  </div>
</template>

<script>
import EditMember from '@/views/Member/Info/EditMember.vue'
export default {
    components:{
        EditMember
    },
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    memberName:'',
                    phone:'',
                    isVip:-1,
                    isReal:-1,
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'会员详情'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-290
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            let param = {
                memberName:this.info.search.memberName,
                phone:this.info.search.phone,
                isVip:this.info.search.isVip,
                isReal:this.info.search.isReal,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.member.ListMember(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total,
                    this.info.page = res.data.pageNumber
                }
            }).catch(e=>{
                console.log(e);
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.memberNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增编辑
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "会员详情"
            }else{
                this.BoxEditInfo.title = "会员详情"
            }  
            this.BoxEditInfo.isVisible = true;
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.memberName='',
            this.info.search.phone = '',
            this.info.search.isVip=-1,
            this.info.search.isReal=-1,
            this.GetDataList()
        },

        //删除
        btnBatchDeleteInfo(){
            console.log('删除会员')
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

    },
    mounted(){
        this.GetDataList();
    }

}
</script>

<style>

</style>