<template>
  <div>

    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="用户姓名">
                    <el-select v-model="info.search.loginNumber" clearable class="search-200">
                      <el-option v-for="info in selectUserList" :key="info.userNumber" :label="info.userName" :value="info.userNumber"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item>
                  <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                  <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
          </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
      <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
          <el-col :span="15">
            <el-button class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
          </el-col>

          <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>      
            
          </el-col>
        </el-row>

      <!-- 列表 -->
      <el-table :data="info.dataList" border stripe style="width: 100%" :height="GetDataHeight">
        <el-table-column prop="id" type="selection" width="50" />  
        <el-table-column prop="loginName" label="用户名" width="180" />
        <el-table-column prop="client" label="客户端"  />
        <el-table-column prop="ip" label="IP地址" />
        <el-table-column prop="loginTime" label="登录时间" width="180">
          <template #default="scope">
              <span>{{DateTimeFormat(scope.row.loginTime)}}</span>
            </template>
        </el-table-column>        
      </el-table>
    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="12">
                
            </el-col>
            <el-col :span="12">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>


  </div>
</template>

<script>
import AllUserList from '@/utils/mixins/AllUserList.js'
import { ElMessage } from 'element-plus'
export default {
    name:'ListLogin',
    mixins:[AllUserList],
    data(){
      return{
        info:{
          dataList:[],  //Table数据
          page:1,   //页数
          size:50,  //单页记录数
          total:0,  //总记录数
          search:{
            loginNumber:''  //用户编号
          }
        }
      }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
      //加载数据列表
      GetDataList(){
        let param = {
          page:this.info.page,
          size:this.info.size,
          loginNumber:this.info.search.loginNumber
        }
        this.$api.system.ListLogLogin(param).then(res=>{
          if(res.data.status === 200){
            this.info.dataList = res.data.data;  //获取数据
            //分页
            this.info.total = res.data.total
            this.info.page = res.data.pageNumber
          }else{
            ElMessage.error(res.data.msg)
          }
        }).catch(e=>{
          console.log(e)
        })
      },
      //分页-页数变化时
      btnPagechange(page){
          this.info.page = page
          this.GetDataList() //加载Table数据
      },
      //分页-单页记录数变化时
      btnSizechange(size){
          this.info.size = size
          this.info.page = 1
          this.GetDataList() //加载Table数据
      },
      //重置
      btnResetSearch(){
        this.info.search.loginNumber = '',
        this.GetDataList() //加载Table数据
      },
    },
    mounted(){
      this.GetDataList() //加载Table数据
    }

}
</script>

<style>

</style>