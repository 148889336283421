<template>
  <div>
    <!-- 财务单抵扣项列表 -->
    <div class="skuBtnList">
      <el-button class="iconfont icon-add" type="primary" plain @click="btnSelectBill">选择单据</el-button>
    </div>

    <el-table border stripe >
        <el-table-column prop="id" label="财务编号" />
        <el-table-column prop="id" label="财务类型" />
        <el-table-column prop="id" label="交易者类型" />
        <el-table-column prop="id" label="交易者" />
        <el-table-column prop="id" label="金额" />
        <el-table-column prop="id" label="状态" />
    </el-table>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {

    methods:{
        //选择单据
        btnSelectBill(){
            ElMessage.warning('功能开发中');
        },
    }
}
</script>

<style>

</style>