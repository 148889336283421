<template>
  <div>
      <el-divider></el-divider>

      <el-form :inline="true"
        v-model="info"
        label-width="80px"
        label-position="right">
            <el-row v-if="info">

                <el-col :span="8">
                    <el-form-item label="评价主题">
                        <el-select v-model="info.themeNumber" clearable>
                          <el-option v-for="theme in themeList" :key="theme.themeNumber" :label="theme.themeName" :value="theme.themeNumber" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="申请企业">
                        <el-select v-model="info.applyNumber" @change="changCompanyInfo">
                          <el-option v-for="company in companyList" :key="company.systemNumber" :label="company.companyName" :value="company.systemNumber" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="截止日期">
                        <el-date-picker v-model="info.termTime" type="date" placeholder="有效期" value-format="YYYY-MM-DD" />
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="评价得分">
                        <el-input v-model="info.totalScore" disabled ></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="证书编号">
                        <el-input v-model="info.certNumber" ></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="评价等级">
                      <el-tag>{{info.levelCode}}</el-tag>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="状态">
                      <el-tag v-if="info.state == 1" type="danger">新申请</el-tag>
                      <el-tag v-if="info.state == 2">已评价</el-tag>
                      <el-tag v-if="info.state == 3" type="info">已取消</el-tag>
                    </el-form-item>
                </el-col>

            </el-row>
      </el-form>

  </div>
</template>

<script>
import moment from "moment";
export default {
  name:'EditRecord',
  props:['billNumber'],
  data(){
    return{
      info:{
        id:0,
        systemNumber:'',
        billNumber:'',
        themeNumber:'',
        applyNumber:'',
        companyName:'',
        totalScore:0,
        state:1,
        termTime:moment(new Date()).format("YYYY-MM-DD")
      },
      departmentId:0,  //部门编号
      themeList:[],
      companyList:[],
    }
  },
  methods:{
    //获取指标主题列表
    SelectThemeList(){
      this.$api.service.SelectEvaluateTheme().then(res=>{
        if(res.data.status === 200){
          this.themeList = res.data.data;
        }
      })
    },
    //加载企业列表
    SelectCompanyList(){
      this.$api.system.SelectCompanyList().then(res=>{
        if(res.data.status === 200){
          this.companyList = res.data.data;
        }
      })
    },
    
    //加载数据
    GetDataInfo(){
      if(this.billNumber != 0){
        let param = {
          billNumber:this.billNumber
        }
        console.log('hahaha')
        this.$api.service.SingleRecordInfo(param).then(res=>{
          if(res.data.status === 200){
            this.info = res.data.data;
          }
        })
      }
      else
      {
        this.info = {
          id:0,
          systemNumber:'',
          billNumber:'',
          themeNumber:'',
          applyNumber:'',
          companyName:'',
          totalScore:0,
          state:1,
          termTime:moment(new Date()).format("YYYY-MM-DD")
        }
      }
    },

    //企业发生改变时
    changCompanyInfo(option){
      let companyObj = this.companyList.find(item=>{
        return item.systemNumber === option
      })
      this.info.companyName = companyObj.companyName;
    },

  },
  mounted(){
    this.SelectThemeList();
    this.SelectCompanyList();
    this.GetDataInfo();
  }
}
</script>

<style>

</style>