<template>
  <div>
    <!-- 客户交易排名统计 -->

    <!-- 标题 -->
    <el-row>
        <el-col :span="24" class="title">
            <h3>客户交易排名</h3>
        </el-col>        
    </el-row>

    <!-- 查询条件 -->
    <el-row style="margin-bottom:20px;" :gutter="5">
        <el-col :span="6"></el-col>
        <el-col :span="6" class="title_right">
            日期：<el-date-picker
                    v-model="search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                    @change="GetDataInfo"
                />
        </el-col>
        <el-col :span="6">
          部门：
          <el-tree-select v-model="search.departmentId" :data="allDptList" :default-expand-all="true" :clearable="true" class="search-200" @change="GetDataInfo"/>
        </el-col>
        <el-button type="primary" @click="GetDataInfo">搜索</el-button>
    </el-row>

    <!-- 统计图 -->
    <div id="main" class="echarts" style="width:100%;height:1000px;" ></div>
    <div class="dataSource">
        数据来源：销售单、零售单
    </div>

  </div>
</template>

<script>
import AllDptList from '@/utils/mixins/AllDptList.js'  //全部部门列表
import * as echarts from 'echarts'  //Echarts 图标组件
import { ElMessage } from 'element-plus'
export default {
    mixins:[AllDptList],
    data(){
        return{
            search:{
                departmentId:0,
                datePoint:'',
            },
            info:{
                tradeNameList:[],
                tradeNumList:[],
                tradePriceList:[],
            }, 
            allDptList:[],    //所有部门列表
            beginDate:this.DateFormat(new Date()),   //开始日期
            endDate:this.DateFormat(new Date()),   //结束日期           
        }
    },
    methods:{

        //示例
        myEcharts(){
            var myChart = echarts.init(document.getElementById('main'));
            
            //配置图表
            var option = {
                tooltip: {
                    trigger: 'axis',   //显示标签
                },
                legend:{
                    top:'bottom',
                    data:this.info.tradeNameList, //示例图
                },
                xAxis: [
                    {
                        type: 'value',
                        name:'销售总额',
                        position: 'left',
                        axisLabel: {
                            formatter: '{value} 元'
                        }
                    },
                    {
                        type:'value',
                        name:'订单数',
                        position: 'right',
                        axisLabel: {
                            formatter: '{value} 单'
                        }
                    },
                ],
                yAxis: {
                    
                    type: 'category',
                    data:this.info.tradeNameList,
                },
                series: [
                    {
                        name:'销售总额',
                        xAxisIndex: 0,  //用的是左侧Y坐标
                        data: this.info.tradePriceList,
                        type: 'bar',
                        tooltip:{
                            valueFormatter:function(value){
                            return value + ' 元'
                            }
                        },
                    },
                    {
                        name:'订单数',
                        xAxisIndex: 1,  //用的是左侧Y坐标
                        data: this.info.tradeNumList,
                        type: 'bar',
                        tooltip:{
                            valueFormatter:function(value){
                            return value + ' 单'
                            }
                        },
                    }
                ]
            };

            myChart.setOption(option);
        },


        //获取数据
        async GetDataInfo(){
            if(this.search.datePoint == null){
                this.search.datePoint = ''
            }

            let param = {
                departmentId:this.search.departmentId,
                startDate:this.DateFormat(this.search.datePoint[0]),
                endDate:this.DateFormat(this.search.datePoint[1]),
            }
            this.$api.stat.StatCustomerTradeRank(param).then(res=>{
                if(res.data.status === 200){
                    this.info = res.data.data;
                    this.myEcharts();
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //计算日期
        GetbeginDate(){
            var date = new Date();
            date.setDate(date.getDate()-7);
            var year = date.getFullYear();
            var month = (date.getMonth() + 1).toString().padStart(2,'0');
            var day = date.getDate().toString().padStart(2,'0');
            var startDate = year + '-' + month + '-'+day;
            this.beginDate = this.DateFormat(startDate);
            this.search.datePoint = [this.beginDate,this.endDate];
            //加载数据
            this.GetDataInfo();
        },

    },
    mounted(){
        this.GetbeginDate();  //加载时间范围
    },
}
</script>

<style scoped>
.title{text-align: center;}

/* 备注  */
.dataSource{margin-left: 120px;font-size: 14px;color: #ccc;}
</style>