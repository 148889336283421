/**
 * 采购单相关
 * 询价单相关
 * 供应商相关
 */

var actions = {

}


var mutations = {
    //设置采购单数据
    SetPurchaseInfo(state,value){
        state.purchaseInfo = value
    },

    //设置询价单数据
    SetInquiryInfo(state,value){
        state.inquiryInfo = value
    },

    //设置供应商数据
    SetSupplierInfo(state,value){
        state.supplierInfo = value
    },

    //信用评价数据
    SetEvaluateInfo(state,value){
        state.evaluateInfo = value
    },

    //计划单数据
    SetPlanInfo(state,value){
        state.planInfo = value
    },
}


var state = {
    purchaseInfo:{},  //采购单信息
    inquiryInfo:{},   //询价单信息
    supplierInfo:{},  //供应商信息
    evaluateInfo:{},  //信用评价信息
    planInfo:{},     //计划单信息
}


export default{
    namespaced:true,
    actions,
    mutations,
    state
}