<template>
  <div>
    <!-- 选择产品 -->
    <div class="skuBtnList" v-if="lossInfo.info && (lossInfo.info.state==1 || lossInfo.info.state ==3)">
      <el-button class="iconfont icon-add" type="primary" plain @click="BtnSelectSku">选择产品</el-button>
    </div>

    <!-- 销售列表 -->
    <el-table :data="lossInfo.detailList" border show-summary style="width: 100%">
        <el-table-column prop="warehouseName" label="仓库" min-width="100"/>
        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
        <el-table-column label="产品名称" min-width="100">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" min-width="90"/>
        <el-table-column label="单位" width="100">
            <template v-slot="scope">
                <el-select v-if="scope.row.isEdit==1" v-model="scope.row.unitId"  @change="ChangeSkuUnit(scope.$index)">
                    <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
                </el-select>
                <span v-else>{{scope.row.unitName}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="unitPrice" label="单价(元)" min-width="120">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.unitPrice" :precision="2" :controls="false" style="width:100px" @change="OnChangeNum"></el-input-number>
                <span v-else >{{this.myRound(scope.row.unitPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="报损数量" min-width="120">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.lossNum" :min="0" :precision="2" :controls="false" style="width:100px" @change="OnChangeNum"></el-input-number>
                <span v-else >{{scope.row.lossNum}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="totalPrice" label="总价(元)" width="80">
            <template v-slot="scope">
                <span>{{scope.row.totalPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column label="库存" min-width="100">
            <template v-slot="scope">
                <span>{{scope.row.stockNum}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope" v-if="lossInfo.info && (lossInfo.info.state ==1 || lossInfo.info.state == 3)">
                <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
                <el-popconfirm 
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.lossNumber,scope.row.id,scope.$index)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>

    <!-- 选择产品 -->
    <el-dialog :title="BoxSkuInfo.title" 
        v-model="BoxSkuInfo.isVisible"
        width="65%"
        :destroy-on-close="true">
            <SelectStockSkuList :areaNum="lossInfo.info.areaNumber" ref="SelectSkuInfo"></SelectStockSkuList>
        <template #footer>
            <div style="padding-top:35px">
                <el-button type="primary" @click="btnSelectData">选择</el-button>
                <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
            </div>            
        </template>
    </el-dialog>

  </div>
</template>

<script>
import SelectStockSkuList from '@/components/common/SelectStockSkuList.vue'  //库存产品列表
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
export default {
  components:{
      SelectStockSkuList
  },
  data(){
    return{
      //选择产品-弹出框
      BoxSkuInfo:{
        isVisible:false,
        title:'选择库存产品'
      },
    }
  },
  computed:{
    ...mapState('Erp',['lossInfo'])
  },
  methods:{

    //选择产品
    BtnSelectSku(){
        this.BoxSkuInfo.isVisible = true
    },

    //保存选中产品
    async btnSelectData(){
        let itemList = this.$refs.SelectSkuInfo.selectedItem;
        if(itemList !=null && itemList.length>0){
            itemList.forEach(item => {
                let newRow = {
                    id:0,
                    systemNumber:item.systemNumber,
                    lossNumber:this.lossInfo.info.lossNumber,
                    warehouseNumber:item.warehouseNumber,
                    warehouseName:item.warehouseName,
                    cid:item.cid,         //分类编号
                    cidName:item.cidName,    //分类名称
                    brandId:item.brandId,    //品牌编号
                    brandName:item.brandName,   //品牌名称
                    spuNumber:item.spuNumber,
                    spuName:item.spuName,
                    skuNumber:item.skuNumber,
                    skuName:item.skuName,
                    unitId:item.defUnitId,   //单位编号
                    unitName:item.defUnitName,
                    unitPrice:item.defUnitPrice,  //单价(默认单位)
                    lossNum:1,
                    maxNum:item.defUnitStockNumClean,  //最大出库数量 (默认单位)
                    totalPrice:item.defUnitPrice*1,   //总价
                    isDel:0,
                    isEdit:1,
                    skuUnitList:item.skuUnitList,   //单位列表
                    stockNum:item.defUnitStockNumClean,   //库存
                }

                this.lossInfo.detailList.push(newRow);
            });     
        }
        this.BoxSkuInfo.isVisible = false  //关闭选择产品弹出框
    },

    //编辑行
    EditRow(id){
        this.lossInfo.detailList = this.lossInfo.detailList.map(item=>{
            if(item.id === id){
                item.isEdit = 1;
            }
            return item
        })
    },

    //删除销售明细
    async DeleteRow(lossNumber, id, index){
        this.lossInfo.detailList.splice(index,1)
        if(id !=0){
            let param = {
                lossNumber:lossNumber,
                id:id
            }
            await this.$api.wms.DeleteLossDetail(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        }
        //重新计算总价
        this.OnChangeNum();
    },

    //当明细数量、价格改变时触发
    OnChangeNum(){
        let newtotalPrice = 0;   //主表-总价
        this.lossInfo.detailList = this.lossInfo.detailList.map(item=>{
            item.totalPrice = this.myRound(item.unitPrice * item.lossNum,2)
            newtotalPrice += item.totalPrice
            return item
        })
        this.lossInfo.info.totalPrice = this.myRound(newtotalPrice,2)  //更新主表-总价
    },

    //当单位发生变化时
    ChangeSkuUnit(index){
        this.lossInfo.detailList = this.lossInfo.detailList.map((item,index2)=>{
            //#region 单位发生变化时-重新计算价格、库存
            if(index === index2){
                let param3 = {
                    warehouseNumber:item.warehouseNumber,
                    skuNumber:item.skuNumber,
                    unitId:item.unitId
                }
                this.$api.wms.GetSkuUnitStockInfo(param3).then(res=>{
                    if(res.data.status === 200){
                        item.stockNum = res.data.data.unitStock;  //库存
                        item.unitPrice = res.data.data.unitPrice; //成本单价
                    }
                })
            }
            //#endregion
            return item
        })
        //重新计算总价
        setTimeout(()=>{
            this.OnChangeNum();
        },500)
        
    },


  }
}
</script>

<style>

</style>