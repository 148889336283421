<template>
  <div class="SingleInfo">
    <el-divider></el-divider>
    
    <!-- Tab标签 -->
    <TabProof></TabProof>

    <el-divider>凭证明细</el-divider>
    <TabProofDetail></TabProofDetail>
    

    <!-- 功能按钮 -->
    <div class="btnGroup">      
      <el-button v-if="CheckUserButtonAuth('BtnProofSave')" type="primary" @click="btnSaveInfo()">保存</el-button>
      <el-button @click="btnClose">关闭</el-button>

      <el-button-group>
          <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
          <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
      </el-button-group>
    </div>

    

  </div>
</template>

<script>
import TabProof from '@/views/Oa/Proof/Info/TabProof.vue'
import TabProofDetail from '@/views/Oa/Proof/Info/TabProofDetail.vue'
import { ElMessage } from 'element-plus'
import moment from 'moment'
import { mapMutations, mapState } from 'vuex'
export default {  
  props:['editId','dataList'],  
  components:{
    TabProof,
    TabProofDetail,
  },
  data(){
    return{
      proofNumber:this.editId,  //凭证编号
    }
  },
  computed:{
    ...mapState('Oa',['proofInfo'])
  },
  methods:{
    ...mapMutations('Oa',['SetProofInfo']),

    //获取信息
    GetDataInfo(){
      if(this.proofNumber!=0){
        let param = {
          proofNumber:this.proofNumber
        }
        this.$api.oa.SingleAllProof(param).then(res=>{
          if(res.data.status === 200){
            this.SetProofInfo(res.data.data);            
          }else{
            this.btnClearData();
          }
        })
      }else{
        this.btnClearData();
      }      
    },

    //保存
    btnSaveInfo(){
      this.$api.oa.SaveAllProof(this.proofInfo).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          this.btnClose();
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },


    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

    //上一条
    btnPrevious(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.proofNumber === this.proofNumber
        })
        let prevIndex = currentIndex -1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有上一条了");
            return false;
        }
        else{
            this.proofNumber = prevInfo.proofNumber
            this.GetDataInfo()
        }
        
    },
    //下一条
    btnNext(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.proofNumber === this.proofNumber
        })
        let prevIndex = currentIndex + 1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有下一条了");
            return false;
        }
        else{
            this.proofNumber = prevInfo.proofNumber
            this.GetDataInfo()
        }
    },

    //清空数据实体
    btnClearData(){
      let allInfo = {
        info:{
          proofNumber:'',
          departmentId:this.userDepartmentId,
          typeId:0,
          title:'',
          createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        },
        detailList:[],        
      }
      this.SetProofInfo(allInfo);
    },


  },
  mounted(){
    this.GetDataInfo();
  }

}
</script>

<style>
</style>