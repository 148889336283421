<template>
  <div>
    <el-divider></el-divider>

    <!-- 打印页面 -->
    <div id="printData">
        <!-- 标题 -->
            <div>
                <el-row>
                    <el-col :span="7" class="titleLeft">打印时间：{{printTime}}</el-col>
                    <el-col :span="10" class="title"><h3>入库单</h3></el-col>
                    <el-col :span="7" class="titleRight">公司名称：{{CurrentsystemInfo.companyName}}</el-col>
                </el-row>
                <el-divider></el-divider>
            </div>

            <!-- 主表 -->
            <table class="DataInfo">
                <tr>
                    <td>入库单号：{{billInInfo.info.inNumber}}</td>
                    <td>部门：{{billInInfo.info.departmentName}}</td>
                    <td>原始单据类型：
                        <span v-if="billInInfo.info.originType==1">采购单</span>
                        <span v-if="billInInfo.info.originType==6">加工单</span>
                        <span v-if="billInInfo.info.originType==13">移仓单</span>
                        <span v-if="billInInfo.info.originType==15">退货单</span>
                    </td>
                    <td>原始单号：{{billInInfo.info.originNumber}}</td>
                    <td>入库时间：{{DateFormat(billInInfo.info.inTime)}}</td>
                    
                </tr>
                <tr>
                    <td>
                        交易者类型：<span v-if="billInInfo.info.tradeType==1">供应商</span>
                        <span v-if="billInInfo.info.tradeType==2">客户</span>
                        <span v-if="billInInfo.info.tradeType==3">员工</span>
                    </td>
                    <td>
                        交易者：{{billInInfo.info.tradeName}}
                    </td>
                    <td>经手人：{{billInInfo.info.receiveName}}</td>
                    <td>
                    状态：<span v-if="billInInfo.info.state==3">已入库</span>
                    <span v-if="billInInfo.info.state==2">入库中</span>
                    <span v-if="billInInfo.info.state==1">新建单</span>
                    </td>
                    <td></td>
                </tr>
            </table>
            <!-- 明细表 -->
            <table class="DataDetail">
                <tr>
                    <th>入库仓库</th>
                    <th>产品类目</th>
                    <th>品牌</th>
                    <th>产品名称</th>
                    <th>规格</th>
                    <th>单位</th>
                    <th>计划数量</th>
                    <th>实际数量</th>
                </tr>
                <tr v-for="detail in billInInfo.detailList" :key="detail.id">
                    <td>{{detail.warehouseName}}</td>
                    <td>{{detail.cidName}}</td>
                    <td>{{detail.brandName}}</td>
                    <td>{{detail.spuName}}</td>
                    <td>{{detail.skuName}}</td>
                    <td>{{detail.unitName}}</td>
                    <td>{{detail.planNum}}</td>
                    <td>{{detail.realNum}}</td>
                </tr>
            </table>
        

    </div>
    

    <div class="btnGroup">
        <el-button type="primary" class="iconfont icon-dayin" v-print="printObj" id="btnPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
export default {
    data(){
        return{
            printTime:moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            printObj: {
              id:'printData',
              preview: false,
              popTitle: '打印预览',
            }
        }
    },
    computed:{
        ...mapState('Erp',['billInInfo']),
        ...mapState('Master',['CurrentsystemInfo'])
    },
    methods:{
        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
    mounted(){
        document.getElementById("btnPrint").click();
    }

}
</script>

<style scoped>
#printData {font-size: 12px;overflow: hidden;} /**防止多打印一张 */
#printData .titleLeft{display: flex;align-items: flex-end;}
#printData .title {text-align: center;font-size: 14px;}
#printData .titleRight{display: flex;align-items: flex-end;justify-content: flex-end;}
#printData .DataInfo{width: 100%;}
#printData .DataInfo td {padding: 5px 0px;}

#printData .DataDetail {width: 100%;border-collapse: collapse;}
#printData .DataDetail th {border: 1px solid #ccc;}
#printData .DataDetail td {border: 1px solid #ccc;}

#printData .companyInfo {float: right;padding: 50px;}
</style>