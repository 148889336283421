<template>
  <div>
      <el-form :inline="true"
        v-model="evaluateInfo.info"
        label-width="80px"
        label-position="right">
            <el-row v-if="evaluateInfo.info">

                <el-col :span="8">
                    <el-form-item label="主题名称">
                        <el-input v-model="evaluateInfo.info.themeName"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="标准依据">
                        <el-input v-model="evaluateInfo.info.standard"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="评审单位">
                        <el-input v-model="evaluateInfo.info.assessor"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="指标总分">
                        <el-input v-model="evaluateInfo.info.totalScore" disabled ></el-input>
                    </el-form-item>
                </el-col>                

                <el-col :span="12">
                    <el-form-item label="证书模板">
                        <!-- 上传图片 -->
                        <el-upload
                        class="avatar-uploader"
                        :action="GetFileUrl('EvaluateCert')"
                        :headers="GetHeader"
                        :show-file-list="false"
                        :before-upload="BeforeUpload"
                        :on-success="UploadSuccess">
                          <img v-if="evaluateInfo.info.cert" :src="evaluateInfo.info.cert" class="avatar">
                          <i v-else class="iconfont icon-add"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>

            </el-row>
     </el-form>
  </div>
</template>

<script>
import Authorize from '@/utils/authorize.js'
import { ElMessage } from 'element-plus';
import { mapState } from 'vuex'
export default {
    name:'TabTheme',
    computed:{
        ...mapState('Purchase',['evaluateInfo']),
        //添加Token - 重要
        GetHeader(){
          return {
            "Authorization":"Bearer "+ Authorize.getToken()
          }
        },
    },
    methods:{
        //上传图片之前操作
      BeforeUpload(file){
        const fileType = file.type;
        const fileSize = file.size;
        
        if(fileType !== 'image/jpeg' && fileType !== 'image/png'){
          ElMessage.error('上传图片格式:jpeg|png')
          return false;
        }
        //文件大小
        if(fileSize/1024/1024 > 3){
          ElMessage.error('上传文件大小不能超过3Mb')
          return false;
        }

      },
      //上传完成
      UploadSuccess(res){
        if(res.status === 200){
          this.evaluateInfo.info.cert = res.data
        }
      },

    }
}
</script>

<style scoped>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 80px;
    height: 80px;
    display: block;
    border: solid 1px #ccc;
  }
  .icon-add {
    font-size: 40px;
    border: solid 1px #ccc;
    display: block;
    width: 80px;
    height: 60px;
    padding-top: 20px;
  }
</style>