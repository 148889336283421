<template>
  <div>
    
    <el-table :data="memberInfo.addressList" border style="width: 100%">
        <el-table-column prop="linkMan" label="联系人"/>
        <el-table-column prop="linkPhone" label="联系电话"/>
        <el-table-column prop="provinceName" label="省份" />
        <el-table-column prop="cityName" label="城市" />
        <el-table-column prop="areaName" label="区县"/>
        <el-table-column prop="areaName" label="详细地址"/>
        <el-table-column label="默认地址">
            <template v-slot="scope">
                <el-tag v-if="scope.row.isDefault == 0 ">否</el-tag>
                <el-tag v-if="scope.row.isDefault == 1 " type="danger">是</el-tag>
            </template>
        </el-table-column>
    </el-table>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Shop',['memberInfo'])
    },

}
</script>

<style>

</style>