<template>
  <div>
    <!-- 年假调整记录 -->
    <el-divider></el-divider>
    
    <el-form :inline="true"
        v-model="info"
        label-width="100px"
        label-position="right">
        <el-row v-if="info">

            <el-col :span="12">
                <el-form-item label="假期天数">
                    <el-input-number v-model="info.holiday"></el-input-number>
                </el-form-item>
            </el-col>   

            <el-col :span="12">
                <el-form-item label="调整原因">
                    <el-input v-model="info.reason" maxlength="50"></el-input>
                </el-form-item>
            </el-col>                

        </el-row>
    </el-form>

    <el-divider>选择员工</el-divider>
    <!-- 员工列表 -->
    <el-table border :data="UserList" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="55" />  
        <el-table-column prop="userName" label="员工姓名"></el-table-column>
        <el-table-column label="性别">
            <template #default="scope">
                <span v-if="scope.row.sex ==0">女</span>
                <span v-if="scope.row.sex ==1">男</span>
                <span v-if="scope.row.sex ==2">未知</span>
            </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="部门"></el-table-column>
    </el-table>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data(){
    return{
      info:{
        holiday:1,  //假期天数
        reason:'',  //调整原因
        userList:[],
      },
      UserList:[],   //员工列表
    }
  },
  methods:{
    //加载用户列表(排班)
    LoadUserList(){
      let param = {
        clockInType:0
      }
      this.$api.system.GetUserListByClockInType(param).then(res=>{
        if(res.data.status === 200){
          this.UserList = res.data.data;
        }
      })
    },

    //选中多选框
    btnCheckbox(selection){
        this.info.userList = []
        selection.map(item=>{
            let newInfo = {
                userNumber:item.userNumber,
                userName:item.userName,
                departmentId:item.departmentId,
                departmentName:item.departmentName
            }
            this.info.userList.push(newInfo);
        })
    },

    //保存数据
    btnSaveData(){
      if(this.info.userList == null || this.info.userList.length <=0){
          ElMessage.error('请选择排班员工');
      }

      this.$api.hr.BatchSaveAnnualLeave(this.info).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          this.btnClose();
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

  },
  mounted(){
    this.LoadUserList();  //
  }
}
</script>

<style>

</style>