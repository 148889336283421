/**
 * 系统模块相关数据
 * 
 */

 var actions = {

}

var mutations = {
    //设置系统信息
    SetSystemInfo(state,value){
        state.systemInfo = value
    },
    //设置角色
    SetRoleInfo(state,value){
        state.roleInfo = value
    },
    
}

var state = {
    systemInfo:{},      //系统信息
    roleInfo:{},   //角色
}

export default{
    namespaced:true,
    actions,
    mutations,
    state
}
