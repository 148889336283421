<template>
  <div>
    <!-- 设置部门 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
                <el-button class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                        
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="id" type="selection" width="55" /> 
            <el-table-column prop="id" label="编号" />
            <el-table-column prop="departmentName" label="部门" min-width="110">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.departmentName"
                        placement="top">
                        <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column label="班次标识">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.shiftFlag==0" type="danger">关闭</el-tag>
                    <el-tag v-if="scope.row.shiftFlag==1">开启</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="产品来源">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.goodsOrigin==0">全部产品</el-tag>
                    <el-tag v-if="scope.row.goodsOrigin==1"  type="danger">部门关联产品</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="100" fixed="right">
                <template v-slot="scope">      
                    <el-dropdown size="small">
                        <el-button type="primary" size="small">操作<el-icon class="el-icon--right"><arrow-down /></el-icon></el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="btnEditInfo(scope.row.setDptNumber)">详情</el-dropdown-item>
                                <el-dropdown-item @click="btnOpenBoxDptSku(scope.row.setDptNumber,scope.row.departmentId)">关联产品</el-dropdown-item>
                                <el-dropdown-item @click="btnDeleteInfo(scope.row.setDptNumber)">删除</el-dropdown-item>                                
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </el-table-column>

        </el-table>

    </div>

    <!-- 分页 -->
    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="400"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditSetDpt :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditSetDpt>
    </el-dialog>

    <!-- 部门关联产品 -->
    <el-dialog title="部门关联产品" 
    v-model="BoxDptSku.isVisible"
    width="70%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
        <EditSetDptSku :dptInfo="BoxDptSku" @closeBox="btnCloseBoxDptSku"></EditSetDptSku>
    </el-dialog>

  </div>
</template>

<script>
import EditSetDpt from '@/views/SetUp/SetDpt/EditSetDpt.vue'
import EditSetDptSku from '@/views/SetUp/SetDpt/EditSetDptSku.vue'

import UserDptList from '@/utils/mixins/UserDptList.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ArrowDown } from '@element-plus/icons-vue'
export default {
    mixins:[UserDptList],
    components:{
        EditSetDpt,
        EditSetDptSku,
        ArrowDown
    },
    data(){
        return{
            dataList:[],  //Table数据
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    selectDepartmentList:[],
                },
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'部门设置详情'
            },
            //部门关联产品
            BoxDptSku:{
                isVisible:false,
                departmentId:0,
                setDptNumber:'',
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
    methods:{
        //加载数据
        GetDataList(){
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.common.PageSetDptList(param).then(res=>{
                if(res.data.status ===200){
                    this.dataList = res.data.data;  //获取数据
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.setDptNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增编辑
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "部门设置详情"
            }else{
                this.BoxEditInfo.title = "部门设置详情"
            }  
            this.BoxEditInfo.isVisible = true;
        },
        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        }, 

        //打开部门关联产品
        btnOpenBoxDptSku(setDptNumber,dptId){
            this.BoxDptSku.setDptNumber = setDptNumber;
            this.BoxDptSku.departmentId = dptId;
            this.BoxDptSku.isVisible = true;
        },
        //关闭部门关联产品
        btnCloseBoxDptSku(){
            this.BoxDptSku.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(setDptNumber){
            ElMessageBox.confirm('确定要删除该信息吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                let param = [setDptNumber]
                this.$api.common.DeleteSetDptAllInfo(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
                })
            }).catch(()=>{
                //取消
            })
            
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            ElMessageBox.confirm('确定要删除选中信息吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                this.$api.common.DeleteSetDptAllInfo(this.info.ids).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                        this.GetDataList();
                        this.info.ids = [];
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                //取消
            })        
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.selectDepartmentList = [];
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList();
        }, 

    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style>

</style>