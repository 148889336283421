<template>
  <div>
    <el-divider></el-divider>
    <!-- 支付页面 -->
    <el-descriptions
    :column="1"
    border>

    <el-descriptions-item label="单号">
        {{returnRetailInfo.info.billNumber}}
    </el-descriptions-item>

    <el-descriptions-item label="支付方式">
        <el-select v-model="returnRetailInfo.info.accountNumber" class="search-200">
            <el-option v-for="accountInfo in retailAccountList" :key="accountInfo.accountNumber" :label="accountInfo.accountName" :value="accountInfo.accountNumber"></el-option>
        </el-select>
    </el-descriptions-item>

    <el-descriptions-item label="实际应付款(元)">
        {{this.myRound(returnRetailInfo.info.realPrice,2)}}
    </el-descriptions-item>

    
    <el-descriptions-item label="支付操作">
        <el-radio-group v-model="returnRetailInfo.info.payMode">
            <el-radio :label="1">系统内支付</el-radio>
            <el-radio :label="2">系统外支付</el-radio>
        </el-radio-group> 
    </el-descriptions-item>

    <el-descriptions-item label="付款时间">
        <el-date-picker v-model="returnRetailInfo.info.payTime" type="datetime" placeholder="付款时间" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px" disabled/>
    </el-descriptions-item>

    <el-descriptions-item label="支付码">
        
    </el-descriptions-item>

    </el-descriptions>

    <div class="btnGroup">
        <el-button type="danger" @click="btnPayRetail">支付完成</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElLoading, ElMessage } from 'element-plus'
import { mapState } from 'vuex'
import accountList from '@/utils/mixins/AccountList.js'  //账户-下拉列表
import moment from 'moment'
export default {
    mixins:[accountList],
    data(){
        return{
            info:{
                payModel:2
            },
            retailAccountList:[],  //零售专用账户列表
        }
    },
    computed:{
        ...mapState('Erp',['returnRetailInfo'])
    },
    methods:{

        //零售专用账户列表
        SelectRetailAccountList(){
            this.$api.finance.SelectRetailAccountList().then(res=>{
                if(res.data.status === 200){
                    this.retailAccountList = res.data.data;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //支付订单
        btnPayRetail(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            let param = {
                billNumber:this.returnRetailInfo.info.billNumber,
                payMode:this.returnRetailInfo.info.payMode,
                accountNumber:this.returnRetailInfo.info.accountNumber,  //账户编号
                state:5,  //状态：已支付
                payTime:this.returnRetailInfo.info.payTime,  //付款时间
            }

            this.$api.erp.UpdateReturnRetailState(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.returnRetailInfo.info.state = 2;  //已支付
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        

    },
    mounted(){
        this.SelectRetailAccountList();  //零售专用账户列表
        if(this.returnRetailInfo.info.payTime == null || this.returnRetailInfo.info.payTime==''){
            this.returnRetailInfo.info.payTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");  //付款时间
        }
        
    },
}
</script>

<style>

</style>