<template>
  <div class="editInfo">
    <!-- 编辑-退零单 -->

    <el-divider></el-divider>

    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="退零单" name="tabInfo">
            <TabReturnRetail ref="tabRetail"></TabReturnRetail>
    
            <el-divider>退零单明细</el-divider>
            <TabReturnRetailDetail></TabReturnRetailDetail>
        </el-tab-pane>

    </el-tabs>
    

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="returnRetailInfo.info">
        <el-button type="primary" v-if="returnRetailInfo.info.state<=1" @click="btnSaveData">保存</el-button>
        <el-button type="danger" v-if="returnRetailInfo.info.billNumber !='' && returnRetailInfo.info.state ==1" @click="BoxPayState.isVisible=true">确认支付</el-button>
        <el-button type="danger" v-if="returnRetailInfo.info.state==5" @click="btnRefundRetail">退款</el-button>
        <el-button type="info" v-if="returnRetailInfo.info.billNumber !='' && returnRetailInfo.info.state ==1" @click="btnCancelRetail">取消</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 结算-弹出框 -->
    <el-dialog title="结算"
    v-model="BoxPayState.isVisible"
    :close-on-click-modal="false"
    width="500">
        <BoxPayBill @closeBox="btnCloseBoxPayBill"></BoxPayBill>
    </el-dialog>

  </div>
</template>

<script>
import BoxPayBill from '@/views/Rms/ReturnRetail/BoxPayBill.vue'
import TabReturnRetail from '@/views/Rms/ReturnRetail/TabReturnRetail.vue'
import TabReturnRetailDetail from '@/views/Rms/ReturnRetail/TabReturnRetailDetail.vue'
import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
export default {
    props:['editId','dataList'],
    data(){
        return{
            info:{
                activeName:'tabInfo',
                billNumber:this.editId,  //当前退零单编号
            },
            //支付方式-弹出框
            BoxPayState:{
                isVisible:false,
            }
        }
    },
    computed:{
        ...mapState('Erp',['returnRetailInfo'])
    },
    components:{
        BoxPayBill,
        TabReturnRetail,
        TabReturnRetailDetail,
    },
    methods:{
        ...mapMutations('Erp',['SetReturnRetailInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.billNumber !=0){
                let param = {
                    billNumber:this.info.billNumber
                }
                this.$api.erp.GetReturnRetailAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetReturnRetailInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //清空实体
        btnClearData(){
            var newInfo = {
                info:{
                    systemNumber:'',
                    billNumber:'',
                    departmentId:this.userDepartmentId,
                    warehouseNumber:'',
                    tradeType:4, //默认：散客
                    tradeNumber:'',
                    payType:1,  //支付方式
                    billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    totalPrice:0,
                    realPrice:0,
                    payMode:2,
                    state:1,
                    originNumber:'',  //原始单号
                    billFlag:0,
                    createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                },
                detailList:[]
            }
            this.SetReturnRetailInfo(newInfo);
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //保存数据
        btnSaveData(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            this.$api.erp.SaveReturnRetailAllInfo(this.returnRetailInfo).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //刷新页面
                    this.returnRetailInfo.info.billNumber = res.data.data;
                    this.info.billNumber = res.data.data;
                    this.GetDataInfo();
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //退款
        btnRefundRetail(){
            ElMessageBox.confirm('确认要退款吗','退款提示',{
                confirmButtonText:'确认',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                //打开遮罩层
                const loading = ElLoading.service({
                    lock:true,
                    text:'正在提交,请稍候!',
                    background:'rgba(0, 0, 0, 0.7)',
                })

                let param = {
                    billNumber:this.info.billNumber
                }
                this.$api.erp.RefundReturnRetail(param).then(res=>{
                    loading.close();
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        //更新状态
                        this.retailInfo.info.state = 1; 
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }).catch(()=>{

            })
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.info.billNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.info.billNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
        },


        //关闭结算-弹出框
        btnCloseBoxPayBill(){
            this.BoxPayState.isVisible=false;
        },

        //取消订单
        btnCancelRetail(){
            ElMessageBox.confirm('确认要取消该订单吗?','取消提示',{
                confirmButtonText:'确认',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                //打开遮罩层
                const loading = ElLoading.service({
                    lock:true,
                    text:'正在提交,请稍候!',
                    background:'rgba(0, 0, 0, 0.7)',
                })

                let param = {
                    billNumber:this.returnRetailInfo.info.billNumber,
                    state:9,  //取消
                }

                this.$api.erp.UpdateReturnRetailState(param).then(res=>{
                    loading.close();
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        this.returnRetailInfo.info.state = 9;  //已取消
                        this.btnClose();
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }).catch(()=>{

            })
        },
    },
    mounted(){
        this.GetDataInfo();
    }
}
</script>

<style>

</style>