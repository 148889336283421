<template>
  <div>
    <el-divider></el-divider>
    
    <div id="printData">
        <div class="title">
            <h3>业务对账单</h3>
        </div>
        <table class="DataInfo">
            <tr>
                <td>账期说明：{{cycleInfo.info.title}}</td>
                <td>
                    交易者类型：<span v-if="cycleInfo.info.tradeType==1">供应商</span>
                    <span v-if="cycleInfo.info.tradeType==2">客户</span>
                </td>
                <td>
                    交易者:{{cycleInfo.info.tradeName}}
                </td>
            </tr>
            <tr>
                <td>
                    开始时间：{{DateFormat(cycleInfo.info.startDate)}}
                </td>
                <td>
                    结束时间：{{DateFormat(cycleInfo.info.endDate)}}
                </td>
                <td>
                    总价：{{cycleInfo.info.totalPrice}}
                </td>
            </tr>
        </table>

        <table class="DataDetail">
            <tr>
                <th>业务单号</th>
                <th>单据类型</th>
                <th>部门</th>
                <th>交易者</th>
                <th>产品</th>
                <th>规格</th>
                <th>单位</th>
                <th>单价</th>
                <th>数量</th>
                <th>总价(元)</th>
                <th>财务单标识</th>
                <th>记账时间</th>
                <th>创建日期</th>            
            </tr>
            <tr v-for="detail in cycleInfo.detailList" :key="detail.id">
                <td>{{detail.billNumber}}</td>
                <td>
                    <span v-if="detail.billType==1">采购单</span>
                    <span v-if="detail.billType==2">销售单</span>
                    <span v-if="detail.billType==3">零售单</span>
                    <span v-if="detail.billType==4">差额单</span>
                    <span v-if="detail.billType==14">退供单</span>
                    <span v-if="detail.billType==15">退货单</span>
                    <span v-if="detail.billType==17">网络订单</span>
                </td>
                <td>{{detail.departmentName}}</td>
                <td>{{detail.tradeName}}</td>
                <td>{{detail.spuName}}</td>
                <td>{{detail.skuName}}</td>
                <td>{{detail.unitName}}</td>
                <td>{{detail.unitPrice}}</td>
                <td>{{detail.itemNum}}</td>
                <td>{{detail.totalPrice}}</td>
                <td>
                    <span v-if="detail.financeFlag==0">未生成</span>
                    <span v-if="detail.financeFlag==1">已生成</span>
                </td>
                <td>{{DateFormat(detail.billTime)}}</td>
                <td>{{DateFormat(detail.createTime)}}</td>
            </tr>
        </table>
    </div>
    
    <div class="btnGroup">
        <el-button type="primary" class="iconfont icon-dayin" v-print="printObj" id="btnPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return{
            printObj: {
              id:'printData',
              preview: false,
              popTitle: '打印预览',
            }
        }
    },
    computed:{
        ...mapState('Erp',['cycleInfo'])
    },
    methods:{
        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
    mounted(){
        document.getElementById("btnPrint").click();
    }
}
</script>

<style scoped>
#printData {font-size: 12px;overflow: hidden;} /**防止多打印一张 */
#printData .title {text-align: center;}
#printData .DataInfo{width: 100%;}
#printData .DataInfo td {padding: 5px 0px;}

#printData .DataDetail {width: 100%;border-collapse: collapse;}
#printData .DataDetail th {border: 1px solid #ccc;}
#printData .DataDetail td {border: 1px solid #ccc;}
</style>