<template>
  <div>

    <!-- 选择产品 -->
    <div class="skuBtnList" v-if="moveInfo.info && (moveInfo.info.state==1 || moveInfo.info.state ==3)">
      <el-button class="iconfont icon-add" type="primary" plain @click="BtnSelectSku">选择产品</el-button>
    </div>

    <!-- 销售列表 -->
    <el-table :data="moveInfo.detailList" border stripe show-summary style="width: 100%">
        
        <el-table-column prop="skuName" label="出库仓库" min-width="100">
            <template v-slot="scope">
            <span>{{scope.row.outWarehouseName}}</span>
            </template>
        </el-table-column>

        <el-table-column label="入库仓库" min-width="120">
            <template v-slot="scope">
            <el-select v-if="scope.row.isEdit==1 && (moveInfo.info.state==1 || moveInfo.info.state==3)" v-model="scope.row.inWarehouseNumber">
                <el-option v-for="warehouse in areaWhList" :key="warehouse.warehouseNumber" :label="warehouse.warehouseName" :value="warehouse.warehouseNumber" />
            </el-select>
            <span v-else>{{scope.row.inWarehouseName}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
        <el-table-column label="产品名称" min-width="100">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" min-width="100"/>
        <el-table-column label="单位" width="100">
            <template v-slot="scope">
                <el-select v-if="scope.row.isEdit==1" v-model="scope.row.unitId" @change="ChangeSkuUnit">
                    <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
                </el-select>
                <span v-else>{{scope.row.unitName}}</span>
            </template>
        </el-table-column>

        <!-- <el-table-column prop="unitPrice" label="单价(元)" min-width="120">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.unitPrice" :precision="2" size="small"></el-input-number>
                <span v-else >{{this.myRound(scope.row.unitPrice,2)}}</span>
            </template>
        </el-table-column> -->

        <el-table-column label="计划数量" min-width="120">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.planNum" :min="1" :precision="2" size="small"></el-input-number>
                <span v-else >{{scope.row.planNum}}</span>
            </template>
        </el-table-column>

        <el-table-column label="出库数量" min-width="120">
            <template v-slot="scope">
                <span>{{scope.row.outNum}}</span>
            </template>
        </el-table-column>

        <el-table-column label="入库数量" min-width="120">
            <template v-slot="scope">
                <span>{{scope.row.inNum}}</span>
            </template>
        </el-table-column>

        <!-- <el-table-column prop="outTotalPrice" label="出库总价(元)" width="90">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.outTotalPrice,2)}}</span>
            </template>
        </el-table-column> -->

        <!-- <el-table-column prop="inTotalPrice" label="入库总价(元)" width="90">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.inTotalPrice,2)}}</span>
            </template>
        </el-table-column> -->

        <el-table-column label="库存" min-width="110">
            <template v-slot="scope">
                <span>{{scope.row.stockNum}}</span>
            </template>
        </el-table-column>


        <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope" v-if="moveInfo.info && (moveInfo.info.state ==1 || moveInfo.info.state ==3)">
                <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
                <el-popconfirm 
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.moveNumber,scope.row.id,scope.$index)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>

    </el-table>

    <!-- 选择产品 -->
    <el-dialog :title="BoxSkuInfo.title" 
        v-model="BoxSkuInfo.isVisible"
        width="65%"
        :destroy-on-close="true">
            <SelectStockSkuList :areaNum="moveInfo.info.outAreaNumber" ref="SelectSkuInfo"></SelectStockSkuList>
        <template #footer>
            <div style="padding-top:35px">
                <el-button type="primary" @click="btnSelectData">选择</el-button>
                <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
            </div>            
        </template>
    </el-dialog>


  </div>
</template>

<script>
import SelectStockSkuList from '@/components/common/SelectStockSkuList.vue'  //库存产品列表
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
export default {
    components:{
        SelectStockSkuList
    },
    data(){
        return{
            areaWhList:[],   //大区关联的仓库列表
            //选择产品-弹出框
            BoxSkuInfo:{
                isVisible:false,
                title:'选择库存产品'
            },
        }
    },
    computed:{
        ...mapState('Erp',['moveInfo'])
    },
    methods:{
        //选择产品
        BtnSelectSku(){
            this.onGetWhListByAreaNumber(1);
            this.BoxSkuInfo.isVisible = true
        },

        //保存选中产品
        btnSelectData(){
            let itemList = this.$refs.SelectSkuInfo.selectedItem
            if(itemList !=null && itemList.length>0){
                itemList.forEach(item => {
                    let newRow = {
                        id:0,
                        systemNumber:'',
                        lossNumber:'',
                        outWarehouseNumber:item.warehouseNumber,  //出库仓库编号
                        outWarehouseName:item.warehouseName,   //出库仓库名称
                        inWarehouseNumber:'',
                        inWarehouseName:'',
                        cid:item.cid,
                        cidName:item.cidName,
                        brandId:item.brandId,
                        brandName:item.brandName,
                        spuNumber:item.spuNumber,
                        spuName:item.spuName,
                        skuNumber:item.skuNumber,
                        skuName:item.skuName,
                        unitId:item.defUnitId,   //默认单位编号
                        unitName:item.defUnitName,  //默认单位名称
                        unitPrice:item.defUnitPrice,  //默认单位单价
                        planNum:1,   //计划数量
                        outNum:0,    //实际出库数量
                        inNum:0,     //实际入库数量
                        outTotalPrice:0,  //出库总成本价
                        inTotalPrice:0,   //入库总成本价
                        remark:'',
                        isDel:0,
                        isEdit:1,
                        stockNum:item.defUnitStockNum,  //库存数量
                        skuUnitList:item.skuUnitList,   //单位列表
                    }
                    this.moveInfo.detailList.push(newRow);
                });
            }
            this.BoxSkuInfo.isVisible = false  //关闭选择产品弹出框
        },

        

        //编辑行
        EditRow(id){
            this.moveInfo.detailList = this.moveInfo.detailList.map(item=>{
                if(item.id === id){
                    item.isEdit = 1;
                }
                return item
            })

            this.onGetWhListByAreaNumber(2);  //加载仓库列表 
        },

        //删除销售明细
        DeleteRow(moveNumber, id, index){
            this.moveInfo.detailList.splice(index,1)
            if(id !=0){
                let param = {
                    moveNumber:moveNumber,
                    id:id
                }
                this.$api.wms.DeleteMoveDetailById(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }
        },

        //当单位发生变化时
        ChangeSkuUnit(){
            this.moveInfo.detailList = this.moveInfo.detailList.map(item=>{
                if(item.isEdit==1){
                    //#region 单位发生变化时-重新计算价格、库存
                    let param3 = {
                        warehouseNumber:this.moveInfo.info.outWarehouseNumber,
                        skuNumber:item.skuNumber,
                        unitId:item.unitId
                    }
                    this.$api.wms.GetSkuUnitStockInfo(param3).then(res=>{
                        if(res.data.status === 200){
                            item.unitPrice = res.data.data.unitPrice;  //成本单价
                            item.stockNum = res.data.data.unitStock;  //库存
                            if(item.planNum > item.maxNum){
                                item.planNum = item.maxNum;  //如果超出库存，就修改数量
                            }
                        }
                    })
                    //#endregion
                }                
                return item
            })            
        },


        //加载指定大区下的仓库列表 type: 1:清空仓库选项 2:保留仓库选项
        onGetWhListByAreaNumber(type){ 
            if(this.moveInfo.info.inAreaNumber!=''){
                let param = {
                    areaNumber:this.moveInfo.info.inAreaNumber,
                }
                this.$api.wms.GetWhListByAreaNumber(param).then(res=>{
                    if(res.data.status === 200){
                        this.areaWhList = res.data.data;
                    }else{
                        this.areaWhList = [];
                    }
                })
            }else{
                this.areaWhList = [];  
            }  

            //清空仓库选项
            if(type==1){
                this.moveInfo.detailList = this.moveInfo.detailList.map(item=>{
                    item.inWarehouseNumber = '';
                    return item
                })
            }
        },

    },
    
}
</script>

<style>

</style>