<template>
  <div>

    <!-- 计划单 -->
    <el-form :inline="true"
    v-model="planInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="planInfo.info">

            <el-col :span="8">
                <el-form-item label="所属部门">
                    <el-tree-select v-model="planInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>            

            <el-col :span="8">
                <el-form-item label="截止日期">
                    <el-date-picker v-model="planInfo.info.termTime" type="datetime" placeholder="截止日期" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px" />
                </el-form-item>
            </el-col>
            
            <el-col :span="8">
                <el-form-item label="状态">
                    <el-tag v-if="planInfo.info.state == 1">新建单</el-tag>
                    <el-tag v-if="planInfo.info.state == 2">已驳回</el-tag>
                    <el-tag v-if="planInfo.info.state == 3">已审核</el-tag>
                    <el-tag v-if="planInfo.info.state == 4">已生成采购单</el-tag>
                    <el-tag v-if="planInfo.info.state == 10">已关单</el-tag>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import { mapState } from 'vuex'
export default {
    mixins:[UserDptList],
    computed:{
        ...mapState('Purchase',['planInfo'])
    },
}
</script>

<style>

</style>