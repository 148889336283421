<template>
  <div>
    <!-- 收货地址 -->
    <el-form :inline="true"
        v-model="orderInfo.addressInfo"
        label-width="100px"
        label-position="right">
            <el-row v-if="orderInfo.addressInfo">

                <el-col :span="8">
                    <el-form-item label="省份">
                        <el-input v-model="orderInfo.addressInfo.provinceName" disabled></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="城市">
                        <el-input v-model="orderInfo.addressInfo.cityName" disabled></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="区县">
                        <el-input v-model="orderInfo.addressInfo.areaName" disabled></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="详细地址">
                        <el-input v-model="orderInfo.addressInfo.address" disabled></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="收货人">
                        <el-input v-model="orderInfo.addressInfo.linkMan" disabled></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="收货电话">
                        <el-input v-model="orderInfo.addressInfo.linkPhone" disabled></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
    </el-form>

    <div class="DetailHeight"></div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name:'TabOrderAddress',
  computed:{
    ...mapState('Shop',['orderInfo'])
  },
}
</script>

<style>

</style>