<template>
  <div>
    <el-divider></el-divider>
    <!-- 选择员工列表 -->

    <el-table :data="info.dataList" border stripe style="width: 100%" @select="btnCheckbox" @select-all="btnAllCheckbox" @row-click="TableRowClick" ref="table">
        <el-table-column prop="id" type="selection" width="50"></el-table-column>
        <el-table-column prop="departmentName" label="所属部门"></el-table-column>
        <el-table-column prop="userName" label="员工姓名"></el-table-column>
        <el-table-column label="性别">
            <template #default="scope">
                <el-tag v-if="scope.row.sex ==0">女</el-tag>
                <el-tag v-if="scope.row.sex ==1" type="danger">男</el-tag>
                <el-tag v-if="scope.row.sex ==2" type="success">未知</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="员工类型">
            <template #default="scope">
                <el-tag v-if="scope.row.staffType ==1">正式员工</el-tag>
                <el-tag v-if="scope.row.staffType ==2" type="danger">临时员工</el-tag>
            </template>
        </el-table-column>
    </el-table>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    data(){
        return{
            info:{
                dataList:[],   //员工列表
            },
            selectedItem:[],   //选中后的员工列表
        }
    },
    methods:{
        //加载员工列表
        GetUserDataList(){
            this.$api.system.SelectUserList().then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data  //用户列表
                }else{
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e.message);
            })
        },

        //选中多选框
        btnCheckbox(row){
            this.selectedItem = row
        },
        //全选
        btnAllCheckbox(){
            this.selectedItem = this.info.dataList
        },

        //选中行时(标准模板)
        TableRowClick(row){
            let isExist = false; //判断是否存在
            this.selectedItem = this.selectedItem.filter(item=>{                
                if(row.userNumber != item.userNumber){
                    return item;
                }else{                    
                    //如果存在
                    isExist = true;
                }                
            })
            
            if(isExist){
                this.$refs.table.toggleRowSelection(row,false);
            }else{
                this.selectedItem.push(row)
                this.$refs.table.toggleRowSelection(row,true);
            }
        },

    },
    mounted(){
        this.GetUserDataList();
    }
}
</script>

<style>

</style>