import axios from 'axios'
import {ElLoading} from 'element-plus'
// import store from '../store'
import Authorization from '../utils/authorize'
import Router from '../router/index'


let loading = null
axios.defaults.timeout = 30000;  //请求超时时间30s
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 请求拦截
axios.interceptors.request.use(config => {
    // 加载效果
    loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    
    // 每次发送请求之前判断vuex中是否存在token        
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
    // const token = store.state.Master.token;
    
    const token = Authorization.getToken() //
    if(token) config.headers.Authorization = `Bearer ${token}`
    return config;
},error =>{
    return Promise.error(error);
})


// 响应拦截
axios.interceptors.response.use(response=>{
    //结束Loading
    loading.close()
    if(response.status===200){
        if(response.data.status === 401){
            Authorization.clearToken()  
            //跳转登录页面
            Router.replace({
                path:'/login',
                query:{
                    redirect: Router.currentRoute.fullPath 
                }
            },1000)
        }
        else if(response.data.status === 403){
            Authorization.clearToken()  
            //跳转登录页面
            Router.replace({
                path:'/login',
                query:{
                    redirect: Router.currentRoute.fullPath 
                }
            },1000)
        }
        else{
            return Promise.resolve(response)
        }        
    }else{
        return Promise.reject(response)
    }
},error=>{
    //结束Loading
    loading.close()
    if(error.response.status){
        switch(error.response.status){
            case 401:
                Router.replace({
                    path:'/login',
                    query:{
                        redirect: Router.currentRoute.fullPath 
                    }
                },1000)
                break;
            case 403:
                // Toast({
                //     message: '登录过期，请重新登录',
                //     duration: 1000,
                //     forbidClick: true
                // });
                //console.log('登录过期，需要重新登录')
                //清除token
                Authorization.clearToken()  
                //跳转登录页面
                Router.replace({
                    path:'/login',
                    query:{
                        redirect: Router.currentRoute.fullPath 
                    }
                },1000)

                break;
            case 404:
                // Toast({
                //     message: '网络请求不存在',
                //     duration: 1500,
                //     forbidClick: true
                // });
                console.log('网络请求不存在')
                break;
            default:
                // Toast({
                //     message: error.response.data.message,
                //     duration: 1500,
                //     forbidClick: true
                // });
        }
    }
    // 错误提醒
    return Promise.reject(error);
})

export default axios
