<template>
  <div>
    <!-- 编辑审批单 -->
    <el-divider></el-divider>

    <el-tabs v-model="info.activeName">
        <el-tab-pane label="审批单" name="tabInfo">
            <TabProcess></TabProcess>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabRecord></TabRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabState></TabState>
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="btnGroup" v-if="processInfo.info">
        <el-button type="primary" v-if="(processInfo.info.billNumber !='' && processInfo.info.state==1) || processInfo.info.state ==3" @click="btnOpenBox">提交审核</el-button>
        <el-button type="warning" v-if="processInfo.info.state ==2 || processInfo.info.state == 4" @click="btnOpenBox">审核</el-button>
        <el-button type="primary" v-if="processInfo.info.state==1 || processInfo.info.state == 3" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 审核-弹出框 -->
    <el-dialog title="审批单审核"
    v-model="BoxCheckState.isVisible"
    width="500">
        <el-divider></el-divider>

        <el-descriptions border :column="1">
            <el-descriptions-item label="下步审核人">
                <el-select v-model="BoxCheckState.checkerNumber" class="search-200">
                    <el-option v-for="user in selectUserList" :key="user.userNumber" :label="user.userName" :value="user.userNumber"></el-option>
                </el-select>
            </el-descriptions-item>

            <el-descriptions-item label="审核说明">
                <el-input v-model="BoxCheckState.remark" :maxlength="50" class="search-200"></el-input>
            </el-descriptions-item>
        </el-descriptions>

        <!-- 操作按钮 -->
        <template #footer>            
            <el-button type="primary" v-if="processInfo.info.state==1 || processInfo.info.state ==3" @click="btnUpdateState(2)">提交审核</el-button>
            <el-button type="danger" v-if="processInfo.info.state==2 || processInfo.info.state == 4" @click="btnUpdateState(3)">驳回</el-button>
            <el-button v-if="processInfo.info.state==2 || processInfo.info.state ==4" type="primary" @click="btnUpdateState(4)">审核通过并上报</el-button>
            <el-button v-if="processInfo.info.state==2 || processInfo.info.state==4" type="success" @click="btnUpdateState(5)">审核完成</el-button>            
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>

import TabProcess from '@/views/Oa/Process/Info/TabProcess.vue'
import TabRecord from '@/views/Oa/Process/Info/TabRecord.vue'
import TabState from '@/views/Oa/Process/Info/TabState.vue'

import AllUserList from '@/utils/mixins/AllUserList.js'
import { ElLoading, ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
export default {
    name:'EditProcess',
    props:['editId','dataList'],
    mixins:[AllUserList],
    components:{
        TabProcess,
        TabRecord,
        TabState,
    },
    data(){
        return{
            info:{
                activeName:'tabInfo',
                billNumber:this.editId,
            },
            //审核-弹出框
            BoxCheckState:{
                isVisible:false,
                //审核内容
                checkerList:[],   //审核人列表
                checkerNumber:'',   //选中审核人(编号)
                remark:'',     //审核说明
            },
        }
    },
    computed:{
        ...mapState('Oa',['processInfo']),
    },
    methods:{
        ...mapMutations('Oa',['SetProcessInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.billNumber!=0){
                let param = {
                    billNumber:this.info.billNumber
                }
                this.$api.oa.SingleProcessAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetProcessInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存数据 type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            this.$api.oa.SaveProcessAllInfo(this.processInfo).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //类型
                    if(type == 1){
                        this.processInfo.info.billNumber = res.data.data;
                        this.info.billNumber = res.data.data;
                        this.GetDataInfo();
                    }else{
                        this.btnClearData();
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })

        },

        //清空数据
        btnClearData(){
            var newInfo = {
                info:{
                    billNumber:'',
                    systemNumber:'',
                    departmentId:0,
                    departmentName:'',
                    typeId:0,
                    typeName:'',
                    state:1,
                    content:'',
                    amount:1,
                    totalPrice:0,
                    remark:'',
                    receiver:'',
                    receiverName:'',
                    createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    creator:'',
                    createName:'',
                    isDel:0,
                },
                detailList:[],
                recordList:[],
                stateList:[]
            }
            this.SetProcessInfo(newInfo);
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.info.billNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.info.billNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
        },

        //打开审核-弹出框
        btnOpenBox(){
            this.BoxCheckState.isVisible = true;
        },
        //提交审核状态-弹出框
        btnUpdateState(state){
            let param = {
                billNumber:this.processInfo.info.billNumber,
                state:state,
                receiver:this.BoxCheckState.checkerNumber,
                remark:this.BoxCheckState.remark
            }
            this.$api.oa.UpdateProcessState(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //重新加载
                    this.GetDataInfo();
                    this.BoxCheckState.isVisible = false;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

    },
    mounted(){
        this.GetDataInfo();
    }

}
</script>

<style>

</style>