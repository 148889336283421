<template>
  <div>
    <el-divider></el-divider>

    <div id="printData">
        <div>            
            <el-row>
                <el-col :span="7" class="titleLeft">打印时间：{{printTime}}</el-col>
                <el-col :span="10" class="title"><h3>财务单</h3></el-col>
                <el-col :span="7" class="titleRight">公司名称：{{CurrentsystemInfo.companyName}}</el-col>
            </el-row>
            <el-divider></el-divider>
        </div>

        <!-- 费用单 -->
        <table class="DataInfo">
            <tr>
                <td>财务单号：{{financeInfo.info.billNumber}}</td>
                <td>所属部门：{{financeInfo.info.departmentName}}</td>
                <td>财务类型：{{financeInfo.info.billTypeName}}</td>
                <td>会计科目：{{financeInfo.info.subFullName}}</td>              
            </tr>
            <tr>
                <td>交易者类型：
                    <span v-if="financeInfo.info.tradeType==1">供应商</span>
                    <span v-if="financeInfo.info.tradeType==2">客户</span>
                    <span v-if="financeInfo.info.tradeType==3">员工</span>
                </td>
                <td>交易者：{{financeInfo.info.tradeName}}</td>
                <td>交易账户：{{financeInfo.info.accountName}}</td>
                <td>总金额：{{financeInfo.info.totalPrice}}元</td>
            </tr>
            <tr>
                <td>记账时间：{{financeInfo.info.billTime}}</td>
                <td>用款时间：{{financeInfo.info.payTime}}</td>
                <td>状态：
                    <span v-if="financeInfo.info.state ==1">新建单</span>
                    <span v-if="financeInfo.info.state ==2">已驳回</span>
                    <span v-if="financeInfo.info.state ==3">审核通过并上报</span>
                    <span v-if="financeInfo.info.state ==4">审核完成</span>
                    <span v-if="financeInfo.info.state ==5">已结款</span>
                    <span v-if="financeInfo.info.state ==10">已完成</span>
                </td>
                <td>备注：{{financeInfo.info.remark}}</td>
            </tr>
        </table>

        <!-- 财务单明细 -->
        <div class="financeDetail">
            <el-divider>财务明细</el-divider>
            <table class="DataDetail">
                <tr>
                    <th>业务单号</th>
                    <th>单据类型</th>
                    <th>部门</th>
                    <th>交易者类型</th>
                    <th>交易者</th>
                    <th>产品类目</th>
                    <th>品牌</th>
                    <th>产品名称</th>
                    <th>规格</th>
                    <th>单位</th>
                    <th>单价(元)</th>
                    <th>数量</th>
                    <th>总价(元)</th>
                    <th>记账时间</th>
                </tr>
                <tr v-for="detail in financeInfo.detailList" :key="detail.costNumber">
                    <td>{{detail.itemName}}</td>
                    <td>{{detail.unitPrice}}</td>
                    <td>{{detail.amount}}</td>
                    <td>{{detail.totalPrice}}</td>
                    <td>{{detail.remark}}</td>
                </tr>
            </table>
        </div>
        
        <div class="financeDetail">
            <el-divider>抵扣明细</el-divider>
            <table class="DataDetail">
                <tr>
                    <th>财务单号</th>
                    <th>财务单类型</th>
                    <th>会计科目</th>
                    <th>交易者类型</th>
                    <th>交易者</th>
                    <th>金额</th>
                    <th>状态</th>
                </tr>
            </table>
        </div>

        <div class="financeDetail">
            <el-divider>差额明细</el-divider>
            <table class="DataDetail">
                <tr>
                    <th>编号</th>
                    <th>差额</th>
                    <th>差额说明</th>
                    <th>文件</th>
                    <th>备注</th>
                    <th>创建时间</th>
                </tr>
            </table>
        </div>

    </div>

    <!-- 按钮 -->
    <div class="btnGroup">
        <el-button type="primary" class="iconfont icon-dayin" v-print="printObj" id="btnPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
export default {
    data(){
        return{
            printTime:moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            printObj: {
              id:'printData',
              preview: false,
              popTitle: '打印预览',
            }
        }
    },
    computed:{
        ...mapState('Erp',['financeInfo']),
        ...mapState('Master',['CurrentsystemInfo']),
    },
    methods:{
        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
}
</script>

<style scoped>
#printData {font-size: 12px;overflow: hidden;} /**防止多打印一张 */
#printData .titleLeft{display: flex;align-items: flex-end;}
#printData .title {text-align: center;font-size: 14px;}
#printData .titleRight{display: flex;align-items: flex-end;justify-content: flex-end;}
#printData .DataInfo{width: 100%;}
#printData .DataInfo td {padding: 5px 0px;}

#printData .cellBill{padding-bottom: 20px;} /* 间隔 单据*/
#printData .DataDetail {width: 100%;border-collapse: collapse;}
#printData .DataDetail th {border: 1px solid #ccc;}
#printData .DataDetail td {border: 1px solid #ccc;}

.financeDetail{padding-top: 20px;}
</style>