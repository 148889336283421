/**
 * 获取用户授权下的仓库列表
 * ===下拉列表专用===
 */

const UserWhList = {
    data(){
        return{
            userWhList:[]  //用户关联仓库列表
        }
    },
    methods:{
        //加载用户关联的仓库
        SelectUserWhList(){
            this.$api.system.SelectUserWhList().then(res=>{
                if(res.data.status === 200){
                    this.userWhList = res.data.data;
                }else{
                    this.userWhList = [];
                }
            })
        },
    },
    mounted(){
        this.SelectUserWhList();  
    },
}

export default UserWhList