<template>
  <div>
      <div class="skuBtnList">
          <el-button class="iconfont icon-add" type="primary" plain @click="BtnSelectSku">选择产品</el-button>
      </div>

      <el-table :data="inquiryInfo.detailList" border style="width: 100%">

        <el-table-column prop="spuName" label="产品名称" />
        <el-table-column prop="skuName" label="规格名称" />
        <el-table-column prop="planNum" label="计划数量">
            <template v-slot="scope">
              <el-input-number v-if="scope.row.isEdit == 1" v-model="scope.row.planNum" :min="1" :precision="2"></el-input-number>
                <span v-else >{{scope.row.planNum}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="160" fixed="right">
          <template v-slot="scope">
            <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
            <el-popconfirm 
            title="确认要删除吗?"
            @confirm="DeleteRow(scope.row.billNumber,scope.row.skuNumber,scope.row.id,scope.$index)">
                <template #reference>
                <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
          </template>
        </el-table-column>
        
      </el-table>

      <!-- 选择产品 -->
      <el-dialog :title="BoxSkuInfo.title" 
        v-model="BoxSkuInfo.isVisible"
        width="60%"
        :destroy-on-close="true">
        <SelectAllSkuList ref="SelectSkuInfo"></SelectAllSkuList>
        <template #footer>
            <el-button type="primary" @click="btnSelectData">选择</el-button>
            <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
        </template>
      </el-dialog>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
import SelectAllSkuList from '@/components/common/SelectAllSkuList.vue'
export default {
  name:'TabSingleInquiryDetail',
  components:{
    SelectAllSkuList
  },
  data(){
    return{
      //选择产品-弹出框
      BoxSkuInfo:{
        isVisible:false,
        title:'选择产品'
      },
    }
  },
  computed:{
    ...mapState('Purchase',['inquiryInfo'])
  },
  methods:{
    //选择产品
    BtnSelectSku(){
        this.BoxSkuInfo.title = "选择产品"
        this.BoxSkuInfo.isVisible = true
    },
    //保存选中产品
    btnSelectData(){
        let itemList = this.$refs.SelectSkuInfo.selectedItem
        if(itemList !=null && itemList.length>0){
            itemList.forEach(item => {
                let newRow = {
                    id:0,
                    systemNumber:item.systemNumber,
                    billNumber:'',
                    spuNumber:item.spuNumber,
                    spuName:item.spuName,
                    skuNumber:item.skuNumber,
                    skuName:item.skuName,
                    planNum:1,
                    isEdit:1,
                    isDel:0
                }
                this.inquiryInfo.detailList.push(newRow)
            });
        }            
        this.BoxSkuInfo.isVisible = false
    },
    //编辑行
    EditRow(id){
        this.inquiryInfo.detailList = this.inquiryInfo.detailList.map(item=>{
            if(item.id === id){
                item.isEdit = 1
            }
            return item
        })
    },
    //删除行
    DeleteRow(billNumber,skuNumber,id,index){
        this.inquiryInfo.detailList.splice(index,1)
        if(id !=0){
          let param = {
            billNumber:billNumber,
            skuNumber:skuNumber
          }
          this.$api.inquiry.DeleteInquiryDetail(param).then(res=>{
              if(res.data.status === 200){
                  ElMessage.success(res.data.msg)
              }
              else
              {
                  ElMessage.error(res.data.msg)
              }
          })
        }
    },


  }

}
</script>

<style>

</style>