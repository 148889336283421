<template>
  <div>
    <!-- 加工组 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="加工组名">
                <el-input v-model="info.search.workGroupName"></el-input>
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>


    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')" v-if="CheckUserButtonAuth('BtnProofAdd')">新增</el-button>
                <el-popconfirm
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column type="selection" prop="workGroupNumber" width="50" />  
        <el-table-column prop="workGroupNumber" label="编号" width="170" />
        <el-table-column prop="departmentName" label="部门名称" min-width="100">
            <template v-slot="scope">
                <el-tooltip
                class="box-item"
                effect="dark"
                :content="scope.row.departmentName"
                placement="top"
                >
                <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column prop="title" label="加工组名" min-width="150">
            <template v-slot="scope">
                <el-tooltip
                class="box-item"
                effect="dark"
                :content="scope.row.workGroupName"
                placement="top"
                >
                <div class="cellOverFlow">{{scope.row.workGroupName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>     

        <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.workGroupNumber)">详情</el-button>
            <el-popconfirm
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.workGroupNumber)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
                </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[50, 200, 500]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange">
            </el-pagination>
        </el-container>

    </div>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="600"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditWorkGroup :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditWorkGroup>
    </el-dialog>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'   //部门-下拉列表-目录树
import EditWorkGroup from '@/views/Work/WorkGroup/EditWorkGroup.vue' //加工组详情页
import { ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList],
    components:{
        EditWorkGroup
    },
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    selectDepartmentList:[],   //部门
                    workGroupName:'',  //加工组名
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'编辑加工组'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{

        //加载数据列表
        GetDataList(){
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                workGroupName:this.info.search.workGroupName,
                page:this.info.page,
                size:this.info.size
            }

            this.$api.erp.PageWorkGroupList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data; 
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.workGroupNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //新增、编辑信息
        btnEditInfo(workGroupNumber){
            this.info.id = workGroupNumber
            if(workGroupNumber==0){
                this.BoxEditInfo.title = "新增加工组"
            }else{
                this.BoxEditInfo.title = "编辑加工组"
            }
            this.BoxEditInfo.isVisible = true;
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(workGroupNumber){
            let param = [workGroupNumber]
            this.$api.erp.DeleteWorkGroupList(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.erp.DeleteWorkGroupList(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.selectDepartmentList = [];
            this.info.search.workGroupName='';
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        },


    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style>

</style>