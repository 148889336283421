<template>
  <div>
    <!-- 生成财务单 - 弹出框 -->
    <el-divider></el-divider>

    <el-form :inline="true"
        v-model="info"
        label-width="100px"
        label-position="right">
            <el-row>

                <el-col :span="12">
                    <el-form-item label="部门">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>部门</span>
                        </template>
                        <el-tree-select v-model="info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200">
                        </el-tree-select>
                    </el-form-item>
                </el-col>      

                <el-col :span="12">
                    <el-form-item label="财务类型">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>财务类型</span>
                        </template>
                        <el-select v-model="info.billType" class="search-200">
                            <el-option label="选择类型" :value="0"/>
                            <el-option v-for="typeInfo in financeTypeList" :key="typeInfo.typeValue" :label="typeInfo.typeName" :value="typeInfo.typeValue"></el-option>
                            
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="会计科目">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>会计科目</span>
                        </template>
                        <el-tree-select v-model="info.subId" :data="selectSubjectList" :default-expanded-keys="[0]" class="search-200">
                        </el-tree-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="交易者">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>交易者</span>
                        </template>
                        <el-select v-model="info.tradeNumber" filterable clearable class="search-200">
                            <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="记账时间">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>记账时间</span>
                        </template>
                        <el-date-picker v-model="info.billTime" type="datetime" placeholder="记账时间" value-format="YYYY-MM-DD hh:mm:ss" style="width:200px"/>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="应付金额">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>应付金额</span>
                        </template>
                        <el-input-number v-model="info.payablePrice" :precision="2" class="search-200" disabled></el-input-number>
                    </el-form-item>
                </el-col>

                

                <el-col :span="12">
                    <el-form-item label="实付金额">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>实付金额</span>
                        </template>
                        <el-input-number v-model="info.totalPrice" :precision="2" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="备注">
                        <el-input v-model="info.remark" :maxlength="50" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
    </el-form>

    <!-- 备注列表 -->
    <el-divider>备注记录</el-divider>
    <div class="skuBtnList btnList">
        <el-button class="iconfont icon-add" type="primary" plain @click="AddRecordFont">文字</el-button>
        <el-upload style="margin-left:10px"
            :action="GetFileUrl('Record')"
            :headers="GetHeader"
            :show-file-list="false"
            multiple
            :before-upload="BeforeUpload"
            :on-success="UploadSuccess"
            :on-error="UploadError">
                <el-button class="iconfont icon-add" type="primary" plain>上传文件</el-button>
        </el-upload>
    </div>
    
    <el-table :data="info.recordList" border style="width: 100%">

        <el-table-column prop="skuName" label="备注类型">
            <template v-slot="scope">
                <span v-if="scope.row.type == 1">文字</span>
                <span v-if="scope.row.type == 2">图片</span>
                <span v-if="scope.row.type == 3">文件</span>
                <span v-if="scope.row.type == 4">视频</span>
            </template>
        </el-table-column>

        <el-table-column prop="skuName" label="备注内容">
            <template v-slot="scope">
                <!-- 文字 -->
                <el-input v-if="scope.row.id ==0 && scope.row.type ==1" v-model="scope.row.content"></el-input>
                <span v-if="scope.row.id !=0 && scope.row.type==1" >{{scope.row.content}}</span>
                <!-- 图片 -->
                <el-image v-if="scope.row.type==2"
                    style="width: 70px; height: 70px;border:1px solid #ccc;"
                    :src="scope.row.content"
                    :preview-src-list="info.imgList"
                    :initial-index="scope.$index"
                    :hide-on-click-modal="true"
                    :infinite="false"
                    fit="cover"
                    />
                <!-- 文件 -->
                <el-link v-if="scope.row.type==3" :href="scope.row.content" target="_blank">{{scope.row.title}}</el-link>
            </template>
        </el-table-column>

        <el-table-column prop="createName" label="创建者">
            <template v-slot="scope">
                <span>{{scope.row.createName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="createName" label="创建时间">
            <template v-slot="scope">
              <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="160" fixed="right">
        <template v-slot="scope">
            <el-popconfirm 
            title="确认要删除吗?"
            @confirm="DeleteRow(scope.row.billNumber,scope.row.id,scope.$index)">
                <template #reference>
                <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
        </template>
        </el-table-column>
    </el-table>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import SubjectList from '@/utils/mixins/SubjectList.js'  //会计科目
import PartTradeList from '@/utils/mixins/PartTradeList.js'  //交易者列表
import FinaceTypeList from '@/utils/mixins/FinanceTypeList.js'  //财务单类型列表
import Authorize from '@/utils/authorize.js'
import moment from 'moment'
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
export default {
    mixins:[UserDptList,SubjectList,PartTradeList,FinaceTypeList],
    props:['financeInfo'],
    data(){
        return{
            info:{
                departmentId:0,
                billType:0,  //财务类型
                subId:0,   
                payablePrice:0,  //应付总金额
                totalPrice:0,  //实付总金额
                billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                remark:'',
                numberList:[],
                tradeType:0,
                tradeNumber:'',  //交易者编号
                tradeName:'',   //交易者名称
                recordList:[],   //备注列表
                imgList:[],  //备注图片列表
            },
            activeName:'TabInfo',   //默认标签页           
        }
    },
    computed:{
        // 获取当前用户名
        ...mapState('Master',['currentUserName']),
        //添加Token - 重要
        GetHeader(){
            return {
                "Authorization":"Bearer "+ Authorize.getToken()
            }
        },
    },
    methods:{
        
        


        //***************备注相关********************************** */
        //新增备注文字
        AddRecordFont(){
            let recordInfo = {
                id:0,
                systemNumber:'',
                billNumber:'',  //财务单号
                billType:30,  //单据类型 ：财务单
                title:'',
                content:'', //内容
                type:1,  //文字类型
                createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                creator:'',
                createName:this.currentUserName,
                isdel:0
            }
            this.info.recordList.push(recordInfo);            
        },
        
        //删除行
        DeleteRow(billNumber,id,index){
            this.info.recordList.splice(index,1);
            if(id!=0){
                let param = {
                    billNumber:billNumber,
                    id:id
                }
                this.$api.erp.DeleteRecord(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }
            
        },


        //上传图片之前操作
        BeforeUpload(file){
            const fileType = file.type;
            const fileSize = file.size;
            if(fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'application/pdf' && fileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                ElMessage.error('上传格式: jpeg | png | pdf | docx | xlsx')
                return false;
            }
            //文件大小
            if(fileSize/1024/1024 > 5){
                ElMessage.error('上传文件大小不能超过5Mb')
                return false;
            }

        },
        //上传完成（图片、文件）通用
        UploadSuccess(res,file){
            if(res.status === 200){
                const fileExt = res.data.slice((res.data.lastIndexOf(".") - 1 >>> 0) + 2); //文件类型
                let fileType = 2; //默认图片类型
                if(fileExt =="docx" || fileExt=="pdf" || fileExt == "xlsx"){
                    fileType = 3;  //文件类型
                }
                //创建新记录
                let newRow = {
                    id:0,
                    systemNumber:'',
                    billNumber:'',  //财务单号
                    billType:30,  //单据类型：30：财务单
                    title:file.name,
                    content:res.data,
                    type:fileType,  //类型
                    createName:this.currentUserName,  //创建者 
                    createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),  //创建时间
                }
                this.info.recordList.push(newRow);  
                ElMessage.success('上传成功');               
            }
        },
        //上传失败（图片、文件）通用
        UploadError(e){
            ElMessage.error(e)
        },

    },
    mounted(){
        this.info.payablePrice = this.financeInfo.totalPrice;  //应付总金额
        this.info.totalPrice = this.financeInfo.totalPrice;  //实付总金额        
        this.info.numberList = this.financeInfo.numberList;
        //交易者编号
        if(this.financeInfo.tradeNumber !=''){
            this.info.tradeNumber = this.financeInfo.tradeNumber;
        }
        //部门
        if(this.financeInfo.departmentId !=0){
            this.info.departmentId = this.financeInfo.departmentId;
        }
    }
}
</script>

<style scoped>
.btnList{display: flex;justify-content: flex-start;}
</style>