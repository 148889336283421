<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="工资单" name="tabWage">
            <TabSingleWage></TabSingleWage>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabSingleWageRecord></TabSingleWageRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabSingleWageState></TabSingleWageState>
        </el-tab-pane>

    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="wageInfo.info">
        <el-button type="primary" v-if="wageInfo.info.state==1 || wageInfo.info.state == 3" @click="btnSaveData">保存</el-button>
        <el-button type="primary" v-if="(wageInfo.info.wageNumber !='' && wageInfo.info.state==1) || wageInfo.info.state ==3" @click="btnUpdateState(2)">提交审核</el-button>
        <el-button v-if="wageInfo.info.state == 2 || wageInfo.info.state == 4" type="warning" @click="BoxCheckState.isVisible = true">审核</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 审核-弹框 -->
    <el-dialog title="费用单审核"
    v-model="BoxCheckState.isVisible"
    width="500px">
        <el-divider></el-divider>
   
        <el-descriptions border :column="1">
            <el-descriptions-item label="下步审核人">
                <el-select v-model="BoxCheckState.checkerNumber" class="search-200">
                    <el-option v-for="item in selectUserList" :key="item.userNumber" :label="item.userName" :value="item.userNumber"></el-option>
                </el-select>
            </el-descriptions-item>

            <el-descriptions-item label="审核说明">
                <el-input v-model="BoxCheckState.remark" :maxlength="50" class="search-200"></el-input>
            </el-descriptions-item>
        </el-descriptions>

        <template #footer>            
            <el-button type="danger" @click="btnUpdateState(3)">驳回</el-button>
            <el-button v-if="CheckUserButtonAuth('BtnWageAuditReport')" type="primary" @click="btnUpdateState(4)">审核通过并上报</el-button>
            <el-button v-if="CheckUserButtonAuth('BtnWageAuditComplate')" type="success" @click="btnUpdateState(5)">审核完成</el-button>            
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>


  </div>
</template>

<script>
import AllUserList from '@/utils/mixins/AllUserList.js'

import TabSingleWage from '@/views/Oa/Wage/TabSingleWage.vue'
import TabSingleWageRecord from '@/views/Oa/Wage/TabSingleWageRecord.vue'
import TabSingleWageState from '@/views/Oa/Wage/TabSingleWageState.vue'
import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
import { ElMessage } from 'element-plus'
export default {
  components:{
    TabSingleWage,
    TabSingleWageRecord,
    TabSingleWageState
  },
  props:['editId','dataList'],
  mixins:[AllUserList],
  data(){
    return{
      info:{
        activeName:'tabWage',   //标签切换页
        wageNumber:this.editId,
      },
      //审核-弹出框
      BoxCheckState:{
          isVisible:false,
          checkerNumber:'',   //选中审核人(编号)
          checkerName:'',   //选中审核人(中文)(不用了)
          remark:'',     //审核说明
      }
    }
  },
  computed:{
    ...mapState('Oa',['wageInfo'])
  },
  methods:{
    ...mapMutations('Oa',['SetWageInfo']),

    //获取数据
    GetDataInfo(){
      if(this.info.wageNumber !=0){
        let param = {
          wageNumber:this.info.wageNumber
        }
        this.$api.oa.SingleWageAllInfo(param).then(res=>{
            if(res.data.status === 200){
              this.SetWageInfo(res.data.data)
            }else{
              this.btnClearData();
            }
        })
      }
      else
      {
          this.btnClearData();
      }
    },

    //保存
    btnSaveData(){
      this.$api.oa.SaveWage(this.wageInfo).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          //
          this.GetDataInfo();
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //提交审核状态
    btnUpdateState(state){
        let param = {
            wageNumber:this.wageInfo.info.wageNumber,
            state:state,
            receiver:this.BoxCheckState.checkerNumber,  //审核人
            remark:this.BoxCheckState.remark,
        }
        if(state ==2){
          param.receiver = this.wageInfo.info.receiver;  //审核人
          param.remark='';  //说明
        }
        this.$api.oa.UpdateWageState(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg);
                //重新加载
                this.GetDataInfo();
                this.BoxCheckState.isVisible = false
            }else{
                ElMessage.error(res.data.msg)
            }
        })
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

    //上一条
    btnPrevious(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.wageNumber === this.info.wageNumber
        })
        let prevIndex = currentIndex -1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有上一条了");
            return false;
        }
        else{
            this.info.wageNumber = prevInfo.wageNumber
            this.GetDataInfo()
        }
        
    },
    //下一条
    btnNext(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.wageNumber === this.info.wageNumber
        })
        let prevIndex = currentIndex + 1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有下一条了");
            return false;
        }
        else{
            this.info.wageNumber = prevInfo.wageNumber
            this.GetDataInfo()
        }
    },

    //清空数据
    btnClearData(){
      var newInfo = {
            info:{
              id:0,
              systemNumber:'',
              userNumber:'',
              wageNumber:'',
              month:moment(new Date()).format("YYYY-MM"),
              realWage:0,
              state:1,
              receiver:'',
              receiverName:'',
              financeNumber:'',
            },
            detailList:[],
            recordList:[],
            stateList:[]
          }
        this.SetWageInfo(newInfo);
    },


  },
  mounted(){
    this.GetDataInfo();
  }
}
</script>

<style scoped>
</style>