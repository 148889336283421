<template>
  <div>

    <div class="skuBtnList">
        <el-button class="iconfont icon-add" type="primary" plain @click="AddRow">新增</el-button>
    </div>

    <!-- 列表 -->
    <el-table :data="supplierInfo.invoiceList" border style="width: 100%">
        <el-table-column prop="skuName" label="账户名称" min-width="110">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.accountName"></el-input>
                <span v-else >{{scope.row.accountName}}</span>
            </template>
        </el-table-column>

        <el-table-column label="纳税人识别号" min-width="110">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.invoiceNumber"></el-input>
                <span v-else >{{scope.row.invoiceNumber}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="address" label="地址" min-width="110">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.address"></el-input>
                <span v-else >{{scope.row.address}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="phone" label="电话" min-width="110">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.phone"></el-input>
                <span v-else >{{scope.row.phone}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="skuName" label="开户行" min-width="110">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.bankName"></el-input>
                <span v-else >{{scope.row.bankName}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="skuName" label="账号" min-width="110">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.bankNumber"></el-input>
                <span v-else >{{scope.row.bankNumber}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="130" fixed="right">
        <template v-slot="scope">
            <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.invoiceId)">编辑</el-button>
            <el-popconfirm 
            title="确认要删除吗?"
            @confirm="DeleteRow(scope.row.supplierNumber,scope.row.invoiceId,scope.$index)">
                <template #reference>
                <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
        </template>
        </el-table-column>
    </el-table>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
export default {
    name:'TabSupplierInvoice',
    computed:{
        ...mapState('Purchase',['supplierInfo'])
    },
    methods:{

        //添加行
        AddRow(){
            let row = {
                invoiceId:0,
                systemNumber:'',
                supplierNumber:this.supplierNumber,
                companyName:'',
                invoiceNumber:'',
                addressPhone:'',
                accountNumber:'',
                isDel:0,
                isEdit:1
            }
            this.supplierInfo.invoiceList.push(row)
        },
        //编辑行
        EditRow(invoiceId){
            this.supplierInfo.invoiceList = this.supplierInfo.invoiceList.map(item=>{
                if(item.invoiceId === invoiceId){
                    item.isEdit = 1
                }                
                return item
            })
        },
        //删除行
        DeleteRow(supplierNumber,invoiceId,index){
            this.supplierInfo.invoiceList.splice(index,1)
            if(invoiceId !=0){
                let param = {
                    supplierNumber:supplierNumber,
                    invoiceId:invoiceId
                }
                this.$api.supplier.DeleteInvoice(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage({type:'success',message:res.data.msg})
                    }
                    else{
                        ElMessage({type:'error',message:res.data.msg})
                    }
                })
            }
        }
    }
}
</script>

<style>

</style>