<template>
  <div>
    <!-- 业务员绩效统计 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="用户编号">
                  <el-select v-model="info.search.userNumber" class="search-150" filterable clearable>
                    <el-option v-for="info in selectUserList" :key="info.userNumber" :label="info.userName" :value="info.userNumber"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="日期">
                    <el-date-picker
                        v-model="info.search.datePoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :editable="false"
                        style="width:250px"
                    />
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <div>
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button type="primary" plain @click="openBoxStatSaler">重新统计</el-button>
                <el-button class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>                    
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>
            </el-col>
        </el-row>
            

        <el-table :data="dataList" border stripe show-summary style="width: 100%;" :height="GetDataHeight" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="id" type="selection" width="55" />  
            <el-table-column label="日期">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.billDate"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{DateFormat(scope.row.billDate)}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="createName" label="营业员"></el-table-column>
            <el-table-column prop="totalPrice" label="销售总额 | 单数">
                <template v-slot="scope">
                    <span class="redPrice">{{this.myRound(scope.row.totalPrice,2)}}</span> /
                    <span class="blueNum">{{scope.row.totalNum}}</span>
                </template>
            </el-table-column>

            <el-table-column prop="salePrice" label="销售单 | 单数">
                <template v-slot="scope">
                    <span>{{this.myRound(scope.row.salePrice,2)}}</span> /
                    <span>{{scope.row.saleNum}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="returnSalePrice" label="退货单 | 单数">
                <template v-slot="scope">
                    <span>{{this.myRound(scope.row.returnSalePrice,2)}}</span> /
                    <span>{{scope.row.returnSaleNum}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="retailPrice" label="零售单 | 单数">
                <template v-slot="scope">
                    <span>{{this.myRound(scope.row.retailPrice,2)}}</span> /
                    <span>{{scope.row.retailNum}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="returnRetailPrice" label="退零单 | 单数">
                <template v-slot="scope">
                    <span>{{this.myRound(scope.row.returnRetailPrice,2)}}</span> /
                    <span>{{scope.row.returnRetailNum}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="purchasePrice" label="采购单 | 单数">
                <template v-slot="scope">
                    <span>{{this.myRound(scope.row.purchasePrice,2)}}</span> /
                    <span>{{scope.row.purchaseNum}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="returnPurchasePrice" label="退供单 | 单数">
                <template v-slot="scope">
                    <span>{{this.myRound(scope.row.returnPurchasePrice,2)}}</span> /
                    <span>{{scope.row.returnPurchaseNum}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="130" fixed="right">
              <template v-slot="scope">
                <el-button size="small" type="danger" @click="btnDeleteInfo(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>

        </el-table>
    </div>
    
    <el-affix position="bottom">
          <el-row class="page_two">
              <el-col :span="24">
                  <!-- 分页 -->
                  <el-container class="page_right">
                      <el-pagination
                      v-model:currentPage="info.page"
                      v-model:page-size="info.size"
                      :page-sizes="[50, 200, 500, 1000]"
                      :background="true"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="info.total"
                      @current-change="btnPagechange"
                      @size-change="btnSizechange"
                      >
                      </el-pagination>
                  </el-container>
              </el-col>
          </el-row>        
    </el-affix>

    <!-- 重新统计-弹出框 -->
    <el-dialog title="重新统计"
    v-model="BoxStatSaler.isVisible"
    width="400px"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <BoxStatSale @closeBox="closeBoxStatSaler"></BoxStatSale>
    </el-dialog>

  </div>
</template>

<script>
import BoxStatSale from '@/views/Ams/Saler/BoxStatSaler.vue'  //重新统计 - 弹出框

import AllUserList from '@/utils/mixins/AllUserList.js'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    mixins:[AllUserList],
    components:{
        BoxStatSale
    },
    data(){
        return{
            dataList:[],
            info:{                
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    userNumber:'',
                    datePoint:'',
                }
            },
            //重新统计-弹出框
            BoxStatSaler:{
                isVisible:false,
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
    methods:{
        //加载数据
        GetDataList(){
            //记账时间
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            let param = {
                creator:this.info.search.userNumber,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.stat.PageSalerStatList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;  //获取数据
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.id)
        },

        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size;
            this.info.page = 1;
            this.GetDataList() //加载Table数据
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.userNumber=''; 
            this.info.search.datePoint = '';
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        },

        //删除按钮
        btnDeleteInfo(id){
            ElMessageBox.confirm('确定要删除该记录吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                let param = [id]
                this.$api.stat.DeleteStatSaler(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
                })
            }).catch(()=>{

            })

            
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            ElMessageBox.confirm('确定要删除选中的记录吗？',"删除提示",{
                confirmButtonText:'确认',
                cancelButtonText:'取消'
            }).then(()=>{
                this.$api.stat.DeleteStatSaler(this.info.ids).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                        this.GetDataList()
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                
            })
            
        },


        //开启-重新统计-弹出框
        openBoxStatSaler(){
            this.BoxStatSaler.isVisible = true;
        },
        //关闭-重新统计-弹出框
        closeBoxStatSaler(){
            this.BoxStatSaler.isVisible = false;
        },
    },
    mounted(){
        this.GetDataList();
    }

}
</script>

<style scoped>
.redPrice{color: red;}
.blueNum{color:dodgerblue;}
</style>