<template>
  <div>

    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="分组名">
                  <el-input v-model="info.search.groupName"></el-input>
                </el-form-item>

                <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
          </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
      <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
          <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                  <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                  </template>
                </el-popconfirm>
          </el-col>

          <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                        
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>
            
          </el-col>
        </el-row>

      <!-- 列表 -->
      <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="50" />  
        <el-table-column prop="groupDesc" label="分组说明" width="180" />
        <el-table-column prop="groupName" label="分组名"  />
        <el-table-column prop="typeName" label="数据名" />
        <el-table-column prop="typeValue" label="数据值" />
        <el-table-column prop="sort" label="排序" />
        <el-table-column prop="remark" label="备注" />
        <el-table-column label="操作" width="130" fixed="right">
          <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.id)">详情</el-button>
            <el-popconfirm 
              title="确认要删除吗?"
              @confirm="btnDeleteInfo(scope.row.id)">
                <template #reference>
                  <el-button size="small" type="danger">删除</el-button>
                </template>
              </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="40%"
      :draggable="true"
      :close-on-click-modal="false"
      @opened="openDialog">
        <EditDict :editId="info.id" ref="editInfo"></EditDict>
      <template #footer>
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="BoxEditInfo.isVisible = false">取消</el-button>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import EditDict from '@/views/Common/EditDict.vue'
export default {
    components:{
      EditDict
    },
    data(){
      return{
        info:{
          dataList:[],  //Table数据
          page:1,   //页数
          size:50,  //单页记录数
          total:0,  //总记录数
          id:0,  //新增、编辑 的参数
          ids:[],   //删除 的参数
          search:{  //搜索条件
            groupName:''
          }
        },
        //编辑-弹出框
        BoxEditInfo:{
          isVisible:false,
          title:'新增字典'
        }

      }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
    methods:{
      //加载数据列表
      GetDataList(){
        let param = {
          groupName:this.info.search.groupName,
          page:this.info.page,
          size:this.info.size
        }
        this.$api.common.PageDictList(param).then(res=>{
          if(res.data.status === 200){
            this.info.dataList = res.data.data;  //获取数据
            //分页
            this.info.total = res.data.total
            this.info.page = res.data.pageNumber
          }
        }).catch(e=>{
          console.log(e)
        })
      },
      //选中多选框
      btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.id)
      },
      //分页-页数变化时
      btnPagechange(page){
          this.info.page = page
          this.GetDataList() //加载Table数据
      },
      //分页-单页记录数变化时
      btnSizechange(size){
          this.info.size = size
          this.info.page = 1
          this.GetDataList() //加载Table数据
      },
      //打开弹出框：加载数据
      openDialog(){
        this.$refs.editInfo.GetDataInfo()
      },
      //新增编辑部门信息
      btnEditInfo(id){
        this.info.id = id
        if(id==0){
            this.BoxEditInfo.title = "新增字典"
        }else{
            this.BoxEditInfo.title = "编辑字典"
        }  
        this.BoxEditInfo.isVisible = true;
      },
      //删除按钮
      btnDeleteInfo(id){
        let param = [id]
        this.$api.common.DeleteDict(param).then(res=>{
          if(res.data.status === 200){
            ElMessage.success(res.data.msg)
            this.GetDataList()
          }
          else{
            ElMessage.error(res.data.msg)
          }
        })
      },
      //批量删除
      btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
          ElMessage.error('请选择要删除的选项')
          return false
        }

        this.$api.common.DeleteDict(this.info.ids).then(res=>{
          if(res.data.status === 200){
            ElMessage.success(res.data.msg)
            this.GetDataList()
          }
          else{
            ElMessage.error(res.data.msg)
          }
        })
      },
      //保存信息
      btnSaveData(){
        this.$api.common.SaveDict(this.$refs.editInfo.info).then(res=>{
          if(res.data.status === 200){
            ElMessage.success(res.data.msg);
            this.GetDataList()
            this.BoxEditInfo.isVisible = false;
          }
          else{
            ElMessage.error(res.data.msg)
          }
        }).catch(e=>{
          console.log(e)
        })
        
      },        
      //重置按钮
      btnResetSearch(){
        this.info.search.groupName='',
        this.GetDataList()
      },

    },
    mounted(){
      this.GetDataList()  //加载数据Table数据
    }
}
</script>

<style>

</style>