<template>
  <div>
    <!-- 摊销单 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">
            
            <el-form-item label="账户名称">
                <el-input v-model="info.search.name"></el-input>
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>           
                
            </el-col>
        </el-row>

        <!-- 数据列表 -->
        <el-table :data="info.dataList" border stripe show-summary :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="id" type="selection" width="60"></el-table-column>
            <el-table-column label="编号">
                <template v-slot="scope">
                    <span>{{scope.row.accountNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="accountName" label="会计科目"></el-table-column>
            <el-table-column label="开始日期">
                <template v-slot="scope">
                    <div class="cellOverFlow">{{DateFormat(scope.row.startDate)}}</div>
                </template>
            </el-table-column>
            <el-table-column label="结束日期">
                <template v-slot="scope">
                    <div class="cellOverFlow">{{DateFormat(scope.row.startDate)}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="accountName" label="摊销金额"></el-table-column>
            <el-table-column prop="accountName" label="摊销期数"></el-table-column>
            <el-table-column prop="accountName" label="累计已摊销金额"></el-table-column>
            <el-table-column prop="accountName" label="未摊销余额"></el-table-column>

            <el-table-column label="状态">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.statFlag == 0" type="primary">关闭</el-tag>
                    <el-tag v-if="scope.row.statFlag == 1" type="danger">开启</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="财务标识">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.statFlag == 0" type="primary">是</el-tag>
                    <el-tag v-if="scope.row.statFlag == 1" type="danger">否</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="排序">
                <template v-slot="scope">
                    <span>{{scope.row.sort}}</span>
                </template>
            </el-table-column>

            <el-table-column label="创建日期">
                <template v-slot="scope">
                    <div class="cellOverFlow">{{DateFormat(scope.row.startDate)}}</div>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="120">
              <template v-slot="scope">
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.accountNumber)">详情</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.accountNumber)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>

              </template>
            </el-table-column>
        </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="12">
                
            </el-col>
            <el-col :span="12">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[20, 50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    
  </div>
</template>

<script>
export default {
  data(){
    return{
      info:{
        dataList:[],  //Table数据
        page:1,
        size:20,
        total:0,  //总记录数
        id:0,  //新增、编辑 的参数
        ids:[],   //删除 的参数
        search:{
          name:''
        }
      },
      //编辑-弹出框
      BoxEditInfo:{
        isVisible:false,
        title:'摊销单详情'
      },
    }
  },
  computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
}
</script>

<style>

</style>