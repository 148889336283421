/**
 * 获取交易者列表(包含供应商+客户+员工) - 下拉列表
 * 全部数据
 */

const AllTradeList = {
    data(){
        return{
            allTradeList:[],  //交易者列表
        }
    },
    methods:{
        //加载交易者列表(包含供应商+客户+员工)
        SelectAllTradeList(){
            this.$api.erp.SelectAllTradeList().then(res=>{
                if(res.data.status === 200){
                    this.allTradeList = res.data.data;
                }else{
                    console.log(res.data.msg);
                }
            })
        },
    },
    mounted(){
        this.SelectAllTradeList();
    }
}

export default AllTradeList;