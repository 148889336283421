<template>
  <div>
    <!-- 单品价格走势图 -->

    <!-- 查询条件 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label="部门">
                    <el-tree-select v-model="search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="交易者类型">
                    <el-select v-model="search.tradeType" @change="ChangeTradeType" class="search-200">
                        <el-option label="全部" :value="0" />
                        <el-option label="供应商" :value="1" />
                        <el-option label="客户" :value="2" />
                        <el-option label="员工" :value="3" />
                    </el-select>
                </el-form-item>

                <el-form-item label="交易者">
                    <el-select v-model="search.tradeNumber" filterable clearable class="search-200">
                        <el-option v-for="trade in tradeList" :key="trade.tradeNumber" :label="trade.tradeName" :value="trade.tradeNumber" />
                    </el-select>
                </el-form-item>

                <el-form-item label="名称">
                    <el-input v-model="search.searchName" placeholder="品牌、分类、产品名称" class="search-200"></el-input>
                </el-form-item>

                <el-form-item label="月份">
                    <el-date-picker
                        v-model="search.currentMonth"
                        :editable="false"
                        :clearable="false"
                        type="month"
                        size="default"
                    />
                </el-form-item>
                
                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="LoadDataView">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 标题 -->
    <el-row>
        <el-col :span="24" class="title">
            <h3>单品价格走势图</h3>
        </el-col>        
    </el-row>


    <!-- 统计图 -->
    <div id="main" class="echarts" style="width:100%;height:500px;" ></div>

    <div class="dataSource">
        数据来源：采购单历史记录、销售单历史记录
    </div>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import CategoryList from '@/utils/mixins/CategoryList.js'
import * as echarts from 'echarts'  //Echarts 图标组件
import moment from 'moment'
import { ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList,CategoryList],
    data(){
        return{
            //查询条件
            search:{
                selectDepartmentList:[],
                tradeType:0,
                tradeNumber:'',  //交易者编号
                searchName:'',  //产品名称
                currentMonth:moment(new Date()).format("YYYY-MM"),   //月份
            },
            tradeList:[],    //交易者列表
            //返回实体
            info:{
                unitName:'斤',  //最小单位名称
                purchasePriceList:[],  //采购单价
                salePriceList:[],   //销售单价
                purchaseNumList:[],  //采购量
                saleNumList:[],    //销售量
            },
        }
    },
    methods:{
        //示例
        myEcharts(){
            var myChart = echarts.init(document.getElementById('main'));
            
            //配置图表
            var option = {
              tooltip: {
                trigger: 'axis',   //显示标签
              },
              legend:{
                top:'bottom',
                data:['采购价','销售价','采购量','销售量'] //示例图
              },
              xAxis: {
                type: 'category',
                data: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
              },
              yAxis: [
                    {
                        type: 'value',
                        name:'金额',
                        position: 'left',
                        axisLabel: {
                            formatter: '{value} 元'
                        }
                    },
                    {
                        type:'value',
                        name:'交易量',
                        position: 'right',
                        axisLabel: {
                            formatter: '{value} '+this.info.unitName
                        }
                    },
              ],
              series: [
                {
                  name:'采购价',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.purchasePriceList,
                  type: 'line',
                  smooth: true,   //平滑曲线
                  itemStyle:{
                    color:'#9F5F9F'
                  },
                  connectNulls:true,  //连上断开的点
                },
                {
                  name:'销售价',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.salePriceList,
                  type: 'line',  //折线图
                  smooth: true,   //平滑曲线
                  itemStyle:{
                    color:'#FF4500'
                  },
                  connectNulls:true,  //连上断开的点
                },

                {
                  name:'采购量',
                  yAxisIndex: 1,  //用的是右侧Y坐标
                  data: this.info.purchaseNumList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#33ccff'
                  }
                },
                {
                  name:'销售量',
                  yAxisIndex: 1,  //用的是右侧Y坐标
                  data: this.info.saleNumList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#DC143C'
                  }
                },
                
              ]
            };
            myChart.setOption(option);
        },

        //加载数据
        async LoadDataView(){
            //年月
            let strMonth = moment(this.search.currentMonth).format("YYYY-MM")
            var dateArr = strMonth.split('-');
            //获取、转换部门
            let newDepartmentList = []
            if(this.search.selectDepartmentList!=null){
                newDepartmentList = this.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                searchName:this.search.searchName,
                tradeType:this.search.tradeType,
                tradeNumber:this.search.tradeNumber,
                year:dateArr[0],
                month:dateArr[1],
            }
          await this.$api.stat.GetHistoryPriceStat(param).then(res=>{
            if(res.data.status === 200){
              this.info = res.data.data;
              this.myEcharts();
            }else{
                ElMessage.error(res.data.msg);
            }
          })
        },

        //重置查询条件
        btnResetSearch(){
            this.search = {
                selectDepartmentList:[],
                tradeType:0,
                tradeNumber:'',  //交易者编号
                searchName:'',  //名称
                currentMonth:moment(new Date()).format("YYYY-MM"),   //月份
            };

            this.info = {
                unitName:'斤',  //最小单位名称
                purchasePriceList:[],  //采购单价
                salePriceList:[],   //销售单价
                purchaseNumList:[],  //采购量
                saleNumList:[],    //销售量
            };
            this.myEcharts();
        },

        //查询条件：交易者类型
        ChangeTradeType(){
            this.SelectTradeList();  //获取交易者
        },

        //查询条件：获取交易者列表
        SelectTradeList(){
            let param = {
                tradeType:this.search.tradeType
            }
            this.$api.erp.SelectTradeList(param).then(res=>{
                if(res.data.status === 200){
                    this.tradeList = res.data.data;
                }else{
                    this.tradeList = [];
                }
            })     
        },

    },
    mounted(){
        this.ChangeTradeType();
        this.myEcharts();
    }
}
</script>

<style scoped>
.title{text-align: center;}
.dataSource{margin-left: 140px;font-size: 14px;color: #ccc;}
</style>