/** 
 * api接口的统一出口
 */


// 登录模块接口
import login from '@/api/modules/login';
import system from '@/api/modules/system';
import common from '@/api/modules/common';
import goods from '@/api/modules/goods';
import supplier from '@/api/modules/supplier';
import inquiry from '@/api/modules/inquiry';
import wms from '@/api/modules/wms';
import service from '@/api/modules/service';
import erp from '@/api/modules/erp';
import oa from '@/api/modules/oa';
import finance from '@/api/modules/finance';
import shop from '@/api/modules/shop';
import member from '@/api/modules/member';
import trace from '@/api/modules/trace';
import hr from '@/api/modules/hr';
import market from '@/api/modules/market';
import stat from '@/api/modules/stat';   //统计
import library from '@/api/modules/library';  //资源

// 导出接口
export default {    
    login,
    system,
    common,
    goods,
    supplier,
    inquiry,
    wms,
    service,
    erp,
    oa,
    finance,
    shop,
    member,
    trace,
    hr,
    market,
    stat,
    library
    // ……
}
