<template>
  <div class="editInfo">
    <!-- 非现金财务单详情页 -->
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="editTab">

        <el-tab-pane label="财务单" name="tabInfo">
            <TabFinance></TabFinance>

            <!-- 非现金财务单明细 -->
            <TabOrigin></TabOrigin>       

        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabFinanceRecord></TabFinanceRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabFinanceState></TabFinanceState>
        </el-tab-pane>

    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter">
        <el-button @click="btnClose">关闭</el-button>


        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>

import TabOrigin from '@/views/Fms/NoCash/TabOrigin.vue'
import { mapMutations, mapState } from 'vuex'
export default {
    props:['editId','dataList'],    
    components:{
        TabOrigin,
    },
    data(){
        return{
            info:{
                activeName:'tabInfo',
                billNumber:this.editId,  //单号 
            },
        }
    },
    computed:{
        ...mapState('Finance',['noCashInfo'])   //无现金财务单信息
    },
    methods:{
        ...mapMutations('Finance',['SetNoCashInfo']),

        //加载数据
        GetDataInfo(){
            // if(this.info.billNumber !=0){

            // }else{

            // }
        },

        //清空数据
        btnClearData(){
            var newInfo = {
                info:{
                    billNumber:'',
                    departmentId:11,
                    subId:0,
                    tradeNumber:'',
                    tradeType:1,
                    accountNumber:'',
                    totalPrice:0,
                    state:1,
                    receiver:'',
                    receiverName:'',
                    remark:''
                },
                originList:[],  //原始单据列表
                recordList:[],
                stateList:[],
            }
            this.SetNoCashInfo(newInfo);
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },


    }
}
</script>

<style>

</style>