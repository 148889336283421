<template>
  <div>
    <el-divider></el-divider>

    <el-tabs v-model="info.activeName">
      <el-tab-pane label="对账单" name="tabInfo">
        <TabCycle ref="tabCycle"></TabCycle>

        <el-divider>财务单列表</el-divider>
        <TabFinanceList></TabFinanceList>
      </el-tab-pane>

      <el-tab-pane label="发票信息" name="tabInvoice">
        <el-divider class="tabInvoice">我方发票信息</el-divider>
        <TabOurInvoice></TabOurInvoice>

        <el-divider class="tabInvoice">交易方发票信息</el-divider>
        <TabTradeInvoice></TabTradeInvoice>
      </el-tab-pane>

      <el-tab-pane label="回执单" name="tabOther">
        <TabReceipt></TabReceipt>
      </el-tab-pane>

      <el-tab-pane label="备注记录" name="tabRecord">
        <TabCycleRecord></TabCycleRecord>
      </el-tab-pane>

      <el-tab-pane label="状态记录" name="tabState">
        <TabCycleState></TabCycleState>
      </el-tab-pane>

    </el-tabs>


    <!-- 功能按钮组 -->
    <div class="btnGroup" v-if="cycleInfo.info">
        <el-button type="primary" v-if="CheckUserButtonAuth('BtnCycleSave')" @click="btnSaveData">保存</el-button>
        <el-button type="warning" @click="btnDownLoadCycle">下载对账涵</el-button>
        <el-button class="iconfont icon-dayin" @click="btnShowBoxPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 打印框 -->
    <el-dialog title="打印预览"
    v-model="BoxPrint.isVisible"
    width="70%">
        <PrintView @closeBox="btnClosePrint"></PrintView>
    </el-dialog>


  </div>
</template>

<script>
import TabCycle from '@/views/Fms/Cycle/TabCycleInfo.vue'
import TabOurInvoice from '@/views/Fms/Cycle/TabOurInvoice.vue'  //我方发票信息
import TabTradeInvoice from '@/views/Fms/Cycle/TabTradeInvoice.vue'  //对方发票信息
import TabReceipt from '@/views/Fms/Cycle/TabReceipt.vue'  //回执单
import TabFinanceList from '@/views/Fms/Cycle/TabFinanceList.vue'  //财务单列表
import TabCycleRecord from '@/views/Fms/Cycle/TabCycleRecord.vue'  //备注记录
import TabCycleState from '@/views/Fms/Cycle/TabCycleState.vue'  //状态记录

import PrintView from '@/views/Fms/Cycle/PrintView.vue'

import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
import { ElMessage } from 'element-plus'

export default {
  props:['editId','dataList'],
  components:{
    TabCycle,
    TabFinanceList,
    TabOurInvoice,
    TabTradeInvoice,
    TabReceipt,
    TabCycleRecord,
    TabCycleState,
    PrintView,
  },
  data(){
    return{
      info:{
        activeName:'tabInfo',
        cycleNumber:this.editId,
      },
      //打印框
      BoxPrint:{
          isVisible:false,
      },
      
    }
  },
  computed:{
    ...mapState('Finance',['cycleInfo'])
  },
  methods:{
    ...mapMutations('Finance',['SetCycleInfo']),

    //加载数据信息
    GetDataInfo(){
      if(this.info.cycleNumber!=0){
        let param = {
          cycleNumber:this.info.cycleNumber
        }
        this.$api.finance.GetCycleAllInfo(param).then(res=>{
          if(res.data.status === 200){
            this.SetCycleInfo(res.data.data)
            console.log(res.data.data)
            //加载完数据后，调取交易者列表
            this.$refs.tabCycle.SelectTradeList();
          }
        })
      }
      else{
        this.btnClearData();
      }
    },

    //保存 - 对账单
    btnSaveData(){
      
      this.$api.finance.SaveCycleAllInfo(this.cycleInfo).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          this.btnClose()
        }else{
          ElMessage.error(res.data.msg);
        }
      }).catch(e=>{
        console.log(e)
      })
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

    //下载对账函
    btnDownLoadCycle(){
      ElMessage.warning('功能开发中');
    },

    //上一条
    btnPrevious(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.cycleNumber === this.info.cycleNumber
        })
        let prevIndex = currentIndex -1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有上一条了");
            return false;
        }
        else{
            this.info.cycleNumber = prevInfo.cycleNumber
            this.GetDataInfo()
        }
        
    },
    //下一条
    btnNext(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.cycleNumber === this.info.cycleNumber
        })
        let prevIndex = currentIndex + 1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有下一条了");
            return false;
        }
        else{
            this.info.cycleNumber = prevInfo.cycleNumber
            this.GetDataInfo()
        }
    },

    //清空数据
    btnClearData(){
      let newInfo = {
        info:{
          systemNumber:'',
          cycleNumber:'',
          title:'',
          tradeNumber:'',
          tradeType:1,
          tradeName:'',
          startDate:moment(new Date()).format("YYYY-MM-DD"),
          endDate:moment(new Date()).format("YYYY-MM-DD"),
          totalPrice:0,
          queueTime:'',
          payTime:'',
          isFit:0,
          creator:'',
          isDel:0
        },
        originList:[],
        ourInvoiceList:[],
        tradeInvoiceList:[],
        recordList:[],
        financeList:[],
      }
      this.SetCycleInfo(newInfo);
    },

    //打开打印框
    btnShowBoxPrint(){
        this.BoxPrint.isVisible =true;
    },
    //关闭打印框
    btnClosePrint(){
      this.BoxPrint.isVisible =false;
    },
    //导出明细到Excel
    btnExportDetail(){
      let param = {
        cycleNumber:this.info.cycleNumber
      }
      this.$api.erp.ExportCycleDetail(param).then(res=>{
        if(res.data.status === 200){
            const alink = document.createElement('a');
            alink.href = res.data.data;
            document.body.appendChild(alink);
            alink.click();
            document.body.removeChild(alink)
        }
        else
        {
            ElMessage.error(res.data.msg)
        }
      }).catch(e=>{
          console.log(e)
      })
    }


  },
  mounted(){
    this.GetDataInfo() 
  }
}
</script>

<style scoped>
.tabInvoice{margin-top: 15px;}
</style>