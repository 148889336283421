/**
 * 会员相关接口
 */

 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const member = {

    //#region 会员信息

    //列表
    ListMember(param){
        return axios.post(`${base.base}/Member/ListMember`,param)
    },
    
    //获取单个会员信息(会员信息+地址信息+充值记录)
    GetMemberAllInfo(param){
        return axios.get(`${base.base}/Member/SingleMemberAllInfo`,{
            params:param
        })
    },

    //#endregion

    //#region 收货地址

    //列表
    ListMemberAddress(param){
        return axios.get(`${base.base}/Member/ListMemberAddress`,{
            params:param
        })
    },

    //获取单个信息
    SingleMemberAddress(param){
        return axios.get(`${base.base}/Member/SingleMemberAddress`,{
            params:param
        })
    },

    //保存
    SaveMemberAddress(param){
        return axios.post(`${base.base}/Member/SaveMemberAddress`,param)
    },

    //删除
    DeleteMemberAddress(param){
        return axios({
            url:`${base.base}/Member/DeleteMemberAddress`,
            method:'post',
            params:param
        })
    },

    //#endregion

    //#region 会员标签

    //列表
    ListMemberTag(param){
        return axios.post(`${base.base}/MemberTag/ListMemberTag`,param)
    },

    //单个信息
    SingleMemberTag(param){
        return axios.get(`${base.base}/MemberTag/SingleMemberTag`,{
            params:param
        })
    },

    //保存
    SaveMemberTag(param){
        return axios.post(`${base.base}/MemberTag/SaveMemberTag`,param)
    },

    //删除
    DeleteMemberTag(param){
        return axios.post(`${base.base}/MemberTag/DeleteMemberTag`,param)
    },

    //#endregion

    //#region 会员关联标签

    //保存会员关联的标签
    SaveRelationMemberTag(param){
        return axios.post(`${base.base}/Member/SaveMemberTag`,param)
    },

    //删除会员关联的标签
    DeleteRelationMemberTag(param){
        return axios({
            url:`${base.base}/Member/DeleteMemberTag`,
            method:'post',
            params:param
        })
    },


    //#endregion

    //#region 会员积分

    //列表
    PageMemberScoreList(param){
        return axios.post(`${base.base}/MemberScore/PageMemberScoreList`,param)
    },

    //保存
    SaveMemberScore(param){
        return axios.post(`${base.base}/MemberScore/SaveMemberScore`,param)
    },

    //删除
    DeleteMemberScore(param){
        return axios.post(`${base.base}/MemberScore/DeleteMemberScore`,param)
    },

    //#endregion

    //#region 会员等级
    
    //#endregion


 }

 export default member
