<template>
  <div>
      <!-- 主动询价编辑页 -->
      <el-divider></el-divider>
      <!-- Tab标签 -->
      <el-tabs v-model="info.activeName">

          <el-tab-pane label="询价单" name="TabInquiry">
              <TabSingleInquiry></TabSingleInquiry>

              <el-divider>询价单明细</el-divider>
              <TabSingleInquiryDetail></TabSingleInquiryDetail>
          </el-tab-pane>

          <!-- <el-tab-pane label="询价单明细" name="TabSingleInquiryDetail">
              <TabSingleInquiryDetail></TabSingleInquiryDetail>
          </el-tab-pane> -->

          <el-tab-pane label="指定供应商" name="TabSingleInquirySupplier">
              <TabSingleInquirySupplier></TabSingleInquirySupplier>
          </el-tab-pane>

          <el-tab-pane label="询价结果" name="TabSingleInquiryResult">
              <TabSingleInquiryResult></TabSingleInquiryResult>
          </el-tab-pane>

      </el-tabs>

      <!-- 功能按钮组 -->
      <div class="btnGroup">
          <el-button type="primary" @click="btnSaveData">保存</el-button>
          <el-button @click="btnClose">关闭</el-button>
      </div>
  </div>
</template>

<script>
import TabSingleInquiry from '@/views/Pms/Inquiry/TabSingleInquiry.vue'
import TabSingleInquiryDetail from '@/views/Pms/Inquiry/TabSingleInquiryDetail.vue'
import TabSingleInquirySupplier from '@/views/Pms/Inquiry/TabSingleInquirySupplier.vue'
import TabSingleInquiryResult from '@/views/Pms/Inquiry/TabSingleInquiryResult.vue'
import { mapMutations, mapState } from 'vuex'
import moment from "moment";
import { ElMessage } from 'element-plus'

export default {
    name:'EditInquiry',
    props:['billNumber'],
    components:{
        TabSingleInquiry,
        TabSingleInquiryDetail,
        TabSingleInquirySupplier,
        TabSingleInquiryResult
    },
    data(){
        return{
            info:{
                activeName:'TabInquiry'
            }
        }
    },
    computed:{
      ...mapState('Purchase',['inquiryInfo']),   //询价单所有信息
    },
    methods:{
        ...mapMutations('Purchase',['SetInquiryInfo']),
        //加载数据信息
        GetDataInfo(){
            if(this.billNumber !=0){
                let param = {
                    billNumber:this.billNumber
                }
                this.$api.inquiry.SingleInquiryAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetInquiryInfo(res.data.data)

                    }else{
                        console.log(res.data.msg)
                    }
                })
            }
            else
            {
                let newInfo = {
                    info:{
                        id:0,
                        systemNumber:'',
                        billNumber:'',
                        departmentId:0,
                        inquiryType:1,
                        state:1,
                        createTime:moment(new Date()).format("YYYY-MM-DD"),
                        endTime:moment(new Date()).format("YYYY-MM-DD"),
                        remark:'',
                        creator:'',
                        isDel:0
                    },
                    detailList:[],
                    supplierList:[],
                    resultList:[],
                }
                this.SetInquiryInfo(newInfo)
            }
        },

        //保存-询价单信息
        btnSaveData(){
            this.$api.inquiry.SaveInquiryAllInfo(this.inquiryInfo).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg);
                this.btnClose()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            }).catch(e=>{
                console.log(e.message)
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        }

    },
    mounted(){
        this.GetDataInfo()  //加载单个询价单所有信息
    }

}
</script>

<style>
</style>