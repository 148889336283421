<template>
  <div>

    <!-- 按钮 -->
    <div class="skuBtnList" v-if="workInfo.info">
      <el-button class="iconfont icon-add" type="primary" plain v-if="workInfo.info.state == 5" @click="btnOpentWorkWaste">选择退料</el-button>
    </div>

    <!-- 退料列表 -->
    <el-table :data="workInfo.wasteList" border stripe show-summary style="width: 100%">
      <el-table-column prop="warehouseName" label="仓库">
        <template v-slot="scope">
          <el-select v-if="scope.row.isEdit==1" v-model="scope.row.warehouseNumber">
            <el-option v-for="warehouse in areaWhList" :key="warehouse.warehouseNumber" :label="warehouse.warehouseName" :value="warehouse.warehouseNumber"></el-option>
          </el-select>
          <span v-else >{{scope.row.warehouseName}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="cidName" label="产品类目"></el-table-column>
      <el-table-column prop="brandName" label="品牌"></el-table-column>
      <el-table-column prop="spuName" label="产品名称" />
      <el-table-column prop="skuName" label="规格" />
      <el-table-column label="单位">
          <template v-slot="scope">
              <el-select v-if="scope.row.isEdit==1" v-model="scope.row.unitId"  size="small" @change="ChangeSkuUnit">
                  <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
              </el-select>
              <span v-else>{{scope.row.unitName}}</span>
          </template>
      </el-table-column>

      <el-table-column prop="unitPrice" label="单价" min-width="115">
        <template v-slot="scope">
            <span>{{this.myRound(scope.row.unitPrice,2)}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="planNum" label="计划退料数量" min-width="115">
        <template v-slot="scope">
            <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.planNum" :min="0" :max="scope.row.maxNum" :precision="2" size="small"></el-input-number>
            <span v-else >{{scope.row.planNum}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="realNum" label="实际入库数量" min-width="115">
        <template v-slot="scope">
            <span>{{scope.row.realNum}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="totalPrice" label="实际总价(元)" width="110">
          <template v-slot="scope">
              <span>{{this.myRound(scope.row.totalPrice,2)}}</span>
          </template>
      </el-table-column>
     

      <el-table-column label="操作" width="130" fixed="right">
        <template v-slot="scope" >
            <el-button v-if="scope.row.isEdit == 0 && (workInfo.info.state ==5)" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
            <el-popconfirm v-if="workInfo.info.state ==5"
            title="确认要删除吗?"
            @confirm="DeleteRow(scope.row.workNumber,scope.row.id,scope.$index)">
                <template #reference>
                <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
        </template>
      </el-table-column>

    </el-table>

    <!-- 选择退料 -->
    <el-dialog title="选择退料" 
        v-model="BoxWaste.isVisible"
        width="70%"
        :destroy-on-close="true">
          <BoxWorkWaste ref="WasteInfo"></BoxWorkWaste>
        <template #footer>
            <el-button type="primary" @click="btnSelectWorkWaste">选择</el-button>
            <el-button @click="BoxWaste.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import BoxWorkWaste from '@/views/Work/WorkBill/BoxWorkWaste.vue'
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    components:{
      BoxWorkWaste
    },
    data(){
        return{
            areaWhList:[],   //大区关联的仓库列表
            //选择退料-弹出框
            BoxWaste:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Erp',['workInfo'])
    },
    methods:{

      //打开选择退料表
      btnOpentWorkWaste(){
        if(this.workInfo.info.workNumber ==''){
            ElMessage.error('此加工单没有可选的退料');
            return false;
        }
        this.BoxWaste.isVisible = true;
      },

      //保存选中的退料
      btnSelectWorkWaste(){
        let selectWasteList = this.$refs.WasteInfo.selectedList;
        if(selectWasteList !=null && selectWasteList.length>0){
          selectWasteList.forEach(item => {

            let newRow = {
              id:0,
              systemNumber:'',
              workNumber:'',
              warehouseNumber:item.warehouseNumber,
              warehouseName:item.warehouseName,
              cid:item.cid,
              cidName:item.cidName,
              brandId:item.brandId,
              brandName:item.brandName,
              spuNumber:item.spuNumber,
              spuName:item.spuName,
              skuNumber:item.skuNumber,
              skuName:item.skuName,
              unitId:item.unitId,   //默认单位编号
              unitName:item.unitName,  //默认单位名称
              unitPrice:item.unitPrice,
              planNum:item.realNum,   //计划数量
              realNum:0,   //实际数量
              maxNum:item.realNum,   //最大数量
              totalPrice:0,  //实际总价
              workType:1,
              isDel:0,
              isEdit:1,   //编辑状态
              skuUnitList:item.skuUnitList,   //单位列表
            }
            
            this.workInfo.wasteList.push(newRow);
          });
        }

        //加载仓库列表 参数：2表示保留已选择了仓库的记录的值。
        this.onGetWhListByAreaNumber(2); 
        this.BoxWaste.isVisible = false;
      },

      //编辑行
      EditRow(id){
        this.workInfo.wasteList = this.workInfo.wasteList.map(item=>{
          if(item.id === id){
            item.isEdit = 1;
          }
          return item;
        })
        
        //加载仓库列表 参数：2表示保留已选择了仓库的记录的值。
        this.onGetWhListByAreaNumber(2); 
      },

      //删除行
      DeleteRow(workNumber,id,index){
        this.workInfo.wasteList.splice(index,1)
        if(id !=0){
          let param = {
            workNumber:workNumber,
            id:id
          }
          this.$api.erp.DeleteWorkWasteById(param).then(res=>{
            if(res.data.status === 200){
              ElMessage.success(res.data.msg);
            }else{
              ElMessage.error(res.data.msg);
            }
          })
        }
      },
      //当单位发生变化时
      ChangeSkuUnit(){
          this.workInfo.wasteList = this.workInfo.wasteList.map(item=>{
              //#region 单位发生变化时-重新计算价格、库存
              if(item.isEdit === 1){
                let param3 = {
                    warehouseNumber:item.warehouseNumber,
                    skuNumber:item.skuNumber,
                    unitId:item.unitId
                }
                this.$api.wms.GetSkuUnitStockInfo(param3).then(res=>{
                    if(res.data.status === 200){
                        item.ckPrice = res.data.data.unitPrice;  //成本单价
                        item.unitPrice = res.data.data.unitPrice; //单价(新加的)
                        item.maxNum = res.data.data.unitStock;  //库存
                        if(item.realNum > item.maxNum){
                            item.realNum = item.maxNum;  //如果超出库存，就修改数量
                        }
                    }
                })
              }            
              //#endregion
              return item
          })
      },

      //加载指定大区下的仓库列表 type: 1:清空仓库选项 2:保留仓库选项
      onGetWhListByAreaNumber(type){ 
          if(this.workInfo.info.areaNumber!=''){
              let param = {
                  areaNumber:this.workInfo.info.areaNumber,
              }
              this.$api.wms.GetWhListByAreaNumber(param).then(res=>{
                  if(res.data.status === 200){
                      this.areaWhList = res.data.data;
                  }else{
                      this.areaWhList = [];
                  }
              })
          }else{
              this.areaWhList = [];  
          }  

          //清空仓库选项
          if(type==1){
              this.workInfo.goodsList = this.workInfo.goodsList.map(item=>{
                  item.warehouseNumber = '';
                  return item
              })
          }
      },

    }
}
</script>

<style>

</style>