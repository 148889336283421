<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="editTab">

        <el-tab-pane label="财务单" name="tabInfo">
            <TabFinance></TabFinance>

            <!-- 明细Tab页 -->
            <el-tabs v-model="info.DetailName">
              <el-tab-pane label="财务明细" name="tabDetail">
                  <TabOrigin></TabOrigin>
              </el-tab-pane>
              <el-tab-pane label="抵扣明细" name="tabDebt">
                  <TabDebt></TabDebt>
              </el-tab-pane>
              <el-tab-pane label="差额明细" name="tabBalance">
                  <TabBalance></TabBalance>
              </el-tab-pane>
            </el-tabs>        

        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabFinanceRecord></TabFinanceRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabFinanceState></TabFinanceState>
        </el-tab-pane>

    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="financeInfo.info">
        <el-button type="primary" v-if="financeInfo.info.state==4" @click="BtnBatchSaveRecord">保存备注</el-button>
        <el-button type="danger" v-if="CheckUserButtonAuth('BtnFinanceCancel') && financeInfo.info.state <= 4 && financeInfo.info.payState ==0" @click="btnCancelBill()">撤单</el-button>
        <el-button type="primary" v-if="(financeInfo.info.state ==1 || financeInfo.info.state ==2) && financeInfo.info.payState==0" @click="btnSaveFinanceAllInfo2">保存</el-button>
        <el-button v-if="financeInfo.info.state == 1 || financeInfo.info.state == 2 || financeInfo.info.state == 3" type="warning" @click="BoxCheckState.isVisible = true">审核</el-button>
        <el-button type="success" v-if="CheckUserButtonAuth('BtnFinanceConfirmSettle') && financeInfo.info.state == 4 && financeInfo.info.payState==0" @click="OpenPayment">去结款</el-button>
        <el-button type="danger" v-if="financeInfo.info.payState==1" @click="btnRefundFinance()">退款</el-button>
        <el-button class="iconfont icon-dayin" v-if="financeInfo.info.state==5" @click="btnShowBoxPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 审核-弹框 -->
    <el-dialog title="财务审核"
    v-model="BoxCheckState.isVisible"
    width="500px">
        <el-divider></el-divider>
        
        <el-descriptions border :column="1">
            <el-descriptions-item label="下步审核人">
                <el-select v-model="BoxCheckState.checkerNumber" @change="ChangeCheckerName" class="search-200">
                    <el-option v-for="checker in BoxCheckState.checkerList" :key="checker.tradeNumber" :label="checker.tradeName" :value="checker.tradeNumber"></el-option>
                </el-select>
            </el-descriptions-item>

            <el-descriptions-item label="审核说明">
                <el-input v-model="BoxCheckState.remark" :maxlength="50" class="search-200"></el-input>
            </el-descriptions-item>
        </el-descriptions>

        <template #footer>            
            <el-button type="danger" @click="UpdateFinanceState(2)">驳回</el-button>
            <el-button v-if="CheckUserButtonAuth('BtnFinanceAuditReport')" type="primary" @click="UpdateFinanceState(3)">审核通过并上报</el-button>
            <el-button v-if="CheckUserButtonAuth('BtnFinancetAuditComplate')" type="success" @click="UpdateFinanceState(4)">审核完成</el-button>   
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

    <!--结款操作-弹框 -->
    <el-dialog :title="BoxSettle.title"
    v-model="BoxSettle.isVisible"
    width="500px"
    :destroy-on-close="true"
    @closed="GetDataInfo">
        <BoxPayment @closeBox="ClosePayment"></BoxPayment>
    </el-dialog>

    <!-- 打印框 -->
    <el-dialog title="打印预览"
    v-model="BoxPrint.isVisible"
    :draggable="true"
    :close-on-click-modal="false"
    width="80%">
        <PrintViewSingle v-if="BoxPrint.isVisible" @closeBox="btnClosePrint"></PrintViewSingle>
    </el-dialog>

  </div>
</template>

<script>
import BoxPayment from '@/views/Fms/Finance/BoxPayment.vue'
import TabFinance from '@/views/Fms/Finance/TabFinance.vue'
import TabOrigin from '@/views/Fms/Finance/TabOrigin.vue'
import TabFinanceRecord from '@/views/Fms/Finance/TabFinanceRecord.vue'
import TabFinanceState from '@/views/Fms/Finance/TabFinanceState.vue'
import TabBalance from '@/views/Fms/Finance/TabBalance.vue'
import TabDebt from '@/views/Fms/Finance/TabDebt.vue'
import PrintViewSingle from '@/views/Fms/Finance/PrintViewSingle.vue'

import { mapMutations, mapState } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'
import accountList from '@/utils/mixins/AccountList.js'  //账户-下拉列表
import moment from 'moment'
export default {
  components:{
    TabFinance,
    TabOrigin,
    TabFinanceRecord,
    TabFinanceState,
    BoxPayment,
    TabBalance,   //差额项
    TabDebt,    //抵扣项
    PrintViewSingle,
  },
  mixins:[accountList],
  props:['editId','dataList'],
  data(){
    return{
      info:{
        activeName:'tabInfo',
        DetailName:'tabDetail',  //默认显示财务明细页
        billNumber:this.editId,  //当前记录编号
        accountNumber:'',   //选择结款账户
        payTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),  //结款时间
      },
      //审核-弹出框
      BoxCheckState:{
          isVisible:false,
          //审核内容
          checkerList:[],   //审核人列表
          checkerNumber:'',   //选中审核人(编号)
          checkerName:'',   //选中审核人(中文)
          remark:'',     //审核说明
      },      
      //结款操作-弹出框
      BoxSettle:{
          isVisible:false,
          title:'确认结款'
      },
      //打印框
      BoxPrint:{
          isVisible:false,
      },

    }
  },
  computed:{
    ...mapState('Erp',['financeInfo'])   //财务单信息
  },
  methods:{
    ...mapMutations('Erp',['SetFinanceInfo']),

    //加载数据
    GetDataInfo(){
      if(this.info.billNumber !=0){
        let param = {
          billNumber:this.info.billNumber
        }
        this.$api.finance.SingleFinanceAllInfo(param).then(res=>{
          if(res.data.status === 200){
            this.SetFinanceInfo(res.data.data);
          }
          else{
            this.btnClearData();
          }
        })
      }
      else{
        this.btnClearData();
      }
    },

    //更新状态
    UpdateFinanceState(state){
      let param = {
        billNumber:this.financeInfo.info.billNumber,
        state:state,
        accountNumber:this.info.accountNumber,   //结款账户
        payTime:this.info.payTime,      //结款时间
        receiver:this.BoxCheckState.checkerNumber,  //审核人
        receiverName:this.BoxCheckState.checkerName,  //审核人(中文)
        remark:this.BoxCheckState.remark,
      }

      if(state==5){
        if(param.accountNumber == null || param.accountNumber ==''){
          ElMessage.error('请选择结款账户')
          return false;
        }

        if(param.payTime == null){
          ElMessage.error('请选择用款时间'); 
        }
      }

      this.$api.finance.UpdateFinanceState(param).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          //
          this.GetDataInfo();
          this.BoxCheckState.isVisible = false;  //先关闭审核窗口
          this.BoxSettle.isVisible = false;   //关闭结款操作窗口
          
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

    //上一条
    btnPrevious(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.billNumber === this.info.billNumber
        })
        let prevIndex = currentIndex -1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有上一条了");
            return false;
        }
        else{
            this.info.billNumber = prevInfo.billNumber
            this.GetDataInfo()
        }
        
    },
    //下一条
    btnNext(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.billNumber === this.info.billNumber
        })
        let prevIndex = currentIndex + 1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有下一条了");
            return false;
        }
        else{
            this.info.billNumber = prevInfo.billNumber
            this.GetDataInfo()
        }
    },

    //清空数据
    btnClearData(){
      var newInfo = {
            info:{
              billNumber:'',
              departmentId:11,
              subId:0,
              tradeNumber:'',
              tradeType:1,
              accountNumber:'',
              totalPrice:0,
              state:1,
              payState:0,
              receiver:'',
              receiverName:'',
              remark:''
            },
            originList:[],  //原始单据列表
            debtList:[],   //抵扣项列表
            balanceList:[],  //差额项列表
            recordList:[],
            stateList:[],
        }
        this.SetFinanceInfo(newInfo);
    },

    //获取审核人列表
    SelectCheckerList(){
        let param = {
            tradeType:3  //固定：获取所有员工列表
        }
        this.$api.erp.SelectTradeList(param).then(res=>{
            if(res.data.status === 200){
                this.BoxCheckState.checkerList = res.data.data;
            }else{
                this.BoxCheckState.checkerList = [];
            }
        }) 
    },

    //审核人改变时：更新审核人(中文)字段
    ChangeCheckerName(option){
        if(option !=""){
            let checkInfo = this.BoxCheckState.checkerList.find(item=>{
                return item.tradeNumber === option
            })
            this.BoxCheckState.checkerName = checkInfo.tradeName;
            this.costInfo.info.receiver = option;  //同步更新实体，只是为了同步
        }else{
            this.BoxCheckState.checkerName = "";
        }
    },

    //撤单
    btnCancelBill(){
      ElMessageBox.confirm(
        '撤单将删除当前财务单，原始单据需要重新提交生成财务单。确认是否撤单？',
        '撤单警告',
        {
          confirmButtonText:'确认',
          cancelButtonText:'取消',
          type:'warning'
        }
      ).then(()=>{
        //确认
        let param = {
          billNumber:this.financeInfo.info.billNumber
        }
        this.$api.finance.CancelFinance(param).then(res=>{
          if(res.data.status === 200){
            ElMessage.success(res.data.msg);
            this.btnClose();
          }else{
            ElMessage.error(res.data.msg);
          }
        })        
        
      }).catch(()=>{
        
      })
      
    },

    //打开付款-弹出框
    OpenPayment(){
      this.BoxSettle.isVisible = true;
    },
    //关闭付款-弹出框
    ClosePayment(){
      this.BoxSettle.isVisible = false;
    },
    
    //打开-打印框
    btnShowBoxPrint(){
        this.BoxPrint.isVisible =true;
    },
    //关闭-打印框
    btnClosePrint(){
        this.BoxPrint.isVisible =false;        
    },

    //退款
    btnRefundFinance(){
      ElMessageBox.confirm('确认要退款吗','退款提示',{
          confirmButtonText:'确认',
          cancelButtonText:'取消',
          type:'warning'
      }).then(()=>{
        let param = {
          billNumber:this.financeInfo.info.billNumber
        }
        this.$api.finance.RefundFinance(param).then(res=>{
          if(res.data.status === 200){
            ElMessage.success(res.data.msg);
            this.GetDataInfo();
          }else{
            ElMessage.error(res.data.msg);
          }
        })
      }).catch(()=>{
        //取消操作 
      })
      
    },

    //保存财务单(只编辑)
    btnSaveFinanceAllInfo2(){
      this.$api.finance.SaveFinanceAllInfo2(this.financeInfo).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          //刷新
          this.GetDataInfo();
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //保存财务单-差额项(作废)
    btnSaveFinanceBalance(){
      let param ={
        billNumber:this.financeInfo.info.billNumber,
        balanceList:this.financeInfo.balanceList
      }
      this.$api.finance.SaveFinanceBalance(param).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          //更新实际支付价格
          this.financeInfo.info.totalPrice = this.myRound(res.data.data,2);
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },


    //保存备注
    BtnBatchSaveRecord(){
      this.$api.erp.BatchSaveRecord(this.financeInfo.recordList).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          //刷新
          this.GetDataInfo();
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

  },
  mounted(){
    this.GetDataInfo();
    this.SelectCheckerList();   //审核人列表
  }
}
</script>

<style scoped>
.SingleInfo {min-height: 200px;}
.LineSpace{margin-top: 20px;}
</style>