<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
      v-model="info"
      label-width="80px"
      label-position="right">
          <el-row v-if="info">

              <el-col :sm="12" :xs="24">
                  <el-form-item label="归属地区">
                      <el-tree-select v-model="info.pid" :data="areaList" check-strictly :default-expand-all="true" class="search-200"/>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="层级">
                      <el-select  v-model="info.deep" class="search-200">
                        <el-option label="省" :value="0"></el-option>
                        <el-option label="市" :value="1"></el-option>
                        <el-option label="区" :value="2"></el-option>
                        <el-option label="镇" :value="3"></el-option>
                      </el-select>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="地区编号">
                      <el-input v-model="info.id" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="地区编码">
                      <el-input v-model="info.areaCode" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="地区简称">
                      <el-input v-model="info.simpleName" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="地区全称">
                      <el-input v-model="info.fullName" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

          </el-row>

    </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import areaList from '@/utils/mixins/AreaList.js'
import { ElMessage } from 'element-plus'
export default {
    name:'EditArea',
    mixins:[areaList],
    props:['editId'],
    data(){
      return{
        info:{
          id:'0',
          pid:'0',
          deep:0,
          simpleName:'',
          areaCode:'',
          fullName:''
        }
      }
    },
    methods:{
      //获取信息
      GetDataInfo(){
        if(this.editId !='0'){
          let param = {
            id:this.editId
          }
          this.$api.common.SingleArea(param).then(res=>{
            if(res.data.status === 200){
              this.info = res.data.data;
            }
          })
        }
        
      },

      //保存信息
      btnSaveData(){
        this.$api.common.SaveArea(this.info).then(res=>{
          if(res.data.status === 200){
                  ElMessage.success(res.data.msg);
                  this.btnClose();
              }
              else{
                  ElMessage.error(res.data.msg)
              }
          }).catch(e=>{
              console.log(e)
        }) 
      },

      //关闭按钮
      btnClose(){
          this.$emit('closeBox')  //关闭弹出框
      },

    },
    mounted(){
      this.GetDataInfo()
    }
}
</script>

<style>

</style>