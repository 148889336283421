/**
 * 营销管理模块
 * 
 */

//内容营销
import ListContent from '@/views/Market/Content/ListContent.vue'
//优惠券管理
import ListCoupon from '@/views/Shop/Coupon/ListCoupon.vue'


export default[
    {
        //内容营销
        name:'ListContent',
        path:'listcontent',
        component:ListContent
    },
    {
        //优惠券管理
        name:'ListCoupon',
        path:'listcoupon',
        component:ListCoupon
    },

]