
//商铺列表
import ListShop from '@/views/Shop/Info/ListShop.vue'
//店铺分类
import ListShopCategory from '@/views/Shop/Category/ListCategory.vue'
//频道管理
import ListChannel from '@/views/Shop/Channel/ListChannel.vue'
//订单管理
import ListOrder from '@/views/Sales/Order/ListOrder.vue'




export default[
    {
        //商铺列表
        name:'ListShop',
        path:'listshop',
        component:ListShop
    },
    {
        //店铺分类
        name:'ListShopCategory',
        path:'listshopcategory',
        component:ListShopCategory
    },
    {
        //频道管理
        name:'ListChannel',
        path:'listchannel',
        component:ListChannel
    },  
    {
        //订单管理
        name:'ListOrder',
        path:'listorder',
        component:ListOrder
    },
    

]