<template>
  <div>
    <!-- 按钮 -->
    <div class="skuBtnList" v-if="workInfo.info">
      <el-button class="iconfont icon-add" type="primary" plain v-if="workInfo.info.state == 1 || workInfo.info.state == 3" @click="btnSelectSku">添加物料</el-button>
    </div>

    <!-- 物料列表 -->
    <el-table :data="workInfo.materialList" border stripe show-summary style="width: 100%">
      <el-table-column prop="warehouseName" label="仓库">
        <template v-slot="scope">
          <span>{{scope.row.warehouseName}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="cidName" label="产品类目"></el-table-column>
      <el-table-column prop="brandName" label="品牌"></el-table-column>
      <el-table-column prop="spuName" label="产品名称" />
      <el-table-column prop="skuName" label="规格" />
      <el-table-column label="单位" width="100">
          <template v-slot="scope">
              <el-select v-if="scope.row.isEdit==1" v-model="scope.row.unitId"  @change="ChangeSkuUnit(scope.$index)">
                  <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
              </el-select>
              <span v-else>{{scope.row.unitName}}</span>
          </template>
      </el-table-column>

      <el-table-column prop="unitPrice" label="单价" min-width="115">
        <template v-slot="scope">
            <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.unitPrice" :min="0" :precision="2" size="small"></el-input-number>
            <span v-else >{{this.myRound(scope.row.unitPrice,2)}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="planNum" label="计划出库数量" min-width="115">
        <template v-slot="scope">
            <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.planNum" :min="0" :precision="2" size="small"></el-input-number>
            <span v-else >{{scope.row.planNum}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="realNum" label="实际出库数量" min-width="115">
        <template v-slot="scope">
            <span>{{scope.row.realNum}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="totalPrice" label="实际总价(元)" width="110">
          <template v-slot="scope">
              <span>{{this.myRound(scope.row.totalPrice,2)}}</span>
          </template>
      </el-table-column>
     
      <el-table-column label="库存" min-width="100">
          <template v-slot="scope">
              <span>{{scope.row.stockNum}}</span>
          </template>
      </el-table-column>

      <el-table-column label="操作" width="130" fixed="right">
        <template v-slot="scope" >
            <el-button v-if="scope.row.isEdit == 0 && (workInfo.info.state ==1 || workInfo.info.state == 3)" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
            <el-popconfirm v-if="workInfo.info.state ==1 || workInfo.info.state ==3"
            title="确认要删除吗?"
            @confirm="DeleteRow(scope.row.workNumber,scope.row.id,scope.$index)">
                <template #reference>
                <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
        </template>
      </el-table-column>

    </el-table>

    <!-- 选择出库产品 -->
    <el-dialog title="选择出库产品" 
        v-model="BoxSkuInfo.isVisible"
        width="65%"
        :destroy-on-close="true">
          <SelectStockSkuList :areaNum="workInfo.info.areaNumber" ref="SelectSkuInfo"></SelectStockSkuList>
        <template #footer>
            <div style="padding-top:35px">
              <el-button type="primary" @click="btnSelectData">选择</el-button>
              <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
            </div>
        </template>
    </el-dialog>



  </div>
</template>

<script>
import SelectStockSkuList from '@/components/common/SelectStockSkuList.vue'
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
  components:{
    SelectStockSkuList
  },
  data(){
    return{
      warehouseList:[],  //仓库列表

      //选择出库产品-弹出框
      BoxSkuInfo:{
        isVisible:false,
      },
    }
  },
  computed:{
    ...mapState('Erp',['workInfo'])
  },
  methods:{

    //选择出库产品
    btnSelectSku(){
        this.BoxSkuInfo.isVisible = true;
    },
    //保存选中出库产品
    async btnSelectData(){
      let itemList = this.$refs.SelectSkuInfo.selectedItem
      let warehouseNumber='';  //仓库编号
      let skuNumberList = [];  //sku集合
      if(itemList !=null && itemList.length>0){
        itemList.forEach(item => {
          warehouseNumber= item.warehouseNumber;
          skuNumberList.push(item.skuNumber);          
        });
        let param = {
          warehouseNumber:warehouseNumber,
          skuNumberList:skuNumberList,
        }
        await this.$api.erp.GetWorkMaterialDetailListByParam(param).then(res=>{
          if(res.data.status === 200){
            res.data.data.forEach(row=>{
              this.workInfo.materialList.push(row);
            });
          }
        })
      }
      this.BoxSkuInfo.isVisible = false; //关闭选择产品弹出框
    },

    //编辑行
    EditRow(id){
      this.workInfo.materialList = this.workInfo.materialList.map(item=>{
        if(item.id === id){
          item.isEdit = 1;
        }
        return item;
      })
    },

    //删除行
    DeleteRow(workNumber, id, index){
        this.workInfo.materialList.splice(index,1)
        if(id !=0){
            let param = {
                workNumber:workNumber,
                id:id
            }
            this.$api.erp.DeleteWorkMaterialById(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        }
    },

    //当单位发生变化时
    ChangeSkuUnit(index){
        this.workInfo.materialList = this.workInfo.materialList.map((item,index2)=>{
            //#region 单位发生变化时-重新计算价格、库存
            if(index === index2){
              let param3 = {
                  warehouseNumber:item.warehouseNumber,
                  skuNumber:item.skuNumber,
                  unitId:item.unitId
              }
              this.$api.wms.GetSkuUnitStockInfo(param3).then(res=>{
                  if(res.data.status === 200){
                      item.unitPrice = res.data.data.unitPrice; //单价(新加的)
                      item.stockNum = res.data.data.unitStock;  //库存
                  }
              })
            }            
            //#endregion
            return item
        })
    },


  }

}
</script>

<style>

</style>