<template>
  <div>
    <div class="skuBtnList">
        <el-upload
          :action="GetFileUrl2('Supplier')"
          :headers="GetHeader"
          :show-file-list="false"
          multiple
          :before-upload="BeforeUpload"
          :on-success="UploadSuccess"
          :on-error="UploadError">
          <el-button class="iconfont icon-add" type="warning" plain >上传资质</el-button>
      </el-upload>
    </div>

    <!-- 资质列表 -->
    <el-table :data="supplierInfo.certList" border style="width: 100%">
      <el-table-column label="文件">
        <template v-slot="scope">
            <el-link v-if="scope.row.fileType==2" :href="scope.row.fileUrl" target="_blank">{{scope.row.title}}</el-link>
            <el-image v-else
            style="width: 70px; height: 70px;border:1px solid #ccc;"
            :src="scope.row.fileUrl"
            :preview-src-list="[scope.row.fileUrl]"
            :hide-on-click-modal="true"
          fit="cover"></el-image>
          </template>
      </el-table-column>
      <el-table-column label="类型">
        <template v-slot="scope">
            <el-tag v-if="scope.row.fileType == 1">图片</el-tag>
            <el-tag v-if="scope.row.fileType == 2">文件</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="创建时间">
        <template v-slot="scope">
            <span>{{DateFormat(scope.row.createTime)}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="createName" label="创建者"></el-table-column>
      <el-table-column prop="remark" label="操作" width="130" fixed="right">
        <template v-slot="scope">
            <el-button size="small" type="danger" @click="btnDeleteSupplierCert(scope.row.supplierNumber,scope.row.id,scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import Authorize from '@/utils/authorize.js'
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
    computed:{
        //添加Token 
        GetHeader(){
            return {
                "Authorization":"Bearer "+ Authorize.getToken()
            }
        },
        ...mapState('Purchase',['supplierInfo']),
        ...mapState('Master',['UserName'])
    },
    methods:{
        //上传图片之前操作
        BeforeUpload(file){
            const fileType = file.type;
            const fileSize = file.size;
            console.log(fileType)

            if(fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType!=='application/pdf'){
                ElMessage.error('格式错误，上传支持:jpg|png|pdf 格式')
                return false;
            }
            //文件大小
            if(fileSize/1024/1024 > 5){
                ElMessage.error('上传文件大小不能超过5Mb')
                return false;
            }
        },

        //上传完成
        UploadSuccess(res,file){
            if(res.status === 200){
                let fileType = 1; //文件类型：默认图片
                const fileExt = res.data.slice((res.data.lastIndexOf(".") - 1 >>> 0) + 2)

                if(fileExt == 'pdf'){
                    fileType = 2
                }
                let row = {
                    id:0,
                    title:file.name,
                    fileUrl:res.data,
                    fileType:fileType,
                    createTime:moment(new Date()).format("YYYY-MM-DD"),
                    createName:this.UserName,
                    isDel:0
                }
                this.supplierInfo.certList.push(row);               
                
            }
        },  

        //上传失败
        UploadError(e){
            ElMessage.error(e)
        },

        //删除单个明细
        btnDeleteSupplierCert(supplierNumber,id,index){
            if(id!=0){
                let param = {
                    id:id
                }
                this.$api.supplier.DeleteSupplierCert(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        this.supplierInfo.certList.splice(index,1);
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }else{
                this.supplierInfo.certList.splice(index,1)
            }
        }

    }

}
</script>

<style>

</style>