<template>
  <div class="editInfo">
    <!-- 查看业务对账单 -->
    <el-divider></el-divider>
    
    <!-- 对账单 -->
    <TabCycleInfo></TabCycleInfo>
    <!-- 对账单明细 -->
    <el-divider></el-divider>
    <TabCycleDetail></TabCycleDetail>

    <div class="editFooter">
      <el-button type="primary" @click="SaveDataInfo">保存</el-button>
      <el-button @click="btnClose">关闭</el-button>

      <el-button-group>
          <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
          <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
      </el-button-group>
    </div>

  </div>
</template>

<script>
import TabCycleInfo from '@/views/Fms/CycleBusiness/TabCycleInfo.vue'  
import TabCycleDetail from '@/views/Fms/CycleBusiness/TabCycleDetail.vue'

import { ElMessage } from 'element-plus';
import { mapMutations, mapState } from 'vuex';
export default {
  props:['editId','dataList'],
  components:{
    TabCycleInfo,
    TabCycleDetail,
  },
  data(){
    return{
      info:{
        cycleNumber:this.editId,
      },
      ff_uuid:'',  //唯一标识         
    }
  },
  computed:{
      ...mapState('Erp',['cycleBusinessInfo'])
  },
  methods:{
    ...mapMutations('Erp',['SetCycleBusinessInfo']),

    //加载数据
    GetDataInfo(){
      if(this.info.cycleNumber !=0){
        let param = {
          cycleNumber:this.info.cycleNumber
        }
        this.$api.erp.GetCycleAllInfo(param).then(res=>{
          if(res.data.status === 200){
            this.SetCycleBusinessInfo(res.data.data)
          }else{
            this.btnClearData();
          }
        })
      }else{
        this.btnClearData();
      }
    },

    //保存
    SaveDataInfo(){
      let header = {
          checkNum:this.ff_uuid,
      }
      this.$api.erp.SaveCycleBusinessAllInfo(this.cycleBusinessInfo,header).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //清空实体
    btnClearData(){
      var newInfo = {
          info:{
            systemNumber:'',
            cycleNumber:'',
            departmentId:0,
            departmentName:'',
            tradeType:0,
            tradeNumber:'',
            tradeName:'',
            totalPrice:0,
            creator:'',
            createName:'',
            remark:'',
          },
          originList:[],
          saleAllInfoList:[],
          returnSaleAllInfoList:[],
      }
      this.SetCycleBusinessInfo(newInfo);
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

    //上一条
    btnPrevious(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.cycleNumber === this.info.cycleNumber
        })
        let prevIndex = currentIndex -1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有上一条了");
            return false;
        }
        else{
            this.info.cycleNumber = prevInfo.cycleNumber
            this.GetDataInfo()
        }
        
    },
    //下一条
    btnNext(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.cycleNumber === this.info.cycleNumber
        })
        let prevIndex = currentIndex + 1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有下一条了");
            return false;
        }
        else{
            this.info.cycleNumber = prevInfo.cycleNumber
            this.GetDataInfo()
        }
    },


  },
  mounted(){
    this.GetDataInfo();
    this.ff_uuid = this.$uuid.v4();  //防重复提交随机编码
  },
}
</script>

<style>

</style>