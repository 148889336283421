<template>
  <div>
    <!-- 对账回执单 -->

    <el-form :inline="true"
        v-model="cycleInfo.receiptInfo"
        label-width="100px"
        label-position="right">
            <el-row v-if="cycleInfo.receiptInfo">

                <el-col :span="8">
                    <el-form-item label="发票号">
                      <template #label>
                            <span style="color:red;">*</span>
                            <span>发票号</span>
                        </template>
                      <el-input v-model="cycleInfo.receiptInfo.invoiceNumber" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="发票金额">
                      <template #label>
                            <span style="color:red;">*</span>
                            <span>发票金额</span>
                        </template>
                      <el-input-number v-model="cycleInfo.receiptInfo.invoicePrice" :precision="2" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="账单发出日期">
                        <el-date-picker v-model="cycleInfo.receiptInfo.sendDate" type="datetime" placeholder="账单发出日期" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px"/>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="账单回收日期">
                        <el-date-picker v-model="cycleInfo.receiptInfo.receiveDate" type="datetime" placeholder="账单回收日期" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px"/>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item label="对账函" >
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>对账函</span>
                    </template>

                    <el-link :href="cycleInfo.receiptInfo.originalFile" target="_blank">对账函文件</el-link>

                    <el-upload
                    :action="GetFileUrl('Cycle')"
                    :headers="GetHeader"
                    :show-file-list="false"
                    :before-upload="BeforeUpload"
                    :on-success="UploadSuccess">
                        <el-button size="small" type="warning">上传对账函</el-button>
                    </el-upload>

                    <!-- 提示 -->
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="将回收的对账函上传到此处"
                        placement="top"
                    >
                        <i class="iconfont icon-wenhao"></i>
                    </el-tooltip> 
                  </el-form-item>
                </el-col>

                
                
            </el-row>
      </el-form>


  </div>
</template>

<script>
import Authorize from '@/utils/authorize.js'
import { ElMessage } from 'element-plus';
import { mapState } from 'vuex'
export default {
  computed:{
    //添加Token - 重要
    GetHeader(){
      return {
        "Authorization":"Bearer "+ Authorize.getToken()
      }
    },
    ...mapState('Finance',['cycleInfo'])
  },
  methods:{
    
    //上传图片之前操作
    BeforeUpload(file){
      const fileType = file.type;
      const fileSize = file.size;
      
      if(fileType !== 'image/jpeg' && fileType !== 'image/png'){
        ElMessage.error('格式错误，上传支持:jpeg|png 格式')
        return false;
      }
      //文件大小
      if(fileSize/1024/1024 > 5){
        ElMessage.error('上传文件大小不能超过5Mb')
        return false;
      }

    },

    //上传完成
    UploadSuccess(res){
      if(res.status === 200){
        ElMessage.success('上传成功');
        this.cycleInfo.receiptInfo.originalFile = res.data
      }
    },

  },

}
</script>

<style>

</style>