//服务模块相关功能 企业评价、行业溯源码、认证服务、金融服务、会员服务

var actions = {

}

var mutations = {
    
}

var state = {
    traceInfo:{},  //溯源信息
}

export default{
    namespaced:true,
    actions,
    mutations,
    state
}