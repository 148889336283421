<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
        v-model="info"
        label-width="110px"
        label-position="right">
          <el-row>

              <el-col :span="24">
                  <el-form-item label="账户名称">
                    <template #label>
                          <span style="color:red;">*</span>
                          <span>账户名称</span>
                      </template>
                    <el-input v-model="info.accountName" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="24">
                  <el-form-item label="纳税人识别号">
                    <template #label>
                          <span style="color:red;">*</span>
                          <span>纳税人识别号</span>
                      </template>
                    <el-input v-model="info.invoiceNumber" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="24">
                  <el-form-item label="地址">
                    <template #label>
                          <span style="color:red;">*</span>
                          <span>地址</span>
                      </template>
                    <el-input v-model="info.address" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="24">
                  <el-form-item label="电话">
                    <template #label>
                          <span style="color:red;">*</span>
                          <span>电话</span>
                      </template>
                    <el-input v-model="info.phone" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="24">
                  <el-form-item label="开户行">
                    <template #label>
                          <span style="color:red;">*</span>
                          <span>开户行</span>
                      </template>
                    <el-input v-model="info.bankName" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="24">
                  <el-form-item label="银行账号">
                    <template #label>
                          <span style="color:red;">*</span>
                          <span>银行账号</span>
                      </template>
                    <el-input v-model="info.bankNumber" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="24">
                  <el-form-item label="默认账户">
                    <el-select v-model="info.isDef" class="search-200">
                      <el-option :value="0" label="否"></el-option>
                      <el-option :value="1" label="是"></el-option>
                    </el-select>
                  </el-form-item>
              </el-col>
              
          </el-row>
    </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="SaveDataInfo">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  props:['editId','dataList'],
  data(){
    return{
      currentId:this.editId,  //当前id

      info:{
        id:0,
        systemNumber:'',
        accountName:'',
        invoiceNumber:'',
        address:'',
        phone:'',
        bankName:'',
        bankNumber:'',
        isDef:0,
      }
    }
  },
  methods:{

    //加载数据信息
    GetDataInfo(){
      console.log(this.currentId)
      if(this.currentId !=0){
        let param = {
          id:this.currentId
        }
        this.$api.finance.GetInvoiceInfo(param).then(res=>{
          if(res.data.status === 200){
            this.info = res.data.data;
          }else{
            this.btnClearData();
          }
        })
      }else{
         this.btnClearData();
      }
    },

    //保存数据
    SaveDataInfo(){
      this.$api.finance.SaveInvoice(this.info).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          this.btnClose();
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },


    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

    //清空数据实体
    btnClearData(){
      let newInfo = {
        id:0,
        systemNumber:'',
        accountName:'',
        invoiceNumber:'',
        address:'',
        phone:'',
        bankName:'',
        bankNumber:'',
        isDef:0,
      }
      this.info = newInfo;
    },

    //上一条
      btnPrevious(){
          let currentIndex = this.dataList.findIndex((item)=>{
              return item.id === this.currentId
          })
          let prevIndex = currentIndex -1;
          let prevInfo = this.dataList[prevIndex];
          if(prevInfo==null){
              ElMessage.warning("没有上一条了");
              return false;
          }
          else{
              this.currentId = prevInfo.id
              this.GetDataInfo()
          }
          
      },
      //下一条
      btnNext(){
          let currentIndex = this.dataList.findIndex((item)=>{
              return item.id === this.currentId
          })
          let prevIndex = currentIndex + 1;
          let prevInfo = this.dataList[prevIndex];
          if(prevInfo==null){
              ElMessage.warning("没有下一条了");
              return false;
          }
          else{
              this.currentId = prevInfo.id
              this.GetDataInfo()
          }
      },


  },
  mounted(){
    this.GetDataInfo();  //获取单个信息
  }
}
</script>

<style>

</style>