<template>
  <div>
    <div v-if="returnPurchaseInfo.outAllInfoList !=null && returnPurchaseInfo.outAllInfoList.length>0">
        <!-- 出库记录 -->
        <div class="CardItem" v-for="(allInfo,index) in returnPurchaseInfo.outAllInfoList" :key="index">
            <!-- 主信息 -->
            <el-descriptions border>
                <el-descriptions-item>
                    <template #label>
                        出库单号
                    </template>
                        {{allInfo.info.outNumber}}
                </el-descriptions-item>

                <el-descriptions-item>
                    <template #label>
                        出库时间
                    </template>
                        {{DateTimeFormat(allInfo.info.outTime)}}
                </el-descriptions-item>

                <el-descriptions-item>
                    <template #label>
                        状态
                    </template>
                        <el-tag v-if="allInfo.info.state == 1">新建单</el-tag>
                        <el-tag v-if="allInfo.info.state == 2">出库中</el-tag>
                        <el-tag v-if="allInfo.info.state == 3" type="info">已出库</el-tag>
                        <el-tag v-if="allInfo.info.state == 10">已取消</el-tag>
                </el-descriptions-item>

                <el-descriptions-item>
                    <template #label>
                        交易者类型
                    </template>
                        <span v-if="allInfo.info.tradeType == 0">无</span>
                        <span v-if="allInfo.info.tradeType == 1">供应商</span>
                        <span v-if="allInfo.info.tradeType == 2">客户</span>
                        <span v-if="allInfo.info.tradeType == 3">员工</span>
                </el-descriptions-item>

                <el-descriptions-item>
                    <template #label>
                        交易者名称
                    </template>
                        {{allInfo.info.tradeName}}
                </el-descriptions-item>

                <el-descriptions-item>
                    <template #label>
                        经手人
                    </template>
                        {{allInfo.info.receiveName}}
                </el-descriptions-item>
            
            </el-descriptions>
            
            <!-- 出库单明细列表 -->
            <el-table :data="allInfo.detailList" border stripe style="width: 100%" >
                <el-table-column prop="warehouseName" label="仓库"></el-table-column>
                <el-table-column prop="cidName" label="产品类目"></el-table-column>
                <el-table-column prop="brandName" label="品牌"></el-table-column>
                <el-table-column prop="spuName" label="产品"></el-table-column>
                <el-table-column prop="skuName" label="规格"></el-table-column>
                <el-table-column prop="unitName" label="单位"></el-table-column>
                <el-table-column prop="realNum" label="出库数量"></el-table-column>
            </el-table>


        </div>
    </div>
    <div v-else>
        无出库记录
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Erp',['returnPurchaseInfo'])
    },
}
</script>

<style scoped>
.CardItem{padding-bottom: 20px;margin-bottom: 20px;border-bottom: 1px solid #ccc;}
</style>