<template>
  <div>

    <!-- 工资单主信息 -->
    <div>
      <el-form :inline="true"
        v-model="wageInfo.info"
        label-width="100px"
        label-position="right">
            <el-row v-if="wageInfo.info">

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="员工姓名">
                      <el-input v-model="wageInfo.info.userName" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="所属部门">
                      <el-input v-model="wageInfo.info.departmentName" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="月份">
                        <el-date-picker
                            v-model="wageInfo.info.month"
                            type="month" disabled style="width:200px"/>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                  <el-form-item label="单据类型">
                    <el-select v-model="wageInfo.info.billType" disabled class="search-200">
                      <el-option :value="1" label="工资"></el-option>
                      <el-option :value="2" label="奖金"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="实发工资">
                      <el-input  v-model="wageInfo.info.realWage" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="审核人">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>审核人</span>
                        </template>
                        <el-select v-model="wageInfo.info.receiver" class="search-200">
                            <el-option v-for="item in selectUserList" :key="item.userNumber" :label="item.userName" :value="item.userNumber"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                  <el-form-item label="财务单号">
                      <el-input v-model="wageInfo.info.financeNumber" disabled class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :md="8" :sm="12" :xs="24">
                  <el-form-item label="状态">
                    <el-tag v-if="wageInfo.info.state ==1" type="info">新建单</el-tag>
                    <el-tag v-if="wageInfo.info.state ==2" >待审核</el-tag>
                    <el-tag v-if="wageInfo.info.state ==3" type="danger">已驳回</el-tag>
                    <el-tag v-if="wageInfo.info.state ==4" type="warning">已审核并上报</el-tag>
                    <el-tag v-if="wageInfo.info.state ==5" type="success">审核已完成</el-tag>
                    <el-tag v-if="wageInfo.info.state ==10">已关单</el-tag>
                  </el-form-item>
              </el-col>

              <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="财务单标识">
                    <el-tag v-if="wageInfo.info.financeFlag == 0" type="danger">未生成</el-tag>
                    <el-tag v-if="wageInfo.info.financeFlag == 1" type="info">已生成</el-tag>
                </el-form-item>
            </el-col>

            </el-row>
      </el-form>      
    </div>

    <!-- 工资结构项列表 -->
    <el-divider>工资单明细</el-divider>
    <div>
      <el-table :data="wageInfo.detailList" border stripe height="400">
          <el-table-column prop="itemName" label="项目名称">
          </el-table-column>

          <el-table-column label="项目值">
            <template v-slot="scope">
              <el-input-number v-if="wageInfo.info.state==1 || wageInfo.info.state==3" v-model="scope.row.itemValue" size="small" :precision="2" :min="0"></el-input-number>
              <span v-else>{{scope.row.itemValue}}</span>
            </template>
          </el-table-column>

          <el-table-column label="计算方式">
            <template #default="scope">
                <el-tag v-if="scope.row.way ==0" type="danger">减</el-tag>
                <el-tag v-if="scope.row.way ==1">加</el-tag>
            </template>
          </el-table-column>

      </el-table>
    </div>

  </div>
</template>

<script>
import AllUserList from '@/utils/mixins/AllUserList.js'
import { mapState } from 'vuex'
export default {
  mixins:[AllUserList],
  data(){
    return{
      info:{
        detailList:[],  //项目列表
        itemName:'',
        way:0,
        state:1,
      }
    }
  },
  computed:{
    ...mapState('Oa',['wageInfo'])
  },
}
</script>

<style scoped>
</style>