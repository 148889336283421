/**
 * 服务模块
 * 
 */

import base from '../base'   //导入接口域名列表
import axios from '@/utils/http'   //导入http中创建的axios实例

const evaluate = {

    //#region 主题

    //获取指标评价主题列表
    ListEvaluateTheme(param){
        return axios.get(`${base.base}/Evaluate/ListEvaluateTheme`,{
            params:param
        })
    },

    //获取指标评价主题列表- 下拉列表用
    SelectEvaluateTheme(){
        return axios.get(`${base.base}/Evaluate/SelectEvaluateTheme`)
    },

    //获取单个信用评价信息（包含主题、等级）
    SingleEvaluateAllInfo(param){
        return axios.get(`${base.base}/Evaluate/SingleEvaluateAllInfo`,{
            params:param
        })
    },

    //获取单个指标主题信息
    SingleEvaluateTheme(param){
        return axios.get(`${base.base}/Evaluate/SingleEvaluateTheme`,{
            params:param
        })
    },

    //获取单个指标主题信息  (不包含系统编号)(跨系统方法) 
    SingleNoSystemEvaluateTheme(param){
        return axios.get(`${base.base}/Evaluate/SingleNoSystemEvaluateTheme`,{
            params:param
        })
    },

    //保存信用评价 (包含主题、级别)
    SaveEvaluateAllInfo(param){
        return axios.post(`${base.base}/Evaluate/SaveEvaluateAllInfo`,param)
    },

    //删除指标主题相关 (全部)
    DeleteEvaluateAllInfo(param){
        return axios.post(`${base.base}/Evaluate/DeleteEvaluateAllInfo`,param)
    },

    //计算总评分
    ComputeTotalScore(themeNumber){
        return axios.post(`${base.base}/Evaluate/ComputeTotalScore?themeNumber=${themeNumber}`)
    },

    //#endregion

    //#region 评价指标级别

    //获取单个指标信息
    SingleEvaluateTag(param){
        return axios.get(`${base.base}/Evaluate/SingleEvaluate`,{
            params:param
        })
    },

    //保存指标信息
    SaveEvaluateTag(param){
        return axios.post(`${base.base}/Evaluate/SaveEvaluateTag`,param)
    },

    //删除指标信息
    DeleteEvaluateTag(param){
        return axios({
            url:`${base.base}/Evaluate/DeleteEvaluateTag`,
            method:'post',
            params:param
        })
    },

    //获取指标目录树 - 下拉列表
    SelectEvaluateTagList(param){
        return axios.get(`${base.base}/Evaluate/SelectEvaluateTagList`,{
            params:param
        })
    },

    //#endregion

    //#region 评价等级

    //删除评价级别
    DeleteEvaluateLevel(param){
        return axios.post(`${base.base}/Evaluate/DeleteEvaluateLevel`,param)
    },

    //#endregion

    //#region 指标评分

    //获取指标评分列表
    ListTagScore(param){
        return axios.get(`${base.base}/Evaluate/ListTagScore`,{
            params:param
        })
    },

    //获取单个指标评分信息
    SingleTagScore(param){
        return axios.get(`${base.base}/Evaluate/SingleTagScore`,{
            params:param
        })
    },

    //保存指标评分信息
    SaveTagScore(param){
        return axios.post(`${base.base}/Evaluate/SaveTagScore`,param)
    },

    //删除指标评分
    DeleteTagScore(param){
        return axios.post(`${base.base}/Evaluate/DeleteTagScore`,param)
    },

    
    //#endregion


    //#region 评价记录

    //评价记录列表
    ListRecord(param){
        return axios.get(`${base.base}/Evaluate/ListRecord`,{
            params:param
        })
    },

    //获取单个评价记录信息 (包含子信息)
    SingleRecordAllInfo(param){
        return axios.get(`${base.base}/Evaluate/SingleRecordAllInfo`,{
            params:param
        })
    },

    //获取单个评价记录信息 (不包含子信息)
    SingleRecordInfo(param){
        return axios.get(`${base.base}/Evaluate/SingleRecordInfo`,{
            params:param
        })
    },

    //保存指标记录信息
    SaveRecordInfo(param){
        return axios.post(`${base.base}/Evaluate/SaveRecordInfo`,param)
    },

    //删除指标记录信息(包含子信息)
    DeleteRecordAllInfo(param){
        return axios.post(`${base.base}/Evaluate/DeleteRecordAllInfo`,param)
    },



    //获取评分记录明细列表
    ListRecordDetail(param){
        return axios.get(`${base.base}/Evaluate/ListRecordDetail`,{
            params:param
        })
    },

    //保存评分记录明细列表
    SaveRecordDetail(param){
        return axios.post(`${base.base}/Evaluate/SaveRecordDetail`,param)
    },


    //#endregion

    //#region 获取当前系统被评价记录

    ListMyRecord(param){
        return axios.post(`${base.base}/Evaluate/ListMyRecord`,param)
    },

    //#endregion


    //#region 短信服务

    //列表-分页
    PageNoticeSmsList(param){
        return axios.post(`${base.base}/Notice/PageNoticeSmsList`,param)
    },

    //#endregion

}

export default evaluate