<template>
  <div class="login">
    <div class="left" v-show="isShow">
      <img class="photo" src="../assets/img/photo2.png" alt="">
    </div>
    <div :class="['right',isShow?'':'full']">
      <div class="login-box">
        <div class="login-box-title">欢迎进入透明云管理平台！</div>
        <form action="" class="form-box" @keydown.enter="submitLogin">
          <div class="input-box-box">
            <img class="icon" src="../assets/img/icon1.png" alt="">
            <input class="input-box username" type="text" placeholder="请输入您的用户名" v-model="loginInfo.loginUserName">
          </div>
          <div class="input-box-box">
            <img class="icon" src="../assets/img/icon2.png" alt="">
            <input class="input-box password" type="password" placeholder="请输入您的密码" v-model="loginInfo.loginPassword">
          </div>
          <!-- 选择系统 -->
          <div class="input-box-box">
            <el-select class="input-box userSystem" style="float:left;width:60%;" v-model="loginInfo.systemNumber">
              <el-option v-for="sysInfo in systemList" :key="sysInfo.systemNumber" :label="sysInfo.systemName" :value="sysInfo.systemNumber"></el-option>
            </el-select>
            <div style="float:left;width:40%;"><button type="button" class="selectSystem" @click="GetSystemList">选择系统</button></div>
          </div>
          <!-- 验证码 -->
          <div class="input-box-box">
              <div style="float:left;width:60%;"><input class="input-Captcha" type="text" placeholder="验证码" v-model="loginInfo.authCode"></div>
              <div style="float:left;width:40%;"><img class="CaptchaImg" :src="'data:image/png;base64,'+captchaBase64" alt="" @click="GetCaptcha"></div>
          </div>
          <button class="input-box btn" type="button" @click="submitLogin">登录</button>
          <div class="rebPwd">
            <!-- <input type="checkbox" :checked="rmbPwd" />记住密码 -->
            <el-link :underline="false" @click="ClearGuestInfo">清空密码</el-link>
          </div>
        </form>
      </div>
      <div class="other">
        <p>中华全国供销合作总社北京商业机械研究所  ©版权所有   ICP备案 : <a href="https://beian.miit.gov.cn" target="_blank">京ICP备15064683号-6</a></p>
        <p>电话：010-63611239   &nbsp; &nbsp;   办公地址：北京市丰台区南四环西路188号十六区3号楼</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import { ElMessage } from 'element-plus'
import Authorize from '@/utils/authorize'
export default {
  name: 'Login',
  data() {
    return {
      screenWidth: null,
      isShow: true,
      rmbPwd:'checked',  //记住密码
      loginInfo:{
        systemNumber:'',  //系统编号
        loginUserName:'',
        loginPassword:'',
        authCode:'',
        uuid:'',
      },
      systemList:[], //系统列表
      captchaBase64:'', //验证码图片64位编码
    }
  },
  methods:{
    ...mapMutations('Master',['ClearTag']),
    //提交登录
    submitLogin(){
        //先处理之前用户留下的痕迹
        Authorize.clearToken() //清空Token
        localStorage.removeItem("TagList") //清空本地TagList，因为可能登录的权限不同
        this.ClearTag();   // 清空Tag
        //基础判断
        if(this.loginInfo.loginUserName=='' || this.loginInfo.loginPassword==''){
            ElMessage.error('用户名和密码不能为空');
            return false;
        }
        if(this.loginInfo.authCode==''){
          ElMessage.error('请输入验证码')
          return false;
        }
        //登录
        this.$api.login.UserLogin(this.loginInfo).then(res=>{
            if(res.data.status ===200){
                //保存Token到本地
                var now = new Date();
                now.setDate(now.getDate()+1);  
                Authorize.saveToken(res.data.data,Date.parse(now))  //保存本地
                //保存本地用户信息
                this.SaveGuestInfo();
                //跳转
                ElMessage.success(res.data.msg)
                this.$router.push({name:'back'})
            }
            else{
                ElMessage.error(res.data.msg)
            }
        })
    },
    //获取验证码
    GetCaptcha(){
      this.$api.login.GetCaptcha().then(res=>{
        if(res.data.status === 200){
          this.captchaBase64 = res.data.data.authCode;
          this.loginInfo.uuid = res.data.data.uuid;
        }
      })
    },

    //获取系统列表
    GetSystemList(){
      if(this.loginInfo.loginUserName==''){
            ElMessage.error('请先输入用户名');
            return false;
        }

      let param = {
        phone:this.loginInfo.loginUserName
      }
      this.$api.system.SelectSystemList(param).then(res=>{
        if(res.data.status === 200){
          this.systemList = res.data.data;
          //默认第一个
          if(this.systemList !=null && this.systemList.length>0){
            this.loginInfo.systemNumber = this.systemList[0].systemNumber;
          }
        }else{
          this.systemList = [];
          ElMessage.error(res.data.msg);
        }
      })
    },

    //存储【用户名和密码】
    SaveGuestInfo(){
      let guestInfo = {
        loginName:this.loginInfo.loginUserName,
        loginPwd:this.loginInfo.loginPassword,
        systemList:this.systemList
      }
      localStorage.setItem('guestInfo',JSON.stringify(guestInfo));
    },

    //加载已存储的【用户名和密码】
    LoadGuestInfo(){
      let guestInfo = JSON.parse(localStorage.getItem('guestInfo'))
      if(guestInfo!=null){
        this.loginInfo.loginUserName = guestInfo.loginName;
        this.loginInfo.loginPassword = guestInfo.loginPwd;
        this.systemList = guestInfo.systemList;
        //默认第一个
        if(this.systemList !=null && this.systemList.length>0){
          this.loginInfo.systemNumber = this.systemList[0].systemNumber;
        }
      }
    },

    //清除已存储的【用户名和密码】
    ClearGuestInfo(){
      localStorage.removeItem('guestInfo')
      this.loginInfo.loginUserName = '';
      this.loginInfo.loginPassword = '';
      this.loginInfo.systemNumber = '';
      this.systemList = [];
      ElMessage.success('已清空用户信息');
    },

  },
  mounted() {
    //加载验证码
    this.GetCaptcha();
    //加载用户和密码信息
    this.LoadGuestInfo();
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
 watch: {
    screenWidth: function (n) {
      if (n <= 990) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    }
  }
}
</script>

<style>
/* 选择系统样式 */
.input-box-box .el-select__wrapper {
height: 50px !important;
line-height: 50px !important;
border-radius: 50px !important;
background-color: #f5f5f5 !important;
font-size: 18px !important;
padding-left: 25px !important;
}
</style>
<style scoped>

.login {
  width: 100%;
  height: 100%;
  background-image: url('../assets/img/bg2.png');
  background-size: 100% 100%;
  display: flex;
  box-sizing: border-box;
}
.left {
  width: 50%;
  height: 100%;
  position: relative;
}
.left .photo {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.right {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  position: relative;
}
.right.full {
  width: 100%;
}
.right .login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 45px 50px 45px 50px;
  border-radius: 12px;
  z-index: 99;
}
.right .login-box .login-box-title {
  font-size: 24px;
  color: #313131;
  text-align: left;
  text-indent: 1em;
}
.right .login-box .form-box {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
.right .login-box .form-box .input-box-box {
  position: relative;
  margin-bottom: 35px;
  width: 380px;
}
.right .login-box .form-box .input-box-box .icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.right .login-box .form-box .input-box {
  width: 100%;
  height: 50px;
  outline: none;
  border: none;
  background-color: #f5f5f5;
  border-radius: 50px;
  font-size: 18px;
  color: #050505;
}
.right .login-box .form-box .input-box.username {
  text-indent: 70px;
  position: relative;
  background-color: #f5f5f5;

}
.right .login-box .form-box .input-box.password {
  text-indent: 70px;
  background-color: #f5f5f5;
}
.userSystem{
  height: 50px;
  border-radius: 30px;
}
.right .login-box .form-box .input-box.btn {
  width: 380px;
  font-size: 25px;
  color: #fff;
  background-color: #3aa2fb;
}
.right .other {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
}
.right .other p {
  font-size: 12px;
  color: #fefefe;
  text-align: center;
}
.right .other p a {
  font-size: 12px;
  color: #fefefe;
  text-decoration: none;
}
/** 验证码 */
.input-Captcha {
  width: 80%;
  height: 50px;
  outline: none;
  border: none;
  background-color: #f5f5f5;
  border-radius: 60px;
  font-size: 20px;
  padding-left: 35px;
  color: #050505;
}
.CaptchaImg{
  border: 1px solid #ccc;
  margin-left: 20px;
}
.rebPwd{padding-top: 10px;}
/* 选择系统 */
.selectSystem{margin-left: 20px;width: 100px;
  font-size: 18px;
  color: #fff;
  background-color: #3aa2fb;
  height: 50px;
  border-radius: 30px;
  border: none;
}
</style>
