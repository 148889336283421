<template>
  <div>
    <!-- 移动端菜单管理 -->
    <el-row>
      <!-- 目录树 -->
      <el-col :span="4" class="tree_info">
          <el-card class="box-card">
            <template #header>
                <div class="card-header">
                    <span>系统菜单</span>
                </div>
            </template>
            <!-- 目录树 -->
            <el-scrollbar :height="GetTreeDataHeight">
                <el-tree :data="info.treeList"
                node-key="id"
                :default-expanded-keys="[0]"
                  @node-click="handleNodeClick" empty-text="暂无系统菜单" />
            </el-scrollbar>                
          </el-card>
      </el-col>

      <!-- 主列表 -->
      <el-col :span="20">
        <!-- 搜索栏 -->
        <el-row>

        </el-row>

        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
          <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
          </el-col>

          <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                          
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>    
            
          </el-col>
        </el-row>

        <!-- 列表 -->
      <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" :row-key="GetRowKey">
        <el-table-column prop="menuId" type="selection" width="50" />  
        <el-table-column prop="menuName" label="菜单名称" />
        <el-table-column prop="jumpName" label="链接名称" min-width="110">
          <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.jumpName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.jumpName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" width="80" />
        
        <el-table-column label="菜单类型">
          <template #default="scope">
            <el-tag v-if="scope.row.menuType==1">模块</el-tag>
            <el-tag v-else-if="scope.row.menuType==2" type="success">菜单</el-tag>
            <el-tag v-else-if="scope.row.menuType==3" type="warning">按钮</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="菜单状态" >
          <template #default="scope">
            <el-tag v-if="scope.row.state==1">正常</el-tag>
            <el-tag v-if="scope.row.state==0" type="danger">停用</el-tag>
          </template>
        </el-table-column>
        
        <el-table-column label="备注">
          <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.remark"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.remark}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="130">
          <template #default="scope">
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.menuId)">编辑</el-button>
            <el-popconfirm 
            title="确认要删除吗?"
            @confirm="btnDeleteInfo(scope.row.menuId)">
              <template #reference>
                <el-button size="small" type="danger">删除</el-button>
              </template>
            </el-popconfirm>
            
          </template>
        </el-table-column>
      </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
              v-model:currentPage="info.page"
              v-model:page-size="info.size"
              :page-sizes="[50, 200, 500]"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="info.total"
              @current-change="btnPagechange"
              @size-change="btnSizechange"
            >
            </el-pagination>
        </el-container>

      </el-col>
    </el-row>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="650"
      :draggable="true"
    :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="onLoadAllData">
        <EditMobileMenu :editId="info.id" @closeBox="btnCloseBox"></EditMobileMenu>
    </el-dialog>

  </div>
</template>

<script>
import EditMobileMenu from '@/views/system/MobileMenu/EditMobileMenu.vue'
import { ElMessage } from 'element-plus'
export default {
    components:{
        EditMobileMenu,
    },
    data(){
        return {
            info:{
                treeList:[],   //菜单目录树
                pid:0,   //目录树父节点
                dataList:[],  //Table数据
                page:1,   //页数
                size:50,  //单页记录数
                total:0,  //总记录数
                id:0,  //单个新增、编辑
                ids:[],   //新增、编辑、删除 的参数
            },
            BoxEditInfo:{
                isVisible:false,  //新增、编辑 弹框
                title:"新增菜单"
            }                
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-190
        },
        //Tree自适应窗体高度
        GetTreeDataHeight(){
            return window.innerHeight - 250
        },
    },
    methods:{
        //获取目录树列表
        GetTreeMenuList(){
            let param = {
              isButton:0   //是否包含按钮 0:不包含 1:包含
            }
            this.$api.system.SelectAllMobileMenuList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.treeList = res.data.data;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //加载数据列表
        GetDataList(){
            let param = {
                pid:this.info.pid,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.system.PageMobileMenuList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //获取目录树节点
        handleNodeClick(data){
            this.info.pid = data.id;
            this.GetDataList()
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.menuId)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //返回行编号
        GetRowKey(row){
            return row.menuId
        },

        //新增、编辑-按钮
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "新增菜单"
            }else{
                this.BoxEditInfo.title = "编辑菜单"
            }        
            this.BoxEditInfo.isVisible = true;
        },

        //删除按钮
        btnDeleteInfo(id){
            let param = {
                menuId:id
            }
            this.$api.system.DeleteMobileMenu(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.onLoadAllData()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //加载所有数据
        onLoadAllData(){
            this.GetTreeMenuList();
            this.GetDataList();
        },
    },
    mounted(){
        this.GetTreeMenuList();
        this.GetDataList();
    },

}
</script>

<style>

</style>