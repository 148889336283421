<template>
  <div>
    <div class="skuBtnList">
        <el-button class="iconfont icon-add" type="primary" plain @click="btnSelectSupplier">选择供应商</el-button>
    </div>

    <!-- 列表 -->
    <el-table :data="inquiryInfo.supplierList" border style="width: 100%">

      <el-table-column prop="supplierName" label="供应商名称" />
      <el-table-column prop="supplierName" label="识别码" />
      <el-table-column label="是否报价">
        <template v-slot="scope">
          <el-tag v-if="scope.row.isRepy == 1">是</el-tag>
          <el-tag v-else>否</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160" fixed="right">
          <template v-slot="scope">
              <el-popconfirm 
              title="确认要删除吗?"
              @confirm="DeleteRow(scope.row.billNumber,scope.row.supplierNumber,scope.row.id,scope.$index)">
                  <template #reference>
                  <el-button size="small" type="danger">删除</el-button>
                  </template>
              </el-popconfirm>
          </template>
      </el-table-column>

    </el-table>

    <!-- 选择供应商 - 弹出框 -->
    <el-dialog :title="BoxSupplier.title" 
    v-model="BoxSupplier.isVisible"
    width="60%"
    :destroy-on-close="true">
      <SelectAllSupplierList ref="selectSupplierList"></SelectAllSupplierList>
      <template #footer>
          <el-button type="primary" @click="btnSelectData">保存</el-button>
          <el-button @click="BoxSupplier.isVisible = false">取消</el-button>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
import SelectAllSupplierList from '@/components/common/SelectAllSupplierList.vue'
export default {
  name:'TabSingleInquirySupplier',
  data(){
    return{
      BoxSupplier:{
        isVisible:false,
        title:'选择供应商'
      }
    }
  },
  components:{
    SelectAllSupplierList
  },
  computed:{
    ...mapState('Purchase',['inquiryInfo'])
  },
  methods:{
    //打开选择供应商组件
    btnSelectSupplier(){
        this.BoxSupplier.isVisible = true
    },
    //获取选中供应商
    btnSelectData(){
      let itemList = this.$refs.selectSupplierList.selectedItem
      if(itemList!=null && itemList.length>0){
        itemList.forEach(item => {
          var newRow = {
            id:0,
            systemNumber:'',
            billNumber:'',
            supplierNumber:item.supplierNumber,
            supplierName:item.supplierName,
            certNumber:item.certNumber,
            isRepy:0,
            isEdit:1,
            isDel:0,
          }
          this.inquiryInfo.supplierList.push(newRow)
        });
      }
      this.BoxSupplier.isVisible = false
    },
    //删除行
    DeleteRow(billNumber,supplierNumber,id,index){
        this.inquiryInfo.supplierList.splice(index,1)
        if(id !=0){
          let param = {
            billNumber:billNumber,
            supplierNumber:supplierNumber
          }
          this.$api.inquiry.DeleteInquirySupplier(param).then(res=>{
              if(res.data.status === 200){
                  ElMessage.success(res.data.msg)
              }
              else
              {
                  ElMessage.error(res.data.msg)
              }
          })
        }
    },


  }
}
</script>

<style>

</style>