<template>
  <div>
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="应用名称">
                <el-input v-model="info.search.appName" class="search-200"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
                <el-button class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button>    
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                        
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="55" /> 
        <el-table-column prop="appNumber" label="编号" width="170" />
        <el-table-column prop="appName" label="应用名称" min-width="110">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.appName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.appName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="version" label="版本号" />
        <el-table-column prop="updateTime" label="更新时间" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.updateTime"
                    placement="top"
                  >
                  <span>{{DateFormat(scope.row.updateTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="remark" label="更新说明" min-width="110">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.remark"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.remark}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="appUrl" label="更新地址"  show-overflow-tooltip min-width="120"/>
        
        <el-table-column label="操作" width="150" fixed="right">
            <template v-slot="scope">              
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.appNumber)">详情</el-button>
                <el-button size="small" type="danger" @click="btnDeleteInfo(scope.row.appNumber)">删除</el-button>
            </template>
        </el-table-column>
        </el-table>

    </div>

    <!-- 分页 -->
    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="400"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditSetMobileVersion :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditSetMobileVersion>
    </el-dialog>

  </div>
</template>

<script>
import EditSetMobileVersion from '@/views/SetUp/SetVersion/EditSetMobileVersion.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    components:{
        EditSetMobileVersion,
    },
    data(){
        return{
            dataList:[],  //Table数据
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    appName:'',
                },
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'移动端版本设置'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
    methods:{
        //获取数据
        GetDataList(){
            let param = {
                appName:this.info.search.appName,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.common.PageMobileVersionList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.appNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增编辑
        btnEditInfo(id){
            this.info.id = id
            this.BoxEditInfo.isVisible = true;
        },
        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(saleNumber){
            ElMessageBox.confirm('确定要删除该信息吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                let param = [saleNumber]
                this.$api.common.DeleteMobileVersion(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
                })
            }).catch(()=>{
                //取消
            })
            
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            ElMessageBox.confirm('确定要删除选中信息吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                this.$api.common.DeleteMobileVersion(this.info.ids).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                        this.GetDataList();
                        this.info.ids = [];
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                //取消
            })        
        },
        //重置按钮
        btnResetSearch(){
            this.info.search.appName='';
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList();
        }, 

    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style>

</style>