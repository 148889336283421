<template>
  <div>
    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">
            
            <el-form-item label="差额单号">
                <el-input v-model="info.search.billNumber" class="search-150"></el-input>
            </el-form-item>

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="大区">
                <el-select v-model="info.search.areaNumberList" multiple collapse-tags collapse-tags-tooltip class="search-200">
                    <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-200">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="状态">
                <el-select v-model="info.search.state" class="search-200">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="新建单"></el-option>
                    <el-option :value="2" label="已驳回"></el-option>
                    <el-option :value="3" label="已审核"></el-option>
                    <el-option :value="10" label="已关单"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="业务标识">
                <el-select v-model="info.search.billFlag" class="search-200">
                    <el-option label="全部" :value="-1" />
                    <el-option label="未生成" :value="0" />
                    <el-option label="已生成" :value="1" />
                </el-select>
            </el-form-item>

            <el-form-item label="记账日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnDifferenceAdd')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
                <el-popconfirm v-if="CheckUserButtonAuth('BtnDifferenceDelete')" 
                    title="确认要删除吗?" 
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
                <el-popconfirm v-if="CheckUserButtonAuth('BtnDifferenceAddBusiness')" 
                    title="确认要创建业务单?"
                    @confirm="btnCreateBusiness">
                    <template #reference>
                        <el-button class="iconfont icon-dingdan" type="warning" plain>生成业务单</el-button>
                    </template>
            </el-popconfirm>    
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                        
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>
            </el-col>
        </el-row>

        <!-- 数据列表 -->
        <el-table :data="dataList" border stripe show-summary :height="GetDataHeight" @row-dblclick="ClickRowData" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="id" type="selection" width="55"></el-table-column>
            <el-table-column label="单据编号" width="170">
                <template v-slot="scope">
                    <span>{{scope.row.billNumber}}</span>
                </template>
            </el-table-column>
            
            <el-table-column prop="departmentName" label="部门" min-width="110">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.departmentName"
                        placement="top">
                        <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
            </el-table-column>

            <el-table-column label="交易者类型" width="100">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                    <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                    <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                </template>
            </el-table-column>            
            
            <el-table-column label="交易者" min-width="120">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.tradeName"
                        placement="top">
                        <div class="cellOverFlow">{{scope.row.tradeName}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column label="原始单类型" min-width="100">
                <template v-slot="scope">
                    <span v-if="scope.row.originType==1">采购单</span>
                    <span v-if="scope.row.originType==2">销售单</span>
                    <span v-if="scope.row.originType==14">退供单</span>
                    <span v-if="scope.row.originType==15">退货单</span>
                </template>
            </el-table-column>
            <el-table-column label="差额说明" min-width="90">
                <template v-slot="scope">
                    <span>{{scope.row.content}}</span>
                </template>
            </el-table-column>
            
            <el-table-column prop="totalPrice" label="总金额" width="90"></el-table-column>
            <el-table-column label="状态" width="90">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.state ==1">新创建</el-tag>
                    <el-tag v-if="scope.row.state ==2" type="danger">已驳回</el-tag>
                    <el-tag v-if="scope.row.state ==3" type="success">已审核</el-tag>
                    <el-tag v-if="scope.row.state ==10">已关单</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="业务单标识" width="100">
                <template #default="scope">
                    <el-tag v-if="scope.row.billFlag == 0" type="danger">未生成</el-tag>
                    <el-tag v-if="scope.row.billFlag == 1" type="info">已生成</el-tag>
                </template>
            </el-table-column>  
            <el-table-column label="记账时间" width="110">
                <template v-slot="scope">
                  <span>{{DateFormat(scope.row.billTime)}}</span> 
                </template>
            </el-table-column>
            <el-table-column label="备注" min-width="100">
                <template v-slot="scope">
                    <span>{{scope.row.remark}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="130" fixed="right">
              <template v-slot="scope">
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.billNumber)">详情</el-button>
                <el-popconfirm v-if="CheckUserButtonAuth('BtnDifferenceDelete')" 
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.billNumber)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
              </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[20, 50, 100, 200]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
            >
            </el-pagination>
        </el-container>

    </div>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="65%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditDifference :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditDifference>
    </el-dialog>
    

  </div>
</template>

<script>
import EditDifference from '@/views/Bill/Difference/EditDifference.vue'

import UserDptList from '@/utils/mixins/UserDptList.js'
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import UserAreaList from '@/utils/mixins/UserAreaList.js'  //用户关联大区列表
import { ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList,AllTradeList,UserAreaList],
    components:{
        EditDifference
    },
    data(){
        return{
            dataList:[],  //Table数据
            info:{                
                page:1,
                size:20,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    billNumber:'',  //差额单号
                    tradeNumber:'',
                    selectDepartmentList:[],
                    areaNumberList:[],   //大区列表
                    datePoint:'',   //截止日期
                    state:0,
                    billFlag:-1,  //业务单标识
                },
                tradeList:[],  //交易者列表
            },            
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'新增差额单'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-290
        }
    },
    methods:{

        //加载数据列表
        GetDataList(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }
            let param = {
                billNumber:this.info.search.billNumber,
                tradeNumber:this.info.search.tradeNumber,
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                state:this.info.search.state,
                billFlag:this.info.search.billFlag,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.erp.PageDifferenceList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.billNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增编辑信息
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "新增差额单"
            }else{
                this.BoxEditInfo.title = "编辑差额单"
            }  
            this.BoxEditInfo.isVisible = true;
        },
        //双击某一行时(查看销售单详情)
        ClickRowData(row){
            this.BoxEditInfo.title = "编辑差额单"
            this.info.id = row.billNumber
            this.BoxEditInfo.isVisible = true;
        },

        //删除按钮
        btnDeleteInfo(billNumber){
            let param = [billNumber]
            this.$api.erp.DeleteDifference(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.erp.DeleteDifference(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                    this.info.ids = [];
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.billNumber="";
            this.info.search.tradeNumber = "";
            this.info.search.selectDepartmentList = [];
            this.info.search.areaNumberList = [];  //大区列表
            this.info.search.datePoint = '';
            this.info.search.state=0;
            this.info.search.billFlag=-1;  //业务单标识
            this.info.page = 1;
            this.info.ids = [];  //编号数组
            this.GetDataList()
        },  

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //差额单生成业务单
        btnCreateBusiness(){
            if(this.info.ids.length<1){
                ElMessage({type:'error',message:'请选择要生成业务单的单据'})
                return false
            }

            this.$api.erp.CreateBusiness(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //刷新页面
                    this.GetDataList()
                    this.info.ids = [];
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

    },
    mounted(){
        this.GetDataList()  //获取数据列表
    }
}
</script>

<style>

</style>