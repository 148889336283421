/**
 * 获取用户授权下的大区列表
 * ===下拉列表专用===
 */

const UserAreaList = {
    data(){
        return{
            userAreaList:[],  //用于关联的大区列表
        }
    },
    methods:{
        //加载用户关联的大区列表
        SelectUserAreaList(){
            this.$api.system.SelectUserAreaList().then(res=>{
                if(res.data.status === 200){
                    this.userAreaList = res.data.data;
                }else{
                    this.userAreaList = [];
                }
            })
        }
    },
    mounted(){
        this.SelectUserAreaList();
    },
}

export default UserAreaList;