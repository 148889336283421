<template>
  <div>

    <el-divider></el-divider>
      
      <el-form :inline="true"
        v-model="info"
        label-width="80px"
        label-position="right">
            <el-row>

                <el-col :span="24">
                    <el-form-item label="类型名称">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>类型名称</span>
                        </template>
                        <el-input v-model="info.typeName" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="计算方式">
                        <el-select v-model="info.way" class="search-200">
                            <el-option label="减" :value="0"></el-option>
                            <el-option label="加" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                
                <el-col :span="24">
                    <el-form-item label="排序">
                        <el-input-number v-model.number="info.sort" :min="0" :max="100" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="显示标识">
                        <el-select v-model="info.isShow" class="search-200">
                            <el-option label="隐藏" :value="0"></el-option>
                            <el-option label="显示" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

            </el-row>
      </el-form>

      <!-- 功能按钮 -->
      <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
      </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    props:['editId'],
    data(){
        return{
            info:{
                typeId:0,
                typeName:'',
                way:0,
                sort:0,
                isShow:1,
                isDel:0,
            },
        }
    },
    methods:{
      //加载数据
      GetDataInfo(){
          if(this.editId!=0){
              let param = {
                  typeId:this.editId
              }
              this.$api.common.SingleFinanceType(param).then(res=>{
                  if(res.data.status === 200){
                      this.info = res.data.data;
                  }else{
                      ElMessage.error(res.data.msg);
                  }
              })
          }
          else
          {
              this.btnClearData();
          }
      },

      //保存信息 -type:1:保存并关闭 2:保存并新增
      btnSaveData(type){
        this.$api.common.SaveFinanceType(this.info).then(res=>{
          if(res.data.status === 200){
            ElMessage.success(res.data.msg);
            //类型
            if(type==1){
              this.btnClose();
            }else{
              this.btnClearData();
            }            
          }
          else{
            ElMessage.error(res.data.msg)
          }
        }).catch(e=>{
          console.log(e)
        })
        
      },

      //清空实体
      btnClearData(){
        this.info = {
            typeId:0,
            typeName:'',
            way:0,
            sort:0,
            isShow:1,
            isDel:0,
        }
      },

      //关闭按钮
      btnClose(){
          this.$emit('closeBox')  //关闭弹出框
      },

    },
    mounted(){
      this.GetDataInfo();
    }

}
</script>

<style>

</style>