/**
 * 获取交易者列表(包含供应商+客户+员工) - 下拉列表
 * 部分数据:只显示isShow=1的数据
 */

const PartTradeList = {
    data(){
        return{
            partTradeList:[],  //交易者列表
        }
    },
    methods:{
        //加载交易者列表(包含供应商+客户+员工)
        SelectPartTradeList(){
            this.$api.erp.SelectPartTradeList().then(res=>{
                if(res.data.status === 200){
                    this.partTradeList = res.data.data;
                }else{
                    console.log(res.data.msg);
                }
            })
        },
    },
    mounted(){
        this.SelectPartTradeList();
    }
}

export default PartTradeList;