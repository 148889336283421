<template>
  <div>
    <el-divider></el-divider>
      <el-form :inline="true"
      v-model="info"
      label-width="80px"
      label-position="right">
          <el-row>

              <el-col :span="12">
                  <el-form-item label="分组说明">
                      <el-input v-model="info.groupDesc"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="分组名">
                      <el-input v-model="info.groupName"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="数据名">
                      <el-input v-model="info.typeName"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="数据值">
                      <el-input v-model.number="info.typeValue"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="排序">
                      <el-input v-model.number="info.sort"></el-input>
                  </el-form-item>
              </el-col>

              

              <el-col :span="12">
                  <el-form-item label="备注">
                      <el-input v-model="info.remark"></el-input>
                  </el-form-item>
              </el-col>

          </el-row>


      </el-form>
  </div>
</template>

<script>
export default {
    name:'EditDict',
    props:['editId'],
    data(){
      return{
        info:{
          id:0,
          groupDesc:'',
          groupName:'',
          typeName:'',
          typeValue:0,
          sort:0,
          remark:''
        }
      }
    },
    methods:{
      //加载数据
      GetDataInfo(){
        if(this.editId!=0){
          let param = {
            id:this.editId
          }
          this.$api.common.SingleDict(param).then(res=>{
            if(res.data.status === 200){
              this.info = res.data.data;
            }
          })

        }
        else
        {
          this.info = {
            id:0,
            groupDesc:'',
            groupName:'',
            typeName:'',
            typeValue:0,
            sort:0,
            remark:''
          }
        }
      }
    }
}
</script>

<style>

</style>