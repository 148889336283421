<template>
  <div>

    <!-- 系统信息 -->
    <el-form :inline="true"
    v-model="groupInfo.info"
    label-width="120px"
    label-position="right">
        <el-row v-if="groupInfo.info">
            
            <el-col :span="24">
                <el-form-item label="组名称">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>组名称</span>
                    </template>
                    <el-input v-model="groupInfo.info.groupName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="排序">
                    <el-input-number v-model="groupInfo.info.sort" :min="0" :max="100" class="search-200"></el-input-number>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="组成员">
                    <el-select v-model="groupInfo.userList" multiple collapse-tags collapse-tags-tooltip class="search-200" >
                        <el-option v-for="item in selectUserList" :key="item.userNumber" :label="item.userName" :value="item.userNumber"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>   

        </el-row>
    </el-form>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import AllUserList from '@/utils/mixins/AllUserList.js'
export default {
    mixins:[AllUserList],
    computed:{
        ...mapState('Oa',['groupInfo'])
    },

}
</script>

<style>

</style>