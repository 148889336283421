<template>
  <div>

    <!-- 系统信息 -->
    <el-form :inline="true"
    v-model="systemInfo.info"
    label-width="120px"
    label-position="right">
        <el-row v-if="systemInfo.info">
            
            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="系统名称">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>系统名称</span>
                    </template>
                    <el-input v-model="systemInfo.info.systemName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="企业名称">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>企业名称</span>
                    </template>
                    <el-input v-model="systemInfo.info.companyName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="租户类型">
                    <el-select v-model="systemInfo.info.systemType" class="search-200">
                        <el-option v-for="typeInfo in DictList" :key="typeInfo.id" :label="typeInfo.typeName" :value="typeInfo.typeValue"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="法人">
                    <el-input v-model="systemInfo.info.legalPerson" minlength="6" maxlength="50" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="系统域名">
                <el-input v-model="systemInfo.info.domainName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="联系人">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>联系人</span>
                    </template>
                    <el-input v-model="systemInfo.info.linkMan" minlength="6" maxlength="50" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="联系电话">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>联系电话</span>
                    </template>
                    <el-input v-model="systemInfo.info.linkPhone" minlength="6" maxlength="50" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="省市区">
                    <el-cascader :options="areaList" clearable v-model="systemInfo.info.selectArea" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="地址">
                    <el-input v-model="systemInfo.info.address" minlength="6" maxlength="50" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="识别码">
                    <el-input v-model="systemInfo.info.certNumber" maxlength="20" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="状态">
                    <el-select v-model="systemInfo.info.state" class="search-200">
                        <el-option label="正常" :value="1"></el-option>
                        <el-option label="关闭" :value="2"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="注册资金(万)">
                    <el-input-number v-model="systemInfo.info.registerCapital" :precision="2" :min="0" class="search-200"></el-input-number>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="注册日期">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>注册日期</span>
                    </template>
                    <el-date-picker v-model="systemInfo.info.registerDate" type="date" placeholder="注册日期" value-format="YYYY-MM-DD" style="width:200px"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="有效期">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>有效期</span>
                    </template>
                    <el-date-picker v-model="systemInfo.info.termTime" type="datetime" placeholder="有效期" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="备注">
                    <el-input v-model="systemInfo.info.remark" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="营业执照">
                    <!-- 上传图片 -->
                    <el-image
                        class="ImgTheme"
                        v-if="systemInfo.info.license"
                        :src="systemInfo.info.license"
                        :preview-src-list="[systemInfo.info.license]"
                        :hide-on-click-modal="true"
                        fit="fill">
                    </el-image>

                    <el-upload
                    :action="GetFileUrl2('Company')"
                    :headers="GetHeader"
                    :show-file-list="false"
                    :before-upload="BeforeUpload"
                    :on-success="UploadSuccess">
                        <el-tag class="UploadImg">上传图片</el-tag>
                    </el-upload>

                </el-form-item>
            </el-col>


        </el-row>
    </el-form>

  </div>
</template>

<script>
import Authorize from '@/utils/authorize.js'
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
export default {

    computed:{
        ...mapState('System',['systemInfo']),
        //添加Token - 重要
        GetHeader(){
            return {
                "Authorization":"Bearer "+ Authorize.getToken()
            }
        },
    },
    data(){
        return{
            DictList:[],  //字典列表
            areaList:[],   //地址列表
        }
    },
    methods:{

        //上传图片之前操作
        BeforeUpload(file){
            const fileType = file.type;
            const fileSize = file.size;
            
            if(fileType !== 'image/jpeg' && fileType !== 'image/png'){
                ElMessage.error('上传图片格式:jpeg|png')
                return false;
            }
            //文件大小
            if(fileSize/1024/1024 > 3){
                ElMessage.error('上传文件大小不能超过3Mb')
                return false;
            }

        },
        //上传完成
        UploadSuccess(res){
            if(res.status === 200){
                this.systemInfo.info.license = res.data
            }
        },

        //获取省市区列表
        GetAreaList(){
            this.$api.common.SelectTreeAreaList2().then(res=>{
                if(res.data.status === 200){
                    this.areaList = res.data.data;
                }
            })
        },

        //获取租户类型字典列表
        GetSelectDict(){
            let param = {
                groupName:'SystemType',
            }
            this.$api.common.SelectDict(param).then(res=>{
                if(res.data.status === 200){
                    this.DictList = res.data.data;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

    },
    mounted(){
        this.GetSelectDict();  
        this.GetAreaList();
    }
}
</script>

<style scoped>
    .ImgTheme{height: 80px;width: 80px;border: 1px solid #ccc;padding: 5px;}
    .UploadImg{margin: 5px;}
</style>