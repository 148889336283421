<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row v-if="info">

            <el-col :span="24">
                <el-form-item label="班次名称">
                    <el-input v-model="info.className" maxlength="50" placeholder="常班、夜班" style="width:250px"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="工作时间">
                    <el-time-picker v-model="info.workTime"
                    is-range
                    range-separator="至"
                    start-placeholder="上班时间"
                    end-placeholder="下班时间"
                    placeholder="选择时间范围" style="width:230px">
                    </el-time-picker>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="排序">
                    <el-input-number v-model="info.sort" min="0" max="100" style="width:250px"></el-input-number>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import moment from 'moment'
export default {
    props:['editId'],
    data(){
        return{
            info:{
                id:0,
                systemNumber:'',
                className:'',
                workTime:[new Date(2022,10,8,9,0),new Date(2022,10,8,18,0)],
                startTime:'',
                entTime:'',
                sort:0
            },
            classId:this.editId,  //当前编号
        }
    },
    methods:{

        //获取数据
        GetDataInfo(){
            if(this.classId !=0){
                    let param = {
                    id:this.classId
                }
                this.$api.oa.SingleRuleClasses(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                        if(this.info.startTime!='' && this.info.entTime!=''){
                            this.info.workTime=[new Date("1900-1-1 "+this.info.startTime),new Date("1900-1-1 "+this.info.endTime)]
                        }
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
                      
        },

        //保存
        btnSaveData(){
            if(this.info.workTime==null || this.info.workTime==''){
                ElMessage.error('请输入工作时间')
                return false;
            }
            this.info.startTime = moment(this.info.workTime[0]).format("HH:mm:ss");
            this.info.endTime = moment(this.info.workTime[1]).format("HH:mm:ss");

            this.$api.oa.SaveRuleClasses(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //清空数据
        btnClearData(){
            this.info={
                id:0,
                systemNumber:'',
                className:'',
                workTime:[new Date(2022,10,8,9,0),new Date(2022,10,8,18,0)],
                startTime:'',
                entTime:'',
                sort:0
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style>

</style>