<template>
  <div>
    <!-- 编辑合同单页 -->
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName">
        <el-tab-pane label="合同信息" name="tabInfo">
            <TabInfo ref="tabContract"></TabInfo>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabRecord></TabRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabState></TabState>
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'

import TabInfo from '@/views/Oa/Contract/TabInfo.vue'
import TabRecord from '@/views/Oa/Contract/TabRecord.vue'
import TabState from '@/views/Oa/Contract/TabState.vue'
export default {
  name:'EditContract',
  props:['editId','dataList'],
  components:{
    TabInfo,
    TabRecord,
    TabState
  },
  data(){
    return{
      info:{
          activeName:'tabInfo',
          contractNumber:this.editId,
      }
    }
  },
  computed:{
    ...mapState('Oa',['contractInfo'])
  },
  methods:{
      ...mapMutations('Oa',['SetContractInfo']),

      //加载数据信息
      GetDataInfo(){
        if(this.info.contractNumber !=0){
          let param = {
            contractNumber:this.info.contractNumber
          }
          this.$api.oa.SingleContractAllInfo(param).then(res=>{
            if(res.data.status === 200){
              this.SetContractInfo(res.data.data)
              //组装时间范围
              this.contractInfo.info.datePoint = [this.contractInfo.info.startDate,this.contractInfo.info.endDate];
            }else{
              this.btnClearData();
            }
            this.$refs.tabContract.SelectTradeList();  //调用子组件方法
          })
        }else{          
          this.btnClearData();
          this.$refs.tabContract.SelectTradeList();  //调用子组件方法
        }
      },

      //保存合同
      btnSaveData(){
        if(this.contractInfo.info.datePoint == null || this.contractInfo.info.datePoint==''){
            ElMessage.error('请选择合同日期范围');
            return false;
        }
        this.contractInfo.info.startDate = this.DateFormat(this.contractInfo.info.datePoint[0]);
        this.contractInfo.info.endDate = this.DateFormat(this.contractInfo.info.datePoint[1]);
        
        this.$api.oa.SaveContractAllInfo(this.contractInfo).then(res=>{
          if(res.data.status === 200){
            ElMessage.success(res.data.msg);
            this.btnClose();
          }else{
            ElMessage.error(res.data.msg);
          }
        })
      },

      //关闭按钮
      btnClose(){
          this.$emit('closeBox')  //关闭弹出框
      },

      //清理数据实体
      btnClearData(){
        var newInfo = {
          info:{
            systemNumber:'',
            contractNumber:'',
            tradeType:0,
            tradeNumber:'',
            tradeName:'',
            title:'',
            state:1,
            fileUrl:'',
            datePoint:[moment(new Date()).format("YYYY-MM-DD"),moment(new Date()).format("YYYY-MM-DD")],
            startDate:moment(new Date()).format("YYYY-MM-DD"),
            endDate:moment(new Date()).format("YYYY-MM-DD"),
            createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            creator:'',
            isDel:0,
          },
          recordList:[],
          stateList:[]
        }
        this.SetContractInfo(newInfo);
      },

      //上一条
      btnPrevious(){
          let currentIndex = this.dataList.findIndex((item)=>{
              return item.contractNumber === this.info.contractNumber
          })
          let prevIndex = currentIndex -1;
          let prevInfo = this.dataList[prevIndex];
          if(prevInfo==null){
              ElMessage.warning("没有上一条了");
              return false;
          }
          else{
              this.info.contractNumber = prevInfo.contractNumber
              this.GetDataInfo()
          }
          
      },
      //下一条
      btnNext(){
          let currentIndex = this.dataList.findIndex((item)=>{
              return item.contractNumber === this.info.contractNumber
          })
          let prevIndex = currentIndex + 1;
          let prevInfo = this.dataList[prevIndex];
          if(prevInfo==null){
              ElMessage.warning("没有下一条了");
              return false;
          }
          else{
              this.info.contractNumber = prevInfo.contractNumber
              this.GetDataInfo()
          }
      },

  },
  mounted(){
    this.GetDataInfo()
  }
}
</script>

<style>
</style>