
//溯源列表-发码企业
import ListApply from '@/views/Trace/Apply/ListApply.vue'

export default[
    {
        //溯源列表-发码企业
        name:'ListApply',
        path:'/listapply',
        component:ListApply
    },
]

