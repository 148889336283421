/**
 * Shop 模块相关数据
 */

var actions = {

}

var mutations = {
    //设置频道信息
    SetChannelInfo(state,value){
        state.channelInfo = value
    },
    //设置订单信息
    SetOrderInfo(state,value){
        state.orderInfo = value
    },
    //会员信息
    SetMemberInfo(state,value){
        state.memberInfo = value
    },
    //店铺分类信息
    SetShopCategoryInfo(state,value){
        state.shopCategoryInfo = value
    },

}

var state = {
    channelInfo:{},   //频道信息
    orderInfo:{},     //订单信息
    memberInfo:{},    //会员信息
    shopCategoryInfo:{},   //店铺分类信息
}

export default{
    namespaced:true,
    actions,
    mutations,
    state
}