<template>
    <!-- 这里不想放编辑器，放图片列表和视频列表 -->
  <div style="border: 1px solid #ccc">
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
      />
      <Editor v-if="spuInfo.info"
        style="height: 500px; overflow-y: hidden;"
        v-model="spuInfo.info.spuDesc"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
      />
    </div>
</template>

<script>
 import axios from '@/utils/http' 
import Authorize from '@/utils/authorize.js'
import base from '@/api/base.js'  //接口地址
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
export default {
    components:{
        Editor,
        Toolbar
    },
    data(){
      return{
        editor: null,  //实例
            toolbarConfig: {
                excludeKeys:['emotion']
             },    //工具栏配置 去掉表情菜单
            editorConfig: { 
                placeholder: '请输入内容...',
                MENU_CONF:{
                    //自定义上传图片
                    uploadImage:{
                        headers: {
                            Authorization: 'Bearer '+Authorize.getToken(),
                        },
                        maxNumberOfFiles: 5,
                        onError(file,err,res){
                            ElMessage.error(err)
                            console.log(file,err,res);
                        },
                        async customUpload(file,insertFn){
                            const form = new FormData();
                            form.append('file',file);
                            axios({
                                url:`${base.base}/File/SaveImage?module=GoodsDesc`,
                                method:'post',
                                data:form
                            }).then(res=>{
                                if(res.data.status === 200){
                                    insertFn(res.data.data,'','')
                                }else{
                                    ElMessage.error('上传失败');
                                }
                                
                            })
                        },
                    },
                    //自定义上传视频
                    uploadVideo:{
                        headers: {
                            Authorization: 'Bearer '+Authorize.getToken(),
                        },
                        maxFileSize: 5 * 1024 * 1024, // 5M
                        maxNumberOfFiles: 1,
                        async customUpload(file,insertFn){
                            const form = new FormData();
                            form.append('file',file);
                            axios({
                                url:`${base.base}/File/SaveImage?module=GoodsDesc`,
                                method:'post',
                                data:form
                            }).then(res=>{
                                if(res.data.status === 200){
                                    insertFn(res.data.data,'','')
                                }else{
                                    ElMessage.error('上传失败');
                                }
                                
                            })
                        },
                    }
                },
                
             },
            mode: 'default', // or 'simple'  功能菜单配置 

      }
    },
    computed:{
        ...mapState('Erp',['spuInfo'])
    },
    methods:{
      onCreated(editor) {
          this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      },

    },
    mounted() {
    },
    beforeUnmount() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }

}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style>

</style>