<template>
  <div>
    <!-- 溯源码申请列表 -->
    
    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>    
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe style="width: 100%" @select="btnCheckbox">
        <el-table-column prop="id" type="selection" width="50" />  
        <el-table-column prop="contractNumber" label="申请单号" />
        <el-table-column prop="title" label="起始码段" />
        <el-table-column prop="title" label="结束码段" />
        <el-table-column prop="receiveCompany" label="状态" />
        <el-table-column prop="title" label="剩余数量" />
        <el-table-column label="创建日期">
            <template #default="scope">
            <span>{{DateFormat(scope.row.createDate)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="200" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.contractNumber)">详情</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.contractNumber)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
                </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[10, 50, 100, 200]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
            >
            </el-pagination>
        </el-container>

    </div>


  </div>
</template>

<script>
export default {
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:10,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    selectDepartmentList:[],
                    datePoint:'',
                    state:0,
                }
            }
        }
    },
    methods:{

    }
}
</script>

<style>

</style>