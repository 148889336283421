<template>
  <div>
    <el-divider></el-divider>
    
    <TabInfo ref="spuInfo"></TabInfo>
    <el-divider>换算单位</el-divider>
    <TabSkuUnit></TabSkuUnit>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
      <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
      <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
      <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import TabInfo from '@/views/Goods/Sku/TabInfo.vue'
import TabSkuUnit from '@/views/Goods/Sku/TabSkuUnit.vue'
import { mapMutations, mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    name:'EditSku',
    props:['editId'],
    components:{
      TabInfo,
      TabSkuUnit,
    },
    data(){
      return{
        info:{
          activeName:'tabInfo',
        }
      }
    },
    computed:{
        ...mapState('Erp',['goodsInfo'])
    },
    methods:{
      ...mapMutations('Erp',['SetGoodsInfo']),
      //加载数据
      GetDataInfo(){
        if(this.editId !=0){
          let param = {
            skuNumber:this.editId
          }
          this.$api.goods.SingleGoodsAllInfo(param).then(res=>{
              if(res.data.status === 200){
                  this.SetGoodsInfo(res.data.data)
              }else{
                this.btnClearData();
              }
              this.$refs.spuInfo.ChangeCategory(); //加载产品列表
          })
        }
        else
        {
          this.btnClearData();
          this.$refs.spuInfo.ChangeCategory(); //加载产品列表
        }
        
      },

      //保存信息 -type:1:保存并关闭 2:保存并新增
      btnSaveData(type){ 
        this.$api.goods.SaveGoodsInfo(this.goodsInfo).then(res=>{
          if(res.data.status === 200){
            ElMessage.success(res.data.msg);
            //类型
            if(type==1){
              this.btnClose();
            }else{
              this.btnClearData();
            }
          }else{
            ElMessage.error(res.data.msg)
          }
        })
        
      },  

      //清空实体
      btnClearData(){
        var allInfo = {
          info:{
            id:0,
            cid:0,
            cidName:'',
            brandId:0,
            brandName:'',
            spuNumber:'',
            spuName:'',
            skuNumber:'',
            skuName:'',
            spuType:1,
            minUnitId:0,
            minUnitName:'',
            defUnitId:0,
            defUnitName:'',
            isShow:1,  //显示标识
          },
          skuUnitList:[],            
        }
        this.SetGoodsInfo(allInfo)
      },

      //关闭按钮
      btnClose(){
          this.$emit('closeBox')  //关闭弹出框
      },

    },
    mounted(){
        this.GetDataInfo() 
    }


}
</script>

<style>

</style>