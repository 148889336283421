<template>
  <div>
      <el-divider></el-divider>

      <el-form 
        v-model="info"
        label-width="80px"
        label-position="right">
            <el-row v-if="info">

                <el-col :span="8">
                    <el-form-item label="评价主题">
                        <el-select v-model="info.themeNumber" @change="SelectThemeChanged">
                          <el-option v-for="item in selectThemeList" :key="item.themeNumber" :label="item.themeName" :value="item.themeNumber"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="所属指标">
                        <el-cascader :options="SelectTagList" clearable v-model="info.selectTags"></el-cascader>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="指标评分">
                        <el-input-number v-model="info.tagScore" :precision="2" :min="0" :max="100"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="指标说明">
                        <el-input :rows="4" type="textarea" v-model="info.tagExplain"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
     </el-form>

    

  </div>
</template>

<script>
export default {
    name:'EditEvaluateTag',
    props:['editId','themeNumber'],
    data(){
      return{
        info:{
          sid:0,
          systemNumber:'',
          themeNumber:'',
          selectTags:[],   //指标列表
          tagId1:0,
          tagId2:0,
          tagId3:0,
          tagExplain:'',
          tagScore:0,
        },
        selectThemeList:[],  //指标主题列表-下拉列表
        SelectTagList:[], //指标 - 下拉列表
      }
    },
    methods:{
      //加载主题下拉列表
      SelectEvaluateTheme(){
        this.$api.service.SelectEvaluateTheme().then(res=>{
          if(res.data.status === 200){
            this.selectThemeList = res.data.data
          }
        })
      },
      //主题选择改变后
      SelectThemeChanged(option){
        this.SelectEvaluateTagList(option)
      },
      //加载指标下拉列表
      SelectEvaluateTagList(number){
        let param = {
          themeNumber:number
        }
        this.$api.service.SelectEvaluateTagList(param).then(res=>{
          if(res.data.status === 200){
            this.SelectTagList = res.data.data
          }
        })
      },
      //加载数据
      GetDataInfo(){
        if(this.editId !=0){
          let param = {
            sid:this.editId
          }
          this.$api.service.SingleTagScore(param).then(res=>{
            if(res.data.status === 200){
              this.info = res.data.data              
            }
          }).catch(e=>{
            console.log(e.message)
          })
        }
        else
        {
          this.info = {
            sid:0,
            systemNumber:'',
            themeNumber:'',
            selectTags:[],   //指标列表
            tagId1:0,
            tagId2:0,
            tagId3:0,
            tagName:'',
            tagExplain:'',
            tagScore:0,
          }
        }
      }
    },
    mounted(){
      this.SelectEvaluateTheme()   //获取主题下拉列表
      this.SelectEvaluateTagList(this.themeNumber)  //加载指标下拉列表
      this.GetDataInfo()  //加载数据
      
    }
}
</script>

<style>

</style>