<template>
  <div>
    
    <el-form :inline="true"
    v-model="memberInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="memberInfo.info">

            <el-col :span="8">
                <el-form-item label="会员编号">
                    <el-input v-model="memberInfo.info.memberNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="会员昵称">
                    <el-input v-model="memberInfo.info.memberName" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="真实姓名">
                    <el-input v-model="memberInfo.info.realName" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="性别">
                    <el-select v-model="memberInfo.info.sex" disabled class="search-200">
                          <el-option label="男" :value="1" />
                          <el-option label="女" :value="2" />
                        </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="手机号码">
                    <el-input v-model="memberInfo.info.phone" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="积分">
                    <el-input v-model="memberInfo.info.score" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="实名标识">
                    <el-select v-model="memberInfo.info.isReal" disabled class="search-200">
                          <el-option label="否" :value="0" />
                          <el-option label="已实名" :value="1" />
                        </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="VIP标识">
                    <el-select v-model="memberInfo.info.isVip" disabled class="search-200">
                          <el-option label="否" :value="0" />
                          <el-option label="VIP" :value="1" />
                        </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="注册时间">
                    <el-input v-model="memberInfo.info.createTime" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="VIP结束时间">
                    <el-input v-model="memberInfo.info.vipEndTime" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="头像">
                  <el-image
                        style="width: 70px; height: 70px"
                        :src="memberInfo.info.headImg"
                        :preview-src-list="[memberInfo.info.headImg]"
                        :hide-on-click-modal="true"
                        fit="cover">
                    </el-image>
                </el-form-item>                 
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Shop',['memberInfo'])
    },

}
</script>

<style>

</style>