<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <el-scrollbar height="500px">
        <!-- 订单主信息 -->
        <TabOrder></TabOrder>
        <!-- 订单明细列表 -->
        <el-divider>产品明细</el-divider>
        <TabOrderDetail></TabOrderDetail>
        <!-- 订单支付信息 -->
        <el-divider>支付信息</el-divider>
        <TabOrderPay></TabOrderPay>
        <!-- 订单收货地址 -->
        <el-divider>收货地址</el-divider>
        <TabOrderAddress></TabOrderAddress>
        <!-- 订单状态列表 -->
        <el-divider>订单状态</el-divider>
        <TabOrderState></TabOrderState>
    </el-scrollbar>
    

    <!-- 功能按钮组 -->
    <div class="editFooter">
        <el-button class="iconfont icon-dayin" @click="BoxPrinter.isVisible=true">打印小票</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <el-dialog title="选择打印机"
    v-model="BoxPrinter.isVisible"
    width="40%">
        <BoxAllPrinter ref="refPrinter"></BoxAllPrinter>
        <template #footer>            
            <el-button  class="iconfont icon-dayin" type="primary" @click="btnPrintOrder">打印</el-button>
            <el-button @click="BoxPrinter.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>    

  </div>
</template>

<script>
import TabOrder from '@/views/Sales/Order/TabOrder.vue'
import TabOrderDetail from '@/views/Sales/Order/TabOrderDetail.vue'
import TabOrderPay from '@/views/Sales/Order/TabOrderPay.vue'
import TabOrderAddress from '@/views/Sales/Order/TabOrderAddress.vue'
import TabOrderState from '@/views/Sales/Order/TabOrderState.vue'

import BoxAllPrinter from '@/components/common/BoxAllPrinter.vue'
import { mapMutations, mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],
    components:{
        TabOrder,
        TabOrderDetail,
        TabOrderPay,
        TabOrderAddress,
        TabOrderState,
        BoxAllPrinter,
    },
    data(){
        return{
            info:{
                orderNumber:this.editId,  //当前零售单编号
            },
            //选择打印机-弹出框
            BoxPrinter:{
                isVisible:false
            },
        }
    },
    computed:{
        ...mapState('Shop',['orderInfo'])
    },
    methods:{
        ...mapMutations('Shop',['SetOrderInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.orderNumber !=0){
                let param = {
                    orderNumber:this.info.orderNumber
                }
                this.$api.shop.SingleOrderAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetOrderInfo(res.data.data)
                    }else{
                        this.btnClearData()
                    }
                })
            }else{
                this.btnClearData()
            }
        },        

        //清空实体
        btnClearData(){
            let newInfo = {
                info:{
                    id:0,
                    systemNumber:'',
                    orderNumber:'',
                    memberNumber:'',
                    state:1,
                    totalPrice:0,
                    realPrice:0,
                    expressPrice:0,
                    expressCompany:'',
                    expressNumber:'',
                    createTime:'',
                    remark:'',
                },   //订单主信息
                detailList:[],   //明细列表
                payList:[],   //支付列表
                stateList:[],   //状态列表
                addressInfo:{
                    id:0,
                    systemNumber:'',
                    orderNumber:'',
                    provinceCode:'',
                    provinceName:'',
                    cityCode:'',
                    cityName:'',
                    areaCode:'',
                    areaName:'',
                    address:'',
                    linkMan:'',
                    linkPhone:'',
                },  //地址信息
            }
            this.SetOrderInfo(newInfo);
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.orderNumber === this.info.orderNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.orderNumber = prevInfo.orderNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.orderNumber === this.info.orderNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.orderNumber = prevInfo.orderNumber
                this.GetDataInfo()
            }
        },

        
        //打印小票
        btnPrintOrder(){
            let printer = this.$refs.refPrinter.info; //选择好的打印信息
            if(printer.printerNumber==''){
                ElMessage.error("请选择打印机");
                return false;
            }

            let param = {
                orderNumber:this.info.orderNumber,   //订单编号
                printerNumber:printer.printerNumber,
                num:printer.num,   //打印数量
            }
            this.$api.shop.PrintOrderInfo(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.BoxPrinter.isVisible=false;
                }
                else{
                    ElMessage.error(res.data.msg);
                }
            })
        },
    },
    mounted(){
        this.GetDataInfo();
    }
}
</script>

<style>

</style>