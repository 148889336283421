<template>
  <div>
    <el-divider></el-divider>

    <!-- 分类主表 -->
    <TabCategory></TabCategory>
    <el-divider>分类所属产品</el-divider>
    <TabCategoryDetail></TabCategoryDetail>

    <!-- 功能按钮 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import TabCategory from '@/views/Shop/Category/TabCategory.vue'
import TabCategoryDetail from '@/views/Shop/Category/TabCategoryDetail.vue'

import { ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
export default {
    name:'EditShopCategory',
    props:['editId'],
    components:{
        TabCategory,
        TabCategoryDetail
    },
    computed:{
        ...mapState('Shop',['shopCategoryInfo'])
    },
    methods:{
        ...mapMutations('Shop',['SetShopCategoryInfo']),

        //加载数据
        GetDataInfo(){
             if(this.editId !=0){
                let param = {
                    categoryNumber:this.editId
                }
                this.$api.shop.SingleShopCategoryAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                        this.SetShopCategoryInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
             }else{
                this.btnClearData();
             }
        },

        //保存分类信息 -type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            this.$api.shop.SaveShopCategoryAllInfo(this.shopCategoryInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //类型
                    if(type==1){
                        this.btnClose()
                    }else{
                        this.btnClearData();
                        //重新加载店铺分类目录
                        this.GetTreeDataInfo();
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //清空实体
        btnClearData(){
            let newInfo = {
                info:{
                    cid:0,
                    categoryName:'',
                    pid:0,
                    sort:0
                },
                detailList:[],
            }
            this.SetShopCategoryInfo(newInfo);
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){        
        this.GetDataInfo();   //加载Table
    }
}
</script>

<style>

</style>