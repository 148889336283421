<template>
  <div>

    <!-- 凭证信息 -->
    <el-form :inline="false"
      v-model="proofInfo.info"
      label-width="80px"
      label-position="right">
          <el-row v-if="proofInfo.info">
              <el-col :span="12">
                  <el-form-item label="所属部门">
                      <template #label>
                          <span style="color:red;">*</span>
                          <span>所属部门</span>
                      </template>
                      <el-tree-select v-model="proofInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="凭证类型">
                    <template #label>
                    <span style="color:red;">*</span>
                    <span>凭证类型</span>
                </template>
                <el-tree-select v-model="proofInfo.info.typeId" :data="selectProofTypeList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="创建时间">
                      <el-input v-model="proofInfo.info.createTime" disabled class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="凭证说明">
                    <template #label>
                          <span style="color:red;">*</span>
                          <span>凭证说明</span>
                      </template>
                      <el-input v-model="proofInfo.info.title" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

          </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import { mapState } from 'vuex'
export default {
    mixins:[UserDptList],
    data(){
        return{
            selectProofTypeList:[],   //凭证类型列表
        }
    },
    computed:{
        ...mapState('Oa',['proofInfo'])
    },
    methods:{
        //获取凭证类型-目录树
        GetTreeProofTypeList(){
            this.$api.oa.SelectTreeProofTypeList().then(res=>{
                if(res.data.status === 200){
                    this.selectProofTypeList = res.data.data;
                }
            })
        },
    },
    mounted(){
        this.GetTreeProofTypeList();
    },

}
</script>

<style>

</style>