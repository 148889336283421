<template>
  <div>
    <el-divider></el-divider>
      
      <el-form :inline="true"
        v-model="info"
        label-width="80px"
        label-position="right">
            <el-row>

                <el-col :span="24">
                    <el-form-item label="大区编号">
                        <el-input v-model="info.areaNumber" class="search-200" disabled></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="大区名称">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>大区名称</span>
                        </template>
                        <el-input v-model="info.areaName" class="search-200"></el-input>
                    </el-form-item>
                </el-col>
                
                <el-col :span="24">
                    <el-form-item label="排序">
                        <el-input-number v-model.number="info.sort" class="search-200" :min="0" :max="100"></el-input-number>
                    </el-form-item>
                </el-col>

            </el-row>
      </el-form>

      <!-- 功能按钮 -->
      <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData()">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
      </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    props:['editId'],
    data(){
        return{
            info:{
                areaNumber:'',
                systemNumber:'',
                areaName:'',
                sort:0,
                isDel:0,
            }
        }
    },
    methods:{
        //加载数据
        GetDataInfo(){
            if(this.editId!=0){
                let param = {
                    areaNumber:this.editId,
                }
                this.$api.system.GetSystemArea(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存信息 
        btnSaveData(){
            this.$api.system.SaveSystemArea(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //清空实体
        btnClearData(){
            this.info = {
                areaNumber:'',
                systemNumber:'',
                areaName:'',
                sort:0,
                isDel:0,
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
    mounted(){
        this.GetDataInfo();
    },
}
</script>

<style>

</style>