<template>
  <div>
      <el-divider></el-divider>
      <el-form :inline="true"
        v-model="info"
        label-width="80px"
        label-position="right">
            <el-row>

                <el-col :span="24">
                    <el-form-item label="单位名称">
                        <el-input v-model="info.unitName" placeholder="斤、箱" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="排列顺序">
                        <el-input-number v-model.number="info.sort" :min="0" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

            </el-row>

        </el-form>
  </div>
</template>

<script>

export default {
    name:'EditUnit',
    props:['editId'],
    data(){
        return{
            info:{
                unitId:0,
                unitName:'',
                sort:0
            }
        }
    },
    methods:{
        //加载数据
        GetDataInfo(){
            if(this.editId!=0){
                let param = {
                    id:this.editId
                }
                this.$api.goods.SingleUnit(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }else{
                        this.info = {
                            unitId:0,
                            unitName:'',
                            sort:0
                        }
                    }
                })
            }
            else
            {
                this.info = {
                    unitId:0,
                    unitName:'',
                    sort:0
                }
            }
        }
    }

}
</script>

<style scoped>
    .el-divider{margin-top: 0px;}
</style>