<template>
  <div>
      <!-- 搜索功能 -->
      <!-- <el-row :style="showSearch.search_hidden">
          <el-col :span="24">
              <el-form :inline="true">

                  <el-form-item label="评价主题">
                    <el-select v-model="info.search.themeNumber" clearable >
                        <el-option v-for="item in selectThemeList" :key="item.themeNumber" :label="item.themeName" :value="item.themeNumber"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item>
                  <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                  <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                  </el-form-item>

              </el-form>
            </el-col>
      </el-row> -->

      <!-- 主内容区 -->
      <div>
          <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <!-- <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm> -->
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
              
            </el-col>
          </el-row>

          <!-- 列表 -->
          <el-table :data="info.dataList" border stripe style="width: 100%" @select="btnCheckbox" ref="table"> 
              <el-table-column prop="id" type="selection" width="50" />  
              <el-table-column prop="billNumber" label="编号" width="170" />
              <el-table-column prop="themeName" label="评价主题" />
              <el-table-column prop="companyName" label="申请企业" />
              <el-table-column prop="totalScore" label="评价得分" />
              <el-table-column prop="state" label="状态">
                  <template v-slot="scope">
                    <el-tag v-if="scope.row.state ==1" type="danger">未评价</el-tag>
                    <el-tag v-if="scope.row.state ==2">已评价</el-tag>
                  </template>
              </el-table-column>
              <el-table-column label="创建日期">
                <template v-slot="scope">
                    <span>{{DateFormat(scope.row.createTime)}}</span> 
                  </template>
              </el-table-column>
              <el-table-column label="有效期">
                <template v-slot="scope">
                    <span>{{DateFormat(scope.row.termTime)}}</span> 
                  </template>
              </el-table-column>
              
              <el-table-column label="操作" width="300" fixed="right">
              <template v-slot="scope">              
                  <!-- <el-button size="small" type="primary" @click="btnEditInfo(scope.row.billNumber)">详情</el-button>
                  <el-button size="small" type="info" @click="btnOpenRecordDetail(scope.row.billNumber)" >评价明细</el-button> -->
                  <el-button size="small" type="success" v-if="scope.row.state == 2" @click="btnOpenLookCert(scope.row.billNumber)">证书</el-button>
                  <!-- <el-popconfirm 
                  title="确认要删除吗?"
                  @confirm="btnDeleteInfo(scope.row.billNumber)">
                      <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                      </template>
                  </el-popconfirm> -->
              </template>
              </el-table-column>
          </el-table>

          <!-- 分页 -->
          <el-container class="page_one">
              <el-pagination
                  v-model:currentPage="info.page"
                  v-model:page-size="info.size"
                  :page-sizes="[10, 50, 100, 200]"
                  :background="true"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="info.total"
                  @current-change="btnPagechange"
                  @size-change="btnSizechange">
              </el-pagination>
          </el-container>

      </div>

      <!-- 证书 - 弹出框 -->
      <el-dialog :title="BoxCert.title"
      v-model="BoxCert.isVisible"
      width="40%"
      :destroy-on-close="true">
        <LookCert :billNumber="info.id"></LookCert>
      </el-dialog>

  </div>
</template>

<script>
import LookCert from '@/views/Service/Evaluate/Record/LookCert.vue'
export default {
    components:{
        LookCert
    },
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,   //页数
                size:10,  //单页记录数
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    themeNumber:'',   //主题编号
                }
            },
            //证书-弹出框
            BoxCert:{
              isVisible:false,
              title:'评价证书'
            },

        }
    },
    methods:{        
        //加载列表数据
        GetDataList(){
            let param = {
                themeNumber:this.info.search.themeNumber,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.service.ListMyRecord(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data
                    //分页
                    this.info.page = res.data.pageNumber
                    this.info.total = res.data.total
                }
            }).catch(e=>{
                console.log(e.message)
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.billNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        
        
        //查看证书 - 弹出框
        btnOpenLookCert(billNumber){
            this.info.id = billNumber;
            this.BoxCert.isVisible = true;
        }

    },
    mounted(){
        this.GetDataList()   //加载列表数据
    }
}
</script>

<style>

</style>