<template>
  <div>
    <!-- 编辑会计科目 -->
    <el-divider></el-divider>

    <el-form :inline="true"
        v-model="info"
        label-width="100px"
        label-position="right">
            <el-row>

                <el-col :span="12">
                    <el-form-item label="科目名称">
                      <el-input v-model="info.subName" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="科目编码">
                      <el-input v-model="info.subCode" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="归属科目" >
                        <el-tree-select v-model="info.pid" :data="selectSubjectList" check-strictly class="search-200"/>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="排列顺序">
                        <el-input-number v-model.number="info.sort" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="计算方式">
                        <el-select v-model.number="info.way" class="search-200">
                            <el-option :value="0" label="减"></el-option>
                            <el-option :value="1" label="加"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="是否显示">
                        <el-select v-model.number="info.isShow" class="search-200">
                            <el-option :value="0" label="否"></el-option>
                            <el-option :value="1" label="是"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="使用范围">
                      <el-input v-model="info.scope" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                
               
            </el-row>
      </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import SubjectList from '@/utils/mixins/SubjectList.js'   //会计科目-下拉列表
import { ElMessage } from 'element-plus'
export default {
    name:'EditSubject',
    props:['editId'],
    mixins:[SubjectList],  //会计科目-下拉列表
    data(){
        return{
            info:{
                subId:0,
                systemNumber:'',
                subName:'',
                subCode:'',
                pid:0,
                sort:0,
                way:1,
                scope:'',
                isShow:1
            }
        }
    },
    methods:{
        //加载数据
        GetDataInfo(){
            if(this.editId!=0){
                let param = {
                    subId:this.editId
                }
                this.$api.finance.SingleSubject(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }else{
                        console.log(res.data.msg);
                    }
                })
            }else{
                this.info = {
                    subId:0,
                    systemNumber:'',
                    subName:'',
                    subCode:'',
                    pid:0,
                    sort:0,
                    way:1,
                    scope:'',
                    isShow:1
                }
            }
        },

        //保存数据 type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            this.$api.finance.SaveSubject(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                    }
                    
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //清空数据 
        btnClearData(){
            this.info = {
                subId:0,
                systemNumber:'',
                subName:'',
                subCode:'',
                pid:0,
                sort:0,
                way:1,
                scope:'',
                isShow:1
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },


    },
    mounted(){
        this.GetDataInfo()  //
    }
}
</script>

<style>

</style>