<template>
  <div>
    <!-- 新增项目 -->
    <div class="skuBtnList" v-if="costInfo.info && costInfo.info.state <=2">
      <el-button class="iconfont icon-add" type="primary" plain @click="btnNewRow">新增</el-button>
    </div>

    <!-- 费用列表 -->
    <el-table :data="costInfo.detailList" border stripe show-summary style="width: 100%">
        <el-table-column label="项目名称">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.itemName"></el-input>
                <span v-else>{{scope.row.itemName}}</span>
            </template>
        </el-table-column>        
        <el-table-column label="单价(元)">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.unitPrice" :precision="2" size="small" @change="OnChangeNum"></el-input-number>
                <span v-else >{{scope.row.unitPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column label="数量">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.amount" :min="0" :precision="2" size="small" @change="OnChangeNum"></el-input-number>
                <span v-else >{{scope.row.amount}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="totalPrice" label="总价(元)" width="80">
            <template v-slot="scope">
                <span>{{scope.row.totalPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column label="备注">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.remark"></el-input>
                <span v-else >{{scope.row.remark}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="150" fixed="right">
        <template v-slot="scope" v-if="costInfo.info && (costInfo.info.state ==1 || costInfo.info.state == 3)">
            <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
            <el-popconfirm 
            title="确认要删除吗?"
            @confirm="DeleteRow(scope.row.costNumber,scope.row.id,scope.$index)">
                <template #reference>
                <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
        </template>
        </el-table-column>
    </el-table>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Erp',['costInfo'])
    },
    methods:{
        //新增
        btnNewRow(){
            let newRow = {
                id:0,
                systemNumber:'',
                costNumber:'',
                itemName:'',
                unitPrice:0,
                amount:1,
                totalPrice:this.myRound(0*1,2),
                remark:'',
                isEdit:1,
            }
            this.costInfo.detailList.push(newRow);
        },

        //编辑行
        EditRow(id){
            this.costInfo.detailList = this.costInfo.detailList.map(item=>{
                if(item.id === id){
                    item.isEdit = 1
                }
                return item
            })
        },

        //删除费用明细
        DeleteRow(costNumber, id, index){
            this.costInfo.detailList.splice(index,1)
            if(id !=0){
                let param = {
                    costNumber:costNumber,
                    id:id
                }
                this.$api.finance.DeleteCostDetail(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }
            //重新计算总价
            this.OnChangeNum();
        },

        //当明细数量、价格改变时触发
        OnChangeNum(){
            let newtotalPrice = 0;   //主表-总价
            this.costInfo.detailList = this.costInfo.detailList.map(item=>{
                item.totalPrice = this.myRound(item.unitPrice * item.amount,2)
                newtotalPrice += item.totalPrice
                return item
            })
            this.costInfo.info.totalPrice = this.myRound(newtotalPrice,2)  //更新主表-总价
        },


    }
}
</script>

<style>

</style>