<template>
  <div>
    <!-- 从图库选择图片 -->
    <el-divider></el-divider>

    <el-row>
        <el-col :span="4" v-for="(img,index) in info.dataList" :key="index">
            <el-image
                class="ItemImg"
                :src="img"
                :preview-src-list="[img]"
                :hide-on-click-modal="true"
                fit="cover">
            </el-image><br/>
            <input name="ChannelImg" class="ItemSel" type="radio" value="" @change="btnSelectItem(img)" />
        </el-col>
    </el-row>

    <div class="btnGroup">
        <el-popconfirm 
            title="确认要删除吗?"
            @confirm="DeleteFileList">
            <template #reference>
                <el-button class="iconfont icon-ashbin btnTk" type="danger" plain>删除</el-button>
            </template>
        </el-popconfirm>
        <el-upload
            :action="GetFileUrl2('Channel')"
            class="btnTk"
            :headers="GetHeader"
            :show-file-list="false"
            multiple
            :before-upload="BeforeUpload"
            :on-success="UploadSuccess"
            :on-error="UploadError">
            <el-button class="iconfont icon-add" type="primary" plain >上传新图</el-button>
        </el-upload>
    </div>    

  </div>
</template>

<script>
import Authorize from '@/utils/authorize.js'
import { ElMessage } from 'element-plus'
export default {
    data(){
        return{
            info:{
                dataList:[],   //Table数据
            },
            selectedItem:'',  //选中集合
        }
    },
    computed:{
      //添加Token - 重要
      GetHeader(){
            return {
                "Authorization":"Bearer "+ Authorize.getToken()
            }
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            let param = {
                module:'Channel'
            }
            this.$api.common.GetFileList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data
                }
            })
        },
        //选择项
        btnSelectItem(item){
            this.selectedItem = item;
        },

        //上传图片之前操作
        BeforeUpload(file){
            const fileType = file.type;
            const fileSize = file.size;
            
            if(fileType !== 'image/jpeg' && fileType !== 'image/png'){
                ElMessage.error('上传图片格式:jpeg|png')
                return false;
            }
            //文件大小
            if(fileSize/1024/1024 > 3){
                ElMessage.error('上传文件大小不能超过3Mb')
                return false;
            }

        },
        //上传过程中
        Uploading(){
            var loading = this.$loading({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
            });
            loading.close();
        },
        //上传完成
        UploadSuccess(res){
            if(res.status === 200){
                this.GetDataList();  //重新加载
            }
        },
        //上传失败
        UploadError(e){
            ElMessage.error(e)
        },

        //删除图片
        DeleteFileList(){
            if(this.selectedItem==''){
                ElMessage.error('请选择要删除的图片');
            }
            //
            let param = {
                module:'Channel',
                fileList:[this.selectedItem]
            }
            this.$api.common.DeleteFileList(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.GetDataList();
                }
            })
        },

    },
    mounted(){
        this.GetDataList();
    }

}
</script>


<style scoped>
.ItemImg{border: 1px solid #ccc;height: 70px;width: 140px;padding: 5px;}
.ItemSel{margin-left: 70px;}

.btnGroup{margin-bottom: 5px;}
.btnTk{float: right;margin: 5px;}
</style>
