<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
    v-model="info"
    label-width="80px"
    label-position="right">
        <el-row>
            <el-col :span="12">
                <el-form-item label="统计日期">
                    <el-date-picker
                        v-model="info.datePoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :editable="false"
                        style="width:250px"
                    />
                </el-form-item>
            </el-col>

            
        </el-row>
    </el-form>

    <div class="btnGroup">
        <el-button type="primary" @click="btnBatchStatSaler">重新统计</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data(){
    return{
      info:{
        datePoint:'',  //统计日期
      }
    }
  },
  methods:{
    //重新统计
    btnBatchStatSaler(){
      if(this.info.datePoint == null){
          this.info.datePoint = ''
      }
      if(this.info.datePoint ==''){
        ElMessage.error("请选择统计日期")
        return false;
      }

      let param = {
          startDate:this.DateFormat(this.info.datePoint[0]),
          endDate:this.DateFormat(this.info.datePoint[1]),
      }
      this.$api.stat.BatchStatSaler(param).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          //关闭
          this.btnClose();
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //计算日期
    GetbeginDate(){
        var date = new Date();
        date.setDate(date.getDate()-7);
        var year = date.getFullYear();
        var month = (date.getMonth() + 1).toString().padStart(2,'0');
        var day = date.getDate().toString().padStart(2,'0');
        var startDate = year + '-' + month + '-'+day;
        this.beginDate = this.DateFormat(startDate);
        this.endDate = this.DateFormat(new Date());
        this.info.datePoint = [this.beginDate,this.endDate];
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

  },
  mounted(){
    this.GetbeginDate();  //设置开始日期
  },
}
</script>

<style>

</style>