<template>
  <div>
    <el-divider></el-divider>

    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="财务单" name="tabInfo">
            <TabAddFinance></TabAddFinance>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabAddRecord></TabAddRecord>
        </el-tab-pane>

    </el-tabs>

    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveFinance()">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import TabAddFinance from '@/views/Fms/Finance/TabAddFinance.vue'
import TabAddRecord from '@/views/Fms/Finance/TabAddRecord.vue'
import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
import { ElLoading, ElMessage } from 'element-plus'

export default {
    data(){
        return{
            info:{
                activeName:'tabInfo'
            },
           
        }
    },
    components:{
        TabAddFinance,
        TabAddRecord,
    },
    computed:{
        ...mapState('Erp',['financeInfo'])   //财务单信息
    },
    methods:{
        ...mapMutations('Erp',['SetFinanceInfo']),

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
        
        //清空数据
        btnClearData(){
            var newInfo = {
                info:{
                    systemNumber:'',
                    billNumber:'',
                    departmentId:this.userDepartmentId,
                    subId:0,
                    tradeNumber:'',
                    tradeType:1,
                    tradeName:'',
                    accountNumber:'0',
                    totalPrice:0,
                    state:1,
                    receiver:'',
                    receiverName:'',
                    billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    remark:''
                },
                originList:[],  //原始单据列表
                debtList:[],   //抵扣项列表
                balanceList:[],  //差额项列表
                recordList:[],
                stateList:[],
            }
            this.SetFinanceInfo(newInfo);
        },

        //保存财务单
        btnSaveFinance(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            this.$api.finance.SaveFinanceAllInfo(this.financeInfo).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //关闭
                    this.btnClose();
                    this.btnClearData();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

    },
    mounted(){
        this.btnClearData();
    },
}
</script>

<style>

</style>