<template>
  <div>
    <!-- 选择打印机和张数 -->
    <el-divider></el-divider>

    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row>

            <el-col :span="12">
                <el-form-item label="选择打印机">
                    <el-select v-model="info.printerNumber">
                        <el-option v-for="printer in printerList" :key="printer.id" :label="printer.printerName" :value="printer.printerNumber"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="打印张数">
                    <el-input-number v-model="info.num" :min="1" :max="5"></el-input-number>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
export default {
    data(){
        return{
            info:{
                printerNumber:'',
                num:1,
            },
            printerList:[],  //打印机列表
        }
    },
    methods:{
        //加载列表
        GetDataList(){
            this.$api.system.SelectPrinterList().then(res=>{
                if(res.data.status === 200){
                    this.printerList = res.data.data;
                    //设置默认
                    if(this.printerList !=null && this.printerList.length>0){
                        this.info.printerNumber = this.printerList[0].printerNumber;
                    }
                }
            })
        }
    },
    mounted(){
        this.GetDataList();
    }
}
</script>

<style>

</style>