
/**
 * 产品模块
 */

//产品
import ListBrand from '@/views/Goods/Brand/ListBrand'
import ListCategory from '@/views/Goods/Category/ListCategory'
import ListSpu from '@/views/Goods/Spu/ListSpu'
import ListSku from '@/views/Goods/Sku/ListSku'
import ListUnit from '@/views/Goods/Unit/ListUnit'

export default [
    {
        //产品品牌
        name:'ListBrand',
        path:'listbrand',
        component:ListBrand
    },
    {
        //产品类目
        name:'ListCategory',
        path:'listcategory',
        component:ListCategory
    },
    {
        //产品单位
        name:'ListUnit',
        path:'listunit',
        component:ListUnit
    },
    {
        //产品信息
        name:'ListSpu',
        path:'listspu',
        component:ListSpu
    },
    {
        //产品规格
        name:'ListSku',
        path:'listsku',
        component:ListSku
    },
]