/**
 * 资源模块接口列表
 */

import base from '../base'   //导入接口域名列表
import axios from '@/utils/http'   //导入http中创建的axios实例


const library = {
    //#region ip资源库

    //列表
    PageLibraryIpList(param){
        return axios.post(`${base.base}/Ip/PageLibraryIpList`,param)
    },

    //单个
    GetLibraryIp(param){
        return axios.get(`${base.base}/Ip/GetLibraryIp`,{
            params:param
        })
    },

    //保存
    SaveLibraryIp(param){
        return axios.post(`${base.base}/Ip/SaveLibraryIp`,param)
    },

    //删除
    DeleteLibraryIp(param){
        return axios.post(`${base.base}/Ip/DeleteLibraryIp`,param)
    },

    //#endregion


}

export default library