<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row>

            <el-col :span="24">
                <el-form-item label="应用编号">
                    <el-input v-model="info.appNumber" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="应用名称">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>应用名称</span>
                    </template>
                    <el-input v-model="info.appName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="版本号">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>版本号</span>
                    </template>
                    <el-input v-model="info.version" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="下载地址">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>下载地址</span>
                    </template>
                    <el-input v-model="info.appUrl" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="更新时间">
                    <template #label>
                      <span style="color:red;">*</span>
                      <span>更新时间</span>
                  </template>
                    <el-date-picker v-model="info.updateTime" type="datetime" placeholder="更新时间" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px"/>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="更新说明">
                    <el-input v-model="info.remark" type="textarea" class="search-200" :rows="5" :maxlength="200"></el-input>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary"  @click="btnSaveData()">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import { ElLoading, ElMessage } from 'element-plus'
import moment from 'moment'
export default {
    props:['editId','dataList'],
    data(){
        return{
            info:{
                id:0,
                appNumber:'',
                appName:'',
                version:'',
                updateTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                remark:'',
                appUrl:'',
                isDel:0
            },
            strAppNumber:this.editId,  //当前编号
        }
    },
    methods:{
        //获取数据
        GetDataInfo(){
            if(this.strAppNumber!=0){
                let param = {
                    appNumber:this.strAppNumber
                }
                this.$api.common.GetMobileVersionInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }           

        },

        //保存数据
        btnSaveData(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            this.$api.common.SaveMobileVersion(this.info).then(res=>{
                loading.close();
                if(res.data.status===200){
                    ElMessage.success(res.data.msg);
                    //更新
                    this.info.appNumber = res.data.data;
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        btnClearData(){
            this.info = {
                id:0,
                appNumber:'',
                appName:'',
                version:'',
                updateTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                remark:'',
                appUrl:'',
                isDel:0
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.appNumber === this.strAppNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.strAppNumber = prevInfo.appNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.appNumber === this.strAppNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.strAppNumber = prevInfo.appNumber
                this.GetDataInfo()
            }
        },

    },
    mounted(){
        this.GetDataInfo();
    },
}
</script>

<style>

</style>