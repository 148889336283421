<template>
  <div>
      <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="日期范围">
                    <el-date-picker
                        v-model="info.search.datePoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>

                <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>
    
    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <!-- <el-button :icon="Plus" type="primary" plain>新增</el-button>
            <el-button :icon="Edit" type="success" plain>修改</el-button>
            <el-button :icon="Delete" type="danger" plain>删除</el-button> -->
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-tooltip
            class="box-item"
            effect="dark"
            :content="showSearch.search_tip"
            placement="top">
            <el-button class="iconfont icon-search" circle @click="btnHiddenSearch(!showSearch.search_isShow)"></el-button>
            </el-tooltip>
            
            <el-tooltip
            class="box-item"
            effect="dark"
            content="流程图"
            placement="top">
            <el-button class="iconfont icon-icon__liuchengtu" circle @click="btnNoFlow"></el-button>
            </el-tooltip>

            <el-tooltip
            class="box-item"
            effect="dark"
            content="打印"
            placement="top">
            <el-button class="iconfont icon-dayin" circle @click="btnNoPrint"></el-button>
            </el-tooltip>

            <el-tooltip
            class="box-item"
            effect="dark"
            content="导出数据"
            placement="top">
            <el-button class="iconfont icon-download" circle @click="btnNoExport"></el-button>
            </el-tooltip>            
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border style="width: 100%">
            <el-table-column prop="id" type="selection" width="50" />  
            <el-table-column prop="systemNumber" label="系统编号" width="180" />
            <el-table-column prop="uv" label="UV数"  />
            <el-table-column prop="pv" label="PV数" />            
        </el-table>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    data(){
        return{
            info:{
                dataList:[],
                search:{
                    datePoint:'',
                    startTime:'',
                    endTime:''
                }                
            }
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            let param = {
                startTime:this.DateFormat(this.info.search.datePoint[0]),
                endTime:this.DateFormat(this.info.search.datePoint[1])
            }
            this.$api.system.GetAllSystemWebUvPv(param).then(res=>{
            if(res.data.status === 200){
                this.info.dataList = res.data.data;  //获取数据
            }else{
                ElMessage({type:'error',message:res.data.msg})
            }
            }).catch(e=>{
            console.log(e.message)
            })
        },
        //重置
        btnResetSearch(){
            this.info.search.loginName = '',
            this.info.page = 1,
            this.info.size = 10,
            this.GetDataList() //加载Table数据
        },
    },
    mounted(){
      this.GetDataList() //加载Table数据
    }
}
</script>

<style>

</style>