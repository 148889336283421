import { ElMessage } from "element-plus"
import moment from "moment";
import { mapState } from "vuex";
import base from '@/api/base.js'  //接口地址

const baseMixin = {
    data(){
        return{
            //隐藏、显示 搜索功能按钮
            showSearch:{
                search_hidden:'',
                search_isShow:true,
                search_tip:'隐藏搜索'
            },
        }
    },
    computed:{
        ...mapState('Master',['userAllButtonList','CurrentsystemInfo','userDepartmentId'])
    },
    methods:{

        //无流程图方法-按钮
        btnNoFlow(){
            ElMessage({type:'error',message:'该页面无流程介绍!'})
        },
        //不支持打印-按钮
        btnNoPrint(){
            ElMessage({type:'error',message:'该页面不支持打印!'})
        },
        //不支持导出-按钮
        btnNoExport(){
            ElMessage({type:'error',message:'该页面不支持导出!'})
        },
        //刷新页面
        btnRefresh(){
            this.$router.go(0)
        },

        //Table - 时间字段格式化 
        DateFormat(date){
            if(date == undefined){
                return ''
            }
            return moment(date).format("YYYY-MM-DD")
        },
        //Table - 时间字段格式化 
        TimeFormat(time){
            if(time == undefined){
                return ''
            }
            return moment(time).format("HH:mm:ss")
        },
        //Table - 时间字段格式化 
        DateTimeFormat(datetime){
            if(datetime == undefined){
                return ''
            }
            return moment(datetime).format("YYYY-MM-DD HH:mm:ss")
        },
        //获取小数点几位
        myRound(number, precision) {
            return Math.round(+number + 'e' + precision) / Math.pow(10, precision);
        },

        //获取文件上传地址: ★所有上传文件、图片都用的这里,方便统一更换地址★
        GetFileUrl(module){
            return `${base.base}/File/SaveImage?module=`+module
        },
        //获取文件上传地址: ★所有上传文件、图片都用的这里,方便统一更换地址★  该地址不包含时间字符串 （品牌图片、产品图片）在用
        GetFileUrl2(module){
            return `${base.base}/File/SaveImage2?module=`+module
        },

        //判断当前用户是否有按钮权限
        CheckUserButtonAuth(buttonName){
            let index = this.userAllButtonList.findIndex(item=>{
                return item.jumpName === buttonName;
            })
            if(index !=-1){
                return true;
            }else{
                return false;
            }
        },

        //限制记账时间范围
        BillDisabledDate(time){
            let dateNum = sessionStorage.getItem("DisableDate");  //获取设置的限制天数
            var startTime = new Date().setDate(new Date().getDate() - dateNum);  //前一天            
            var endTime = new Date().setDate(new Date().getDate() +1);  //后一天
            if(time.getTime() > startTime && time.getTime() < endTime){
                return false;
            }else{
                return true;
            }
        },

    },
    mounted(){
    }
};

export default baseMixin

