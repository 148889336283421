<template>
  <div>
    <video width="560" controls poster="@/assets/img/flow.png">
      <source :src="videoUrl"  type="video/mp4">
      您的浏览器不支持 HTML5 video 标签。
    </video>
  </div>
</template>

<script>
export default {
  props:['videoUrl'],
}
</script>

<style>

</style>