<template>
  <div>

    <!-- 店铺分类信息 -->
    <el-form :inline="true"
        v-model="shopCategoryInfo.info"
        label-width="80px"
        label-position="right">
            <el-row v-if="shopCategoryInfo.info">

                <el-col :span="8">
                    <el-form-item label="类目名称">
                        <el-input v-model="shopCategoryInfo.info.categoryName" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="归属类目">
                        <el-tree-select v-model="shopCategoryInfo.info.pid" :data="TreeShopCategory" check-strictly :default-expand-all="true" class="search-200"/>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="排列顺序">
                        <el-input-number v-model.number="shopCategoryInfo.info.sort" :min="0" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

            </el-row>
      </el-form>


  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data(){
    return{
        TreeShopCategory:[],
    }
  },
  computed:{
    ...mapState('Shop',['shopCategoryInfo'])
  },
  methods:{
    //加载店铺分类目录树
    GetTreeDataInfo(){
        this.$api.shop.SelectTreeCategory().then(res=>{
            if(res.data.status === 200){
                this.TreeShopCategory = res.data.data;
            }
        })
    },


  },
  mounted(){
    this.GetTreeDataInfo();   //加载目录树
  },

}
</script>

<style>

</style>