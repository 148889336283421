<template>
  <div>
    <!-- 交易者的发票信息 -->
    <div class="InvoiceBtn">
      <el-button class="iconfont icon-fapiao" type="primary" plain @click="btnOpenBoxTradeInvoice">选择发票</el-button>
    </div>

    
    <el-table :data="cycleInfo.tradeInvoiceList" border stripe >
        <el-table-column prop="accountName" label="账户名称" min-width="170">
            <template v-slot="scope">
                <el-tooltip
                class="box-item"
                effect="dark"
                :content="scope.row.accountName"
                placement="top"
                >
                <div class="cellOverFlow">{{scope.row.accountName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="invoiceNumber" label="纳税人识别号" width="200" />
        <el-table-column prop="address" label="地址" min-width="170">
            <template v-slot="scope">
                <el-tooltip
                class="box-item"
                effect="dark"
                :content="scope.row.address"
                placement="top"
                >
                <div class="cellOverFlow">{{scope.row.address}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="phone" label="电话" width="170" />
        <el-table-column prop="bankName" label="开户行" width="170" />
        <el-table-column prop="bankNumber" label="账号" width="170" />
        <el-table-column label="操作" width="80" fixed="right">
            <template v-slot="scope">              
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.id,scope.$index)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>

    <!-- 弹出框 -->
    <el-dialog title="选择交易方发票信息"
    v-model="BoxInvoice.isVisible"
    width="50%"
    :destroy-on-close="true">
      <BoxTradeInvoice :parentInfo="info" ref="SelectTradeInvoice"></BoxTradeInvoice>
      <template #footer>
        <el-button type="primary" @click="btnSelectTradeInvoice">选择</el-button>
        <el-button @click="BoxInvoice.isVisible = false">关闭</el-button>
      </template>
    </el-dialog>


  </div>
</template>

<script>
import BoxTradeInvoice from '@/views/Fms/Cycle/BoxTradeInvoice.vue'
import { mapState } from 'vuex'
export default {
  components:{
    BoxTradeInvoice
  },
  data(){
    return{
      info:{
        tradeNumber:'',  //交易者编号
      },
      //选择发票框
      BoxInvoice:{
        isVisible:false,
      },

    }
  },
  computed:{
      ...mapState('Finance',['cycleInfo'])
  },
  methods:{

    //打开弹出框
    btnOpenBoxTradeInvoice(){
      this.info.tradeNumber = this.cycleInfo.info.tradeNumber;  //获取交易者编号
      this.BoxInvoice.isVisible = true;
    },
    //选择发票
    btnSelectTradeInvoice(){
      let itemList = this.$refs.SelectTradeInvoice.selectedItem;
      if(itemList !=null && itemList.length>0){
        itemList.forEach(item => {
          let newRow = {
            id:0,
            systemNumber:'',
            cycleNumber:'',
            accountName:item.accountName,
            invoiceNumber:item.invoiceNumber,
            address:item.address,
            phone:item.phone,
            bankName:item.bankName,
            bankNumber:item.bankNumber,
          }
          this.cycleInfo.tradeInvoiceList.push(newRow);
        });
        this.BoxInvoice.isVisible = false;
      }
    },

    //删除发票
    btnDeleteInfo(id,index){
      this.cycleInfo.tradeInvoiceList.splice(index,1);
    },

  }
}
</script>

<style scoped>
.InvoiceBtn{margin-bottom: 10px;}
</style>