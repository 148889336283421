<template>
  <div>
    <div class="skuBtnList">
        <el-upload v-if="CheckUserButtonAuth('BtnProofUpLoad')"
          :action="GetFileUrl('Proof')"
          class="btnTk"
          :headers="GetHeader"
          :show-file-list="false"
          multiple
          :before-upload="BeforeUpload"
          :on-success="UploadSuccess"
          :on-error="UploadError">
          <el-button class="iconfont icon-add" type="warning" plain >上传凭证</el-button>
      </el-upload>
    </div>

    <!-- 凭证列表 -->
    <el-table :data="proofInfo.detailList" border stripe style="width: 100%">
      <el-table-column label="文件">
        <template v-slot="scope">
            <el-image v-if="scope.row.fileType==1"
                style="width: 70px; height: 70px"
                :src="scope.row.fileUrl"
                :preview-src-list="proofInfo.imgList"
                :initial-index="scope.$index"
                :hide-on-click-modal="true"
                :infinite="false"
                fit="cover">
            </el-image>
            <img v-if="scope.row.fileType==3" src="@/assets/img/video.png" alt="" style="width: 70px; height: 70px" @click="btnShowBox(scope.row.fileUrl)">
            <!-- 文件 -->
            <el-link v-if="scope.row.fileType==2" :href="scope.row.fileUrl" target="_blank">{{scope.row.title}}</el-link>
          </template>
      </el-table-column>
      <el-table-column label="类型">
        <template v-slot="scope">
            <el-tag v-if="scope.row.fileType == 1">图片</el-tag>
            <el-tag v-if="scope.row.fileType == 2">文件</el-tag>
            <el-tag v-if="scope.row.fileType == 3">视频</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createName" label="创建者"></el-table-column>
      <el-table-column prop="remark" label="操作">
        <template v-slot="scope">
            <el-button v-if="CheckUserButtonAuth('BtnProofDelDetail')" size="small" type="danger" @click="btnDeleteProofDetail(scope.row.proofNumber,scope.row.id,scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 视频-弹框 -->
    <el-dialog :title="BoxFlow.title"
    v-model="BoxFlow.isVisible"
    :width="600"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
    <el-divider></el-divider>
        <BoxVideo :videoUrl="BoxFlow.videoUrl"></BoxVideo>
    </el-dialog>

  </div>
</template>

<script>
import BoxVideo from '@/components/common/BoxVideo.vue'  //视频弹出框
import Authorize from '@/utils/authorize.js'
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
export default {
    components:{
      BoxVideo
    },
    data(){
        return{
            ImgList:[],   //预览图片列表
            // 视频弹框 
            BoxFlow:{
                title:'视频文件',
                isVisible:false,
                videoUrl:'',  //视频地址
            },
        }
    },
    computed:{
        //添加Token 
        GetHeader(){
            return {
                "Authorization":"Bearer "+ Authorize.getToken()
            }
        },
        ...mapState('Oa',['proofInfo']),
        ...mapState('Master',['currentUserName'])
    },
    methods:{
        //上传图片之前操作
        BeforeUpload(file){
            const fileType = file.type;
            const fileSize = file.size;

            if(fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType!=='video/mp4' && fileType !== 'application/pdf' && fileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                ElMessage.error('上传格式错误，凭证支持: jpg | png | mp4 | pdf | docx | xlsx 格式')
                return false;
            }
            //文件大小
            if(fileSize/1024/1024 > 5){
                ElMessage.error('上传文件大小不能超过5Mb')
                return false;
            }
        },

        //上传过程中
        Uploading(){
            
        },

        //上传完成
        UploadSuccess(res,file){
            if(res.status === 200){
                let fileType = 1; //文件类型：默认退安
                const fileExt = res.data.slice((res.data.lastIndexOf(".") - 1 >>> 0) + 2)
                if(fileExt =="docx" || fileExt=="pdf" || fileExt == "xlsx"){
                    fileType = 2;  //文件类型
                }
                if(fileExt == 'mp4'){
                    fileType = 3   //视频
                }
                let row = {
                    id:0,
                    title:file.name,  //文件名称
                    fileUrl:res.data,
                    fileType:fileType,
                    createName:this.currentUserName,
                    isDel:0
                }
                this.proofInfo.detailList.push(row);
                //这里只组装图片
                if(row.fileType==1){
                    this.proofInfo.imgList.push(res.data);
                }
                
            }
        },  

        //上传失败
        UploadError(e){
            ElMessage.error(e)
        },

        //删除单个明细
        btnDeleteProofDetail(proofNumber,id,index){
            if(id!=0){
                let param = {
                    proofNumber:proofNumber,
                    id:id
                }
                this.$api.oa.DeleteProofDetail(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        this.proofInfo.detailList.splice(index,1)
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }else{
                this.proofInfo.detailList.splice(index,1)
            }
            
        },

        //打开视频 - 弹框
        btnShowBox(url){
            this.BoxFlow.videoUrl = url;
            this.BoxFlow.title = '视频凭证';
            this.BoxFlow.isVisible = true;
        },
    },

}
</script>

<style>

</style>