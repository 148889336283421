<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
        v-model="info"
        label-width="100px"
        label-position="right">
            <el-row>

                <el-col :span="12">
                    <el-form-item label="所属部门">
                        <el-tree-select v-model="info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="品牌">
                        <el-select v-model="info.brandId" class="search-200">
                            <el-option label="得力" :value="1"></el-option>
                        </el-select>
                    </el-form-item>                    
                </el-col>
                
                <el-col :span="12">
                    <el-form-item label="考勤机编号">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>考勤机编号</span>
                        </template>
                        <el-input v-model="info.brandName" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="考勤机名称">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>考勤机名称</span>
                        </template>
                        <el-input v-model="info.brandName" class="search-200"></el-input>
                    </el-form-item>
                </el-col>
                
                <el-col :span="12">
                    <el-form-item label="识别码">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>识别码</span>
                        </template>
                        <el-input v-model="info.brandName" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
      </el-form>

    <!-- 功能按钮 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
export default {
    mixins:[UserDptList],
    data(){
        return{
            info:{
                departmentId:0,
                brandId:1,
            }
        }
    },
    methods:{

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    }
}
</script>

<style>

</style>