<template>
  <div>
    
    <el-row>
      <!-- 目录树 -->
      <el-col :span="4" class="tree_info">
        <el-card class="box-card">
          <template #header>
              <div class="card-header">
                  <span>知识类型</span>
              </div>
          </template>
          <!-- 目录树 -->
          <el-scrollbar :height="GetTreeDataHeight">
              <el-tree :data="info.treeList"
              node-key="id"
              :default-expanded-keys="[0]"
                @node-click="handleNodeClick" empty-text="暂无知识类型" />
          </el-scrollbar>                
        </el-card>
      </el-col>

      <!-- 主列表 -->
      <el-col :span="20">
        <div>
          <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
              <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>      
              
            </el-col>
          </el-row>
          <!-- 列表 -->
          <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox">
            <el-table-column prop="id" type="selection" width="50" />
            <el-table-column prop="id" label="编号" width="150" />
            <el-table-column prop="typeName" label="类型名称" />
            <el-table-column prop="pidName" label="归属" />
            <el-table-column prop="sort" label="排序" />
            <el-table-column label="操作" width="220">
              <template v-slot="scope">
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.id)">详情</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.id)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
                
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-container class="page_one">
              <el-pagination
                v-model:currentPage="info.page"
                v-model:page-size="info.size"
                :page-sizes="[50, 200, 500]"
                :background="true"
                layout="total, sizes, prev, pager, next, jumper"
                :total="info.total"
                @current-change="btnPagechange"
                @size-change="btnSizechange"
              >
              </el-pagination>
          </el-container>
        </div>

      </el-col>
    </el-row>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="400"
      :draggable="true"
    :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="ReloadData">
        <EditKnowledgeType :editId="info.id" @closeBox="btnCloseBox"></EditKnowledgeType>
    </el-dialog>

  </div>
</template>

<script>
import EditKnowledgeType from '@/views/Oa/Knowledge/Type/EditKnowledgeType.vue'
import { ElMessage } from 'element-plus'
export default {
  components:{
    EditKnowledgeType
  },
  data(){
        return{
            info:{
                treeList:[],  //分类目录树
                pid:0,  //目录树父节点
                dataList:[],  //Table数据
                page:1,   //页数
                size:50,  //单页记录数
                total:0,  //总记录数
                id:0,  //单个新增、编辑
                ids:[],   //新增、编辑、删除 的参数
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'新增知识类型'
            },
        }
    },
    computed:{
        //Table自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-190
        },
        //Tree自适应窗体高度
        GetTreeDataHeight(){
          return window.innerHeight - 250
        },
    },
    methods:{
      //加载Tree数据
      GetTreeDataList(){
        this.$api.oa.SelectTreeKnowledgeTypeList().then(res=>{
          if(res.data.status === 200){
            this.info.treeList = res.data.data;
          }else{
            ElMessage.error(res.data.msg);
          }
        })
      },
      //加载Table数据
      GetDataList(){
        let param = {
            pid:this.info.pid,
            page:this.info.page,
            size:this.info.size
        }
        this.$api.oa.ListKnowledgeType(param).then(res=>{
          if(res.data.status === 200){
            this.info.dataList = res.data.data;
            //分页
            this.info.total = res.data.total
            this.info.page = res.data.pageNumber
          }
        })
      },

      //重新加载
      ReloadData(){
        this.GetTreeDataList();
        this.GetDataList();
      },

      //获取目录树节点
      handleNodeClick(data){
        this.info.pid = data.id;
        this.GetDataList()
      },
      //选中多选框
      btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.id)
      },
      //分页-页数变化时
      btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
      },
      //分页-单页记录数变化时
      btnSizechange(size){
        this.info.size = size
        this.info.page = 1
        this.GetDataList() //加载Table数据
      },
      //新增编辑信息
      btnEditInfo(id){
          this.info.id = id
          if(id==0){
              this.BoxEditInfo.title = "新增知识类型"
          }else{
              this.BoxEditInfo.title = "编辑知识类型"
          }  
          this.BoxEditInfo.isVisible = true;
      },

      //删除单个
      btnDeleteInfo(id){
      let param = [id]
      this.$api.oa.DeleteKnowledgeType(param).then(res=>{
              if(res.data.status === 200){
                  ElMessage.success(res.data.msg)
                  this.ReloadData()
              }
              else{
                  ElMessage.error(res.data.msg)
              }
          })
      },  
      //批量删除
      btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要删除的选项')
            return false
          }

          this.$api.oa.DeleteKnowledgeType(this.info.ids).then(res=>{
            if(res.data.status === 200){
              ElMessage.success(res.data.msg)
              this.ReloadData()
            }
            else{
              ElMessage.error(res.data.msg)
            }
          })
      },

      //关闭弹出框
      btnCloseBox(){
          this.BoxEditInfo.isVisible = false;
      },

    },
    mounted(){
      this.ReloadData();
    }

}
</script>

<style>

</style>