<template>
  <div>
    <el-descriptions
        title="各单据统计"
        :column="3"
        :size="size"
        v-if="shiftRecordInfo.statInfo"
        border>
        <el-descriptions-item label="销售总额">{{shiftRecordInfo.statInfo.saleTotal}}</el-descriptions-item>
        <el-descriptions-item label="销售总成本">{{shiftRecordInfo.statInfo.saleCostTotal}}</el-descriptions-item>
        <el-descriptions-item label="销售毛利">{{shiftRecordInfo.statInfo.saleProfit}}</el-descriptions-item>

        <el-descriptions-item label="退货总额">{{shiftRecordInfo.statInfo.returnSaleTotal}}</el-descriptions-item>
        <el-descriptions-item label="退货总成本">{{shiftRecordInfo.statInfo.returnSaleCostTotal}}</el-descriptions-item>
        <el-descriptions-item label="退货毛利">{{shiftRecordInfo.statInfo.returnSaleProfit}}</el-descriptions-item>

        <el-descriptions-item label="零售总额">{{shiftRecordInfo.statInfo.retailTotal}}</el-descriptions-item>
        <el-descriptions-item label="零售总成本">{{shiftRecordInfo.statInfo.retailCostTotal}}</el-descriptions-item>
        <el-descriptions-item label="零售毛利">{{shiftRecordInfo.statInfo.retailProfit}}</el-descriptions-item>

        <el-descriptions-item label="退零总额">{{shiftRecordInfo.statInfo.returnRetailTotal}}</el-descriptions-item>
        <el-descriptions-item label="退零总成本">{{shiftRecordInfo.statInfo.returnRetailCostTotal}}</el-descriptions-item>
        <el-descriptions-item label="退零毛利">{{shiftRecordInfo.statInfo.returnRetailProfit}}</el-descriptions-item>
    </el-descriptions>

    <!-- 列表 -->
    <el-table :data="shiftRecordInfo.detailList" border stripe show-summary style="width: 100%">
        <el-table-column prop="accountName" label="账户名称" min-width="110"/>       
        
        <el-table-column prop="realPrice" label="实际交易额" min-width="110">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.realPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="totalPrice" label="系统发生额" min-width="100">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.totalPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="diffPrice" label="系统差额" min-width="110">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.diffPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="备注" min-width="90">
            <template v-slot="scope">
                <span>{{scope.row.remark}}</span>
            </template>
        </el-table-column>

        <el-table-column label="调账标识">
          <template #default="scope">
            <el-tag v-if="scope.row.isAdjust === 1" type="danger">已调账</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope">
                <el-button size="small" type="primary" @click="btnSaveAdjustRecord(scope.row)">生成调账记录</el-button>
            </template>
        </el-table-column>

    </el-table>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Erp',['shiftRecordInfo']),
        ...mapState('Master',['currentUserNumber'])
    },
    methods:{
        //生成调账记录
        btnSaveAdjustRecord(row){
            let param = {
                adjustNumber:'',
                systemNumber:this.shiftRecordInfo.info.systemNumber,
                departmentId:this.shiftRecordInfo.info.departmentId,
                departmentName:this.shiftRecordInfo.info.departmentName,
                tradeType:3,
                tradeNumber:this.currentUserNumber,
                tradeName:'',
                shiftNumber:this.shiftRecordInfo.info.shiftNumber,
                shiftName:this.shiftRecordInfo.info.shiftName,
                accountNumber:row.accountNumber,
                accountName:row.accountName,
                totalPrice:row.diffPrice,
                summary:'系统调账',
                state:1,
                createFlag:2,
                isDel:0,
            }
            this.$api.erp.SaveRetailAdjustRecordBySys(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success('已生成调账单');
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },
    },
}
</script>

<style>

</style>