<template>
  <div>

    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="优惠券名称">
                <el-input v-model="info.search.couponName" class="search-200"></el-input>
            </el-form-item>

            <el-form-item label="优惠券类型">
                <el-select v-model="info.search.couponType" class="search-200">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="指定发放"></el-option>
                    <el-option :value="2" label="随机领取"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>       
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                        
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>    
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="couponNumber" type="selection" width="50" />  
        <el-table-column prop="couponName" label="优惠券名称" />
        <el-table-column prop="couponPrice" label="优惠券金额" />
        <el-table-column label="优惠券类型">
            <template v-slot="scope">
                <el-tag v-if="scope.row.couponType == 1" type="danger">指定发放</el-tag>
                <el-tag v-if="scope.row.couponType == 2" type="warning">随机领取</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="开始时间">
            <template #default="scope">
              <span>{{DateTimeFormat(scope.row.startDate)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="结束时间">
            <template #default="scope">
              <span>{{DateTimeFormat(scope.row.endDate)}}</span>
            </template>
        </el-table-column>        
        <el-table-column prop="total" label="总数量" />
        <el-table-column label="操作" width="200" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.channelNumber)">详情</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.channelNumber)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[50, 200, 500]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
            >
            </el-pagination>
        </el-container>

    </div>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="65%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditChannel :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditChannel>
    </el-dialog>

  </div>
</template>

<script>
export default {
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    couponName:'',
                    couponType:0,
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'优惠券信息'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){

        },

        //新增
        btnEditInfo(){
            console.log('测试')
        },

    }
}
</script>

<style>

</style>