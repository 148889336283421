<template>
  <div>
    <el-divider></el-divider>
    <!-- 销售单小票打印 -->

    <el-select v-model="info.printerNumber" class="search-200">
        <el-option v-for="printer in info.printerList" :key="printer.id" :label="printer.printerName" :value="printer.printerNumber"></el-option>
    </el-select>

    <!-- 功能按钮 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnPrintSale">销售小票</el-button>
        <el-button type="primary" @click="btnPrintOutStock">配货小票</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
export default {
    props:['editId'],
    data(){
        return{
            info:{
                printerNumber:'',   //选中的打印机编号
                printerList:[],  //打印机列表
            }
        }
    },
    methods:{
        //选择小票打印机
        btnSelectPrinter(){
            this.$api.system.SelectPrinterList().then(res=>{
                if(res.data.status ===200){
                    this.info.printerList = res.data.data;
                    //默认项
                    if(this.info.printerList !=null && this.info.printerList.length>0){
                        this.info.printerNumber = this.info.printerList[0].printerNumber;  
                    }else{
                        ElMessage.error('该部门没有绑定下小票打印机');
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },
        //打印销售小票
        btnPrintSale(){
            let param = {
                printerNumber:this.info.printerNumber,
                saleNumber:this.editId,
            }
            this.$api.erp.PrintSaleInfo(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                }else{
                    ElMessage.error(res.data.msg);
                }
                this.btnClose();
            })
        },
        //打印配货小票
        btnPrintOutStock(){
            let param = {
                printerNumber:this.info.printerNumber,
                saleNumber:this.editId,
            }
            this.$api.erp.PrintSaleOutStock(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                }else{
                    ElMessage.error(res.data.msg);
                }
                // this.btnClose();
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.btnSelectPrinter();  //选择打印机
    },
}
</script>

<style>

</style>