<template>
  <div>

    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="审批类型">
                <el-tree-select v-model="info.search.typeId" :data="processTypeList" :default-expand-all="true" class="search-200"/>
            </el-form-item>

            <el-form-item label="状态">
                <el-select v-model="info.search.state" class="search-150">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="新建单"></el-option>
                    <el-option :value="2" label="待审核"></el-option>
                    <el-option :value="3" label="已驳回"></el-option>
                    <el-option :value="4" label="已审核并上报"></el-option>
                    <el-option :value="5" label="审核已完成"></el-option>
                    <!-- <el-option :value="10" label="已完成"></el-option> -->
                </el-select>
            </el-form-item>

            <el-form-item label="记账日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
            <el-button class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button>
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>             
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="id" type="selection" width="50" />  
            <el-table-column prop="billNumber" label="审批单号" width="170" />
            <el-table-column prop="departmentName" label="所在部门" width="170" />
            <el-table-column prop="typeName" label="审批类型">
            </el-table-column>
            <el-table-column prop="content" label="申请事由" />
            <el-table-column prop="state" label="审批状态">
                <template #default="scope">
                    <el-tag v-if="scope.row.state ==1" type="info">新建单</el-tag>
                    <el-tag v-if="scope.row.state ==2">待审核</el-tag>
                    <el-tag v-if="scope.row.state ==3" type="danger">已驳回</el-tag>
                    <el-tag v-if="scope.row.state ==4" type="warning">已审核并上报</el-tag>
                    <el-tag v-if="scope.row.state ==5" type="info">审核已完成</el-tag>
                    <!-- <el-tag v-if="scope.row.state ==10" type="info">已完成</el-tag> -->
                </template>
            </el-table-column>
            <el-table-column prop="createName" label="申请人" />
            <el-table-column prop="receiverName" label="审核人" />        
            <el-table-column label="创建日期" width="110">
                <template #default="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.createTime"
                        placement="top"
                    >
                    <span>{{DateFormat(scope.row.createTime)}}</span>
                    </el-tooltip>                
                </template>
            </el-table-column>
            <el-table-column label="操作" width="130" fixed="right">
                <template v-slot="scope">              
                    <el-button size="small" type="primary" @click="btnEditInfo(scope.row.billNumber)">详情</el-button>
                    <el-button size="small" type="danger" @click="btnDeleteInfo(scope.row.billNumber)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="700"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditProcess :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditProcess>
    </el-dialog>


  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'

import EditProcess from '@/views/Oa/Process/Info/EditProcess.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    components:{
        EditProcess
    },
    mixins:[UserDptList],
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    selectDepartmentList:[],
                    datePoint:'',   //截止日期
                    receiver:'',
                    typeId:0,
                    state:0
                },
            },
            processTypeList:[],   //审批类型列表
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'审批单'
            },

        }
    },
    computed:{
        //Table自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
    methods:{

        //加载审批类型列表
        SelectTreeProcessTypeList(){
            this.$api.oa.SelectTreeProcessTypeList().then(res=>{
                if(res.data.status === 200){
                    this.processTypeList = res.data.data;
                }else{
                    this.processTypeList = [];
                }
            })
        },

        //获取数据列表
        GetDataList(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                state:this.info.search.state,
                departmentList:newDepartmentList,
                typeId:this.info.search.typeId,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.oa.PageProcessList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            })

        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.billNumber)
        },
        
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //新增编辑信息
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "新增审批单"
            }else{
                this.BoxEditInfo.title = "编辑审批单"
            }  
            this.BoxEditInfo.isVisible = true;
        },

        //删除按钮
        btnDeleteInfo(billNumber){
            ElMessageBox.confirm('确定要删除该审批单吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                let param = [billNumber]
                this.$api.oa.DeleteProcessAllInfo(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
                })
            }).catch(()=>{
                //取消
            })
            
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            ElMessageBox.confirm('确定要删除该审批单吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                this.$api.oa.DeleteProcessAllInfo(this.info.ids).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                        this.GetDataList();
                        this.info.ids = [];
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                //取消
            })        
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.selectDepartmentList=[];  //部门
            this.info.search.datePoint = "";
            this.info.search.typeId=0;
            this.info.search.state = 0;
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()

        },


    },
    mounted(){
        this.SelectTreeProcessTypeList();
        this.GetDataList();
    },
    
}
</script>

<style>

</style>