<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="费用单" name="tabInfo">
            <TabCost ref="tabCost"></TabCost>

            <el-divider>费用单明细</el-divider>
            <TabCostDetail></TabCostDetail>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabCostRecord></TabCostRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabCostState></TabCostState>
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="costInfo.info">
        <el-button type="primary" v-if="costInfo.info.state==1 || costInfo.info.state == 3" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" v-if="costInfo.info.state==1 || costInfo.info.state == 3" @click="btnSaveData(1)">保存</el-button>
        <el-button type="primary" v-if="(costInfo.info.costNumber !='' && costInfo.info.state==1) || costInfo.info.state ==3" @click="btnUpdateState(2)">提交审核</el-button>
        <el-button v-if="costInfo.info.state == 2 || costInfo.info.state == 4" type="warning" @click="BoxCheckState.isVisible = true">审核</el-button>
        <el-button class="iconfont icon-dayin" v-if="costInfo.info.state==5" @click="btnShowBoxPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 审核-弹框 -->
    <el-dialog title="费用单审核"
    v-model="BoxCheckState.isVisible"
    width="500px">
        <el-divider></el-divider>
   
        <el-descriptions border :column="1">
            <el-descriptions-item label="下步审核人">
                <el-select v-model="BoxCheckState.checkerNumber" class="search-200">
                    <el-option v-for="checker in selectUserList" :key="checker.userNumber" :label="checker.userName" :value="checker.userNumber"></el-option>
                </el-select>
            </el-descriptions-item>

            <el-descriptions-item label="审核说明">
                <el-input v-model="BoxCheckState.remark" :maxlength="50" class="search-200"></el-input>
            </el-descriptions-item>
        </el-descriptions>

        <template #footer>            
            <el-button type="danger" @click="btnUpdateState(3)">驳回</el-button>
            <el-button v-if="CheckUserButtonAuth('BtnCostAuditReport')" type="primary" @click="btnUpdateState(4)">审核通过并上报</el-button>
            <el-button v-if="CheckUserButtonAuth('BtnCostAuditComplate')" type="success" @click="btnUpdateState(5)">审核完成</el-button>            
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>
    
    <!-- 打印框 -->
    <el-dialog title="打印预览"
    v-model="BoxPrint.isVisible"
    :draggable="true"
    :close-on-click-modal="false"
    width="80%">
        <PrintView v-if="BoxPrint.isVisible" @closeBox="btnClosePrint"></PrintView>
    </el-dialog>

  </div>
</template>

<script>
import TabCost from '@/views/Fms/Cost/TabCost.vue'
import TabCostDetail from '@/views/Fms/Cost/TabCostDetail.vue'
import TabCostRecord from '@/views/Fms/Cost/TabCostRecord.vue'
import TabCostState from '@/views/Fms/Cost/TabCostState.vue'
import PrintView from '@/views/Fms/Cost/PrintView.vue'

import AllUserList from '@/utils/mixins/AllUserList.js'   //用户列表
import { mapMutations, mapState } from 'vuex'
import { ElLoading, ElMessage } from 'element-plus'
import moment from 'moment'
export default {
    props:['editId','dataList'],
    mixins:[AllUserList],
    components:{
        TabCost,
        TabCostDetail,
        TabCostRecord,
        TabCostState,
        PrintView,
    },
    data(){
        return{
            info:{
                activeName:'tabInfo',
                costNumber:this.editId,                
            },
            //审核-弹出框
            BoxCheckState:{
                isVisible:false,
                //审核内容
                checkerList:[],   //审核人列表
                checkerNumber:'',   //选中审核人(编号)
                checkerName:'',   //选中审核人(中文)
                remark:'',     //审核说明
            },
            //打印框
            BoxPrint:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Erp',['costInfo'])
    },
    methods:{
        ...mapMutations('Erp',['SetCostInfo']),

        //加载数据 
        GetDataInfo(){
            if(this.info.costNumber!=0){
                let param = {
                    costNumber:this.info.costNumber
                }
                this.$api.finance.SingleCostAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetCostInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存数据 type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            //#region 验证
            if(this.costInfo.departmentId==0){
                ElMessage.error('请选择部门')
                return false;
            }

            if(this.costInfo.tradeNumber ==''){
                ElMessage.error('请选择交易者')
                return false;
            }

            if(this.costInfo.costType ==0){
                ElMessage.error('请选择单据类型')
                return false;
            }
            //#endregion

            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            this.$api.finance.SaveCostAllInfo(this.costInfo).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //类型
                    if(type == 1){
                        //this.btnClose();
                        //刷新页面
                        this.costInfo.info.costNumber = res.data.data;
                        this.info.costNumber = res.data.data;
                        this.GetDataInfo();
                    }else{
                        this.btnClearData();
                    }                    
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //提交审核状态
        btnUpdateState(state){
            let param = {
                costNumber:this.costInfo.info.costNumber,
                state:state,
                receiver:this.BoxCheckState.checkerNumber,  //审核人
                remark:this.BoxCheckState.remark,
            }
            if(state == 2){
                //待审核：
                param.receiver = this.costInfo.info.receiver; //审核人
                param.remark = "";     //说明
            }
            this.$api.finance.UpdateCostState(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //重新加载
                    this.GetDataInfo();
                    this.BoxCheckState.isVisible = false
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.costNumber === this.info.costNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.costNumber = prevInfo.costNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.costNumber === this.info.costNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.costNumber = prevInfo.costNumber
                this.GetDataInfo()
            }
        },

        //清空数据
        btnClearData(){
            var newInfo = {
                info:{
                    systemNumber:'',
                    costNumber:'',
                    departmentId:this.userDepartmentId,
                    tradeType:0,
                    tradeNumber:'',
                    tradeName:'',
                    costName:'',
                    billType:0,
                    totalPrice:0,
                    state:1,
                    payState:0,
                    receiver:'',
                    receiverName:'',
                    financeFlag:0,
                    financeNumber:'',
                    billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                },
                detailList:[],
                recordList:[],
                stateList:[]
            }
            this.SetCostInfo(newInfo);
        },
        
        //打开-打印框
        btnShowBoxPrint(){
            this.BoxPrint.isVisible =true;
        },
        //关闭-打印框
        btnClosePrint(){
            this.BoxPrint.isVisible =false;        
        },

    },
    mounted(){
        this.GetDataInfo();
    }
}
</script>

<style scoped>
.SingleInfo {min-height: 200px;}
</style>