<template>
  <div>
    <el-form :inline="true"
        v-model="orderInfo.info"
        label-width="100px"
        label-position="right">
            <el-row v-if="orderInfo.info">

                <el-col :span="8">
                    <el-form-item label="订单编号">
                        <el-input v-model="orderInfo.info.orderNumber" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="会员名称">
                        <el-input v-model="orderInfo.info.memberName" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="订单状态">
                        <el-select v-model="orderInfo.info.state" disabled class="search-200">
                          <el-option label="待支付" :value="1" />
                          <el-option label="待发货" :value="2" />
                          <el-option label="待收货" :value="3" />
                          <el-option label="已完成" :value="4" />
                          <el-option label="售后中" :value="5" />
                          <el-option label="已取消" :value="6" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="产品总价">
                        <el-input v-model="orderInfo.info.totalPrice" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="实际支付价">
                        <el-input v-model="orderInfo.info.realPrice" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="订单时间">
                        <el-input v-model="orderInfo.info.billTime" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="备注">
                        <el-input v-model="orderInfo.info.remark" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="快递费">
                        <el-input v-model="orderInfo.info.expressPrice" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="快递公司">
                        <el-input v-model="orderInfo.info.expressCompany" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="快递单号">
                        <el-input v-model="orderInfo.info.expressNumber" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name:'TabOrder',
  computed:{
    ...mapState('Shop',['orderInfo'])
  },

}
</script>

<style>

</style>