<template>
  <div>
    <el-divider></el-divider>

    <div id="printData">
        <div>
            <el-row>
                <el-col :span="7" class="titleLeft">打印时间：{{printTime}}</el-col>
                <el-col :span="10" class="title"><h3>财务单</h3></el-col>
                <el-col :span="7" class="titleRight">公司名称：{{CurrentsystemInfo.companyName}}</el-col>
            </el-row>
            <el-divider></el-divider>
        </div>
        <table class="DataInfo">
        </table>

        <table class="DataDetail">
            <tr>
                <th>业务单号</th>
                <th>部门</th>
                <th>单据类型</th>
                <th>会计科目</th>
                <th>计算方式</th>
                <th>交易者类型</th>
                <th>交易者</th>
                <th>交易账户</th>
                <th>交易金额(元)</th>
                <th>状态</th>
                <th>记账时间</th>
                <th>用款时间</th>            
                <th>备注</th>
            </tr>
            <tr v-for="detail in dataList" :key="detail.billNumber">
                <td>{{detail.billNumber}}</td>
                <td>{{detail.departmentName}}</td>
                <td>
                    <span v-if="detail.originType==9">工资单</span>
                    <span v-if="detail.originType==10">费用单</span>
                    <span v-if="detail.originType==25">业务单</span>
                </td>
                <td>{{detail.subName}}</td>
                <td>
                    <span v-if="detail.way==0">减</span>
                    <span v-if="detail.way==1">加</span>
                </td>
                <td>
                    <span v-if="detail.tradeType==1">供应商</span>
                    <span v-if="detail.tradeType==2">客户</span>
                    <span v-if="detail.tradeType==3">员工</span>
                    <span v-if="detail.tradeType==4">散客</span>
                </td>
                <td>{{detail.tradeName}}</td>
                <td>{{detail.accountName}}</td>
                <td>{{detail.totalPrice}}</td>
                <td>
                    <span v-if="detail.state==1">新财务单</span>
                    <span v-if="detail.state==2">已驳回</span>
                    <span v-if="detail.state==3">已凭证审核</span>
                    <span v-if="detail.state==4">已付款审核</span>
                    <span v-if="detail.state==5">已结款</span>
                    <span v-if="detail.state==10">已关单</span>
                </td>
                <td>{{DateFormat(detail.billTime)}}</td>
                <td>{{DateFormat(detail.payTime)}}</td>
                <td>{{detail.remark}}</td>
            </tr>
        </table>

    </div>

    <!-- 按钮 -->
    <div class="btnGroup">
        <el-button type="primary" class="iconfont icon-dayin" v-print="printObj" id="btnPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
export default {
    props:['dataList'],
    data(){
        return{
            printTime:moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            printObj: {
              id:'printData',
              preview: false,
              popTitle: '打印预览',
            }
        }
    },
    methods:{
        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
    
}
</script>

<style scoped>
#printData {font-size: 12px;overflow: hidden;} /**防止多打印一张 */
#printData .titleLeft{display: flex;align-items: flex-end;}
#printData .title {text-align: center;font-size: 14px;}
#printData .titleRight{display: flex;align-items: flex-end;justify-content: flex-end;}
#printData .DataInfo{width: 100%;}
#printData .DataInfo td {padding: 5px 0px;}

#printData .DataDetail {width: 100%;border-collapse: collapse;}
#printData .DataDetail th {border: 1px solid #ccc;}
#printData .DataDetail td {border: 1px solid #ccc;}
</style>