<template>
  <div class="SingleInfo">
    <el-divider></el-divider>

    编辑指纹信息页面
  </div>
</template>

<script>
export default {
    
}
</script>

<style>

</style>