<template>
  <div>

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="大区">
                <el-select v-model="info.search.areaNumberList" multiple collapse-tags collapse-tags-tooltip class="search-200">
                    <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="状态">
                <el-select v-model="info.search.state" class="search-150">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="新建单"></el-option>
                    <el-option :value="2" label="待审核"></el-option>
                    <el-option :value="3" label="已驳回"></el-option>
                    <el-option :value="4" label="已审核"></el-option>
                    <el-option :value="5" label="已报损"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button v-if="CheckUserButtonAuth('BtnLossAdd')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
            <el-popconfirm v-if="CheckUserButtonAuth('BtnLossDelete')"
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>   
            <el-button class="iconfont icon-dingdan" type="warning" plain @click="OpenCreateFinance()">生成财务单</el-button>    
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>    
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" show-summary border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="55" />  
        <el-table-column label="报损单号" width="170">
            <template #default="scope">
                {{scope.row.lossNumber}}
            </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="部门" />

        <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
        </el-table-column>

        <el-table-column prop="totalPrice" label="总价(元)" />
    
        <el-table-column label="状态">
            <template #default="scope">
                <el-tag v-if="scope.row.state ==1" type="info">新建单</el-tag>
                <el-tag v-if="scope.row.state ==2" type="warning">待审核</el-tag>
                <el-tag v-if="scope.row.state ==3" type="danger">已驳回</el-tag>
                <el-tag v-if="scope.row.state ==4" type="success">已审核</el-tag>
                <el-tag v-if="scope.row.state ==5" type="primary">已报损</el-tag>
            </template>
        </el-table-column>

        <el-table-column label="财务标识">
            <template #default="scope">
                <el-tag v-if="scope.row.financeFlag == 0" type="danger">未生成</el-tag>
                <el-tag v-if="scope.row.financeFlag == 1" type="info">已生成</el-tag>
            </template>
        </el-table-column>

        <el-table-column label="创建日期" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.createTime"
                    placement="top"
                >
                <span>{{DateFormat(scope.row.createTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.lossNumber)">详情</el-button>
            <el-popconfirm v-if="CheckUserButtonAuth('BtnLossDelete')"
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.lossNumber)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditLoss :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditLoss>
    </el-drawer>

    <!-- 生成财务单-弹框 -->
    <el-dialog title="生成财务单"
    v-model="BoxCreateFinance.isVisible"
    width="45%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
        <BoxCreateFinanceNoCash :financeInfo="financeInfo" ref="newFinanceInfo"></BoxCreateFinanceNoCash>
        <template #footer>            
            <el-button type="primary" @click="btnCreateFinance()">生成财务单</el-button>
            <el-button @click="BoxCreateFinance.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'     //用户关联部门列表
import UserAreaList from '@/utils/mixins/UserAreaList.js'  //用户关联大区列表
import { ElLoading, ElMessage } from 'element-plus'
import EditLoss from '@/views/Wms/Loss/EditLoss.vue'
import BoxCreateFinanceNoCash from '@/components/common/BoxCreateFinanceNoCash.vue'  //非现金财务单
export default {
    components:{
        EditLoss,
        BoxCreateFinanceNoCash,
    },
    mixins:[UserDptList,UserAreaList],
    data(){
        return{
            dataList:[],  //Table数据
            info:{                
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    selectDepartmentList:[],   //部门列表
                    areaNumberList:[],    //大区列表
                    state:0,
                    datePoint:'',
                }
            },
            financeInfo:{
                numberList:[],  //编号数组
                priceList:[], 
                departmentId:0,
                totalPrice:0,  //总价 
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'新增报损单'
            },
            //生成财务单-弹出框
            BoxCreateFinance:{
                isVisible:false,
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,   //大区列表
                state:this.info.search.state,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.wms.ListLoss(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;  //获取数据
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e)
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.lossNumber)
            this.financeInfo.numberList = selection.map(item=>item.lossNumber)  //编号数组
            this.financeInfo.priceList = selection.map(item=> parseFloat(item.totalPrice))  //计算总金额
            this.financeInfo.departmentId = selection.find(item=>item).departmentId;  //部门
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //新增编辑部门信息
        btnEditInfo(lossNumber){
            this.info.id = lossNumber
            if(lossNumber==0){
                this.BoxEditInfo.title = "新增报损单"
            }else{
                this.BoxEditInfo.title = "编辑报损单"
            }
            this.BoxEditInfo.isVisible = true;
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(lossNumber){
            let param = [lossNumber]
            this.$api.wms.DeleteLossAllInfo(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.wms.DeleteLossAllInfo(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.selectDepartmentList = [];  //部门列表
            this.info.search.areaNumberList=[];  //大区列表
            this.info.search.state=0;
            this.info.search.datePoint = '';
            this.info.page = 1;
            this.info.ids = [];
            this.GetDataList();
        }, 

        //打开-报损单生成财务单-弹出框
        OpenCreateFinance(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要生成财务单的单据')
                return false
            }

            //计算总金额
            this.financeInfo.totalPrice = 0;
            this.financeInfo.priceList.forEach(element => {
                this.financeInfo.totalPrice =  this.financeInfo.totalPrice + element
            });
            this.BoxCreateFinance.isVisible = true;
        },
        //保存-报损单生成财务单
        btnCreateFinance(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            this.$api.wms.LossCreateFinanceNoCash(this.$refs.newFinanceInfo.allInfo).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //关闭
                    this.BoxCreateFinance.isVisible = false;
                    this.GetDataList();  //重新加载
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

    },
    mounted(){
        this.GetDataList();  //加载数据
    }
}
</script>

<style>

</style>