<template>
  <div>
    <el-divider></el-divider>
    <!-- 修改系统信息 -->

    <el-descriptions :column="2"
      border>
        <el-descriptions-item label="系统名称">
          {{info.systemName}}
        </el-descriptions-item>

        <el-descriptions-item label="企业名称">
          {{info.companyName}}
        </el-descriptions-item>

        <el-descriptions-item label="法人">
          {{info.legalPerson}}
        </el-descriptions-item>

        <el-descriptions-item label="联系人">
          {{info.linkMan}}
        </el-descriptions-item>

        <el-descriptions-item label="联系电话">
          {{info.linkPhone}}
        </el-descriptions-item>

        <el-descriptions-item label="省市区">
          {{}}
        </el-descriptions-item>

        <el-descriptions-item label="地址">
          {{info.address}}
        </el-descriptions-item>

        <el-descriptions-item label="注册资金(万)">
          {{info.registerCapital}}
        </el-descriptions-item>

        <el-descriptions-item label="注册日期">
          {{info.registerDate}}
        </el-descriptions-item>

        <el-descriptions-item label="状态">
          <span v-if="info.state == 1">正常</span>
          <span v-if="info.state == 2" type="info">关闭</span>
        </el-descriptions-item>

        <el-descriptions-item label="有效期">
          <span>{{DateFormat(info.termTime)}}</span>
        </el-descriptions-item>

    </el-descriptions>


    <!-- 按钮 -->
    <div class="btnGroup">
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
export default {
    name:'UpdateSystemInfo',
    data(){
        return{
            info:{
                id:0,
                systemNumber:'',
                systemName:'',
                domainName:'',
                state:0,
                certNumber:'',
                staff:0,
                companyName:'',
                legalPerson:'',
                license:'',
                linkMan:'',
                linkPhone:'',
                provinceCode:'',
                cityCode:'',
                areaCode:'',
                address:'',
                selectArea:[]
            },
            areaList:[],   //地址列表
        }
    },
    methods:{
        //加载数据信息
        GetDataInfo(){
            this.$api.system.SingleCurrentSystemInfo().then(res=>{
                if(res.data.status === 200){
                    this.info = res.data.data;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.GetDataInfo();
    }
}
</script>

<style scoped>
    .ImgTheme{height: 80px;width: 80px;border: 1px solid #ccc;padding: 5px;}
</style>