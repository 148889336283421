<template>
  <div>
    <el-divider></el-divider>

    <!-- 工资单主信息 -->
    <div>
      <el-form :inline="true"
        v-model="info"
        label-width="80px"
        label-position="right">
            <el-row>

                <el-col :span="12">
                    <el-form-item label="账户名称">
                      <template #label>
                            <span style="color:red;">*</span>
                            <span>账户名称</span>
                        </template>
                      <el-input v-model="info.accountName" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="账户余额">
                      <el-input-number  v-model="info.totalPrice" :precision="2" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="初始日期">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>初始日期</span>
                        </template>
                        <el-date-picker v-model="info.startDate" type="date" placeholder="初始日期" value-format="YYYY-MM-DD" style="width:200px"/>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="初始余额">
                      <el-input-number  v-model="info.startPrice" :precision="2" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label="手续费率">
                    <el-input-number v-model="info.rate" :min="0" :precision="2" :step="0.1" :max="100" class="search-200"></el-input-number>
                    <span>%</span>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="统计标识">
                      <el-select v-model="info.statFlag" class="search-200">
                        <el-option :value="0" label="关闭"></el-option>
                        <el-option :value="1" label="开启"></el-option>
                      </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="零售标识">
                      <el-select v-model="info.retailFlag" class="search-200">
                        <el-option :value="0" label="关闭"></el-option>
                        <el-option :value="1" label="开启"></el-option>
                      </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="排序">
                      <el-input-number  v-model="info.sort" :min="0" :max="100" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

            </el-row>
      </el-form>      
    </div>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElLoading, ElMessage } from 'element-plus'
import moment from 'moment'
export default {
  name:'EditAccount',
  props:['editId'],
  data(){
    return{
      info:{
        id:0,
        accountNumber:'',
        accountName:'',
        totalPrice:0,
        startPrice:0,
        startDate:moment(new Date()).format("YYYY-MM-DD"),
        statFlag:0,
        retailFlag:0,  //零售标识
        sort:0
      }
    }
  },
  methods:{
    //获取数据
    GetDataInfo(){
      if(this.editId!=0){
        let param = {
          accountNumber:this.editId
        }
        this.$api.finance.SingleAccount(param).then(res=>{
          if(res.data.status === 200){
            this.info = res.data.data;
          }
        })
      }else{
        this.btnClearData();
      }      
    },

    //保存数据 type:1:保存并关闭 2:保存并新增
    btnSaveData(type){
      //打开遮罩层
      const loading = ElLoading.service({
          lock:true,
          text:'正在提交,请稍候!',
          background:'rgba(0, 0, 0, 0.7)',
      })
      //保存
      this.$api.finance.SaveAccount(this.info).then(res=>{
        loading.close();
        if(res.data.status === 200){
              ElMessage.success(res.data.msg);
              //
              if(type==1){
                this.btnClose();
              }else{
                this.btnClearData();
              }
              
          }else{
              ElMessage.error(res.data.msg);
          }
      })
    },

    //清空数据
    btnClearData(){
      this.info = {
          id:0,
          accountNumber:'',
          accountName:'',
          totalPrice:0,
          startPrice:0,
          startDate:moment(new Date()).format("YYYY-MM-DD"),
          statFlag:0,
          retailFlag:0,
          sort:0
        }
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    }

  },
  mounted(){
    this.GetDataInfo()
  }
}
</script>

<style scoped>

</style>