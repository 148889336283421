<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="editInfo.activeName" class="editTab">
        <el-tab-pane label="入库单" name="tabBill">
            <TabBillIn ref="tabBillIn" @OnChangeArea="onChangeArea"></TabBillIn>

            <el-divider>入库单明细</el-divider>
            <TabBillInDetail ref="tablBillInDetail"></TabBillInDetail>
            
        </el-tab-pane>

        <el-tab-pane label="附加成本项" name="tabExtra">
            <TabBillInExtra></TabBillInExtra>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabBillInRecord></TabBillInRecord>
            
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabBillInState></TabBillInState>
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="billInInfo.info">
        <el-button type="warning" v-if="CheckUserButtonAuth('BtnBillInSelectBill') && billInInfo.info.state!=3 && billInInfo.info.inNumber =='' " @click="btnOpenBoxBill()">选择入库单据</el-button>
        <el-button type="primary" v-if="billInInfo.info.state<=2" @click="btnSaveData">保存</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnBillInConfirm') && billInInfo.info.inNumber !='' && billInInfo.info.state <= 2" type="warning" @click="btnUpdateBillInByState(3)">确认入库</el-button>
        <el-button class="iconfont icon-dayin" v-if="billInInfo.info.state==3" @click="btnShowBoxPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 打印框 -->
    <el-dialog title="打印预览"
    v-model="BoxPrint.isVisible"
    width="70%">
        <PrintView v-if="BoxPrint.isVisible" @closeBox="btnClosePrint"></PrintView>
    </el-dialog>

    <!-- 选择单据 - 弹出框 -->
    <el-dialog title="选择入库单据"
    v-model="BoxBill.isVisible"
    width="85%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
        <BoxOriginIn ref="OriginInfo"></BoxOriginIn>
        <template #footer>
            <el-button type="primary" @click="btnSelectOrigin">选择</el-button>
            <el-button @click="btnCloseBoxBill">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import TabBillIn from '@/views/Wms/BillIn/TabBillIn.vue'
import TabBillInDetail from '@/views/Wms/BillIn/TabBillInDetail.vue'
import TabBillInExtra from '@/views/Wms/BillIn/TabBillInExtra.vue'
import TabBillInRecord from '@/views/Wms/BillIn/TabBillInRecord.vue'
import TabBillInState from '@/views/Wms/BillIn/TabBillInState.vue'
import PrintView from '@/views/Wms/BillIn/PrintView.vue'
import BoxOriginIn from '@/views/Wms/BillIn/BoxOriginIn.vue'

import { ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
export default {
    props:['editId','dataList'],
    components:{
        TabBillIn,
        TabBillInDetail,
        TabBillInExtra,
        TabBillInRecord,
        TabBillInState,
        PrintView,
        BoxOriginIn
    },
    data(){
        return{
            editInfo:{
                activeName:'tabBill',
                inNumber:this.editId,   //当前记录编号
            },
            //打印框
            BoxPrint:{
                isVisible:false,
            },
            //选择单据-弹出框
            BoxBill:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Erp',['billInInfo']),
        ...mapState('Master',['currentUserNumber']),
    },
    methods:{
        ...mapMutations('Erp',['SetBillInInfo']),

        //加载数据
        GetDataInfo(){
            if(this.editInfo.inNumber !=0){
                let param = {
                    inNumber:this.editInfo.inNumber
                }
                this.$api.wms.SingleBillInAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetBillInInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存数据
        btnSaveData(){
            this.$api.wms.SaveBillInAllInfo(this.billInInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //更新编号
                    this.billInInfo.info.inNumber = res.data.data;
                    this.editInfo.inNumber = res.data.data;
                    this.GetDataInfo();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //更新入库状态
        btnUpdateBillInByState(state){
            let param = {
                inNumber:this.billInInfo.info.inNumber,
                state:state
            };
            this.$api.wms.UpdateBillInByState(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //更新状态
                    this.editInfo.inNumber = this.billInInfo.info.inNumber;
                    this.GetDataInfo();
                    //关闭
                    //this.btnClose()
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //清空数据
        async btnClearData(){
            var newInfo = {
                info:{
                    id:0,
                    systemNumber:'',
                    inNumber:'',   //新单号
                    departmentId:0,
                    areaNumber:'',
                    areaName:'',
                    originNumber:'',
                    originType:20, //新增默认：20
                    state:1,
                    tradeType:0,
                    tradeNumber:'',
                    tradeName:'',
                    receiver:this.currentUserNumber, //当前用户编号
                    inTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                },
                detailList:[],
                extraList:[],
                recordList:[],
                stateList:[]
            }
            this.SetBillInInfo(newInfo);      
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.inNumber === this.editInfo.inNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.editInfo.inNumber = prevInfo.inNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.inNumber === this.editInfo.inNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.editInfo.inNumber = prevInfo.inNumber
                this.GetDataInfo()
            }
        },

        //打开打印框
        btnShowBoxPrint(){
            this.BoxPrint.isVisible =true;
        },
        //关闭打印框
        btnClosePrint(){
            this.BoxPrint.isVisible =false;
        },


        //打开选择单据-弹框
        btnOpenBoxBill(){
            this.BoxBill.isVisible = true;
        },
        //关闭选择单据-弹框
        btnCloseBoxBill(){
            this.BoxBill.isVisible = false;
        },
        //选择原始单据
        btnSelectOrigin(){
            let originInfo = this.$refs.OriginInfo.selectedInfo;  //获取选中的单据主信息
            let originDetailList = this.$refs.OriginInfo.selectedDetail;  //获取选中的单据明细列表
            //若有数据的话
            if(originInfo.billNumber !='' && originInfo.billNumber!=undefined){
                originDetailList.forEach(element => {
                    element.originItemId = element.id;
                    element.id = 0;
                    element.isEdit = 1;
                    element.planNum = element.planNum - element.realNum;  //计划数量 = 原计划数量 - 已完成数量
                    element.realNum = element.planNum;  //实际数量 = 计划数量
                    //#region 计划最小单位数量
                    let param2 = {
                        skuNumber:element.skuNumber,
                        unitId:element.unitId,
                        unitNum:element.planNum
                    }
                    this.$api.goods.GetMinUnitTotalNum(param2).then(res=>{
                        if(res.data.status === 200){
                            element.planMinUnitNum = res.data.data;
                        }
                    })
                    //#endregion
                    //#region 加载单位列表
                    let param = {
                        skuNumber:element.skuNumber
                    }
                    this.$api.goods.GetSkuUnitList(param).then(res=>{
                        if(res.data.status === 200){
                            element.skuUnitList = res.data.data;
                        }
                    })
                    //#endregion
                });
                
                var newInfo = {
                    info:{
                        id:0,
                        systemNumber:'',  
                        inNumber:'',  //新单号
                        departmentId:originInfo.departmentId,
                        departmentName:originInfo.departmentName,  //部门名称
                        areaNumber:originInfo.areaNumber,  //大区编号
                        areaName:originInfo.areaName,    //大区名称 
                        originNumber:originInfo.billNumber,  //原始单据编号
                        originType:originInfo.billType, //原始单据类型
                        state:1,
                        tradeType:originInfo.tradeType,
                        tradeNumber:originInfo.tradeNumber,
                        tradeName:originInfo.tradeName,
                        receiver:this.currentUserNumber, //当前用户编号
                        receiveName:'',
                        inTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    },
                    detailList:originDetailList,
                    extraList:[],  //附加成本项
                    recordList:[],
                    stateList:[]
                }   
                this.SetBillInInfo(newInfo);
            }            
            this.btnCloseBoxBill();  //关闭弹框
        },

        //当大区发生改变时
        onChangeArea(){
            this.$refs.tablBillInDetail.onGetWhListByAreaNumber(1);
        },

    },
    mounted(){
        this.GetDataInfo();
    }
}
</script>

<style>

</style>