import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import App from './App.vue'
import store from './store/index.js'
import router from './router/index.js'
import api from './api/index'  //Api请求总入口
import mixin from './utils/mixin.js'  //混合
// import * as echarts from 'echarts'  //Echarts 图标组件
import print from 'vue3-print-nb'   //打印组件
import UUID from 'vue-uuid'

// 阿里图标
import './assets/font/iconfont.css'
/*导入全局样式*/
import './assets/css/global.css'


const app = createApp(App)

app.config.globalProperties.$api = api;
// app.config.globalProperties.$echarts = echarts;  //Echarts

app.mixin(mixin)
app.use(ElementPlus,{locale:zhCn})  //国际化配置
app.use(store)
app.use(router)
app.use(print)      //打印组件
app.use(UUID)   //uuid组件
app.mount('#app')


