<template>
  <div>
      <el-divider></el-divider>
      
      <el-form :inline="true"
        v-model="info"
        label-width="80px"
        label-position="right">
            <el-row>

                <el-col :span="24">
                    <el-form-item label="品牌名称">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>品牌名称</span>
                        </template>
                        <el-input v-model="info.brandName" class="search-200"></el-input>
                    </el-form-item>
                </el-col>
                
                <el-col :span="24">
                    <el-form-item label="排列顺序">
                        <el-input-number v-model.number="info.sort" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item label="品牌图标">
                      <!-- 上传图片 -->
                      <el-image
                          class="ImgTheme"
                          v-if="info.logo"
                          :src="info.logo"
                          :preview-src-list="[info.logo]"
                          :hide-on-click-modal="true"
                          fit="fill">
                      </el-image>

                      <el-upload
                      :action="GetFileUrl2('Brand')"
                      :headers="GetHeader"
                      :show-file-list="false"
                      :before-upload="BeforeUpload"
                      :on-success="UploadSuccess">
                          <el-tag class="UploadImg">上传图片</el-tag>
                      </el-upload>
                      
                  </el-form-item>
                </el-col>

            </el-row>
      </el-form>

      <!-- 功能按钮 -->
      <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
      </div>

  </div>
</template>

<script>
import Authorize from '@/utils/authorize.js'
import { ElMessage } from 'element-plus'
export default {
    name:'EditBrand',
    props:['editId'],
    data(){
        return{
            info:{
                brandId:0,
                brandName:'',
                logo:'',
                sort:0
            },
        }
    },
    computed:{
      //添加Token - 重要
      GetHeader(){
          return {
            "Authorization":"Bearer "+ Authorize.getToken()
          }
        },
    },
    methods:{
      //加载数据
      GetDataInfo(){
          if(this.editId!=0){
              let param = {
                  brandId:this.editId
              }
              this.$api.goods.SingleBrand(param).then(res=>{
                  if(res.data.status === 200){
                      this.info = res.data.data;
                  }else{
                      ElMessage.error(res.data.msg);
                  }
              })
          }
          else
          {
              this.btnClearData();
          }
      },

      //保存信息 -type:1:保存并关闭 2:保存并新增
      btnSaveData(type){
        this.$api.goods.SaveBrand(this.info).then(res=>{
          if(res.data.status === 200){
            ElMessage.success(res.data.msg);
            //类型
            if(type==1){
              this.btnClose();
            }else{
              this.btnClearData();
            }            
          }
          else{
            ElMessage.error(res.data.msg)
          }
        }).catch(e=>{
          console.log(e)
        })
        
      },

      //清空实体
      btnClearData(){
        this.info = {
            brandId:0,
            brandName:'',
            logo:'',
            sort:0
        }
      },

      //关闭按钮
      btnClose(){
          this.$emit('closeBox')  //关闭弹出框
      },


      //上传图片之前操作
      BeforeUpload(file){
        const fileType = file.type;
        const fileSize = file.size;
        
        if(fileType !== 'image/jpeg' && fileType !== 'image/png'){
            ElMessage.error('上传图片格式:jpeg|png')
            return false;
        }
        //文件大小
        if(fileSize/1024/1024 > 2){
            ElMessage.error('上传文件大小不能超过2Mb')
            return false;
        }

      },
      //上传完成
      UploadSuccess(res){
        if(res.status === 200){
          this.info.logo = res.data
        }
      },
      //上传失败
      UploadError(e){
        ElMessage.error(e)
      }

    },
    mounted(){
      this.GetDataInfo();
    }
}
</script>

<style scoped>
 .ImgTheme{height: 80px;width: 80px;border: 1px solid #ccc;padding: 5px;}
  .UploadImg{margin: 5px;}
</style>