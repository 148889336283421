/**
 * 营销相关
 */

 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const market = {

    //#region 内容营销

    //获取列表
    ListContent(param){
        return axios.post(`${base.base}/Content/ListContent`,param)
    },

    //获取单个信息
    SingleContentInfo(param){
        return axios.get(`${base.base}/Content/SingleContentInfo`,{
            params:param
        })
    },

    //保存
    SaveContentInfo(param){
        return axios.post(`${base.base}/Content/SaveContentInfo`,param)
    },

    //删除
    DeleteContent(param){
        return axios.post(`${base.base}/Content/DeleteContent`,param)
    },

    //更新状态
    


    //#endregion


 }

 export default market