<template>
  <div>
    <!-- 账户统计 -->
    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">
            
            <el-form-item label="账户">
                <el-select v-model="info.search.accountList" multiple collapse-tags collapse-tags-tooltip class="search-200">
                    <el-option v-for="account in selectAccountList" :key="account.accountNumber" :label="account.accountName" :value="account.accountNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>                    
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
                    </el-tooltip>
                </el-button-group>
            </el-col>
        </el-row>

        <!-- 数据列表 -->
        <el-table :data="dataList" border show-summary style="width: 100%" height="680">
            <el-table-column prop="id" type="selection" width="70"></el-table-column>
            <el-table-column prop="accountName" label="账户名称"></el-table-column>
            <el-table-column prop="startSurplus" label="期初余额"></el-table-column>
            <el-table-column prop="inPrice" label="收款发生额"></el-table-column>
            <el-table-column prop="outPrice" label="付款发生额"></el-table-column>
            <el-table-column prop="surplusPrice" label="发生额"></el-table-column>
            <el-table-column prop="endSurplus" label="期末余额"></el-table-column>
        </el-table>

        

    </div>


  </div>
</template>

<script>
import accountList from '@/utils/mixins/AccountList.js'
import { ElLoading, ElMessage } from 'element-plus'
export default {
    mixins:[accountList],
    data(){
        return{
            dataList:[],  //数据
            info:{
                search:{
                    datePoint:'',
                    accountList:[],
                }
            }
        }
    },
    methods:{

        //获取数据列表
        GetDataList(){
            //统计日期
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            let param = {
                accountList:this.info.search.accountList,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
            }
            // console.log(param)
            this.$api.stat.StatisticsAccount(param).then(res=>{
                console.log(res.data)
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.accountList =[];
            this.GetbeginDate();
            this.GetDataList();
        },

        //计算日期
        GetbeginDate(){
            var date = new Date();
            date.setDate(date.getDate()-7);
            var year = date.getFullYear();
            var month = (date.getMonth() + 1).toString().padStart(2,'0');
            var day = date.getDate().toString().padStart(2,'0');
            var startDate = year + '-' + month + '-'+day;
            this.beginDate = this.DateFormat(startDate);
            this.endDate = this.DateFormat(new Date());
            this.info.search.datePoint = [this.beginDate,this.endDate];
        },

        //导出数据到Excel
        btnExportInfo(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在导出数据,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            //统计日期
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            let param = {
                accountList:this.info.search.accountList,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
            }
            this.$api.stat.ExportStatisticsAccount(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    const alink = document.createElement('a');
                    alink.href = res.data.data;
                    document.body.appendChild(alink);
                    alink.click();
                    document.body.removeChild(alink)
                }else{
                    ElMessage.error(res.data.msg);
                }
            })

        }

    },
    mounted(){
         this.GetbeginDate();  //设置开始日期
         this.GetDataList();  //加载数据
    },
}
</script>

<style>

</style>