<template>
  <div>
    <div class="skuBtnList" v-if="billInInfo.info && billInInfo.info.state == 1 && billInInfo.info.originType == 20">
        <el-button v-if="CheckUserButtonAuth('BtnBillInSelectGoods')" class="iconfont icon-add" type="primary" plain @click="BtnSelectSku">选择产品</el-button>
    </div>

      <!-- 列表 -->
      <el-table :data="billInInfo.detailList" v-if="billInInfo.info" border show-summary style="width: 100%">

          <el-table-column label="入库仓库" min-width="90">
              <template v-slot="scope">
                <el-select v-if="scope.row.isEdit==1 && billInInfo.info.originType == 20" v-model="scope.row.warehouseNumber">
                    <el-option v-for="warehouse in areaWhList" :key="warehouse.warehouseNumber" :label="warehouse.warehouseName" :value="warehouse.warehouseNumber" />
                </el-select>
                <span v-else>{{scope.row.warehouseName}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
          <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>

          <el-table-column prop="skuName" label="产品名称" min-width="100">
              <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.spuName"
                        placement="top">
                        <div class="cellOverFlow">{{scope.row.spuName}}</div>
                    </el-tooltip>
                </template>
          </el-table-column>

          <el-table-column label="规格" min-width="100">
              <template v-slot="scope">
                  <span>{{scope.row.skuName}}</span>
              </template>
          </el-table-column>

          <el-table-column label="单位" min-width="90">
                <template v-slot="scope">
                    <el-select v-if="scope.row.isEdit==1 && billInInfo.info.state == 1 && (billInInfo.info.originType == 20)" v-model="scope.row.unitId"  @change="ChangeSkuUnit">
                        <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
                    </el-select>
                    <span v-else>{{scope.row.unitName}}</span>
                </template>
          </el-table-column>

            <!-- 只有单据类型=入库单 才能看到单价和总价 列 -->
          <el-table-column prop="unitPrice" label="单价(元)" v-if="billInInfo.info.originType == 20" min-width="110">
              <template v-slot="scope">
                  <!-- originType=20 表示只有单据类型=入库单 时才可以编辑，其他单据类型不允许编辑 -->
                  <el-input-number v-if="scope.row.isEdit==1 && billInInfo.info.originType == 20 && billInInfo.info.state == 1" v-model="scope.row.unitPrice" :precision="2" @change="OnChangeRealNum" :controls="false" style="width:100px"></el-input-number>
                  <span v-else >{{scope.row.unitPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="planNum" label="计划数量" min-width="110">
              <template v-slot="scope">
                  <!-- originType=20 表示只有单据类型=入库单 时才可以编辑，其他单据类型不允许编辑 -->
                  <el-input-number v-if="scope.row.isEdit==1 && billInInfo.info.originType == 20 && billInInfo.info.state == 1" v-model="scope.row.planNum" :min="0" :precision="2" @change="OnChangPlanNum" :controls="false" style="width:100px"></el-input-number>
                  <span v-else >{{scope.row.planNum}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="realNum" label="实际数量" min-width="110">
              <template v-slot="scope">
                  <el-input-number v-if="scope.row.isEdit==1 && billInInfo.info.state == 1" v-model="scope.row.realNum" :min="0" :precision="2"  @change="OnChangeRealNum" :controls="false" style="width:100px"></el-input-number>
                  <span v-else >{{scope.row.realNum}}</span>
              </template>
          </el-table-column>
            
          <el-table-column prop="totalPrice" label="总价(元)" min-width="80" v-if="billInInfo.info.originType == 20">
              <template v-slot="scope">
                  <span>{{scope.row.totalPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope" v-if="billInInfo.info && billInInfo.info.state <=2">
                <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
                <!-- originType=20 表示只有单据类型=入库单 时才可以删除，其他单据类型不允许删除 -->
                <el-popconfirm
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.inNumber,scope.row.id,scope.$index)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
          </el-table-column>
      </el-table>

      <!-- 选择产品 -->
    <el-dialog :title="BoxSkuInfo.title" 
        v-model="BoxSkuInfo.isVisible"
        width="60%"
        :destroy-on-close="true">
            <SelectAllSkuList ref="SelectSkuInfo"></SelectAllSkuList>
        <template #footer>
            <el-button type="primary" @click="btnSelectData">选择</el-button>
            <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import SelectAllSkuList from '@/components/common/SelectAllSkuList.vue'
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    components:{
        SelectAllSkuList
    },
    data(){
        return{
            areaWhList:[],   //大区关联的仓库列表
            //选择产品-弹出框
            BoxSkuInfo:{
              isVisible:false,
              title:'选择产品'
            },
        }
    },
    computed:{
        ...mapState('Erp',['billInInfo']),
    },
    methods:{

        //选择产品
        BtnSelectSku(){
            this.BoxSkuInfo.title = "选择产品"
            this.BoxSkuInfo.isVisible = true
        },
        //保存选中产品
        btnSelectData(){
            let itemList = this.$refs.SelectSkuInfo.selectedItem
            if(itemList !=null && itemList.length>0){
                itemList.forEach(item => {
                    let newRow = {
                        id:0,
                        systemNumber:this.billInInfo.info.systemNumber,
                        inNumber:this.billInInfo.info.inNumber,
                        warehouseNumber:'',
                        warehouseName:'',
                        cid:item.cid,         //分类编号
                        cidName:item.cidName,    //分类名称
                        brandId:item.brandId,    //品牌编号
                        brandName:item.brandName,   //品牌名称
                        spuNumber:item.spuNumber,
                        spuName:item.spuName,
                        skuNumber:item.skuNumber,
                        skuName:item.skuName,
                        unitId:item.defUnitId,   //单位编号
                        unitName:item.defUnitName,
                        unitPrice:0,   //单价
                        planNum:1,
                        realNum:1,
                        totalPrice:0,
                        isDel:0,
                        isEdit:1,
                        skuUnitList:item.skuUnitList,   //单位列表
                    }
                    
                    setTimeout(()=>{
                        this.billInInfo.detailList.push(newRow)
                    },500);
                });
            }   
            //加载仓库列表 参数：2表示保留已选择了仓库的记录的值。
            this.onGetWhListByAreaNumber(2);         
            this.BoxSkuInfo.isVisible = false;
        },
        //编辑行
        EditRow(id){
            this.billInInfo.detailList = this.billInInfo.detailList.map(item=>{
                if(item.id === id){
                    item.isEdit = 1;
                    //#region 加载单位列表
                    let param = {
                        skuNumber:item.skuNumber
                    }
                    this.$api.goods.GetSkuUnitList(param).then(res=>{
                        if(res.data.status === 200){
                            item.skuUnitList = res.data.data;
                        }
                    }) 
                    //#endregion
                }
                return item
            })

            this.onGetWhListByAreaNumber(2);  //加载仓库列表 
        },
        //删除明细
        DeleteRow(inNumber, id, index){
            this.billInInfo.detailList.splice(index,1)
            if(id !=0){
                let param = {
                    inNumber:inNumber,
                    id:id
                }
                this.$api.wms.DeleteBillInDetail(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }
        },

        //计划数量改变时
        OnChangPlanNum(){
            this.billInInfo.detailList = this.billInInfo.detailList.map(item=>{
                item.realNum = item.planNum;  //实际数量 = 计划数量
                item.totalPrice = this.myRound(item.unitPrice * item.realNum,5);
                return item
            })
        },
        //实际数量改变时
        OnChangeRealNum(){
            this.billInInfo.detailList = this.billInInfo.detailList.map(item=>{
                item.totalPrice = this.myRound(item.unitPrice * item.realNum,5);
                return item
            })
        },
        //当单位发生变化是 20:入库单->不差库存了 6:加工单->换算计划数量
        ChangeSkuUnit(){
            this.billInInfo.detailList = this.billInInfo.detailList.map(item=>{
                //只有加工单需要处理
                if(this.billInInfo.info.originType == 6){
                    //#region 重新计算指定单位的数量
                    if(item.isEdit ==1){
                        let param4 = {
                            skuNumber:item.skuNumber,
                            unitId:item.unitId,   //单位编号
                            minUnitNum:item.planMinUnitNum,  //计划最小单位数量
                        }
                        this.$api.goods.GetUnitNumByUnitId(param4).then(res=>{
                            if(res.data.status === 200){
                                item.planNum = res.data.data;
                                item.realNum = res.data.data;
                            }else{
                                item.planNum = 0;
                                item.realNum = 0;
                            }
                        })
                    }                    
                    //#endregion
                }
                return item
            })
        },
        

        //加载指定大区下的仓库列表 type: 1:清空仓库选项 2:保留仓库选项
        onGetWhListByAreaNumber(type){ 
            if(this.billInInfo.info.areaNumber!=''){
                let param = {
                    areaNumber:this.billInInfo.info.areaNumber,
                }
                this.$api.wms.GetWhListByAreaNumber(param).then(res=>{
                    if(res.data.status === 200){
                        this.areaWhList = res.data.data;
                    }else{
                        this.areaWhList = [];
                    }
                })
            }else{
                this.areaWhList = [];  
            }  

            //清空仓库选项
            if(type==1){
                this.billInInfo.detailList = this.billInInfo.detailList.map(item=>{
                    item.warehouseNumber = '';
                    return item
                })
            }
        },

    },
    mounted(){
    }
}
</script>

<style>

</style>