<template>
  <div>

    <!-- 选择产品 -->
    <div class="skuBtnList" v-if="returnRetailInfo.info && returnRetailInfo.info.state <=1 && returnRetailInfo.info.originNumber=='' ">
      <el-button class="iconfont icon-add" type="primary" plain @click="BtnSelectSku">选择产品</el-button>
    </div>
    
    <!-- 列表 -->
    <el-table :data="returnRetailInfo.detailList" border show-summary style="width: 100%">
        <el-table-column prop="warehouseName" label="仓库" min-width="90"/>
        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="90">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" min-width="90"/>
        <el-table-column label="单位" width="100">
            <template v-slot="scope">
                <el-select v-if="scope.row.isEdit==1" v-model="scope.row.unitId"  @change="ChangeSkuUnit(scope.$index)">
                    <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
                </el-select>
                <span v-else>{{scope.row.unitName}}</span>
            </template>
        </el-table-column>

        <el-table-column label="成本单价(元)" min-width="100">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.costUnitPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="unitPrice" label="零售价(元)" min-width="110">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1 && returnRetailInfo.info.originNumber=='' " v-model="scope.row.unitPrice" :precision="2" :controls="false" style="width:100px" @change="OnChangeNum"></el-input-number>
                <span v-else >{{scope.row.unitPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="realNum" label="数量" min-width="110">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.realNum" :min="1" :max="scope.row.maxNum" :precision="2" :controls="false" style="width:100px" @change="OnChangeNum"></el-input-number>
                <span v-else >{{scope.row.realNum}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="totalPrice" label="总价(元)" min-width="110">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.totalPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="130" fixed="right" v-if="returnRetailInfo.info && (returnRetailInfo.info.state ==1)">
            <template v-slot="scope">
                <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
                <el-popconfirm 
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.billNumber,scope.row.id,scope.$index)">
                    <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>

    </el-table>

    <!-- 选择产品 -->
    <el-dialog :title="BoxSkuInfo.title" 
        v-model="BoxSkuInfo.isVisible"
        width="65%"
        :destroy-on-close="true">
            <SelectStockSkuList :areaNum="returnRetailInfo.info.areaNumber" ref="SelectSkuInfo"></SelectStockSkuList>
        <template #footer>
            <div style="padding-top:35px">
                <el-button type="primary" @click="btnSelectData">选择</el-button>
                <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
            </div>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import SelectStockSkuList from '@/components/common/SelectStockSkuList.vue'  //库存产品列表
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
  components:{
    SelectStockSkuList,
  },
  data(){
    return{
        //选择产品-弹出框
        BoxSkuInfo:{
            isVisible:false,
            title:'选择库存产品'
        },
    }
  },
  computed:{
    ...mapState('Erp',['returnRetailInfo'])
  },
  methods:{
    //选择产品
    BtnSelectSku(){
        this.BoxSkuInfo.isVisible = true
    },

    //保存选中产品
    btnSelectData(){
        let itemList = this.$refs.SelectSkuInfo.selectedItem
        let newtotalPrice = 0;  //总价
        if(itemList !=null && itemList.length>0){
            itemList.forEach(item => {
                let newRow = {
                    id:0,
                    systemNumber:'',
                    retailNumber:'',
                    warehouseNumber:item.warehouseNumber,
                    warehouseName:item.warehouseName,
                    cid:item.cid,
                    cidName:item.cidName,
                    brandId:item.brandId,
                    brandName:item.brandName,
                    spuNumber:item.spuNumber,
                    spuName:item.spuName,
                    skuNumber:item.skuNumber,
                    skuName:item.skuName,
                    costUnitPrice:item.defUnitPrice,  //成本单价：默认单位单价
                    unitId:item.defUnitId,  //单位编号：默认单位编号
                    unitPrice:0, //零售价
                    realNum:1,  //数量 
                    totalPrice:0,  //总价
                    stockNum:item.defUnitStockNum,  //库存数量
                    skuUnitList:item.skuUnitList,  //单位列表
                    remark:'',
                    isDel:0,
                    isEdit:1
                }
                newtotalPrice += newRow.totalPrice;  //计算总价
                this.returnRetailInfo.detailList.push(newRow)
            });
        }
        this.returnRetailInfo.info.totalPrice = this.myRound(newtotalPrice,2);  //应付金额
        this.returnRetailInfo.info.realPrice = this.myRound(newtotalPrice,2);  //实付金额
        this.BoxSkuInfo.isVisible = false  //关闭选择产品弹出框
    },

    //编辑行
    EditRow(id){
        this.returnRetailInfo.detailList = this.returnRetailInfo.detailList.map(item=>{
            if(item.id === id){
                item.isEdit = 1
            }
            return item
        })
    },

    //删除销售明细
    async DeleteRow(billNumber, id, index){
        this.returnRetailInfo.detailList.splice(index,1)
        if(id !=0){
            let param = {
                billNumber:billNumber,
                id:id
            }
            await this.$api.erp.DeleteReturnRetailDetail(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })    
        }
        //重新计算
        this.OnChangeNum();
    },

    //当明细数量、价格改变时触发
    OnChangeNum(){
        let newtotalPrice = 0;   //主表-总价
        this.returnRetailInfo.detailList = this.returnRetailInfo.detailList.map(item=>{
            item.totalPrice = this.myRound(item.unitPrice * item.realNum,2)
            newtotalPrice += item.totalPrice
            return item
        })
        this.returnRetailInfo.info.totalPrice = this.myRound(newtotalPrice,2)  //应付金额
        this.returnRetailInfo.info.realPrice = this.myRound(newtotalPrice,2);  //实付金额
    },

    //当单位发生变化时
    ChangeSkuUnit(index){
        this.returnRetailInfo.detailList = this.returnRetailInfo.detailList.map((item,index2)=>{
            if(index === index2){
                //#region 单位发生变化时-重新计算成本价、库存
                let param3 = {
                    warehouseNumber:item.warehouseNumber,
                    skuNumber:item.skuNumber,
                    unitId:item.unitId
                }
                this.$api.wms.GetSkuUnitStockInfo(param3).then(res=>{
                    if(res.data.status === 200){
                        item.stockNum = res.data.data.unitStock;  //库存
                        item.costUnitPrice = res.data.data.unitPrice;  //成本单价
                    }
                })
                //#endregion
            }
            
            return item
        })  
    },


  }
}
</script>

<style>

</style>