<template>
  <div>

    <el-form :inline="true"
    v-model="systemInfo.userInfo"
    label-width="80px"
    label-position="right">
        <el-row v-if="systemInfo.userInfo">

            <el-col :span="8">
                <el-form-item label="用户名">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>用户名</span>
                    </template>
                    <el-input v-model="systemInfo.userInfo.userName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="手机号码">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>手机号码</span>
                    </template>
                    <el-input v-model="systemInfo.userInfo.phone" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="在职状态">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>在职状态</span>
                    </template>
                    <el-select v-model="systemInfo.userInfo.workState" class="search-200">
                        <el-option :value="1" label="在职"></el-option>
                        <el-option :value="2" label="离职"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="性别">
                    <el-radio-group v-model="systemInfo.userInfo.sex">
                        <el-radio :label="0">女</el-radio>
                        <el-radio :label="1">男</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>

            <el-col :span="8" v-if="systemInfo.userInfo.systemNumber==''">
                <el-form-item label="登录密码">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>登录密码</span>
                    </template>
                    <el-input type="password" v-model="systemInfo.userLoginPassWord" class="search-200"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
    
    </el-form>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('System',['systemInfo'])
    },
}
</script>

<style>

</style>