<template>
  <div>
    <el-divider></el-divider>

    <!-- 频道信息 -->
    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row v-if="info">

            <el-col :span="24">
                <el-form-item label="标签名称">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>标签名称</span>
                  </template>
                    <el-input v-model="info.tagName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="备注">
                    <el-input v-model="info.remark" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="排序">
                    <el-input-number v-model="info.sort" :min="0" class="search-200"></el-input-number>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>


    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],
    data(){
        return{
            tagId:this.editId,   //当前编号

            info:{
                tagId:0,
                systemNumber:'',
                tagName:'',
                remark:'',
                sort:0,
            }
        }
    },
    methods:{
        //获取数据信息
        GetDataInfo(){
            if(this.tagId!=0){
                let param = {
                    tagId:this.tagId,
                }
                this.$api.member.SingleMemberTag(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }
                })
            }
        },

        //保存数据 type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            this.$api.member.SaveMemberTag(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //清空数据
        btnClearData(){
            let newInfo = {
                tagId:0,
                systemNumber:'',
                tagName:'',
                remark:'',
                sort:0,
            }
            this.info = newInfo;
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.tagId === this.tagId
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.tagId = prevInfo.tagId
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.tagId === this.tagId
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.tagId = prevInfo.tagId
                this.GetDataInfo()
            }
        },


    },
    mounted(){
        this.GetDataInfo();
    }

}
</script>

<style>

</style>