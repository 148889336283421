<template>
  <div>
    <!-- 编辑业务单页 -->
    <el-divider></el-divider>

    <el-tabs v-model="info.activeName">
        <el-tab-pane label="业务单" name="TabInfo">
            <TabBusiness ref="tabBusiness"></TabBusiness>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabOriginRecord></TabOriginRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabOriginState></TabOriginState>
        </el-tab-pane>

    </el-tabs>

    <div class="btnGroup">
        <el-button v-if="CheckUserButtonAuth('BtnBusinessSave')" type="primary" @click="btnSaveBusinessInfo">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import TabBusiness from '@/views/Bill/Business/TabBusiness.vue'
import TabOriginState from '@/views/Bill/Business/TabOriginState.vue'
import TabOriginRecord from '@/views/Bill/Business/TabOriginRecord.vue'

import { mapMutations, mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],
    components:{
        TabBusiness,
        TabOriginRecord,
        TabOriginState,
    },
    data(){
        return{
            info:{
                activeName:'TabInfo',
                billNumber:this.editId,
            }
        }
    },
    computed:{
        ...mapState('Erp',['businessInfo'])
    },
    methods:{
        ...mapMutations('Erp',['SetBusinessInfo']),

        //获取单个业务单信息
        GetDataInfo(){
            if(this.info.billNumber!=0){
                let param = {
                    billNumber:this.info.billNumber
                }
                this.$api.erp.SingleBusinessAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetBusinessInfo(res.data.data);
                        //调用子组件方法
                        this.$refs.tabBusiness.SelectTradeList();
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存业务单信息
        btnSaveBusinessInfo(){
            this.$api.erp.SaveBusinessInfo(this.businessInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.info.billNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.info.billNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
        },

        //清空数据
        btnClearData(){
            let newInfo = {
                info:{
                    systemNumber:'',
                    billNumber:'',
                    originType:0,
                    departmentId:0,
                    tradeType:1,
                    tradeNumber:'',
                    tradeName:'',
                    financeNumber:'',
                },
                recordList:[],
                stateList:[]
            }
            this.SetBusinessInfo(newInfo)
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style>

</style>