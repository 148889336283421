<template>
  <div>
    <!-- 列表 -->
        <el-table :data="differenceInfo.stateList" border style="width: 100%">
            <el-table-column prop="skuName" label="状态">
                <template v-slot="scope">
                    <span v-if="scope.row.state ==1">新单据</span>
                    <span v-if="scope.row.state ==2">已驳回</span>
                    <span v-if="scope.row.state ==3">已审核</span>
                    <span v-if="scope.row.state ==10">已关单</span>
                </template>
            </el-table-column>

            <el-table-column label="创建时间">
                <template v-slot="scope">
                    <span>{{DateTimeFormat(scope.row.createTime)}}</span>
                </template>
            </el-table-column>

            <el-table-column label="创建者">
                <template v-slot="scope">
                    <span>{{scope.row.createName}}</span>
                </template>
            </el-table-column>

        </el-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Erp',['differenceInfo'])
    },
}
</script>

<style>

</style>