<template>
  <div>
    <!-- 零售班组 主信息 -->
    <el-form :inline="false"
      v-model="retailGroupInfo.info"
      label-width="100px"
      label-position="right">
          <el-row v-if="retailGroupInfo.info">
              <el-col :span="12">
                  <el-form-item label="所属部门">
                      <template #label>
                          <span style="color:red;">*</span>
                          <span>所属部门</span>
                      </template>
                      <el-tree-select v-model="retailGroupInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200" />
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="班组名称">
                    <template #label>
                          <span style="color:red;">*</span>
                          <span>班组名称</span>
                      </template>
                      <el-input v-model="retailGroupInfo.info.retailGroupName" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="排序">
                    <el-input-number v-model="retailGroupInfo.info.sort" :min="0" :max="100" class="search-200"></el-input-number>
                </el-form-item>
              </el-col>

          </el-row>
    </el-form>


  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import { mapState } from 'vuex'
export default {
  mixins:[UserDptList],
  computed:{
      ...mapState('Erp',['retailGroupInfo'])
  },

}
</script>

<style>

</style>