<template>
  <div>
    <!-- 根据选择的客户、规格获取到销售的历史单价列表 -->
    <el-divider></el-divider>

    <el-table :data="dataList" border stripe style="width: 100%">
        <el-table-column prop="cidName" label="产品分类"></el-table-column>
        <el-table-column prop="brandName" label="品牌"></el-table-column>
        <el-table-column prop="spuName" label="产品"></el-table-column>
        <el-table-column prop="skuName" label="规格"></el-table-column>
        <el-table-column prop="unitName" label="单位"></el-table-column>
        <el-table-column prop="unitPrice" label="单价"></el-table-column>
        <el-table-column label="创建日期" width="110">
            <template #default="scope">
            <span>{{DateFormat(scope.row.billTime)}}</span>
            </template>
        </el-table-column>
    </el-table>

  </div>
</template>

<script>
export default {
    props:['PriceInfo'],
    data(){
        return{
            dataList:[],  //数据列表
        }
    },
    methods:{
        //加载数据
        GetDataInfo(){
            let param = {
                tradeNumber:this.PriceInfo.tradeNumber,
                skuNumber:this.PriceInfo.skuNumber,
                page:1,
                size:10
            }
            this.$api.erp.ListSalePrice(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    console.log('ceshi',this.dataList);
                }
            })
        }
    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style>

</style>