<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row v-if="info">

            <el-col :span="24">
                <el-form-item label="周一">
                    <el-select v-model="info.week1" class="search-200">
                        <el-option :value="1" label="假期日"></el-option>
                        <el-option :value="2" label="工作日"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="周二">
                    <el-select v-model.number="info.week2" class="search-200">
                        <el-option :value="1" label="假期日"></el-option>
                        <el-option :value="2" label="工作日"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="周三">
                    <el-select v-model.number="info.week3" class="search-200">
                        <el-option :value="1" label="假期日"></el-option>
                        <el-option :value="2" label="工作日"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="周四">
                    <el-select v-model.number="info.week4" class="search-200">
                        <el-option :value="1" label="假期日"></el-option>
                        <el-option :value="2" label="工作日"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="周五">
                    <el-select v-model.number="info.week5" class="search-200">
                        <el-option :value="1" label="假期日"></el-option>
                        <el-option :value="2" label="工作日"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="周六">
                    <el-select v-model.number="info.week6" class="search-200">
                        <el-option :value="1" label="假期日"></el-option>
                        <el-option :value="2" label="工作日"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="周日">
                    <el-select v-model.number="info.week7" class="search-200">
                        <el-option :value="1" label="假期日"></el-option>
                        <el-option :value="2" label="工作日"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
export default {
    data(){
        return{
            info:{
                id:0,
                week1:2,
                week2:2,
                week3:2,
                week4:2,
                week5:2,
                week6:1,
                week7:1,
            }
        }
    },
    methods:{
        //获取数据信息
        GetDataInfo(){
            this.$api.oa.SingleRuleWorkWeek().then(res=>{
                if(res.data.status === 200 && res.data.data !=null){
                    this.info = res.data.data;
                }else{
                    this.info = {
                        id:0,
                        week1:2,
                        week2:2,
                        week3:2,
                        week4:2,
                        week5:2,
                        week6:1,
                        week7:1,
                    }
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //保存数据
        btnSaveData(){
            this.$api.oa.SaveRuleWorkWeek(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
    mounted(){
        this.GetDataInfo()
    }

}
</script>

<style>

</style>