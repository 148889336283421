<template>
  <div>
    <!-- 业务单(单据式) -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="业务单号">
                <el-input v-model="info.search.billNumber" class="search-150"></el-input>
            </el-form-item>

            <el-form-item label="原始单号">
                <el-input v-model="info.search.originNumber" class="search-150"></el-input>
            </el-form-item>

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" class="search-200" collapse-tags collapse-tags-tooltip>
                </el-tree-select>
            </el-form-item>

            <el-form-item label="大区">
                <el-select v-model="info.search.areaNumberList" multiple collapse-tags collapse-tags-tooltip class="search-200">
                    <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="单据类型">
                <el-select v-model="info.search.originTypeList" multiple collapse-tags collapse-tags-tooltip class="search-150">
                    <el-option label="采购单" :value="1" />
                    <el-option label="销售单" :value="2" />
                    <el-option label="零售单" :value="3" />
                    <el-option label="差额单" :value="4" />
                    <el-option label="退供单" :value="14" />
                    <el-option label="退货单" :value="15" />
                    <el-option label="网络订单" :value="17" />
                </el-select>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-150">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="财务标识">
                <el-select v-model="info.search.financeFlag" class="search-150">
                    <el-option label="全部" :value="-1" />
                    <el-option label="未生成" :value="0" />
                    <el-option label="已生成" :value="1" />
                </el-select>
            </el-form-item>

            <el-form-item label="支付状态">
                <el-select v-model="info.search.payState" class="search-150">
                    <el-option label="全部" :value="-1" />
                    <el-option label="未支付" :value="0" />
                    <el-option label="已支付" :value="1" />
                </el-select>
            </el-form-item>

            <el-form-item label="记账日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item label="创建日期">
                <el-date-picker
                    v-model="info.search.createPoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnBusinessRefresh')" class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
                <el-button v-if="CheckUserButtonAuth('BtnBusinessAddFinance')" class="iconfont icon-dingdan" type="warning" plain @click="showCreateFinance()">生成财务单</el-button>
                <el-button type="success" class="iconfont icon-dingdan" @click="btnOpenCycle">生成对账单</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                    
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" ></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" border stripe show-summary style="width: 100%;" :height="GetDataHeight" @select="btnCheckbox" @select-all="btnCheckbox" >

            <el-table-column prop="id" type="selection" width="55" />  

            <el-table-column label="原始单号" width="170" >
                <template v-slot="scope">
                    <span>{{scope.row.originNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column label="单据类型" width="85">
                <template #default="scope">
                    <el-tag v-if="scope.row.originType == 1" type="danger">采购单</el-tag>
                    <el-tag v-if="scope.row.originType == 2" type="success">销售单</el-tag>
                    <el-tag v-if="scope.row.originType == 3" type="info">零售单</el-tag>
                    <el-tag v-if="scope.row.originType == 4" type="warning">差额单</el-tag>                    
                    <el-tag v-if="scope.row.originType == 14" type="warning">退供单</el-tag>
                    <el-tag v-if="scope.row.originType == 15" type="warning">退货单</el-tag>
                    <el-tag v-if="scope.row.originType == 17" type="info">网络订单</el-tag>
                </template>
            </el-table-column> 
            <el-table-column prop="departmentName" label="部门名称" width="150">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.departmentName"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
            </el-table-column>
            
            <el-table-column label="交易者类型" min-width="95">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.tradeType == 1" type="danger">供应商</el-tag>
                    <el-tag v-if="scope.row.tradeType == 2" type="success">客户</el-tag>
                    <el-tag v-if="scope.row.tradeType == 3" type="info">员工</el-tag>
                    <el-tag v-if="scope.row.tradeType == 4" type="info">散客</el-tag>
                    <el-tag v-if="scope.row.tradeType == 5" type="info">会员</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="交易者" property="tradeName" show-overflow-tooltip min-width="120">
            </el-table-column>

            <el-table-column prop="totalPrice" label="总价(元)" min-width="95" />     

            <el-table-column label="财务标识" width="95">
                <template #default="scope">
                    <el-tag v-if="scope.row.financeFlag == 0" type="danger">未生成</el-tag>
                    <el-tag v-if="scope.row.financeFlag == 1" type="info">已生成</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="支付状态" width="95">
                <template #default="scope">
                    <el-tag v-if="scope.row.payState == 0" type="danger">未支付</el-tag>
                    <el-tag v-if="scope.row.payState == 1" type="info">已支付</el-tag>
                </template>
            </el-table-column>

            <el-table-column prop="billTime" label="记账时间" width="110">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.billTime"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{DateFormat(scope.row.billTime)}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="创建日期" width="110">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.createTime"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{DateFormat(scope.row.createTime)}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="制单人" property="createName" show-overflow-tooltip min-width="90"></el-table-column>

            <el-table-column label="备注" min-width="100">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.remark"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{scope.row.remark}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right">
                <template v-slot="scope">
                    <el-button size="small" type="primary" @click="btnEditInfo(scope.row.originNumber)">详情</el-button>
                </template>
            </el-table-column>
        
        </el-table>
    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 业务单详情 - 弹出框 -->
    <el-drawer
    :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditBusinessOrigin :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditBusinessOrigin>
    </el-drawer>

  </div>
</template>

<script>

import UserDptList from '@/utils/mixins/UserDptList.js'   //用户管理部门列表 
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import UserAreaList from '@/utils/mixins/UserAreaList.js'  //用户关联大区列表

import EditBusinessOrigin from '@/views/Bill/BusinessOrigin/EditBusinessOrigin.vue'
import { ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList,AllTradeList,UserAreaList],
    components:{
        EditBusinessOrigin
    },
    data(){
        return{
            dataList:[],  //Table数据
            info:{        
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    billNumber:'',   //业务单号
                    tradeNumber:'',   //交易者编号
                    selectDepartmentList:[], //选中部门
                    areaNumberList:[],   //大区列表
                    originNumber:'',  //原始单据编号
                    financeFlag:-1,  //财务单标识
                    originTypeList:[],   //单据类型
                    datePoint:'',    //制单日期
                    createPoint:'',  //创建日期
                    payState:-1,  //支付状态 -1:全部 0:未支付 1:已支付
                },
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'业务单详情'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-135
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            //记账时间
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }
            //创建日期
            if(this.info.search.createPoint == null){
            this.info.search.createPoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
            billNumber:this.info.search.billNumber,           //业务单号
            departmentList:newDepartmentList,           //部门列表
            areaNumberList:this.info.search.areaNumberList,  //大区
            originNumber:this.info.search.originNumber,  //原始单据编号
            tradeNumber:this.info.search.tradeNumber,   //交易者
            financeFlag:this.info.search.financeFlag,  //财务标识
            originTypeList:this.info.search.originTypeList,    //单据类型
            searchName:this.info.search.searchName,    //名称
            payState:this.info.search.payState,   //支付状态
            startDate:this.DateFormat(this.info.search.datePoint[0]),   //制单日期
            endDate:this.DateFormat(this.info.search.datePoint[1]),
            createStartDate:this.DateFormat(this.info.search.createPoint[0]),  //创建日期
            createEndDate:this.DateFormat(this.info.search.createPoint[1]),  
            page:this.info.page,
            size:this.info.size
            }
            this.$api.erp.PageBusinessOriginList(param).then(res=>{
            if(res.data.status === 200){
                this.dataList = res.data.data.map(item=>{
                    item.children = [];
                    item.hasChildren=true;
                    return item;
                })
                //分页
                this.info.total = res.data.total
                this.info.page = res.data.pageNumber
            }else{
                ElMessage.error(res.data.msg);
            }
            }).catch(e=>{
                console.log(e);
            })
        },
        
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.originNumber)
        },

        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size;
            this.info.page = 1;
            this.GetDataList() //加载Table数据
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.billNumber=''; 
            this.info.search.tradeNumber='';
            this.info.search.selectDepartmentList = [];
            this.info.search.areaNumberList = [];
            this.info.search.originNumber='';
            this.info.search.financeFlag=-1;
            this.info.search.payState=-1;
            this.info.search.datePoint = '';
            this.info.search.createPoint = '';
            this.info.search.originTypeList = [];   //原始单据类型
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //业务单详情
        btnEditInfo(originNumber){
            this.info.id = originNumber;
            this.BoxEditInfo.title="业务单详情";
            this.BoxEditInfo.isVisible=true;
        },


        //打开-业务对账单-弹出框
        btnOpenCycle(){
            console.log('打开业务对账单')
        },

    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style>

</style>