<template>
  <div>
    <!-- 发码企业专用 -->

    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="频道名称">
                <el-input v-model="info.search.channelTitle"></el-input>
            </el-form-item>

            <el-form-item label="频道类型">
                <el-select v-model="info.search.channelType">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="轮播频道"></el-option>
                    <el-option :value="2" label="活动频道"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>       
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>    
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="channelId" type="selection" width="50" />  
        <el-table-column prop="channelTitle" label="频道名称" />
        <el-table-column label="图片">
            <template v-slot="scope">
                <el-image
                    style="width: 140px; height: 70px"
                    :src="scope.row.bgImg"
                    :preview-src-list="[scope.row.bgImg]"
                    :hide-on-click-modal="true"
                    fit="cover">
                </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="onLine" label="频道类型">
            <template v-slot="scope">
                <el-tag v-if="scope.row.channelType == 1" type="danger">轮播频道</el-tag>
                <el-tag v-if="scope.row.channelType == 2" type="warning">活动频道</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="onLine" label="上线标识">
            <template v-slot="scope">
                <el-tag v-if="scope.row.onLine == 1">上线</el-tag>
                <el-tag v-if="scope.row.onLine == 0">下线</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" />
        <el-table-column label="操作" width="200" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.channelNumber)">详情</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.channelNumber)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[20, 50, 100, 200]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
            >
            </el-pagination>
        </el-container>

    </div>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="70%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditChannel :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditChannel>
    </el-dialog>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:20,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    applyNumber:'',  //申请编号
                    applySystemNumber:'',  //申请系统编号
                    state:0, //状态
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'频道信息'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            let param = {
                applyNumber:this.info.search.applyNumber,
                applySystemNumber:this.info.search.applySystemNumber,
                state:this.info.search.state,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.trace.PageAllApplyList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                }
            }).catch(err=>{
                console.log(err);
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.applyNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //新增编辑部门信息
        btnEditInfo(applyNumber){
            this.info.id = applyNumber
            if(applyNumber==0){
                this.BoxEditInfo.title = "新增溯源码申请"
            }else{
                this.BoxEditInfo.title = "编辑溯源码申请"
            }
            this.BoxEditInfo.isVisible = true;
        },
        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(applyNumber){
            let param = [applyNumber]
            this.$api.trace.DeleteApplyAllInfo(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.trace.DeleteApplyAllInfo(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.applyNumber='';
            this.info.search.applySystemNumber = '';
            this.info.search.state = 0;
            this.info.page = 1;
            this.GetDataList()
        },


    },
    mounted(){
        this.GetDataList();
    }

}
</script>

<style>

</style>