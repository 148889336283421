<template>
  <div>
      <el-divider></el-divider>
      <!-- Tab标签 -->
      <el-tabs v-model="info.activeName">

          <el-tab-pane label="评价主题" name="TabTheme">
              <TabTheme></TabTheme>
          </el-tab-pane>

          <el-tab-pane label="评价等级设置" name="TabLevel">
              <TabLevel></TabLevel>
          </el-tab-pane>

          <el-tab-pane label="指标级别设置" name="TabTag">
              <TabTag></TabTag>
          </el-tab-pane>

      </el-tabs>

      <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button type="primary" @click="ComputeTotalScore">重新计算总分</el-button>
        <el-button @click="btnClose">关闭</el-button>
      </div>

  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import TabTheme from '@/views/Service/Evaluate/Theme/TabTheme.vue'
import TabLevel from '@/views/Service/Evaluate/Theme/TabLevel.vue'
import TabTag from '@/views/Service/Evaluate/Theme/TabTag.vue'
import { ElMessage } from 'element-plus'
export default {
    name:'EditTheme',
    props:['editId'],
    components:{
        TabTheme,
        TabLevel,
        TabTag
    },
    computed:{
        ...mapState('Purchase',['evaluateInfo']),
    },
    data(){
        return{
            info:{
                activeName:'TabTheme'
            }
        }
    },
    methods:{
        ...mapMutations('Purchase',['SetEvaluateInfo']),

        //加载数据
        GetDataInfo(){
            if(this.editId !=0){
                let param = {
                    themeNumber:this.editId
                }
                this.$api.service.SingleEvaluateAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetEvaluateInfo(res.data.data);
                    }
                }).catch(e=>{
                    console.log(e.message)
                })
            }
            else
            {
                let newInfo = {
                    info:{
                        id:0,
                        systemNumber:'',
                        themeNumber:'',
                        themeName:'',
                        totalScore:0,
                        standard:'',
                        isDel:0
                    },
                    levelList:[],
                    tagList:[],
                }
                this.SetEvaluateInfo(newInfo)
            }
        },

        //保存-主题信息
        btnSaveData(){
            this.$api.service.SaveEvaluateAllInfo(this.evaluateInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //重新计算总分
        ComputeTotalScore(){
            let themeNumber= this.evaluateInfo.info.themeNumber
            if(themeNumber ==''){
                ElMessage.error('暂无指标计算')
                return false
            }
            this.$api.service.ComputeTotalScore(themeNumber).then(res=>{
                if(res.data.status === 200){
                    this.evaluateInfo.info.totalScore = res.data.data
                    ElMessage.success(res.data.msg)                    
                }
                else
                {
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
    mounted(){
        this.GetDataInfo()  //加载数据
    }
}
</script>

<style>

</style>