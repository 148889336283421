<template>
  <div>
    <!-- 营业额统计 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :editable="false"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataInfo">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>


    <!-- 统计页面 -->
    <div class="statistics">
        <div class="statistic">
            <div class="value colorSale">￥{{ dataInfo.totalSalePrice }}</div>
            <div class="label">总销售额</div>
        </div>
        <div class="statistic">
            <div class="value colorSale">{{ dataInfo.totalSaleNum }}</div>
            <div class="label">总销售订单</div>
        </div>

        <div class="statistic">
            <div class="value colorPurchase">￥{{ dataInfo.totalPurchasePrice }}</div>
            <div class="label">总采购额</div>
        </div>
        <div class="statistic colorPurchase">
            <div class="value">{{ dataInfo.totalPurchaseNum }}</div>
            <div class="label">总采购订单</div>
        </div>
    </div>

    <el-divider />

    <el-row :gutter="20">
        <!-- 批发 -->
        <el-col :span="8">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>批发统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>销售额：<span class="mainPrice">{{ dataInfo.wholesalePrice }}</span>元</div>
                    <div>销售单数：<span class="mainPrice">{{ dataInfo.wholesaleNum }}</span>单</div>
                    <div>毛利润：<span class="mainPrice">{{ dataInfo.wholesaleProfit }}</span>元</div>
                    <div>毛利率：<span class="mainPrice">{{ dataInfo.wholesaleProfitRate }}</span>%</div>
                </div>     
            </el-card>
        </el-col>

        <!-- 零售 -->
        <el-col :span="8">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>零售统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>销售额：<span class="mainPrice">0</span>元</div>
                    <div>销售单数：<span class="mainPrice">0</span>单</div>
                    <div>毛利润：<span class="mainPrice">0</span>元</div>
                    <div>毛利率：<span class="mainPrice">0</span>%</div>
                </div>     
            </el-card>
        </el-col>

        <!-- 电商 -->
        <el-col :span="8">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>电商统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>销售额：<span class="mainPrice">0</span>元</div>
                    <div>销售单数：<span class="mainPrice">0</span>单</div>
                    <div>毛利润：<span class="mainPrice">0</span>元</div>
                    <div>毛利率：<span class="mainPrice">0</span>%</div>
                </div>     
            </el-card>
        </el-col>


    </el-row>


  </div>
</template>

<script>
import { ElLoading } from 'element-plus'
import UserDptList from '@/utils/mixins/UserDptList.js'
export default {
    mixins:[UserDptList],
    data(){
        return{
            info:{
                search:{
                    selectDepartmentList:[],
                    datePoint:'',
                },
                beginDate:this.DateFormat(new Date()),   //开始日期
                endDate:this.DateFormat(new Date()),   //结束日期
            },
            dataInfo:{
                totalSalePrice:0,   
                totalPurchasePrice:0,
                totalSaleNum:0,
                totalPurchaseNum:0,

                wholesalePrice:0,
                wholesaleNum:0,

                offlinePrice:0,
                offlineNum:0,

                onlinePrice:0,
                onlineNum:0,
            }
        }
    },
    methods:{
        //获取统计数据
        GetDataInfo(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在加载,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
            }
            this.$api.stat.GetStatTurnover(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    this.dataInfo = res.data.data;
                }
            })
        },

        //重置
        btnResetSearch(){
            this.info.search.selectDepartmentList = [];
            this.info.search.datePoint = [this.beginDate,this.endDate];
            this.GetDataInfo();
        },

        //计算日期
        GetbeginDate(){
            this.beginDate = this.DateFormat(new Date());
            this.endDate = this.DateFormat(new Date());
            this.info.search.datePoint = [this.beginDate,this.endDate];
        },
    },
    mounted(){
        this.GetbeginDate();
        this.GetDataInfo();
    }
}
</script>

<style scoped>
.colorSale{color:red;}
.colorPurchase{color: #1085d3;}

.statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px;
}

.statistic {
  text-align: center;
}

.value {
  font-size: 24px;
  font-weight: bold;
}

.label {
  margin-top: 10px;
  font-size: 16px;
  color: gray;
}


.cardMain{height: 200px;}
.mainPrice{color: #f30641;font-size: 25px;}
</style>