<template>
  <div>
    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-150">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="记账日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item label="名称">
                <el-input v-model="info.search.searchName" placeholder="品牌、分类、产品名称" class="search-200"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
                </el-tooltip>
            </el-button-group>    
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox">
        <el-table-column prop="id" type="selection" width="50" />        
        <el-table-column prop="departmentName" label="部门" />
        <el-table-column label="交易者类型" min-width="95">
            <template v-slot="scope">
                <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                <el-tag v-if="scope.row.tradeType ==4" type="info">散客</el-tag>
            </template>
        </el-table-column>

        <el-table-column label="交易者" min-width="120">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.tradeName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.tradeName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column prop="cidName" label="产品类目" />
        <el-table-column prop="brandName" label="品牌" />
        <el-table-column prop="spuName" label="产品名称" />
        <el-table-column prop="skuName" label="规格名称" />
        <el-table-column prop="unitName" label="最小单位" />
        <el-table-column prop="unitPrice" label="最小单位单价" />
        <el-table-column prop="unitNum" label="最小单位数量" />
        <el-table-column label="创建日期" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.billTime"
                    placement="top"
                  >
                  <span>{{DateFormat(scope.row.billTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="billNumber" label="采购单号" width="170" />
        <el-table-column label="操作" width="80" fixed="right">
            <template v-slot="scope">              
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.id)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[20, 50, 200, 500, 1000]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
            >
            </el-pagination>
        </el-container>

    </div>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表

import { ElLoading, ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList,AllTradeList],
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:20,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                search:{
                    selectDepartmentList:[],
                    tradeNumber:'',
                    searchName:'',
                    datePoint:'',
                },
            },
            goodsSpuList:[],  //产品列表
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-290
        }
    },
    methods:{

        //加载数据列表
        GetDataList(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                tradeNumber:this.info.search.tradeNumber,
                searchName:this.info.search.searchName,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.erp.ListPurchasePrice(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.id)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //删除按钮
        btnDeleteInfo(id){
            let param = [id]
            this.$api.erp.DeletePurchasePrice(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.erp.DeletePurchasePrice(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.selectDepartmentList = [];
            this.info.search.datePoint = '',
            this.info.search.tradeNumber = '',
            this.info.search.searchName='';
            this.info.page = 1,
            this.GetDataList()
        },

        //导出采购单价历史记录到Excel
        btnExportInfo(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在导出数据,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                tradeNumber:this.info.search.tradeNumber,
                searchName:this.info.search.searchName,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }

            this.$api.erp.ExportPurchasePrice(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    const alink = document.createElement('a');
                    alink.href = res.data.data;
                    document.body.appendChild(alink);
                    alink.click();
                    document.body.removeChild(alink)
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

    },
    mounted(){
        this.GetDataList()
    }
}
</script>

<style>

</style>