<template>
  <div>

    <el-table :data="memberInfo.recordList" border style="width: 100%">
        <el-table-column prop="recordNumber" label="记录编号" />
        <el-table-column prop="id" label="会员姓名" />
        <el-table-column prop="id" label="充值金额" />
        <el-table-column prop="id" label="支付状态" />
        <el-table-column prop="id" label="充值时间" />
    </el-table>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Shop',['memberInfo'])
    },

}
</script>

<style>

</style>