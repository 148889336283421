<template>
  <div>
    <el-divider></el-divider>

    <el-descriptions border :column="1">
        <el-descriptions-item label="结款账户">
            <el-select v-model="info.accountNumber" class="search-200">
                <el-option v-for="accountInfo in selectAccountList" :key="accountInfo.accountNumber" :label="accountInfo.accountName" :value="accountInfo.accountNumber"></el-option>
            </el-select>
        </el-descriptions-item>

        <el-descriptions-item label="结款时间">
            <el-date-picker v-model="info.payTime" type="datetime" placeholder="结款时间" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px"/>
        </el-descriptions-item>
    </el-descriptions>

    <div class="btnGroup">
        <el-button type="success" v-if="CheckUserButtonAuth('BtnFinanceConfirmSettle') && financeInfo.info.state == 4" @click="btnPayment">确认结款</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import accountList from '@/utils/mixins/AccountList.js'  //账户-下拉列表
import { mapState } from 'vuex'
import moment from 'moment'
import { ElMessage } from 'element-plus'
export default {
    mixins:[accountList],
    data(){
        return{
            info:{
                billNumber:"",  //财务单号
                accountNumber:'',   //选择结款账户
                payTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),  //结款时间
            }
        }
    },
    computed:{
        ...mapState('Erp',['financeInfo'])   //财务单信息
    },
    methods:{
        
        //确认结款
        btnPayment(){
            let param = {
                billNumber:this.financeInfo.info.billNumber,
                accountNumber:this.info.accountNumber,   //结款账户
                payTime:this.info.payTime,      //结款时间
            }

            if(param.accountNumber == null || param.accountNumber ==''){
                ElMessage.error('请选择结款账户')
                return false;
            }

            if(param.payTime == null){
                ElMessage.error('请选择用款时间'); 
            }

            this.$api.finance.PayFinance(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭-弹出框
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        
    }

}
</script>

<style>

</style>