<template>
  <el-container class="home_container">
    <!-- 左侧菜单 -->
      <el-aside>
        <SystemMenu/>
      </el-aside>

    
    <!-- 中部容器 -->
    <el-container class="home_container">
      <!-- 头部容器 -->
      <el-header>
          <SystemHeader/>
          <!-- 标签列表 -->
          <CommonTag/>
      </el-header>


      <!-- 内容区域 -->
      <el-main>        
        <!-- 主内容:缓存组件 -->
        <router-view class="content" v-slot="{Component}">
          <keep-alive>
            <component :is="Component"></component>
          </keep-alive>
        </router-view>
        
      </el-main>
    </el-container>


  </el-container>
</template>

<script>
import {mapActions,mapMutations,mapState} from 'vuex'
import Authorize from '@/utils/authorize'
import SystemMenu from '@/components/home/SystemMenu.vue'
import SystemHeader from '@/components/home/SystemHeader.vue'
import CommonTag from '@/components/home/CommonTag.vue'
export default {
  data(){
    return{
      UserName:''      
    }    
  },
  components:{
    SystemMenu,
    SystemHeader,
    CommonTag
  },
  computed:{
    ...mapState('Master',['TagList'])
  },
  methods:{
    ...mapActions('Master',['work_GetIsMenuCollapse','work_LoadTag']),
    ...mapMutations('Master',['SetUserInfo','InsertToken']),
    //加载
    GetUserInfo(){
      this.$api.system.GetCurrentUserInfo().then(res=>{
        if(res.data.status===200){
          this.SetUserInfo(res.data.data);
          this.InsertToken(Authorize.getToken())
        }else{
          this.$router.push({name:'login'})
        }
      }).catch(e=>{
        console.log(e)
      })
    },
    //加载Tag标签
    btnLoadTag(){
        this.work_LoadTag()  //加载本地标签数组
        let newRoute = "center"
        //跳转到默认标签
        this.TagList.forEach(element => {
            if(element.effect == "dark"){
              newRoute = element.routeName
            }
        });
        this.$router.push({
          name:newRoute
        })
    }
    
  },
  beforeMount(){
    this.GetUserInfo()  //加载用户数据
    this.work_GetIsMenuCollapse() //加载是否隐藏菜单配置项
  },
  mounted(){
      this.btnLoadTag() //这个方法必须加载完页面才能执行
  }

}
</script>

<style>
  .home_container{height: 100%;}
  .el-header{padding: 0px;}
  .el-aside{background-color: #333744;width: auto;}
  .el-main {background-color: #fff;padding: 40px 5px 0px 5px;}
  .content {padding-top: 3px;}
</style>