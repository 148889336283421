<template>
  <div>
    <el-divider></el-divider>

    <!-- 标签数据列表 -->
    <el-table :data="info.dataList" border stripe @select="btnCheckbox" @select-all="btnCheckbox" @row-click="TableRowClick" ref="currentTable">
      <el-table-column prop="tagId" type="selection" width="50" />  
      <el-table-column prop="tagId" label="编号" />
      <el-table-column prop="tagName" label="标签名称" />
      <el-table-column prop="remark" label="备注" />
    </el-table>

    <!-- 分页 -->
    <el-container class="page_one">
        <el-pagination
        v-model:currentPage="info.page"
        v-model:page-size="info.size"
        :page-sizes="[10, 20]"
        :background="true"
        layout="total, sizes, prev, pager, next, jumper"
        :total="info.total"
        @current-change="btnPagechange"
        @size-change="btnSizechange"
        >
        </el-pagination>
    </el-container>

    <div class="btnGroup BoxFooter">
        <el-button type="primary" @click="btnSaveMemberTag()">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  props:['memberNumber'],
  data(){
    return{
      info:{
        dataList:[],   //数据列表
        page:1,   //页数
        size:10,  //单页记录数
        total:0,  //总记录数
        ids:[],   //单据数组
        search:{
            tagName:'',  //标签名称
        }
      },
      

    }
  },
  methods:{
    //加载数据列表
    GetDataList(){
      let param = {
        tagName:this.info.search.tagName,
        page:this.info.page,
        size:this.info.size
      }
      this.$api.member.ListMemberTag(param).then(res=>{
        if(res.data.status === 200){
          this.info.dataList = res.data.data;
          //分页
          this.info.total = res.data.total
          this.info.page = res.data.pageNumber
        }
      }).catch(e=>{
        console.log(e);
      })
    },

    //选中多选框
    btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.tagId)
    },
    //分页-页数变化时
    btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
    },
    //分页-单页记录数变化时
    btnSizechange(size){
        this.info.size = size
        this.info.page = 1
        this.GetDataList() //加载Table数据
    },

    //选中行时(标准模板)
    TableRowClick(row){
        let isExist = false; //判断是否存在
        this.info.ids = this.info.ids.filter(item=>{                
            if(row.tagId != item){
                return item;
            }else{                    
                //如果存在
                isExist = true;
            }                
        })
        
        if(isExist){
            this.$refs.currentTable.toggleRowSelection(row,false);
        }else{
            this.info.ids.push(row.tagId)
            this.$refs.currentTable.toggleRowSelection(row,true);
        }
    },

    //保存会员标签
    btnSaveMemberTag(){
      let param = {
        memberNumber:this.memberNumber,
        idList:this.info.ids
      }
      this.$api.member.SaveRelationMemberTag(param).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          this.btnClose();
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //关闭弹窗
    btnClose(){
      this.$emit('closeBox');
    }
  },
  mounted(){
    this.GetDataList();
  }
}
</script>

<style scoped>
.BoxFooter{padding-top: 55px;}
</style>