<template>
  <div>
    <el-scrollbar :height="GetDataHeight">
      <el-tree
      :data="allPcMenuList"
      :default-checked-keys="roleInfo.selectMenuList"
      default-expand-all
      show-checkbox
      ref = "treeMenu"
      node-key="id">
      </el-tree>
    </el-scrollbar>
    
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name:'TreeRoleMenu',
    data(){
      return{
        allPcMenuList:[], //PC端菜单目录树
      }
    },
    computed:{
      //自适应窗体高度
      GetDataHeight(){
          return window.innerHeight-200
      },
      ...mapState('System',['roleInfo'])
    },
    methods:{
      //加载数据
      GetDataInfo(){
        //加载系统的全部菜单
        this.$api.system.SelectSystemMenuList().then(res=>{
          if(res.data.status === 200){
            this.allPcMenuList = res.data.data
          }else{
            this.allPcMenuList = []
          }
        })
      },

    },
    mounted(){
      this.GetDataInfo();
    },
}
</script>


<style>
</style>