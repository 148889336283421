/*
* 通用Vuex
*/



var actions = {
    //更新菜单是否折叠
    work_UpdateIsMenuCollapse(context,value){
        localStorage.isMenuCollapse = value
        context.commit('UpdateIsMenuCollapse',value)
    },
    //加载菜单是否折叠状态
    work_GetIsMenuCollapse(context){
        if(localStorage.isMenuCollapse=="true")
        {
            context.commit('UpdateIsMenuCollapse',true)
        }
        else{
            context.commit('UpdateIsMenuCollapse',false)
        }
    },

    //新便签页添加到数组
    work_AddTag(context,value){
        let isExis = false
        context.state.TagList = context.state.TagList.map((item)=>{
            if(item.name==value.name){
                isExis = true
                item.effect='dark' //选中
            }
            else
            {
                item.effect='plain' //未选中
            }            
            return item
        })
        //只有不存在时才去创建
        if(!isExis){
            context.commit('AddTag',value)
        }

        //标签保存到本地
        localStorage.TagList = JSON.stringify(state.TagList)        
    },
    //删除标签
    work_DelTag(context,value){
        if(value.effect == 'dark'){
            //如果删除的是选中标签的话
            const index = context.state.TagList.indexOf(value)-1;
            const newItem = context.state.TagList[index]

            context.state.TagList = context.state.TagList.map((item)=>{
                if(item.name==newItem.name){
                    item.effect='dark' //选中
                }           
                return item
            })
        }      
        
        //删除标签
        context.commit('DeleteTag',value)
        //标签保存到本地
        localStorage.TagList = JSON.stringify(state.TagList)
    },
    //加载标签
    work_LoadTag(context){
        context.commit('LoadTag')
    },
    //清空标签
    work_ClearTag(context){
        context.commit('ClearTag')
    },


    //更新模块编号
    work_SetModuleId(context,value){
        localStorage.moduleId = value;
        context.commit('SetModuleId',value)
    },
    //获取模块编号
    work_GetModuleId(context){
        context.commit('SetModuleId',localStorage.moduleId)
    },

}

var mutations = {
    //更新token
    InsertToken(state,value){
        state.token = value
    },

    //设置登录用户 注：这里没有办法用一个实体，因为可能会没有数据
    SetUserInfo(state,value){
        state.currentUserName = value.userName;
        state.userDepartmentId = value.departmentId;
        state.currentUserNumber = value.userNumber;
    },

    //设置系统信息
    SetSystemInfo(state,value){
        state.CurrentsystemInfo = value;
    },

    //更新菜单是否折叠
    UpdateIsMenuCollapse(state,value){
        state.isMenuCollapse = value
    },

    //新标签页添加到数组
    AddTag(state,value){
        state.TagList.push(value)
        
    },
    //删除标签页
    DeleteTag(state,value){
        const index = state.TagList.indexOf(value)
        state.TagList.splice(index,1)
    },
    //清空标签
    ClearTag(state){
        state.TagList = [
            {
                name:'首页',
                isClose:false,
                routeName:'center',
                effect:'dark'
            }
        ]
    },
    //加载本地标签
    LoadTag(state){
        if(localStorage.TagList!=null){
            state.TagList = JSON.parse(localStorage.TagList)          
        }  
    },

    //设置模块编号
    SetModuleId(state,value){
        state.moduleId = value;
    },

    //设置用户授权内的所有菜单列表
    SetUserAllMenuList(state,value){
        state.userAllMenuList = value;
    },
    
    //设置用户授权内的所有按钮列表
    SetUserAllButtonList(state,value){
        state.userAllButtonList = value;
    }
}

var state = {
    token:'',  //登录用户Token
    currentUserName:'',  //登录用户名称
    currentUserNumber:'',  //登录用户编号
    userDepartmentId:0,  //用户所属部门编号
    CurrentsystemInfo:null,  //系统信息（包含企业名称）    
    TagList:[{
        name:'首页',
        isClose:false,
        routeName:'center',
        effect:'dark'}
    ],  //标签对象数组
    isMenuCollapse:false, //主菜单是否折叠
    moduleId:0,    //默认模块编号
    userAllMenuList:[],    //用户权限内所有菜单列表
    userAllButtonList:[],  //用户权限内所有按钮列表
}

export default{
    namespaced:true,
    actions,
    mutations,
    state
}