
/**
 * 接口域名的管理
 */

 const base = {    
    base: 'https://scm.bjszhgx.cn/api',  //正式接口环境地址
    host: 'https://web.bjszhgx.cn',    //正式网站地址

    // base: 'http://scm.bjszhgx.net/api',   //测试接口环境地址
    // host: 'http://web.bjszhgx.net',    //测试网站地址
}

export default base;