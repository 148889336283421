<template>
  <div>
    <!-- 加工组成员 -->
    <div class="skuBtnList">
        <el-button type="primary" @click="BoxUserInfo.isVisible = true">选择成员</el-button>
    </div>

    <!-- 成员列表 -->
    <el-table :data="workGroupInfo.memberList" border stripe style="width: 100%">
      <el-table-column prop="userName" label="成员姓名"></el-table-column>
      <el-table-column prop="remark" label="操作">
        <template v-slot="scope">
            <el-button size="small" type="danger" @click="DeleteRow(scope.row.workGroupNumber,scope.row.id,scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 选择员工-弹出框 -->
    <el-dialog :title="BoxUserInfo.title"
    v-model="BoxUserInfo.isVisible"
    width="50%"
    :destroy-on-close="true">
        <SelectUserList ref="SelectUserInfo"></SelectUserList>
        <template #footer>
            <el-button type="primary" @click="btnSelectData">选择</el-button>
            <el-button @click="BoxUserInfo.isVisible = false">取消</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import SelectUserList from '@/components/common/SelectUserList.vue'
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    components:{
        SelectUserList
    },
    data(){
        return{
            //选择员工-弹出框
            BoxUserInfo:{
                isVisible:false,
                title:'选择员工'
            }
        }
    },
    computed:{
        ...mapState('Erp',['workGroupInfo'])
    },
    methods:{

        //保存选中员工
        btnSelectData(){
            let userList = this.$refs.SelectUserInfo.selectedItem;
            if(userList !=null && userList.length>0){
                userList.forEach(item => {
                    let newRow = {
                        id:0,
                        workGroupNumber:this.workGroupInfo.info.workGroupNumber,  //加工组编号
                        userNumber:item.userNumber,
                        userName:item.userName,
                    }
                    this.workGroupInfo.memberList.push(newRow);
                });
            }
            this.BoxUserInfo.isVisible = false;  //
        },

        //删除行
        DeleteRow(workGroupNumber,id,index){
            this.workGroupInfo.memberList.splice(index,1)
            if(id !=0){
                let param = {
                    workGroupNumber:workGroupNumber,
                    id:id,
                }
                this.$api.erp.DeleteWorkGroupMember(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }
        }


    },
}
</script>

<style>

</style>