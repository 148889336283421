<template>
  <div>
    <!-- 财务单差额项列表 -->
    <div class="skuBtnList" v-if="financeInfo.info">
      <el-button class="iconfont icon-add" type="primary" v-if="financeInfo.info.state ==1 || financeInfo.info.state ==2" plain @click="btnEditdBalance()">新增记录</el-button>
    </div>

    <el-table border stripe :data="financeInfo.balanceList" show-summary>
        <!-- <el-table-column prop="id" label="编号" /> -->
        <el-table-column label="差额说明">
          <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.content"
                    placement="top"
                  >
                  <div class="cellOverFlow">{{scope.row.content}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="差额" />     
        <el-table-column label="凭证">
          <template v-slot="scope">
            <!-- 无 -->
            <span v-if="scope.row.fileType==0" >无</span>
            <!-- 图片 -->
            <el-image v-if="scope.row.fileType==1"
                style="width: 70px; height: 70px;border:1px solid #ccc;"
                hide-on-click-modal
                :src="scope.row.fileUrl"
                :preview-src-list="[scope.row.fileUrl]"
                fit="cover"
                />
            <!-- 文件 -->
            <el-link v-if="scope.row.fileType==2" :href="scope.row.fileUrl" target="_blank">查看文件</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" />
        <el-table-column label="操作" width="100" fixed="right">
          <template v-slot="scope">
              <el-popconfirm v-if="financeInfo.info.state == 1 || financeInfo.info.state == 2"
              title="确认要删除吗?"
              @confirm="DeleteRow(scope.row.billNumber,scope.row.id,scope.$index)">
                  <template #reference>
                  <el-button size="small" type="danger">删除</el-button>
                  </template>
              </el-popconfirm>
          </template>
        </el-table-column>
    </el-table>

    <!-- 编辑差额项-弹出框 -->
    <el-dialog :title="BoxBalance.title"
    v-model="BoxBalance.isVisible"
    :draggable="true"
    :close-on-click-modal="false"
    width="400px">
        <BoxBalance ref="tabBalan"></BoxBalance>
        <template #footer>
            <el-button type="primary" @click="btnSaveBanlance">提交</el-button>
            <el-button @click="BoxBalance.isVisible=false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import BoxBalance from '@/views/Fms/Finance/BoxBalance.vue'
import { mapState } from 'vuex';
import { ElMessage } from 'element-plus';


export default {
    components:{
      BoxBalance,
    },
    data(){
      return{
        info:{
          id:0,
        },
        //弹出框
        BoxBalance:{
          isVisible:false,
          title:'新增差额项',
        },
      }
    },
    computed:{
        ...mapState('Erp',['financeInfo'])   //财务单信息
    },
    methods:{
        //新增记录
        btnEditdBalance(){
          this.BoxBalance.title = "新增差额项";
          this.BoxBalance.isVisible = true;
          this.$refs.tabBalan.GetDataInfo();
        },

        //保存差额项
        btnSaveBanlance(){
          let newInfo = this.$refs.tabBalan.info;
          this.financeInfo.balanceList.push(newInfo);
          this.BoxBalance.isVisible = false;
        },

        //删除差额项
        DeleteRow(billNumber,id,index){
          this.financeInfo.balanceList.splice(index,1);
          if(id!=0){
            let param = {
              billNumber:billNumber,
              id:id,
            }
            this.$api.finance.DeleteBalanceById(param).then(res=>{
              if(res.data.status === 200){
                ElMessage.success(res.data.msg);
                //更新实付金额
                this.financeInfo.info.totalPrice = this.myRound(res.data.data,2);
              }else{
                ElMessage.error(res.data.msg);
              }
            })
          }
        },
        


    }
}
</script>

<style>

</style>