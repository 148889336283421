<template>
  <div>

    <el-form :inline="true"
        v-model="processInfo.info"
        label-width="80px"
        label-position="right">
            <el-row v-if="processInfo.info">

                <el-col :span="12">
                    <el-form-item label="审批单号">
                        <el-input v-model="processInfo.info.billNumber" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="所属部门">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>所属部门</span>
                    </template>
                        <el-tree-select v-model="processInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="审批类型">
                        <el-tree-select v-model="processInfo.info.typeId" :data="processTypeList" :default-expand-all="true" class="search-200"/>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="申请事由">
                      <el-input v-model="processInfo.info.content" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="数量">
                      <el-input-number v-model="processInfo.info.amount" class="search-200" :min="0" ></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="预算金额">
                      <el-input-number v-model="processInfo.info.totalPrice" class="search-200" :min="0" :precision="2" ></el-input-number>
                    </el-form-item>
                </el-col>
                
                <el-col :span="12">
                    <el-form-item label="备注">
                      <el-input v-model="processInfo.info.remark" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="审核人">
                      <el-select v-model="processInfo.info.receiver" class="search-200">
                        <el-option v-for="user in selectUserList" :key="user.userNumber" :label="user.userName" :value="user.userNumber"></el-option>
                      </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="创建时间">
                        <el-date-picker v-model="processInfo.info.createTime" type="datetime" placeholder="创建时间" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px" disabled/>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="状态">
                      <el-tag v-if="processInfo.info.state ==1">新建单</el-tag>
                      <el-tag v-if="processInfo.info.state ==2">待审核</el-tag>
                      <el-tag v-if="processInfo.info.state ==3" type="danger">已驳回</el-tag>
                      <el-tag v-if="processInfo.info.state ==4" type="warning">已审核并上报</el-tag>
                      <el-tag v-if="processInfo.info.state ==5">审核已完成</el-tag>
                      <!-- <el-tag v-if="processInfo.info.state ==10">已完成</el-tag> -->
                    </el-form-item>
                </el-col>

            </el-row>
      </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import AllUserList from '@/utils/mixins/AllUserList.js'

import { mapState } from 'vuex'
export default {
    mixins:[UserDptList,AllUserList],
    computed:{
        ...mapState('Oa',['processInfo']),
    },
    data(){
        return{
            processTypeList:[],   //审批类型列表
        }
    },
    methods:{
        //加载审批类型列表
        SelectTreeProcessTypeList(){
            this.$api.oa.SelectTreeProcessTypeList().then(res=>{
                if(res.data.status === 200){
                    this.processTypeList = res.data.data;
                }else{
                    this.processTypeList = [];
                }
            })
        },
    },
    mounted(){
        this.SelectTreeProcessTypeList();
    },
}
</script>

<style>

</style>