<template>
  <div>
      <!-- 编辑菜单 -->
      <el-divider></el-divider>
      <el-form :inline="true"
      v-model="info"
      label-width="80px"
      label-position="right">
          <el-row>

            <el-col :sm="12" :xs="24">
                <el-form-item label="系统模块">
                    <el-select v-model="info.moduleId" @change="ChangeModule" class="search-200">
                        <el-option :value="0" label="请选择"></el-option>
                        <el-option v-for="mod in ModuleList" :key="mod.moduleId" :label="mod.moduleName" :value="mod.moduleId"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :sm="12" :xs="24">
                <el-form-item label="所属菜单">
                    <el-tree-select v-model="info.pid" :data="TreeMenuList" check-strictly :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :sm="12" :xs="24">
                <el-form-item label="菜单名称">
                    <el-input v-model="info.menuName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="图标">
                    <el-input v-model="info.icon" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="链接地址">
                    <el-input v-model="info.jumpName" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="排序">
                    <el-input v-model.number="info.sort" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="备注">
                    <el-input v-model="info.remark" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="菜单类型">
                      <el-radio-group v-model="info.menuType">
                          <el-radio :label="1">目录</el-radio>
                          <el-radio :label="2">菜单</el-radio>
                          <el-radio :label="3">按钮</el-radio>
                      </el-radio-group>                    
                 </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="状态">
                      <el-radio-group v-model="info.state">
                          <el-radio :label="0">停用</el-radio>
                          <el-radio :label="1">正常</el-radio>
                      </el-radio-group>                    
                  </el-form-item>
              </el-col>

              <!-- <el-col :span="12">
                  <el-form-item label="链接类型">
                      <el-radio-group v-model="info.jumpType">
                          <el-radio :label="0">内链</el-radio>
                          <el-radio :label="1">外链</el-radio>
                      </el-radio-group>
                  </el-form-item>
              </el-col> -->

          </el-row>

      </el-form>

      <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    name:'EditMenu',
    props:['editId'],
    data(){
        return{
            info:{
                menuId:0,
                pid:0,
                moduleId:0,
                menuName:'',
                menuType:1,
                icon:'',
                jumpName:'',
                jump:'',
                sort:0,
                jumpType:0,
                state:1,
                isInitial:0,
                remark:'',
                isPay:0
            },
            TreeMenuList:[],   //菜单列表
            selectModuleId:0,   //选中模块编号
            ModuleList:[],    //模块列表
        }
    },
    methods:{
        //加载模块列表
        GetModuleList(){
            let param = {
                isShow:0,   //显示全部
            }
            this.$api.system.SelectModuleList(param).then(res=>{
                if(res.data.status === 200){
                    this.ModuleList = res.data.data;
                }
            })
        },

        //加载树
        GetTreeMenuList(){
            let param = {
                moduleId:this.selectModuleId
            }
            this.$api.system.GetTreeMenuListByModuleId(param).then(res=>{
                if(res.data.status === 200){
                    this.TreeMenuList = res.data.data
                }
            })
        },

        //加载数据
        GetDataInfo(){            
            if(this.editId!=0){
                let param = {
                    menuId:this.editId
                }
                this.$api.system.GetSystemMenuInfo(param).then(res=>{
                    if(res.data.status ===200){
                        this.info = res.data.data
                    }
                })
            }
            
        },

        //保存数据方法
        btnSaveData(){
            this.$api.system.SaveSystemMenu(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //模块发生改变时
        ChangeModule(option){
            this.selectModuleId =option;
            this.GetTreeMenuList();  //重新加载菜单树
        },

    },
    mounted(){
        this.GetModuleList();   //模块列表
        this.GetTreeMenuList();  //菜单列表
        this.GetDataInfo();
    }

}
</script>

<style>
.el-radio {margin-right: 15px;}
</style>