/**
 * 溯源码相关
 */

import base from '../base'   //导入接口域名列表
import axios from '@/utils/http'   //导入http中创建的axios实例

const trace = {

    //#region 溯源码申请

    //获取所有溯源码申请列表
    PageAllApplyList(param){
        return axios.post(`${base.base}/Trace/PageAllApplyList`,param)
    },

    //获取申请企业的溯源码申请列表
    PageApplyList(param){
        return axios.post(`${base.base}/Trace/PageApplyList`,param)
    },

    //获取单个溯源码申请信息
    GetApplyInfo(param){
        return axios.get(`${base.base}/Trace/GetApplyInfo`,{
            params:param
        })
    },

    //保存溯源码申请信息
    SaveApplyInfo(param){
        return axios.post(`${base.base}/Trace/SaveApplyInfo`,param)
    },

    //更新溯源码申请记录状态
    UpdateApplyByState(param){
        return axios({
            url:`${base.base}/Trace/UpdateApplyByState`,
            method:'post',
            params:param
        })
    },

    //删除溯源码申请记录(包含溯源码明细)
    DeleteApplyAllInfo(param){
        return axios.post(`${base.base}/Trace/DeleteApplyAllInfo`,param)
    },

    //#endregion


    //#region 溯源码明细

    //获取所有溯源码信息 - 发放溯源码企业使用
    GetTraceAllList(param){
        return axios.post(`${base.base}/Trace/GetTraceAllList`,param)
    },

    //获取申请企业的溯源码信息 - 申请溯源码企业使用
    GetTraceList(param){
        return axios.post(`${base.base}/Trace/GetTraceList`,param)
    },

    //批量更新溯源码状态
    UpdateTraceByState(param){
        return axios.post(`${base.base}/Trace/UpdateTraceByState`,param)
    },

    
    //#endregion


    //#region 溯源码流转信息

    //获取溯源码流转信息列表
    GetTraceFlowList(param){
        return axios.post(`${base.base}/Trace/GetTraceFlowList`,param)
    },

    //获取单个流转信息
    GetTraceFlowInfo(param){
        return axios.get(`${base.base}/Trace/GetTraceFlowInfo`,{
            params:param
        })
    },

    //保存单个流转信息
    SaveTraceFlowInfo(param){
        return axios.post(`${base.base}/Trace/SaveTraceFlowInfo`,param)
    },

    //删除溯源流转记录
    DeleteTraceFlowList(param){
        return axios.post(`${base.base}/Trace/DeleteTraceFlowList`,param)
    },

    //#endregion


}

export default trace