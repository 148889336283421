<template>
  <div>
    <el-divider></el-divider>

    <el-descriptions
    :column="1"
    border>
        <el-descriptions-item label="统计起始日期">
            <el-date-picker v-model="info.startDate" type="datet" placeholder="起始日期" value-format="YYYY-MM-DD" :editable="false" :clearable="false" style="width:200px"/>
        </el-descriptions-item>

    </el-descriptions>
    <!-- 账户列表 -->
    <el-table :data="dataList" border stripe style="width: 100%" :height="400" @select="btnCheckBox" @select-all="btnCheckBox">
        <el-table-column prop="id" type="selection" width="70"></el-table-column>
        <el-table-column prop="accountNumber" label="账户编号"></el-table-column>
        <el-table-column prop="accountName" label="账户名称"></el-table-column>
        <el-table-column prop="totalPrice" label="账户余额"></el-table-column>
    </el-table>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnResetStat">重新统计</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElLoading, ElMessage } from 'element-plus'
import moment from 'moment'
export default {
    data(){
        return{
            info:{
                accountList:[],
                startDate:moment(new Date()).format("YYYY-MM-DD"),
            },
            dataList:[],   //账户列表
        }
    },
    methods:{

        //加载账户列表（只加载开启统计的账户）
        GetDataList(){
            let param = {
                statFlag:1
            }
            this.$api.finance.SelectAccountList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;  
                }else{
                    console.log(res.data.msg);
                }
            })
        },

        //选中多选框
        btnCheckBox(selection){
            this.info.accountList = selection.map(item=>item.accountNumber)
        },

        //提交-重新统计
        btnResetStat(){
            if(this.info.accountList.length<1){
                ElMessage.error('请选择要统计的账户')
                return false
            }

            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            //重新统计
            this.$api.finance.ManualStartTask(this.info).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                    this.info.accountList = []
                }else{
                    ElMessage.error(res.data.msg);
                }
            })            

        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.GetDataList();  //加载账户列表
    }
}
</script>

<style>

</style>