<template>
  <div>
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">          

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="产品类目">
                <el-tree-select v-model="info.search.cid" :data="selectCategoryList" @change="ChangeTreeSelect" :default-expand-all="true" filterable class="search-200"/>
            </el-form-item>

            <el-form-item label="品牌">
                <el-select v-model="info.search.brandId" placeholder="品牌" filterable class="search-200">
                    <el-option
                    v-for="item in treeBrandList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="创建日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>
            <el-button class="iconfont icon-dingdan" type="warning" plain @click="showCreatePurchase()">生成采购单</el-button>
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>    
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="cycleNumber" type="selection" width="50" />  
        <el-table-column label="计划单号" width="170">
            <template #default="scope">
                {{scope.row.planNumber}}
            </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="部门" />
        <el-table-column prop="cidName" label="产品类目" />
        <el-table-column prop="brandName" label="品牌" />
        <el-table-column prop="skuName" label="产品名称" min-width="100">
              <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格" />
        <el-table-column prop="unitName" label="单位" />
        <el-table-column prop="itemNum" label="采购数量" />
        <el-table-column prop="state" label="状态">
            <template #default="scope">
                <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                <el-tag v-if="scope.row.state ==2" type="danger">已驳回</el-tag>
                <el-tag v-if="scope.row.state ==3" type="success">已审核</el-tag>
                <el-tag v-if="scope.row.state ==4" type="info">已生成采购单</el-tag>
                <el-tag v-if="scope.row.state ==10">已关单</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="创建日期" width="110">
            <template #default="scope">
            <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="截止日期" width="110">
            <template #default="scope">
            <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.planNumber)">详情</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.planNumber)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
                </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

    </div>

    <el-affix position="bottom">
        <div class="page_two">
            <el-container class="page_right">
                <el-pagination
                v-model:currentPage="info.page"
                v-model:page-size="info.size"
                :page-sizes="[50, 200, 500, 1000]"
                :background="true"
                layout="total, sizes, prev, pager, next, jumper"
                :total="info.total"
                @current-change="btnPagechange"
                @size-change="btnSizechange"
                >
                </el-pagination>
            </el-container>
        </div>          
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditPlan :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditPlan>
    </el-drawer>

    <!-- 生成采购单-弹框 -->
    <el-dialog title="生成采购单"
    v-model="BoxCreatePurchase.isVisible"
    width="50%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
        <BoxCreatePurchase :purchaseInfo="purchaseInfo" ref="newPurchaseInfo"></BoxCreatePurchase>
        <template #footer>
            <el-button type="primary" @click="saveCreatePurchase()">生成采购单</el-button>
            <el-button @click="BoxCreatePurchase.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import CategoryList from '@/utils/mixins/CategoryList.js'  //产品分类
import UserDptList from '@/utils/mixins/UserDptList.js'   //用户关联部门列表
import EditPlan from '@/views/Pms/Plan/EditPlan.vue'
import { ElMessage } from 'element-plus'
import BoxCreatePurchase from '@/views/Pms/Plan/BoxCreatePurchase.vue'
export default {
  mixins:[UserDptList,CategoryList],
  components:{
    EditPlan,
    BoxCreatePurchase
  },
  data(){
      return{
          info:{
              dataList:[],  //Table数据
              page:1,
              size:50,
              total:0,  //总记录数
              id:0,  //新增、编辑 的参数
              ids:[],   //删除 的参数
              search:{
                  selectDepartmentList:[], //选中部门
                  state:0,  //状态
                  cid:0,  //产品类目
                  brandId:0,  //品牌
                  datePoint:'',  //创建时间
              },              
          },
          treeBrandList:[],   //品牌列表

          //编辑-弹出框
          BoxEditInfo:{
            isVisible:false,
            title:'计划单'
          },
          //生成采购单信息
          purchaseInfo:{
            numberList:[],   //计划单号数组
            departmentId:0,  //默认部门编号
          },
          //生成采购单-弹出框
          BoxCreatePurchase:{
            isVisible:false,
          }
      }
  },
  computed:{
    //自适应窗体高度
    GetDataHeight(){
        return window.innerHeight-235
    }
  },
  methods:{

    //获取品牌下拉列表
    GetTreeBrandList(){
        let param = {
            cid:this.info.search.cid
        }
        this.$api.goods.SelectCategoryBrandList(param).then(res=>{
            if(res.data.status === 200){
                this.treeBrandList = res.data.data
            }else{
                ElMessage.error(res.data.msg);
            }
        })
    },

    //加载数据列表
    GetDataList(){
        //创建时间
        if(this.info.search.datePoint == null){
            this.info.search.datePoint = ''
        }

        //获取、转换部门
        let newDepartmentList = []
        if(this.info.search.selectDepartmentList!=null){
            newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                return item
            })
        }

        let param = {
            departmentList:newDepartmentList,
            state:this.info.search.state,
            cid:this.info.search.cid,
            brandId:this.info.search.brandId,
            startDate:this.DateFormat(this.info.search.datePoint[0]),
            endDate:this.DateFormat(this.info.search.datePoint[1]),
            page:this.info.page,
            size:this.info.size
        }
        this.$api.erp.ListPlan(param).then(res=>{
            if(res.data.status === 200){
                this.info.dataList = res.data.data;
                //分页
                this.info.total = res.data.total
                this.info.page = res.data.pageNumber
            }else{
                ElMessage.error(res.data.msg);
            }
        }).catch(e=>{
            console.log(e)
        })
    },

    //选中多选框
    btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.planNumber)
        //
        this.purchaseInfo.numberList = selection.map(item=>item.planNumber)  //编号数组
        //部门编号
        if(selection.length>0){
            this.purchaseInfo.departmentId = selection[0].departmentId;
        }else{
            this.purchaseInfo.departmentId = 0;
        }

    },
    //分页-页数变化时
    btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
    },
    //分页-单页记录数变化时
    btnSizechange(size){
        this.info.size = size
        this.info.page = 1
        this.GetDataList() //加载Table数据
    },

    //新增编辑部门信息
    btnEditInfo(planNumber){
      this.info.id = planNumber
      if(planNumber==0){
          this.BoxEditInfo.title = "新增计划单"
      }else{
          this.BoxEditInfo.title = "编辑计划单"
      }
      this.BoxEditInfo.isVisible = true;
    },

    //关闭弹出框
    btnCloseBox(){
        this.BoxEditInfo.isVisible = false;
    },

    //删除按钮
    btnDeleteInfo(planNumber){
        let param = [planNumber]
        this.$api.erp.DeletePlan(param).then(res=>{
          if(res.data.status === 200){
              ElMessage.success(res.data.msg)
              this.GetDataList()
          }
          else{
              ElMessage.error(res.data.msg)
          }
        })
    },
    //批量删除
    btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要删除的选项')
            return false
        }

        this.$api.erp.DeletePlan(this.info.ids).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
        })
    },

    //重置按钮
    btnResetSearch(){
        this.info.search.selectDepartmentList = [];
        this.info.search.state=0;
        this.info.search.cid=0;
        this.info.search.brandId=0;
        this.info.search.datePoint='';  
        this.info.ids = [];  //编号数组
        this.info.page = 1,
        this.GetDataList()
    }, 

    //弹出-生成采购单
    showCreatePurchase(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要生成采购单的单据')
            return false
        }
        this.BoxCreatePurchase.isVisible = true;
    },
    //保存-生成采购单
    saveCreatePurchase(){
        //验证-部门
        if(this.$refs.newPurchaseInfo.allInfo.info.departmentId ===0){
            ElMessage.error("请选择部门");
            return false;
        }
        console.log(this.$refs.newPurchaseInfo.allInfo);
        this.$api.erp.CreatePurchase(this.$refs.newPurchaseInfo.allInfo).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg);
                //关闭
                this.BoxCreatePurchase.isVisible = false;
                this.GetDataList(); //重新加载
            }else{
                ElMessage.error(res.data.msg);
            }
        })
    },

  },
  mounted(){
    this.GetDataList();  //加载数据
    this.GetTreeBrandList();   //品牌列表
  }

}
</script>

<style>

</style>