<template>
  <div>
      <el-divider></el-divider>

      <el-descriptions
      :column="1"
      border>

        <el-descriptions-item label="用户名称">
          {{info.userName}}
        </el-descriptions-item>

        <el-descriptions-item label="所属部门">
          {{info.departmentName}}
        </el-descriptions-item>

        <el-descriptions-item label="性别">
          <span v-if="info.sex == 1">男</span>
          <span v-if="info.sex == 0">女</span>
        </el-descriptions-item>

        <el-descriptions-item label="手机号码">
          {{info.phone}}
        </el-descriptions-item>

        <el-descriptions-item label="用户头像">
          <el-image
              class="ImgTheme"
              v-if="info.logo"
              :src="info.logo"
              :preview-src-list="[info.logo]"
              :hide-on-click-modal="true"
              fit="fill">
          </el-image>
        </el-descriptions-item>

      </el-descriptions>



      <!-- 按钮 -->
      <div class="btnGroup">
          <el-button @click="btnClose">关闭</el-button>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            info:{
                id:0,
                systemNumber:'',
                userNumber:'',
                userName:'',
                departmentId:0,
                departmentName:'',
                loginName:'',
                logo:'',
                phone:'',
                sex:0,
            }
        }
    },
    methods:{
       
        //加载数据信息
        GetDataInfo(){
          this.$api.system.GetCurrentUserInfo().then(res=>{
            if(res.data.status === 200){
              this.info = res.data.data;
            }

          })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
      this.GetDataInfo()  //加载数据信息
    }
}
</script>

<style scoped>
  .ImgTheme{height: 80px;width: 80px;border: 1px solid #ccc;padding: 5px;}
</style>