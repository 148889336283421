<template>
  <div class="editInfo">
    <!-- 业务单(单据式) -->
    <el-divider></el-divider>

    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="业务单" name="TabInfo">
            <TabBusiness ref="tabBusiness"></TabBusiness>

            <TabDetail></TabDetail>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabOriginRecord></TabOriginRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabOriginState></TabOriginState>
        </el-tab-pane>
    </el-tabs>

    <div class="editFooter">
        <el-button v-if="CheckUserButtonAuth('BtnBusinessSave')" type="primary" @click="btnSaveBusinessOrigin">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import TabBusiness from '@/views/Bill/BusinessOrigin/TabBusiness.vue'
import TabDetail from '@/views/Bill/BusinessOrigin/TabDetail.vue'
import TabOriginRecord from '@/views/Bill/BusinessOrigin/TabOriginRecord.vue'
import TabOriginState from '@/views/Bill/BusinessOrigin/TabOriginState.vue'

import { ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
export default {
    props:['editId','dataList'],
    components:{
        TabBusiness,
        TabDetail,
        TabOriginRecord,
        TabOriginState,
    },
    data(){
        return{
            info:{
                activeName:'TabInfo',
                originNumber:this.editId,
            }
        }
    },
    computed:{
        ...mapState('Erp',['businessOrigin'])
    },
    methods:{
        ...mapMutations('Erp',['SetBusinessOrigin']),

        //获取单个业务单信息
        GetDataInfo(){
            if(this.info.originNumber!=0){
                let param = {
                    originNumber:this.info.originNumber
                }
                this.$api.erp.GetBusinessOriginAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetBusinessOrigin(res.data.data);

                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存业务单(单据式)
        btnSaveBusinessOrigin(){
            this.$api.erp.SaveBusinessOriginAllInfo(this.businessOrigin).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.originNumber === this.info.originNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.originNumber = prevInfo.originNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.originNumber === this.info.originNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.originNumber = prevInfo.originNumber
                this.GetDataInfo()
            }
        },

        //清空数据
        btnClearData(){
            let newInfo = {
                info:{
                    id:0,
                    systemNumber:'',
                    originNumber:'',
                    originType:0,
                    departmentId:0,
                    departmentName:'',
                    shiftNumber:'',
                    shiftName:'',
                    areaNumber:'',
                    areaName:'',
                    tradeType:0,
                    tradeNumber:'',
                    tradeName:'',
                    totalPrice:0,
                    payState:0,
                    remark:'',
                    createtor:'',
                    createName:'',
                    isDel:0
                },
                recordList:[],
                stateList:[]
            }
            this.SetBusinessOrigin(newInfo);
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },


    },
    mounted(){
        this.GetDataInfo();
    },
}
</script>

<style>

</style>