<template>
  <div>
      
      <!-- 列表 -->
        <el-table :data="wageInfo.stateList" border style="width: 100%">
            <el-table-column label="状态">
                <template v-slot="scope">
                    <span v-if="scope.row.state ==1">新建单</span>
                    <span v-if="scope.row.state ==2">待审核</span>
                    <span v-if="scope.row.state ==3">已驳回</span>
                    <span v-if="scope.row.state ==4">审核通过并上报</span>
                    <span v-if="scope.row.state ==5">审核已完成</span>
                    <span v-if="scope.row.state ==10">已关单</span>
                </template>
            </el-table-column>

            <el-table-column prop="remark" label="说明"></el-table-column>
            <el-table-column prop="receiverName" label="指定审核人"></el-table-column>

            <el-table-column label="创建时间">
                <template v-slot="scope">
                    <span>{{DateTimeFormat(scope.row.createTime)}}</span>
                </template>
            </el-table-column>

            <el-table-column label="创建者">
                <template v-slot="scope">
                    <span>{{scope.row.createName}}</span>
                </template>
            </el-table-column>

        </el-table>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Oa',['wageInfo'])
    },

}
</script>

<style>

</style>