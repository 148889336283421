<template>
  <!-- 编辑-询价单信息 -->
  <div>

      <el-form :inline="true"
        v-model="inquiryInfo.info"
        label-width="100px"
        label-position="right">
        <el-row v-if="inquiryInfo.info">

          <el-col :span="8">
              <el-form-item label="所属部门">
                  <el-tree-select v-model="inquiryInfo.info.departmentId" :data="departmentList" class="search-200"/>
              </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="询价方式">
              <el-select v-model="inquiryInfo.info.inquiryType" class="search-200">
                <el-option label="公开询价" :value="1"></el-option>
                <el-option label="指定供应商" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
              <el-form-item label="截止日期">
                  <el-date-picker v-model="inquiryInfo.info.endTime" type="date" placeholder="截止日期" value-format="YYYY-MM-DD" style="width:200px"/>
              </el-form-item>
          </el-col>

          <el-col :span="8">
              <el-form-item label="备注">
                  <el-input v-model="inquiryInfo.info.remark" class="search-200"></el-input>
              </el-form-item>
          </el-col>

          <el-col :span="8">
              <el-form-item label="状态">
                <el-tag v-if="inquiryInfo.info.state == 1">新询价单</el-tag>
                <el-tag v-if="inquiryInfo.info.state == 2">询价中</el-tag>
                <el-tag v-if="inquiryInfo.info.state == 3">已报价</el-tag>
                <el-tag v-if="inquiryInfo.info.state == 4">已关单</el-tag>
              </el-form-item>
          </el-col>

        </el-row>
      </el-form>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import UserDptList from '@/utils/mixins/UserDptList.js'
export default {
  name:'TabSingleInquiry',
  mixins:[UserDptList],
  computed:{
    ...mapState('Purchase',['inquiryInfo'])
  },

}
</script>

<style>

</style>