<template>
  <div>

    <!-- 模块列表 -->
    <el-tree
    :data="allModuleList"
    :default-checked-keys="systemInfo.selectModuleList"
    default-expand-all
    show-checkbox
    ref = "treeModule"
    node-key="moduleId">
        <template #default="{data}">
            <span :class="'iconfont '+data.icon">{{data.moduleName}}</span>
        </template>
    </el-tree>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return{
            allModuleList:[],   //全部模块列表
        }
    },
    computed:{
        ...mapState('System',['systemInfo'])
    },
    methods:{

        //加载全部模块列表
        loadDataAllModule(){
            //加载全部模块
            let param = {
                isShow:0
            }
            this.$api.system.SelectModuleList(param).then(res=>{
                if(res.data.status === 200){
                    this.allModuleList = res.data.data;
                    if(this.allModuleList == null){
                        this.allModuleList = []
                    }
                }
            })
        },
        

    },
    mounted(){
        this.loadDataAllModule();   //加载全部模块列表
    }
}
</script>

<style>

</style>