<template>
  <div>
      <div class="skuBtnList" v-if="evaluateInfo.info && evaluateInfo.info.themeNumber">
          <el-button class="iconfont icon-add" type="primary" plain @click="AddNode">新增指标</el-button>
      </div>

      <!-- 目录树 -->
      <div>
          <el-tree :data="evaluateInfo.treeTagList">
              <template #default="{node,data}">
                  <span class="custom-tree-node">
                    <span>
                        {{node.label}}
                    </span>
                    <span>
                        <el-button text type="danger" size="small" @click="DeleteNode(data)">删除</el-button>
                    </span>
                  </span>                  
              </template>
          </el-tree>
      </div>

      <!-- 新增节点 -->
      <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="40%"
      :destroy-on-close="true">
        <AddTag :number="info.number" ref="tagInfo"></AddTag>
        <template #footer>
            <el-button type="primary" @click="btnSaveData">保存</el-button>
            <el-button @click="BoxEditInfo.isVisible = false">关闭</el-button>
        </template>
      </el-dialog>


  </div>
</template>

<script>
import { mapState } from 'vuex'
import AddTag from '@/views/Service/Evaluate/Theme/AddTag.vue'
import { ElMessage } from 'element-plus/lib/components'
export default {
    name:'TabTag',
    data(){
        return{
            info:{
                number:'',
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'新增标签'
            },
        }
    },
    components:{
        AddTag
    },
    computed:{
        ...mapState('Purchase',['evaluateInfo']),
    },
    methods:{
        //...mapMutations('Purchase',['SetEvaluateInfo']),
        //新增指标节点
        AddNode(){
            this.info.number = this.evaluateInfo.info.themeNumber
            this.BoxEditInfo.isVisible = true
        },
        //删除节点
        DeleteNode(data){
            if(data.id!=0){
                let param = {
                    tagId:data.id
                }
                this.$api.service.DeleteEvaluateTag(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                        //更新目录树
                        this.GetTreeTagList()
                    }
                    else
                    {
                        ElMessage.error(res.data.msg)
                    }
                })
            }
            else
            {
                ElMessage.error('该节点不能删除')
            }
        },
        //保存数据
        btnSaveData(){
            var newTag = this.$refs.tagInfo.info
            this.$api.service.SaveEvaluateTag(newTag).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.BoxEditInfo.isVisible = false
                    //更新目录树
                    this.GetTreeTagList()
                }
                else
                {
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重新获取目录树
        GetTreeTagList(){
            let param = {
                themeNumber:this.evaluateInfo.info.themeNumber
            }
            this.$api.service.SelectEvaluateTagList(param).then(res=>{
                if(res.data.status === 200){
                    this.evaluateInfo.treeTagList = res.data.data
                }
                else
                {
                    ElMessage.error(res.data.msg)
                }
            })
        }


    }
}
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>