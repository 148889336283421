<template>
  <div>

    <!-- 频道信息 -->
    <el-form :inline="true"
    v-model="channelInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="channelInfo.info">

            <el-col :span="8">
                <el-form-item label="频道名称">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>频道名称</span>
                  </template>
                    <el-input v-model="channelInfo.info.channelTitle" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="排序">
                    <el-input-number v-model="channelInfo.info.sort" :min="0" class="search-200"></el-input-number>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="频道类型">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>频道类型</span>
                  </template>
                    <el-select v-model="channelInfo.info.channelType" class="search-200">
                        <el-option label="轮播频道" :value="1"></el-option>
                        <el-option label="活动频道" :value="2"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="上线标识">
                    <el-select v-model="channelInfo.info.onLine" class="search-200">
                        <el-option label="上线" :value="1"></el-option>
                        <el-option label="下线" :value="0"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="频道说明">
                    <el-input v-model="channelInfo.info.content" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="图片">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>图片</span>
                  </template>
                  <el-image
                        class="ChannelImg"
                        v-if="channelInfo.info.bgImg"
                        :src="channelInfo.info.bgImg"
                        :preview-src-list="[channelInfo.info.bgImg]"
                        :hide-on-click-modal="true"
                        fit="fill">
                    </el-image>
                  <el-button plain type="primary" @click="BoxImgInfo.isVisible=true">图库</el-button>
                </el-form-item>                 
            </el-col>


        </el-row>
    </el-form>

    <!-- 图库 弹出框 -->
    <el-dialog :title="BoxImgInfo.title" 
    v-model="BoxImgInfo.isVisible"
    width="65%"
    :destroy-on-close="true">
        <SelectChannelImg ref="SelectImgStock"></SelectChannelImg>
        <template #footer>
            <el-button type="primary" @click="btnSelectImgStock">选择</el-button>
            <el-button @click="BoxImgInfo.isVisible = false">取消</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import SelectChannelImg from '@/components/common/SelectChannelImg.vue'

import { mapState } from 'vuex'
export default {
    components:{
      SelectChannelImg
    },
    data(){
      return{
        //图库-弹出框
        BoxImgInfo:{
            isVisible:false,
            title:'频道图库'
        },
      }
    },
    computed:{
      ...mapState('Shop',['channelInfo'])
    },
    methods:{
      //选择图库
        btnSelectImgStock(){
            let itemList = this.$refs.SelectImgStock.selectedItem
            this.channelInfo.info.bgImg = itemList;
            this.BoxImgInfo.isVisible = false;  //关闭图库
        }
    }
}
</script>

<style scoped>
  .ChannelImg {
    width: 140px;
    height: 70px;
    border: solid 1px #ccc;
    margin-right: 5px;
  }
</style>