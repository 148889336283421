<template>
  <div>
    <!-- 分享零售单页 -->

    分享页
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>