
/**
 * 系统模块
 */

// 系统相关的
import ListModule from '@/views/system/Module/ListModule'
import ListSystem from '@/views/system/Info/ListSystem.vue'
import ListMenu from '@/views/system/Menu/ListMenu'   //PC菜单
import ListMobileMenu from '@/views/system/MobileMenu/ListMobileMenu.vue' //移动菜单
import ListRole from '@/views/system/Role/ListRole'
import ListDepartment from '@/views/system/Department/ListDepartment'
import ListUser from '@/views/system/User/ListUser'
import ListSystemArea from '@/views/system/Area/ListSystemArea'  //大区管理

//设备
import ListPrinter from '@/views/Device/Printer/ListPrinter.vue'  //云打印机
import ListClockIn from '@/views/Device/ClockIn/ListClockIn.vue'  //云考勤机

//日志
import ListLogin from '@/views/Log/ListLogin'    //登录日志
import ListLoginError from '@/views/Log/ListLoginError'   //登录失败日志
import ListSystemFlow from '@/views/Log/ListSystemFlow'


export default [
    {
        //系统模块
        name:'ListModule',
        path:'listmodule',
        component:ListModule
    },
    {
        //用户列表
        name:'ListUser',
        path:'listuser',
        component:ListUser
    },
    {
        //系统列表
        name:'ListSystem',
        path:'listsystem',
        component:ListSystem
    },
    {
        //菜单
        name:'ListMenu',
        path:'listmenu',
        component:ListMenu
    },
    {
        //移动菜单
        name:'ListMobileMenu',
        path:'listmobilemenu',
        component:ListMobileMenu
    },
    {
        //角色列表
        name:'ListRole',
        path:'listrole',
        component:ListRole
    },{
        //部门列表
        name:'ListDepartment',
        path:'listdepartment',
        component:ListDepartment
    },
    {
        //大区管理
        name:'ListSystemArea',
        path:'listsystemarea',
        component:ListSystemArea
    },

    {
        //云打印机
        name:'ListPrinter',
        path:'listprinter',
        component:ListPrinter
    },
    {
        //云考勤机
        name:'ListClockIn',
        path:'listclockin',
        component:ListClockIn
    },

    {
        //登录日志列表
        name:'ListLogin',
        path:'listlogin',
        component:ListLogin
    },
    {
        //登录失败日志列表
        name:'ListLoginError',
        path:'listloginerror',
        component:ListLoginError
    },
    {
        //所有系统流量统计
        name:'ListSystemFlow',
        path:'listsystemflow',
        component:ListSystemFlow
    },

]