<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row v-if="info">

            <el-col :span="224">
                <el-form-item label="规则日">
                    <el-date-picker v-model="info.ruleDay" type="date" placeholder="规则日" value-format="YYYY-MM-DD" style="width:200px"/>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="规则类型">
                    <el-select v-model.number="info.ruleType" class="search-200">
                        <el-option :value="1" label="假期日"></el-option>
                        <el-option :value="2" label="工作日"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="备注">
                    <el-input v-model="info.remark" class="search-200"></el-input>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { ElMessage } from 'element-plus'
export default {
    props:['editId'],
    data(){
        return{
            info:{
                id:0,
                ruleDay:moment(new Date()).format("YYYY-MM-DD"),
                ruleType:1,
                remark:'',
                isDel:0
            }
        }
    },
    methods:{

        //获取数据
        GetDataInfo(){
            if(this.editId !=0){
                let param = {
                    id:this.editId
                }
                this.$api.oa.SingleRuleWorkDay(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }
                })
            }            
        },

        //保存数据 type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            this.info.ruleDay = moment(this.info.ruleDay).format("YYYY-MM-DD");
            this.$api.oa.SaveRuleWorkDay(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                    }                    
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //清空数据
        btnClearData(){
            this.info = {
                id:0,
                ruleDay:moment(new Date()).format("YYYY-MM-DD"),
                ruleType:1,
                remark:'',
                isDel:0
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style>
</style>