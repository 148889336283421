<template>
  <div>
    <el-divider></el-divider>
    <!-- 部门关联的产品列表 -->
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true"
            label-width="80px"
            label-position="right">

                <el-form-item label="产品类目">
                    <el-tree-select v-model="info.search.cid" :data="selectCategoryList" @change="ChangeTreeSelect" :default-expand-all="true" class="search-200"/>
                </el-form-item>

                <el-form-item label="品牌">
                    <el-select v-model="info.search.brandId" placeholder="请选择" class="search-200">
                            <el-option
                            v-for="item in treeBrandList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id">
                            </el-option>
                        </el-select>
                </el-form-item>

                <el-form-item label="名称">
                    <el-input v-model="info.search.searchName" placeholder="分类、品牌、产品" v-on:keyup.enter="GetDataList" class="search-200"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
          </el-col>
    </el-row>

    <el-table :data="dataList" border stripe :height="450" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="50" /> 
        <el-table-column prop="cidName" label="类目" min-width="90"/>
        <el-table-column prop="brandName" label="品牌" min-width="80"/>
        <el-table-column prop="spuName" label="产品名称" min-width="100">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格" min-width="100"/>
        <el-table-column label="产品类型" min-width="90">
            <template v-slot="scope">
                <el-tag v-if="scope.row.spuType ==1">产品</el-tag>
                <el-tag v-if="scope.row.spuType ==2" type="warning">耗材</el-tag>
            </template>
        </el-table-column>

        <el-table-column prop="skuNumber" label="操作" width="80">
          <template v-slot="scope">
                <el-button size="small" type="danger" @click="btnDeleteInfo(scope.row.skuNumber)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[20, 50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 功能按钮组 -->
    <div class="btnGroup" >
        <el-button type="primary"  @click="btnNewSku">新增</el-button>
        <el-button type="danger" @click="btnBatchDeleteInfo">删除</el-button>        
        <el-button @click="btnClose">关闭</el-button>
    </div>

    <!-- 选择产品 -->
    <el-dialog :title="BoxSkuInfo.title" 
      v-model="BoxSkuInfo.isVisible"
      width="70%"
      :destroy-on-close="true">
        <SelectAllSkuList ref="SelectSkuInfo"></SelectAllSkuList>
      <template #footer>
          <el-button type="primary" @click="btnSelectData">选择</el-button>
          <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import SelectAllSkuList from '@/components/common/SelectAllSkuList.vue'
import CategoryList from '@/utils/mixins/CategoryList.js'
import { mapState } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  props:['dptInfo'],
  mixins:[CategoryList],
  components:{
    SelectAllSkuList
  },
  data(){
    return{
      dataList:[],  //Table数据
      info:{
        page:1,   //页数
        size:20,  //单页记录数
        total:0,  //总记录数
        id:0,  //新增、编辑 的参数
        ids:[],   //删除 的参数
        search:{  //搜索条件
            cid:0,
            brandId:0,
            searchName:'',
        }
      },
      treeBrandList:[],  //品牌列表
      //选择产品-弹出框
      BoxSkuInfo:{
        isVisible:false,
        title:'选择产品'
      },
    }
  },
  computed:{
      ...mapState('SetUp',['setDptInfo'])
  },
  methods:{
    //获取品牌下拉列表
    GetTreeBrandList(cid){
        let param = {
            cid:cid
        }
        this.$api.goods.SelectCategoryBrandList(param).then(res=>{
            if(res.data.status === 200){
              this.treeBrandList = res.data.data
            }else{
              this.treeBrandList = []
            }
        })
    },

    //获取产品列表
    GetDataList(){
      let param = {
        setDptNumber:this.dptInfo.setDptNumber,
        departmentId:this.dptInfo.departmentId,
        cid:this.info.search.cid,
        brandId:this.info.search.brandId,
        searchName:this.info.search.searchName,
        page:this.info.page,
        size:this.info.size,
      }
      this.$api.common.PageSetDptSkuList(param).then(res=>{
        if(res.data.status === 200){
          this.dataList = res.data.data;
          //分页
          this.info.total = res.data.total
          this.info.page = res.data.pageNumber
        }
      })
    },
    //选中多选框
    btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.skuNumber)
    },
    //分页-页数变化时
    btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
    },
    //分页-单页记录数变化时
    btnSizechange(size){
        this.info.size = size
        this.info.page = 1
        this.GetDataList() //加载Table数据
    },

    //删除按钮
    btnDeleteInfo(skuNumber){
        ElMessageBox.confirm('确定要删除该信息吗?','删除提示',{
            confirmButtonText: '确认',
            cancelButtonText: '取消'
        }).then(()=>{
            let param = {
              setDptNumber:this.dptInfo.setDptNumber,
              skuNumList:[skuNumber],
            }
            this.$api.common.DeleteSetDptSku(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        }).catch(()=>{
            //取消
        })
        
    },
    //批量删除
    btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要删除的选项')
            return false
        }

        ElMessageBox.confirm('确定要删除选中信息吗?','删除提示',{
            confirmButtonText: '确认',
            cancelButtonText: '取消'
        }).then(()=>{
          let param = {
            setDptNumber:this.dptInfo.setDptNumber,
            skuNumList:this.info.ids,
          }
          this.$api.common.DeleteSetDptSku(param).then(res=>{
              if(res.data.status === 200){
                  ElMessage.success(res.data.msg)
                  this.GetDataList();
                  this.info.ids = [];
              }
              else{
                  ElMessage.error(res.data.msg)
              }
          })
        }).catch(()=>{
            //取消
        })        
    },

    //新增产品
    btnNewSku(){
      this.BoxSkuInfo.isVisible = true;
    },
    //保存选中产品
    async btnSelectData(){
      let itemList = this.$refs.SelectSkuInfo.selectedItem;
      let skuNumberList=[];
      if(itemList !=null && itemList.length>0){
        itemList.forEach(item => {
          skuNumberList.push(item.skuNumber);
        });
      }
      
      let param = {
        setDptNumber:this.dptInfo.setDptNumber,
        departmentId:this.dptInfo.departmentId,
        skuList:skuNumberList
      }
      this.$api.common.SaveSetDptSku(param).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          //关闭
          this.BoxSkuInfo.isVisible = false;
        }
      })
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

    btnResetSearch(){
      this.info.search.cid=0;
      this.info.search.brandId=0;
      this.info.search.searchName='';
      this.info.ids = [];  //编号数组
      this.info.page = 1;
      this.GetDataList();
    },

  },
  mounted(){
    this.GetTreeBrandList(0);
    this.GetDataList();  //获取数据
  },
}
</script>

<style>

</style>