<template>
  <div>
    
    <el-table :data="orderInfo.payList" border show-summary style="width: 100%">
      <el-table-column label="支付方式">
        <template #default="scope">
          <span v-if="scope.row.payMode ==1">货到付款</span>
          <span v-if="scope.row.payMode ==2">微信支付</span>
          <span v-if="scope.row.payMode ==3">支付宝支付</span>
          <span v-if="scope.row.payMode ==4">优惠券支付</span>
          <span v-if="scope.row.payMode ==5">积分支付</span>
        </template>
      </el-table-column>
      <el-table-column prop="payPrice" label="支付金额"></el-table-column>
      <el-table-column label="支付状态">
        <template #default="scope">
          <el-tag v-if="scope.row.payState ==1">未支付</el-tag>
          <el-tag v-if="scope.row.payState ==2">已支付</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="支付时间">
        <template #default="scope">
            <span>{{DateFormat(scope.row.payTime)}}</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="DetailHeight"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name:'TabOrderPay',
  computed:{
    ...mapState('Shop',['orderInfo'])
  },
}
</script>

<style scoped>
.DetailHeight{height: 20px;}
</style>