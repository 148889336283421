<template>
  <div>
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">
            
            <el-form-item label="账户">
                <el-select v-model="info.search.accountList" multiple collapse-tags collapse-tags-tooltip class="search-200">
                    <el-option v-for="account in selectAccountList" :key="account.accountNumber" :label="account.accountName" :value="account.accountNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="日期">
                <el-date-picker v-model="info.search.datePoint" 
                type="daterange" 
                range-separator="To"
                start-placeholder="开始日期"
                end-placeholder="结束日期" 
                style="width:250px"/>
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
                <el-button class="iconfont icon-shujujianguan" type="primary" plain @click="OpenBoxStat">重新统计</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>              
                
            </el-col>
        </el-row>

        <!-- 数据列表 -->
        <el-table :data="dataList" border stripe show-summary style="width: 100%" :height="GetDataHeight">
            <el-table-column prop="id" type="selection" width="70"></el-table-column>
            <el-table-column prop="accountName" label="账户名称"></el-table-column>
            <el-table-column prop="createDate" label="日期">
                <template v-slot="scope">
                  <span>{{DateFormat(scope.row.createDate)}}</span> 
                </template>
            </el-table-column>
            <el-table-column prop="startSurplus" label="期初余额"></el-table-column>
            <el-table-column prop="inPrice" label="收款发生额"></el-table-column>
            <el-table-column prop="outPrice" label="付款发生额"></el-table-column>
            <el-table-column prop="surplusPrice" label="发生额"></el-table-column>
            <el-table-column prop="endSurplus" label="期末余额"></el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[50, 200, 500,1000]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
            >
            </el-pagination>
        </el-container>

    </div>

    <!-- 重新统计-弹出框 -->
    <el-dialog :title="BoxEditInfo.title"
    v-model="BoxEditInfo.isVisible"
    width="750"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true" 
    @closed="GetDataList">
        <BoxStat @closeBox="btnCloseBox"></BoxStat>

    </el-dialog>

  </div>
</template>

<script>
import BoxStat from '@/views/Fms/Surplus/BoxStat.vue'
import accountList from '@/utils/mixins/AccountList.js'
import { ElMessage } from 'element-plus'
import throttle from '@/utils/throttle'
export default {
    mixins:[accountList],
    components:{
        BoxStat
    },
    data(){
        return{
            dataList:[],  //Table数据
            info:{                
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    accountList:[],
                    datePoint:'',
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'重新统计'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
        //加载数据
        GetDataList(){
            //统计日期
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            let param = {
                accountList:this.info.search.accountList,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.ListSurplus(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            })
        },

        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },


        //重置按钮
        btnResetSearch(){
            this.info.search.accountList =[];
            this.info.search.datePoint = '';
            this.info.page = 1;
            this.GetDataList()
        }, 

        //手动启动任务：节流示例
        ManualStartTask:throttle(()=>{
            ElMessage.warning('功能开发中！');
        },2000),

        //打开重新核算-弹出框
        OpenBoxStat(){
            this.BoxEditInfo.isVisible=true;
        },
        //关闭-重新核算-弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },


    },
    mounted(){
        this.GetDataList()
    }
    

}
</script>

<style>

</style>