<template>
  <div>
    <!-- 系统设置 -->

    <el-descriptions title="系统设置"
    :column="3"
    border>
        <template #extra>
          <el-button type="primary">保存设置</el-button>
        </template>

        <el-descriptions-item label="微信AppId">            
            <el-input v-model="info.wxAppId" maxlength="200"></el-input>
        </el-descriptions-item>

        <el-descriptions-item label="微信AppSecret">            
            <el-input v-model="info.wxAppSecret" maxlength="200"></el-input>
        </el-descriptions-item>

        <el-descriptions-item label="微信商户ID">            
            <el-input v-model="info.wxMchid" maxlength="200"></el-input>
        </el-descriptions-item>


    </el-descriptions>

  </div>
</template>

<script>
export default {
  data(){
    return{
      info:{
        wxAppId:'',
        wxAppSecret:'',
        wxMchid:'',

      }
    }
  }
}
</script>

<style>

</style>