// 系统列表页
<template>
  <div>
      <!-- 搜索功能 -->
      <el-row :style="showSearch.search_hidden">
          <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label="系统名称">
                  <el-input v-model="info.search.systemName" class="search-200"></el-input>
                </el-form-item>

                <!-- <el-form-item label="识别码">
                  <el-input v-model="info.search.certNumber"></el-input>
                </el-form-item> -->

                <el-form-item label="审核状态">
                  <el-select v-model="info.search.state" class="search-200">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="正常"></el-option>
                    <el-option :value="2" label="关闭"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
            </el-col>
      </el-row>

      <div>
        <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
              <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
              <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
                    </el-tooltip>
                </el-button-group>         
              
            </el-col>
          </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe style="width: 100%" :height="GetDataHeight">
          <el-table-column prop="id" type="selection" width="50" />  
          <el-table-column prop="systemNumber" label="系统编号" width="170" />
          <el-table-column prop="systemName" label="系统名称" min-width="90">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.systemName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.systemName}}</div>
                </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column prop="companyName" label="企业名称" min-width="90">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.companyName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.companyName}}</div>
                </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column label="租户类型">
            <template #default="scope">
              <span>{{scope.row.systemTypeName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="registerCapital" label="注册资金(万)"/>
          <el-table-column prop="remark" label="备注"/>
          
          <el-table-column prop="state" label="状态" width="100" >
            <template #default="scope">
              <el-tag v-if="scope.row.state==1" >正常</el-tag>
              <el-tag v-else-if="scope.row.state==2" type="info">关闭</el-tag>
            </template>
          </el-table-column>
          
          <el-table-column label="创建日期" width="110">
            <template #default="scope">
              <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="strTermTime" label="有效期" width="110">
            <template #default="scope">
              <span>{{DateFormat(scope.row.termTime)}}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="150" fixed="right">
            <template v-slot="scope">
              <el-button size="small" type="primary" @click="btnEditInfo(scope.row.systemNumber)">详情</el-button>
              <el-button size="small" type="danger" @click="btnDeleteInfo(scope.row.systemNumber)">删除</el-button>
              
            </template>
          </el-table-column>
        </el-table>

      </div>

      <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditSystem :editId="info.id" @closeBox="btnCloseBox"></EditSystem>
    </el-drawer>

    

  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import EditSystem from '@/views/system/Info/EditSystem.vue'
export default {
  components:{
      EditSystem
    },
    data(){
        return{            
            info:{
                dataList:[],  //Table数据
                page:1,   //页数
                size:50,  //单页记录数
                total:0,  //总记录数
                id:0,  //单个新增、编辑
                ids:[],   //新增、编辑、删除 的参数
                search:{  //搜索条件
                  systemName:'',
                  certNumber:'',
                  state:0  // 审核状态 0:全部 1:未审核 2:审核通过 3:审核驳回
                }
            },
            //新增、编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'新增系统'
            },
           
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
        
        //加载数据列表
        GetDataList(){
          let param = {
            systemName:this.info.search.systemName,
            certNumber:this.info.search.certNumber,
            state:this.info.search.state,
            page:this.info.page,
            size:this.info.size
          }
          this.$api.system.GetSystemInfoList(param).then(res=>{
              if(res.status === 200){
                  this.info.dataList = res.data.data;  //获取数据
                  //分页
                  this.info.total = res.data.total
                  this.info.page = res.data.pageNumber
              }
          })
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //删除系统
        btnDeleteInfo(systemNumber){
          ElMessageBox.prompt('系统相关业务数据将被执行删除，请输入执行密码:','删除系统',{
            inputType:'password',
            confirmButtonText:'确定',
            cancelButtonText:'取消',
            type:'warning'
          }).then(({value})=>{
            if(value == 'yefeng566399'){
              let param = {
                systemNumber:systemNumber
              }
              this.$api.system.DeleteDeepSystemInfo(param).then(res=>{
                if(res.data.status === 200){
                  ElMessage.success(res.data.msg)
                  this.GetDataList()
                }
                else{
                  ElMessage.error(res.data.msg)
                }
              })
            }else{
              ElMessage.error('执行密码错误,操作失败!')
            }
          }).catch(()=>{
            ElMessage.info('取消执行删除');
          })
        },
        //重置按钮
        btnResetSearch(){
          this.info.search.systemName='',
          this.info.search.certNumber='',
          this.info.search.state = 0
          this.GetDataList()
        },
        //导出系统列表
        btnExportInfo(){
          let param = {
            systemName:this.info.search.systemName,
            certNumber:this.info.search.certNumber,
            state:this.info.search.state,
            page:this.info.page,
            size:this.info.size
          }
          this.$api.system.ExportSystem(param).then(res=>{
            if(res.data.status === 200){
              const alink = document.createElement('a');
              alink.href = res.data.data;
              document.body.appendChild(alink);
              alink.click();
              document.body.removeChild(alink)
            }
            else{
              ElMessage({type:'error',message:res.data.msg})
            }
          })
        },

        //新增、编辑-打开弹出框
        btnEditInfo(systemNumber){
          this.info.id = systemNumber;
          if(systemNumber==0){
            this.BoxEditInfo.title = "新增系统";
          }else{
            this.BoxEditInfo.title = "编辑系统";
          }
          this.BoxEditInfo.isVisible = true;
        },

        //关闭
        btnCloseBox(){
          this.BoxEditInfo.isVisible = false;
        },

    },
    mounted(){
        this.GetDataList() //加载Table数据
    }
}
</script>

<style>
    .operation {margin-bottom: 5px;}
    .operation .operation_hidden {text-align: right;}
    .page_one {float: right;margin-top: 5px;}
</style>