<template>
  <div>

    <el-row :gutter="5" class="RowGap">
      <!-- 今日经营概况 -->
      <el-col :md="12" :sm="24">
        <el-card>
          <template #header>
            <div class="card-header">
              <div>
                <i class="iconfont icon-chart-bar myicon"></i>
                <span class="myTitle">今日经营概况</span>
              </div>          
              <el-link @click="btnManualUpdate">手动更新</el-link>
            </div>              
          </template>
          <el-row :gutter="10">

            <el-col :xl="8" :lg="12" :md="24">
              <div class="staticCard">
                  <div class="staticValue fontBlue">
                    <span style="font-size:14px;">¥</span><span>{{info.saleTotalPrice}}</span>
                  </div>
                  <div class="staticNum">销售总金额</div>
              </div>
            </el-col>

            <el-col :xl="8" :lg="12" :md="24">
              <div class="staticCard">
                  <div class="staticValue fontRed">
                    <span style="font-size:14px;">¥</span><span>{{info.salePayTotalPrice}}</span>
                  </div>
                  <div class="staticNum">已结款销售金额</div>
              </div>
            </el-col>

            <el-col :xl="8" :lg="12" :md="24">
              <div class="staticCard">
                  <div class="staticValue fontBlue">
                    <span style="font-size:14px;">¥</span><span>{{info.purTotalPrice}}</span>
                  </div>
                  <div class="staticNum">采购总金额</div>
              </div>
            </el-col>

            <el-col :xl="8" :lg="12" :md="24">
              <div class="staticCard">
                  <div class="staticValue fontRed">
                    <span style="font-size:14px;">¥</span><span>{{info.purPayTotalPrice}}</span>
                  </div>
                  <div class="staticNum">已结款采购金额</div>
              </div>
            </el-col>

            <el-col :xl="8" :lg="12" :md="24">
              <div class="staticCard">
                  <div class="staticValue fontRed">
                    <span style="font-size:14px;">¥</span><span>{{info.retailTotalPrice}}</span>
                  </div>
                  <div class="staticNum">零售总金额</div>
              </div>
            </el-col>

            <el-col :xl="8" :lg="12" :md="24">
              <div class="staticCard">
                  <div class="staticValue fontBlue">
                    <!-- <span style="font-size:14px;">¥</span> -->
                    <span>0</span>
                  </div>
                  <div class="staticNum">加工单</div>
              </div>
            </el-col>

          </el-row>

          <el-row>
            <el-col style="display: flex;justify-content: flex-start;">
              <span style="font-size:12px;">数据来自:业务单、零售单</span>
            </el-col>
          </el-row>

        </el-card>
      </el-col>

      <!-- 昨日经营概况 -->
      <el-col :md="12" :sm="24">
        <el-card>
          <template #header>
            <div class="card-header">
              <div>
                <i class="iconfont icon-chart-bar myicon"></i>
                <span class="myTitle">昨日经营概况</span>
              </div>          
              <!-- <el-link @click="btnManualUpdate">手动更新</el-link> -->
            </div>              
          </template>

          <el-row :gutter="10">

            <el-col :xl="8" :lg="12" :md="24">
              <div class="staticCard">
                  <div class="staticValue fontBlue">
                    <span style="font-size:14px;">¥</span><span>{{info.prevSaleTotalPrice}}</span>
                  </div>
                  <div class="staticNum">销售总金额</div>
              </div>
            </el-col>

            <el-col :xl="8" :lg="12" :md="24">
              <div class="staticCard">
                  <div class="staticValue fontRed">
                    <span style="font-size:14px;">¥</span><span>{{info.prevSalePayTotalPrice}}</span>
                  </div>
                  <div class="staticNum">已结款销售金额</div>
              </div>
            </el-col>

            <el-col :xl="8" :lg="12" :md="24">
              <div class="staticCard">
                  <div class="staticValue fontBlue">
                    <span style="font-size:14px;">¥</span><span>{{info.prevPurTotalPrice}}</span>
                  </div>
                  <div class="staticNum">采购总金额</div>
              </div>
            </el-col>

            <el-col :xl="8" :lg="12" :md="24">
              <div class="staticCard">
                  <div class="staticValue fontRed">
                    <span style="font-size:14px;">¥</span><span>{{info.prevPurPayTotalPrice}}</span>
                  </div>
                  <div class="staticNum">已结款采购金额</div>
              </div>
            </el-col>

            <el-col :xl="8" :lg="12" :md="24">
              <div class="staticCard">
                  <div class="staticValue fontRed">
                    <span style="font-size:14px;">¥</span><span>{{info.prevRetailTotalPrice}}</span>
                  </div>
                  <div class="staticNum">零售总金额</div>
              </div>
            </el-col>

            <el-col :xl="8" :lg="12" :md="24">
              <div class="staticCard">
                  <div class="staticValue fontBlue">
                    <!-- <span style="font-size:14px;">¥</span> -->
                    <span>0</span>
                  </div>
                  <div class="staticNum">加工单</div>
              </div>
            </el-col>

          </el-row>

          <el-row>
            <el-col style="display: flex;justify-content: flex-end;">
              <span style="font-size:12px;">2小时更新</span>
            </el-col>
          </el-row>

        </el-card>
      </el-col>

    </el-row>

    <!-- 采购额、销售额 -->
    <el-row :gutter="5" class="RowGap">
      <el-col :md="12" :sm="24">
        <el-card>
          <template #header>
            <div class="card-header">
              <div>
                <i class="iconfont icon-shengchanguochengguanli myicon"></i>
                <span class="myTitle">采购额</span>
              </div>  
              <el-radio-group size="small" v-model="purchaseTab">
                <el-radio-button :value="1">近30天</el-radio-button>
                <el-radio-button :value="2">近一年</el-radio-button>
                <el-radio-button :value="3">近五年</el-radio-button>
              </el-radio-group>               
            </div>              
          </template>
          <!-- 统计图 -->
          <div id="main" class="echarts" style="width:100%;height:300px;" ></div>
        </el-card>
      </el-col>

      <el-col :md="12" :sm="24">
        <el-card>
          <template #header>
            <div class="card-header">
              <div>
                <i class="iconfont icon-dianpu myicon"></i>
                <span class="myTitle">销售额</span>
              </div> 
              <el-radio-group size="small" v-model="saleTab">
                <el-radio-button :value="1">近30天</el-radio-button>
                <el-radio-button :value="2">近一年</el-radio-button>
                <el-radio-button :value="3">近五年</el-radio-button>
              </el-radio-group>               
            </div>              
          </template>
          <!-- 统计图 -->
          <div id="main2" class="echarts" style="width:100%;height:300px;" ></div>
        </el-card>
      </el-col>
    </el-row>


  </div>
</template>

<script>
import * as echarts from 'echarts'  //Echarts 图标组件
import { mapActions } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
  data(){
    return{
      info:{
        saleTotalPrice:0,  //销售总额
        salePayTotalPrice:0,   //已结款销售总额
        purTotalPrice:0,    //采购总额
        purPayTotalPrice:0,
        retailTotalPrice:0,  //零售单总额

        prevSaleTotalPrice:0,  //昨日-销售总额
        prevSalePayTotalPrice:0,
        prevPurTotalPrice:0,
        prevPurPayTotalPrice:0,
        prevRetailTotalPrice:0,

      },
      purchaseTab:1,  //采购标签： 近30天、近一年。近五年
      saleTab:1,  //销售标签： 近30天、近一年。近五年
      salePriceList:[0,0,0,0,0,0,0,0,0,0],
    }
  },
  methods:{
    ...mapActions('Master',['work_AddTag']),
    
    //打开-功能介绍(作废)
    btnFlowVideo(){
      //1.添加标签
      let newTag = {
        name:'功能介绍',
        isClose:true, //是否有关闭功能
        routeName:'ListVideo',  //路由
        effect:'dark'
      }
      this.work_AddTag(newTag)
      //2.跳转到页面
      this.$router.push({
        name:'ListVideo'
      })
    },


    //验证用户密码最后修改时间
    VerifyUserLoginTime(){
      this.$api.system.GetLastChangePwdDate().then(res=>{
        if(res.data.status ===400){
          ElMessage({
            type:'warning',
            message:res.data.msg,
            duration:5000
          });
        }
      })
    },

    //加载首页统计数据
    OnLoadHomeInfo(isNew){
      let param = {
        isNew:isNew,
      }
      this.$api.erp.GetHomeDataInfo(param).then(res=>{
        if(res.data.status === 200){
          this.info = res.data.data;
        }
      })
    },

    //手动更新
    btnManualUpdate(){
      this.OnLoadHomeInfo(1);  //手动加载首页数据
    },

    //示例
    myEcharts(){
        var myChart = echarts.init(document.getElementById('main'));
        var myChart2 = echarts.init(document.getElementById('main2'));
        
        //配置图表
        var option = {
          tooltip: {
            trigger: 'axis',   //显示标签
          },
          xAxis: {
            type: 'category',
            data: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
          },
          yAxis: [
                {
                    type: 'value',
                    name:'金额',
                    position: 'left',
                    axisLabel: {
                        formatter: '{value} 元'
                    }
                },
          ],
          series: [
            
            {
              name:'采购额',
              yAxisIndex: 0,  //用的是左侧Y坐标
              data: this.salePriceList,
              type: 'bar',  //柱状图
              itemStyle:{
                color:'#33ccff'
              },  //颜色
              tooltip:{
                valueFormatter:function(value){
                  return value + ' 元'
                }
              },
            },
          ]
        };
        myChart.setOption(option);
        myChart2.setOption(option);
    },

  },
  mounted(){
    this.VerifyUserLoginTime();  //验证用户最后修改密码时间
    this.OnLoadHomeInfo(0);  //获取首页数据(缓存)
    this.myEcharts();
  }
}
</script>

<style scoped>
.myicon{padding-right: 10px;color:#409eff;}
.myTitle{font: bolder;}
.staticCard {background-color: #f4f6fe;border-radius: 10px;height: 80px;margin-bottom: 10px;text-align: center;}
.staticCard .staticValue{height: 40px;display: flex;align-items: flex-end;justify-content: center;font-weight:900;font-size: 18px;}
.staticCard .fontBlue{color: #409eff;}
.staticCard .fontRed{color: red;}
.staticCard .fontGray{color: #939399;}
.staticCard .staticNum{height: 40px;display: flex;align-items: center;justify-content: center;}


.RowGap{margin-top: 10px;}


.card-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

  
</style>