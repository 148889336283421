/**
 * 获取地区列表
 * ===下拉列表专用===
 */


const AreaList = {
    data(){
        return{
            areaList:[]
        }
    },
    methods:{
        //加载地区下拉列表  SelectTreeAreaList2
        SelectAreaList(){
            this.$api.common.SelectTreeAreaList2().then(res=>{
                if(res.data.status === 200){
                    this.areaList = res.data.data;
                }
            }).catch(e=>{
                console.log(e)
            })
        }
    },
    mounted(){
        this.SelectAreaList();   //
    }
}

export default AreaList