<template>
  <div>
    <el-divider></el-divider>
    <!-- 库存账：查看原始单据页面 -->

    <!-- 主信息 -->
    <el-descriptions class="margin-top"
    :column="3"
    border v-if="allInfo.info">
      <el-descriptions-item label="单号">
        {{allInfo.info.outInNumber}}
      </el-descriptions-item>

      <el-descriptions-item label="日期">
        {{allInfo.info.inOutTime}}
      </el-descriptions-item>

      <el-descriptions-item label="部门">
        {{allInfo.info.departmentName}}
      </el-descriptions-item>

      <el-descriptions-item label="大区">
        {{allInfo.info.areaName}}
      </el-descriptions-item>

      <el-descriptions-item label="出入库类型">
        {{allInfo.info.outInTypeName}}
      </el-descriptions-item>

      <el-descriptions-item label="原始单据类型">
        <span v-if="allInfo.info.originType == 1">采购单</span>
        <span v-if="allInfo.info.originType == 2">销售单</span>
        <span v-if="allInfo.info.originType == 6">加工单</span>
        <span v-if="allInfo.info.originType == 8">报损单</span>
        <span v-if="allInfo.info.originType == 13">移仓单</span>
        <span v-if="allInfo.info.originType == 14">退供单</span>
        <span v-if="allInfo.info.originType == 15">退货单</span>
      </el-descriptions-item>

      <el-descriptions-item label="交易者类型">
        <span v-if="allInfo.info.tradeType == 1">采购商</span>
        <span v-if="allInfo.info.tradeType == 2">客户</span>
        <span v-if="allInfo.info.tradeType == 3">员工</span>
      </el-descriptions-item>

      <el-descriptions-item label="交易者">
        {{allInfo.info.tradeName}}
      </el-descriptions-item>

      

    </el-descriptions>
    <!-- 明细列表 -->
    <div class="rowHeigh"></div>
    <el-table :data="allInfo.detailList" border stripe :height="250" style="width: 100%">
        <el-table-column prop="warehouseName" label="仓库" />
        <el-table-column prop="cidName" label="产品类目" />
        <el-table-column prop="brandName" label="品牌" />
        <el-table-column label="产品" width="150">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="规格" width="100">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.skuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.skuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="unitName" label="单位" />
        <el-table-column prop="realNum" label="实际数量" />
        <el-table-column prop="unitPrice" label="单价" />
        <el-table-column prop="totalPrice" label="总价" />
    </el-table>

  </div>
</template>

<script>
export default {
  props:['selectRow'],
    data(){
      return{
        allInfo:{
          info:{
            outInTypeName:'',
          }
        }
      }
    },
    methods:{
      GetDataInfo(){
        console.log(this.selectRow)
        if(this.selectRow.outInType == 1){
          if(this.selectRow.originType == 8){
            //报损单: 特殊，因为没有出库单
            let param = {
              lossNumber:this.selectRow.outInNumber
            }
            this.$api.wms.SingleLossAllInfo(param).then(res=>{
              if(res.data.status === 200){
                this.allInfo = res.data.data;
                this.allInfo.info.outInNumber = this.allInfo.info.lossNumber;  //设置通用字段
                this.allInfo.info.inOutTime = this.allInfo.info.createTime;
                this.allInfo.info.originType = 8; //报损单
                this.allInfo.info.outInTypeName = '出库单';
              }
            })
          }else{
            //出库：销售单、退供单
            let param = {
              outNumber:this.selectRow.outInNumber
            }
            this.$api.wms.SingleBillOutAllInfo(param).then(res=>{
              if(res.data.status === 200){
                  this.allInfo = res.data.data;
                  this.allInfo.info.outInNumber = this.allInfo.info.outNumber;  //设置通用字段
                  this.allInfo.info.inOutTime = this.allInfo.info.outTime;
                  this.allInfo.info.outInTypeName = '出库单';
              }
            })
          }          
        }else{
          //入库：采购、退货单
          let param = {
            inNumber:this.selectRow.outInNumber
          }
          this.$api.wms.SingleBillInAllInfo(param).then(res=>{
            if(res.data.status === 200){
                this.allInfo = res.data.data;
                this.allInfo.info.outInNumber = this.allInfo.info.inNumber;  //设置通用字段
                this.allInfo.info.inOutTime = this.allInfo.info.inTime;
                this.allInfo.info.outInTypeName = '入库单';
            }
          })
        }
        
      },
    },
    mounted(){
      this.GetDataInfo();
    },
}
</script>

<style scoped>
.rowHeigh{height: 20px;}
</style>