<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
        v-model="info"
        label-width="80px"
        label-position="right">
            <el-row>

                <el-col :span="24">
                    <el-form-item label="内容" style="width:100%;">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>内容</span>
                        </template>
                        <el-input type="textarea" v-model="info.content" placeholder="请输入打印内容" :rows="7" :maxlength="200" :show-word-limit="true"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
    </el-form>

    <div class="btnGroup">
        <el-button type="primary" class="iconfont icon-dayin" @click="btnTestPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    props:['editId','printerNumber'],
    data(){
        return{
            info:{
                content:'',
                num:1,
            }
        }
    },
    methods:{
        //打印
        btnTestPrint(){
            let param = {
                printerNumber:this.printerNumber,
                content:this.info.content,
                num:this.info.num
            }
            this.$api.system.TestPrinter(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    }
}
</script>

<style>

</style>