<template>
  <div>
    <!-- 新增、编辑 零售班组 -->
    <el-divider></el-divider>

    <!-- 零售班组 -->
    <TabGroupInfo></TabGroupInfo>

    <el-divider>零售班组成员</el-divider>
    <TabGroupStaff></TabGroupStaff>

    <!-- 功能按钮 -->
    <div class="btnGroup">      
      <el-button v-if="CheckUserButtonAuth('BtnRetailGroupSave')" type="primary" @click="btnSaveInfo()">保存</el-button>
      <el-button @click="btnClose">关闭</el-button>

      <el-button-group>
          <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
          <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
      </el-button-group>
    </div>

  </div>
</template>

<script>
import TabGroupInfo from '@/views/Rms/RetailGroup/TabGroupInfo.vue'
import TabGroupStaff from '@/views/Rms/RetailGroup/TabGroupStaff.vue'
import { mapMutations, mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],  
    components:{
        TabGroupInfo,
        TabGroupStaff,
    },
    data(){
        return{
            info:{
                retailGroupNumber:this.editId,   //零售班组编号
            }
        }
    },
    computed:{
        ...mapState('Erp',['retailGroupInfo'])
    },
    methods:{
        ...mapMutations('Erp',['SetRetailGroupInfo']),

        //获取信息
        GetDataInfo(){
            if(this.info.retailGroupNumber !=0){
                let param = {
                    retailGroupNumber:this.info.retailGroupNumber,
                }
                this.$api.erp.GetRetailGroupAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetRetailGroupInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存
        btnSaveInfo(){
            this.$api.erp.SaveRetailGroupAllInfo(this.retailGroupInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.retailGroupNumber === this.info.retailGroupNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.retailGroupNumber = prevInfo.retailGroupNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.retailGroupNumber === this.info.retailGroupNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.retailGroupNumber = prevInfo.retailGroupNumber
                this.GetDataInfo()
            }
        },

        //清空数据实体
        btnClearData(){
            let newInfo = {
                info:{
                    systemNumber:'',
                    retailGroupName:'',
                    departmentId:this.userDepartmentId,  //默认用户当前所属部门
                    sort:0,  //排序
                    state:1,  //班组状态 1:休息 2:在班
                },
                staffList:[],  //成员列表
            }
            this.SetRetailGroupInfo(newInfo);
        },


    },
    mounted(){
        this.GetDataInfo();
    }
}
</script>

<style>

</style>