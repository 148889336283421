<template>
  <div>
    <!-- 班次主表信息 -->

    <el-form :inline="true"
        v-model="shiftRecordInfo.info"
        label-width="100px"
        label-position="right">

        <el-row v-if="shiftRecordInfo.info">
            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="班次编号">
                    <el-input v-model="shiftRecordInfo.info.shiftNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属部门">
                    <el-tree-select v-if="shiftRecordInfo.info.state === 1" v-model="shiftRecordInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                    <el-tree-select v-else v-model="shiftRecordInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200" disabled/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="排班日期">
                    <el-date-picker v-if="shiftRecordInfo.info.state ===1" v-model="shiftRecordInfo.info.classDate" type="date" placeholder="排班日期" value-format="YYYY-MM-DD" style="width:200px"/>
                    <el-date-picker v-else v-model="shiftRecordInfo.info.classDate" type="date" placeholder="排班日期" value-format="YYYY-MM-DD" style="width:200px" disabled/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="排班类型">
                    <el-select v-model="shiftRecordInfo.info.classType" class="search-200">
                        <el-option label="早班" :value="1"></el-option>
                        <el-option label="午班" :value="2"></el-option>
                        <el-option label="晚班" :value="3"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="在班班组">
                    <el-select v-model="shiftRecordInfo.info.retailGroupNumber" class="search-200">
                        <el-option v-for="retailGroup in UserRetailGroupList" :key="retailGroup.retailGroupNumber" :label="retailGroup.retailGroupName" :value="retailGroup.retailGroupNumber"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="开始时间">
                    <el-input v-model="shiftRecordInfo.info.startTime" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="结束时间">
                    <el-input v-model="shiftRecordInfo.info.endTime" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="系统发生额">
                    <el-input-number v-model="shiftRecordInfo.info.totalPrice" disabled :precision="2" :controls="false" class="search-200"></el-input-number>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="实际交易额">
                    <el-input-number v-model="shiftRecordInfo.info.realPrice" :precision="2" :controls="false" class="search-200" disabled></el-input-number>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="差额">
                    <el-input-number v-model="shiftRecordInfo.info.diffPrice" :precision="2" :controls="false" class="search-200" disabled></el-input-number>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="状态">
                    <el-tag v-if="shiftRecordInfo.info.state == 1" type="info">未开始</el-tag>
                    <el-tag v-if="shiftRecordInfo.info.state == 2">在班中</el-tag>
                    <el-tag v-if="shiftRecordInfo.info.state == 3" type="warning">已交班</el-tag>
                    <el-tag v-if="shiftRecordInfo.info.state == 4">待审核</el-tag>
                    <el-tag v-if="shiftRecordInfo.info.state == 5" type="danger">已驳回</el-tag>
                    <el-tag v-if="shiftRecordInfo.info.state == 6" type="info">已审核</el-tag>
                    <el-tag v-if="shiftRecordInfo.info.state == 10" type="info">已完成</el-tag>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'  //用户授权部门列表
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList],
    data(){
        return{
            UserRetailGroupList:[],  //用户授权部门下的班组列表
        }
    },
    computed:{
        ...mapState('Erp',['shiftRecordInfo'])
    },
    methods:{
        //加载零售班组
        LoadUserRetailGroupList(){
            this.$api.erp.GetUserRetailGroupList().then(res=>{
                if(res.data.status === 200){
                    this.UserRetailGroupList = res.data.data;
                    //新增时默认加载第一个
                    if(this.UserRetailGroupList!=null && this.shiftRecordInfo.info.shiftNumber==''){
                        this.shiftRecordInfo.info.retailGroupNumber = this.UserRetailGroupList[0].retailGroupNumber;
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },
    },
    mounted(){
        this.LoadUserRetailGroupList();  //加载零售班组
    },  
}
</script>

<style>

</style>