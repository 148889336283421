<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="报损单" name="tabLoss">
            <TabLoss ref="tabLoss"></TabLoss>

            <el-divider>报损单明细</el-divider>
            <TabLossDetail></TabLossDetail>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabLossRecord></TabLossRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabLossState></TabLossState>
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="lossInfo.info">
        <el-button type="primary" v-if="lossInfo.info.state==1 || lossInfo.info.state==3" @click="btnSaveData">保存</el-button>
        <el-button v-if="lossInfo.info.lossNumber !='' && (lossInfo.info.state==1 || lossInfo.info.state == 3)" type="primary" @click="btnUpdateState(2)">提交审核</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnLossCheck') && lossInfo.info.state == 2" type="warning" @click="BoxCheckState.isVisible = true">审核</el-button>
        <el-button v-if="lossInfo.info.state == 4" type="danger" @click="btnCancelLoss()">撤单</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnLossConfirm') && lossInfo.info.state == 4 " type="success" @click="btnUpdateState(5)">确认报损</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 审核-弹框 -->
    <el-dialog title="报损单审核"
    v-model="BoxCheckState.isVisible"
    width="20%">
        <el-divider></el-divider>
        <div>报损单是否审核通过?</div>
        <template #footer>            
            <el-button type="primary" @click="btnUpdateState(4)">通过</el-button>
            <el-button type="danger" @click="btnUpdateState(3)">驳回</el-button>
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import TabLoss from '@/views/Wms/Loss/TabLoss.vue'
import TabLossDetail from '@/views/Wms/Loss/TabLossDetail.vue'
import TabLossRecord from '@/views/Wms/Loss/TabLossRecord.vue'
import TabLossState from '@/views/Wms/Loss/TabLossState.vue'

import { ElLoading, ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
export default {
  props:['editId','dataList'],
  components:{
    TabLoss,
    TabLossDetail,
    TabLossRecord,
    TabLossState
  },
  data(){
      return{
          info:{
              activeName:'tabLoss',
              lossNumber:this.editId,
          },
          //审核-弹出框
          BoxCheckState:{
              isVisible:false,
          }
      }
  },
  computed:{
      ...mapState('Erp',['lossInfo'])
  },
  methods:{
    ...mapMutations('Erp',['SetLossInfo']),

    //加载数据
    GetDataInfo(){
        if(this.info.lossNumber!=0){
          let param = {
            lossNumber:this.info.lossNumber
          }
          this.$api.wms.SingleLossAllInfo(param).then(res=>{
            if(res.data.status === 200){
              this.SetLossInfo(res.data.data)
            }else{
              this.btnClearData();
            }
            //加载
            this.$refs.tabLoss.SelectUserAreaList();   //加载用户关联的大区列表 
          })
        }else{
          this.btnClearData();
          //加载
            this.$refs.tabLoss.SelectUserAreaList();   //加载用户关联的大区列表 
        }
    },

    //保存数据
    btnSaveData(){
      //打开遮罩层
      const loading = ElLoading.service({
          lock:true,
          text:'正在提交,请稍候!',
          background:'rgba(0, 0, 0, 0.7)',
      })
        this.$api.wms.SaveLossAllInfo(this.lossInfo).then(res=>{
          loading.close();
          if(res.data.status === 200){
            ElMessage.success(res.data.msg);
            //刷新页面
            this.lossInfo.info.lossNumber = res.data.data;
            this.info.lossNumber = res.data.data;
            this.GetDataInfo();
          }else{
            ElMessage.error(res.data.msg);
          }
        })
    },

    //提交审核状态
    btnUpdateState(state){
      //打开遮罩层
      const loading = ElLoading.service({
          lock:true,
          text:'正在提交,请稍候!',
          background:'rgba(0, 0, 0, 0.7)',
      })
        let param = {
            lossNumber:this.lossInfo.info.lossNumber,
            state:state
        }
        this.$api.wms.UpdateLossState(param).then(res=>{
          loading.close();
          if(res.data.status === 200){
              ElMessage.success(res.data.msg);
              this.BoxCheckState.isVisible = false;
              //更新
              this.GetDataInfo();
          }else{
              ElMessage.error(res.data.msg)
          }
        })
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

    //清空数据
    btnClearData(){
      let newInfo = {
        info:{
          systemNumber:'',
          lossNumber:'',
          departmentId:this.userDepartmentId,
          areaNumber:'',
          areaName:'',
          totalPrice:0,
          state:1,
          isDel:0
        },
        detailList:[],
        recordList:[],
        stateList:[]
      }
      this.SetLossInfo(newInfo);
    },

    //上一条
    btnPrevious(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.lossNumber === this.info.lossNumber
        })
        let prevIndex = currentIndex -1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有上一条了");
            return false;
        }
        else{
            this.info.lossNumber = prevInfo.lossNumber
            this.GetDataInfo()
        }
        
    },
    //下一条
    btnNext(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.lossNumber === this.info.lossNumber
        })
        let prevIndex = currentIndex + 1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有下一条了");
            return false;
        }
        else{
            this.info.lossNumber = prevInfo.lossNumber
            this.GetDataInfo()
        }
    },

    //撤单-报损单
    btnCancelLoss(){
      let param = {
        lossNumber:this.info.lossNumber
      }
      this.$api.wms.CancelLoss(param).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          this.lossInfo.info.state = 1;
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

  },
  mounted(){
    this.GetDataInfo()
  }

}
</script>

<style scoped>
.SingleInfo {min-height: 200px;}
</style>