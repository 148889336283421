
//会员信息
import ListMember from '@/views/Member/Info/ListMember.vue'
//会员标签
import ListMemberTag from '@/views/Member/Tag/ListTag.vue'
//会员积分
import ListScore from '@/views/Member/Score/ListScore.vue'


export default[
    {
        //会员信息
        name:'ListMember',
        path:'listmember',
        component:ListMember
    },
    {
        //会员标签
        name:'ListMemberTag',
        path:'listmembertag',
        component:ListMemberTag
    },
    {
        //会员积分
        name:'ListScore',
        path:'listscore',
        component:ListScore
    },
]