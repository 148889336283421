<template>
  <div>
    <!-- 编辑移动端菜单 -->
    <el-divider></el-divider>

    <el-form :inline="true"
    v-model="info"
    label-width="80px"
    label-position="right">
        <el-row>
            <el-col :sm="12" :xs="24">
                <el-form-item label="所属菜单">
                    <el-tree-select v-model="info.pid" :data="TreeMenuList" check-strictly :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :sm="12" :xs="24">
                <el-form-item label="菜单名称">
                    <el-input v-model="info.menuName" class="search-200"></el-input>
                </el-form-item>
            </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="图标">
                    <el-input v-model="info.icon" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="链接地址">
                    <el-input v-model="info.jumpName" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="排序">
                    <el-input v-model.number="info.sort" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="备注">
                    <el-input v-model="info.remark" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="菜单类型">
                      <el-radio-group v-model="info.menuType">
                          <el-radio :label="1">模块</el-radio>
                          <el-radio :label="2">菜单</el-radio>
                          <el-radio :label="3">按钮</el-radio>
                      </el-radio-group>                    
                 </el-form-item>
              </el-col>

              <el-col :sm="12" :xs="24">
                  <el-form-item label="状态">
                      <el-radio-group v-model="info.state">
                          <el-radio :label="0">停用</el-radio>
                          <el-radio :label="1">正常</el-radio>
                      </el-radio-group>                    
                  </el-form-item>
              </el-col>
        </el-row>
    </el-form>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
export default {
    props:['editId'],
    data(){
        return{
            info:{
                menuId:0,
                pid:0,
                menuName:'',
                menuType:1,
                icon:'',
                jumpName:'',
                jump:'',
                sort:0,
                jumpType:0,
                state:1,
                remark:''
            },
            TreeMenuList:[],   //菜单列表
            selectModuleId:0,   //选中模块编号
        }
    },
    methods:{
        //获取目录树列表
        GetTreeMenuList(){
            let param = {
              isButton:0   //是否包含按钮 0:不包含 1:包含
            }
            this.$api.system.SelectAllMobileMenuList(param).then(res=>{
                if(res.data.status === 200){
                    this.TreeMenuList = res.data.data;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //加载数据
        GetDataInfo(){            
            if(this.editId!=0){
                let param = {
                    menuId:this.editId
                }
                this.$api.system.GetMobileMenuByMenuId(param).then(res=>{
                    if(res.data.status ===200){
                        this.info = res.data.data
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }  
        },

        //保存数据方法
        btnSaveData(){
            this.$api.system.SaveMobileMenu(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.GetTreeMenuList();
        this.GetDataInfo();
    },

}
</script>

<style>

</style>