<template>
  <div>
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="费用单号">
                <el-input v-model="info.search.costNumber" maxlength="20" class="search-150"></el-input>
            </el-form-item>
            
            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-200">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="单据类型">
                <el-select v-model="info.search.billType" class="search-150">
                    <el-option label="全部" :value="0" />
                    <el-option v-for="typeInfo in financeTypeList" :key="typeInfo.typeValue" :label="typeInfo.typeName" :value="typeInfo.typeValue"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="审核状态">
                <el-select v-model="info.search.state" class="search-150">
                    <el-option label="全部" :value="0" />
                    <el-option label="新建单" :value="1" />
                    <el-option label="待审核" :value="2" />
                    <el-option label="已驳回" :value="3" />
                    <el-option label="已审核并上报" :value="4" />
                    <el-option label="已完成" :value="5" />
                </el-select>
            </el-form-item>

            <el-form-item label="财务标识">
                <el-select v-model="info.search.financeFlag" class="search-150">
                    <el-option label="全部" :value="-1" />
                    <el-option label="未生成" :value="0" />
                    <el-option label="已生成" :value="1" />
                </el-select>
            </el-form-item>

            <el-form-item label="支付状态">
                <el-select v-model="info.search.payState" class="search-150">
                    <el-option label="全部" :value="-1" />
                    <el-option label="未支付" :value="0" />
                    <el-option label="已支付" :value="1" />
                </el-select>
            </el-form-item>

            <el-form-item label="审核人">
                <el-select v-model="info.search.receiver" filterable clearable class="search-150">
                    <el-option v-for="item in selectUserList" :key="item.userNumber" :label="item.userName" :value="item.userNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="记账日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnCostAdd')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
                <el-popconfirm v-if="CheckUserButtonAuth('BtnCostDelete')"
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
                <el-button v-if="CheckUserButtonAuth('BtnCostCreateFinance')" class="iconfont icon-dingdan" type="warning" plain @click="showCreateFinance()">生成财务单</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                                    
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
                    </el-tooltip>
                </el-button-group>        
                
            </el-col>
        </el-row>

        <!-- 数据列表 -->
        <el-table :data="dataList" border stripe show-summary :height="GetDataHeight" @row-dblclick="ClickRowData" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="单据编号"  width="165">
                <template v-slot="scope">
                    <span>{{scope.row.costNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="departmentName" label="部门名称" min-width="100">
                <template v-slot="scope">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.departmentName"
                    placement="top"
                  >
                  <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="单据类型" min-width="90">
                <template v-slot="scope">
                    <span>{{scope.row.billTypeName}}</span>
                </template>
            </el-table-column>
            <el-table-column label="交易者类型" min-width="95">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                    <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                    <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                    <el-tag v-if="scope.row.tradeType ==4" type="info">散客</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="交易者" min-width="120">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.tradeName"
                        placement="top">
                        <div class="cellOverFlow">{{scope.row.tradeName}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="说明" min-width="100">
                <template v-slot="scope">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.costName"
                    placement="top"
                  >
                  <div class="cellOverFlow">{{scope.row.costName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            
            <el-table-column prop="totalPrice" label="总金额"></el-table-column>

            <el-table-column label="审核人">
                <template v-slot="scope">
                    <span>{{scope.row.receiverName}}</span>
                </template>
            </el-table-column>

            <el-table-column label="审核状态" width="90">
                <template #default="scope">
                    <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                    <el-tag v-if="scope.row.state ==2" type="success">待审核</el-tag>
                    <el-tag v-if="scope.row.state ==3" type="danger">已驳回</el-tag>
                    <el-tag v-if="scope.row.state ==4" type="warning">已审核并上报</el-tag>
                    <el-tag v-if="scope.row.state ==5" type="info">已完成</el-tag>
                    <el-tag v-if="scope.row.state ==10">已关单</el-tag>
                </template>
            </el-table-column>
            
            <el-table-column label="财务标识" width="90">
                <template #default="scope">
                    <el-tag v-if="scope.row.financeFlag == 0" type="danger">未生成</el-tag>
                    <el-tag v-if="scope.row.financeFlag == 1" type="info">已生成</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="支付状态" width="95">
                <template #default="scope">
                    <el-tag v-if="scope.row.payState == 0" type="danger">未支付</el-tag>
                    <el-tag v-if="scope.row.payState == 1" type="info">已支付</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="记账时间" width="110">
                <template #default="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.billTime"
                        placement="top"
                    >
                    <span>{{DateFormat(scope.row.billTime)}}</span>
                    </el-tooltip>                
                </template>
            </el-table-column>
            <el-table-column label="操作" width="130" fixed="right">
              <template v-slot="scope">
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.costNumber)">详情</el-button>
                <el-popconfirm v-if="CheckUserButtonAuth('BtnCostDelete')"
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.costNumber)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
              </template>
            </el-table-column>
        </el-table>
    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditCost :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditCost>
    </el-drawer>

    <!-- 生成财务单-弹框 -->
    <el-dialog title="生成财务单"
    v-model="BoxCreateFinance.isVisible"
    width="45%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
        <BoxCreateFinance :financeInfo="financeInfo" ref="newFinanceInfo"></BoxCreateFinance>
        <template #footer>            
            <el-button type="primary" @click="btnCreateFinance()">生成财务单</el-button>
            <el-button @click="BoxCreateFinance.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import FinaceTypeList from '@/utils/mixins/FinanceTypeList.js'  //财务单类型列表
import AllUserList from '@/utils/mixins/AllUserList.js'    //全部用户列表

import { ElLoading, ElMessage } from 'element-plus'
import EditCost from '@/views/Fms/Cost/EditCost.vue'
import moment from 'moment'
import BoxCreateFinance from '@/components/common/BoxCreateFinance.vue'  //生成财务单
export default {
    components:{
        EditCost,
        BoxCreateFinance
    },
    mixins:[UserDptList,AllTradeList,FinaceTypeList,AllUserList],
    data(){
        return{
            dataList:[],  //Table数据
            info:{                
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    costNumber:'',  //费用单号
                    tradeNumber:'',  //交易者编号
                    billType:0,  //单据类型
                    state:0,   //状态
                    payState:-1,  //支付状态
                    receiver:'',   //审核人
                    financeFlag:-1,  //财务单标识
                    selectDepartmentList:[],
                    datePoint:'',   //截止日期
                },
            },
            financeInfo:{
                numberList:[],  //编号数组
                priceList:[], 
                departmentId:0,
                billType:0, //财务类型： 按选择的给出默认
                subId:0,
                totalPrice:0,
                billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                tradeType:0,
                tradeNumber:'',
                tradeName:'',
            },  //生成财务单信息
            //生成财务单-弹出框
            BoxCreateFinance:{
                isVisible:false,
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'账户详情'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-285
        }
    },
    methods:{

        //加载数据列表
        GetDataList(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                costNumber:this.info.search.costNumber,  //费用单号
                tradeNumber:this.info.search.tradeNumber,
                billType:this.info.search.billType,
                state:this.info.search.state,
                financeFlag:this.info.search.financeFlag,  //财务标识
                departmentList:newDepartmentList,
                payState:this.info.search.payState,   //支付状态
                receiver:this.info.search.receiver,   //审核人
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.ListCost(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.costNumber)
            this.financeInfo.numberList = selection.map(item=>item.costNumber)  //编号数组
            this.financeInfo.priceList = selection.map(item=> parseFloat(item.totalPrice))  //计算总金额
            this.financeInfo.billType = selection.find(item=>item).costType;   //财务类型
            this.financeInfo.tradeNumber = selection.find(item=>item).tradeNumber;  //交易者编号
            this.financeInfo.departmentId = selection.find(item=>item).departmentId;  //部门
        },
        
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增编辑信息
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "新增费用单"
            }else{
                this.BoxEditInfo.title = "编辑费用单"
            }  
            this.BoxEditInfo.isVisible = true;
        },
        //双击某一行时(查看财务单详情)
        ClickRowData(row){
            this.BoxEditInfo.title = "编辑费用单"
            this.info.id = row.costNumber
            this.BoxEditInfo.isVisible = true;
        },

        //删除按钮
        btnDeleteInfo(costNumber){
            let param = [costNumber]
            this.$api.finance.DeleteCostAllInfo(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.finance.DeleteCostAllInfo(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.costNumber="";  //费用单号
            this.info.search.tradeNumber="";  //交易者编号
            this.info.search.billType=0;  //单据类型
            this.info.search.state=0;   //状态
            this.info.search.financeFlag = -1;  //财务单标识
            this.info.search.selectDepartmentList = [];
            this.info.search.datePoint = "";
            this.info.search.payState=-1;   //支付状态
            this.info.search.receiver="";   //审核人
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        },  

        //费用单生成财务单
        showCreateFinance(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要生成财务单的单据')
                return false
            }
            //计算总金额
            this.financeInfo.totalPrice = 0;
            this.financeInfo.priceList.forEach(element => {
                this.financeInfo.totalPrice =  this.financeInfo.totalPrice + element
            });
            this.BoxCreateFinance.isVisible =true
        },
        //保存-费用单生成财务单
        btnCreateFinance(){
            //验证-部门
            if(this.$refs.newFinanceInfo.info.departmentId ===0){
                ElMessage.error("请选择部门");
                return false;
            }

            //验证-会计科目
            if(this.$refs.newFinanceInfo.info.subId ===0){
                ElMessage.error("请选择会计科目");
                return false;
            }

            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            this.$api.finance.CreateFinance(this.$refs.newFinanceInfo.info).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //关闭
                    this.BoxCreateFinance.isVisible = false;
                    this.GetDataList();  //重新加载
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },       

        //导出费用单列表到Excel
        btnExportInfo(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                costNumber:this.info.search.costNumber,
                tradeNumber:this.info.search.tradeNumber,
                billType:this.info.search.billType,
                state:this.info.search.state,
                financeFlag:this.info.search.financeFlag,
                departmentList:newDepartmentList,
                payState:this.info.search.payState,    //支付状态
                receiver:this.info.search.receiver,    //审核人
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }

            this.$api.finance.ExportCost(param).then(res=>{
                if(res.data.status === 200){
                    const alink = document.createElement('a');
                    alink.href = res.data.data;
                    document.body.appendChild(alink);
                    alink.click();
                    document.body.removeChild(alink)
                }
                else
                {
                    ElMessage.error(res.data.msg)
                }
            }).catch(err=>{
                console.log(err)
            })
        },
        

    },
    mounted(){
        this.GetDataList()   //获取数据列表
    }

}
</script>

<style>
</style>