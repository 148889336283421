/**
 * 供应商模块接口列表
 */

 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const supplier = {
    //#region 供应商信息

    //获取供应商列表
    PageSupplierList(param){
        return axios.post(`${base.base}/Supplier/PageSupplierList`,param)
    },

    //获取单个供应商信息（包括信息、发票、产品）
    SingleSupplierAllInfo(param){
        return axios.get(`${base.base}/Supplier/SingleSupplierAllInfo`,{
            params:param
        })
    },

    //获取单个供应商信息(不包含子信息)
    SingleSupplier(param){
        return axios.get(`${base.base}/Supplier/SingleSupplier`,{
            params:param
        })
    },

    //保存供应商信息(包括信息、发票、产品)
    SaveSupplier(param){
        return axios.post(`${base.base}/Supplier/SaveSupplier`,param)
    },

    //删除供应商信息(包括信息、发票、产品) 可能会有问题
    DeleteSupplier(param){
        return axios.post(`${base.base}/Supplier/DeleteSupplier`,param)
    },

    //导出供应商列表
    ExportSupplier(param){
        return axios.post(`${base.base}/Supplier/ExportSupplier`,param)
    },

    //#endregion

    //#region  供应商发票

    //获取发票列表
    ListInvoice(param){
        return axios.get(`${base.base}/Supplier/ListInvoice`,{
            params:param
        })
    },

    //获取单个发票信息

    //删除发票
    DeleteInvoice(param){
        return axios({
            url:`${base.base}/Supplier/DeleteInvoice`,
            method:'POST',
            params:param
        })
    },

    //获取默认发票信息
    GetDefInvoice(param){
        return axios.get(`${base.base}/Supplier/GetDefInvoice`,{
            params:param
        })
    },

    //#endregion

    //#region 供应商资质

    //删除单个供应商资质
    DeleteSupplierCert(param){
        return axios({
            url:`${base.base}/Supplier/DeleteSupplierCert`,
            method:'post',
            params:param
        })
    },

    //#endregion

    //#region  供应商产品

    //获取供应商产品列表
    ListSupplierSku(param){
        return axios.get(`${base.base}/Supplier/ListSupplierSku`,{
            params:param
        })
    },

    //删除供应商产品
    DeleteSupplierSku(param){
        return axios({
            url:`${base.base}/Supplier/DeleteSupplierSku`,
            method:'post',
            params:param
        })
    },

    //#endregion
    
    //#region 供应商申请链接

    //列表
    PageSupplierApplyList(param){
        return axios.post(`${base.base}/SupplierApply/PageSupplierApplyList`,param)
    },

    //保存
    SaveSupplierApply(){
        return axios.post(`${base.base}/SupplierApply/SaveSupplierApply`)
    },

    //删除
    DeleteSupplierApply(param){
        return axios.post(`${base.base}/SupplierApply/DeleteSupplierApply`,param)
    },

    //#endregion


 }

 export default supplier