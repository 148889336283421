<template>
  <div>
    <!-- 选择产品 -->
    <div class="skuBtnList" v-if="saleInfo.info && saleInfo.info.state <=2">
      <el-button class="iconfont icon-add" type="primary" plain @click="BtnSelectSku">选择产品</el-button>
    </div>

    <!-- 销售列表 -->
    <el-table :data="saleInfo.detailList" border stripe show-summary style="width: 100%">
        <el-table-column prop="warehouseName" label="仓库" min-width="90"/>
        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="90">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" min-width="90"/>
        <el-table-column label="单位" min-width="100">
            <template v-slot="scope">
                <el-select v-if="scope.row.isEdit==1" v-model="scope.row.unitId"  @change="ChangeSkuUnit(scope.$index)">
                    <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
                </el-select>
                <span v-else>{{scope.row.unitName}}</span>
            </template>
        </el-table-column>
        
        <el-table-column prop="costUnitPrice" label="成本单价(元)" min-width="100">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.costUnitPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="unitPrice" label="销售单价(元)" min-width="120">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.unitPrice" :precision="2" @change="OnChangeNum" :controls="false" style="width:100px"></el-input-number>
                <span v-else >{{scope.row.unitPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="planNum" label="计划数量" min-width="120">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.planNum" :min="0" :precision="2" @change="OnChangeNum" :controls="false" style="width:100px"></el-input-number>
                <span v-else >{{scope.row.planNum}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="realNum" label="出库数量" min-width="90"></el-table-column>

        <el-table-column prop="totalPrice" label="总价(元)" width="80">
            <template v-slot="scope">
                <span>{{scope.row.totalPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column label="库存" min-width="90">
            <template v-slot="scope">
                <span>{{scope.row.stockNum}}</span>
            </template>
        </el-table-column>

        <el-table-column label="备注" min-width="90">
            <template v-slot="scope">
                <el-input v-if="scope.row.isEdit==1" v-model="scope.row.remark"></el-input>
                <span v-else >{{scope.row.remark}}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="130" fixed="right" v-if="saleInfo.info && (saleInfo.info.state ==1 || saleInfo.info.state == 3)">
            <template v-slot="scope">
                <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
                <el-popconfirm 
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.saleNumber,scope.row.id,scope.$index)">
                    <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
                <el-button size="small" type="warning" @click="OpenBoxHistoryPrice(saleInfo.info.tradeNumber,scope.row.skuNumber)">历史单价</el-button>
            </template>
        </el-table-column>
    </el-table>

    <!-- 选择产品 -->
    <el-dialog :title="BoxSkuInfo.title" 
        v-model="BoxSkuInfo.isVisible"
        width="70%"
        :destroy-on-close="true">
            <SelectStockSkuList :areaNum="saleInfo.info.areaNumber" ref="SelectSkuInfo"></SelectStockSkuList>
        <template #footer>
            <div style="padding-top:35px">
                <el-button type="primary" @click="btnSelectData">选择</el-button>
                <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
            </div>            
        </template>
    </el-dialog>

    <!-- 历史单价列表 -->
    <el-dialog title="历史单价记录"
    v-model="BoxHistoryPrice.isVisible"
    width="40%"
    :destroy-on-close="true">
        <HistoryPrice :PriceInfo="PriceInfo"></HistoryPrice>
        <template #footer>
            <el-button @click="BoxHistoryPrice.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import SelectStockSkuList from '@/components/common/SelectStockSkuList.vue'  //库存产品列表
import HistoryPrice from '@/views/Sales/Sale/HistoryPrice.vue'  //历史单价
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
export default {
  components:{
      SelectStockSkuList,
      HistoryPrice
  },
  data(){
    return{
      //选择产品-弹出框
      BoxSkuInfo:{
        isVisible:false,
        title:'选择库存产品'
      },
      //历史价格-弹出框
      BoxHistoryPrice:{
        isVisible:false
      },
      PriceInfo:{
        tradeNumber:'', //交易者编号
        skuNumber:'',
      },
    }
  },
  computed:{
    ...mapState('Erp',['saleInfo'])
  },
  methods:{

    //选择产品
    BtnSelectSku(){
        this.BoxSkuInfo.isVisible = true
    },

    //保存选中产品
    async btnSelectData(){
        let itemList = this.$refs.SelectSkuInfo.selectedItem
        let warehouseNumber = '';
        let skuNumberList = [];
        if(itemList !=null && itemList.length>0){
            itemList.forEach(item => {
                warehouseNumber= item.warehouseNumber;
                skuNumberList.push(item.skuNumber);
            });
            let param = {
                warehouseNumber:warehouseNumber,
                skuNumberList:skuNumberList,
            }
            await this.$api.erp.GetSaleDetailListByParam(param).then(res=>{
                if(res.data.status === 200){
                    res.data.data.forEach(row => {
                        this.saleInfo.detailList.push(row)
                    });                    
                }
            })
        }
        this.BoxSkuInfo.isVisible = false  //关闭选择产品弹出框        
    },

    //编辑行
    EditRow(id){
        this.saleInfo.detailList = this.saleInfo.detailList.map(item=>{
            if(item.id === id){
                item.isEdit = 1;
            }
            return item
        })
    },

    //删除销售明细
    async DeleteRow(saleNumber, id, index){
        this.saleInfo.detailList.splice(index,1)
        if(id !=0){
            let param = {
                saleNumber:saleNumber,
                id:id
            }
            await this.$api.erp.DeleteSaleDetail(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        }
        //重新计算总价
        this.OnChangeNum();        
    },

    //当明细数量、价格改变时触发
    OnChangeNum(){
        let newtotalPrice = 0;   //主表-总价
        this.saleInfo.detailList = this.saleInfo.detailList.map(item=>{
            item.totalPrice = this.myRound(item.unitPrice * item.planNum,2);
            newtotalPrice += item.totalPrice;
            return item
        })
        this.saleInfo.info.totalPrice = this.myRound(newtotalPrice,2)  //更新主表-总价
    },

    //历史单价-弹出框
    OpenBoxHistoryPrice(tradeNumber,skuNumber){
        this.PriceInfo.tradeNumber = tradeNumber;
        this.PriceInfo.skuNumber = skuNumber;
        this.BoxHistoryPrice.isVisible = true;
    },

    //当单位发生变化时
    ChangeSkuUnit(index){
        this.saleInfo.detailList = this.saleInfo.detailList.map((item,index2)=>{
            if(index === index2){
                //#region 单位发生变化时-重新计算成本价、库存
                let param3 = {
                    warehouseNumber:item.warehouseNumber,
                    skuNumber:item.skuNumber,
                    unitId:item.unitId
                }
                this.$api.wms.GetSkuUnitStockInfo(param3).then(res=>{
                    if(res.data.status === 200){
                        item.stockNum = res.data.data.unitStock;  //库存
                        item.costUnitPrice = res.data.data.unitPrice;  //成本单价
                    }
                })
                //#endregion
            }
            
            return item
        })
        
    },


  }
}
</script>

<style>

</style>