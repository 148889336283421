<template>
  <div>

    <el-form :inline="true"
    v-model="checkInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="checkInfo.info">

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属部门">
                    <el-tree-select v-model="checkInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属大区">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>所属大区</span>
                    </template>
                    <el-select v-model="checkInfo.info.areaNumber" class="search-200">
                        <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="盘点名称">
                    <el-input v-model="checkInfo.info.checkName" class="search-200"></el-input>
                </el-form-item>
            </el-col>
            
            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="盘点方式">
                    <el-select v-model.number="checkInfo.info.checkMode" class="search-200">
                        <el-option :value="1" label="明盘"></el-option>
                        <el-option :value="2" label="盲盘"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="状态">
                  <el-tag v-if="checkInfo.info.state ==1">新建单</el-tag>
                  <el-tag v-if="checkInfo.info.state ==2" type="primary">盘点中</el-tag>
                  <el-tag v-if="checkInfo.info.state ==3" type="success">完成盘点</el-tag>
                  <el-tag v-if="checkInfo.info.state ==4" type="danger">已驳回</el-tag>
                  <el-tag v-if="checkInfo.info.state ==5" type="info">已完成</el-tag>
                  <el-tag v-if="checkInfo.info.state ==10">已关单</el-tag>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import { mapState } from 'vuex'
export default {
    name:'TabCheck',
    mixins:[UserDptList],
    computed:{
        ...mapState('Erp',['checkInfo'])
    },
    data(){
        return{
            userAreaList:[],    //用户关联的大区
        }
    },
    methods:{
        //加载用户关联的大区列表
        SelectUserAreaList(){
            this.$api.system.SelectUserAreaList().then(res=>{
                if(res.data.status === 200){
                    this.userAreaList = res.data.data;
                    //新增时，默认加载第一个大区
                    if(this.userAreaList.length>0 && this.checkInfo.info.checkNumber==''){
                        this.checkInfo.info.areaNumber = this.userAreaList[0].areaNumber;
                        
                    }
                }else{
                    this.userAreaList = [];
                }
            })
        },

    }
}
</script>

<style>

</style>