<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="加工单" name="tabWork">
            <TabWork @OnChangeArea="onChangeArea"></TabWork>
            <div>
                <el-divider>物料清单</el-divider>
                <TabWorkMaterial></TabWorkMaterial>
            </div>
            
            <div class="LineSpace">
                <el-divider>成品清单</el-divider>
                <TabWorkGoods ref="tabWorkGoods"></TabWorkGoods>
            </div>
            
            <div class="LineSpace">
                <el-divider>退料清单</el-divider>
                <TabWorkWaste></TabWorkWaste>
            </div>
        </el-tab-pane>

        <el-tab-pane label="出库记录" name="tabOutRecord">
            <TabOutStock></TabOutStock>
        </el-tab-pane>

        <el-tab-pane label="入库记录" name="tabInRecord">
            <TabInStock></TabInStock>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabWorkRecord></TabWorkRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabWorkState></TabWorkState>
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="workInfo.info">
        <el-button v-if="CheckUserButtonAuth('BtnWorkCancel') && workInfo.info.state == 4" type="danger" @click="btnCancelWork">撤单</el-button>
        
        <el-button type="primary" v-if="CheckUserButtonAuth('BtnWorkSave') && (workInfo.info.state==1 || workInfo.info.state == 3)" @click="btnSaveData">保存</el-button>
        <el-button type="primary" v-if="CheckUserButtonAuth('BtnWorkSave') && (workInfo.info.state==5)" @click="btnSaveWaste">保存退料</el-button>
        <el-button v-if="workInfo.info.workNumber !='' && (workInfo.info.state==1 || workInfo.info.state == 3)" type="primary" @click="btnUpdateState(2)">提交审核</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnWorkCheck') && workInfo.info.state == 2" type="warning" @click="BoxCheckState.isVisible=true">审核</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnWorkSubmitStock') && workInfo.info.state == 5" type="success" @click="btnSubmitGoodsWarehouse">提交入库</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>
    

    <!-- 审核-弹框 -->
    <el-dialog title="审核"
    v-model="BoxCheckState.isVisible"
    width="20%">
        <el-divider></el-divider>
        <div>审核是否通过?</div>
        <template #footer>            
            <el-button type="primary" @click="btnUpdateState(4)">通过</el-button>
            <el-button type="danger" @click="btnUpdateState(3)">驳回</el-button>
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>


  </div>
</template>

<script>
import TabWork from '@/views/Work/WorkBill/TabWork.vue'
import TabWorkMaterial from '@/views/Work/WorkBill/TabWorkMaterial.vue'
import TabWorkWaste from '@/views/Work/WorkBill/TabWorkWaste.vue'
import TabWorkGoods from '@/views/Work/WorkBill/TabWorkGoods.vue'
import TabWorkRecord from '@/views/Work/WorkBill/TabWorkRecord.vue'
import TabWorkState from '@/views/Work/WorkBill/TabWorkState.vue'

import TabOutStock from '@/views/Work/WorkBill/TabOutStock.vue'
import TabInStock from '@/views/Work/WorkBill/TabInStock.vue'

import { mapMutations, mapState } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
    props:['editId','dataList'],
    components:{
        TabWork,
        TabWorkMaterial,
        TabWorkWaste,
        TabWorkGoods,
        TabWorkRecord,
        TabWorkState,
        TabOutStock,
        TabInStock,
    },
    data(){
        return{
            info:{
                activeName:'tabWork',
                workNumber:this.editId,
            },
            //审核-弹出框
            BoxCheckState:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Erp',['workInfo'])
    },
    methods:{
        ...mapMutations('Erp',['SetWorkInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.workNumber!=0){
                let param = {
                    workNumber:this.info.workNumber
                }
                this.$api.erp.SingleWorkAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetWorkInfo(res.data.data)
                    }else{
                        this.btnClearData();
                    }
                    
                })
            }else{
                this.btnClearData();
            }
        },

        //保存数据
        btnSaveData(){
            this.$api.erp.SaveWorkAllInfo(this.workInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //this.btnClose()
                    //更新编号
                    this.workInfo.info.workNumber = res.data.data;
                    this.info.workNumber = res.data.data;
                    this.GetDataInfo();
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //保存退料
        btnSaveWaste(){
            this.$api.erp.SaveWorkWaste(this.workInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.GetDataInfo();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },
        
        //提交审核状态
        btnUpdateState(state){
            let param = {
                workNumber:this.workInfo.info.workNumber,
                state:state
            }
            this.$api.erp.UpdateWorkState(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //更新
                    this.workInfo.info.state = state;
                    this.BoxCheckState.isVisible = false;  //关闭审核框
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //提交入库
        btnSubmitGoodsWarehouse(){
            ElMessageBox.confirm('请确认是否还有未使用完的物料需要退库，请先完成退库，这里提交后将不再支持退库操作！','重要提示',{
                confirmButtonText:'确认提交',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                //提交操作
                let param = {
                    workNumber:this.workInfo.info.workNumber,
                    state:6
                }
                this.$api.erp.UpdateWorkState(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        //更新
                        this.GetDataInfo();
                    }else{
                        ElMessage.error(res.data.msg)
                    }
                })                
            }).catch(()=>{
                //取消操作
            })
        },

        //撤单功能
        btnCancelWork(){
            ElMessageBox.confirm('撤单将加工单变更为【新建单】,确认是否撤单?','撤单警告',{
                confirmButtonText:'确认',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                let param = {
                    workNumber:this.workInfo.info.workNumber
                }
                this.$api.erp.CancelWork(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        this.btnClose();
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }).catch(()=>{

            })
            
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //清空数据
        btnClearData(){
            var newInfo = {
                info:{
                    id:0,
                    systemNumber:'',
                    workNumber:'',
                    departmentId:this.userDepartmentId,
                    areaNumber:'',
                    areaName:'',
                    workGroupNumber:'',
                    workName:'',
                    successRate:0,
                    state:1,
                    startTime:'',
                    endTime:'',
                    timeDiff:'',
                },
                materialList:[],
                goodsList:[],
                goodsRecordList:[],
                outAllInfoList:[],
                inAllInfoList:[],
                recordList:[],
                stateList:[]
            }
            this.SetWorkInfo(newInfo);
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.workNumber === this.info.workNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.workNumber = prevInfo.workNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.workNumber === this.info.workNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.workNumber = prevInfo.workNumber
                this.GetDataInfo()
            }
        },


        //当大区发生改变时-更新仓库列表
        onChangeArea(){
            this.$refs.tabWorkGoods.onGetWhListByAreaNumber(1);  //成品仓库列表
        },

    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style scoped>
.SingleInfo {min-height: 200px;}
.LineSpace{margin-top: 20px;}
</style>