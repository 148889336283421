<template>
  <div>
    <!-- 编辑营销内容页面 -->
    <el-divider></el-divider>

    <!-- 内容 -->
    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row>

            <el-col :span="8">
                <el-form-item label="标题">
                    <el-input v-model="info.title" class="search-200"></el-input>
                </el-form-item>
            </el-col>
            
            <el-col :span="8">
                <el-form-item label="是否首页显示">
                    <el-select v-model="info.isShow" class="search-200">
                        <el-option :value="0" label="否"></el-option>
                        <el-option :value="1" label="是"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="状态">
                    <el-tag v-if="info.state ==1">新创建</el-tag>
                    <el-tag v-if="info.state ==2">已驳回</el-tag>
                    <el-tag v-if="info.state ==3">已审核</el-tag>
                </el-form-item>
            </el-col>
           
           <el-col :span="8">
                <el-form-item label="封面图片">
                    <!-- 上传图片 -->
                    <el-image
                        class="ImgTheme"
                        v-if="info.cover"
                        :src="info.cover"
                        :preview-src-list="[info.cover]"
                        :hide-on-click-modal="true"
                        fit="fill">
                    </el-image>

                    <el-upload
                    :action="GetFileUrl('Market')"
                    :headers="GetHeader"
                    :show-file-list="false"
                    :before-upload="BeforeUpload"
                    :on-success="UploadSuccess">
                        <el-tag class="UploadImg">上传图片</el-tag>
                    </el-upload>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="内容">
                    <div style="border: 1px solid #ccc;">
                        <Toolbar
                            style="border-bottom: 1px solid #ccc"
                            :editor="editor"
                            :defaultConfig="toolbarConfig"
                            :mode="mode"
                        />
                        <Editor
                            style="height: 500px; overflow-y: hidden;"
                            v-model="info.content"
                            :defaultConfig="editorConfig"
                            :mode="mode"
                            @onCreated="onCreated"
                        />
                    </div>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>
    
    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import axios from '@/utils/http' 
import base from '@/api/base'  //接口地址入口
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import Authorize from '@/utils/authorize.js'

import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],
    components:{
        Editor,
        Toolbar
    },
    data(){
        return{
            editor: null,  //实例
            toolbarConfig: {
                excludeKeys:['emotion']
             },    //工具栏配置 去掉表情菜单
            editorConfig: { 
                placeholder: '请输入内容...',
                MENU_CONF:{
                    //自定义上传图片
                    uploadImage:{
                        headers: {
                            Authorization: 'Bearer '+Authorize.getToken(),
                        },
                        maxNumberOfFiles: 5,
                        onError(file,err,res){
                            ElMessage.error(err)
                            console.log(file,err,res);
                        },
                        async customUpload(file,insertFn){
                            const form = new FormData();
                            form.append('file',file);
                            axios({
                                url:`${base.base}/File/SaveImage?module=Market`,
                                method:'post',
                                data:form
                            }).then(res=>{
                                if(res.data.status === 200){
                                    insertFn(res.data.data,'','')
                                }else{
                                    ElMessage.error('上传失败');
                                }
                                
                            })
                        },
                    },
                    //自定义上传视频
                    uploadVideo:{
                        headers: {
                            Authorization: 'Bearer '+Authorize.getToken(),
                        },
                        maxFileSize: 5 * 1024 * 1024, // 5M
                        maxNumberOfFiles: 1,
                        async customUpload(file,insertFn){
                            const form = new FormData();
                            form.append('file',file);
                            axios({
                                url:`${base.base}/File/SaveImage?module=Market`,
                                method:'post',
                                data:form
                            }).then(res=>{
                                if(res.data.status === 200){
                                    insertFn(res.data.data,'','')
                                }else{
                                    ElMessage.error('上传失败');
                                }
                                
                            })
                        },
                    }
                },
                
             },
            mode: 'default', // or 'simple'  功能菜单配置 

            info:{
                id:0,
                systemNumber:'',
                billNumber:'',
                title:'',
                cover:'',   //封面图片
                content:'',   //编辑器主内容
                state:1,
                isShow:0,
                receiver:'',
                receiverName:''
            },
            billNumber:this.editId,   //编号
        }
    },
    computed:{
        //添加Token - 重要
        GetHeader(){
            return {
                "Authorization":"Bearer "+ Authorize.getToken()
            }
        },
    },
    methods:{
        //加载数据信息
        GetDataInfo(){
            if(this.billNumber !=0){
                let param = {
                    billNumber:this.billNumber
                }
                this.$api.market.SingleContentInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存数据
        btnSaveData(){
            this.$api.market.SaveContentInfo(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
            
        },

        //清空实体
        btnClearData(){
            this.info = {
                id:0,
                systemNumber:'',
                billNumber:'',
                title:'',
                content:'',
                state:1,
                isShow:0,
                receiver:'',
                receiverName:''
            }
            console.log(this.info)
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.billNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.billNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //#region 图片上传

        //上传图片之前操作
        BeforeUpload(file){
            const fileType = file.type;
            const fileSize = file.size;
            
            if(fileType !== 'image/jpeg' && fileType !== 'image/png'){
                ElMessage.error('上传图片格式:jpeg|png')
                return false;
            }
            //文件大小
            if(fileSize/1024/1024 > 5){
                ElMessage.error('上传文件大小不能超过5Mb')
                return false;
            }

        },
        //上传完成
        UploadSuccess(res){
            if(res.status === 200){
            this.info.cover = res.data
            }
        },
        //上传失败
        UploadError(e){
            ElMessage.error(e)
        },
        //#endregion

        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
            this.GetDataInfo();
        },

    },
    mounted() {
    },
    beforeUnmount() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
  .ImgTheme{height: 80px;width: 80px;border: 1px solid #ccc;padding: 5px;}
  .UploadImg{margin: 5px;}
</style>