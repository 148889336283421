<template>
  <div>
       <div class="skuBtnList" v-if="purchaseInfo.info && purchaseInfo.info.state <=2">
          <el-button class="iconfont icon-add" type="primary" plain @click="btnSelectSku">选择产品</el-button>
      </div>

      <!-- 列表 -->
      <el-table :data="purchaseInfo.detailList" border show-summary style="width: 100%" ref="myTable">

        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
          <el-table-column prop="skuName" label="产品名称" min-width="100">
              <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column label="规格名称" min-width="100">
              <template v-slot="scope">
                  <span>{{scope.row.skuName}}</span>
              </template>
          </el-table-column>

          <el-table-column label="单位" min-width="90">
                <template v-slot="scope">
                    <el-select v-if="scope.row.isEdit==1" v-model="scope.row.unitId"  @change="ChangeSkuUnit(scope.$index)">
                        <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
                    </el-select>
                    <span v-else>{{scope.row.unitName}}</span>
                </template>
          </el-table-column>

          <el-table-column prop="unitPrice" label="采购价(元)" min-width="120">
              <template v-slot="scope">
                  <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.unitPrice" :precision="2" @change="OnChangeNum" :controls="false" style="width:100px"></el-input-number>
                  <span v-else >{{scope.row.unitPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="planNum" label="计划数量" min-width="120">
              <template v-slot="scope">
                  <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.planNum" :min="0" :precision="2" @change="OnChangeNum" :controls="false" style="width:100px"></el-input-number>
                  <span v-else >{{scope.row.planNum}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="realNum" label="实际数量" min-width="120">
              <template v-slot="scope">
                  <span>{{scope.row.realNum}}</span>
              </template>
          </el-table-column>

          <el-table-column prop="totalPrice" label="总价(元)" width="80">
              <template v-slot="scope">
                  <span>{{scope.row.totalPrice}}</span>
              </template>
          </el-table-column>

          <el-table-column label="参考价">
                <template v-slot="scope">
                    <span>{{scope.row.costUnitPrice}}</span>
                </template>
          </el-table-column>

          <el-table-column label="备注">
              <template v-slot="scope">
                  <el-input v-if="scope.row.isEdit==1" v-model="scope.row.remark"></el-input>
                  <span v-else >{{scope.row.remark}}</span>
              </template>
          </el-table-column>

          <el-table-column label="操作" width="130" fixed="right" v-if="purchaseInfo.info && (purchaseInfo.info.state ==1 || purchaseInfo.info.state ==3)">
            <template v-slot="scope">
                <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
                <el-popconfirm 
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.billNumber,scope.row.id,scope.$index)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
          </el-table-column>
      </el-table>

      <!-- 选择产品 -->
      <el-dialog :title="BoxSkuInfo.title" 
        v-model="BoxSkuInfo.isVisible"
        width="70%"
        :destroy-on-close="true">
            <SelectAllSkuList ref="SelectSkuInfo"></SelectAllSkuList>
        <template #footer>
            <el-button type="primary" @click="btnSelectData">选择</el-button>
            <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
        </template>
      </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SelectAllSkuList from '@/components/common/SelectAllSkuList.vue'
import { ElMessage } from 'element-plus'
export default {
    name:'TabPurchaseDetail',
    components:{
        SelectAllSkuList
    },
    data(){
        return{
            //选择产品-弹出框
            BoxSkuInfo:{
              isVisible:false,
              title:'选择产品'
            },
        }
    },
    computed:{
        ...mapState('Purchase',['purchaseInfo'])
    },
    methods:{
        
        //选择产品
        btnSelectSku(){
            this.BoxSkuInfo.title = "选择产品"
            this.BoxSkuInfo.isVisible = true            
        },
        
        //保存选中产品
        async btnSelectData(){
            let itemList = this.$refs.SelectSkuInfo.selectedItem
            let skuNumberList=[];
            if(itemList !=null && itemList.length>0){
                itemList.forEach(item => {                   
                    skuNumberList.push(item.skuNumber);    
                });
                let param = {
                    warehouseNumber:this.purchaseInfo.info.warehouseNumber,
                    skuNumberList:skuNumberList,
                }
                await this.$api.erp.GetPurchaseDetailListByParam(param).then(res=>{
                    if(res.data.status === 200){
                        res.data.data.forEach(row => {
                            this.purchaseInfo.detailList.push(row);
                        });
                    }
                })
            }
            this.BoxSkuInfo.isVisible = false   //关闭选择产品弹框
        },

        //编辑行
        EditRow(id){
            this.purchaseInfo.detailList = this.purchaseInfo.detailList.map(item=>{
                if(item.id === id){
                    item.isEdit = 1;
                }
                return item
            })
        },
        //删除行
        async DeleteRow(billNumber,id,index){
            this.purchaseInfo.detailList.splice(index,1)
            if(id !=0){
                let param = {
                    billNumber:billNumber,
                    id:id
                }
                await this.$api.erp.DeletePurchaseDetail(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                    }else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }
            //重新计算价格
            this.OnChangeNum();
        },
        //当明细数量、价格改变时触发
        OnChangeNum(){
            let newtotalPrice = 0
            this.purchaseInfo.detailList = this.purchaseInfo.detailList.map(item=>{
                item.totalPrice = this.myRound(item.unitPrice * item.planNum,2)
                newtotalPrice += item.totalPrice
                return item
            })
            this.purchaseInfo.info.totalPrice = this.myRound(newtotalPrice,2)
        },


        //当单位发生变化时
        ChangeSkuUnit(index){
            let whNumber = '';  //仓库编号
            if(this.purchaseInfo.info.warehouseNumber!=''){
                whNumber = this.purchaseInfo.info.warehouseNumber;
            }else{
                ElMessage.error("请选择仓库，不然不能提供采购参考价");
                return false;
            }

            this.purchaseInfo.detailList = this.purchaseInfo.detailList.map((item,index2)=>{
                if(index === index2){
                    //#region 单位发生变化时-重新计算价格、库存
                    let param3 = {
                        warehouseNumber:whNumber,
                        skuNumber:item.skuNumber,
                        unitId:item.unitId
                    }
                    this.$api.wms.GetSkuUnitStockInfo(param3).then(res=>{
                        if(res.data.status === 200){
                            item.costUnitPrice = res.data.data.unitPrice;  //成本单价
                        }
                    })
                    //#endregion
                }                
                return item
            });
        },

    },
}
</script>

<style>

</style>