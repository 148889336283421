<template>
  <div>

    <div>
        <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
              <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
              <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                  <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                  </template>
                </el-popconfirm>
              
            </el-col>

            <el-col :span="9" class="operation_hidden">
              <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
              
            </el-col>
          </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox">
          <el-table-column prop="moduleId" type="selection" width="50" />  
          <el-table-column prop="moduleId" label="编号" />  
          <el-table-column prop="moduleName" label="模块名称"/>
          <el-table-column label="是否显示">
            <template v-slot="scope">
                <el-tag v-if="scope.row.isShow ==0" type="danger">否</el-tag>
                <el-tag v-if="scope.row.isShow ==1">是</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="排序" />
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button size="small" type="primary" @click="btnEditInfo(scope.row.moduleId)">详情</el-button>
              <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.moduleId)">
                  <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                  </template>
                </el-popconfirm>
                
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
              v-model:currentPage="info.page"
              v-model:page-size="info.size"
              :page-sizes="[50, 200, 500]"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="info.total"
              @current-change="btnPagechange"
              @size-change="btnSizechange"
            >
            </el-pagination>
        </el-container>

      </div>

      <!-- 新增、编辑 弹出框 -->
      <el-dialog :title="BoxEditInfo.title" 
        v-model="BoxEditInfo.isVisible"
        width="500"
        :draggable="true"
    :close-on-click-modal="false"
        :destroy-on-close="true"
        @closed="GetDataList">
          <EditModule :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditModule>
      </el-dialog>


  </div>
</template>

<script>
import EditModule from '@/views/system/Module/EditModule.vue'
import { ElMessage } from 'element-plus'
export default {
    components:{
        EditModule
    },
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,   //页数
                size:50,  //单页记录数
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
            },
            BoxEditInfo:{
                isVisible:false,  //新增、编辑 弹框
                title:"系统模块"
            },
        }
    },
    computed:{
      //自适应窗体高度
      GetDataHeight(){
          return window.innerHeight-190
      }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            let param = {
                page:this.info.page,
                size:this.info.size
            }
            this.$api.system.ListSystemModule(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            })
        },

        //选中多选框
        btnCheckbox(selection){
          this.info.ids = selection.map(item=>item.moduleId)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //新增编辑部门信息
        btnEditInfo(id){
          this.info.id = id
          if(id==0){
              this.BoxEditInfo.title = "新增模块"
          }else{
              this.BoxEditInfo.title = "编辑模块"
          }  
          this.BoxEditInfo.isVisible = true;
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(id){
          let param = [id]
          this.$api.system.DeleteSystemModule(param).then(res=>{
            if(res.data.status === 200){
              ElMessage.success(res.data.msg)
              this.GetDataList()
            }
            else{
              ElMessage.error(res.data.msg)
            }
          })
        },
        //批量删除
        btnBatchDeleteInfo(){
          if(this.info.ids.length<1){
            ElMessage({type:'error',message:'请选择要删除的选项'})
            return false
          }

          this.$api.system.DeleteSystemModule(this.info.ids).then(res=>{
            if(res.data.status === 200){
              ElMessage.success(res.data.msg)
              this.GetDataList()
            }
            else{
              ElMessage.error(res.data.msg)
            }
          })
        },


    },
    mounted(){
        this.GetDataList();
    }
}
</script>

<style>

</style>