<template>
  <div>
    <!-- 通知服务 - 短信列表 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="短信类型">
                <el-select v-model="info.search.type">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="验证码类"></el-option>
                    <el-option :value="2" label="通知类"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="手机号码">
                <el-input v-model="info.search.phone" :maxlength="20"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnStockRefresh')" class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>        
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                        
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="55" /> 
        <el-table-column prop="id" label="编号" width="100"/>
        <el-table-column label="短信类型" width="110">
            <template v-slot="scope">
                <el-tag v-if="scope.row.type == 1">验证码类</el-tag>
                <el-tag v-if="scope.row.type == 2" type="warning">通知类</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号码" width="200"/>
        <el-table-column label="内容">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.content"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.content}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="创建日期" min-width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.createTime"
                    placement="top"
                  >
                  <span>{{DateFormat(scope.row.createTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
        <!-- <el-table-column label="操作" width="150" fixed="right">
            <template v-slot="scope">              
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.id)">详情</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.userNumber)">
                    <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column> -->
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[20, 50, 100, 200]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange">
            </el-pagination>
        </el-container>

    </div>

  </div>
</template>

<script>
export default {
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:20,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    type:0,  //短信类型
                    phone:'',  //手机号码
                },
            }
        }
    },
    methods:{
        //获取列表
        GetDataList(){
            let param = {
                type:this.info.search.type,
                phone:this.info.search.phone,
                page:this.info.page,
                size:this.info.size,
            }
            this.$api.service.PageNoticeSmsList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;  //获取数据
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search = {
                type:0,  //类型
                phone:'',  //手机号码
                spuName:'',   //仓库名称
            }
            this.GetDataList();
        },

    },
    mounted(){
        this.GetDataList();
    }
}
</script>

<style>

</style>