// 系统菜单组件

<template>
  <div>
    <div v-if="!isMenuCollapse" class="home_logo">
        <div class="home_title">
            <img src="@/assets/logo.png" alt="">
            <!-- <router-link to="/home"><h3>供应链管理平台</h3></router-link> -->
            <!-- <h3>{{info.systemInfo.systemName}}</h3> -->
            <!-- <h3>透明云 · 星空</h3> -->
            <h3> 透明云</h3>
        </div>        
    </div>
    <div v-if="isMenuCollapse" class="home_hidden_logo">
        <div class="home_title">
            <img src="@/assets/logo.png" alt="">
        </div>        
    </div>

      <el-menu
          active-text-color="#ffd04b"
          background-color="#333744"
          class="el-menu-vertical-demo"
          :collapse="isMenuCollapse"
          text-color="#fff"
          :router="true"
          :unique-opened="true"
          @select="SelectMenu">

          <!-- 加载菜单 -->
          <template v-for="menu1 in userAllMenuList" :key="menu1.menuId">
            <!-- 一级目录 -->
            <template v-if="menu1.children !=null && menu1.children.length>0">
              <el-sub-menu :index="menu1.menuId+''">
                <template #title>
                  <i :class="'iconfont '+ menu1.icon"></i>
                  <span>{{menu1.menuName}}</span>
                </template>
                <!-- 二级 -->
                <template v-for="menu2 in menu1.children" :key="menu2.menuId">
                  <!-- 二级目录 -->
                  <template v-if="menu2.children !=null && menu2.children.length>0">
                      <el-sub-menu :index="menu2.menuId+''">
                        <template #title>
                          <i :class="'iconfont '+ menu2.icon"></i>
                          <span>{{menu2.menuName}}</span>
                        </template>
                        <!-- 三级 -->
                        <template v-for="menu3 in menu2.children" :key="menu3.menuId">
                          <!-- 三级目录 -->
                          <template v-if="menu3.children !=null && menu3.children.length>0">
                              <el-sub-menu :index="menu3.menuId+''">
                                <template #title>
                                  <i :class="'iconfont '+ menu3.icon"></i>
                                  <span>{{menu3.menuName}}</span>
                                </template>
                                <!-- 四级 -->
                                <template v-for="menu4 in menu3.children" :key="menu4.menuId">
                                  <!-- 四级目录-不写了，没完没了 -->
                                  <!-- 四级菜单 -->
                                  <el-menu-item :index="menu4.menuId+''" :route="{name:`${menu4.jumpName}`, title:`${menu4.menuName}`}">{{menu4.menuName}}</el-menu-item>
                                </template>
                              </el-sub-menu>
                          </template>
                          <!-- 三级菜单 -->
                          <template v-else>
                            <el-menu-item :index="menu3.menuId+''" :route="{name:`${menu3.jumpName}`, title:`${menu3.menuName}`}">{{menu3.menuName}}</el-menu-item>
                          </template>
                        </template>
                      </el-sub-menu>
                  </template>
                  <!-- 二级菜单 -->
                  <template v-else>
                    <el-menu-item :index="menu2.menuId+''" :route="{name:`${menu2.jumpName}`, title:`${menu2.menuName}`}">{{menu2.menuName}}</el-menu-item>
                  </template>
                </template>
              </el-sub-menu>
            </template>
            <!-- 一级菜单 -->
            <template v-else>
              <el-menu-item :index="menu1.menuId+''" :route="{name:`${menu1.jumpName}`, title:`${menu1.menuName}`}">
                <i :class="'iconfont '+ menu1.icon"></i> <!--这里特殊需要加图标-->
                <span>{{menu1.menuName}}</span>
                </el-menu-item>
            </template>
          </template>

        </el-menu>
  </div>
</template>


<script>
import {mapState,mapActions,mapMutations} from 'vuex'
export default {
    name:'SystemMenu',
    data(){
      return{
        info:{
          systemInfo:{
            systemName:'',
          },  //系统信息
        }
      }
    },  //这里已经不用了，标题固定了
    computed:{
        ...mapState('Master',['isMenuCollapse','userAllMenuList'])
    },
    methods:{
        ...mapActions('Master',['work_AddTag']),
        ...mapMutations('Master',['UpdateIsMenuCollapse','SetUserAllButtonList','SetSystemInfo']),
        //新增Tag
        SelectMenu(item,a,info){
          let newTag = {
            name:info.route.title,
            isClose:true,
            routeName:info.route.name,
            effect:'dark'
          }
          this.work_AddTag(newTag)
        },
        //获取当前系统信息
        GetCurrentSystemInfo(){
          this.$api.system.SingleCurrentSystemInfo().then(res=>{
            if(res.data.status === 200){
              this.info.systemInfo = res.data.data; //
              this.SetSystemInfo(res.data.data);  //设置系统信息
            }else{
              console.log(res)
            }
          })
        },
        //获取用户所有菜单 (不用了)
        // GetUserAllMenuList(){
        //   this.$api.system.ListUserAllMenu().then(res=>{
        //     if(res.data.status === 200){
        //       this.info.MenuList = res.data.data
        //     }else{
        //       ElMessage.error(res.data.msg)
        //     }
        //   }).catch(e=>{
        //     console.log(e)
        //   })
        // },
        
        //获取用户权限内所有按钮
        GetUserAllButtonList(){
          this.$api.system.ListUserAllButton().then(res=>{
            if(res.data.status === 200){
              this.SetUserAllButtonList(res.data.data);
            }
          })
        },
    },
    mounted(){
      this.GetCurrentSystemInfo();  //获取当前系统信息
      //this.GetUserAllMenuList();  //获取用户权限内的菜单列表
      this.GetUserAllButtonList();  //获取用户权限内的按钮列表
    }
}
</script>

<style scoped>
    .el-menu{border-right: 0;}
    .el-menu-item{padding-left: 25px;}  /**后来改的，不知道会影响到哪里 */
    .home_logo{
        width: 180px;
        text-align:center;
        display: flex;
    }
    .home_hidden_logo{
        width: 60px;
        text-align:center;
        display: flex;
    }

   .home_title{
      height: 60px;
      margin: auto;
      display: flex;
      align-items:center;
      color: #fff;
    }
   .home_title img{
    height: 50px;    
  }

  .iconfont {padding-right: 5px;font-size: 18px;}
</style>