<template>
  <div>
    <el-divider></el-divider>

    <TabGroup></TabGroup>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary"  @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>


  </div>
</template>

<script>
import TabGroup from '@/views/Common/Group/TabGroup.vue'
import { ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
export default {
    props:['editId','dataList'],
    components:{
        TabGroup,
    },
    data(){
        return{
            info:{
                groupNumber:this.editId,   //组编号
                activeName:'tabInfo',
            }
        }
    },
    computed:{
        ...mapState('Oa',['groupInfo'])
    },
    methods:{
        ...mapMutations('Oa',['SetGroupInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.groupNumber !=0){
                let param = {
                    groupNumber:this.info.groupNumber
                }
                this.$api.common.GetGroupAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetGroupInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存数据 -type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            //获取选中的项
            this.$api.common.SaveGroupAllInfo(this.groupInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //清理数据
        btnClearData(){
            let newInfo = {
                info:{
                    groupNumber:'',
                    systemNumber:'',
                    groupName:'',
                    sort:0,
                },
                userList:[],
            }
            this.SetGroupInfo(newInfo);
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.groupNumber === this.info.groupNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.groupNumber = prevInfo.groupNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.groupNumber === this.info.groupNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.groupNumber = prevInfo.groupNumber
                this.GetDataInfo()
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.GetDataInfo()
    }

}
</script>

<style>

</style>