<template>
  <div>
      <!-- 搜索功能 -->
      <el-row :style="showSearch.search_hidden">
          <el-col :span="24">
              <el-form :inline="true">

                  <el-form-item label="主题名称">
                    <el-input v-model="info.search.themeName"></el-input>
                  </el-form-item>

                  <el-form-item>
                  <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                  <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                  </el-form-item>

              </el-form>
            </el-col>
      </el-row>

      <!-- 主内容区 -->
      <div>
          <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
              
          </el-col>
          </el-row>

          <!-- 列表 -->
          <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox"> 
              <el-table-column prop="id" type="selection" width="50" />  
              <el-table-column prop="themeNumber" label="主题编号" />
              <el-table-column prop="themeName" label="主题名称" />
              <el-table-column prop="totalScore" label="总分" />
              <el-table-column prop="standard" label="标准依据" />
              <el-table-column prop="assessor" label="评审单位" />
              <el-table-column label="证书模板">
                <template v-slot="scope">
                    <el-image
                            style="width: 70px; height: 70px"
                            :src="scope.row.cert"
                            :preview-src-list="[scope.row.cert]"
                            fit="cover"></el-image>
                </template>
              </el-table-column>

              <el-table-column label="创建日期" width="110">
                <template #default="scope">
                <span>{{DateFormat(scope.row.createTime)}}</span>
                </template>
              </el-table-column>

              <el-table-column label="操作" width="200" fixed="right">
              <template v-slot="scope">              
                  <el-button size="small" type="primary" @click="btnEditInfo(scope.row.themeNumber)">详情</el-button>
                  <el-popconfirm 
                  title="确认要删除吗?"
                  @confirm="btnDeleteInfo(scope.row.themeNumber)">
                      <template #reference>
                      <el-button size="small" type="danger">删除</el-button>
                      </template>
                  </el-popconfirm>
              </template>
              </el-table-column>
          </el-table>

          <!-- 分页 -->
          <el-container class="page_one">
              <el-pagination
                  v-model:currentPage="info.page"
                  v-model:page-size="info.size"
                  :page-sizes="[10, 50, 100, 200]"
                  :background="true"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="info.total"
                  @current-change="btnPagechange"
                  @size-change="btnSizechange">
              </el-pagination>
          </el-container>

      </div>

      <!-- 新增、编辑 弹出框 -->
      <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="60%"
      :draggable="true"
    :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditTheme :editId="info.id" @closeBox="btnCloseBox"></EditTheme>
      </el-dialog>
      

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import EditTheme from '@/views/Service/Evaluate/Theme/EditTheme.vue'
export default {
    name:'ListTheme',
    components:{
        EditTheme
    },
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,   //页数
                size:10,  //单页记录数
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{  //搜索条件
                    themeName:'',   //主题名称
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'新增信用评价主题'
            },

        }
    },
    computed:{
        //Table自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
        //加载列表数据
        GetDataList(){
            let param = {
                themeName:this.info.search.themeName,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.service.ListEvaluateTheme(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data
                    //分页
                    this.info.page = res.data.pageNumber
                    this.info.total = res.data.total
                }
            }).catch(e=>{
                console.log(e.message)
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.themeNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增、编辑信息
        btnEditInfo(themeNumber){
            this.info.id = themeNumber
            if(themeNumber == 0){
                this.BoxEditInfo.title = "新增信用评价主题"
            }
            else{
                this.BoxEditInfo.title = "编辑信用评价主题"
            }
            this.BoxEditInfo.isVisible = true;
        },
        
        //删除按钮
        btnDeleteInfo(themeNumber){
            let param = [themeNumber]
            this.$api.service.DeleteEvaluateAllInfo(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage({type:'success',message:res.data.msg})
                    this.GetDataList()
                }
                else{
                    ElMessage({type:'error',message:res.data.msg})
                }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage({type:'error',message:'请选择要删除的选项'})
                return false
            }

            this.$api.service.DeleteEvaluateAllInfo(this.info.ids).then(res=>{
            if(res.data.status === 200){
                ElMessage({type:'success',message:res.data.msg})
                this.GetDataList()
            }
            else{
                ElMessage({type:'error',message:res.data.msg})
            }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search = {
                themeName:'',   //主题名称
            }
            this.GetDataList();
        },
        
        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

    },
    mounted(){
        this.GetDataList()   //加载列表数据
    }
}
</script>

<style>

</style>