<template>
  <div>
    <!-- 账户转账记录 -->
    <el-divider></el-divider>

    <el-form :inline="false"
      v-model="info"
      label-width="90px"
      label-position="right">
          <el-row v-if="info">

            <el-col :span="12">
                <el-form-item label="调账编号">
                    <el-input v-model="info.adjustNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

              <el-col :span="12">
                  <el-form-item label="所属部门">
                      <template #label>
                          <span style="color:red;">*</span>
                          <span>所属部门</span>
                      </template>
                      <el-tree-select v-if="info.state==1" v-model="info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200" />
                      <el-input v-else v-model="info.departmentName" disabled class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                    <el-form-item label="所属班次">
                        <el-select v-if="info.state ==1" v-model="info.shiftNumber" class="search-200">
                            <el-option v-for="shiftInfo in shiftRecordList" :key="shiftInfo.shiftNumber" :label="shiftInfo.shiftName" :value="shiftInfo.shiftNumber"></el-option>
                        </el-select>
                        <el-input v-else v-model="info.shiftName" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="交易者">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>交易者</span>
                        </template>
                        <el-select v-if="info.state==1" v-model="info.tradeNumber" filterable class="search-200" disabled>
                            <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                        </el-select>
                        <el-input v-else v-model="info.tradeName" class="search-200" disabled></el-input>
                    </el-form-item>
                </el-col>

              <el-col :span="12">
                <el-form-item label="调账账户">
                    <el-select v-if="info.state==1" v-model="info.accountNumber" class="search-200">
                        <el-option v-for="accountInfo in retailAccountList" :key="accountInfo.accountNumber" :label="accountInfo.accountName" :value="accountInfo.accountNumber"></el-option>
                    </el-select>
                    <el-input v-else v-model="info.accountName" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

              <el-col :span="12">
                    <el-form-item label="调账金额">
                        <el-input-number v-if="info.state === 1" v-model="info.totalPrice" :precision="2" :controls="false" class="search-200"></el-input-number>
                        <el-input-number v-else v-model="info.totalPrice" :precision="2" :controls="false" class="search-200" disabled></el-input-number>
                    </el-form-item>
                </el-col>

              <el-col :span="12">
                  <el-form-item label="调账说明">
                      <el-input v-if="info.state==1" v-model="info.summary" class="search-200"></el-input>
                      <el-input v-else v-model="info.summary" disabled class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                  <el-form-item label="操作人">
                      <el-input v-model="info.createName" disabled class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="12">
                    <el-form-item label="付款时间">
                        <el-input v-model="info.payTime" disabled class="search-200"></el-input>
                    </el-form-item>
              </el-col>

              <el-col :span="12">
                    <el-form-item label="创建时间">
                        <el-input v-model="info.createTime" disabled class="search-200"></el-input>
                    </el-form-item>
              </el-col>

              <el-col :span="12">
                    <el-form-item label="状态">
                        <el-tag v-if="info.state ==1" type="danger">未支付</el-tag>
                        <el-tag v-if="info.state ==5" type="success">已支付</el-tag>
                        <el-tag v-if="info.state ==9" type="info">已取消</el-tag>
                    </el-form-item>
                </el-col>

          </el-row>
    </el-form>


    <!-- 功能按钮 -->
    <div class="btnGroup">      
      <el-button type="primary" v-if="CheckUserButtonAuth('BtnAdjustSave') && info.state==1" @click="btnSaveInfo()">保存</el-button>
      <el-button type="danger" v-if="CheckUserButtonAuth('BtnAdjustPay') && info.adjustNumber!='' && info.state==1" @click="btnPayRecord">支付</el-button>
      <el-button @click="btnClose">关闭</el-button>

      <el-button-group>
          <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
          <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
      </el-button-group>
    </div>


  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'  //部门列表
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import { mapState } from 'vuex'
export default {
    props:['editId','dataList'],
    mixins:[UserDptList,AllTradeList],
    data(){
        return{
            info:{
                adjustNumber:'',  //调账编号
                departmentId:this.userDepartmentId,  //默认部门编号
                systemNumber:'',
                tradeType:3,  //默认员工
                tradeNumber:this.currentUserNumber, //默认用户编号
                tradeName:'',
                shiftNumber:'',  //班次编号
                accountNumber:'',  //账户编号
                accountName:'',   //账户名称
                state:1,
                totalPrice:0,  //调账金额
                summary:'',  //调账说明
            },
            shiftRecordList:[],   //班次列表 
            retailAccountList:[],  //零售专用账户列表
            selAdjustNumber:this.editId,  //选中调账编号 
        }
    },
    computed:{
        ...mapState('Master',['currentUserNumber'])
    },
    methods:{
        //获取班次列表
        SelectShiftRecordList(){
            this.$api.erp.GetShiftRecordList().then(res=>{
                if(res.data.status === 200){
                    this.shiftRecordList = res.data.data;
                    //新增时，默认加载第一个 
                    if(this.shiftRecordList !=null && this.info.adjustNumber==''){
                        this.info.shiftNumber = this.shiftRecordList[0].shiftNumber;
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //零售专用账户列表
        SelectRetailAccountList(){
            this.$api.finance.SelectRetailAccountList().then(res=>{
                if(res.data.status === 200){
                    this.retailAccountList = res.data.data;
                    //新增时:默认加载第一个
                    if(this.retailAccountList !=null && this.info.accountNumber==''){
                        this.info.accountNumber = this.retailAccountList[0].accountNumber;
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //加载数据
        GetDataInfo(){            
            if(this.selAdjustNumber!=0){
                let param = {
                    adjustNumber:this.selAdjustNumber,
                }
                this.$api.erp.GetRetailAdjustRecord(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }            
        },

        //保存调账记录
        btnSaveInfo(){
            this.$api.erp.SaveRetailAdjustRecord(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //
                    this.info.adjustNumber = res.data.data;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //支付
        btnPayRecord(){
            ElMessageBox.confirm('确认要支付该记录码?','支付提示',{
                confirmButtonText:'确认',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                //打开遮罩层
                const loading = ElLoading.service({
                    lock:true,
                    text:'正在提交,请稍候!',
                    background:'rgba(0, 0, 0, 0.7)',
                })

                let param = {
                    adjustNumber:this.selAdjustNumber,
                    state:5,   //状态：5:已支付
                }
                this.$api.erp.UpdateRetailAdjustRecordState(param).then(res=>{
                    loading.close();
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        //更新状态
                        this.info.state = 5;
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }).catch(()=>{

            })            
        },


        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.adjustNumber === this.selAdjustNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.selAdjustNumber = prevInfo.adjustNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.adjustNumber === this.selAdjustNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.selAdjustNumber = prevInfo.adjustNumber
                this.GetDataInfo()
            }
        },

        //清空实体
        btnClearData(){
            this.info = {
                adjustNumber:'',  //调账编号
                departmentId:this.userDepartmentId,  //默认部门编号
                systemNumber:'',
                tradeType:3,  //默认员工
                tradeNumber:this.currentUserNumber, //默认用户编号
                tradeName:'',
                shiftNumber:'',  //班次编号
                accountNumber:'',  //账户编号
                accountName:'',   //账户名称
                state:1,
                totalPrice:0,  //调账金额
                summary:'',  //调账说明
            }
        },


    },
    mounted(){
        this.SelectRetailAccountList();  //零售专用账户列表
        this.SelectShiftRecordList();  //班次列表
        this.GetDataInfo();
    }
}
</script>

<style>

</style>