<template>
  <div>
      <!-- 搜索功能 -->
      <el-row :style="showSearch.search_hidden">
          <el-col :span="24">
            <el-form :inline="true"
            label-width="70px"
            label-position="right">

                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="allDptList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip  class="search-200">
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="员工姓名">
                  <el-input v-model="info.search.userName" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="手机号码">
                  <el-input v-model="info.search.phone" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="在职状态">
                  <el-select v-model="info.search.workState" class="search-150">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="在职"></el-option>
                    <el-option :value="2" label="离职"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="显示标识">
                  <el-select v-model="info.search.isShow" class="search-150">
                    <el-option :value="-1" label="全部"></el-option>
                    <el-option :value="0" label="隐藏"></el-option>
                    <el-option :value="1" label="显示"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item>
                  <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                  <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
            </el-col>
      </el-row>

      <div>
        <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
              <el-button v-if="CheckUserButtonAuth('BtnUserAdd')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
              <!-- <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
              </el-popconfirm> -->
            </el-col>

            <el-col :span="9" class="operation_hidden">
              <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportUser"></el-button>
                    </el-tooltip>
                </el-button-group>          
              
            </el-col>
          </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
          <el-table-column prop="id" type="selection" width="50" />  
          <el-table-column prop="userName" label="员工姓名" />
          <!-- <el-table-column prop="loginName" label="登录名" /> -->
          <el-table-column prop="phone" label="手机号码" />
          <el-table-column prop="departmentName" label="所属部门" />
          <el-table-column label="性别" width="80" >
            <template #default="scope">
              <el-tag v-if="scope.row.sex==0">女</el-tag>
              <el-tag v-else-if="scope.row.sex==1" type="success">男</el-tag>
              <el-tag v-else-if="scope.row.sex==2" type="info">保密</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="员工类型" width="100" >
            <template #default="scope">
              <el-tag v-if="scope.row.staffType==1">正式员工</el-tag>
              <el-tag v-else-if="scope.row.staffType==2" type="success">临时员工</el-tag>
              <el-tag v-else type="info">未知</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="在职状态">
            <template #default="scope">
              <el-tag v-if="scope.row.workState == 1">在职</el-tag>
              <el-tag v-if="scope.row.workState == 2" type="info">离职</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="显示标识">
            <template #default="scope">
              <el-tag v-if="scope.row.isShow == 1">显示</el-tag>
              <el-tag v-if="scope.row.isShow == 0" type="info">隐藏</el-tag>
            </template>
          </el-table-column>
          
          <el-table-column prop="zhiWei" label="职位" />
          <!-- <el-table-column prop="isOwner" label="系统标识">
              <template #default="scope">
                  <el-tag v-if="scope.row.isOwner==1">管理员</el-tag>
                  <el-tag v-if="scope.row.isOwner==0" type="info">用户</el-tag>
              </template>
          </el-table-column> -->

          <el-table-column label="操作" width="150" fixed="right">
            <template v-slot="scope">              
              <el-button size="small" type="primary" @click="btnEditInfo(scope.row.userNumber)">详情</el-button>
              <el-popconfirm v-if="CheckUserButtonAuth('BtnUserDelete')" 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.userNumber)">
                  <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                  </template>
                </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>

      </div>

      <el-affix position="bottom">
          <el-row class="page_two">
              <el-col :span="12">
                  
              </el-col>
              <el-col :span="12">
                  <!-- 分页 -->
                  <el-container class="page_right">
                      <el-pagination
                      v-model:currentPage="info.page"
                      v-model:page-size="info.size"
                      :page-sizes="[50, 200, 500]"
                      :background="true"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="info.total"
                      @current-change="btnPagechange"
                      @size-change="btnSizechange"
                      >
                      </el-pagination>
                  </el-container>
              </el-col>
          </el-row>        
      </el-affix>

        <!-- 新增、编辑 弹出框 -->
        <el-dialog :title="dialog.title" 
        v-model="dialog.dialogVisible"
        width="700px"
        :draggable="true"
        :close-on-click-modal="false"
        :destroy-on-close="true" 
        @closed="GetDataList">
          <EditUser :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditUser>
        </el-dialog>


        <!-- 重置密码 弹出框 -->
        <el-dialog title="重置密码" width="250px" 
        v-model="resetDialog.dialogVisible"
        :draggable="true"
        :close-on-click-modal="false">
          <el-divider></el-divider>
          <el-input v-model="resetDialog.newPwd" type="password" placeholder="新密码" maxlength="30"></el-input>
          <template #footer>
              <el-button type="primary" @click="btnSaveNewPwd">保存</el-button>
              <el-button @click="resetDialog.dialogVisible = false">关闭</el-button>
          </template>
        </el-dialog>

  </div>
</template>


<script>
import { ElMessage } from 'element-plus'
import EditUser from '@/views/system/User/EditUser.vue'
import AllDptList from '@/utils/mixins/AllDptList.js'  //全部部门列表
export default {
    name:'ListUser',
    mixins:[AllDptList],
    data(){
        return {
            info:{
                dataList:[],  //Table数据
                page:1,   //页数
                size:50,  //单页记录数
                total:0,  //总记录数
                id:0,  //单个新增、编辑
                ids:[], //删除
                search:{
                  selectDepartmentList:[],
                  userName:'',
                  phone:'',
                  workState:0,
                  isShow:-1,  //默认：全部
                }
            },
            dialog:{
                dialogVisible:false,  //新增、编辑 弹框
                title:"新增员工"
            },
            //重置密码弹出框
            resetDialog:{
              dialogVisible:false,
              newPwd:''
            }  //重置密码 弹框
        }
    },
    components:{
        EditUser
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
        
        //加载数据列表
        GetDataList(){

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                userName:this.info.search.userName,
                phone:this.info.search.phone,
                workState:this.info.search.workState,
                isShow:this.info.search.isShow,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.system.GetUserList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                  ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
              console.log(e)
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.userNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        
        //重置按钮
        btnResetSearch(){
          this.info.search.selectDepartmentList=[];
          this.info.search.userName='';
          this.info.search.phone='';
          this.info.search.workState =0;
          this.info.search.isShow = -1;
          this.info.page = 1;
          this.GetDataList()
        },

        //导出用户列表
        btnExportUser(){
            console.log(1)
        },

        //新增、编辑 用户信息
        btnEditInfo(id){
          this.info.id = id
          if(id==0){
            this.dialog.title='新增员工';
          }else{
            this.dialog.title='编辑员工'
          }          
          this.dialog.dialogVisible = true;
        },
        
        //删除信息
        btnDeleteInfo(userNumber){
            // let param = {
            //     userNumber:userNumber
            // }
            this.$api.system.DeleteUserInfo(userNumber).then(res=>{
                if(res.data.status===200){
                    ElMessage.success(res.data.msg);
                    this.GetDataList(); // 
                }else{
                    ElMessage.error(res.data.msg)
                }

            })
        },
        //批量删除
        btnBatchDeleteInfo(){
          console.log('1')
        },

        //关闭弹出框
        btnCloseBox(){
          this.dialog.dialogVisible = false;
        },

        //重置密码框
        showResetPwd(number){
          this.info.id = number
          this.resetDialog.dialogVisible =true
        },

        //保存新密码
        btnSaveNewPwd(){
          let param = {
            userNumber:this.info.id,
            newPwd:this.resetDialog.newPwd
          }
          this.$api.system.ResetUserPwd(param).then(res=>{
            if(res.data.status === 200){
              ElMessage.success(res.data.msg)
              this.resetDialog.newPwd=''
              this.resetDialog.dialogVisible =false
            }else{
              ElMessage.error(res.data.msg)
            }
          }).catch(e=>{
            console.log(e)
          })
        }

    },
    mounted(){
        this.GetDataList();  //加载数据列表
    }

}
</script>

<style scoped>

</style>