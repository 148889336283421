<template>
  <div>
    <!-- 选择采购单据 -->
    <el-divider></el-divider>

    <!-- 搜索列表 -->
    <el-row>
        <el-form :inline="true">

            <el-form-item label="采购单号">
                <el-input v-model="info.search.billNumber"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

        </el-form>
    </el-row>

    <!-- 采购单主表 -->
    <el-table :data="billList" border stripe style="width: 100%" @current-change="ClickRowData" highlight-current-row>
        <el-table-column label="采购单号" >
            <template #default="scope">
                {{scope.row.billNumber}}
            </template>
        </el-table-column>
        <el-table-column label="部门" >
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.departmentName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
        </el-table-column>
        
        <el-table-column label="交易者类型" min-width="95">
            <template v-slot="scope">
                <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                <el-tag v-if="scope.row.tradeType ==4" type="info">散客</el-tag>
            </template>
        </el-table-column>

        <el-table-column label="交易者" min-width="120">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.tradeName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.tradeName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="总价(元)" />
        <el-table-column label="状态">
            <template #default="scope">
                <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                <el-tag v-if="scope.row.state ==2">待审核</el-tag>
                <el-tag v-if="scope.row.state ==3" type="danger">已驳回</el-tag>
                <el-tag v-if="scope.row.state ==4" type="success">已审核</el-tag>
                <el-tag v-if="scope.row.state ==5" type="warning">已发货</el-tag>
                <el-tag v-if="scope.row.state ==6">已收货</el-tag>
                <el-tag v-if="scope.row.state ==7" type="success">已入库</el-tag>
                <el-tag v-if="scope.row.state ==9" type="info">已取消</el-tag>
                <el-tag v-if="scope.row.state ==10" type="info">已完成</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="billTime" label="记账时间" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.billTime"
                    placement="top"
                  >
                  <span>{{DateFormat(scope.row.billTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-container class="page_one">
        <el-pagination
        v-model:currentPage="info.page"
        v-model:page-size="info.size"
        :page-sizes="[20, 50, 100, 200]"
        :background="true"
        layout="total, sizes, prev, pager, next, jumper"
        :total="info.total"
        @current-change="btnPagechange"
        @size-change="btnSizechange"
        >
        </el-pagination>
    </el-container>


    <!-- 明细表 -->
    <el-divider class="CenterLine">单据明细列表</el-divider>
    <el-table :data="billDetailList" border stripe style="width: 100%" @select="btnCheckbox" @select-all="btnAllCheckbox" ref="billDetailList">
        <el-table-column prop="id" type="selection"></el-table-column>
        <el-table-column prop="warehouseName" label="仓库" />
        <el-table-column prop="cidName" label="产品类目"></el-table-column>
        <el-table-column prop="brandName" label="品牌"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="90">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" />
        <el-table-column label="单位">
            <template v-slot="scope">
                <span>{{scope.row.unitName}}</span>
            </template>
        </el-table-column>
        
        <el-table-column prop="unitPrice" label="单价(元)" min-width="120">
            <template v-slot="scope">
                <span>{{scope.row.unitPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="realNum" label="采购数量" min-width="120">
            <template v-slot="scope">
                <span>{{scope.row.realNum}}</span>
            </template>
        </el-table-column>

        <el-table-column prop="totalPrice" label="总价(元)" width="80">
            <template v-slot="scope">
                <span>{{scope.row.totalPrice}}</span>
            </template>
        </el-table-column>
        
    </el-table>

  </div>
</template>

<script>
export default {
    data(){
        return{            
            info:{
                page:1,   //页数
                size:10,  //单页记录数
                total:0,  //总记录数
                search:{
                    billNumber:'',   //采购单号
                    selectDepartmentList:[],
                    tradeType:0,
                    tradeNumber:'',  //交易者编号
                    isTransport:-1,
                    billFlag:-1,
                    state:10,   //状态 10:已完成
                    datePoint:'',
                }
            },
            billList:[],  //单据列表
            billDetailList:[],   //单据明细列表
            
            selectbillNumber:'',  //选中销售单号
            selectedInfo:{},  //选中的单据(主表)
            selectedDetail:[],   //选中的明细列表
        }
    },
    methods:{

        //加载数据列表
        GetDataList(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                billNumber:this.info.search.billNumber,
                departmentList:newDepartmentList,
                tradeType:this.info.search.tradeType,
                tradeNumber:this.info.search.tradeNumber,
                isTransport:this.info.search.isTransport,
                billFlag:this.info.search.billFlag,
                state:this.info.search.state,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.erp.ListPurchase(param).then(res=>{
                if(res.data.status === 200){
                    this.billList = res.data.data;  //获取数据
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //加载明细列表
        GetBillDetailList(){
            let param = {
                billNumber:this.selectbillNumber
            }
            this.$api.erp.GetPurchaseDetailList(param).then(res=>{
                if(res.data.status === 200){
                    this.billDetailList = res.data.data;
                    if(this.billDetailList !=null){
                        //设置选择项
                        this.$refs.billDetailList.toggleAllSelection();  //默认全选
                    }
                }else{
                    this.billDetailList = [];
                }
            })
        },

        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.billNumber='';
            this.info.search.selectDepartmentList = [];
            this.info.search.tradeType = 0;
            this.info.search.tradeNumber='';
            this.info.search.isTransport=-1;
            this.info.search.billFlag=-1;
            this.info.search.state=10;   //这里只加载10：已完成的单据
            this.info.search.datePoint = '',
            this.info.page = 1,
            this.GetDataList()
        }, 

        //单击-选择主单据
        ClickRowData(row){
            if(row !=null){
                this.selectbillNumber = row.billNumber
                this.selectedInfo = row;  //选中的主表
                this.GetBillDetailList();  //加载明细列表
            }            
        },

        //明细表-选中多选框
        btnCheckbox(row){
            this.selectedDetail = row
        },
        //明细表-全选
        btnAllCheckbox(){
            this.selectedDetail = this.billDetailList;
        },

    },
    mounted(){
        this.GetDataList();  //加载销售列表
    }
}
</script>

<style scoped>
.CenterLine{margin-top: 55px;}
</style>