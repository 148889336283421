<template>
  <div>
    <!-- 按钮 -->
    <div class="skuBtnList" v-if="workInfo.info">
      <el-button class="iconfont icon-add" type="danger" plain v-if="workInfo.info.state == 1 || workInfo.info.state == 3" @click="btnSelectInSku">选择成品</el-button>
    </div>

    <!-- 加工列表 -->
    <el-table :data="workInfo.goodsList" border stripe show-summary style="width: 100%">
      <el-table-column prop="warehouseName" label="仓库">
        <template v-slot="scope">
          <el-select v-if="scope.row.isEdit==1" v-model="scope.row.warehouseNumber">
            <el-option v-for="warehouse in areaWhList" :key="warehouse.warehouseNumber" :label="warehouse.warehouseName" :value="warehouse.warehouseNumber"></el-option>
          </el-select>
          <span v-else >{{scope.row.warehouseName}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="cidName" label="产品类目"></el-table-column>
      <el-table-column prop="brandName" label="品牌"></el-table-column>
      <el-table-column prop="spuName" label="产品名称" />
      <el-table-column prop="skuName" label="规格" />
      <el-table-column label="单位" width="100">
          <template v-slot="scope">
              <el-select v-if="scope.row.isEdit==1" v-model="scope.row.unitId">
                  <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
              </el-select>
              <span v-else>{{scope.row.unitName}}</span>
          </template>
      </el-table-column>

      <el-table-column prop="unitPrice" label="单价" min-width="115">
        <template v-slot="scope">
            <span>{{this.myRound(scope.row.unitPrice,2)}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="planNum" label="计划生产数量" min-width="115">
        <template v-slot="scope">
            <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.planNum" :min="0" :precision="2" size="small"></el-input-number>
            <span v-else >{{scope.row.planNum}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="realNum" label="实际入库数量" min-width="115">
        <template v-slot="scope">
            <span>{{scope.row.realNum}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="totalPrice" label="实际总价(元)" width="110">
          <template v-slot="scope">
              <span>{{this.myRound(scope.row.totalPrice,2)}}</span>
          </template>
      </el-table-column>
     

      <el-table-column label="操作" width="130" fixed="right">
        <template v-slot="scope" >
            <el-button v-if="scope.row.isEdit == 0 && (workInfo.info.state ==1 || workInfo.info.state == 3)" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
            <el-popconfirm v-if="workInfo.info.state ==1 || workInfo.info.state ==3"
            title="确认要删除吗?"
            @confirm="DeleteRow(scope.row.workNumber,scope.row.id,scope.$index)">
                <template #reference>
                <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
        </template>
      </el-table-column>

    </el-table>

    <!-- 选择入库产品 -->
    <el-dialog title="选择入库产品" 
        v-model="BoxInSku.isVisible"
        width="65%"
        :destroy-on-close="true">
          <SelectAllSkuList ref="SelectInSku"></SelectAllSkuList>
        <template #footer>
            <el-button type="primary" @click="btnSelectInData">选择</el-button>
            <el-button @click="BoxInSku.isVisible = false">取消</el-button>
        </template>
    </el-dialog>


  </div>
</template>

<script>
import SelectAllSkuList from '@/components/common/SelectAllSkuList.vue'
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
  components:{
    SelectAllSkuList
  },
  data(){
    return{
      areaWhList:[],   //大区关联的仓库列表
      //选择入库产品-弹出框
      BoxInSku:{
        isVisible:false,
      },
    }
  },
  computed:{
    ...mapState('Erp',['workInfo'])
  },
  methods:{
    //选择入库产品
    btnSelectInSku(){
      this.BoxInSku.isVisible = true
    },
    //保存选中入库产品
    btnSelectInData(){
      let itemList = this.$refs.SelectInSku.selectedItem
      
      if(itemList !=null && itemList.length>0){
        itemList.forEach(item=>{
          let newRow = {
            id:0,
            systemNumber:'',
            workNumber:'',
            warehouseNumber:'',  //仓库编号
            warehouseName:'',  //仓库名称
            cid:item.cid,
            cidName:item.cidName,
            brandId:item.brandId,
            brandName:item.brandName,
            spuNumber:item.spuNumber,
            spuName:item.spuName,
            skuNumber:item.skuNumber,
            skuName:item.skuName,
            unitId:item.defUnitId,   //默认单位编号
            unitName:item.defUnitName,  //默认单位名称
            unitPrice:0,
            planNum:1,  //计划数量
            realNum:0,   //实际数量
            maxNum:0,  //最大数量(入库不管)
            totalPrice:0,   //实际总价
            workType:2,  //加工类型
            isDel:0,
            isEdit:1,    //编辑撞他
            skuUnitList:item.skuUnitList,   //单位列表
            ckPrice:0,   //参考单价(入库不管)
          }
         
          this.workInfo.goodsList.push(newRow);
        });
      }

      //加载仓库列表 参数：2表示保留已选择了仓库的记录的值。
      this.onGetWhListByAreaNumber(2); 
      this.BoxInSku.isVisible = false;  //关闭选择入库产品弹窗
    },

    //编辑行
    EditRow(id){
      this.workInfo.goodsList = this.workInfo.goodsList.map(item=>{
        if(item.id === id){
          item.isEdit = 1;
          //#region 加载单位列表
          let param = {
              skuNumber:item.skuNumber
          }
          this.$api.goods.GetSkuUnitList(param).then(res=>{
              if(res.data.status === 200){
                  item.skuUnitList = res.data.data;
              }
          }) 
          //#endregion
        }
        return item;
      })

      this.onGetWhListByAreaNumber(2); 
    },

    //删除行
    DeleteRow(workNumber, id, index){
        this.workInfo.goodsList.splice(index,1)
        if(id !=0){
            let param = {
                workNumber:workNumber,
                id:id
            }
            this.$api.erp.DeleteWorkGoodsById(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        }
    },

    //加载指定大区下的仓库列表 type: 1:清空仓库选项 2:保留仓库选项
    onGetWhListByAreaNumber(type){ 
        if(this.workInfo.info.areaNumber!=''){
            let param = {
                areaNumber:this.workInfo.info.areaNumber,
            }
            this.$api.wms.GetWhListByAreaNumber(param).then(res=>{
                if(res.data.status === 200){
                    this.areaWhList = res.data.data;
                }else{
                    this.areaWhList = [];
                }
            })
        }else{
            this.areaWhList = [];  
        }  

        //清空仓库选项
        if(type==1){
            this.workInfo.goodsList = this.workInfo.goodsList.map(item=>{
                item.warehouseNumber = '';
                return item
            })
        }
    },


  },

}
</script>

<style>

</style>