<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="退供单" name="tabInfo">
            <TabReturnPurchase ref="tabReturnPurchase"></TabReturnPurchase>
            <!-- 明细 -->
            <el-divider>退供单明细</el-divider>
            <TabReturnPurchaseDetail></TabReturnPurchaseDetail>
        </el-tab-pane>

        <el-tab-pane label="出库记录" name="tabOutRecord">
            <TabOutStock></TabOutStock>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabReturnPurchaseRecord></TabReturnPurchaseRecord>
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <TabReturnPurchaseState></TabReturnPurchaseState>
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="returnPurchaseInfo.info">
        <el-button type="warning" v-if="returnPurchaseInfo.info.state == 1" @click="btnOpenBoxBill()">选择采购单</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnCancelReturnPurchase') && returnPurchaseInfo.info.state == 4" type="danger" @click="CancelReturnPurchase">撤单</el-button>
        <el-button type="primary" v-if="returnPurchaseInfo.info.state==1 || returnPurchaseInfo.info.state==3" @click="btnSaveData">保存</el-button>
        <el-button v-if="returnPurchaseInfo.info.billNumber !='' && (returnPurchaseInfo.info.state==1 || returnPurchaseInfo.info.state == 3)" type="primary" @click="btnUpdateState(2)">提交审核</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnReturnPurchaseAudit') && returnPurchaseInfo.info.state == 2" type="warning" @click="BoxCheckState.isVisible = true">审核</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnReturnPurchaseConfirm') && returnPurchaseInfo.info.state == 5 " type="primary" @click="btnUpdateState(10)">确认完成</el-button>
        <el-button class="iconfont icon-dayin" v-if="returnPurchaseInfo.info.state==10" @click="btnShowBoxPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 审核-弹框 -->
    <el-dialog title="退供单审核"
    v-model="BoxCheckState.isVisible"
    width="350px">
        <el-divider></el-divider>
        <div>退供单是否审核通过?</div>
        <template #footer>            
            <el-button type="primary" @click="btnUpdateState(4)">通过</el-button>
            <el-button type="danger" @click="btnUpdateState(3)">驳回</el-button>
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

    <!-- 选择单据 - 弹出框 -->
    <el-dialog title="选择采购单据"
    v-model="BoxBill.isVisible"
    width="90%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
        <BoxOriginPurchase ref="OriginInfo"></BoxOriginPurchase>
        <template #footer>
            <el-button type="primary" @click="btnSelectOrigin">选择</el-button>
            <el-button @click="btnCloseBoxBill">关闭</el-button>
        </template>
    </el-dialog>

    <!-- 打印框 -->
    <el-dialog title="打印预览"
    v-model="BoxPrint.isVisible"
    :draggable="true"
    :close-on-click-modal="false"
    width="80%">
        <PrintView v-if="BoxPrint.isVisible" @closeBox="btnClosePrint"></PrintView>
    </el-dialog>

  </div>
</template>

<script>
import TabReturnPurchase from '@/views/Pms/ReturnPurchase/TabReturnPurchase.vue'
import TabReturnPurchaseDetail from '@/views/Pms/ReturnPurchase/TabReturnPurchaseDetail.vue'
import TabReturnPurchaseRecord from '@/views/Pms/ReturnPurchase/TabReturnPurchaseRecord.vue'
import TabReturnPurchaseState from '@/views/Pms/ReturnPurchase/TabReturnPurchaseState.vue'
import TabOutStock from '@/views/Pms/ReturnPurchase/TabOutStock.vue'
import BoxOriginPurchase from '@/views/Pms/ReturnPurchase/BoxOriginPurchase.vue'
import PrintView from '@/views/Pms/ReturnPurchase/PrintView.vue'  //打印页面

import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'

export default {
    props:['editId','dataList'],
    components:{
        TabReturnPurchase,
        TabReturnPurchaseDetail,
        TabOutStock,
        TabReturnPurchaseRecord,
        TabReturnPurchaseState,
        BoxOriginPurchase,
        PrintView,
    },
    data(){
        return{
            info:{
                activeName:'tabInfo',
                billNumber:this.editId
            },
            //审核-弹出框
            BoxCheckState:{
                isVisible:false,
            },
            //选择单据-弹出框
            BoxBill:{
                isVisible:false,
            },
            //打印框
            BoxPrint:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Erp',['returnPurchaseInfo'])
    },
    methods:{
        ...mapMutations('Erp',['SetReturnPurchaseInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.billNumber!=0){
                let param = {
                    billNumber:this.info.billNumber
                }
                this.$api.erp.SingleReturnPurchaseAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetReturnPurchaseInfo(res.data.data)
                    }else{
                        this.btnClearData();
                    }
                    //加载
                    this.$refs.tabReturnPurchase.SelectShiftRecordList(); //班次列表
                    this.$refs.tabReturnPurchase.SelectUserAreaList();  //大区列表
                })
            }
            else
            {
                this.btnClearData();
                //加载
                this.$refs.tabReturnPurchase.SelectShiftRecordList(); //班次列表
                this.$refs.tabReturnPurchase.SelectUserAreaList();  //大区列表
            }
        },

        //保存数据
        btnSaveData(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            this.$api.erp.SaveReturnPurchaseAllInfo(this.returnPurchaseInfo).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //刷新页面
                    this.returnPurchaseInfo.info.billNumber = res.data.data;
                    this.info.billNumber = res.data.data;
                    this.GetDataInfo();
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //提交审核状态
        btnUpdateState(state){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            let param = {
                billNumber:this.returnPurchaseInfo.info.billNumber,
                state:state
            }
            this.$api.erp.UpdateReturnPurchaseByState(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //更新
                    this.returnPurchaseInfo.info.state = state;
                    this.BoxCheckState.isVisible = false
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //撤单
        CancelReturnPurchase(){
            ElMessageBox.confirm('撤单将退供单状态变更为【新建单】。确认是否撤单？','撤单警告',{
                confirmButtonText:'确认',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                //确认
                let param = {
                    billNumber:this.info.billNumber
                }
                this.$api.erp.CancelReturnPurchase(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        this.btnClose();
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }).catch(()=>{
                
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //清空数据实体
        btnClearData(){
            var newInfo = {
                info:{
                    id:0,
                    systemNumber:'',
                    billNumber:'',
                    departmentId:this.userDepartmentId,
                    shiftNumber:'',
                    tradeType:0,
                    tradeNumber:'',
                    billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    totalPrice:0,
                    isTransport:0,
                    originNumber:'',  //原始单号
                    state:1,
                    remark:'',
                },
                detailList:[],
                recordList:[],
                stateList:[]
            }
            this.SetReturnPurchaseInfo(newInfo);
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.info.billNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.billNumber === this.info.billNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.billNumber = prevInfo.billNumber
                this.GetDataInfo()
            }
        },

        //打开选择单据-弹框
        btnOpenBoxBill(){
            this.BoxBill.isVisible = true;
        },
        //关闭选择单据-弹框
        btnCloseBoxBill(){
            this.BoxBill.isVisible = false;
        },
        //选择采购单据
        btnSelectOrigin(){
            let originInfo = this.$refs.OriginInfo.selectedInfo;
            let originDetailList = this.$refs.OriginInfo.selectedDetail;  //列表

            let totalPrice = 0;  //总价
            originDetailList.forEach(item => {
                item.id = 0;
                item.isEdit =1;
                item.planNum =item.realNum;
                item.maxNum = item.realNum;  //最大数量
                item.realNum = 0;
                item.totalPrice = this.myRound(item.unitPrice * item.planNum,2); 
                totalPrice = totalPrice + item.totalPrice;               
            });

            let newInfo = {
                info:{
                    systemNumber:'',
                    billNumber:'',
                    departmentId:originInfo.departmentId,
                    departmentName:originInfo.departmentName,
                    shiftNumber:originInfo.shiftNumber,
                    shiftName:originInfo.shiftName,
                    areaNumber:originInfo.areaNumber,
                    areaName:originInfo.areaName,    //大区名称
                    originNumber:originInfo.billNumber,  //原始单据编号
                    state:1,
                    totalPrice:totalPrice,   //总价
                    tradeType:originInfo.tradeType,
                    tradeNumber:originInfo.tradeNumber,
                    tradeName:originInfo.tradeName,
                    billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    isDel:0,
                },
                detailList:originDetailList,
                recordList:[],
                stateList:[]
            }
            this.SetReturnPurchaseInfo(newInfo);
            this.btnCloseBoxBill();
        },

        //打开-打印框
        btnShowBoxPrint(){
            this.BoxPrint.isVisible =true;
        },
        //关闭-打印框
        btnClosePrint(){
            this.BoxPrint.isVisible =false;        
        },
        
    },
    mounted(){
        this.GetDataInfo()
    }

}
</script>

<style scoped>
.SingleInfo {min-height: 200px;}
</style>