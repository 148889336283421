/**
 *   获取全部仓库列表
 * === 下拉列表专用 ===
 */

const AllWhList = {
    data(){
        return{
            allWhList:[],   //全部仓库列表
        }
    },
    methods:{
        LoadAllWhList(){
            this.$api.wms.SelectCacheWarehouseList().then(res=>{
                if(res.data.status === 200){
                    this.allWhList = res.data.data;
                }else{
                    this.allWhList = [];
                }
            })
        }
    },
    mounted(){
        this.LoadAllWhList();
    },
}

export default AllWhList;
