<template>
  <div>
    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="标签名称">
                <el-input v-model="info.search.tagName"></el-input>
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
              <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>       
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>         
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight"  style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="tagId" type="selection" width="50" />  
        <el-table-column prop="tagId" label="编号" width="170" />
        <el-table-column prop="tagName" label="标签名称" />
        <el-table-column prop="remark" label="备注" />
        <el-table-column prop="sort" label="排序" />
        <el-table-column label="创建时间" width="110">
            <template #default="scope">
            <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="140" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.tagId)">详情</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.tagId)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[50, 100, 200]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
            >
            </el-pagination>
        </el-container>

    </div>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="450"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditTag :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditTag>
    </el-dialog>

  </div>
</template>

<script>
import EditTag from '@/views/Member/Tag/EditTag.vue'
import { ElMessage } from 'element-plus'
export default {
  components:{
    EditTag,
  },
  data(){
    return{
      info:{
          dataList:[],  //Table数据
          page:1,
          size:50,
          total:0,  //总记录数
          id:0,  //新增、编辑 的参数
          ids:[],   //删除 的参数
          search:{
              tagName:'',
          }
      },
      //编辑-弹出框
      BoxEditInfo:{
          isVisible:false,
          title:'会员标签'
      },
    }
  },
  computed:{
      //自适应窗体高度
      GetDataHeight(){
          return window.innerHeight-240
      }
  },
  methods:{
    //加载数据列表
    GetDataList(){
      let param = {
        tagName:this.info.search.tagName,
        page:this.info.page,
        size:this.info.size
      }
      this.$api.member.ListMemberTag(param).then(res=>{
        if(res.data.status === 200){
          this.info.dataList = res.data.data;
          //分页
          this.info.total = res.data.total
          this.info.page = res.data.pageNumber
        }
      }).catch(e=>{
        console.log(e);
      })
    },

    //选中多选框
    btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.tagId)
    },
    //分页-页数变化时
    btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
    },
    //分页-单页记录数变化时
    btnSizechange(size){
        this.info.size = size
        this.info.page = 1
        this.GetDataList() //加载Table数据
    },

    //新增编辑
    btnEditInfo(id){
        this.info.id = id
        if(id==0){
            this.BoxEditInfo.title = "新增会员标签"
        }else{
            this.BoxEditInfo.title = "编辑会员标签"
        }  
        this.BoxEditInfo.isVisible = true;
    },
    //重置按钮
    btnResetSearch(){
        this.info.search.tagName='',
        this.GetDataList()
    },

    //关闭弹出框
    btnCloseBox(){
        this.BoxEditInfo.isVisible = false;
    },

    //删除按钮
    btnDeleteInfo(tagId){
        let param = [tagId]
        this.$api.member.DeleteMemberTag(param).then(res=>{
        if(res.data.status === 200){
            ElMessage.success(res.data.msg)
            this.GetDataList()
        }
        else{
            ElMessage.error(res.data.msg)
        }
        })
    },
    //批量删除按钮
    btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要删除的选项')
            return false
        }

        this.$api.member.DeleteMemberTag(this.info.ids).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
        })
    },

  },
  mounted(){
    this.GetDataList();   //加载数据
  }

}
</script>

<style>

</style>