
import Vuex from 'vuex'
// 引入模块
import Master from '@/store/modules/master.js'
//系统相关的
import System from '@/store/modules/system.js'
//采购相关的
import Purchase from '@/store/modules/purchase.js'
//ERP模块相关的
import Erp from '@/store/modules/erp.js'
//OA模块相关的
import Oa from '@/store/modules/oa.js'
//服务模块相关
import Service from '@/store/modules/service.js'
//电商模块相关
import Shop from '@/store/modules/shop.js'
//财务模块相关
import Finance from '@/store/modules/finance.js'
//设置模块相关
import SetUp from '@/store/modules/setup.js'


export default new Vuex.Store({
    modules:{
        Master,
        System,
        Purchase,
        Erp,        
        Oa,
        Service,
        Shop,
        Finance,
        SetUp,
    }
})