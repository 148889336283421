<template>
  <div>
    <div class="skuBtnList" v-if="planInfo.info && planInfo.info.state <=2">
        <el-button class="iconfont icon-add" type="primary" plain @click="btnSelectSku">选择产品</el-button>
    </div>

    <!-- 列表 -->
    <el-table :data="planInfo.detailList" border style="width:100%">
        <el-table-column prop="cidName" label="产品类目"></el-table-column>
        <el-table-column prop="brandName" label="品牌"></el-table-column>
          <el-table-column prop="skuName" label="产品名称">
              <template v-slot="scope">
                  <span>{{scope.row.spuName}}</span>
              </template>
          </el-table-column>

          <el-table-column label="规格">
              <template v-slot="scope">
                  <span>{{scope.row.skuName}}</span>
              </template>
          </el-table-column>

          <el-table-column label="单位">
                <template v-slot="scope">
                    <el-select v-if="scope.row.isEdit==1" v-model="scope.row.unitId"  min-width="90" >
                        <el-option v-for="skuUnit in scope.row.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
                    </el-select>
                    <span v-else>{{scope.row.unitName}}</span>
                </template>
          </el-table-column>

          <el-table-column label="计划数量">
              <template v-slot="scope">
                  <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.itemNum" :min="0" :precision="2" min-width="120"></el-input-number>
                  <span v-else >{{scope.row.itemNum}}</span>
              </template>
          </el-table-column>

          <el-table-column label="操作" width="150" fixed="right" v-if="planInfo.info && planInfo.info.state <=2">
            <template v-slot="scope">
                <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
            </template>
          </el-table-column>
          
    </el-table>

    <!-- 选择产品 -->
    <el-dialog :title="BoxSkuInfo.title" 
        v-model="BoxSkuInfo.isVisible"
        width="65%"
        :destroy-on-close="true">
        <SelectAllSkuList ref="SelectSkuInfo"></SelectAllSkuList>
        <template #footer>
            <el-button type="primary" @click="btnSelectData">选择</el-button>
            <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import SelectAllSkuList from '@/components/common/SelectAllSkuList.vue'
export default {
    components:{
        SelectAllSkuList
    },
    data(){
        return{
            //选择产品-弹出框
            BoxSkuInfo:{
              isVisible:false,
              title:'选择产品'
            },
        }
    },
    computed:{
        ...mapState('Purchase',['planInfo'])
    },
    methods:{
        //选择产品
        btnSelectSku(){
            this.BoxSkuInfo.title = "选择产品"
            this.BoxSkuInfo.isVisible = true            
        },

        //保存选中产品
        btnSelectData(){
            let itemList = this.$refs.SelectSkuInfo.selectedItem
            if(itemList !=null && itemList.length>0){
                itemList.forEach(item => {
                    let newRow = {
                        id:0,
                        cid:item.cid,
                        cidName:item.cidName,
                        brandId:item.brandId,
                        brandName:item.brandName,
                        spuNumber:item.spuNumber,
                        spuName:item.spuName,
                        skuNumber:item.skuNumber,
                        skuName:item.skuName,
                        unitId:item.defUnitId,   //默认单位
                        unitName:item.defUnitName,
                        unitPrice:item.unitPrice,
                        itemNum:1,
                        remark:'',
                        state:1,
                        isDel:0,
                        isEdit:1,
                        skuUnitList:item.skuUnitList,   //单位列表
                    }                      
                    this.planInfo.detailList.push(newRow);
                });
            }
            this.BoxSkuInfo.isVisible = false   //关闭选择产品弹框
        },

        //编辑行
        EditRow(id){
            this.planInfo.detailList = this.planInfo.detailList.map(item=>{
                if(item.id === id){
                    item.isEdit = 1;
                }
                return item
            })
        },
        

    }

}
</script>

<style>

</style>