<template>
    <div>
        <div v-if="workInfo.inAllInfoList !=null && workInfo.inAllInfoList.length>0">
            <!-- 入库记录 -->
            <div class="CardItem" v-for="(allInfo,index) in workInfo.inAllInfoList" :key="index">
                <!-- 主信息 -->
                <el-descriptions border>
                    <el-descriptions-item>
                        <template #label>
                            入库单号
                        </template>
                            {{allInfo.info.inNumber}}
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template #label>
                            入库时间
                        </template>
                            {{DateTimeFormat(allInfo.info.inTime)}}
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template #label>
                            状态
                        </template>
                            <el-tag v-if="allInfo.info.state == 1">新建单</el-tag>
                            <el-tag v-if="allInfo.info.state == 2">入库中</el-tag>
                            <el-tag v-if="allInfo.info.state == 3" type="info">已入库</el-tag>
                            <el-tag v-if="allInfo.info.state == 10">已取消</el-tag>
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template #label>
                            交易者类型
                        </template>
                            <span v-if="allInfo.info.tradeType == 0">无</span>
                            <span v-if="allInfo.info.tradeType == 1">供应商</span>
                            <span v-if="allInfo.info.tradeType == 2">客户</span>
                            <span v-if="allInfo.info.tradeType == 3">员工</span>
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template #label>
                            交易者名称
                        </template>
                            {{allInfo.info.tradeName}}
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template #label>
                            经手人
                        </template>
                            {{allInfo.info.receiveName}}
                    </el-descriptions-item>

                    
                
                </el-descriptions>
                
                <!-- 入库单明细列表 -->
                <el-table :data="allInfo.detailList" border stripe style="width: 100%" >
                    <el-table-column prop="warehouseName" label="仓库"></el-table-column>
                    <el-table-column prop="cidName" label="产品类目"></el-table-column>
                    <el-table-column prop="brandName" label="品牌"></el-table-column>
                    <el-table-column prop="spuName" label="产品"></el-table-column>
                    <el-table-column prop="skuName" label="规格"></el-table-column>
                    <el-table-column prop="unitName" label="单位"></el-table-column>
                    <el-table-column prop="realNum" label="入库数量"></el-table-column>
                    <el-table-column label="备注">
                        <template v-slot="scope">
                            <el-tag v-if="scope.row.goodsFlag ==1" type="warning">退料</el-tag>
                            <el-tag v-else>成品</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div v-else>
            无入库记录
        </div>
    </div>  
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Erp',['workInfo'])
    },
}
</script>

<style scoped>
.CardItem{padding-bottom: 20px;margin-bottom: 20px;border-bottom: 1px solid #ccc;}
</style>