<template>
  <div>
    
    <el-table :data="orderInfo.detailList" border show-summary style="width: 100%">
      <el-table-column prop="spuName" label="产品名称"></el-table-column>
      <el-table-column prop="skuName" label="规格"></el-table-column>
      <el-table-column label="图片">
          <template v-slot="scope">
              <el-image
                  style="width: 70px; height: 70px"
                  :src="scope.row.skuImg"
                  :preview-src-list="[scope.row.skuImg]"
                  :hide-on-click-modal="true"
                  fit="cover">
              </el-image>
          </template>
      </el-table-column>
      <el-table-column prop="retailPrice" label="单价"></el-table-column>
      <el-table-column prop="realNum" label="数量"></el-table-column>
      <el-table-column prop="totalPrice" label="总价"></el-table-column>  
    </el-table>

    <div class="DetailHeight"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name:'TabOrderDetail',
  computed:{
    ...mapState('Shop',['orderInfo'])
  },

}
</script>

<style scoped>
.DetailHeight{height: 20px;}
</style>