<template>
  <div>
      <!-- 搜索功能 -->
      <el-row>
          <el-col :span="24">
              <el-form :inline="true">

                <el-form-item label="业务单号">
                  <el-input v-model="info.search.billNumber" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="原始单号">
                  <el-input v-model="info.search.originNumber" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" class="search-200" collapse-tags collapse-tags-tooltip>
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="大区">
                    <el-select v-model="info.search.areaNumberList" multiple collapse-tags collapse-tags-tooltip class="search-200">
                        <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="单据类型">
                    <el-select v-model="info.search.originTypeList" multiple collapse-tags collapse-tags-tooltip class="search-150">
                        <el-option label="采购单" :value="1" />
                        <el-option label="销售单" :value="2" />
                        <el-option label="零售单" :value="3" />
                        <el-option label="差额单" :value="4" />
                        <el-option label="退供单" :value="14" />
                        <el-option label="退货单" :value="15" />
                        <el-option label="网络订单" :value="17" />
                    </el-select>
                </el-form-item>

                <el-form-item label="交易者">
                    <el-select v-model="info.search.tradeNumber" filterable clearable class="search-150">
                        <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                    </el-select>
                </el-form-item>

                <el-form-item label="财务标识">
                    <el-select v-model="info.search.financeFlag" class="search-150">
                        <el-option label="全部" :value="-1" />
                        <el-option label="未生成" :value="0" />
                        <el-option label="已生成" :value="1" />
                    </el-select>
                </el-form-item>

                <el-form-item label="支付状态">
                    <el-select v-model="info.search.payState" class="search-150">
                        <el-option label="全部" :value="-1" />
                        <el-option label="未支付" :value="0" />
                        <el-option label="已支付" :value="1" />
                    </el-select>
                </el-form-item>

                <el-form-item label="名称">
                    <el-input v-model="info.search.searchName" placeholder="品牌、分类、产品名称" class="search-200"></el-input>
                </el-form-item>

                <el-form-item label="记账日期">
                    <el-date-picker
                        v-model="info.search.datePoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width:250px"
                    />
                </el-form-item>

                <el-form-item label="创建日期">
                    <el-date-picker
                        v-model="info.search.createPoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width:250px"
                    />
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

              </el-form>
          </el-col>
      </el-row>

      <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button v-if="CheckUserButtonAuth('BtnBusinessRefresh')" class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
            <el-button v-if="CheckUserButtonAuth('BtnBusinessAddFinance')" class="iconfont icon-dingdan" type="warning" plain @click="showCreateFinance()">生成财务单</el-button>
            <el-button type="success" class="iconfont icon-dingdan" @click="btnOpenCycle">生成对账单</el-button>
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnShowBoxPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
                </el-tooltip>
            </el-button-group>
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" border stripe show-summary style="width: 100%;" :height="GetDataHeight" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="id" type="selection" width="55" />  
            <el-table-column label="业务单号" width="170">
                <template v-slot="scope">
                    <span>{{scope.row.billNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column label="单据类型" width="85">
                <template #default="scope">
                    <el-tag v-if="scope.row.originType == 1" type="danger">采购单</el-tag>
                    <el-tag v-if="scope.row.originType == 2" type="success">销售单</el-tag>
                    <el-tag v-if="scope.row.originType == 3" type="info">零售单</el-tag>
                    <el-tag v-if="scope.row.originType == 4" type="warning">差额单</el-tag>                    
                    <el-tag v-if="scope.row.originType == 14" type="warning">退供单</el-tag>
                    <el-tag v-if="scope.row.originType == 15" type="warning">退货单</el-tag>
                    <el-tag v-if="scope.row.originType == 17" type="info">网络订单</el-tag>
                </template>
            </el-table-column> 
            <el-table-column prop="departmentName" label="部门名称" width="150">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.departmentName"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
            </el-table-column>
            
            <el-table-column label="交易者类型" min-width="95">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.tradeType == 1" type="danger">供应商</el-tag>
                    <el-tag v-if="scope.row.tradeType == 2" type="success">客户</el-tag>
                    <el-tag v-if="scope.row.tradeType == 3" type="info">员工</el-tag>
                    <el-tag v-if="scope.row.tradeType == 4" type="info">散客</el-tag>
                    <el-tag v-if="scope.row.tradeType == 5" type="info">会员</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="交易者" property="tradeName" show-overflow-tooltip min-width="120">
            </el-table-column>

            <el-table-column label="产品类目" show-overflow-tooltip min-width="90">
                <template v-slot="scope">
                    <span>{{scope.row.cidName}}</span>
                </template>
            </el-table-column>
            <el-table-column label="品牌">
                <template v-slot="scope">
                    <span>{{scope.row.brandName}}</span>
                </template>
            </el-table-column>
            <el-table-column label="产品/类型" min-width="160">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.spuName"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="规格/说明" show-overflow-tooltip min-width="100">
                <template v-slot="scope">
                    <span>{{scope.row.skuName}}</span>
                </template>
            </el-table-column>
            <el-table-column label="单位">
                <template v-slot="scope">
                    <span>{{scope.row.unitName}}</span>
                </template>
            </el-table-column>
            <el-table-column label="单价" min-width="80">
                <template v-slot="scope">
                    <span>{{scope.row.unitPrice}}</span>
                </template>
            </el-table-column>
            <el-table-column label="数量" min-width="80">
                <template v-slot="scope">
                    <span>{{scope.row.itemNum}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="总价(元)" min-width="95" />     

            <el-table-column label="财务标识" width="95">
                <template #default="scope">
                    <el-tag v-if="scope.row.financeFlag == 0" type="danger">未生成</el-tag>
                    <el-tag v-if="scope.row.financeFlag == 1" type="info">已生成</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="支付状态" width="95">
                <template #default="scope">
                    <el-tag v-if="scope.row.payState == 0" type="danger">未支付</el-tag>
                    <el-tag v-if="scope.row.payState == 1" type="info">已支付</el-tag>
                </template>
            </el-table-column>

            <el-table-column prop="billTime" label="记账时间" width="110">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.billTime"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{DateFormat(scope.row.billTime)}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="创建日期" width="110">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.createTime"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{DateFormat(scope.row.createTime)}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="制单人" property="createName" show-overflow-tooltip min-width="90"></el-table-column>

            <el-table-column label="备注" min-width="100">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.remark"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{scope.row.remark}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right">
                <template v-slot="scope">
                    <el-button size="small" type="primary" @click="btnEditInfo(scope.row.billNumber)">详情</el-button>
                </template>
            </el-table-column>
        
        </el-table>
        
    </div>

    <el-affix position="bottom">
          <el-row class="page_two">
              <el-col :span="24">
                  <!-- 分页 -->
                  <el-container class="page_right">
                      <el-pagination
                      v-model:currentPage="info.page"
                      v-model:page-size="info.size"
                      :page-sizes="[50, 200, 500, 1000]"
                      :background="true"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="info.total"
                      @current-change="btnPagechange"
                      @size-change="btnSizechange"
                      >
                      </el-pagination>
                  </el-container>
              </el-col>
          </el-row>        
      </el-affix>

    <!-- 详细页面-弹框 -->
    <el-dialog title="业务单详情"
    v-model="BoxBusinessInfo.isVisible"
    width="65%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditBusiness :editId="info.id" :dataList="dataList" @closeBox="btnCloseBusinessBox" ></EditBusiness>
    </el-dialog>

    <!-- 生成财务单-弹框 -->
    <el-dialog title="生成财务单"
    v-model="BoxCreateFinance.isVisible"
    width="45%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
        <BoxCreateFinance :financeInfo="financeInfo" ref="newFinanceInfo"></BoxCreateFinance>
        <template #footer>            
            <el-button type="primary" @click="btnCreateFinance()">生成财务单</el-button>
            <el-button @click="BoxCreateFinance.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

    <!-- 打印框 -->
    <el-dialog title="打印预览"
    v-model="BoxPrint.isVisible"
    :draggable="true"
    :close-on-click-modal="false"
    width="80%">
        <PrintView v-if="BoxPrint.isVisible" ref="printView" :dataList="dataList" @closeBox="btnClosePrint"></PrintView>
    </el-dialog>

    <!-- 对账单-弹出框 -->
    <el-dialog title="选择对账单"
    v-model="BoxCycle.isVisible"
    width="800px"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
        <BoxCreateCycle :financeInfo="financeInfo" ref="newCycleInfo"></BoxCreateCycle>
        <template #footer>
            <el-button type="primary" @click="btnCreateCycleBusiness">生成对账单</el-button>
            <el-button @click="BoxCycle.isVisible =false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import moment from 'moment';
import { ElLoading, ElMessage } from 'element-plus';

import UserDptList from '@/utils/mixins/UserDptList.js'   //用户管理部门列表 
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import UserAreaList from '@/utils/mixins/UserAreaList.js'  //用户关联大区列表

import BoxCreateFinance from '@/components/common/BoxCreateFinance.vue'  //生成财务单
import EditBusiness from '@/views/Bill/Business/EditBusiness.vue'   //业务单详情页
import PrintView from '@/views/Bill/Business/PrintView.vue'   //打印
import BoxCreateCycle from '@/views/Bill/Business/BoxCreateCycle.vue' //生成对账单
export default {
  name:'ListBusiness',
  components:{
    BoxCreateFinance,
    EditBusiness,
    PrintView,
    BoxCreateCycle,
  },
  mixins:[UserDptList,AllTradeList,UserAreaList],
  data(){
    return{
      dataList:[],  //Table数据
      info:{        
        page:1,
        size:50,
        total:0,  //总记录数
        id:0,  //新增、编辑 的参数
        ids:[],   //删除 的参数
        search:{
            billNumber:'',   //业务单号
            tradeNumber:'',   //交易者编号
            selectDepartmentList:[], //选中部门
            areaNumberList:[],   //大区列表
            originNumber:'',  //原始单据编号
            financeFlag:-1,  //财务单标识
            originTypeList:[],   //单据类型
            searchName:'',    //分类、品牌、产品名称
            datePoint:'',    //制单日期
            createPoint:'',  //创建日期
            payState:-1,  //支付状态 -1:全部 0:未支付 1:已支付
        },
      },
      financeInfo:{
          numberList:[],  //编号数组
          priceList:[], 
          departmentId:0,
          billType:0,   //财务类型 默认：根据交易者类型定义
          subId:0,
          totalPrice:0,
          billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          tradeType:0,
          tradeNumber:'',
      },  //生成财务单信息
      //生成财务单-弹出框
      BoxCreateFinance:{
          isVisible:false,
      },
      //业务单详情-弹出框
      BoxBusinessInfo:{
        isVisible:false,
      },
      //打印框
        BoxPrint:{
            isVisible:false,
        },
        //选择对账单-弹出框
        BoxCycle:{
            isVisible:false
        },

    }
  },
  computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-135
        }
    },
  methods:{
    
    //加载数据列表
    GetDataList(){
      //记账时间
      if(this.info.search.datePoint == null){
          this.info.search.datePoint = ''
      }
      //创建日期
      if(this.info.search.createPoint == null){
        this.info.search.createPoint = ''
      }

      //获取、转换部门
      let newDepartmentList = []
      if(this.info.search.selectDepartmentList!=null){
          newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
              return item
          })
      }

      let param = {
        billNumber:this.info.search.billNumber,           //业务单号
        departmentList:newDepartmentList,           //部门列表
        areaNumberList:this.info.search.areaNumberList,  //大区
        originNumber:this.info.search.originNumber,  //原始单据编号
        tradeNumber:this.info.search.tradeNumber,   //交易者
        financeFlag:this.info.search.financeFlag,  //财务标识
        originTypeList:this.info.search.originTypeList,    //单据类型
        searchName:this.info.search.searchName,    //名称
        payState:this.info.search.payState,   //支付状态
        startDate:this.DateFormat(this.info.search.datePoint[0]),   //制单日期
        endDate:this.DateFormat(this.info.search.datePoint[1]),
        createStartDate:this.DateFormat(this.info.search.createPoint[0]),  //创建日期
        createEndDate:this.DateFormat(this.info.search.createPoint[1]),  
        page:this.info.page,
        size:this.info.size
      }
      this.$api.erp.PageBusinessList(param).then(res=>{
        if(res.data.status === 200){
          this.dataList = res.data.data;  //获取数据
          //分页
          this.info.total = res.data.total
          this.info.page = res.data.pageNumber
        }else{
            ElMessage.error(res.data.msg);
        }
      }).catch(e=>{
        console.log(e);
      })
    },
    //选中多选框
    btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.billNumber)
        this.financeInfo.numberList = selection.map(item=>item.billNumber);  //编号数组
        this.financeInfo.priceList = selection.map(item=> parseFloat(item.totalPrice));  //计算总金额
        this.financeInfo.tradeNumber = selection.find(item=>item).tradeNumber;  //交易者编号
        this.financeInfo.tradeName = selection.find(item=>item).tradeName;   //交易者名称
        this.financeInfo.departmentId = selection.find(item=>item).departmentId;  //部门
    },

    //分页-页数变化时
    btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
    },
    //分页-单页记录数变化时
    btnSizechange(size){
        this.info.size = size;
        this.info.page = 1;
        this.GetDataList() //加载Table数据
    },

    //重置按钮
    btnResetSearch(){
        this.info.search.billNumber=''; 
        this.info.search.tradeNumber='';
        this.info.search.selectDepartmentList = [];
        this.info.search.areaNumberList = [];
        this.info.search.originNumber='';
        this.info.search.financeFlag=-1;
        this.info.search.payState=-1;
        this.info.search.datePoint = '';
        this.info.search.createPoint = '';
        this.info.search.originTypeList = [];   //原始单据类型
        this.info.search.searchName = '';
        this.info.ids = [];  //编号数组
        this.info.page = 1;
        this.GetDataList()
    },

    //编辑单个信息
    btnEditInfo(billNumber){
        this.info.id = billNumber;
        this.BoxBusinessInfo.isVisible = true;
    },
    //关闭业务单-弹出框
    btnCloseBusinessBox(){
        this.BoxBusinessInfo.isVisible = false;
    },


    //弹出-生成财务单
    showCreateFinance(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要生成财务单的单据')
            return false
        }
        //计算总金额
        this.financeInfo.totalPrice = 0;
        this.financeInfo.priceList.forEach(element => {
            this.financeInfo.totalPrice =  this.financeInfo.totalPrice + element
        });
        this.BoxCreateFinance.isVisible =true
    },
    //保存-业务单生成财务单
    btnCreateFinance(){
        //验证-部门
        if(this.$refs.newFinanceInfo.info.departmentId ===0){
            ElMessage.error("请选择部门");
            return false;
        }

        //验证-会计科目
        if(this.$refs.newFinanceInfo.info.subId ===0){
            ElMessage.error("请选择会计科目");
            return false;
        }

        //打开遮罩层
        const loading = ElLoading.service({
            lock:true,
            text:'正在提交,请稍候! 业务单过多时，请稍后查询财务单，防止重复提交',
            background:'rgba(0, 0, 0, 0.7)',
        })
        
        this.$api.erp.CreateFinance(this.$refs.newFinanceInfo.info).then(res=>{
            loading.close();
            if(res.data.status === 200){
                ElMessage.success(res.data.msg);
                //关闭
                this.BoxCreateFinance.isVisible = false;
                this.info.ids=[];
                this.financeInfo.numberList=[];  //清空选中的单据
                this.GetDataList();  //重新加载
            }else{
                ElMessage.error(res.data.msg);
            }
        })
    },

    //导出业务单列表到Excel
    btnExportInfo(){

        //打开遮罩层
        const loading = ElLoading.service({
            lock:true,
            text:'正在导出数据,请稍候!',
            background:'rgba(0, 0, 0, 0.7)',
        })

        //记账时间
        if(this.info.search.datePoint == null){
            this.info.search.datePoint = ''
        }
        //创建时间
        if(this.info.search.createPoint == null){
            this.info.search.createPoint = ''
        }

      //获取、转换部门
      let newDepartmentList = []
      if(this.info.search.selectDepartmentList!=null){
        newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
            return item
        })
      }

      let param = {
        billNumber:this.info.search.billNumber,    //业务单号
        departmentList:newDepartmentList,
        areaNumberList:this.info.search.areaNumberList,  //大区
        originNumber:this.info.search.originNumber,  //原始单据编号
        tradeNumber:this.info.search.tradeNumber,   //交易者
        financeFlag:this.info.search.financeFlag,  //财务标识
        originTypeList:this.info.search.originTypeList,    //单据类型
        searchName:this.info.search.searchName,      //名称
        payState:this.info.search.payState,   //支付状态
        startDate:this.DateFormat(this.info.search.datePoint[0]),   //制单日期 
        endDate:this.DateFormat(this.info.search.datePoint[1]),
        createStartDate:this.DateFormat(this.info.search.createPoint[0]),  //创建日期
        createEndDate:this.DateFormat(this.info.search.createPoint[1]),  
        page:this.info.page,
        size:this.info.size
      }

        this.$api.erp.ExportBusiness(param).then(res=>{
            loading.close();
            if(res.data.status === 200){
                const alink = document.createElement('a');
                alink.href = res.data.data;
                document.body.appendChild(alink);
                alink.click();
                document.body.removeChild(alink)
            }
            else
            {
                ElMessage.error(res.data.msg)
            }
        }).catch(err=>{
            console.log(err)
        })
    },


    //打开-对账单-弹出框
    btnOpenCycle(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要添加到对账单的单据')
            return false
        }
        this.BoxCycle.isVisible = true;
    },
    //保存业务对账单
    btnCreateCycleBusiness(){
        //打开遮罩层
        const loading = ElLoading.service({
            lock:true,
            text:'正在提交,请稍候!',
            background:'rgba(0, 0, 0, 0.7)',
        })
        this.$api.erp.CreateCycleBusiness(this.$refs.newCycleInfo.info).then(res=>{
            loading.close();
            if(res.data.status === 200){
                ElMessage.success(res.data.msg);
                //关闭
                this.BoxCycle.isVisible = false;
            }else{
                ElMessage.error(res.data.msg);
            }
        })
        
    },


    //打开打印框
    btnShowBoxPrint(){
        this.BoxPrint.isVisible =true;
    },
    //关闭打印框
    btnClosePrint(){
        this.BoxPrint.isVisible =false;        
    },
    

  },
  mounted(){
    this.GetDataList();  //加载数据
  },

}
</script>

<style>

</style>