<template>
  <div>
    <el-divider></el-divider>

    <TabChannel></TabChannel>
    <el-divider>产品明细</el-divider>
    <TabChannelDetail></TabChannelDetail>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import TabChannel from '@/views/Shop/Channel/TabChannel.vue'
import TabChannelDetail from '@/views/Shop/Channel/TabChannelDetail.vue'

import { mapMutations, mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],
    components:{
        TabChannel,
        TabChannelDetail
    },
    data(){
        return{
            channelNumber:this.editId,  //当前编号
        }
    },
    computed:{      
        ...mapState('Shop',['channelInfo'])
    },
    methods:{
        ...mapMutations('Shop',['SetChannelInfo']),

        //获取数据信息
        GetDataInfo(){
            if(this.channelNumber!=0){
                let param = {
                    channelNumber:this.channelNumber
                }
                this.$api.shop.GetChannelAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetChannelInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存数据 type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            this.$api.shop.SaveChannelAllInfo(this.channelInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //清空数据
        btnClearData(){
            let newInfo = {
                info:{
                    id:0,
                    systemNumber:'',
                    channelNumber:'',
                    channelTitle:'',
                    content:'',
                    bgImg:'',
                    channelType:1,
                    sort:0,
                    onLine:1,
                    isDel:0
                },
                detailList:[],                
            }
            this.SetChannelInfo(newInfo);
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.channelNumber === this.channelNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.channelNumber = prevInfo.channelNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.channelNumber === this.channelNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.channelNumber = prevInfo.channelNumber
                this.GetDataInfo()
            }
        },


    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style>
</style>