<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <div class="editMain">
        <TabInfo></TabInfo>
    </div>
    
    <el-tabs v-model="activeName" class="editTab" type="border-card">
        <el-tab-pane label="授权Web端菜单" name="tabPcMenu">
            <TabPcMenu ref="tabPcMenu"></TabPcMenu>
        </el-tab-pane>
        <el-tab-pane label="授权Mobile端菜单" name="tabMobileMenu">
            <TabMobileMenu ref="tabMobileMenu"></TabMobileMenu>
        </el-tab-pane>
    </el-tabs>
    

    <!-- 功能按钮 -->
    <div class="editFooter">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary" @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import TabInfo from '@/views/system/Role/TabRoleInfo.vue' //角色信息
import TabPcMenu from '@/views/system/Role/TabPcMenu.vue' //PC端菜单
import TabMobileMenu from '@/views/system/Role/TabMobileMenu.vue' //移动端菜单
import { ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
export default {
    name:'EditRole',
    props:['editId','dataList'],
    components:{
        TabInfo,
        TabPcMenu,
        TabMobileMenu,
    },
    data(){
        return{
            activeName:'tabPcMenu',  //默认标签页
            roleId:this.editId,   //当前编号
        }
    },
    computed:{
        ...mapState('System',['roleInfo'])
    },
    methods:{
        ...mapMutations('System',['SetRoleInfo']),

        //加载数据
        GetDataInfo(){
            if(this.roleId!=0){
                let param = {
                    roleId:this.roleId
                }
                this.$api.system.GetSystemRoleAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetRoleInfo(res.data.data);
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }
            else
            {
                this.btnClearData()
            }
        },

        //保存信息 -type:1:保存并关闭 2:保存并新增
        btnSaveData(type){
            //#region 选中的PC端菜单
            let selPcMenuList = [];
            //全选中
            const selPcMenuKeys = this.$refs.tabPcMenu.$refs.treeMenu.getCheckedKeys();
            selPcMenuKeys.forEach(element => {
                let menu = {
                    id:0,
                    systemNumber:'',
                    roleId:this.roleInfo.info.roleId,
                    menuId:element,
                    state:2
                }
                selPcMenuList.push(menu);
            });
            //半选中
            const halfSelPcMenuKeys = this.$refs.tabPcMenu.$refs.treeMenu.getHalfCheckedKeys();
            halfSelPcMenuKeys.forEach(element=>{
                let menu = {
                    id:0,
                    systemNumber:'',
                    roleId:this.roleInfo.info.roleId,
                    menuId:element,
                    state:1
                }
                selPcMenuList.push(menu);
            })
            this.roleInfo.menuList = selPcMenuList;
            //#endregion
            //#region 选中的Mobile端菜单
            let selMobileMenuList = [];
            //全选中
            const selMobileMenuKeys = this.$refs.tabMobileMenu.$refs.treeMenu.getCheckedKeys();
            selMobileMenuKeys.forEach(element => {
                let menu = {
                    id:0,
                    systemNumber:'',
                    roleId:this.roleInfo.info.roleId,
                    menuId:element,
                    state:2
                }
                selMobileMenuList.push(menu);
            });
            //半选中
            const halfSelMobileMenuKeys = this.$refs.tabMobileMenu.$refs.treeMenu.getHalfCheckedKeys();
            halfSelMobileMenuKeys.forEach(element=>{
                let menu = {
                    id:0,
                    systemNumber:'',
                    roleId:this.roleInfo.info.roleId,
                    menuId:element,
                    state:1
                }
                selMobileMenuList.push(menu);
            })
            this.roleInfo.mobileMenuList = selMobileMenuList;
            //#endregion            
            this.$api.system.SaveRoleAllInfo(this.roleInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //类型
                    if(type==2){
                        this.btnClearData();
                    }else{
                        this.btnClose();
                    }
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })         
        }, 

        //清空实体
        btnClearData(){
            let newInfo = {
                info:{
                    roleId:0,
                    roleName:'',
                    mark:'',
                    sort:1,
                },
                selectMenuList:[],
                menuList:[],
                selectMobileMenuList:[],
                mobileMenuList:[],
            }
            this.SetRoleInfo(newInfo);
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.roleId === this.roleId
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.roleId = prevInfo.roleId
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.roleId === this.roleId
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.roleId = prevInfo.roleId
                this.GetDataInfo()
            }
        },

    },
    mounted(){
        this.GetDataInfo();
    }
}
</script>

<style>

</style>