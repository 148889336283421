<template>
  <!-- 被动询价单列表 -->
  <div>    
      <!-- 搜索功能 -->
      <el-row :style="showSearch.search_hidden">
          <el-col :span="24">
              <el-form :inline="true">

                <el-form-item label="编号">
                  <el-input v-model="info.search.billNumber" class="search-200"></el-input>
                </el-form-item>

                <el-form-item label="部门">
                    
                </el-form-item>

                <el-form-item label="供应商">
                    <el-select v-model="info.search.supplierNumber" clearable class="search-200">
                        <el-option v-for="supplier in info.supplierList" :key="supplier.supplierNumber" :label="supplier.supplierName" :value="supplier.supplierNumber" />
                    </el-select>
                </el-form-item>

                <el-form-item label="是否需要物流">
                    <el-select v-model="info.search.isTransport" class="search-200">
                        <el-option :value="-1" label="全部"></el-option>
                        <el-option :value="0" label="否"></el-option>
                        <el-option :value="1" label="是"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="info.search.state" class="search-200">
                        <el-option :value="0" label="全部"></el-option>
                        <el-option :value="1" label="新采购单"></el-option>
                        <el-option :value="2" label="已驳回"></el-option>
                        <el-option :value="3" label="已通过"></el-option>
                        <el-option :value="4" label="配送中"></el-option>
                        <el-option :value="5" label="已完成"></el-option>
                        <el-option :value="6" label="已取消"></el-option>
                        <el-option :value="10" label="已关单"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="记账日期">
                    <el-date-picker
                        v-model="info.search.datePoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>

                <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

              </el-form>
          </el-col>
      </el-row>

      <!-- 主内容区 -->
      <div>
          <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
              
          </el-col>
          </el-row>

          <!-- 列表 -->
          <el-table :data="info.dataList" border stripe style="width: 100%" @select="btnCheckbox" ref="table"> 
              <el-table-column prop="id" type="selection" width="50" />  
              <el-table-column prop="departmentName" label="部门名称" />
              <el-table-column prop="warehouseCode" label="仓库编码" />
              <el-table-column prop="warehouseName" label="仓库名称" />
              <el-table-column prop="type" label="仓库类型">
                  <template v-slot="scope">
                    <el-tag v-if="scope.row.type ==1">普通仓</el-tag>
                    <el-tag v-if="scope.row.type ==2">冷库仓</el-tag>  
                  </template>
              </el-table-column>
              <el-table-column prop="areaNum" label="库区数量" />
              <el-table-column prop="state" label="状态">
                  <template v-slot="scope">
                    <el-tag v-if="scope.row.state ==1">正常</el-tag>
                    <el-tag v-if="scope.row.state ==0" type="danger">关闭</el-tag>
                  </template>
              </el-table-column>
              <el-table-column label="操作" width="200" fixed="right">
              <template v-slot="scope">              
                  <el-button size="small" type="primary" @click="btnEditInfo(scope.row.warehouseNumber)">编辑</el-button>
                  <el-popconfirm 
                  title="确认要删除吗?"
                  @confirm="btnDeleteInfo(scope.row.warehouseNumber)">
                      <template #reference>
                      <el-button size="small" type="danger">删除</el-button>
                      </template>
                  </el-popconfirm>
              </template>
              </el-table-column>
          </el-table>

          <!-- 分页 -->
          <el-container class="page_one">
              <el-pagination
                  v-model:currentPage="info.page"
                  v-model:page-size="info.size"
                  :page-sizes="[10, 50, 100, 200]"
                  :background="true"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="info.total"
                  @current-change="btnPagechange"
                  @size-change="btnSizechange">
              </el-pagination>
          </el-container>

      </div>

      <!-- 新增、编辑 弹出框 -->
      <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="60%"
      :destroy-on-close="true">
        <!-- <EditWarehouse :editId="info.id" ref="editInfo"></EditWarehouse> -->
        <template #footer>
            <el-button type="primary" @click="btnSaveData">保存</el-button>
            <el-button @click="BoxEditInfo.isVisible = false">关闭</el-button>
        </template>
      </el-dialog>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    name:'TabInquiryPassive',
    data(){
      return{
        info:{
          dataList:[],  //Table数据
          page:1,
          size:10,
          total:0,  //总记录数
          id:0,  //新增、编辑 的参数
          ids:[],   //删除 的参数
          search:{

          }
        },
        //编辑-弹出框
        BoxEditInfo:{
          isVisible:false,
          title:'新增询价单'
        },

      }
    },
    methods:{
      //加载数据列表
      GetDataList(){
        console.log('加载数据')
      },
      //选中多选框
      btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.billNumber)
      },
      //分页-页数变化时
      btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
      },
      //分页-单页记录数变化时
      btnSizechange(size){
        this.info.size = size
        this.info.page = 1
        this.GetDataList() //加载Table数据
      },
      //新增、编辑信息
      btnEditInfo(billNumber){
        this.info.id = billNumber
        if(billNumber == 0){
            this.BoxEditInfo.title = "新增询价单"
        }
        else{
            this.BoxEditInfo.title = "编辑询价单"
        }
        this.BoxEditInfo.isVisible = true;
      },
      //保存-仓库信息
      btnSaveData(){
        this.$api.wms.SaveWarehouseInfo(this.$refs.editInfo.info).then(res=>{
          if(res.data.status === 200){
              ElMessage({type:'success',message:res.data.msg});
              this.GetDataList()
              this.BoxEditInfo.isVisible = false;
          }
          else{
              ElMessage.error(res.data.msg)
          }
        }).catch(e=>{
          console.log(e.message)
        })
      },
      //删除按钮
      btnDeleteInfo(warehouseNumber){
        let param = [warehouseNumber]
        this.$api.wms.DeleteWarehouseInfo(param).then(res=>{
          if(res.data.status === 200){
              ElMessage({type:'success',message:res.data.msg})
              this.GetDataList()
          }
          else{
              ElMessage({type:'error',message:res.data.msg})
          }
        })
      },
      //批量删除
      btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
          ElMessage({type:'error',message:'请选择要删除的选项'})
          return false
        }

        this.$api.wms.DeleteWarehouseInfo(this.info.ids).then(res=>{
          if(res.data.status === 200){
              ElMessage({type:'success',message:res.data.msg})
              this.GetDataList()
          }
          else{
              ElMessage({type:'error',message:res.data.msg})
          }
        })
      },

      //重置按钮
      btnResetSearch(){
        this.info.search = {
            departmentIdList:[],
            warehouseName:'',
            type:0
        }
        this.GetDataList();
      }

    }
}
</script>

<style>

</style>