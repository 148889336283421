<template>
  <div>
    <!-- 全年财务分类统计页面 -->

    <!-- 统计图 -->
    <div id="main1" class="echarts" style="width:100%;height:500px;" ></div>

    
  </div>
</template>

<script>
import * as echarts from 'echarts'  //Echarts 图标组件
export default {
    data(){
        return{
            currentYear:'',
            info:[],  //数据
        }
    },
    methods:{
        
        //Echarts
        myEcharts(){
            var myChart = echarts.init(document.getElementById('main1'));

            var option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: this.info
                    }
                ]
                };
                myChart.setOption(option);

                //点击事件
                myChart.off('click');  //取消之前的点击事件，不然会触发两次点击
                myChart.on('click',function(param){
                    console.log(param.data);
                })
        },

        //加载数据
        async LoadDataView(strYear){
            this.currentYear = strYear;
          let param = {
            year:strYear
          }
          await this.$api.stat.GetStatYearFinanceBillType(param).then(res=>{
            if(res.data.status === 200){
              this.info = res.data.data;
              this.myEcharts();
            }
          })
        },

        

    },
    mounted(){
    }
}
</script>

<style>

</style>