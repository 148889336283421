<template>
  <div>
    <!-- 生成采购单 - 弹出框 -->
    <el-divider></el-divider>

    <el-form :inline="true"
        v-model="allInfo.info"
        label-width="100px"
        label-position="right">
            <el-row v-if="allInfo.info">

                <el-col :span="12">
                    <el-form-item label="所属部门">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>所属部门</span>
                        </template>
                        <el-tree-select v-model="allInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200">
                        </el-tree-select>
                    </el-form-item>
                </el-col>                

                <el-col :span="12">
                    <el-form-item label="入库仓库">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>入库仓库</span>
                        </template>
                        <el-select v-model="allInfo.info.warehouseNumber" class="search-200">
                          <el-option v-for="warehouse in userWhList" :key="warehouse.warehouseNumber" :label="warehouse.warehouseName" :value="warehouse.warehouseNumber" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="交易者">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>交易者</span>
                        </template>
                        <el-select v-model="allInfo.info.tradeNumber" filterable class="search-200">
                            <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="记账时间">
                        <el-date-picker v-model="allInfo.info.billTime" type="datetime" placeholder="记账时间" value-format="YYYY-MM-DD hh:mm:ss" :disabled-date="BillDisabledDate" style="width:200px"/>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="期望到货时间">
                        <el-date-picker v-model="allInfo.info.arriveTime" type="date" placeholder="期望到货时间" value-format="YYYY-MM-DD" style="width:200px" />
                    </el-form-item>
                </el-col>


            </el-row>
    </el-form>

  </div>
</template>

<script>
import moment from 'moment'
import UserDptList from '@/utils/mixins/UserDptList.js'
import UserWhList from '@/utils/mixins/UserWhList.js'  //用户关联仓库
import PartTradeList from '@/utils/mixins/PartTradeList.js'  //部分交易者列表
export default {
  name:'BoxCreatePurchase',
  mixins:[UserDptList,UserWhList,PartTradeList],
  props:['purchaseInfo'],
  data(){
    return{
      allInfo:{
        info:{
          billNumber:'',
          departmentId:0,
          warehouseNumber:'',
          tradeNumber:'',
          billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          arriveTime:moment(new Date()).format("YYYY-MM-DD"),
          isTransport:0,
          state:1,
        },
        planNumberList:[],
      },
      invoiceList:[],  //发票类型列表
    }
  },
  methods:{
    //加载默认部门编号
    LoadDefaultDepartment(){
      this.allInfo.info.departmentId = this.purchaseInfo.departmentId;
    },
    
  },
  mounted(){
    this.LoadDefaultDepartment();   //加载默认部门编号
    this.allInfo.planNumberList = this.purchaseInfo.numberList;
  }
}
</script>

<style>

</style>