<template>
  <div>
    <!-- 移动端菜单授权 -->

    <el-scrollbar :height="GetDataHeight" always :min-size="30">
        <el-tree
        :data="allMobileMenuList"
        :default-checked-keys="systemInfo.selectMobileMenuList"
        :default-expanded-keys="[0]"
        show-checkbox
        ref = "treeMenu"
        node-key="id">          
        </el-tree>
    </el-scrollbar>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return{
            allMobileMenuList:[],   //全部移动端菜单列表
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-200
        },
        ...mapState('System',['systemInfo'])
    },
    methods:{
        //加载移动端全部菜单
        loadDataAllMenu(){
            let param = {
                isButton:1
            }
            this.$api.system.SelectAllMobileMenuList(param).then(res=>{
                if(res.data.status === 200){
                    this.allMobileMenuList = res.data.data;
                }else{
                    this.allMobileMenuList = []
                }
            })
        },
    },
    mounted(){
        this.loadDataAllMenu();
    },
}
</script>

<style>

</style>