/**
 * 获取全部部门列表
 * === 下拉列表专用 ===
 */

const AllDepartmentList = {
    data(){
        return{
            allDptList:[],   //全部部门列表
        }
    },
    methods:{
        //加载全部部门列表
        LoadDptList(){
            let param = {
                isShow:1
            }
            this.$api.system.GetTreeDptList(param).then(res=>{
                if(res.data.status === 200){
                    this.allDptList = res.data.data;
                }else{
                    this.allDptList = [];
                }
            })
        },

    },
    mounted(){
        this.LoadDptList();

    },
}

export default AllDepartmentList;