<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="零售单" name="tabInfo">
            <TabRetail ref="tabRetail"></TabRetail>
    
            <el-divider>零售单明细</el-divider>
            <TabRetailDetail></TabRetailDetail>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabRetailRecord></TabRetailRecord>
        </el-tab-pane>
    </el-tabs>
    

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="retailInfo.info">
        <el-button type="primary" v-if="retailInfo.info.state<=1" @click="btnSaveData">保存</el-button>
        <el-button type="danger" v-if="retailInfo.info.retailNumber !='' && retailInfo.info.state ==1" @click="BoxPayState.isVisible=true">确认支付</el-button>
        <el-button type="info" v-if="retailInfo.info.state==5" @click="btnSelectPrinter">打印小票</el-button>
        
        <el-button type="danger" v-if="retailInfo.info.state==5" @click="btnRefundRetail">退款</el-button>
        <el-button type="warning" v-if="retailInfo.info.state==5" @click="btnOpenBoxReturnRetail">创建退零单</el-button>
        <el-button type="primary" v-if="retailInfo.info.retailNumber !='' && retailInfo.info.state==1" @click="btnSaveRetailToRetail(retailInfo.info.retailNumber)">生成销售单</el-button>
        <el-button type="info" v-if="retailInfo.info.retailNumber !='' && retailInfo.info.state ==1" @click="btnCancelRetail">取消</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 结算-弹出框 -->
    <el-dialog title="结算"
    v-model="BoxPayState.isVisible"
    :close-on-click-modal="false"
    width="500">
        <BoxPayBill @closeBox="btnCloseBoxPayBill"></BoxPayBill>
    </el-dialog>

    <!-- 创建退零单-弹出框 -->
    <el-dialog title="创建退零单"
    v-model="BoxReturnRetail.isVisible"
    :close-on-click-modal="false"
    width="80%">
        <BoxReturnRetail :oldInfo="retailInfo" @closeBox="btnCloseBoxReturnRetail"></BoxReturnRetail>
    </el-dialog>

    <el-dialog title="选择打印机"
    v-model="BoxPrinter.isVisible"
    width="300">
        <el-divider></el-divider>
        <el-select v-model="info.printerNumber" class="search-200">
            <el-option v-for="printer in info.printerList" :key="printer.id" :label="printer.printerName" :value="printer.printerNumber"></el-option>
        </el-select>

        <template #footer>
            <el-button @click="btnPrintRetail">零售小票</el-button>
            <el-button @click="btnPrintRetialOutStock">配货小票</el-button>
            <el-button @click="BoxPrinter.isVisible=false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import BoxPayBill from '@/views/Rms/Retail/BoxPayBill.vue'
import TabRetail from '@/views/Rms/Retail/TabRetail.vue'
import TabRetailDetail from '@/views/Rms/Retail/TabRetailDetail.vue'
import TabRetailRecord from '@/views/Rms/Retail/TabRetailRecord.vue'  //tab-备注记录
import BoxReturnRetail from '@/views/Rms/Retail/BoxReturnRetail.vue' //退零单-弹出框
import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
export default {
    props:['editId','dataList'],
    data(){
        return{
            info:{
                activeName:'tabInfo',
                retailNumber:this.editId,  //当前零售单编号
                printerNumber:'',   //选中的打印机编号
                printerList:[],  //打印机列表
            },
            //支付方式-弹出框
            BoxPayState:{
                isVisible:false,
            },
            //创建退零单 - 弹出框
            BoxReturnRetail:{
                isVisible:false,
            },
            //打印机-弹出框
            BoxPrinter:{
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Erp',['retailInfo'])
    },
    components:{
        TabRetail,
        TabRetailDetail,
        BoxPayBill,
        BoxReturnRetail,
        TabRetailRecord,
    },
    methods:{
        ...mapMutations('Erp',['SetRetailInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.retailNumber !=0){
                let param = {
                    retailNumber:this.info.retailNumber
                }
                this.$api.erp.GetRetailAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetRetailInfo(res.data.data)
                    }else{
                        this.btnClearData();
                    }
                    //加载
                    this.$refs.tabRetail.SelectShiftRecordList();
                    this.$refs.tabRetail.SelectRetailAccountList();  //零售专用账户列表
                    this.$refs.tabRetail.SelectUserAreaList();   //用户关联大区列表
                })
            }else{
                this.btnClearData();
                //加载
                this.$refs.tabRetail.SelectShiftRecordList();
                this.$refs.tabRetail.SelectRetailAccountList();  //零售专用账户列表
                this.$refs.tabRetail.SelectUserAreaList();   //用户关联大区列表
            }
        },

        //清空实体
        btnClearData(){
            var newInfo = {
                info:{
                    systemNumber:'',
                    retailNumber:'',
                    departmentId:this.userDepartmentId,
                    areaNumber:'',
                    areaName:'',
                    tradeType:4, //默认：散客
                    accountNumber:'',  //账户编号
                    tradeNumber:'',
                    tradeName:'散客',
                    payType:1,  //支付方式
                    billTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    totalPrice:0,
                    realPrice:0,
                    payMode:2,
                    state:1,
                    billFlag:0,
                    createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                },
                detailList:[]
            }
            this.SetRetailInfo(newInfo);
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //保存数据
        btnSaveData(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })
            
            this.$api.erp.SaveRetailAllInfo(this.retailInfo).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //刷新页面
                    this.retailInfo.info.retailNumber = res.data.data;
                    this.info.retailNumber = res.data.data;
                    this.GetDataInfo();
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //退款
        btnRefundRetail(){
            ElMessageBox.confirm('确认要退款吗','退款提示',{
                confirmButtonText:'确认',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                //打开遮罩层
                const loading = ElLoading.service({
                    lock:true,
                    text:'正在提交,请稍候!',
                    background:'rgba(0, 0, 0, 0.7)',
                })

                let param = {
                    retailNumber:this.info.retailNumber
                }
                this.$api.erp.RefundRetail(param).then(res=>{
                    loading.close();
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        //更新状态
                        this.retailInfo.info.state = 1; 
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }).catch(()=>{

            })
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.retailNumber === this.info.retailNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.retailNumber = prevInfo.retailNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.retailNumber === this.info.retailNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.retailNumber = prevInfo.retailNumber
                this.GetDataInfo()
            }
        },


        //关闭结算-弹出框
        btnCloseBoxPayBill(){
            this.BoxPayState.isVisible=false;
        },

        //取消订单
        btnCancelRetail(){
            ElMessageBox.confirm('确认要取消该订单吗?','取消提示',{
                confirmButtonText:'确认',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                //打开遮罩层
                const loading = ElLoading.service({
                    lock:true,
                    text:'正在提交,请稍候!',
                    background:'rgba(0, 0, 0, 0.7)',
                })

                let param = {
                    retailNumber:this.retailInfo.info.retailNumber,
                    payMode:this.retailInfo.info.payMode,
                    state:9,  //取消
                }

                this.$api.erp.UpdateRetailState(param).then(res=>{
                    loading.close();
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        this.retailInfo.info.state = 2;  //已支付
                        this.btnClose();
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }).catch(()=>{

            })
        },

        
        //创建退零单
        btnOpenBoxReturnRetail(){
            this.BoxReturnRetail.isVisible=true;
        },
        //关闭退零单
        btnCloseBoxReturnRetail(){
            this.BoxReturnRetail.isVisible=false;
        },

        //生成销售单
        btnSaveRetailToRetail(retailNumber){
            ElMessageBox.confirm('确认要将此单移至销售单吗?','移单提示',{
                confirmButtonText:'确认',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                //打开遮罩层
                const loading = ElLoading.service({
                    lock:true,
                    text:'正在提交,请稍候!',
                    background:'rgba(0, 0, 0, 0.7)',
                })

                let param = {
                    retailNumber:retailNumber
                }
                this.$api.erp.SaveRetailToSale(param).then(res=>{
                    loading.close();
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        //关闭
                        this.btnClose();
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }).catch(()=>{

            })
        },

        //选择小票打印机
        btnSelectPrinter(){
            this.$api.system.SelectPrinterList().then(res=>{
                if(res.data.status ===200){
                    this.info.printerList = res.data.data;
                    //默认项
                    if(this.info.printerList !=null && this.info.printerList.length>0){
                        this.info.printerNumber = this.info.printerList[0].printerNumber;  
                    }                    
                    //选择打印机、选择打印的小票
                    if(this.info.printerList!=null && this.info.printerList.length>0){                
                        //多个打印机
                        this.BoxPrinter.isVisible = true;
                    }else{
                        ElMessage.error('该部门没有绑定下小票打印机');
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },
        //打印零售单 - 销售小票
        btnPrintRetail(){
            let param = {
                printerNumber:this.info.printerNumber,
                retailNumber:this.editId,
                realPrice:this.retailInfo.info.realPrice,
            }
            this.$api.erp.PrintRetailInfo(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                }else{
                    ElMessage.error(res.data.msg);
                }
                this.BoxPrinter.isVisible =false;
            })
        },

        //打印零售单 - 配货小票
        btnPrintRetialOutStock(){
            let param = {
                printerNumber:this.info.printerNumber,
                retailNumber:this.editId,
            }
            this.$api.erp.PrintRetailOutStock(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                }else{
                    ElMessage.error(res.data.msg);
                }
                this.BoxPrinter.isVisible =false;
            })
        },


    },
    mounted(){
        this.ff_uuid = this.$uuid.v4();  //防重复提交随机编码
        this.GetDataInfo()
    }
}
</script>

<style scoped>
</style>