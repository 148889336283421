<template>
  <div class="editInfo">
    <el-divider></el-divider>

    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="会员信息" name="tabInfo">
            <!-- 会员信息 -->
            <TabMemberInfo></TabMemberInfo>

            <!-- 会员标签 -->
            <el-divider>会员标签</el-divider>
            <TabMemberTag></TabMemberTag>

            <!-- 会员收货地址信息 -->
            <el-divider>收货地址</el-divider>
            <TabMemberAddress></TabMemberAddress>
        </el-tab-pane>

        <el-tab-pane label="充值记录" name="tabVip">
            <TabVipRecord></TabVipRecord>
        </el-tab-pane>

        <el-tab-pane label="积分记录" name="tabScore">
            <TabVipRecord></TabVipRecord>
        </el-tab-pane>

    </el-tabs>

    


    <!-- 功能按钮组 -->
    <div class="editFooter">
        <!-- <el-button type="primary" @click="btnSaveData">保存</el-button> -->
        <el-button type="warning" @click="BoxMemberTag.isVisible = true">新增标签</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>


    <!-- 会员标签-弹出框 -->
    <el-dialog title="选择会员标签"
    v-model="BoxMemberTag.isVisible"
    width="50%"
    :destroy-on-close="true"
    @closed="GetDataInfo">
        <AddMemberTag @closeBox="BoxCloseTag" :memberNumber="info.memberNumber"></AddMemberTag>
    </el-dialog>

  </div>
</template>

<script>
import TabMemberInfo from '@/views/Member/Info/TabMemberInfo.vue'
import TabMemberTag from '@/views/Member/Info/TabMemberTag.vue'
import TabMemberAddress from '@/views/Member/Info/TabMemberAddress.vue'
import TabVipRecord from '@/views/Member/Info/TabVipRecord.vue'

import AddMemberTag from '@/views/Member/Info/AddMemberTag.vue'

import { ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
export default {
    props:['editId','dataList'],
    components:{
        TabMemberInfo,
        TabMemberTag,
        TabMemberAddress,
        TabVipRecord,
        AddMemberTag
    },
    data(){
        return{
            info:{
                activeName:'tabInfo',
                memberNumber:this.editId, //当前会员编号
            },
            //标签-弹出框
            BoxMemberTag:{
                isVisible:false,
            }
        }
    },
    computed:{
        ...mapState('Shop',['memberInfo'])
    },
    methods:{
        ...mapMutations('Shop',['SetMemberInfo']),

        //加载数据
        GetDataInfo(){
            if(this.info.memberNumber !=0){
                let param = {
                    memberNumber:this.info.memberNumber
                }
                this.$api.member.GetMemberAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetMemberInfo(res.data.data);
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },
        
        //清空实体
        btnClearData(){
            var newInfo = {
                info:{
                    systemNumber:'',
                    memberNumber:'',
                    memberName:'',
                    realName:'',
                    headImg:'',
                    sex:0,
                    birthDate:'',
                    phone:'',
                    loginPwd:'',
                    openId:'',
                    isVip:0,
                    isReal:0,
                    createTime:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    isDel:0
                },
                addressList:[],   //收获地址
            };
            this.SetMemberInfo(newInfo);
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.memberNumber === this.info.memberNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.info.memberNumber = prevInfo.memberNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.memberNumber === this.info.memberNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.info.memberNumber = prevInfo.memberNumber
                this.GetDataInfo()
            }
        },

        //详情页-关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
        //关闭-标签弹出框
        BoxCloseTag(){
            this.BoxMemberTag.isVisible = false;
        },
        

    },
    mounted(){
        this.GetDataInfo()
    }
}
</script>

<style>

</style>