/**
 * 店铺相关
 */

 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const shop = {

    //#region 店铺信息

    //列表
    ListShop(param){
        return axios.post(`${base.base}/Shop/ListShop`,param)
    },

    //获取单个信息
    SingleShopInfo(param){
        return axios.get(`${base.base}/Shop/SingleShopInfo`,{
            params:param
        })
    },
    
    //保存店铺
    SaveShopInfo(param){
        return axios.post(`${base.base}/Shop/SaveShopInfo`,param)
    },

    //删除店铺
    DeleteShopList(param){
        return axios.post(`${base.base}/Shop/DeleteShopList`,param)
    },

    //店铺列表 - 下拉列表
    SelectShopList(){
        return axios.get(`${base.base}/Shop/SelectShopList`)
    },


    //#endregion

    //#region 店铺分类

    //获取店铺分类目录树
    SelectTreeCategory(){
        return axios.get(`${base.base}/ShopCategory/SelectTreeCategory`)
    },

    //获取店铺分类列表
    ListShopCategory(param){
        return axios.post(`${base.base}/ShopCategory/ListShopCategory`,param)
    },

    //获取单个店铺分类
    SingleShopCategoryAllInfo(param){
        return axios.get(`${base.base}/ShopCategory/SingleShopCategoryAllInfo`,{
            params:param
        })
    },

    //保存店铺分类
    SaveShopCategoryAllInfo(param){
        return axios.post(`${base.base}/ShopCategory/SaveShopCategoryAllInfo`,param)
    },

    //删除店铺分类
    DeleteShopCategory(param){
        return axios.post(`${base.base}/ShopCategory/DeleteShopCategory`,param)
    },

    //分类明细
    
    //删除店铺产品分类明细
    DeleteShopCategoryDetail(param){
        return axios({
            url:`${base.base}/ShopCategory/DeleteShopCategoryDetail`,
            method:'post',
            params:param
        })
    },

    //#endregion

    //#region 频道管理

    //列表
    GetChannelList(param){
        return axios.post(`${base.base}/Channel/GetChannelList`,param)
    },

    //获取单个信息(包含明细)
    GetChannelAllInfo(param){
        return axios.get(`${base.base}/Channel/GetChannelAllInfo`,{
            params:param
        })
    },

    //保存频道信息
    SaveChannelAllInfo(param){
        return axios.post(`${base.base}/Channel/SaveChannelAllInfo`,param)
    },

    //删除频道信息
    DeleteChannelInfo(param){
        return axios.post(`${base.base}/Channel/DeleteChannelInfo`,param)
    },

    //频道列表 - 下拉列表
    SelectChannelList(){
        return axios.get(`${base.base}/Channel/SelectChannelList`)
    },

    //删除频道产品明细
    DeleteChannelDetail(param){
        return axios({
            url:`${base.base}/Channel/DeleteChannelDetail`,
            method:'post',
            params:param
        })
    },
    
    //#endregion
    
    //#region 订单管理

    //获取订单列表
    ListOrder(param){
        return axios.post(`${base.base}/Order/ListOrder`,param)
    },

    //获取单个订单信息
    SingleOrderAllInfo(param){
        return axios.get(`${base.base}/Order/SingleOrderAllInfo`,{
            params:param
        })
    },

    //更新订单状态
    UpdateOrderState(param){
        return axios.post(`${base.base}/Order/UpdateOrderState`,param)
    },

    //打印小票订单
    PrintOrderInfo(param){
        return axios.post(`${base.base}/Order/PrintOrderInfo`,param)
    },

    //导入外部订单

    //#endregion


 }

 export default shop