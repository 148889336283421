<template>
  <div>
    <el-divider></el-divider>

    <!-- 审批类型信息 -->
    <el-form :inline="true"
      v-model="info"
      label-width="80px"
      label-position="right">
          <el-row>

              <el-col :span="24">
                  <el-form-item label="类型名称">
                      <el-input v-model="info.typeName" class="search-200"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="24">
                  <el-form-item label="归属类型">
                      <el-tree-select v-model="info.pid" :data="treeProcessType" check-strictly :default-expand-all="true" class="search-200"/>
                  </el-form-item>
              </el-col>

              <el-col :span="24">
                  <el-form-item label="排序">
                      <el-input-number v-model.number="info.sort" :min="0" class="search-200"></el-input-number>
                  </el-form-item>
              </el-col>

          </el-row>
    </el-form>

    <!-- 功能按钮 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData()">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
export default {
  props:['editId'],
  data(){
    return{
      info:{
        id:0,
        typeName:'',
        pid:0,
        sort:0,
      },
      treeProcessType:[],  //审批类型目录树
    }
  },
  methods:{
    //加载Tree数据
    GetTreeDataInfo(){
      this.$api.oa.SelectTreeProcessTypeList().then(res=>{
        if(res.data.status === 200){
          this.treeProcessType = res.data.data;
        }
      })
    },

    //保存分类
    btnSaveData(){
      this.$api.oa.SaveProcessType(this.info).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          this.btnClose();
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //加载数据
    GetDataInfo(){
      if(this.editId !=0){
        let param = {
          id:this.editId,
        }
        this.$api.oa.SingleProcessType(param).then(res=>{
          if(res.data.status === 200){
            this.info = res.data.data;
          }else{
            this.btnClearData();
          }
        })
      }else{
        this.btnClearData();
      }
    },

    //清空实体
    btnClearData(){
      this.info = {
        id:0,
        typeName:'',
        pid:0,
        sort:0,
      }
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },


  },
  mounted(){
    this.GetTreeDataInfo();  //Tree
    this.GetDataInfo();  //加载数据
  }
}
</script>

<style>

</style>