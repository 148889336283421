<template>
  <div>
    <!-- 打卡记录列表 -->
    
    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="员工姓名">
                <el-input v-model="info.search.userNumber"></el-input>
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-tooltip
            class="box-item"
            effect="dark"
            :content="showSearch.search_tip"
            placement="top">
            <el-button class="iconfont icon-search" circle @click="btnHiddenSearch(!showSearch.search_isShow)"></el-button>
            </el-tooltip>
            
            <el-tooltip
            class="box-item"
            effect="dark"
            content="流程图"
            placement="top">
            <el-button class="iconfont icon-icon__liuchengtu" circle @click="btnNoFlow"></el-button>
            </el-tooltip>

            <el-tooltip
            class="box-item"
            effect="dark"
            content="打印"
            placement="top">
            <el-button class="iconfont icon-dayin" circle @click="btnNoPrint"></el-button>
            </el-tooltip>

            <el-tooltip
            class="box-item"
            effect="dark"
            content="导出数据"
            placement="top">
            <el-button class="iconfont icon-download" circle @click="btnNoExport"></el-button>
            </el-tooltip>            
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border style="width: 100%" @select="btnCheckbox">
        <el-table-column type="selection" width="50" />  
        <el-table-column prop="userName" label="员工姓名" />
        <el-table-column prop="clockInDate" label="打卡日期" />
        <el-table-column label="打卡类型">
            <template #default="scope">
                <el-tag v-if="scope.row.clockInType ==1" type="danger">上班卡</el-tag>
                <el-tag v-if="scope.row.clockInType ==2">下班卡</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="打卡日期" width="110">
            <template #default="scope">
              <span>{{DateFormat(scope.row.clockInTime)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="打卡方式">
            <template #default="scope">
                <el-tag v-if="scope.row.clockInType ==1" type="danger">密码</el-tag>
                <el-tag v-if="scope.row.clockInType ==2" type="primary">指纹</el-tag>
                <el-tag v-if="scope.row.clockInType ==3" type="warning">刷卡</el-tag>
                <el-tag v-if="scope.row.clockInType ==4" type="success">人脸</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="打卡时间" width="110">
            <template #default="scope">
            <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>        
        <el-table-column label="操作" width="200" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.cycleNumber)">详情</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.cycleNumber)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
                </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[10, 50, 100, 200]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
            >
            </el-pagination>
        </el-container>

    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      info:{
        dataList:[],  //Table数据
        page:1,
        size:10,
        total:0,  //总记录数
        id:0,  //新增、编辑 的参数
        ids:[],   //删除 的参数
        search:{
          userNumber:'',   //员工姓名
        }
      }
    }
  }
}
</script>

<style>

</style>