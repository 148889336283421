<template>
  <div>
    <el-divider></el-divider>

    <div>
        <!-- 月份 -->
        <el-form :inline="true"
            v-model="info"
            label-width="100px"
            label-position="right">
                <el-row>

                    <el-col :span="12">
                        <el-form-item label="月份">
                            <el-date-picker
                                v-model="info.month"
                                type="month"/>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="单据类型">
                            <el-select v-model="info.billType" class="search-200">
                                <el-option :value="1" label="工资"></el-option>
                                <el-option :value="2" label="奖金"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                </el-row>
        </el-form>

        <!-- 员工列表 -->
        <el-divider>选择员工</el-divider>
        <el-table :data="info.dataList" border style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="id" type="selection" width="50"></el-table-column>
            <el-table-column prop="departmentName" label="所属部门"></el-table-column>
            <el-table-column prop="userName" label="员工姓名"></el-table-column>
            <el-table-column label="性别">
                <template #default="scope">
                    <el-tag v-if="scope.row.sex ==0">女</el-tag>
                    <el-tag v-if="scope.row.sex ==1" type="danger">男</el-tag>
                    <el-tag v-if="scope.row.sex ==2" type="success">未知</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="员工类型">
                <template #default="scope">
                    <el-tag v-if="scope.row.staffType ==1">正式员工</el-tag>
                    <el-tag v-if="scope.row.staffType ==2" type="danger">临时员工</el-tag>
                </template>
            </el-table-column>
        </el-table>
    </div>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnBatchSaveWage">保存</el-button>
        <el-button @click="btnBatchClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { ElMessage } from 'element-plus'
export default {
    data(){
        return{
            info:{
                month:moment(new Date()).format("YYYY-MM"),
                billType:1,  //单据类型： 1：工资 2:奖金
                selectUserList:[],  //选中员工
                dataList:[]   //加载的员工列表
            }
        }
    },
    methods:{

        //加载员工列表
        GetUserDataList(){
            this.$api.system.SelectUserList().then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data  //用户列表
                }else{
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e.message);
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.selectUserList = selection.map(item=>item.userNumber);
            console.log(this.info.selectUserList)
        },
        //保存
        btnBatchSaveWage(){
            let param = {
                month:moment(this.info.month).format("YYYY-MM"),
                billType:this.info.billType,
                userNumberList:this.info.selectUserList
            }
            //console.log('保存',param)
            this.$api.oa.SaveBatchWage(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //关闭
                    this.btnBatchClose();
                }
                else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnBatchClose(){
            this.$emit('closeBatchBox')  //关闭弹出框
        }
    },
    mounted(){
        this.GetUserDataList() //
    }
}
</script>

<style>

</style>