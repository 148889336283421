/**
 * 获取用户授权下的部门列表
 * ===下拉列表专用===
 */

const UserDptList = {
    data(){
        return{
            departmentList:[]
        }
    },
    methods:{
        //加载部门下拉列表
        SelectUserDptList(){
            this.$api.system.SelectUserDptList().then(res=>{
                if(res.data.status === 200){
                    this.departmentList = res.data.data;
                }
            }).catch(e=>{
                console.log(e)
            })
        }

    },
    mounted(){
        this.SelectUserDptList()   //加载用户授权部门列表
    }
}

export default UserDptList