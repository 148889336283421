<template>
  <div>
    
    <!-- 搜索功能 -->
      <el-row :style="showSearch.search_hidden">
          <el-col :span="24">
              <el-form :inline="true">

                <el-form-item label="订单编号">
                    <el-input v-model="info.search.orderNumber" class="search-150"></el-input>
                </el-form-item>

                  <el-form-item label="订单状态">
                    <el-select v-model="info.search.state" placeholder="请选择" class="search-200">
                        <el-option label="全部" :value="0"></el-option>
                        <el-option label="待支付" :value="1"></el-option>
                        <el-option label="待发货" :value="2"></el-option>
                        <el-option label="待收货" :value="3"></el-option>
                        <el-option label="已完成" :value="4"></el-option>
                        <el-option label="售后中" :value="5"></el-option>
                        <el-option label="已取消" :value="6"></el-option>
                    </el-select>
                  </el-form-item>

                <el-form-item label="会员名称">
                    <el-input v-model="info.search.memberName" class="search-150"></el-input>
                </el-form-item>

                  <el-form-item>
                  <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                  <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                  </el-form-item>

              </el-form>
            </el-col>
      </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <!-- <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button> -->
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
                <el-popconfirm 
                    title="确认要创建业务单?"
                    @confirm="btnCreateBusiness">
                    <template #reference>
                        <el-button class="iconfont icon-dingdan" type="warning" plain>生成业务单</el-button>
                    </template>
                </el-popconfirm>        
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" show-summary border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="55" /> 
        <el-table-column label="订单编号">
            <template v-slot="scope">
                {{scope.row.orderNumber}}
            </template>
        </el-table-column>
        <el-table-column prop="memberName" label="会员名称" />
        <el-table-column label="状态">
            <template #default="scope">
                <el-tag v-if="scope.row.state == 1">待支付</el-tag>
                <el-tag v-if="scope.row.state == 2">待发货</el-tag>
                <el-tag v-if="scope.row.state == 3">待收货</el-tag>
                <el-tag v-if="scope.row.state == 4">已完成</el-tag>
                <el-tag v-if="scope.row.state == 5">售后中</el-tag>
                <el-tag v-if="scope.row.state == 6">已取消</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="产品总价(元)" />
        <el-table-column prop="totalPrice" label="实际支付价(元)" />
        <el-table-column label="下单日期" min-width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.createTime"
                    placement="top"
                >
                <span>{{DateFormat(scope.row.createTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
            <template v-slot="scope">              
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.orderNumber)">详情</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.orderNumber)">
                    <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="12">
                
            </el-col>
            <el-col :span="12">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[20, 50, 200, 500]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditOrder :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditOrder>
    </el-drawer>

  </div>
</template>

<script>
import EditOrder from '@/views/Sales/Order/EditOrder.vue'
import { ElMessage } from 'element-plus'
export default {
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:20,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    orderNumber:'',  //订单编号
                    memberName:'',   //会员名称
                    state:0,   //全部
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'订单明细'
            },
        }
    },
    components:{
        EditOrder
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            let param = {
                orderNumber:this.info.search.orderNumber,
                memberName:this.info.search.memberName,
                state:this.info.search.state,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.shop.ListOrder(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.orderNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增编辑部门信息
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "订单明细"
            }else{
                this.BoxEditInfo.title = "订单明细"
            }  
            this.BoxEditInfo.isVisible = true;
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.orderNumber='',
            this.info.search.memberName='',
            this.info.search.state = 0,
            this.GetDataList()
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //生成业务单
        btnCreateBusiness(){
            if(this.info.ids.length <1){
                ElMessage.error('请选择要生成业务单的单据');
                return false;
            }

            ElMessage.error('开发中');
        },

    },
    mounted(){
        this.GetDataList();
    }
}
</script>

<style>

</style>