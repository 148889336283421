<template>
  <div>
    <!-- 按钮 -->
    <div>
        <el-upload class="btnTk"
            :action="GetFileUrl('Goods')"
            :headers="GetHeader"
            :show-file-list="false"
            multiple
            :before-upload="BeforeUpload"
            :on-success="UploadSuccess"
            :on-error="UploadError">
            <el-button class="iconfont icon-add" type="primary" plain>上传文件</el-button>
        </el-upload>
    </div>

    <!-- 图片列表 -->
    <el-table :data="spuInfo.imgList" border style="width: 100%;height:500px;">
        <el-table-column prop="id" label="编号" width="100"></el-table-column>
        <el-table-column label="图片">
            <template v-slot="scope">
                <el-image
                style="width: 70px; height: 70px"
                :src="scope.row.spuImg"
                :preview-src-list="spuInfo.img2List"
                :initial-index="scope.$index"
                :hide-on-click-modal="true"
                :infinite="false"
                fit="cover"></el-image>
          </template>
        </el-table-column>

        <el-table-column label="排序" width="150">
            <template v-slot="scope">
                <el-input-number v-model="scope.row.sort" :min="0" :max="100" style="width:110px"></el-input-number>
            </template>
        </el-table-column>

        <el-table-column prop="isDef" label="默认项" width="130">
            <template v-slot="scope">
                <el-radio-group v-model="scope.row.isDef">
                    <el-radio-button :value="0">否</el-radio-button>
                    <el-radio-button :value="1">是</el-radio-button>
              </el-radio-group>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="90" fixed="right">
            <template v-slot="scope">
                <el-popconfirm 
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.spuNumber,scope.row.id,scope.$index)">
                    <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>

    

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { mapState } from 'vuex';
import Authorize from '@/utils/authorize.js'
export default {
    props:['editId'],
    computed:{
        ...mapState('Erp',['spuInfo']),
        //添加Token - 重要
        GetHeader(){
            return {
                "Authorization":"Bearer "+ Authorize.getToken()
            }
        },
    },
    methods:{
        //删除文件
        DeleteRow(spuNumber,id,index){
            this.spuInfo.imgList.splice(index,1)
            this.spuInfo.img2List.splice(index,1)
            if(id !==0){
                let param = {
                    spuNumber:spuNumber,
                    id:id
                }
                this.$api.goods.DeleteSpuImage(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }
        },

        //上传图片之前操作
        BeforeUpload(file){
            const fileType = file.type;
            const fileSize = file.size;
            if(fileType !== 'image/jpeg' && fileType !== 'image/png'){
                ElMessage.error('上传格式: jpeg | png ')
                return false;
            }
            //文件大小
            if(fileSize/1024/1024 > 10){
                ElMessage.error('上传文件大小不能超过10Mb')
                return false;
            }

        },
        //上传完成（图片、文件）通用
        UploadSuccess(res){
            if(res.status === 200){
                //创建新记录
                let newRow = {
                    id:0,
                    systemNumber:'',
                    spuNumber:this.spuInfo.info.spuNumber,
                    spuImg:res.data,
                    sort:0,
                    isDef:0,
                }
                this.spuInfo.imgList.push(newRow);           
            }
        },
        //上传失败（图片、文件）通用
        UploadError(e){
            ElMessage.error(e)
        },


    },
    mounted(){
    }
}
</script>

<style scoped>
.btnTk{float: left;margin: 5px;}
</style>