/**
 * 获取会计科目列表- 目录树
 * 
 */

const SubjectList = {
    data(){
        return{
            selectSubjectList:[]
        }
    },
    methods:{
        //加载会计科目下拉列表
        SelectSubjectList(){
            this.$api.finance.SelectTreeSubject().then(res=>{
                if(res.data.status === 200){
                  this.selectSubjectList = res.data.data;  //目录树列表
                }else{
                    console.log(res.data.msg);
                }
              }).catch(e=>{
                console.log(e.message);
              })
        }
    },
    mounted(){
        this.SelectSubjectList();   //加载会计科目列表
    }
}

export default SubjectList